import { useMemo } from 'react'
import { omit } from 'lodash'
import { QueryClient } from 'react-query'

import {
  GetRequestInterface,
  RequestInterfaceNew,
  TableRequestInterface,
} from '@src/interfaces'
import { api, apiWithoutHandling } from '@src/api/index'
import { API } from '@src/constants/api'
import {
  AccessManagementSettingsInterface,
  AccountsSettingsInterface,
  ApprovalFlowSettingStep,
  ApprovalProcess,
  ApprovalProcessSteps,
  CommunicationSettingsInterface,
  CompanyPreferencesInterface,
  CompanySettingsInterface,
  ContactHRSettingsInterface,
  ContractsSettingsInterface,
  DocumentsSettingsInterface,
  EmployeeSettingsInterface,
  GlobalSettingsResponse,
  HelpCenterSettingsInterface,
  LifecycleSettingsInterface,
  LocationSettingsInterface,
  OfferSettingsInterface,
  OrganisationSettingsInterface,
  PayrollSettingsInterface,
  RequisitionSettingsInterface,
  RoadmapSettingsInterface,
  ScreeningSettingsInterface,
  SecuritySettingsInterface,
  SkillSettingsInterface,
  TimeOffSettingsInterface,
  TodoSettingsInterface,
  UseGetEmployeeSettingsInterface,
  CareerPageSettingsInterface,
  JobPostingSettingsInterface,
  ApplicationFormSettingsInterface,
  CandidateSettingsInterface,
  KeyPersonsSettingsInterface,
  HiringProcessSettingsInterface,
  CountryHiringInterface,
  AttendanceTrackingSettings,
  GlobalPublicSettingsResponse,
  CompensationSettings,
  LinkedinIntegrationResponseInterface,
} from '@src/interfaces/settings'
import { FileInterface } from '@src/interfaces/files'
import { useFetch, usePost, useUpdate } from '@src/utils/reactQuery'
import { FetchDataQueryInterface } from '@src/interfaces/data'
import { filterSortPageIntoQuery } from '@src/utils/table'
import { GlobalSettings } from '@src/store/auth/types'
import { TenantDBClusterInterface } from '@src/interfaces/tenants'

export const companySettings: RequestInterfaceNew<CompanySettingsInterface> = {
  get: async ({ id }) => api.get(`${API.COMPANY_SETTINGS}/${id}`),
  update: async (data, { id }) =>
    apiWithoutHandling.patch(`${API.COMPANY_SETTINGS}/${id}`, data),
  submit: async data => apiWithoutHandling.post(API.COMPANY_SETTINGS, data),
}

export const companyPreferences: RequestInterfaceNew<CompanyPreferencesInterface> = {
  get: async ({ id }) => api.get(`${API.COMPANY_PREFERENCES}/${id}`),
  update: async (data, { id }) =>
    apiWithoutHandling.patch(`${API.COMPANY_PREFERENCES}/${id}`, data),
  submit: async data => apiWithoutHandling.post(API.COMPANY_PREFERENCES, data),
}

export const companyPreferencesOnbV2: RequestInterfaceNew<
  CompanyPreferencesInterface<'OnboardingV2'>
> = {
  get: async ({ id }) => api.get(`${API.COMPANY_PREFERENCES}/${id}`),
  update: async (data, { id }) =>
    apiWithoutHandling.patch(`${API.COMPANY_PREFERENCES}/${id}`, data),
  submit: async data => apiWithoutHandling.post(API.COMPANY_PREFERENCES, data),
}

export const companyPreferencesWithInvalidation = (
  queryClient: QueryClient,
): RequestInterfaceNew<CompanyPreferencesInterface> => ({
  ...companyPreferences,
  update: async (data, { id }) =>
    apiWithoutHandling.patch(`${API.COMPANY_PREFERENCES}/${id}`, data).then(response => {
      queryClient.setQueryData([API.COMPANY_PREFERENCES, 'v1', null], response.data)
      queryClient.invalidateQueries(API.COMPANY_PREFERENCES)
      return response
    }),
})

export const securitySettings: RequestInterfaceNew<SecuritySettingsInterface> = {
  get: async ({ id }) => api.get(`${API.SECURITY_SETTINGS}/${id}`),
  update: async (data, { id }) =>
    apiWithoutHandling.patch(`${API.SECURITY_SETTINGS}/${id}`, data),
  submit: async data => apiWithoutHandling.post(API.SECURITY_SETTINGS, data),
}

export const uploadLogo = async (file: File) => {
  const fileFormData = new FormData()
  fileFormData.append('file', file, file.name)
  fileFormData.append('category', 'company_logo')

  return apiWithoutHandling.post<FileInterface>('/companyLogo', fileFormData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export const useGetCompanyPreferences = (enabled = true) =>
  useFetch<CompanyPreferencesInterface>(
    API.COMPANY_PREFERENCES,
    undefined,
    undefined,
    undefined,
    {
      cacheTime: 1000 * 60 * 30,
      staleTime: 1000 * 60 * 60,
      refetchOnWindowFocus: false,
      enabled,
    },
  )

export const useUpdateCompanyPreferences = () =>
  usePost<CompanyPreferencesInterface, Partial<CompanyPreferencesInterface>>(
    API.COMPANY_PREFERENCES,
    undefined,
    undefined,
    (oldData, newData) => ({
      ...oldData,
      ...newData,
    }),
  )

export const useGetCompanySettings = (enabled = true) =>
  useFetch<CompanySettingsInterface>(
    `${API.COMPANY_SETTINGS}/1`,
    undefined,
    undefined,
    undefined,
    {
      cacheTime: 1000 * 60 * 30,
      staleTime: 1000 * 60 * 60,
      refetchOnWindowFocus: false,
      enabled,
    },
  )

export const useUpdateCompanySettings = () =>
  useUpdate<CompanySettingsInterface, Partial<CompanySettingsInterface>>(
    API.COMPANY_SETTINGS,
    undefined,
    undefined,
    false,
    (oldData, newData) => ({
      ...oldData,
      ...newData,
    }),
    true,
    true,
  )

export const useGetCompanyLogo = () =>
  useFetch<FileInterface>(`/companyLogo`, undefined, undefined, undefined, {
    cacheTime: 1000 * 60 * 30,
    staleTime: 1000 * 60 * 60,
    refetchOnWindowFocus: false,
  })

export const contractsSettings: RequestInterfaceNew<ContractsSettingsInterface> = {
  get: async () => api.get(API.CONTRACTS_SETTINGS, undefined, 'v1', true),
  update: async (data, { id }) =>
    apiWithoutHandling.patch(
      `${API.CONTRACTS_SETTINGS}/${id}`,
      data,
      undefined,
      'v1',
      true,
    ),
  submit: async data =>
    apiWithoutHandling.post(API.CONTRACTS_SETTINGS, data, undefined, 'v1', true),
}

export const useGetContractsSettings = () =>
  useFetch<ContractsSettingsInterface>(
    API.CONTRACTS_SETTINGS,
    undefined,
    undefined,
    true,
    {
      staleTime: Infinity,
      cacheTime: Infinity,
    },
    true,
  )

export const employeeSettings: RequestInterfaceNew<EmployeeSettingsInterface> = {
  get: async () => api.get(API.EMPLOYEE_SETTINGS, undefined, undefined, true),
  update: async (data, { id }) =>
    apiWithoutHandling.patch(
      `${API.EMPLOYEE_SETTINGS}/${id}`,
      data,
      undefined,
      undefined,
      true,
    ),
  submit: async data =>
    apiWithoutHandling.post(API.EMPLOYEE_SETTINGS, data, undefined, undefined, true),
}

export const useGetEmployeeSettings = (disabled?: boolean) =>
  useFetch<UseGetEmployeeSettingsInterface>(
    API.EMPLOYEE_SETTINGS,
    undefined,
    undefined,
    true,
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      enabled: !disabled,
    },
    true,
  )

export const lifecycleSettings: RequestInterfaceNew<LifecycleSettingsInterface> = {
  get: async () => api.get(API.LIFECYCLE_SETTINGS, undefined, undefined, true),
  update: async (data, { id }) =>
    apiWithoutHandling.patch(
      `${API.LIFECYCLE_SETTINGS}/${id}`,
      data,
      undefined,
      undefined,
      true,
    ),
  submit: async data =>
    apiWithoutHandling.post(API.LIFECYCLE_SETTINGS, data, undefined, undefined, true),
}

export const useGetLifecycleSettings = () =>
  useFetch<LifecycleSettingsInterface>(
    API.LIFECYCLE_SETTINGS,
    undefined,
    undefined,
    true,
    {
      staleTime: Infinity,
      cacheTime: Infinity,
    },
    true,
  )

export const useGetContactHRSettings = () =>
  useFetch<ContactHRSettingsInterface>(
    API.CONTACT_HR,
    undefined,
    undefined,
    true,
    {
      staleTime: Infinity,
      cacheTime: Infinity,
    },
    true,
  )

export const documentsSettings: RequestInterfaceNew<DocumentsSettingsInterface> = {
  get: async () => api.get(API.DOCUMENT_SETTINGS, undefined, 'v1', true),
  update: async (data, { id }) =>
    apiWithoutHandling.patch(
      `${API.DOCUMENT_SETTINGS}/${id}`,
      data,
      undefined,
      'v1',
      true,
    ),
  submit: async data =>
    apiWithoutHandling.post(API.DOCUMENT_SETTINGS, data, undefined, 'v1', true),
}

export const useGetDocumentsSettings = () =>
  useFetch<DocumentsSettingsInterface>(
    API.DOCUMENT_SETTINGS,
    undefined,
    undefined,
    true,
    {
      staleTime: Infinity,
      cacheTime: Infinity,
    },
    true,
  )

export const accountSettings: RequestInterfaceNew<AccountsSettingsInterface> = {
  get: async ({ id }) => api.get(`${API.TENANTS}/${id}`),
  update: async (data, { id }) =>
    apiWithoutHandling.patch(`${API.TENANTS}/${id}`, omit(data, 'admin.phone_number')),
  submit: async data => apiWithoutHandling.post(API.TENANTS, data),
}

export const getAccountSettings = (id: number | string) =>
  apiWithoutHandling.get<AccountsSettingsInterface>(`${API.TENANTS}/${id}`)

export const getAccounts = ({ sortBy, filters, page }: FetchDataQueryInterface) =>
  api.get<GetRequestInterface<AccountsSettingsInterface>>(API.TENANTS, {
    params: filterSortPageIntoQuery(sortBy, filters, page),
  })

export const getAccountActivationLink = (tenantId: number | string) =>
  /** /activationUrl/1 the "1" is hardcoded on the BE */
  api.get<{ activation_url: string }>(
    `${API.ACCOUNT}/activationUrl/1?tenant_id=${tenantId}`,
  )

export const setupTenant = (tenantId: number | string, db_cluster_id: string | number) =>
  api.put(`${API.TENANTS}/${tenantId}/setup`, { db_cluster_id })

export const useTenantDBClusters = (enabled: boolean) => {
  const fetchResult = useFetch<GetRequestInterface<TenantDBClusterInterface>>(
    `${API.TENANTS}/dbClusters`,
    undefined,
    undefined,
    true,
    { enabled },
  )

  return fetchResult.data?.results
}

export const locationSettings: RequestInterfaceNew<LocationSettingsInterface> = {
  get: async () => api.get(API.LOCATION_SETTINGS, undefined, undefined, true),
  update: async (data, { id }) =>
    apiWithoutHandling.patch(
      `${API.LOCATION_SETTINGS}/${id}`,
      data,
      undefined,
      undefined,
      true,
    ),
  submit: async data =>
    apiWithoutHandling.post(API.LOCATION_SETTINGS, data, undefined, undefined, true),
}

export const useGetLocationSettings = () =>
  useFetch<LocationSettingsInterface>(API.LOCATION_SETTINGS, undefined, undefined, true, {
    staleTime: Infinity,
    cacheTime: Infinity,
  })

export const accessManagementSettings: RequestInterfaceNew<AccessManagementSettingsInterface> =
  {
    get: async () => api.get(API.ACCESS_MANAGEMENT_SETTINGS),
    update: async (data, { id }) =>
      apiWithoutHandling.patch(`${API.ACCESS_MANAGEMENT_SETTINGS}/${id}`, data),
    submit: async data => apiWithoutHandling.post(API.ACCESS_MANAGEMENT_SETTINGS, data),
  }

export const useGetAccessManagementSettings = () =>
  useFetch<AccessManagementSettingsInterface>(
    API.ACCESS_MANAGEMENT_SETTINGS,
    undefined,
    undefined,
    true,
    {
      staleTime: Infinity,
      cacheTime: Infinity,
    },
  )

export const skillSettings: RequestInterfaceNew<SkillSettingsInterface> = {
  get: async () => api.get(API.SKILL_SETTINGS),
  update: async (data, { id }) =>
    apiWithoutHandling.patch(`${API.SKILL_SETTINGS}/${id}`, data),
  submit: async data => apiWithoutHandling.post(API.SKILL_SETTINGS, data),
}

export const useGetSkillsSettings = () => {
  return useFetch<SkillSettingsInterface>(
    API.SKILL_SETTINGS,
    undefined,
    undefined,
    true,
    {
      staleTime: Infinity,
      cacheTime: Infinity,
    },
  )
}

export const organisationSettings: RequestInterfaceNew<OrganisationSettingsInterface> = {
  get: async () => api.get(API.ORGANISATION_SETTINGS, undefined, undefined, true),
  update: async (data, { id }) =>
    apiWithoutHandling.patch(
      `${API.ORGANISATION_SETTINGS}/${id}`,
      data,
      undefined,
      undefined,
      true,
    ),
  submit: async data =>
    apiWithoutHandling.post(API.ORGANISATION_SETTINGS, data, undefined, undefined, true),
}

export const useGetOrganisationSettings = () =>
  useFetch<OrganisationSettingsInterface>(
    API.ORGANISATION_SETTINGS,
    undefined,
    undefined,
    true,
    {
      staleTime: Infinity,
      cacheTime: Infinity,
    },
    true,
  )

export const todoSettings: RequestInterfaceNew<TodoSettingsInterface> = {
  get: async () => api.get(API.TODO_SETTINGS),
  update: async (data, { id }) =>
    apiWithoutHandling.patch(`${API.TODO_SETTINGS}/${id}`, data),
  submit: async data => apiWithoutHandling.post(API.TODO_SETTINGS, data),
}

export const useGetRoadmapSettings = () =>
  useFetch<RoadmapSettingsInterface>(API.ROADMAP_SETTINGS, undefined, undefined, true, {
    staleTime: Infinity,
    cacheTime: Infinity,
  })

export const roadmapSettings: RequestInterfaceNew<RoadmapSettingsInterface> = {
  get: async () => api.get(API.ROADMAP_SETTINGS, undefined, undefined, true),
  update: async (data, { id }) =>
    apiWithoutHandling.patch(
      `${API.ROADMAP_SETTINGS}/${id}`,
      data,
      undefined,
      undefined,
      true,
    ),
  submit: async data =>
    apiWithoutHandling.post(API.ROADMAP_SETTINGS, data, undefined, undefined, true),
}

export const communicationSettings: RequestInterfaceNew<CommunicationSettingsInterface> =
  {
    get: async () => api.get(API.COMMUNICATION_SETTINGS),
    update: async (data, { id }) =>
      apiWithoutHandling.patch(`${API.COMMUNICATION_SETTINGS}/${id}`, data),
    submit: async data => apiWithoutHandling.post(API.COMMUNICATION_SETTINGS, data),
  }

export const useGetCommunicationSettings = () =>
  useFetch<CommunicationSettingsInterface>(
    API.COMMUNICATION_SETTINGS,
    undefined,
    undefined,
    true,
    {
      staleTime: Infinity,
      cacheTime: Infinity,
    },
  )

export const attendanceTrackingSettings: RequestInterfaceNew<AttendanceTrackingSettings> =
  {
    get: async () => api.get(`${API.ATTENDANCE_TRACKING}/attendanceTrackingSettings/1`),
    update: async data =>
      apiWithoutHandling.patch(
        `${API.ATTENDANCE_TRACKING}/attendanceTrackingSettings/1`,
        data,
      ),
    submit: async data =>
      apiWithoutHandling.post(
        `${API.ATTENDANCE_TRACKING}/attendanceTrackingSettings/1`,
        data,
      ),
  }

export const useAttendanceTrackingSettings = () =>
  useFetch<AttendanceTrackingSettings>(
    `${API.ATTENDANCE_TRACKING}/attendanceTrackingSettings/1`,
  )

export const approvalStepSettingsRequests: RequestInterfaceNew<ApprovalFlowSettingStep> =
  {
    get: async ({ id }) => api.get(`${API.APPRVAL_STEP_CONFIGURATIONS}/${id}`),
    update: async (data, { id }) =>
      apiWithoutHandling.patch(`${API.APPRVAL_STEP_CONFIGURATIONS}/${id}`, data),
    submit: async data => apiWithoutHandling.post(API.APPRVAL_STEP_CONFIGURATIONS, data),
    delete: async ({ id }) => api.delete(`${API.APPRVAL_STEP_CONFIGURATIONS}/${id}`),
  }

export const APPROVAL_PROCESS_GROUPS_API = `${API.APPRVAL_STEP_CONFIGURATIONS}/approvalProcesses`

export const useApprovalStepConfigurations = (process: ApprovalProcess) => {
  const fetchResults = useFetch<ApprovalProcessSteps>(
    APPROVAL_PROCESS_GROUPS_API,
    undefined,
    undefined,
    true,
    {
      staleTime: Infinity,
      cacheTime: Infinity,
    },
  )

  return fetchResults.data?.[process]
}

export type GlobalSettingsRecord = Record<
  | GlobalSettings.AttendanceEnabled
  | GlobalSettings.CompensationEnabled
  | GlobalSettings.BenefitsEnabled
  | GlobalSettings.EngagementEnabled
  | GlobalSettings.PromotionsEnabled
  | GlobalSettings.ProbationEnabled
  | GlobalSettings.PipEnabled
  | GlobalSettings.FeedbackEnabled
  | GlobalSettings.KeyPersonEnabled
  | GlobalSettings.LinkedAccountsEnabled
  | GlobalSettings.ScreeningEnabled
  | GlobalSettings.DocumentsTemplatesEnabled
  | GlobalSettings.RequisitionsEnabled
  | GlobalSettings.JobPostingsEnabled
  | GlobalSettings.CommercialProductDisabled
  | GlobalSettings.CareerPageEnabled
  | GlobalSettings.CandidatesEnabled
  | GlobalSettings.ResignationsEnabled
  | GlobalSettings.ApplicantsTrackingEnabled
  | GlobalSettings.ReferralsEnabled
  | GlobalSettings.RecruitmentGroupsEnabled
  | GlobalSettings.CandidateSchedulingEnabled
  | GlobalSettings.OffersTemplatesEnabled
  | GlobalSettings.PayrollEnabled
  | GlobalSettings.TotalCompensationEnabled
  | GlobalSettings.TimeOffEnabled,
  boolean | undefined
>

export type GlobalPublicSettingsRecord = Record<
  GlobalSettings.CommercialProductDisabled,
  boolean | undefined
>

export const useGlobalSettings = () => {
  const { data, isLoading } = useFetch<GlobalSettingsResponse>(
    API.APP_SETTINGS,
    undefined,
    undefined,
    undefined,
    {
      cacheTime: Infinity,
      staleTime: Infinity,
    },
    true,
  )

  return useMemo<{
    isLoading: boolean
    // TODO: All the global settings will go here once BE implements it, for now picking those that are returned with the endpoint
    settings: GlobalSettingsRecord
  }>(
    () => ({
      isLoading,
      settings: {
        [GlobalSettings.AttendanceEnabled]: data?.attendance?.enabled,
        [GlobalSettings.CompensationEnabled]: data?.compensation?.enabled,
        [GlobalSettings.BenefitsEnabled]: data?.benefits?.enabled,
        [GlobalSettings.EngagementEnabled]: data?.engagement?.enabled,
        [GlobalSettings.PromotionsEnabled]: data?.promotions?.enabled,
        [GlobalSettings.ProbationEnabled]: data?.probation?.enabled,
        [GlobalSettings.PipEnabled]: data?.pip?.enabled,
        [GlobalSettings.FeedbackEnabled]: data?.feedback?.enabled,
        [GlobalSettings.KeyPersonEnabled]: data?.key_person?.enabled,
        [GlobalSettings.LinkedAccountsEnabled]: data?.linked_accounts?.enabled,
        [GlobalSettings.ScreeningEnabled]: data?.screening?.enabled,
        [GlobalSettings.DocumentsTemplatesEnabled]:
          data?.documents?.enable_document_generation_from_templates,
        [GlobalSettings.RequisitionsEnabled]: data?.requisitions?.enabled,
        [GlobalSettings.CandidatesEnabled]: data?.candidates?.enabled,
        [GlobalSettings.JobPostingsEnabled]: data?.job_postings?.enabled,
        // it's inverse, because usually we show sections if it's not a commercial product
        [GlobalSettings.CommercialProductDisabled]:
          data?.commercial_product?.enabled === undefined
            ? undefined
            : data?.commercial_product?.enabled === false,
        [GlobalSettings.ResignationsEnabled]: data?.resignations?.enabled,
        [GlobalSettings.PayrollEnabled]: data?.payroll?.enabled,
        [GlobalSettings.CareerPageEnabled]: data?.career_page?.enabled,
        [GlobalSettings.ApplicantsTrackingEnabled]: data?.applicant_tracking?.enabled,
        [GlobalSettings.ReferralsEnabled]: data?.referrals?.enabled,
        [GlobalSettings.RecruitmentGroupsEnabled]: data?.recruitment_groups?.enabled,
        [GlobalSettings.CandidateSchedulingEnabled]: data?.candidate_scheduling?.enabled,
        [GlobalSettings.OffersTemplatesEnabled]: data?.offers?.enable_offer_templates,
        [GlobalSettings.TotalCompensationEnabled]: data?.total_compensation?.enabled,
        [GlobalSettings.TimeOffEnabled]: false, // is fetched from another endpoint
      },
    }),
    [data, isLoading],
  )
}

export const usePublicGlobalSettings = () => {
  const { data, isLoading } = useFetch<GlobalPublicSettingsResponse>(
    API.APP_PUBLIC_SETTINGS,
    undefined,
    undefined,
    undefined,
    {
      cacheTime: Infinity,
      staleTime: Infinity,
    },
    true,
  )

  return useMemo<{
    isLoading: boolean
    settings: GlobalPublicSettingsRecord
  }>(
    () => ({
      isLoading,
      settings: {
        // it's inverse, because usually we show sections if it's not a commercial product
        [GlobalSettings.CommercialProductDisabled]:
          data?.commercial_product?.enabled === undefined
            ? undefined
            : data?.commercial_product?.enabled === false,
      },
    }),
    [data, isLoading],
  )
}

export const timeOffSettingsRequests: RequestInterfaceNew<TimeOffSettingsInterface> = {
  get: async () => api.get(API.TIME_OFF_SETTINGS),
  update: async (data, { id }) =>
    apiWithoutHandling.patch(`${API.TIME_OFF_SETTINGS}/${id}`, data),
  submit: async data => apiWithoutHandling.post(API.TIME_OFF_SETTINGS, data),
}

export const useGetTimeOffSettings = (disabled?: boolean) =>
  useFetch<TimeOffSettingsInterface>(
    API.TIME_OFF_SETTINGS,
    undefined,
    undefined,
    undefined,
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      enabled: !disabled,
    },
  )

export const requisitionSettings: RequestInterfaceNew<RequisitionSettingsInterface> = {
  get: async () => api.get(API.REQUISITION_SETTINGS),
  update: async (data, { id }) =>
    apiWithoutHandling.patch(`${API.REQUISITION_SETTINGS}/${id}`, data),
  submit: async data => apiWithoutHandling.post(API.REQUISITION_SETTINGS, data),
}

export const candidateSettings: RequestInterfaceNew<CandidateSettingsInterface> = {
  get: async () => api.get(API.CANDIDATE_SETTINGS),
  update: async (data, { id }) =>
    apiWithoutHandling.patch(`${API.CANDIDATE_SETTINGS}/${id}`, data),
  submit: async data => apiWithoutHandling.post(API.CANDIDATE_SETTINGS, data),
}

export const hiringProcessSettings: RequestInterfaceNew<HiringProcessSettingsInterface> =
  {
    get: async () => api.get(API.HIRING_PROCESS_SETTINGS),
    update: async (data, { id }) =>
      apiWithoutHandling.patch(`${API.HIRING_PROCESS_SETTINGS}/${id}`, data),
    submit: async data => apiWithoutHandling.post(API.HIRING_PROCESS_SETTINGS, data),
  }

export const useGetHiringProcessSettings = (disabled?: boolean) =>
  useFetch<HiringProcessSettingsInterface>(
    API.HIRING_PROCESS_SETTINGS,
    undefined,
    undefined,
    true,
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      enabled: !disabled,
    },
  )

export const useGetCandidateSettings = (disabled?: boolean) =>
  useFetch<CandidateSettingsInterface>(
    API.CANDIDATE_SETTINGS,
    undefined,
    undefined,
    true,
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      enabled: !disabled,
    },
  )

export const useGetScreeningSettings = () =>
  useFetch<ScreeningSettingsInterface>(
    API.SCREENING_SETTINGS,
    undefined,
    undefined,
    true,
    {
      staleTime: Infinity,
      cacheTime: Infinity,
    },
  )

export const screeningSettings: RequestInterfaceNew<ScreeningSettingsInterface> = {
  get: async () => api.get(API.SCREENING_SETTINGS),
  update: async (data, { id }) =>
    apiWithoutHandling.patch(`${API.SCREENING_SETTINGS}/${id}`, data),
  submit: async data => apiWithoutHandling.post(API.SCREENING_SETTINGS, data),
}

export const useGetRequisitionSettings = () =>
  useFetch<RequisitionSettingsInterface>(
    API.REQUISITION_SETTINGS,
    undefined,
    undefined,
    true,
    {
      staleTime: Infinity,
      cacheTime: Infinity,
    },
  )

export const jobPostingSettings: RequestInterfaceNew<JobPostingSettingsInterface> = {
  get: async () => api.get(API.JOB_POSTING_SETTINGS),
  update: async (data, { id }) =>
    apiWithoutHandling.patch(`${API.JOB_POSTING_SETTINGS}/${id}`, data),
  submit: async data => apiWithoutHandling.post(API.JOB_POSTING_SETTINGS, data),
}

export const useGetJobPostingSettings = () =>
  useFetch<JobPostingSettingsInterface>(
    API.JOB_POSTING_SETTINGS,
    undefined,
    undefined,
    true,
    {
      staleTime: Infinity,
      cacheTime: Infinity,
    },
  )

export const enableLinkedinPromotedJobs = (data: LinkedinIntegrationResponseInterface) =>
  apiWithoutHandling.patch(`${API.JOB_POSTING_SETTINGS}/1/enableLinkedinPromotedJobs`, {
    integration_context: data.integrationContext,
    integration_type: data.integrationType,
    tenant_type: data.tenantType,
  })

export const getLinkedinApiKey = () =>
  api.get<{ client_id: string }>(`/linkedin/applicationConfig/getConfig`)

export const applicationFormSettings: RequestInterfaceNew<ApplicationFormSettingsInterface> =
  {
    get: async () => api.get(API.APPLICATION_FORM_SETTINGS),
    update: async (data, { id }) =>
      apiWithoutHandling.patch(`${API.APPLICATION_FORM_SETTINGS}/${id}`, data),
    submit: async data => apiWithoutHandling.post(API.APPLICATION_FORM_SETTINGS, data),
  }

export const useGetJobPostingsApplicationFormSettings = () =>
  useFetch<ApplicationFormSettingsInterface>(
    API.APPLICATION_FORM_SETTINGS,
    undefined,
    undefined,
    true,
    {
      staleTime: Infinity,
      cacheTime: Infinity,
    },
  )

export const careerPageSettings: RequestInterfaceNew<CareerPageSettingsInterface> = {
  get: async () => api.get(API.CAREER_PAGE_SETTINGS),
  update: async (data, { id }) =>
    apiWithoutHandling.patch(`${API.CAREER_PAGE_SETTINGS}/${id}`, data),
  submit: async data => apiWithoutHandling.post(API.CAREER_PAGE_SETTINGS, data),
}

export const useGetCareerSettings = () =>
  useFetch<CareerPageSettingsInterface>(API.CAREER_PAGE_SETTINGS)

export const offersSettings: RequestInterfaceNew<OfferSettingsInterface> = {
  get: async () => api.get(API.OFFER_SETTINGS),
  update: async (data, { id }) =>
    apiWithoutHandling.patch(`${API.OFFER_SETTINGS}/${id}`, data),
  submit: async data => apiWithoutHandling.post(API.OFFER_SETTINGS, data),
}

export const payrollSettingsRequests: RequestInterfaceNew<PayrollSettingsInterface> = {
  get: async () => api.get(API.PAYROLL_SETTINGS),
  update: async (data, { id }) =>
    apiWithoutHandling.patch(`${API.PAYROLL_SETTINGS}/${id}`, data),
  submit: async data => apiWithoutHandling.post(API.PAYROLL_SETTINGS, data),
}

export const useGetPayrollSettings = () =>
  useFetch<PayrollSettingsInterface>(API.PAYROLL_SETTINGS, undefined, undefined, true, {
    staleTime: Infinity,
    cacheTime: Infinity,
  })

export const helpCenterSettings: RequestInterfaceNew<HelpCenterSettingsInterface> = {
  get: async () => api.get(API.HELP_CENTER_SETTINGS),
  update: async (data, { id }) =>
    apiWithoutHandling.patch(`${API.HELP_CENTER_SETTINGS}/${id}`, data),
  submit: async data => apiWithoutHandling.post(API.HELP_CENTER_SETTINGS, data),
}

export const useGetHelpCenterSettings = (disabled?: boolean) =>
  useFetch<HelpCenterSettingsInterface>(
    API.HELP_CENTER_SETTINGS,
    undefined,
    undefined,
    true,
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      enabled: !disabled,
    },
  )

export const useGetKeyPersonsSettings = () => {
  return useFetch<KeyPersonsSettingsInterface>(
    API.KEY_PERSONS_SETTINGS,
    undefined,
    undefined,
    true,
    {
      staleTime: Infinity,
      cacheTime: Infinity,
    },
  )
}

export const useGetOfferSettings = () =>
  useFetch<OfferSettingsInterface>(API.OFFER_SETTINGS, undefined, undefined, true, {
    staleTime: Infinity,
    cacheTime: Infinity,
  })

export const keyPersonsSettings: RequestInterfaceNew<KeyPersonsSettingsInterface> = {
  get: async () => api.get(API.KEY_PERSONS_SETTINGS),
  update: async (data, { id }) =>
    apiWithoutHandling.patch(`${API.KEY_PERSONS_SETTINGS}/${id}`, data),
  submit: async data => apiWithoutHandling.post(API.KEY_PERSONS_SETTINGS, data),
}

export const uploadUserAvatar = (file: File) => {
  const fileFormData = new FormData()
  fileFormData.append('file', file, file.name)
  fileFormData.append('category', 'user_avatar')

  return apiWithoutHandling.post<FileInterface>(API.USER_AVATAR, fileFormData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export const deleteUserAvatar = (id: number) => api.delete(`${API.USER_AVATAR}/${id}`)

export const useGetUserAvatar = (enabled = true) =>
  useFetch<FileInterface>(
    enabled ? API.USER_AVATAR : null,
    undefined,
    undefined,
    undefined,
    {
      cacheTime: Infinity,
      staleTime: Infinity,
    },
  )

export const countryHiringRequests: TableRequestInterface<CountryHiringInterface> = {
  getItems: async ({ sortBy, filters, page }) =>
    api.get(API.COUNTRY_HIRING_SETTINGS, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    }),
}

export const countryHiringRequestsNew: RequestInterfaceNew<CountryHiringInterface> = {
  get: async ({ id }) => api.get(`${API.COUNTRY_HIRING_SETTINGS}/${id}`),
  update: async (data, { id }) => api.patch(`${API.COUNTRY_HIRING_SETTINGS}/${id}`, data),
  submit: async (data, { id }) => api.patch(`${API.COUNTRY_HIRING_SETTINGS}/${id}`, data),
}

export const compensationSettingsRequests: RequestInterfaceNew<CompensationSettings> = {
  get: async () => api.get(API.COMPENSATION_SETTINGS),
  update: async (data, { id }) =>
    apiWithoutHandling.patch(`${API.COMPENSATION_SETTINGS}/${id}`, data),
  submit: async data => apiWithoutHandling.post(API.COMPENSATION_SETTINGS, data),
}
