import React from 'react'
import { connect } from 'lape'

import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { onboardingSteps } from '@src/interfaces/onboarding'
import { navigateTo } from '@src/actions/RouterActions'
import { PageBody } from '@src/components/Page/PageBody'
import { NextButton, StartPageView, TabProps } from '../common'
import { PageActions } from '@src/components/Page/PageActions'
import { useGetLifecycleSettings } from '@src/api/settings'

const Start = ({ data, onComplete }: TabProps) => {
  const { data: lifecycleSettings } = useGetLifecycleSettings()
  const title = lifecycleSettings?.welcome_page_title
  const videoUrl = lifecycleSettings?.welcome_page_video_url
  const text = lifecycleSettings?.welcome_page_text

  return (
    <>
      <PageBody>
        <StartPageView
          title={title}
          videoUrl={videoUrl}
          text={text}
          name={data.full_name}
          joiningDateTime={data.joining_date_time}
        />
      </PageBody>

      <PageActions>
        <NextButton
          onClickInPreviewMode={() => {
            navigateTo(pathToUrl(ROUTES.ONBOARDING.BASIC_INFO, { id: data.id }))
          }}
          afterSubmit={() => {
            navigateTo(pathToUrl(ROUTES.ONBOARDING.BASIC_INFO, { id: data.id }))
            onComplete(onboardingSteps.welcome)
          }}
          elevated
        >
          Let's start
        </NextButton>
      </PageActions>
    </>
  )
}

export default connect(Start)
