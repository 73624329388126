import React from 'react'
import styled from 'styled-components'
import DOMPurify from 'dompurify'
import { Text } from '@revolut/ui-kit'

const StyledSubtitle = styled(Text)`
  p {
    margin: 0;
  }

  ul,
  ol {
    padding: 0 0 0 40px;
    margin: 0;
  }
`

type Props = {
  value?: string | React.ReactNode
}

const SectionSubtitle = ({ value }: Props) => {
  if (!value) {
    return null
  }

  if (typeof value === 'string') {
    return (
      <StyledSubtitle
        use="div"
        variant="secondary"
        color="grey-tone-50"
        style={{ wordBreak: 'break-word' }}
        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(value) }}
      />
    )
  }

  return (
    <Text
      use="div"
      variant="secondary"
      color="grey-tone-50"
      style={{ wordBreak: 'break-word' }}
    >
      {value}
    </Text>
  )
}

export default SectionSubtitle
