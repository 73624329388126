import { scaleLinear } from '@visx/scale'
import { Token } from '@revolut/ui-kit'

import { ChartLines, LinesData, YAxisPoints } from './types'

const SAMPLE_COLORS = [
  Token.color.green,
  Token.color.red,
  Token.color.orange,
  Token.color.blue,
  Token.color.pink,
]

export const getLinesData = <T>(dataSet: T[], lines: ChartLines<T>) => {
  return Object.entries(lines).reduce<LinesData<T>>((acc, [key, line], idx) => {
    const lineData = dataSet
      .map(dataPoint => {
        return line.accessor(dataPoint)
      })
      .filter(dataPoint => typeof dataPoint === 'number')

    if (!lineData.length) {
      return acc
    }
    acc.push({
      name: line.name,
      color: line.color || SAMPLE_COLORS[idx],
      data: lineData,
      key,
    })
    return acc
  }, [])
}

export const getYPointsByIndex = <T>(
  linesData: LinesData<T>,
  yScale: ReturnType<typeof scaleLinear>,
  idx: number,
): YAxisPoints => {
  return linesData.map(({ key, name, data, color }) => ({
    key,
    name,
    color,
    top: yScale(data[idx]) as number,
  }))
}

export const getNormalizedYAxisPoints = (
  points: YAxisPoints,
  lineHeightOffset: number = 0,
) => {
  return points.reduce<YAxisPoints>((acc, point, idx) => {
    const lineHeightAlignedTop = point.top - lineHeightOffset * idx

    if (lineHeightAlignedTop < 0) {
      acc.push({ ...point, top: 0 })
    } else {
      const prevSummaryOffset = acc
        .slice(0, idx)
        .reduce((sum, value) => sum + value.top, 0)
      const offsetAlignedTop = lineHeightAlignedTop - prevSummaryOffset
      acc.push({ ...point, top: offsetAlignedTop < 0 ? 0 : offsetAlignedTop })
    }
    return acc
  }, [])
}

export const defaultTickLabelPropsX = () => ({
  fill: Token.color.greyTone50,
  fontSize: 11,
  textAnchor: 'middle' as const,
})

export const defaultTickLabelPropsY = () => ({
  fill: Token.color.greyTone50,
  fontSize: 11,
  textAnchor: 'end' as const,
})
