import React, { useMemo, useState } from 'react'
import { FilterType, RowInterface } from '@src/interfaces/data'
import AdjustableTable from '@components/Table/AdjustableTable'
import { useTable } from '@components/Table/hooks'
import { FilterButton, MoreBar, chain, TableWidget } from '@revolut/ui-kit'
import { crmTableRequests } from '@src/api/CRM'
import {
  crmNameColumn,
  crmSpecialisationColumn,
  crmSeniorityColumn,
  crmTagsColumn,
  crmCurrentCompanyColumn,
  crmYearsOfExperienceColumn,
  crmPreferredLocationsColumn,
  crmCurrentCountryColumn,
  crmStatusColumn,
  crmArchivalReasonColumn,
  crmDesiredSalaryColumn,
  crmLastEngagementDateColumn,
  crmArchivalDateColumn,
  crmReEngagementDateColumn,
  crmReEngagementStatusColumn,
} from '@src/constants/columns/CRM'
import { TableNames } from '@src/constants/table'
import { getSelectCellConfig } from '@src/components/Table/AdvancedCells/SelectCell/SelectCell'
import { FiltersSidebarItemInterface } from '@src/components/FiltersSidebar/FiltersSidebarItem'
import FiltersSidebar, {
  getAppliedFiltersCount,
} from '@src/components/FiltersSidebar/FiltersSidebar'
import { selectorKeys } from '@src/constants/api'
import SearchTable from '@src/components/Table/SearchTable/SearchTable'
import { CRMInterface } from '@src/interfaces/CRM'
import { useGetSelectors } from '@src/api/selectors'
import { useGetCandidateSettings } from '@src/api/settings'
import SendEngagementEmail from '@src/pages/Recruitment/RecruitmentSubTabs/CRM/SendEngagementEmail'
import SelectTableWrapper, {
  SelectTableWrapperOnChangeData,
} from '@src/components/Table/AdvancedCells/SelectCell/SelectTableWrapper'
import { renderOptionFilterSidebarItem } from '@src/components/JobPostingOption/JobPostingOption'

const useRow = () => {
  const { data: archivedReasonsOptions } = useGetSelectors(
    selectorKeys.interview_round_archived_reasons,
  )
  const row: RowInterface<CRMInterface> = {
    cells: [
      getSelectCellConfig(),
      { ...crmNameColumn, width: 250 },
      { ...crmSpecialisationColumn, width: 250 },
      { ...crmSeniorityColumn, width: 100 },
      { ...crmTagsColumn, width: 180 },
      { ...crmCurrentCompanyColumn, width: 150 },
      { ...crmYearsOfExperienceColumn, width: 120 },
      { ...crmPreferredLocationsColumn, width: 150 },
      { ...crmCurrentCountryColumn, width: 150 },
      { ...crmStatusColumn, width: 120 },
      {
        ...crmArchivalReasonColumn,
        width: 120,
        insert: ({ data }) => {
          const archivedReason = archivedReasonsOptions?.find(
            ({ id }) => id === data.archived_reason,
          )
          return archivedReason ? archivedReason.name : '-'
        },
      },
      { ...crmDesiredSalaryColumn, width: 140 },
      { ...crmLastEngagementDateColumn, width: 140 },
      { ...crmArchivalDateColumn, width: 120 },
      { ...crmReEngagementStatusColumn, width: 140 },
      { ...crmReEngagementDateColumn, width: 140 },
    ],
  }
  return row
}

const sidebarFilters: FiltersSidebarItemInterface[] = [
  {
    label: 'Last activity date',
    field: 'latest_activity_date_time',
    filterType: FilterType.date,
    icon: 'Calendar',
  },
  {
    label: 'Last active stage',
    field: 'latest_interview_stage__interview_type',
    filterType: FilterType.selector,
    selector: selectorKeys.interview_stage_types,
    icon: 'Target',
  },
  {
    label: 'Confidential candidates only',
    field: 'candidate__is_confidential',
    filterType: FilterType.boolean,
    icon: 'Anonymous',
  },
  {
    label: 'Sourced by',
    field: 'source',
    filterType: FilterType.selector,
    selector: selectorKeys.employee,
    icon: 'LogoLinkedIn',
  },
  {
    label: 'Job posting',
    field: 'application__job_posting',
    filterType: FilterType.selector,
    selector: selectorKeys.job_postings_locations_type,
    renderOption: renderOptionFilterSidebarItem,
    icon: 'Document',
  },
  {
    label: 'Hiring manager',
    field: 'hiring_manager',
    filterType: FilterType.selector,
    selector: selectorKeys.employee,
    icon: 'Profile',
  },
  {
    label: 'Recruiter',
    field: 'recruiter',
    filterType: FilterType.selector,
    selector: selectorKeys.employee,
    icon: 'Profile',
  },
  {
    label: 'Right to Work',
    field: 'candidate__right_to_work',
    filterType: FilterType.selector,
    selector: selectorKeys.interview_feedback_right_to_work_choices,
    icon: 'Document',
  },
  {
    label: 'Ready to relocate',
    field: 'candidate__is_ready_to_relocate',
    filterType: FilterType.boolean,
    icon: 'Document',
  },
  {
    label: 'Eligibility to relocate',
    field: 'candidate__is_eligible_to_relocate',
    filterType: FilterType.boolean,
    icon: 'Document',
  },
  {
    label: 'Origin',
    field: 'origin',
    filterType: FilterType.selector,
    selector: selectorKeys.candidate_origin_choices,
    icon: 'Document',
  },
  // {
  //   label: 'Desired compensation',
  //   field: 'converted_expected_salary',
  //   filterType: FilterType.range,
  //   icon: Cash,
  // },
  // {
  //   label: 'Current country',
  //   field: 'current_country',
  //   filterType: FilterType.selector,
  //   selector: selectorKeys.location,
  //   icon: LocationPin,
  // },
  // {
  //   label: 'Preferred work location',
  //   field: 'preferred_location',
  //   filterType: FilterType.selector,
  //   selector: selectorKeys.location,
  //   icon: LocationPin,
  // },
  // {
  //   label: 'Specialisation',
  //   field: 'specialisation',
  //   filterType: FilterType.selector,
  //   selector: selectorKeys.specialisations,
  //   icon: RepairTool,
  // },
  // {
  //   label: 'Current company',
  //   field: 'current_company',
  //   filterType: FilterType.text,
  //   icon: Utilities,
  // },
  // {
  //   label: 'Created date',
  //   field: 'created_date',
  //   filterType: FilterType.date,
  //   icon: TimeAndMoney,
  // },
  // {
  //   label: 'Interview round created date',
  //   field: 'interview_round_created_date',
  //   filterType: FilterType.date,
  //   icon: Time,
  // },
  // {
  //   label: 'Notice period',
  //   field: 'notice_period',
  //   filterType: FilterType.date,
  //   icon: Message,
  // },
]

type CRMSidebars = 'filters' | 'sendEmails'

const useSidebars = () => {
  const [state, setState] = useState<CRMSidebars>()
  return {
    sidebar: state,
    toggleSidebar: (sidebar: CRMSidebars) => {
      setState(sidebar === state ? undefined : sidebar)
    },
    closeSidebar: () => {
      setState(undefined)
    },
  }
}

const getSelectedIds = (
  tableData: CRMInterface[],
  selectedData?: SelectTableWrapperOnChangeData<CRMInterface>,
) => {
  let rows = selectedData?.selectedRowsData ?? []
  if (selectedData?.isAllSelected) {
    rows = tableData.filter(o => !selectedData?.excludeListIds?.has(String(o.id)))
  }
  return rows.map(o => ({
    candidateId: o.candidate.id,
    roundId: o.id,
    stageId: o?.latest_interview_stage?.id,
  }))
}

const CRMTable = () => {
  const table = useTable<CRMInterface>(crmTableRequests)
  const row = useRow()
  const { sidebar, toggleSidebar, closeSidebar } = useSidebars()
  const { data: candidateSettings } = useGetCandidateSettings()
  const [selectedData, setSelectedData] =
    useState<SelectTableWrapperOnChangeData<CRMInterface>>()
  const disabledBulkActions =
    !selectedData?.selectedRowsData.length && !selectedData?.isAllSelected
  const selectedCandidates = useMemo(
    () => getSelectedIds(table.data, selectedData),
    [selectedData, table.data],
  )
  return (
    <>
      <TableWidget>
        <TableWidget.Info />
        <TableWidget.Actions>
          <MoreBar>
            {candidateSettings?.enable_emails && (
              <MoreBar.Action
                onClick={() => {
                  toggleSidebar('sendEmails')
                }}
                disabled={disabledBulkActions}
                useIcon="Envelope"
              >
                Send engagement emails
              </MoreBar.Action>
            )}
          </MoreBar>
        </TableWidget.Actions>
        <TableWidget.Filters>
          <FilterButton
            useIcon="Filter"
            onClick={() => {
              toggleSidebar('filters')
            }}
            onClear={() => {
              table.resetFiltersAndSorting()
            }}
          >
            {chain('Filters', getAppliedFiltersCount(table.filterBy, sidebarFilters))}
          </FilterButton>
        </TableWidget.Filters>
        <TableWidget.Search>
          <SearchTable
            placeholder="Search by name, email, specialisation, current company"
            onFilter={table.onFilterChange}
            ml={0}
          />
        </TableWidget.Search>

        <TableWidget.Table>
          <SelectTableWrapper
            enabled
            onChange={setSelectedData}
            filters={table.filterBy}
            tableDataLength={table.data.length}
          >
            <AdjustableTable<CRMInterface>
              name={TableNames.CRM}
              useWindowScroll
              dataType="records"
              row={row}
              noDataMessage="Records will appear here."
              {...table}
            />
          </SelectTableWrapper>
        </TableWidget.Table>
      </TableWidget>

      <FiltersSidebar
        open={sidebar === 'filters'}
        onClose={closeSidebar}
        items={sidebarFilters}
        filters={table.filterBy}
        onFilter={table.onFilterChange}
      />
      {candidateSettings?.enable_emails && (
        <SendEngagementEmail
          candidates={selectedCandidates}
          open={sidebar === 'sendEmails'}
          onClose={closeSidebar}
        />
      )}
    </>
  )
}

export default CRMTable
