import React from 'react'
import { MoreBar, useTooltip, Tooltip } from '@revolut/ui-kit'
import { ArrowSend } from '@revolut/icons'
import { AnalyticsEvents, useAnalytics } from '@src/utils/analytics'
import {
  clearCVScreeningSession,
  navigateToCVScreening,
  setCVScreeningSession,
} from '@src/pages/Forms/CVScreening/utils'
import { useLocation } from 'react-router-dom'
import { CandidateInterface } from '@src/interfaces/interviewTool'

type Props = {
  candidates: CandidateInterface[]
  disabled: boolean
}

const ScreenCandidatesButton = ({ candidates, disabled }: Props) => {
  const { sendAnalyticsEvent } = useAnalytics()
  const location = useLocation()
  const tooltip = useTooltip()

  return (
    <>
      <MoreBar.Action
        {...tooltip.getAnchorProps()}
        onClick={() => {
          if (!disabled) {
            sendAnalyticsEvent(AnalyticsEvents.click_screen_candidates_btn)
            clearCVScreeningSession()
            setCVScreeningSession({
              candidates: candidates.map(item => ({ id: item.id, name: item.full_name })),
              referrerPath: `${location.pathname}${location.search}`,
            })
            navigateToCVScreening(candidates[0]?.id, location)
          }
        }}
        aria-disabled={disabled}
        useIcon={ArrowSend}
      >
        Screen candidates
      </MoreBar.Action>
      {disabled && (
        <Tooltip {...tooltip.getTargetProps()}>
          Select candidates on CV Screening stage
        </Tooltip>
      )}
    </>
  )
}

export default ScreenCandidatesButton
