import React from 'react'
import { Flex, Token, VStack } from '@revolut/ui-kit'
import Favourites from '@components/Favourites/Favourites'
import { DashboardToDo } from '@src/pages/Home/HomeSubTabs/Dashboard/DashboardToDo'
import { PageBody } from '@components/Page/PageBody'
import Announcements from '@src/pages/Home/HomeSubTabs/Dashboard/components/Announcements/Announcements'
import { useGetOnboardingCheckpoints } from '@src/api/onboardingChecklistV2'
import { checkIfFirstStepsCompleted } from '@src/pages/OnboardingChecklistV2/common/helpers'
import PageLoading from '@src/components/PageLoading/PageLoading'
import { ProgressBanner } from '@src/pages/OnboardingChecklistV2/LandingPage/ProgressBanner'
import { ChecklistSection } from '@src/pages/OnboardingChecklistV2/LandingPage/ChecklistSection'
import { onboardingCheckpointsSectionsConfig } from '@src/pages/OnboardingChecklistV2/common/checkpointsConfig'
import { useIsOnboardingDashboardEnabled } from '@src/pages/OnboardingChecklistV2/common/hooks'

const OnboardingChecklist = () => {
  const { checkpoints, isLoading } = useGetOnboardingCheckpoints()
  const firstStepsCompleted = checkIfFirstStepsCompleted(checkpoints)

  if (isLoading) {
    return <PageLoading />
  }

  return (
    <>
      <PageBody>
        <VStack space="s-16">
          <ProgressBanner />
          {firstStepsCompleted ? (
            <>
              <ChecklistSection
                title={onboardingCheckpointsSectionsConfig.performance.title}
                configs={onboardingCheckpointsSectionsConfig.performance.configs}
              />
              <ChecklistSection
                title={onboardingCheckpointsSectionsConfig.recruitment.title}
                configs={onboardingCheckpointsSectionsConfig.recruitment.configs}
              />
              <ChecklistSection
                title={onboardingCheckpointsSectionsConfig.hr.title}
                configs={onboardingCheckpointsSectionsConfig.hr.configs}
              />
              <ChecklistSection
                title={onboardingCheckpointsSectionsConfig.launch.title}
                configs={onboardingCheckpointsSectionsConfig.launch.configs}
              />
            </>
          ) : (
            <ChecklistSection
              canPostpone={false}
              title={onboardingCheckpointsSectionsConfig.firstSteps.title}
              configs={onboardingCheckpointsSectionsConfig.firstSteps.configs}
            />
          )}
        </VStack>
      </PageBody>
    </>
  )
}

const Dashboard = () => {
  const { enabled, isLoading } = useIsOnboardingDashboardEnabled()

  if (isLoading) {
    return <PageLoading />
  }

  return (
    <PageBody maxWidth={Token.breakpoint.xl}>
      {enabled ? (
        <OnboardingChecklist />
      ) : (
        <Flex
          gap="s-16"
          flexDirection="column"
          overflow={{ all: 'hidden', md: 'visible' }}
        >
          <Announcements />
          <DashboardToDo />
          <Favourites />
        </Flex>
      )}
    </PageBody>
  )
}

export default Dashboard
