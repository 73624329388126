import { Delete, LinkExternal } from '@revolut/icons'
import {
  Action,
  Color,
  DetailsCell,
  DetailsSkeleton,
  ExpandableCell,
  Link,
  Text,
  VStack,
  chain,
} from '@revolut/ui-kit'
import { InfoIconWithTooltip } from '@src/components/Icon/InfoIconWithTooltip'
import React, { useState } from 'react'
import { Entity, EntityDetails, getModelDetails } from './entities'
import { useGetPathWithWorkspace } from '@src/actions/RouterActions'

type DependencyError = {
  model_id: number
  model: Entity
  label?: string
}

export type GroupedError = {
  model: Entity
  items: DependencyError[]
}

export const groupErrors = (errors: DependencyError[]): GroupedError[] => {
  const map = new Map<Entity, GroupedError>()
  for (let error of errors) {
    if (!map.has(error.model)) {
      map.set(error.model, {
        model: error.model,
        items: [error],
      })
    } else {
      map.get(error.model)?.items.push(error)
    }
  }

  return [...map.values()]
}

const Item = ({
  href,
  label,
  onDelete,
}: {
  href?: string
  label: string
  onDelete?: () => Promise<void>
}) => {
  const getPathWithWorkspace = useGetPathWithWorkspace()
  const [status, setStatus] = useState<'active' | 'pending' | 'failed'>('active')
  const titleProps = href
    ? {
        use: Link,
        href: getPathWithWorkspace(href),
        target: '_blank',
      }
    : {}
  return status === 'pending' ? (
    <DetailsSkeleton />
  ) : (
    <DetailsCell pr={0} pl={0}>
      <DetailsCell.Title {...titleProps}>
        <Text variant="caption">{label}</Text>
      </DetailsCell.Title>
      {onDelete && (
        <DetailsCell.Content>
          {status === 'active' && (
            <Action
              iconOnly
              useIcon={Delete}
              color={Color.GREY_TONE_50}
              onClick={async () => {
                setStatus('pending')
                try {
                  await onDelete()
                  setStatus('active')
                } catch (err) {
                  setStatus('failed')
                }
              }}
            />
          )}
          {status === 'failed' && (
            <InfoIconWithTooltip
              tooltipProps={{ width: '270px' }}
              color={Color.WARNING}
              content="Item could not be deleted from here. Please try from its summary page."
            />
          )}
        </DetailsCell.Content>
      )}
    </DetailsCell>
  )
}

type FailedEntitiesWithDetails = {
  [key in Entity]: EntityDetails
}

export const DependantEntities = ({
  entityId,
  details,
  prefix,
}: {
  entityId: number
  details: GroupedError[]
  prefix?: string
}) => {
  const getPathWithWorkspace = useGetPathWithWorkspace()
  const [dependencies] = useState<FailedEntitiesWithDetails>(
    details.reduce((acc, group) => {
      const modelDetails = getModelDetails(group.model, {
        entityId,
        items: group.items,
        prefix,
      })
      if (modelDetails) {
        acc[group.model] = modelDetails
      }

      return acc
    }, {} as FailedEntitiesWithDetails),
  )

  return (
    <VStack space="s-16">
      {Object.values(dependencies).map(
        ({ title, items, listUrl, itemUrl, handleDelete }) => {
          return items.length !== 0 ? (
            <ExpandableCell key={title}>
              <ExpandableCell.Title>{chain(title, items.length)}</ExpandableCell.Title>
              {!!listUrl && (
                <ExpandableCell.Content>
                  <Action
                    use={Link}
                    href={getPathWithWorkspace(listUrl)}
                    target="_blank"
                    iconOnly
                    useIcon={LinkExternal}
                  />
                </ExpandableCell.Content>
              )}
              <ExpandableCell.Note>
                {items.slice(0, 5).map(item => (
                  <Item
                    key={item.model_id}
                    href={itemUrl ? itemUrl(item.model_id) : undefined}
                    label={item.label || `# ${item.model_id}`}
                    onDelete={
                      handleDelete ? () => handleDelete(item.model_id) : undefined
                    }
                  />
                ))}
                {items.length > 5 && listUrl && (
                  <Action
                    use={Link}
                    href={getPathWithWorkspace(listUrl)}
                    target="_blank"
                    useEndIcon={LinkExternal}
                  >
                    View more
                  </Action>
                )}
              </ExpandableCell.Note>
            </ExpandableCell>
          ) : null
        },
      )}
    </VStack>
  )
}
