import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { VStack, InputGroup, Text, Box, Token, Cell } from '@revolut/ui-kit'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { PageWrapper } from '@components/Page/Page'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { PageBody } from '@components/Page/PageBody'
import { PageActions } from '@components/Page/PageActions'
import { useLapeContext } from '@src/features/Form/LapeForm'
import {
  EngagementDriverInterface,
  EngagementQuestionInterface,
} from '@src/interfaces/engagement'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import LapeNewTextArea from '@components/Inputs/LapeFields/LapeNewTextArea'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { navigateReplace } from '@src/actions/RouterActions'
import { selectUser } from '@src/store/auth/selectors'
import AutoStepper from '@components/Stepper/AutoStepper'
import NewStepperTitle from '@components/Stepper/NewStepperTitle'
import { selectorKeys } from '@src/constants/api'
import LapeMultiInput from '@src/components/Inputs/LapeFields/LapeMultiInput'
import LapeHTMLEditor from '@components/Inputs/LapeFields/LapeHTMLEditor'
import { defaultTheme } from '@src/styles/theme'
import { createNewKey } from '@src/components/Inputs/RadioSelectInput/RadioSelectInput'
import { CreateDriverPopup } from './CreateDriverPopup'
import { useGetSelectors } from '@src/api/selectors'
import { IdAndName } from '@src/interfaces'

type InputSectionProps = {
  insideSidebarPortal?: boolean
}

export const QuestionMainInputs = ({ insideSidebarPortal }: InputSectionProps) => {
  const zIndex = insideSidebarPortal ? defaultTheme.zIndex.sideBar + 1 : undefined

  return (
    <Cell>
      <InputGroup>
        <LapeNewTextArea
          required
          name="question_text"
          label="Question"
          rows={2}
          message="This is the main part of the question. It will be shown in a large font size and in bold, at the top of the screen."
        />
        <LapeHTMLEditor
          name="description"
          placeholder="Description"
          greyBg
          height={150}
        />
        <Box mt="-s-12" ml="s-12">
          <Text fontSize="small" color={Token.color.greyTone50}>
            You can add additional information to show under the main question text. This
            will be shown in a smaller and lighter font.
          </Text>
        </Box>
        <LapeRadioSelectInput
          required
          name="owner"
          label="Owner"
          selector={selectorKeys.employee}
          zIndex={zIndex}
        />
      </InputGroup>
    </Cell>
  )
}

export const QuestionSettingsInputs = ({ insideSidebarPortal }: InputSectionProps) => {
  const zIndex = insideSidebarPortal ? defaultTheme.zIndex.sideBar + 1 : undefined
  const { values } = useLapeContext<EngagementQuestionInterface>()
  const { data: driverOptions, refetch } = useGetSelectors<IdAndName>(
    selectorKeys.engagement_drivers,
  )

  const driverOptionsParsed = useMemo(
    () =>
      driverOptions?.map(driver => ({
        label: driver.name,
        value: {
          id: driver.id,
          name: driver.name,
        },
      })),
    [driverOptions],
  )
  const [isNewDriverPopupVisible, setNewDriverPopupVisible] = useState(false)

  const isOpenEnded = values?.type?.id === 'open_ended'

  useEffect(() => {
    if (isOpenEnded) {
      values.comment = {
        id: 'mandatory',
        name: 'Mandatory',
      }
    }
  }, [isOpenEnded])

  return (
    <>
      <Cell>
        <InputGroup>
          <VStack>
            <Text variant="primary">Settings</Text>
            <Text variant="caption" color={Token.color.greyTone50}>
              Consider the owner and category
            </Text>
          </VStack>
          <LapeRadioSelectInput
            required
            disabled={!!values?.id}
            name="type"
            label="Question type"
            selector={selectorKeys.engagement_question_types}
            message="This defines how the question will be answered (1-5 scale rating or open ended). This cannot be changed later."
            zIndex={zIndex}
          />
          <LapeRadioSelectInput
            required
            showCreateNewButton
            onChange={(val: IdAndName | null | { id: 'create-new' }) => {
              if (val?.id === createNewKey) {
                setNewDriverPopupVisible(true)
              } else if (val) {
                // @ts-expect-error just need a name and id here not entire interface
                values.driver = val
              }
            }}
            name="driver"
            label="Category"
            options={driverOptionsParsed || []}
            message="Define category with which the question will be linked."
            zIndex={zIndex}
          />
          <LapeRadioSelectInput
            required
            disabled={isOpenEnded}
            name="comment"
            label="Comment"
            selector={selectorKeys.engagement_question_comment_options}
            zIndex={zIndex}
          />
          {isOpenEnded && (
            <LapeMultiInput
              title="Answer categories"
              description="You can define below which categories employees can select when answering the question. If you leave this blank, the category selector will not appear in the survey when this question is answered."
              name="categories.names"
              valueFieldName={undefined}
              withCell
            />
          )}
        </InputGroup>
      </Cell>
      <CreateDriverPopup
        open={isNewDriverPopupVisible}
        onClose={(newCategory?: EngagementDriverInterface) => {
          refetch()
          setNewDriverPopupVisible(false)
          if (newCategory) {
            values.driver = newCategory
          }
        }}
      />
    </>
  )
}

export const QuestionForm = () => {
  const { values } = useLapeContext<EngagementQuestionInterface>()
  const user = useSelector(selectUser)

  useEffect(() => {
    if (!values.owner) {
      values.owner = { id: user.id, full_name: user.full_name }
    }
  }, [])

  const title = values.id ? 'Edit question' : 'Add new question'

  return (
    <PageWrapper>
      <PageHeader title={title} backUrl={ROUTES.APPS.ENGAGEMENT.QUESTIONS_TABLE} />
      <PageBody>
        <AutoStepper>
          <NewStepperTitle title="Define question" />
          <QuestionMainInputs />
          <NewStepperTitle title="Settings" />
          <QuestionSettingsInputs />
        </AutoStepper>
      </PageBody>
      <PageActions>
        <NewSaveButtonWithPopup
          useValidator
          successText={
            values.id ? 'Question successfully updated' : 'Question successfully added'
          }
          onAfterSubmit={res => {
            navigateReplace(
              pathToUrl(ROUTES.APPS.ENGAGEMENT.QUESTION.PREVIEW, { id: res.id }),
            )
          }}
        >
          Save
        </NewSaveButtonWithPopup>
      </PageActions>
    </PageWrapper>
  )
}
