import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import ProbationGoals from '@src/pages/Forms/ProbationOverview/ProbationGoals/ProbationGoals'
import ProbationKPI from '@src/pages/Forms/ProbationOverview/ProbationGoals/ProbationKPI'
import { EmployeeInterface } from '@src/interfaces/employees'
import { employeesRequestsNew } from '@src/api/employees'
import Loader from '@components/CommonSC/Loader'
import { useGetPerformanceSelector } from '@src/api/performance'
import { useGetProbationCheckpoints } from '@src/api/probationReview'
import { PageWrapper } from '@components/Page/Page'
import { GenericGoals } from '@src/pages/Forms/ProbationOverview/ProbationGoals/GenericGoals'
import { ReviewCategory } from '@src/interfaces/performance'

export const ProbationLayoutGoals = () => {
  const params = useParams<{ employeeId: string; cycleId: string }>()
  const { employeeId, cycleId } = params
  const employeeIdValid = employeeId && !isNaN(parseFloat(employeeId))
  const [data, setData] = useState<EmployeeInterface>()
  const [loading, setLoading] = useState(employeeIdValid)
  const { data: checkpoints, refetch } = useGetProbationCheckpoints(
    employeeIdValid ? +employeeId : null,
    cycleId,
  )
  const performanceSelector = useGetPerformanceSelector(+employeeId)
  const deliveryType = performanceSelector.data?.find(
    item => item.id === cycleId,
  )?.probation_reviews_deliverables_type

  useEffect(() => {
    if (employeeId && !isNaN(parseFloat(employeeId))) {
      employeesRequestsNew.get({ id: employeeId }).then(res => {
        setData(res.data)
        setLoading(false)
      })
    }
  }, [])

  if (loading) {
    return (
      <PageWrapper>
        <Loader />
      </PageWrapper>
    )
  }

  if (!data) {
    return null
  }

  return (
    <GenericGoals data={data} cycleId={cycleId} category={ReviewCategory.Probation}>
      {deliveryType === 'kpi' ? (
        <ProbationKPI
          data={data}
          checkpoints={checkpoints}
          cycleId={cycleId}
          refetch={refetch}
        />
      ) : (
        <ProbationGoals
          data={data}
          checkpoints={checkpoints}
          cycleId={cycleId}
          refetch={refetch}
        />
      )}
    </GenericGoals>
  )
}
