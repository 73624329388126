import { ActionButton, Item } from '@revolut/ui-kit'
import { profileRequests } from '@src/api/employees'
import { InternalLink } from '@components/InternalLink/InternalLink'
import { ROUTES } from '@src/constants/routes'
import { EmployeeInterface } from '@src/interfaces/employees'
import { ProcessStages } from '@src/interfaces/onboarding'
import { pathToUrl } from '@src/utils/router'
import React, { useEffect, useState } from 'react'

interface Props {
  data: EmployeeInterface
}

const ONBOARDING_TABS: Record<ProcessStages, string> = {
  [ProcessStages.Start]: ROUTES.FORMS.ONBOARDING_TIMELINE.START,
  [ProcessStages.Work]: ROUTES.FORMS.ONBOARDING_TIMELINE.WORK,
  [ProcessStages.Documents]: ROUTES.FORMS.ONBOARDING_TIMELINE.DOCUMENTS,
  [ProcessStages.Contract]: ROUTES.FORMS.ONBOARDING_TIMELINE.CONTRACT,
  [ProcessStages.Screening]: ROUTES.FORMS.ONBOARDING_TIMELINE.SCREENING,
  [ProcessStages.RightToWork]: ROUTES.FORMS.ONBOARDING_TIMELINE.SCREENING,
  [ProcessStages.Finish]: ROUTES.FORMS.ONBOARDING_TIMELINE.FINISH,
}

type State = {
  id: number | null
  currentStage: ProcessStages | null
}

export const OnboardingWarning = ({ data }: Props) => {
  const [onboarding, setOnboarding] = useState<State>({
    id: null,
    currentStage: null,
  })

  useEffect(() => {
    const getPersonalInfo = async () => {
      const res = await profileRequests.get({ id: data.id.toString() })

      if (res.data.onboarding_process?.id) {
        setOnboarding({
          id: res.data.onboarding_process.id,
          currentStage: res.data.current_onboarding_stage.id,
        })
      }
    }

    getPersonalInfo()
  }, [])

  if (!onboarding.id) {
    return null
  }

  return (
    <Item>
      <Item.Content>
        <Item.Title>{data.first_name} is currently going through onboarding</Item.Title>
      </Item.Content>
      <Item.Side>
        <ActionButton
          use={InternalLink}
          to={pathToUrl(ONBOARDING_TABS[onboarding.currentStage || 1], {
            employeeId: String(data.id),
            id: onboarding.id,
          })}
        >
          Open timeline
        </ActionButton>
      </Item.Side>
    </Item>
  )
}
