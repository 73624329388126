import React from 'react'
import { Flex, Token, IconButton } from '@revolut/ui-kit'
import { NavigationBack, NavigationForward } from '@revolut/icons'
import format from 'date-fns/format'
import addMonths from 'date-fns/addMonths'

export const getInitialMonthFilter = () => {
  const date = new Date()
  date.setDate(1)
  return format(date, dateFilterFormat)
}

export const dateFilterFormat = 'yyyy-MM-dd'

interface MonthToggleProps {
  value: string | number
  onChange: (value: string) => void
}

export const MonthToggle = ({ value, onChange }: MonthToggleProps) => {
  const onMonthChange = (offset: number) => {
    const date = new Date(value)
    date.setDate(1)

    onChange(format(addMonths(date, offset), dateFilterFormat))
  }

  return (
    <Flex alignItems="flex-start" gap="s-24">
      <IconButton
        onClick={() => onMonthChange(-1)}
        useIcon={NavigationBack}
        color={Token.color.foreground}
        aria-label="previous month"
      />
      <IconButton
        onClick={() => onMonthChange(1)}
        useIcon={NavigationForward}
        color={Token.color.foreground}
        aria-label="next month"
      />
    </Flex>
  )
}
