import React from 'react'
import styled from 'styled-components'
import { Text } from '@revolut/ui-kit'

import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import { selectorKeys } from '../api'
import { RevolutersInterface } from '@src/interfaces/revoluters'
import { EmployeeInterface, EmployeeOptionInterface } from '@src/interfaces/employees'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import UserWithAvatar, {
  UserWithAvatarProps,
} from '@components/UserWithAvatar/UserWithAvatar'
import { getStatusColor } from '@components/CommonSC/General'

const Cursive = styled.div`
  font-style: italic;
`

export const employeeNameColumn: ColumnInterface<
  EmployeeInterface | RevolutersInterface
> = {
  type: CellTypes.insert,
  idPoint: 'id',
  dataPoint: 'full_name',
  sortKey: 'full_name',
  filterKey: 'id',
  dynamicHyperlinks: data => {
    return pathToUrl(ROUTES.FORMS.EMPLOYEE.PROFILE, { id: data.id })
  },
  insert: ({ data }) => {
    if (data?.full_name === 'Pending Employee') {
      return <Cursive>{data.full_name}</Cursive>
    }
    return <UserWithAvatar {...data} status={undefined} />
  },
  selectorsKey: selectorKeys.all_employees,
  title: 'Name',
}

export const ownerFullNameColumn: ColumnInterface<{}> = {
  type: CellTypes.text,
  idPoint: 'owner.id',
  dataPoint: 'owner.full_name',
  sortKey: 'owner__full_name',
  filterKey: 'owner__id',
  selectorsKey: selectorKeys.employee,
  title: 'Created By',
}

export const ownerNameColumn: ColumnInterface<{
  owner?: EmployeeOptionInterface
}> = {
  type: CellTypes.insert,
  idPoint: 'owner.id',
  dataPoint: 'owner.name',
  sortKey: 'owner__full_name',
  filterKey: 'owner__id',
  selectorsKey: selectorKeys.employee,
  title: 'Owner',
  insert: ({ data }) => (data.owner ? <UserWithAvatar {...data.owner} /> : '-'),
}

export const ownerNameWithAvatarColumn: ColumnInterface<{
  owner?: UserWithAvatarProps
}> = {
  type: CellTypes.insert,
  idPoint: 'owner.id',
  dataPoint: 'owner.name',
  sortKey: 'owner__full_name',
  filterKey: 'owner__id',
  selectorsKey: selectorKeys.employee,
  title: 'Owner',
  insert: ({ data }) => <UserWithAvatar {...data.owner} status={undefined} />,
}

export const recruiterNameColumn: ColumnInterface<{
  recruiter?: EmployeeOptionInterface
}> = {
  type: CellTypes.insert,
  idPoint: 'recruiter.id',
  dataPoint: 'recruiter.full_name',
  sortKey: 'recruiter__full_name',
  filterKey: 'recruiter__id',
  selectorsKey: selectorKeys.employee,
  title: 'Recruiter',
  insert: ({ data }) => <UserWithAvatar {...data.recruiter} />,
}

export const lineManagerColumn: ColumnInterface<{
  line_manager: EmployeeOptionInterface | null
}> = {
  type: CellTypes.insert,
  idPoint: 'line_manager.id',
  dataPoint: 'line_manager.full_name',
  sortKey: 'line_manager__full_name',
  filterKey: 'line_manager__id',
  selectorsKey: selectorKeys.manager,
  title: 'Line manager',
  insert: ({ data }) => <UserWithAvatar {...data.line_manager} />,
}

export const functionalManagerColumn: ColumnInterface<{}> = {
  type: CellTypes.text,
  idPoint: 'quality_control.id',
  dataPoint: 'quality_control.full_name',
  sortKey: 'quality_control__full_name',
  filterKey: 'quality_control__id',
  selectorsKey: selectorKeys.manager,
  title: 'Functional Manager',
}

export const teamOwnerColumn: ColumnInterface<{
  team_owner: EmployeeOptionInterface
}> = {
  type: CellTypes.insert,
  idPoint: 'team_owner.id',
  dataPoint: 'team_owner.full_name',
  sortKey: 'team_owner__full_name',
  filterKey: 'team_owner__id',
  selectorsKey: selectorKeys.employee,
  title: 'Owner',
  insert: ({ data }) => {
    return <UserWithAvatar {...data.team_owner} />
  },
}

export const roleManagerColumn: ColumnInterface<{
  role_manager: EmployeeOptionInterface
}> = {
  type: CellTypes.insert,
  idPoint: 'role_manager.id',
  dataPoint: 'role_manager.full_name',
  sortKey: 'role_manager__full_name',
  filterKey: 'role_manager__id',
  selectorsKey: selectorKeys.employee,
  title: 'Owner',
  insert: ({ data }) => <UserWithAvatar {...data.role_manager} />,
}

export const qualityControlRevolutersColumn: ColumnInterface<RevolutersInterface> = {
  type: CellTypes.insert,
  idPoint: 'quality_control.id',
  dataPoint: 'quality_control.name',
  sortKey: 'quality_control__full_name',
  filterKey: 'quality_control__id',
  selectorsKey: selectorKeys.manager,
  title: 'Functional manager',
  insert: ({ data }) => <UserWithAvatar {...data.quality_control} />,
}

export const employeeNameIdStatusColumn: ColumnInterface<
  EmployeeInterface | RevolutersInterface
> = {
  type: CellTypes.insert,
  idPoint: 'status.id',
  dataPoint: 'status.name',
  sortKey: 'status',
  filterKey: 'status',
  selectorsKey: selectorKeys.employee_status_pending,
  title: 'Status',
  insert: ({ data }) => (
    <Text color={getStatusColor(data.status.id)}>{data.status.name}</Text>
  ),
}

export const employeeStartedAtColumn: ColumnInterface<{}> = {
  type: CellTypes.date,
  idPoint: 'joining_date_time',
  dataPoint: 'joining_date_time',
  sortKey: 'joining_date_time',
  filterKey: 'joining_date_time',
  selectorsKey: selectorKeys.none,
  title: 'Start date',
}

export const employeeEntityColumn: ColumnInterface<RevolutersInterface> = {
  type: CellTypes.text,
  idPoint: 'entity.id',
  dataPoint: 'entity.name',
  sortKey: 'entity__name',
  filterKey: 'entity__id',
  selectorsKey: selectorKeys.entity,
  title: 'Entity',
}

export const employeeStatusColumn: ColumnInterface<
  EmployeeInterface | RevolutersInterface
> = {
  type: CellTypes.insert,
  idPoint: 'status.id',
  dataPoint: 'status.name',
  sortKey: 'status',
  filterKey: 'status',
  selectorsKey: selectorKeys.employee_status_pending,
  title: 'Status',
  insert: ({ data }) => (
    <Text color={getStatusColor(data.status.id)}>{data.status.name}</Text>
  ),
}

export const employeeTypeColumn: ColumnInterface<{}> = {
  type: CellTypes.text,
  idPoint: 'employee_type.id',
  dataPoint: 'employee_type.name',
  sortKey: 'employee_type',
  filterKey: 'employee_type',
  selectorsKey: selectorKeys.employee_types,
  title: 'Type',
}

export const eligibleEmployeeTypeColumn: ColumnInterface<{}> = {
  type: CellTypes.text,
  idPoint: 'employee_type',
  dataPoint: 'employee_type',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Type',
}

export const eligibleEmployeeContractColumn: ColumnInterface<{}> = {
  type: CellTypes.text,
  idPoint: 'contract_type.id',
  dataPoint: 'contract_type.name',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Contract',
}

export const eligibleEmployeeLocationColumn: ColumnInterface<{}> = {
  type: CellTypes.text,
  idPoint: 'location.id',
  dataPoint: 'location.location_name',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Header',
}

export const eligibleEmployeeStartedAtColumn: ColumnInterface<{}> = {
  type: CellTypes.date,
  idPoint: 'joining_date_time',
  dataPoint: 'joining_date_time',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Start date',
}

export const employeeCountryColumn: ColumnInterface<{}> = {
  type: CellTypes.text,
  idPoint: 'country.id',
  dataPoint: 'country.name',
  sortKey: null,
  filterKey: 'location__country__id',
  selectorsKey: selectorKeys.countries,
  title: 'Country',
}
