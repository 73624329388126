import React from 'react'
import pluralize from 'pluralize'

import { Box, Color, Flex } from '@revolut/ui-kit'
import AdjustableTable from '@components/Table/AdjustableTable'
import { IdAndName } from '@src/interfaces'
import {
  EngagementResultInterface,
  EngagementResultsScope,
  QuestionResultStatsInterface,
} from '@src/interfaces/engagement'
import { TableNames } from '@src/constants/table'
import { RowInterface } from '@src/interfaces/data'
import { useTableReturnType } from '@src/components/Table/hooks'
import {
  engagementResultsAverageDistributionColumn,
  engagementResultsAverageScoreColumn,
  engagementResultsBenchmarkColumn,
  engagementResultsAnswersColumn,
  engagementResultsNpsDistributionColumn,
  engagementResultsNpsScoreColumn,
  engagementResultsQuestionDriverNameColumn,
  engagementResultsQuestionNameColumn,
  engagementResultsQuestionTypeColumn,
  engagementResultsTrendAverageScoreColumn,
  engagementResultsTrendNpsScoreColumn,
} from '@src/constants/columns/engagementResults'
import { ResultsTableAnonymised } from '../components/ResultsTableAnonymised'
import { scopeToString } from '../helpers'

const trendColumnTooltip = (
  <Box color={Color.BACKGROUND} p="s-8" minWidth={250}>
    This indicates how much the score for this question has increased or decreased since
    the last time it was asked to employees
  </Box>
)

const getRow = (
  isAverage: boolean,
  scope?: EngagementResultsScope,
  canViewComments?: boolean,
): RowInterface<EngagementResultInterface> => ({
  cells: [
    { ...engagementResultsQuestionNameColumn, width: 350 },
    { ...engagementResultsQuestionTypeColumn, width: 140 },
    { ...engagementResultsQuestionDriverNameColumn, width: 250 },
    {
      ...(isAverage
        ? engagementResultsAverageScoreColumn
        : engagementResultsNpsScoreColumn),
      width: 105,
    },
    {
      ...(isAverage
        ? engagementResultsAverageDistributionColumn
        : engagementResultsNpsDistributionColumn),
      width: 300,
    },
    {
      ...(isAverage
        ? engagementResultsTrendAverageScoreColumn
        : engagementResultsTrendNpsScoreColumn),
      width: 95,
      headerTooltip: trendColumnTooltip,
    },
    ...(!!scope && scope !== EngagementResultsScope.Company
      ? [
          {
            ...engagementResultsBenchmarkColumn,
            width: 125,
            headerTooltip: scope ? (
              <Box color={Color.BACKGROUND} p="s-8" minWidth={250}>
                This indicates how your {scopeToString(scope)} score compares across all
                other {pluralize(scopeToString(scope))}
              </Box>
            ) : (
              ''
            ),
          },
        ]
      : []),
    ...(canViewComments ? [{ ...engagementResultsAnswersColumn, width: 120 }] : []),
  ],
})

type Props = {
  table: useTableReturnType<EngagementResultInterface, QuestionResultStatsInterface>
  isAverage: boolean
  setQuestion: (question: IdAndName | undefined) => void
  switchToChartTab: () => void
  scope?: EngagementResultsScope
  canViewComments?: boolean
}
export const ResultsTable = ({
  table,
  isAverage,
  setQuestion,
  switchToChartTab,
  scope,
  canViewComments,
}: Props) => {
  return (
    <Flex style={{ position: 'relative' }} flex="1 0">
      <AdjustableTable<EngagementResultInterface, QuestionResultStatsInterface>
        name={TableNames.EngagementQuestionsResults}
        useWindowScroll
        row={getRow(isAverage, scope, canViewComments)}
        {...table}
        onRowClick={row => {
          if (!row.can_show_details) {
            return
          }
          if (row.question) {
            setQuestion({ id: row.question.id, name: row.question.question_text })
            switchToChartTab()
          }
        }}
        noDataMessage="Questions results data will appear here"
        rowHeight="large"
        emptyState={
          table.stats?.can_show_details === false ? <ResultsTableAnonymised /> : undefined
        }
      />
    </Flex>
  )
}
