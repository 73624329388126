import React, { useState } from 'react'
import { Box, Color, Flex, MoreBar, Text } from '@revolut/ui-kit'
import { ReviewSummaryInterface } from '@src/interfaces/performance'
import AutoStepper from '@components/Stepper/AutoStepper'
import AutoStepperTitle from '@components/Stepper/NewStepperTitle'
import ScorecardRecommendation, {
  RecommendationTypes,
} from '@components/ScorecardRecommendation/ScorecardRecommendation'
import { relationToString } from '@src/features/Scorecard/constants'
import { useLapeContext } from '@src/features/Form/LapeForm'
import OverallFeedback from '@src/pages/Forms/EmployeePerformance/OverallFeedback'
import PerformanceViewSummarySidebar from '@src/pages/Forms/EmployeePerformance/PerformanceViewSummarySidebar'
import { useHasNewScorecards } from '@src/utils/performance'

const Summary = () => {
  const { values } = useLapeContext<ReviewSummaryInterface>()
  const [isOpenSidebar, setIsOpenSidebar] = useState(true)
  const showNewScorecards = useHasNewScorecards()

  if (!values) {
    return null
  }

  return (
    <>
      <Box>
        <Flex alignItems="center" mb="s-8">
          <Text fontWeight={500} fontSize="h5">
            Review Summary
          </Text>
        </Flex>
        <MoreBar>
          <MoreBar.Action
            onClick={() => {
              setIsOpenSidebar(true)
            }}
          >
            Open summary sidebar
          </MoreBar.Action>
        </MoreBar>
        {values.summary?.pip_extra_sections?.[0] && (
          <ScorecardRecommendation
            data={values.summary.pip_extra_sections[0]!}
            pipData={values.summary?.pip_extra_sections!}
            reviews={values.summary?.pip_extra_sections!}
            type={
              showNewScorecards ? RecommendationTypes.PIP : RecommendationTypes.PIP_V1
            }
            viewMode
          />
        )}

        <AutoStepper width={628}>
          <AutoStepperTitle noAutoStep title="Overall feedback" variant="letter" />
          {values.summary?.overall_feedbacks.map((feedback, i) => {
            const title = (
              <Text use="div" fontSize="primary" fontWeight={500} mb="s-12">
                {feedback.review.reviewer.display_name}
                {feedback.review.probation_checkpoint && (
                  <Text color={Color.GREY_TONE_50} pl="s-6">
                    CP{feedback.review.probation_checkpoint.number}
                  </Text>
                )}
                {feedback.review.reviewer_relation && (
                  <Text color={Color.GREY_TONE_50} pl="s-6">
                    ({relationToString(feedback.review.reviewer_relation, true)})
                  </Text>
                )}
              </Text>
            )

            return (
              <Box key={i} mb="s-24">
                <OverallFeedback
                  avatar={feedback.review.reviewer.avatar}
                  cons={feedback.value?.cons}
                  pros={feedback.value?.pros}
                  disabled
                  name={feedback.review.reviewer.full_name}
                  reviewerId={feedback.review.reviewer.id}
                  title={title}
                />
              </Box>
            )
          })}
        </AutoStepper>
      </Box>
      <PerformanceViewSummarySidebar
        isOpen={isOpenSidebar}
        onClose={() => {
          setIsOpenSidebar(false)
        }}
      />
    </>
  )
}

export default Summary
