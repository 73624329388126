import React from 'react'
import { useTable } from '@components/Table/hooks'
import { KpiInterface } from '@src/interfaces/kpis'
import {
  FilterByInterface,
  SORT_DIRECTION,
  SortByInterface,
  Stats,
} from '@src/interfaces/data'
import { DepartmentInterface } from '@src/interfaces/deparment'
import { Statuses } from '@src/interfaces'
import { useTableReturnType } from '@components/Table/hooks'
import { getKpiPerformanceGraph } from '@src/api/department'
import { RowInterface } from '@src/interfaces/data'
import Tooltip from '@components/Tooltip/Tooltip'
import IconButton from '@components/ButtonIcon/IconButton'
import {
  kpiCurrentValueColumn,
  kpiInitialValueColumn,
  kpiPerformanceColumn,
  kpiPerformanceColumnWithLooker,
  kpiStrategyColumn,
  kpiTargetColumn,
  kpiUnitColumn,
} from '@src/constants/columns/kpi'
import { ownerNameWithAvatarColumn } from '@src/constants/columns/employee'
import { FeatureFlags, PermissionTypes } from '@src/store/auth/types'
import { MoreBar, TableWidget, Icon } from '@revolut/ui-kit'
import AdjustableTable from '@components/Table/AdjustableTable'
import { onKPITableRowClick } from '@src/pages/EmployeeProfile/Preview/KPI/utils'
import { TableNames } from '@src/constants/table'
import { rowHighlight } from '@src/features/KPI'
import { useRenderRefreshKPIsTableNote } from '@src/utils/kpi'
import { useSelector } from 'react-redux'
import { selectFeatureFlags } from '@src/store/auth/selectors'
import { OverallProgress } from '@src/features/Goals/components/OverallProgress'
import {
  foreignGoalColumn,
  goalTargetNameColumn,
  targetsTypeColumn,
} from '@src/constants/columns/goals'
import { selectorKeys } from '@src/constants/api'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import { getCommonFilters } from './common'
import { GoalCycleFilter } from '../components/GoalCycleFilter'
import { targetsTableWithoutChildren } from '@src/api/goals'

interface StructureProps {
  data: DepartmentInterface
  table: useTableReturnType<KpiInterface, Stats>
  warnAboutMandatoryKPIs: boolean
}

const departmentRow = (allowedLooker: boolean): RowInterface<KpiInterface> => ({
  highlight: data => rowHighlight(data.target_status),
  disabled: data => data.target_status === Statuses.archived,
  cells: [
    {
      ...goalTargetNameColumn,
      width: 300,
    },
    {
      ...foreignGoalColumn,
      width: 200,
    },
    allowedLooker
      ? { ...kpiPerformanceColumnWithLooker, width: 100 }
      : {
          ...kpiPerformanceColumn,
          width: 100,
        },
    {
      ...kpiInitialValueColumn,
      width: 60,
    },
    {
      ...kpiCurrentValueColumn,
      width: 60,
    },
    {
      ...kpiTargetColumn,
      width: 60,
    },
    {
      ...kpiUnitColumn,
      width: 60,
    },
    {
      ...kpiStrategyColumn,
      width: 90,
    },
    {
      ...ownerNameWithAvatarColumn,
      width: 150,
    },
    {
      ...targetsTypeColumn,
      width: 90,
    },
  ],
})

const KPI = ({ data, table, warnAboutMandatoryKPIs }: StructureProps) => {
  const featureFlags = useSelector(selectFeatureFlags)
  const allowedLooker = featureFlags.includes(FeatureFlags.Allowed_Looker)

  const canRefreshKpis = data.field_options.permissions?.includes(
    PermissionTypes.CanRefreshKpis,
  )

  const { refreshNote, refreshButton } = useRenderRefreshKPIsTableNote(
    table,
    canRefreshKpis,
  )

  return (
    <TableWidget>
      <TableWidget.Info>
        <GoalCycleFilter
          onFilterChange={table.onFilterChange}
          columnName="cycle__id"
          selector={selectorKeys.review_cycles}
          filter={table.filterBy}
        />
        <OverallProgress
          value={table?.stats?.avg_performance}
          id={data.id}
          fetchData={getKpiPerformanceGraph}
          tooltip="Overall progress is calculated as the weighted average of all approved KPIs."
        />
        {warnAboutMandatoryKPIs && (
          <Tooltip
            placement="right"
            text="This profile has mandatory KPIs which must have 100% progress by cycle end, otherwise Overall Progress will be set to zero."
          >
            <IconButton>
              <Icon name="16/Warning" color="error" />
            </IconButton>
          </Tooltip>
        )}
      </TableWidget.Info>
      <TableWidget.Actions>
        <MoreBar>{refreshButton}</MoreBar>
      </TableWidget.Actions>
      <TableWidget.Filters />
      <TableWidget.Table>
        <AdjustableTable<KpiInterface>
          expandableType="chevron"
          name={TableNames.DepartmentKPIs}
          useWindowScroll
          dataType="KPI"
          row={departmentRow(allowedLooker)}
          {...table}
          fetchChildren={(parentIndexes, id) => {
            return table.fetchChildren(parentIndexes, id, [
              {
                filters: [{ name: `${data.id}`, id: data.id }],
                columnName: 'team__department__id',
                nonResettable: true,
              },
            ])
          }}
          onRowClick={onKPITableRowClick}
          noDataMessage="Please add your department KPIs to see them here."
          useFetchedChildren
        />
        {refreshNote}
      </TableWidget.Table>
    </TableWidget>
  )
}
interface StructureProps {
  data: DepartmentInterface
}

export const getKpiFilters = (
  data: DepartmentInterface,
  reviewCycle?: ReviewCyclesInterface,
): FilterByInterface[] => [
  ...getCommonFilters({ reviewCycle }),
  {
    filters: [{ name: `${data.id}`, id: data.id }],
    columnName: 'department__id',
    nonResettable: true,
    nonInheritable: true,
  },
]

export const kpiSorting: SortByInterface[] = [
  {
    sortBy: 'weight',
    direction: SORT_DIRECTION.ASC,
    nonResettable: true,
  },
  {
    sortBy: 'has_children',
    direction: SORT_DIRECTION.ASC,
  },
]

export const DepartmentTargets = ({
  data,
  cycle,
}: {
  data: DepartmentInterface
  cycle?: ReviewCyclesInterface
}) => {
  const table = useTable<KpiInterface, Stats>(
    targetsTableWithoutChildren,
    getKpiFilters(data, cycle),
    kpiSorting,
  )

  return (
    <KPI
      data={data}
      table={table}
      warnAboutMandatoryKPIs={!!table?.stats?.mandatory_kpi_incomplete}
    />
  )
}
