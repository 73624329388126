import { IconName } from '@revolut/ui-kit'
import { AudicenceIdType, EngagementSurveyInterface } from '@src/interfaces/engagement'
import { EngagementEditStep } from '@src/pages/Performance/Engagement/types'

export const mapAudienceIcon = (id: AudicenceIdType): IconName => {
  switch (id) {
    case 'department':
      return 'Bank'
    case 'seniority':
      return 'Trophy'
    case 'role':
      return 'Profile'
    case 'specialisation':
      return 'IndustrialGear'
    case 'function':
      return 'RepairTool'
    case 'team':
    case 'dynamic_group':
    default:
      return 'People'
  }
}

export const isStepDone = (
  step: EngagementEditStep,
  values?: Partial<EngagementSurveyInterface>,
) => {
  switch (step) {
    case 'audience':
      return !!values?.audiences?.length
    case 'basic':
      return !!(values?.title && values?.owner && values?.sent_every?.id)
    case 'notifications':
      return !!(values?.display_name && values?.description && values?.campaign)
    case 'questions':
      return !!values?.survey_questions?.length
    case 'review':
      return !!(
        values?.title &&
        values?.owner &&
        values?.sent_every?.id &&
        values?.display_name &&
        values?.description &&
        values?.campaign &&
        values?.survey_questions?.length
      )
    default:
      return false
  }
}
