import React, { useEffect, useState } from 'react'
import assign from 'lodash/assign'
import pick from 'lodash/pick'
import {
  VStack,
  Box,
  Group,
  Banner,
  Spinner,
  Cell,
  Flex,
  TextButton,
  Token,
} from '@revolut/ui-kit'

import { IdAndName } from '@src/interfaces'
import { LapeRadioSwitch } from '@src/pages/OnboardingChecklist/components/RadioSwitch'
import SectionTitle from '@src/pages/OnboardingChecklist/components/SectionTitle'
import LapeRadioSelectInput from '@src/components/Inputs/LapeFields/LapeRadioSelectInput'
import { selectorKeys } from '@src/constants/api'
import LapeNewSwitch from '@src/components/Inputs/LapeFields/LapeNewSwitch'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { TimeOffPolicyEligibilityStep } from '@src/interfaces/timeOff'
import { PolicyEditTabCommonProps } from '@src/pages/Forms/TimeOffPolicies/EditTabs/common'
import { renderIcon } from '../common'
import { AudienceTable } from '@src/features/AudienceSelection/AudienceTable'
import { TableFilter } from '../../CustomFieldsForm/FilterTable/types'
import {
  getTimeOffPolicyEligibilityCustomFiltersWithoutHandling,
  timeOffPolicyEligibilityCustomFiltersRequests,
} from '@src/api/timeOff'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { ROUTES } from '@src/constants/routes'
import { ActivePolicyDraftWarning } from '../components/ActivePolicyDraftWarning'
import { NextTabButton } from './NextTabButton'

export const PolicyEditEligibilityTab = ({
  copyFrom,
  setIsCopied,
  nextTabPath,
  onNavigateToNextTab,
}: PolicyEditTabCommonProps) => {
  const { values } = useLapeContext<TimeOffPolicyEligibilityStep>()
  // We interact with API only by the response id value (not by the url id) as they can be different here ¯\_(ツ)_/¯
  // The reason for it is that BE creates new "draft" step for editing an existing policy and applies it later
  const policyEditStepId = values.id

  useEffect(() => {
    if (!copyFrom) {
      return
    }
    if (copyFrom.custom_filters_group?.id) {
      loadCustomFiltersByGroupId(copyFrom.custom_filters_group.id)
    }

    assign(
      values,
      pick(copyFrom, [
        'apply_from_employee_start_date',
        'auto_assign_group',
        'custom_filters_group',
        'draft_eligibility_step_done',
        'draft_has_active_policy',
        'eligibility_group_type',
        'group',
      ]),
    )
    setIsCopied()
  }, [copyFrom])

  const [isLoadingCustomFilters, setIsLoadingCustomFilters] = useState(false)
  const [initialCustomFilters, setInitialCustomFilters] = useState<TableFilter>({})
  const [draftCustomFilters, setDraftCustomFilters] = useState<TableFilter>()
  const [customFilters, setCustomFilters] = useState<TableFilter>({})

  const filtersFallback = draftCustomFilters || initialCustomFilters

  const loadCustomFiltersByGroupId = (groupId: number | string) => {
    setIsLoadingCustomFilters(true)
    getTimeOffPolicyEligibilityCustomFiltersWithoutHandling(policyEditStepId, groupId)
      .then(res => {
        if (res.data?.table_filters) {
          setInitialCustomFilters(res.data.table_filters)
          setCustomFilters(res.data.table_filters)
        }
        return res
      })
      .catch(err => {
        if (err.response?.status === 404) {
          console.warn(
            'Could not find time-off policy eligibility custom filters data for an existing group',
          )
          setCustomFilters(filtersFallback)
        } else {
          throw err
        }
      })
      .finally(() => {
        setIsLoadingCustomFilters(false)
      })
  }

  useEffect(() => {
    if (values.eligibility_group_type?.id !== 'custom_choices') {
      return
    }
    if (values.custom_filters_group?.id) {
      loadCustomFiltersByGroupId(values.custom_filters_group.id)
      values.group = values.custom_filters_group
    }
  }, [values.eligibility_group_type?.id])

  return (
    <>
      {values.draft_has_active_policy && <ActivePolicyDraftWarning />}
      <VStack gap="s-16">
        <Box>
          <SectionTitle title="How should the eligible employees be assigned?" />
          <Group>
            <LapeNewSwitch
              itemTypeProps={{
                title: 'Assign policy to employees',
                description:
                  'Create a rule which assigns this work schedule to a group of employees.',
                avatar: renderIcon('IndustrialGear'),
              }}
              name="auto_assign_group"
            />
            <LapeNewSwitch
              itemTypeProps={{
                title: 'Policy applies from the employee’s start date',
                description:
                  'If an employee joined on the 1st of Jan 2021, this policy will calculate time off starting from that date. If turned off, we’ll use today’s date.',
                avatar: renderIcon('CalendarDate'),
              }}
              name="apply_from_employee_start_date"
            />
          </Group>
        </Box>
        {values.auto_assign_group && (
          <Box>
            <SectionTitle title="Who should we assign this policy to?" />
            <LapeRadioSwitch<IdAndName<string>>
              name="eligibility_group_type"
              variant="horizontal"
              options={[
                {
                  id: 'eligibility_group',
                  label: 'Groups',
                  description: (
                    <>
                      Assign employees to a pre-defined group. You can create groups{' '}
                      <TextButton to={ROUTES.APPS.GROUPS} use={InternalLink}>
                        here
                      </TextButton>
                      .
                    </>
                  ),
                  value: {
                    id: 'eligibility_group',
                    name: 'Eligibility group',
                  },
                },
                {
                  id: 'custom_choices',
                  label: 'Custom filters',
                  description: 'Assign eligibility based on simple filters.',
                  value: {
                    id: 'custom_choices',
                    name: 'Custom choices',
                  },
                },
              ]}
            />
            <Group mt="s-16">
              {values.eligibility_group_type?.id === 'eligibility_group' && (
                <>
                  <Banner>
                    <Banner.Content>
                      <Banner.Title>Groups</Banner.Title>
                      <Banner.Description>
                        Whom should this policy be applied to?
                      </Banner.Description>
                    </Banner.Content>
                  </Banner>
                  <Box p="s-8">
                    <LapeRadioSelectInput
                      name="group"
                      label="Eligibility group"
                      selector={selectorKeys.dynamic_groups}
                      clearable
                    />
                  </Box>
                </>
              )}
              {values.eligibility_group_type?.id === 'custom_choices' && (
                <Box p="s-16">
                  {isLoadingCustomFilters ? (
                    <Cell>
                      <Flex width="100%" justifyContent="center">
                        <Spinner color={Token.color.blue} />
                      </Flex>
                    </Cell>
                  ) : (
                    <AudienceTable
                      filter={customFilters}
                      onFilterChange={newFilters => {
                        setDraftCustomFilters(newFilters)
                        setCustomFilters(newFilters)
                      }}
                    />
                  )}
                </Box>
              )}
            </Group>
          </Box>
        )}
      </VStack>
      <NextTabButton
        onBeforeSubmit={async () => {
          if (values.eligibility_group_type?.id !== 'custom_choices' || !customFilters) {
            return
          }
          let groupId

          if (!values.custom_filters_group?.id) {
            const { data: newFilter } =
              await timeOffPolicyEligibilityCustomFiltersRequests.submit(
                {
                  table_filters: customFilters,
                },
                policyEditStepId,
              )
            groupId = newFilter.id
            values.custom_filters_group = newFilter
          } else {
            await timeOffPolicyEligibilityCustomFiltersRequests.update(
              {
                table_filters: customFilters,
              },
              policyEditStepId,
              values.custom_filters_group.id,
            )
            groupId = values.custom_filters_group.id
          }
          // On the final step the eligible employees are fetched by the group.id,
          // even if the source is custom filter, so to make it display correct results,
          // we need to replace the value here before proceeding to the next step
          values.group = { id: groupId } as IdAndName
        }}
        nextTabPath={nextTabPath}
        onNavigateToNextTab={onNavigateToNextTab}
      />
    </>
  )
}
