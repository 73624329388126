import React, { useState } from 'react'
import {
  IconName,
  Token,
  Banner,
  Avatar,
  Flex,
  VStack,
  HStack,
  Tag,
  Text,
  Icon,
  Color,
  MoreButton,
  useToggle,
  Spacer,
} from '@revolut/ui-kit'
import { mapSurveyRoundStatusToColor } from '@src/apps/People/Engagement/helpers'
import {
  EngagementSurveyHistoryInterface,
  EngagementSurveyInterface,
} from '@src/interfaces/engagement'
import {
  calculatePercentageBetweenDates,
  formatDate,
  formatPercentage,
  timeLeft,
} from '@src/utils/format'
import { StopRunConfirmation } from './StopRunConfirmation'
import { PermissionTypes } from '@src/store/auth/types'
import { useTestSurvey } from '@src/pages/Forms/Engagement/hooks/useTestSurvey'
import { navigateTo } from '@src/actions/RouterActions'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import { SurveyRoundForm } from './SurveyRoundForm'
import { cancelSurveyRound, closeSurveyRound } from '@src/api/engagement'
import { RoundPopupVariant } from '../types'
import { getRoundEndTime } from '../helpers/getRoundEndTime'

const mapStatusToAvatarData = (
  status: EngagementSurveyHistoryInterface['status'],
): { icon: IconName; color: Color } => {
  switch (status.id) {
    case 'completed':
      return { icon: 'Check', color: Token.color.accent }
    case 'upcoming':
      return { icon: '16/SandWatch', color: Token.color.accent }
    case 'cancelled':
    case 'terminated':
      return { icon: 'Cross', color: Token.color.greyTone20 }
    default:
      return { icon: 'TurboTransfer', color: Token.color.success }
  }
}

export const SingleRoundItem = ({
  permissions,
  surveyId,
  round,
  refetch,
}: {
  permissions: EngagementSurveyInterface['field_options']['permissions']
  surveyId: number
  refetch: VoidFunction
  round: EngagementSurveyHistoryInterface
}) => {
  const { runTestSurvey, isPending } = useTestSurvey({ surveyId, preSubmit: false })
  const [popupType, setPopupType] = useState<RoundPopupVariant>()
  const [moreOpen, toggleMoreOpen] = useToggle()

  const roundEndTime = getRoundEndTime({
    startTime: round.sent_on,
    deadlineDays: round.response_deadline_days,
    endTime: round.closing_date,
  })
  const progress = calculatePercentageBetweenDates(round.sent_on, roundEndTime)
  const { color: avatarColor, icon } = mapStatusToAvatarData(round.status)

  const canStop =
    round.status?.id === 'ongoing' &&
    permissions?.includes(PermissionTypes.CancelEngagementSurvey)

  const canCancel =
    round.status.id === 'upcoming' &&
    permissions?.includes(PermissionTypes.CancelEngagementSurvey)
  const canPreview = round.status?.id === 'ongoing' || round.status?.id === 'upcoming'

  const canChangeEndDate =
    round.status?.id === 'ongoing' &&
    permissions?.includes(PermissionTypes.ChangeEngagementSurvey)

  const canChangeSchedule =
    round.status?.id === 'upcoming' &&
    permissions?.includes(PermissionTypes.ChangeEngagementSurvey)

  const canSeeResults = round.status?.id === 'completed'

  return (
    <Banner
      style={{
        border: `1px solid ${Token.color.greyTone5}`,
        borderRadius: '16px',
      }}
    >
      <Banner.Avatar size={40}>
        <Avatar
          size={40}
          progressColor={avatarColor}
          useIcon={icon}
          color={avatarColor}
          progress={progress}
        />
      </Banner.Avatar>
      <Banner.Content>
        <Flex gap="s-16" alignItems="center">
          <VStack gap="s-2" flex={1}>
            <Text>{round.sent_on ? formatDate(round.sent_on) : round.status.name}</Text>
            <HStack gap="s-8">
              {round.status.id === 'completed' ? (
                <Text variant="small" color={mapSurveyRoundStatusToColor(round)}>
                  {round.status.name}
                </Text>
              ) : (
                <Tag variant="outlined" color={mapSurveyRoundStatusToColor(round)}>
                  {round.status.name}
                </Tag>
              )}
              {round.status.id === 'ongoing' && (
                <Text variant="small" color={Token.color.greyTone50}>
                  {timeLeft(roundEndTime)}
                </Text>
              )}
              {round.status.id === 'upcoming' && round.sent_on && (
                <Text variant="small" color={Token.color.greyTone50}>
                  Starting {formatDate(round.sent_on)}
                </Text>
              )}
            </HStack>
          </VStack>
          <VStack flex={1}>
            <HStack gap="s-4">
              <Icon name="Profile" color={Token.color.grey50} />
              <Text color={Token.color.grey50} variant="h6">
                {round.response_count || 0}/{round.audience_size || 0}
              </Text>
            </HStack>
            <Text color={Token.color.grey50} variant="h6">
              Responses (
              {formatPercentage(
                round.audience_size
                  ? (round.response_count || 0) / round.audience_size
                  : 0,
                2,
              )}
              )
            </Text>
          </VStack>
          <VStack flex={1}>
            {/* 
            TODO: https://revolut.atlassian.net/browse/REVC-6541 
            NEEDS BE
            <HStack gap="s-4">
              <Text variant="primary">-</Text>
            </HStack>
            <Text color={Token.color.grey50} variant="h6">
              Engagement index
            </Text> */}
          </VStack>
          {[canStop, canPreview, canChangeEndDate, canChangeSchedule, canSeeResults].some(
            permission => !!permission,
          ) ? (
            <MoreButton
              open={moreOpen}
              onToggle={toggleMoreOpen}
              variant="icon"
              color={Token.color.accent}
              placement="auto"
            >
              {canPreview && (
                <MoreButton.Action
                  pending={isPending}
                  useIcon="EyeShow"
                  onClick={runTestSurvey}
                >
                  Preview
                </MoreButton.Action>
              )}
              {canChangeEndDate && (
                <MoreButton.Action
                  useIcon="TimeOutline"
                  onClick={() => setPopupType('end')}
                >
                  Change end date
                </MoreButton.Action>
              )}
              {canSeeResults && (
                <MoreButton.Action
                  useIcon="Wealth"
                  onClick={() => {
                    navigateTo(
                      pathToUrl(ROUTES.PERFORMANCE.ENGAGEMENT.DETAILS_RESULTS, {
                        id: surveyId,
                      }),
                      {
                        roundId: round.id,
                      },
                    )
                  }}
                >
                  Results
                </MoreButton.Action>
              )}
              {canChangeSchedule && (
                <MoreButton.Action
                  useIcon="TimeOutline"
                  onClick={() => setPopupType('reschedule')}
                >
                  Change schedule
                </MoreButton.Action>
              )}
              {canCancel && (
                <MoreButton.Action
                  variant="negative"
                  useIcon="Cross"
                  onClick={() => setPopupType('cancel')}
                >
                  Cancel
                </MoreButton.Action>
              )}
              {canStop && (
                <MoreButton.Action
                  variant="negative"
                  useIcon="Cross"
                  onClick={() => setPopupType('stop')}
                >
                  Stop
                </MoreButton.Action>
              )}
              <StopRunConfirmation
                refetch={refetch}
                surveyId={surveyId}
                roundId={round.id}
                actionFn={popupType === 'stop' ? closeSurveyRound : cancelSurveyRound}
                isOpen={popupType === 'stop' || popupType === 'cancel'}
                onClose={() => setPopupType(undefined)}
              />
              <SurveyRoundForm
                type={popupType}
                refetch={refetch}
                surveyId={surveyId}
                roundId={round.id}
                isOpen={popupType === 'reschedule' || popupType === 'end'}
                onClose={() => setPopupType(undefined)}
              />
            </MoreButton>
          ) : (
            <Spacer width={24} />
          )}
        </Flex>
      </Banner.Content>
    </Banner>
  )
}
