import React from 'react'
import { VStack, Widget, Text, Color } from '@revolut/ui-kit'

import { OptionInterface } from '@src/interfaces/selectors'
import { TalentPerformanceInterface } from '@src/interfaces/talent/performance'
import { ReviewCategory, ReviewSummaryInterface } from '@src/interfaces/performance'
import Cards from '@src/pages/EmployeeProfile/Preview/Performance/Summary/Cards'
import NoReviewsWidget from '@src/pages/EmployeeProfile/Preview/Performance/Common/NoReviewsWidget'
import ReviewersFilter from '@src/pages/EmployeeProfile/Preview/Performance/Summary/ReviewersFilter'
import SidebarFeedbacks from '@src/pages/EmployeeProfile/Preview/Performance/Summary/SidebarFeedbacks'

export interface ReviewsProps {
  data: ReviewSummaryInterface
  talent: TalentPerformanceInterface
  onFilterChanged: (option?: OptionInterface[]) => void
}

export const ReviewsSection = ({ data, talent, onFilterChanged }: ReviewsProps) => {
  const { summary, reviews } = data

  return (
    <Widget p="s-16">
      <VStack space="s-16">
        <ReviewersFilter data={reviews} onFilterChange={onFilterChanged} showFilters />

        <Cards
          data={data}
          cycleId={String(talent.cycle.id)}
          employeeId={talent.employee.id}
          employeeSeniorityId={talent.seniority?.id}
          category={ReviewCategory.Performance}
        />

        {reviews.length ? (
          <div style={{ marginTop: '16px' }}>
            <Text mb={0} color={Color.GREY_TONE_50} variant="caption" fontWeight={500}>
              Feedback
            </Text>
            <SidebarFeedbacks
              renderTitle={false}
              feedbacks={summary?.overall_feedbacks}
              ratings={summary?.ratings}
            />
          </div>
        ) : (
          <NoReviewsWidget />
        )}
      </VStack>
    </Widget>
  )
}
