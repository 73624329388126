import differenceInDays from 'date-fns/differenceInDays'
import differenceInHours from 'date-fns/differenceInHours'
import formatDistanceToNow from 'date-fns/formatDistanceToNow'
import React from 'react'
import { Color, Flex } from '@revolut/ui-kit'
import formatDistance from 'date-fns/formatDistance'
import isPast from 'date-fns/isPast'

interface Props {
  date?: string
  color?: Color
}

interface DoneDateDistanceProps {
  deadlineDate?: string
  doneDate?: string
}

export const DoneDateDistance = ({ deadlineDate, doneDate }: DoneDateDistanceProps) => {
  if (!deadlineDate || !doneDate) {
    return <>-</>
  }

  const diff = differenceInHours(new Date(deadlineDate), new Date(doneDate))
  if (diff < 0) {
    return (
      <Flex color="red">
        {`Done `}
        {formatDistance(new Date(deadlineDate), new Date(doneDate))}
        {` late`}
      </Flex>
    )
  }

  return (
    <Flex color="green">
      {`Done `}
      {formatDistance(new Date(deadlineDate), new Date(doneDate))}
      {` before deadline`}
    </Flex>
  )
}

const DateDistance = ({ date, color }: Props) => {
  if (!date) {
    return <>-</>
  }
  if (isPast(new Date(date))) {
    return (
      <Flex color={color || 'red'}>{`${formatDistanceToNow(new Date(date))} ago`}</Flex>
    )
  }

  const dayDiff = differenceInDays(new Date(date), new Date())

  return (
    <Flex color={dayDiff <= 3 ? color || 'orange' : color || 'foreground'}>
      {`in ${formatDistanceToNow(new Date(date))}`}
    </Flex>
  )
}

export default DateDistance
