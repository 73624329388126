import { GoalKpiDetails, GoalsInterface } from '@src/interfaces/goals'
import { useFormObserver } from '../FormObserverProvider'
import { KpiInterface } from '@src/interfaces/kpis'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import { omit, pick } from 'lodash'
import { DeepPartial } from 'redux'
import { useOrgEntity } from '@src/features/OrgEntityProvider/OrgEntityProvider'

export const useAddMetric = ({
  reviewCycles,
  values,
}: {
  values: GoalsInterface & { kpis: DeepPartial<GoalsInterface['kpis']> }
  reviewCycles: ReviewCyclesInterface[] | undefined
}) => {
  const { getFormById } = useFormObserver()
  const { getEntityProps } = useOrgEntity()

  const base = {
    ...getEntityProps(),
    tempId: Date.now(), // need a number value before metrics are submitted and have actual id. Used for targeting the form for copying data
    is_company: values.is_company,
    goal: { id: values.id },
    owner: values.owner,
  }

  const getEmptyMetric = (initReviewCycle?: ReviewCyclesInterface) => {
    return { ...base, targets: [{ review_cycle: initReviewCycle }] }
  }

  const getNextMetric = ({ copyFromId }: { copyFromId?: number }) => {
    // when multiple goals enabled we have option to duplicate goal in the same cycle
    // need to grab correct data for it
    let formID = values.kpis.at(-1)?.id || values.kpis.at(-1)?.tempId
    const targetForm = getFormById(formID)
    let copyValues: GoalKpiDetails | KpiInterface | undefined =
      targetForm?.().form.values || values.kpis.at(-1)

    if (copyFromId) {
      const metric = values.kpis.find(
        kpi => kpi.id === copyFromId || kpi.tempId === copyFromId,
      )
      if (metric) {
        copyValues = metric
      }
    }

    const targetsData = copyValues?.targets?.at(0)

    const notSelectedCycles = reviewCycles
      ?.filter(
        cycle =>
          !values.kpis.some(kpi => kpi.targets.at(0)?.review_cycle?.id === cycle.id),
      )
      .sort((a, b) => Number(b.offset) - Number(a.offset))

    const nextMetricCycle = copyFromId
      ? targetsData?.review_cycle
      : notSelectedCycles?.find(
          cycle =>
            Number(cycle.offset) <
            Number(copyValues?.targets?.at(0)?.review_cycle?.offset),
        )
    const copyied = copyValues
      ? omit(copyValues, 'id', 'targets', 'rejection_reason', 'target_epics')
      : {}

    const targetEpics = copyValues?.target_epics?.at(0)
      ? [
          {
            ...pick(copyValues.target_epics.at(0), 'epics'),
            review_cycle: nextMetricCycle,
          },
        ]
      : undefined

    const nextMetric: DeepPartial<GoalKpiDetails> = {
      ...base,
      ...copyied,
      tempId: Date.now(), // need to override the current tempID
      targets: [
        {
          ...pick(targetsData, 'kpi_goal'),
          initial_value: targetsData?.target,
          target: undefined,
          review_cycle: nextMetricCycle || notSelectedCycles?.at(0),
        },
      ],
      target_epics: targetEpics,
    }
    return nextMetric
  }

  return { getNextMetric, getEmptyMetric }
}
