import { FilterByInterface } from '@src/interfaces/data'

export const getFilterBySurveyId = (surveyId?: number, roundId?: number | undefined) => {
  const filters: FilterByInterface[] = []

  if (surveyId) {
    filters.push({
      filters: [{ id: String(surveyId), name: String(surveyId) }],
      columnName: 'survey__id',
    })
  }
  if (roundId) {
    filters.push({
      filters: [{ id: String(roundId), name: String(roundId) }],
      columnName: 'survey_round_id',
    })
  }
  return filters
}

export const getOptionsFilters = (surveyId?: number) => {
  const defaultFilters = [
    {
      filters: [{ id: 'active', name: 'active' }],
      columnName: 'status',
    },
  ]

  return [...defaultFilters, ...getFilterBySurveyId(surveyId)]
}
