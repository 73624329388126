import React, { useState } from 'react'

import { Color, HStack, Text, VStack } from '@revolut/ui-kit'
import { cancelSurveyRound, closeSurveyRound } from '@src/api/engagement'
import {
  EngagementSurveyHistoryInterface,
  EngagementSurveyInterface,
} from '@src/interfaces/engagement'
import { useLapeContext } from '@src/features/Form/LapeForm'
import ConfirmationDialog from '@src/features/Popups/ConfirmationDialog'
import { TableActionButton } from '@components/Button/TableActionButton'

type Props = {
  data: EngagementSurveyHistoryInterface
  refetch: () => void
}
export const HistoryActions = ({ data, refetch }: Props) => {
  const { initialValues } = useLapeContext<EngagementSurveyInterface>()

  const [isCancelPending, setIsCancelPending] = useState(false)
  const [isClosingPending, setIsClosingPending] = useState(false)

  const [isCancelSubmitShown, setIsCancelSubmitShown] = useState(false)
  const [isClosingSubmitShown, setIsClosingSubmitShown] = useState(false)

  const canCancel = data.status.id === 'upcoming'
  const canClose = data.status.id === 'ongoing'

  const displayActions = canCancel || canClose

  if (!canClose && !canCancel) {
    return null
  }
  return (
    <>
      {displayActions ? (
        <HStack space="s-12">
          {canClose && (
            <TableActionButton
              pending={isClosingPending}
              onClick={() => setIsClosingSubmitShown(true)}
            >
              <Text>Close</Text>
            </TableActionButton>
          )}
          {canCancel && (
            <TableActionButton
              pending={isCancelPending}
              color={Color.RED}
              onClick={() => setIsCancelSubmitShown(true)}
            >
              <Text>Cancel</Text>
            </TableActionButton>
          )}
        </HStack>
      ) : null}
      <ConfirmationDialog
        open={isCancelSubmitShown}
        onClose={() => setIsCancelSubmitShown(false)}
        onConfirm={async () => {
          setIsCancelPending(true)
          try {
            await cancelSurveyRound(initialValues.id!, data.id)
          } finally {
            setIsCancelPending(false)
            setIsCancelSubmitShown(false)
            refetch()
          }
        }}
        loading={isCancelPending}
        onReject={() => setIsCancelSubmitShown(false)}
        label="Are you sure you want to cancel this survey?"
        body={
          <VStack space="s-8">
            <Text color={Color.GREY_TONE_50}>
              Any answers already submitted by employees will be ignored when managers
              look at survey results.
            </Text>
            <Text color={Color.RED}>This action is irreversible.</Text>
          </VStack>
        }
        yesMessage="Confirm"
        noMessage="Cancel"
      />
      <ConfirmationDialog
        open={isClosingSubmitShown}
        onClose={() => setIsClosingSubmitShown(false)}
        onConfirm={async () => {
          setIsClosingPending(true)
          try {
            await closeSurveyRound(initialValues.id!, data.id)
          } finally {
            setIsClosingPending(false)
            setIsClosingSubmitShown(false)
            refetch()
          }
        }}
        loading={isClosingPending}
        onReject={() => setIsClosingSubmitShown(false)}
        label="Are you sure you want to close this survey?"
        body={<Text color={Color.RED}>This action is irreversible.</Text>}
        yesMessage="Confirm"
        noMessage="Cancel"
      />
    </>
  )
}
