import { Avatar, Group, Item, VStack } from '@revolut/ui-kit'
import { PageBody } from '@components/Page/PageBody'
import React from 'react'
import { InternalLink } from '@components/InternalLink/InternalLink'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { Gear, LockClosed, LogoutDoor, Profile, SendMessage } from '@revolut/icons'
import { setFeedbackOpen } from '@src/store/feedback/actions'
import { logOutAction } from '@src/store/auth/actions'
import { useDispatch, useSelector } from 'react-redux'
import { selectFeatureFlags, selectPermissions } from '@src/store/auth/selectors'
import {
  generalSettings,
  organisationSettings,
  peopleSettings,
  performanceSettings,
  plansAndBilling,
} from '@src/pages/Settings/SettingsLandingPage/SettingsLandingPage'
import { FeatureFlags, PermissionTypes } from '@src/store/auth/types'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { PageWrapper } from '@components/Page/Page'
import AppVersion from '@src/features/AppVersion/AppVersion'
import HideIfCommercial from '@components/HideIfCommercial/HideIfCommercial'
import { useSubsciptionInfo } from '@src/api/plans'

const Preferences = () => {
  const dispatch = useDispatch()

  const subscriptionInfo = useSubsciptionInfo()
  const featureFlags = useSelector(selectFeatureFlags)
  const permissions = useSelector(selectPermissions)
  const isCommercial = featureFlags?.includes(FeatureFlags.CommercialProduct)
  const canViewUsersAccessManagement = permissions.includes(PermissionTypes.ViewUser)

  const canViewAdmin = [
    ...peopleSettings(),
    ...organisationSettings,
    ...performanceSettings({ isCommercial }),
    ...plansAndBilling(subscriptionInfo),
    ...generalSettings({
      isCommercial,
      accountsEnabled: featureFlags?.includes(FeatureFlags.TenantAccounts),
    }),
  ]
    .reduce<PermissionTypes[]>((prev, curr) => [...prev, ...(curr.canView || [])], [])
    .some(permission => permissions.includes(permission))

  return (
    <PageWrapper>
      <PageHeader title="User settings" backUrl={ROUTES.MAIN} />
      <PageBody>
        <VStack gap="s-16">
          <Group>
            {canViewAdmin ? (
              <Item
                variant="disclosure"
                type="button"
                use={InternalLink}
                to={pathToUrl(ROUTES.SETTINGS.ALL)}
              >
                <Item.Avatar>
                  <Avatar useIcon={Gear} />
                </Item.Avatar>
                <Item.Content>
                  <Item.Title>Admin panel</Item.Title>
                </Item.Content>
              </Item>
            ) : null}

            {canViewUsersAccessManagement ? (
              <Item
                variant="disclosure"
                type="button"
                use={InternalLink}
                to={ROUTES.ADMIN.ACCESS_MANAGEMENT.USERS}
              >
                <Item.Avatar>
                  <Avatar useIcon="Shield" />
                </Item.Avatar>
                <Item.Content>
                  <Item.Title>Access Management</Item.Title>
                </Item.Content>
              </Item>
            ) : null}
          </Group>

          <Group>
            <Item
              variant="disclosure"
              type="button"
              use={InternalLink}
              to={ROUTES.SETTINGS.USER_PREFERENCES}
            >
              <Item.Avatar>
                <Avatar useIcon={Profile} />
              </Item.Avatar>
              <Item.Content>
                <Item.Title>User preferences</Item.Title>
              </Item.Content>
            </Item>

            <HideIfCommercial>
              <Item
                variant="disclosure"
                type="button"
                use={InternalLink}
                to={pathToUrl(ROUTES.PREFERENCES.PRIVACY)}
              >
                <Item.Avatar>
                  <Avatar useIcon={LockClosed} />
                </Item.Avatar>
                <Item.Content>
                  <Item.Title>Data & privacy policies</Item.Title>
                </Item.Content>
              </Item>
            </HideIfCommercial>

            <Item
              use="button"
              onClick={() => {
                dispatch(setFeedbackOpen(true))
              }}
              variant="disclosure"
            >
              <Item.Avatar>
                <Avatar useIcon={SendMessage} />
              </Item.Avatar>
              <Item.Content>
                <Item.Title>Leave feedback</Item.Title>
              </Item.Content>
            </Item>
          </Group>

          <Group>
            <Item
              use="button"
              onClick={() => {
                dispatch(logOutAction())
              }}
            >
              <Item.Avatar>
                <Avatar useIcon={LogoutDoor} color="red" />
              </Item.Avatar>
              <Item.Content>
                <Item.Title>Logout</Item.Title>
              </Item.Content>
            </Item>
          </Group>
          <AppVersion />
        </VStack>
      </PageBody>
    </PageWrapper>
  )
}

export default Preferences
