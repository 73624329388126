import { goalsRequests } from '@src/api/goals'
import Form from '@src/features/Form/Form'
import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { GoalFormPage } from './GoalFormPage'
import { GoalPreviewPage } from './Form/Preview/GoalPreviewPage'
import { SidebarProvider } from './common/SidebarProvider'
import { EntityFetcher } from './EntityFetcher'
import { isOnboardingPath } from '@src/pages/OnboardingChecklistV2/common/helpers'
import { ROUTES } from '@src/constants/routes'
import { CreateGoalLevelSelect } from './CreateGoalLevelSelect'
import { NewGoalFormPage } from './NewGoalFormPage'

export const GoalRouterWithForm = () => {
  const isOnboarding = isOnboardingPath()

  return (
    <Switch>
      <Route
        exact
        path={
          isOnboarding
            ? ROUTES.ONBOARDING_CHECKLIST_V2.GOALS.GOAL.CREATE.SELECT_LEVEL
            : ROUTES.FORMS.GOAL.CREATE.SELECT_LEVEL
        }
      >
        <CreateGoalLevelSelect />
      </Route>
      <Route
        path={
          isOnboarding
            ? ROUTES.ONBOARDING_CHECKLIST_V2.GOALS.GOAL.CREATE.FORM
            : ROUTES.FORMS.GOAL.CREATE.FORM
        }
      >
        <Form
          api={goalsRequests}
          fieldsToExclude={['kpis', 'roadmaps', 'dashboards']}
          refetchOnLocationChange
        >
          <NewGoalFormPage />
        </Form>
      </Route>
      <Route>
        <Form
          api={goalsRequests}
          fieldsToExclude={['kpis', 'roadmaps', 'dashboards']}
          refetchOnLocationChange
        >
          <EntityFetcher>
            <SidebarProvider>
              <Switch>
                <Route
                  exact
                  path={
                    isOnboarding
                      ? ROUTES.ONBOARDING_CHECKLIST_V2.GOALS.GOAL.PREVIEW
                      : ROUTES.FORMS.GOAL.PREVIEW
                  }
                >
                  <GoalPreviewPage />
                </Route>

                <Route
                  exact
                  path={
                    isOnboarding
                      ? ROUTES.ONBOARDING_CHECKLIST_V2.GOALS.GOAL.EDIT
                      : ROUTES.FORMS.GOAL.EDIT
                  }
                >
                  <GoalFormPage />
                </Route>
              </Switch>
            </SidebarProvider>
          </EntityFetcher>
        </Form>
      </Route>
    </Switch>
  )
}
