import React from 'react'
import GenericNotificationTable from '@src/pages/Forms/Notifications/GenericNotificationTable'
import { RowInterface, TabComponentProps } from '@src/interfaces/data'
import { NotificationsInterface } from '@src/interfaces/notifications'
import {
  notificationsActionColumn,
  notificationsEmployeedColumn,
  notificationsCommentText,
  notificationsReceivedColumn,
} from '@src/constants/columns/notifications'
import { notificationsComments } from '@src/api/notifications'
import ChatTagsManager from '@src/components/Chat/ChatTagsManager'
import { Flex } from '@revolut/ui-kit'

const Comments = ({
  refreshStats,
  type,
  employeeId,
  filterBy,
  tableRef,
}: TabComponentProps) => {
  const isDashboard = type === 'dashboard'

  const RowNotifications: RowInterface<NotificationsInterface> = {
    cells: [
      {
        ...notificationsEmployeedColumn(isDashboard),
        width: isDashboard ? 100 : 260,
        title: 'Requested by',
      },
      {
        ...notificationsCommentText,
        width: 400,
      },
      {
        ...notificationsReceivedColumn,
        width: 140,
      },
      {
        ...notificationsActionColumn,
        width: 140,
      },
    ],
  }

  return (
    <Flex flexDirection="column" width="100%">
      <ChatTagsManager noTooltip>
        <GenericNotificationTable
          rows={RowNotifications}
          request={notificationsComments(employeeId)}
          refreshStats={refreshStats}
          type={type}
          filterBy={filterBy}
          tableRef={tableRef}
        />
      </ChatTagsManager>
    </Flex>
  )
}

export default Comments
