import React from 'react'
import {
  ReviewScorecardInterface,
  ReviewSummaryInterface,
} from '@src/interfaces/performance'
import { connect } from 'lape'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { Statuses } from '@src/interfaces'
import { FilterByInterface } from '@src/interfaces/data'
import { KPIsCardTableProps } from '@src/pages/Forms/EmployeePerformanceLayout/components/KPIsCardTable'
import { EntityTypes } from '@src/constants/api'
import { useGetGoalContentTypeFilter } from '@src/features/Goals/useGoalFilters'
import { EmployeeGoalsCardTableContent } from '@src/pages/Forms/EmployeePerformanceLayout/components/EmployeeGoalsCardTable'

export const TeamGoalsCardTable = connect(({ ...props }: KPIsCardTableProps) => {
  const { values } = useLapeContext<ReviewScorecardInterface | ReviewSummaryInterface>()
  const cycle = 'reviews' in values ? values.reviews[0]?.cycle : values.cycle
  const contentTypeFilter = useGetGoalContentTypeFilter(EntityTypes.team)
  const team = values.team

  if (!contentTypeFilter) {
    return null
  }

  const initialFilters: FilterByInterface[] = [
    ...(cycle?.id
      ? [
          {
            columnName: 'cycle__id',
            filters: [{ id: String(cycle.id), name: String(cycle.name) }],
            nonResettable: true,
          },
        ]
      : []),
    {
      columnName: 'approval_status',
      nonResettable: true,
      filters: [
        { id: Statuses.pending, name: Statuses.pending },
        { id: Statuses.approved, name: Statuses.approved },
        { id: Statuses.requires_changes, name: Statuses.requires_changes },
      ],
    },
    contentTypeFilter,
    ...(team
      ? [
          {
            columnName: 'object_id',
            filters: [{ id: team.id, name: team.name }],
            nonResettable: true,
            nonInheritable: true,
          },
        ]
      : []),
  ]

  return <EmployeeGoalsCardTableContent initialFilters={initialFilters} {...props} />
})
