import React, { useContext } from 'react'
import { Box, DetailsCell, Group, Header, MoreBar, VStack } from '@revolut/ui-kit'
import {
  InterviewCancellingStatuses,
  InterviewReschedulingStatuses,
  SchedulingInterviewInterface,
} from '@src/interfaces/interviewTool'
import { getCurrentTimezone } from '@src/utils/timezones'
import ScheduledWidget from '@src/pages/Forms/Candidate/ScheduleSidebar/ScheduledWidget'
import ScheduledStatus from '@src/pages/Forms/Candidate/ScheduleSidebar/ScheduledStatus'
import UserWithAvatar from '@components/UserWithAvatar/UserWithAvatar'
import RescheduleInterviewButton from '@src/pages/Forms/Candidate/ScheduleSidebar/Buttons/RescheduleInterviewButton'
import CancelInterviewButton from '@src/pages/Forms/Candidate/ScheduleSidebar/Buttons/CancelInterviewButton'
import ScheduledDetails from '@src/pages/Forms/Candidate/ScheduleSidebar/ScheduledDetails'
import { getDuration } from '@src/pages/Forms/Candidate/ScheduleSidebar/utils'
import PreviewSlots from '@src/pages/Forms/Candidate/ScheduleSidebar/PreviewSlots'
import { HeaderSchedulingSidebarContext } from '@src/utils/extension'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { SendCandidateEmailInterface } from '@src/interfaces/hiringProccess'
import { getAvatarUrl } from '@src/utils/employees'

type Props = {
  roundId: number
  interview: SchedulingInterviewInterface
  onReschedule: () => void
  onCancelInterview: () => Promise<void>
  onEdit: () => void
  isPrepCall?: boolean
}

const ScheduledInterview = ({
  roundId,
  interview,
  onReschedule,
  onCancelInterview,
  onEdit,
  isPrepCall,
}: Props) => {
  const { values } = useLapeContext<SendCandidateEmailInterface>()
  const headerContext = useContext(HeaderSchedulingSidebarContext)
  const currentTimezoneId = getCurrentTimezone()
  const timeZoneId = interview?.scheduling_timezone
    ? String(interview.scheduling_timezone.id)
    : currentTimezoneId

  const status = isPrepCall
    ? interview?.adhoc_scheduling_status
    : interview?.interview_stage.scheduling_status

  const canReschedule =
    status && InterviewReschedulingStatuses.includes(status) && !isPrepCall
  const canCancel = status && InterviewCancellingStatuses.includes(status)

  const title = isPrepCall ? 'Catch-up call' : values.interview_stage?.title

  const renderDuration = () => {
    const duration = interview?.duration || values.interview_stage?.duration
    const durationUnit = interview?.duration_unit || values.interview_stage?.duration_unit

    if (!duration || !durationUnit) {
      return '-'
    }

    return getDuration(duration, durationUnit)
  }

  return (
    <>
      <Header variant="item">
        {headerContext?.onBack ? (
          <Header.BackButton aria-label="Back" onClick={headerContext.onBack} />
        ) : (
          <Header.CloseButton aria-label="Close" />
        )}
        <Header.Title aria-label="sidebar-title">{title}</Header.Title>
        <Header.Description>
          <ScheduledStatus status={status} />
        </Header.Description>
        {(canReschedule || canCancel) && (
          <Header.Bar>
            <MoreBar>
              {interview.event_date_time && status !== 'interview_cancelled' && (
                <MoreBar.Action onClick={onEdit} data-testid="btn-edit">
                  Edit
                </MoreBar.Action>
              )}
              {canReschedule && <RescheduleInterviewButton onProceed={onReschedule} />}
              {canCancel && values.interview_stage?.id && (
                <CancelInterviewButton
                  roundId={roundId}
                  stageId={values.interview_stage.id}
                  interviewId={interview?.id!}
                  onProceed={onCancelInterview}
                  menuType="negative"
                />
              )}
            </MoreBar>
          </Header.Bar>
        )}
        {headerContext?.actions && (
          <Header.Actions>{headerContext?.actions}</Header.Actions>
        )}
      </Header>

      <VStack gap="s-16">
        <Box>
          <Group>
            {!isPrepCall && (
              <DetailsCell>
                <DetailsCell.Title>Stage</DetailsCell.Title>
                <DetailsCell.Content>{values.interview_stage?.title}</DetailsCell.Content>
              </DetailsCell>
            )}
            <DetailsCell>
              <DetailsCell.Title>Duration</DetailsCell.Title>
              <DetailsCell.Content>{renderDuration()}</DetailsCell.Content>
            </DetailsCell>
            {interview && (
              <>
                <ScheduledDetails interview={interview} />
                {interview.interviewer && (
                  <DetailsCell>
                    <DetailsCell.Title>Lead interviewer</DetailsCell.Title>
                    <DetailsCell.Content>
                      <UserWithAvatar
                        full_name={interview.interviewer.name}
                        id={interview.interviewer.id}
                        avatar={getAvatarUrl(interview.interviewer.avatar)}
                      />
                    </DetailsCell.Content>
                  </DetailsCell>
                )}
                {!!interview?.additional_interviewers?.length && (
                  <DetailsCell>
                    <DetailsCell.Title>Additional interviewers</DetailsCell.Title>
                    <DetailsCell.Content>
                      <VStack gap="s-16" align="end">
                        {interview.additional_interviewers.map(item => (
                          <UserWithAvatar
                            key={item.id}
                            full_name={item.name}
                            id={item.id}
                            avatar={getAvatarUrl(item.avatar)}
                          />
                        ))}
                      </VStack>
                    </DetailsCell.Content>
                  </DetailsCell>
                )}
                <DetailsCell>
                  <DetailsCell.Title>Invite candidate</DetailsCell.Title>
                  <DetailsCell.Content>
                    {interview.is_candidate_involved ? 'Yes' : 'No'}
                  </DetailsCell.Content>
                </DetailsCell>
              </>
            )}
          </Group>
        </Box>

        <ScheduledWidget
          status={status}
          time={interview.event_date_time}
          timeZoneId={timeZoneId}
        />
      </VStack>

      <VStack gap="s-16">
        {/* we show sent slots only before candidate selected the slot */}
        {!interview.event_date_time && (
          <PreviewSlots roundId={roundId} label="Slots sent" interviewId={interview.id} />
        )}
      </VStack>
    </>
  )
}

export default ScheduledInterview
