import React from 'react'
import { MoreBar, useTooltip, Tooltip as UIKitTooltip } from '@revolut/ui-kit'
import { Plus } from '@revolut/icons'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { getRequisitionInitialValues } from '@src/pages/Forms/RequisitionForm/General/General'
import { useSelector } from 'react-redux'
import { selectPermissions, selectUser } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { RequisitionInterface } from '@src/interfaces/requisitions'
import useIsCommercial from '@src/hooks/useIsCommercial'

type Props = {
  jobPostingId?: number
  newItemInitialValues?: Partial<RequisitionInterface>
}

const AddRequisitionButton = ({ jobPostingId, newItemInitialValues = {} }: Props) => {
  const user = useSelector(selectUser)
  const permissions = useSelector(selectPermissions)
  const canAdd = permissions.includes(PermissionTypes.AddRequisitions)
  const tooltip = useTooltip()
  const isCommercial = useIsCommercial()

  const addNewRequisition = () => {
    navigateTo(pathToUrl(ROUTES.FORMS.REQUISITION.ROLE, {}), {
      initialValues: { ...getRequisitionInitialValues(user), ...newItemInitialValues },
      jobPostingId,
    })
  }

  const tooltipText = isCommercial
    ? 'You don’t have access to create requisition. If it is needed, please contact your system admin to assign the permission'
    : 'You don’t have access to create requisitions, if it is needed, please ask your HoD to delegate this access to you by going to the Department Settings page and clicking on “Manage Permissions” button'

  return (
    <MoreBar.Action
      {...tooltip.getAnchorProps()}
      useIcon={Plus}
      aria-disabled={!canAdd}
      onClick={() => {
        if (canAdd) {
          addNewRequisition()
        }
      }}
    >
      Add requisition
      {!canAdd && (
        <UIKitTooltip {...tooltip.getTargetProps()} width={200}>
          {tooltipText}
        </UIKitTooltip>
      )}
    </MoreBar.Action>
  )
}

export default AddRequisitionButton
