import React, { useEffect } from 'react'
import { Bar, HStack, TableWidget } from '@revolut/ui-kit'

import {
  EngagementResultsComment,
  EngagementResultsCommentsStats,
  EngagementResultsScope,
} from '@src/interfaces/engagement'
import { SurveySelector } from '@src/apps/People/Engagement/Results/components/SurveySelector'
import useFetchOptions, { AsyncState } from '@src/components/Inputs/hooks/useFetchOptions'
import { FieldOptions, IdAndName } from '@src/interfaces'
import FilterButtonDateRange from '@components/FilterButtonDateRange/FilterButtonDateRange'
import FilterButtonCheckboxSelect from '@components/FilterButtonCheckboxSelect/FilterButtonCheckboxSelect'
import {
  useCommentsFilters,
  useDriversOptions,
  useQuestionsOptions,
} from '@src/apps/People/Engagement/Results/hooks'
import useTabBarSwitcher from '@src/features/TabBarSwitcher/useTabBarSwitcher'
import { FeedbackCells } from './FeedbackCells'
import { getFilterBySurveyId, getOptionsFilters } from './helpers'
import { selectorKeys } from '@src/constants/api'
import { OptionInterface } from '@src/interfaces/selectors'
import { FeedbackTable } from '@src/apps/People/Engagement/Results/Feedback/FeedbackTable'
import Stat from '@components/Stat/Stat'
import { useTable } from '@components/Table/hooks'
import { getResultsCommentsTableRequests } from '@src/api/engagement'
import {
  ANSWER_SCORE_KEY,
  CREATION_DATE_TIME_KEY,
  DRIVER_ID_KEY,
  PageStateKeys,
  QUESTION_ID_KEY,
  QUESTION_TYPE_KEY,
} from '@src/apps/People/Engagement/Results/constants'
import SearchTable from '@components/Table/SearchTable/SearchTable'
import { RoundSelector } from '../components/RoundSelector'

enum ToggleCellsTabBar {
  Cells = 'Cells',
  Table = 'Table',
}

const tabsIcons = {
  [ToggleCellsTabBar.Cells]: 'IdBackSide' as const,
  [ToggleCellsTabBar.Table]: 'ViewListMedium' as const,
}

type Props<T> = {
  scope?: EngagementResultsScope
  data: T
  surveysOptions: IdAndName[]
  surveysOptionsAsyncState: AsyncState
  survey?: IdAndName
  setSurvey: (survey: IdAndName | undefined) => void
  round?: IdAndName
  setRound: (round: IdAndName | undefined) => void
  setHasAnonymisedData: (hasData: boolean) => void
  isNewLayout?: boolean
  navigation?: React.ReactElement
}
export const Feedback = <T extends { id: number; field_options: FieldOptions }>({
  scope,
  data,
  surveysOptions,
  surveysOptionsAsyncState,
  survey,
  setSurvey,
  round,
  setRound,
  setHasAnonymisedData,
  isNewLayout = false,
  navigation,
}: Props<T>) => {
  const toggleCellsViewTabBar = useTabBarSwitcher({
    tabs: [ToggleCellsTabBar.Table, ToggleCellsTabBar.Cells],
    useIcons: tabsIcons,
    defaultTab: ToggleCellsTabBar.Table,
    highlightSelected: false,
  })

  const { filters, filtersParams } = useCommentsFilters({
    defaultSurveyId: survey?.id ? String(survey.id) : undefined,
  })

  const { driversOptions } = useDriversOptions({
    defaultFilters: getOptionsFilters(survey?.id),
  })
  const { questionsOptions } = useQuestionsOptions({
    defaultFilters: getOptionsFilters(survey?.id),
  })
  const scoreOptionsData = useFetchOptions<OptionInterface>(
    selectorKeys.engagement_answer_scores,
  )
  const scoreOptions = scoreOptionsData.options.map(({ value }) => value)

  const questionTypesOptionsData = useFetchOptions<OptionInterface>(
    selectorKeys.engagement_question_types,
  )
  const questionTypesOptions = questionTypesOptionsData.options.map(({ value }) => value)

  const table = useTable<EngagementResultsComment, EngagementResultsCommentsStats>(
    getResultsCommentsTableRequests({
      scope,
      scopeId: data.id,
      defaultFilters: getFilterBySurveyId(survey?.id, round?.id),
    }),
    undefined,
    undefined,
    { disable: !survey?.id, omitKeys: PageStateKeys },
  )

  useEffect(() => {
    table.resetFiltersAndSorting()
  }, [])

  return (
    <TableWidget>
      <TableWidget.Info>
        <HStack space="s-32" mb="s-8">
          <SurveySelector
            value={survey}
            options={surveysOptions}
            asyncState={surveysOptionsAsyncState}
            onChange={value => {
              if (value?.id) {
                setSurvey(value)
                setRound(undefined)
              }
            }}
          />
          <RoundSelector
            value={round}
            surveyId={survey?.id}
            onChange={value => {
              if (value?.id) {
                setRound(value)
                table.onFilterChange({
                  columnName: 'survey_round_id',
                  filters: [value],
                })
              }
            }}
          />
          <Stat label="Answers" val={table.count} />
        </HStack>
      </TableWidget.Info>
      <TableWidget.Actions>{isNewLayout && navigation}</TableWidget.Actions>
      <TableWidget.Filters>
        <Bar>
          <FilterButtonDateRange
            label="Date"
            value={filters.dateFilter}
            onChange={value => {
              if (value) {
                filters.setDateFilter(value)
                table.onFilterChange({
                  columnName: CREATION_DATE_TIME_KEY,
                  filters: value,
                })
              }
            }}
          />
          <FilterButtonCheckboxSelect
            searchable
            width={300}
            options={driversOptions}
            disabled={!driversOptions.length}
            label="Driver"
            value={filters.driversFilter}
            onChange={value => {
              if (value) {
                filters.setDriversFilter(value)
                table.onFilterChange({ columnName: DRIVER_ID_KEY, filters: value })
              }
            }}
          />
          <FilterButtonCheckboxSelect
            searchable
            width={400}
            options={questionsOptions}
            disabled={!questionsOptions.length}
            label="Question"
            value={filters.questionsFilter}
            onChange={value => {
              if (value) {
                filters.setQuestionsFilter(value)
                table.onFilterChange({ columnName: QUESTION_ID_KEY, filters: value })
              }
            }}
          />
          <FilterButtonCheckboxSelect
            options={scoreOptions}
            label="Rating"
            value={filters.scoreFilter}
            onChange={value => {
              if (value) {
                filters.setScoreFilter(value)
                table.onFilterChange({ columnName: ANSWER_SCORE_KEY, filters: value })
              }
            }}
          />
          <FilterButtonCheckboxSelect
            options={questionTypesOptions}
            label="Type"
            value={filters.questionTypeFilter}
            onChange={value => {
              if (value) {
                filters.setQuestionTypeFilter(value)
                table.onFilterChange({ columnName: QUESTION_TYPE_KEY, filters: value })
              }
            }}
          />
        </Bar>
      </TableWidget.Filters>
      <TableWidget.Search>
        <SearchTable columnName="answer_text" onFilter={table.onFilterChange} />
      </TableWidget.Search>
      <TableWidget.Table>
        {toggleCellsViewTabBar.currentTab === ToggleCellsTabBar.Table && (
          <FeedbackTable
            scope={scope}
            surveyId={survey?.id}
            data={data}
            setHasAnonymisedData={setHasAnonymisedData}
            table={table}
          />
        )}
        {toggleCellsViewTabBar.currentTab === ToggleCellsTabBar.Cells && (
          <FeedbackCells
            data={data}
            scope={scope}
            filtersParams={filtersParams}
            setHasAnonymisedData={setHasAnonymisedData}
          />
        )}
      </TableWidget.Table>
    </TableWidget>
  )
}
