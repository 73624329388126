import React, { useEffect } from 'react'
import { Token, Flex, Button } from '@revolut/ui-kit'
import { PageActions } from '@src/components/Page/PageActions'
import { PageBody } from '@src/components/Page/PageBody'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { GoalsInterface } from '@src/interfaces/goals'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import { PerformanceSettingsInterface } from '@src/interfaces/settings'

import { GoalForm } from './Form/GoalForm'
import { useFormObserver } from './Form/Widgets/FormObserverProvider'
import { useGoalFormSubmit } from './Form/useGoalFormSubmit'
import { useGoalFormCache } from './useGoalFormCache'
import { ApprovalStatuses } from '@src/interfaces/approvalFlow'

export const GoalFormPageBody = ({
  performanceSettings,
  defaultReviewCycle,
  onSubmitted,
  onDirtyChanged,
}: {
  performanceSettings: PerformanceSettingsInterface
  defaultReviewCycle?: ReviewCyclesInterface
  onSubmitted: () => void
  onDirtyChanged: (isDirty: boolean) => void
}) => {
  const goalForm = useLapeContext<GoalsInterface & { targets: unknown }>()
  const { values, dirty } = goalForm
  const { hasDirtyForms } = useFormObserver()
  const { cacheUpdated } = useGoalFormCache()
  const { submit } = useGoalFormSubmit()

  const isDraft = values.approval_status.id === ApprovalStatuses.Draft

  const isDirty = hasDirtyForms() || dirty

  useEffect(() => {
    onDirtyChanged(isDirty)
  }, [isDirty])

  // eslint-disable-next-line consistent-return
  const onSubmit = async (updateStatus: boolean) => {
    try {
      await submit(updateStatus)
      onSubmitted()
    } catch (err) {
      // that's ok, validation error
    }
  }

  return (
    <PageBody maxWidth={{ all: Token.breakpoint.lg, xxl: Token.breakpoint.xl }}>
      {cacheUpdated && (
        <GoalForm
          enforceParentKpi={performanceSettings.enable_mandatory_parent_kpi}
          defaultReviewCycle={defaultReviewCycle}
        />
      )}

      <PageActions alignSelf="center" aria-label="page actions" mt="s-24">
        <Flex justifyContent="center" maxWidth="340px" alignSelf="center" gap="s-8">
          {isDraft
            ? isDirty && (
                <Button variant="secondary" onClick={() => onSubmit(false)}>
                  Save as draft
                </Button>
              )
            : null}
          {isDirty && (
            <Button onClick={() => onSubmit(true)} elevated>
              {isDraft ? 'Submit' : 'Save changes'}
            </Button>
          )}
        </Flex>
      </PageActions>
    </PageBody>
  )
}
