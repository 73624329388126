import Cookies from 'js-cookie'
import get from 'lodash/get'
import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects'
import { api, apiWithoutHandling } from '../../api'
import { API, COOKIE, REDIRECT_PARAM, SESSION_STORAGE } from '../../constants/api'
import { ERROR_DEFAULT_DURATION, ERRORS } from '../../constants/notifications'
import { pushError, pushNotification } from '../notifications/actions'
import {
  setApiTokenAction,
  setAuthenticatedAction,
  setLoginUrlAction,
  setUserAction,
} from './actions'
import { selectLoginUrl } from './selectors'
import { AuthActionTypes } from './types'
import { Authenticated } from './constants'
import { NotificationTypes } from '../notifications/types'
import { navigateTo } from '../../actions/RouterActions'
import { ROUTES, SIGNUP, WORKSPACES } from '../../constants/routes'
import { saveLoginData } from '@src/pages/Login/common'
import { cookiesApi, removeSignupAuthenticatedCookie } from '@src/utils/cookies'
import { removeSignupStateCookie } from '@src/pages/SignUp/common'
import { isWorkspacesEnabled } from '@src/utils'

function* handleSetApiToken(action: { payload: string }) {
  try {
    // @ts-ignore
    const data = yield call(
      api.post,
      API.LOGIN,
      {
        code: action.payload,
        redirect_url: REDIRECT_PARAM,
      },
      undefined,
      undefined,
      true,
    )

    if (data.data.authenticated) {
      if (data.data.two_factor_authentication_enabled) {
        window.opener.sessionStorage.setItem(
          SESSION_STORAGE.TWO_FACTOR_LOGIN,
          JSON.stringify(data.data),
        )
      } else {
        saveLoginData(data.data)
      }
    }
  } catch (e) {
    cookiesApi.set(COOKIE.AUTHENTICATED, Authenticated.not_authenticated)
    removeSignupAuthenticatedCookie()
  } finally {
    if (window && window.opener && window.opener.location) {
      window.opener.location.reload()
      window.close()
    }
  }
}

function* handleTestLogin(action: { payload: string }) {
  cookiesApi.set(COOKIE.API_TOKEN, action.payload)
  // @ts-ignore
  const data = yield call(api.get, API.LOGIN, undefined, undefined, true)
  if (data.data.authenticated) {
    saveLoginData(data.data)
  }
}

function* handleVerifyApiToken() {
  try {
    const currentURL = new URL(window.location.href)
    const googleToken = currentURL.searchParams.get('code')
    if (googleToken) {
      yield put(setApiTokenAction(googleToken))
    }
  } catch (e) {
    pushError({ message: ERRORS.NO_ACCESS, error: e })
    console.error(e)
  }
}

function* handleLogOut() {
  try {
    if (Cookies.get(COOKIE.AUTHENTICATED)) {
      yield call(apiWithoutHandling.post, API.LOGOUT, {}, undefined, undefined, true)
    }
  } catch (e) {
    console.error(e)
  } finally {
    // @ts-expect-error
    yield put(setUserAction({}))
    yield put(setAuthenticatedAction(false))
    Cookies.remove(COOKIE.AUTHENTICATED)
    removeSignupAuthenticatedCookie()
    removeSignupStateCookie()

    if (window.location.pathname.startsWith(SIGNUP)) {
      navigateTo(isWorkspacesEnabled() ? WORKSPACES.MAIN : ROUTES.LOGIN.MAIN)
    } else {
      navigateTo(ROUTES.LOGIN.MAIN, undefined, true)
    }
  }
}

function* handleGetLoginLink() {
  try {
    const authenticatedString = Cookies.get(COOKIE.AUTHENTICATED)
    // @ts-ignore
    const loginResponse = yield call(
      api.get,
      API.LOGIN,
      {
        params: {
          redirect_url: REDIRECT_PARAM,
        },
      },
      undefined,
      true,
    )
    if (authenticatedString === Authenticated.not_authenticated) {
      pushNotification({
        value: ERRORS.NO_ACCESS,
        type: NotificationTypes.error,
        duration: ERROR_DEFAULT_DURATION,
      })
      Cookies.remove(COOKIE.AUTHENTICATED)
    }

    const url = get(loginResponse, 'data.auth_url')

    if (url) {
      yield put(setLoginUrlAction(url))
    }
  } catch (e) {
    pushError({ message: ERRORS.BAD_LOGIN, error: e })
    console.error(e)
  }
}

function* handleLogIn() {
  try {
    // find all local storage keys that contain 'persist--'
    const persistKeys = Object.keys(localStorage).filter(key => key.includes('persist--'))

    // and "spare" them from clearing
    const toSpare = {}
    persistKeys.forEach(key => {
      /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
      toSpare[key] = localStorage.getItem(key)
    })

    localStorage.clear()

    /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
    persistKeys.forEach(key => localStorage.setItem(key, toSpare[key]))

    // @ts-ignore
    const url = yield select(selectLoginUrl)

    if (url) {
      window.open(url, `_blank`, 'width=600,height=800')
    }
  } catch (e) {
    pushError({ message: ERRORS.BAD_LOGIN, error: e })
    yield put(setAuthenticatedAction(false))
    console.error(e)
  }
}

export function* authSaga() {
  yield all([
    takeEvery<any>(AuthActionTypes.SET_API_TOKEN, handleSetApiToken),
    takeEvery<any>(AuthActionTypes.TEST_LOGIN, handleTestLogin),
    takeLatest(AuthActionTypes.VERIFY_API_TOKEN, handleVerifyApiToken),
    takeLatest(AuthActionTypes.LOG_OUT, handleLogOut),
    takeLatest(AuthActionTypes.LOG_IN, handleLogIn),
    takeLatest(AuthActionTypes.GET_LOGIN_URL, handleGetLoginLink),
  ])
}
