import React from 'react'
import { useParams } from 'react-router-dom'

import { downloadTenantInvoice, useTenantInvoiceDetails } from '@src/api/tenants'
import { InvoiceDetailsPage } from '@src/pages/Forms/Plans/InvoiceDetailsPage'
import { PageWrapper } from '@src/components/Page/Page'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import { adminDeleteInvoice } from '@src/api/plans'

export const AccountInvoiceDetails = () => {
  const params = useParams<{ id: string; invoiceId: string }>()

  const data = useTenantInvoiceDetails(params.id, params.invoiceId)

  return (
    <PageWrapper>
      <InvoiceDetailsPage
        data={data}
        downloadInvoiceCallback={() => downloadTenantInvoice(params.id, params.invoiceId)}
        backUrl={pathToUrl(ROUTES.SETTINGS.ACCOUNTS.INVOICES, params)}
        deleteInvoice={() => adminDeleteInvoice(params.id, params.invoiceId)}
        showMarkAsPaidButton
      />
    </PageWrapper>
  )
}
