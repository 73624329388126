import React, { useEffect } from 'react'
import { EntitySelector } from '@src/apps/People/Engagement/Results/components/EntitySelector'
import { IdAndName } from '@src/interfaces'
import { QueryParams } from '@src/apps/People/Engagement/Results/constants'
import { PageStateQueryInterface } from '../hooks'

type Props = {
  value?: IdAndName
  setValue: (question: IdAndName | undefined) => void
  options: IdAndName[]
  isFetching: boolean
  query: PageStateQueryInterface
}
export const QuestionSelector = ({
  value,
  setValue,
  options,
  query,
  isFetching,
}: Props) => {
  useEffect(() => {
    if (isFetching) {
      return
    }
    const questionIdFromQuery = query[QueryParams.question]
    const defaultOption =
      options.find(option => String(option.id) === questionIdFromQuery) || options[0]

    if (!value && defaultOption) {
      setValue(defaultOption)
    }
  }, [isFetching, options])

  return (
    <EntitySelector
      label="Question"
      value={value}
      setValue={setValue}
      options={options}
      isFetching={isFetching}
    />
  )
}
