import React from 'react'
import { useParams } from 'react-router-dom'
import { Group, MoreBar } from '@revolut/ui-kit'
import { Pencil, Plus } from '@revolut/icons'

import { PageBody } from '@src/components/Page/PageBody'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { FormPreview } from '@components/FormPreview/FormPreview'
import { UserAccessManagementInterface } from '@src/interfaces/accessManagement'
import SettingsButtons from '@src/features/SettingsButtons'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { useSelector } from 'react-redux'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { useGetEmployee } from '@src/api/employees'
import { IdStatuses } from '@src/interfaces/employees'
import { GetActivationLink } from '@src/pages/EmployeeProfile/Preview/components/Buttons/Actions/GetActivationLink'

const General = () => {
  const { id } = useParams<{ id: string }>()
  const { values } = useLapeContext<UserAccessManagementInterface>()
  const permissions = useSelector(selectPermissions)

  const { data: employee } = useGetEmployee(
    values.employee?.id && values.employee?.status === IdStatuses.onboarding
      ? values.employee.id
      : undefined,
  )
  const canEdit =
    values.user_type === 'service' &&
    permissions.includes(PermissionTypes.AddServiceAccount)

  const canAssignUserGroups = permissions?.includes(PermissionTypes.AddUserGroups)

  return (
    <PageBody>
      <SettingsButtons mb="s-16">
        {employee ? <GetActivationLink data={employee} /> : null}
        {canAssignUserGroups && (
          <MoreBar.Action
            useIcon={Plus}
            use={InternalLink}
            to={pathToUrl(ROUTES.FORMS.ASSIGN_USER_TO_PERMISSION_GROUPS.GENERAL, {
              userId: id,
            })}
          >
            Assign groups
          </MoreBar.Action>
        )}
        {canEdit && (
          <MoreBar.Action
            useIcon={Pencil}
            use={InternalLink}
            to={pathToUrl(ROUTES.FORMS.ADD_SERVICE_ACCOUNT.GENERAL, {
              id: values.service_account.id,
            })}
          >
            Edit
          </MoreBar.Action>
        )}
      </SettingsButtons>
      <FormPreview data={values}>
        <Group>
          {values.user_type === 'employee' ? (
            <FormPreview.Item title="Name" field="employee.name" />
          ) : (
            <FormPreview.Item title="Name" field="email" />
          )}
          <FormPreview.Item title="Status" field="status.name" />
          <FormPreview.Item title="Type" field="user_type" type="capitalized" />
          <FormPreview.Item title="Email" field="email" />
          <FormPreview.Item title="Last login" field="last_login" type="dateTime" />
          {values.user_type === 'employee' ? (
            <FormPreview.Item title="Employee profile" field="employee" type="employee" />
          ) : (
            <FormPreview.Item
              title="Owner profile"
              field="service_account.owner"
              type="employee"
            />
          )}
        </Group>
      </FormPreview>
    </PageBody>
  )
}

export default General
