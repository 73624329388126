import React, { useEffect, useState } from 'react'
import { OnboardingIntro } from '@components/OnboardingIntro/OnboardingIntro'
import { OnboardingIntroTextWidget } from '@components/OnboardingIntro/OnboardingIntroTextWidget'
import { FormattedMessage } from 'react-intl'
import { ROUTES } from '@src/constants/routes'
import { EngagementIntroStartWidget } from './components/EngagementIntroStartWidget'
import { Button, ItemSkeleton, StatusPopup, Token, useStatusPopup } from '@revolut/ui-kit'
import { pathToUrl } from '@src/utils/router'
import {
  createSurveyFromTemplate,
  useEngagementSurveysTemplates,
} from '@src/api/engagement'
import { EngagementSurveyInterface } from '@src/interfaces/engagement'
import { getStringMessageFromError } from '@src/store/notifications/actions'
import { PageActions } from '@src/components/Page/PageActions'
import { navigateReplace } from '@src/actions/RouterActions'
import { updateOnboardingCheckpointCategoryCurrentStep } from '@src/api/onboardingChecklistV2'
import { engagementConfig } from '../common/checkpointsConfig'
import { workspaceLocalStorage } from '@src/features/Workspaces/workspaceLocalStorage'
import { LOCAL_STORAGE } from '@src/constants/api'

export const EngagementIntro = () => {
  const { data: templates, isLoading } = useEngagementSurveysTemplates()
  const [selectedTemplate, setSelectedTemplate] = useState<EngagementSurveyInterface>()
  const [isPending, setIsPending] = useState(false)
  const statusPopup = useStatusPopup()

  useEffect(() => {
    if (templates?.results.length) {
      /* @TODO allow selecting different templates
        and select is recommended by default
        https://revolut.atlassian.net/browse/REVC-6381 */
      setSelectedTemplate(templates.results[0])
    }
  }, [isLoading])

  const handleCreateFromTemplate = async () => {
    if (!selectedTemplate) {
      return
    }
    setIsPending(true)
    try {
      const hiddenDraft = await createSurveyFromTemplate(selectedTemplate.id)
      workspaceLocalStorage.setItem(
        LOCAL_STORAGE.ONBOARDING_ENGAGEMENT_CURRENT_DRAFT_ID,
        String(hiddenDraft.data.id),
      )

      await updateOnboardingCheckpointCategoryCurrentStep(
        engagementConfig.category,
        'Intro',
      )
      navigateReplace(
        pathToUrl(ROUTES.ONBOARDING_CHECKLIST_V2.ENGAGEMENT.SETUP, {
          id: String(hiddenDraft.data.id),
        }),
      )
    } catch (error) {
      statusPopup.show(
        <StatusPopup variant="error">
          <StatusPopup.Title>Couldn't complete the step</StatusPopup.Title>
          <StatusPopup.Description>
            {getStringMessageFromError(error)}
          </StatusPopup.Description>
        </StatusPopup>,
      )
    } finally {
      setIsPending(false)
    }
  }
  return (
    <>
      <OnboardingIntro
        // @TODO correct video
        // https://revolut.atlassian.net/browse/REVC-6425
        videoUrl="/intros/Intro%20-%20Jobs.mov"
        videoPosterUrl="/intros/Intro%20-%20Jobs.png"
      >
        <OnboardingIntroTextWidget
          title={
            <FormattedMessage
              id="onboardingV2.engagement.intro.title"
              defaultMessage="Measure your employee engagement with ease"
            />
          }
        >
          <p>
            <FormattedMessage
              id="onboardingV2.engagement.intro.text1"
              defaultMessage="Surveys will help you to track changes in employee sentiment and engagement over time by measuring Overall Engagement Index (OEI), which is the industry benchmark that represents overall organizational health. Using OEI supports for ongoing monitoring and adjustment of the engagement initiatives to drive continuous improvement. Identify specific areas where employee satisfaction or engagement may be lacking, allowing for targeted interventions and improvements."
            />
          </p>
        </OnboardingIntroTextWidget>
        {isLoading ? (
          <ItemSkeleton />
        ) : (
          <EngagementIntroStartWidget
            isRecommended={selectedTemplate?.is_recommended}
            title={selectedTemplate?.title}
            // https://revolut.atlassian.net/browse/REVC-6381
            // TODO when we have multiple templates BE will return data for this
            // so fallback can be removed
            description={
              selectedTemplate?.subtitle ||
              'Ready-to-use survey to learn about your employees'
            }
            keyValues={
              selectedTemplate?.key_values || [
                'Measure and Boost Employee Engagement',
                'Identify Areas for Improvement',
                'Increase Employee Morale and Satisfaction',
              ]
            }
          />
        )}
      </OnboardingIntro>
      {!!selectedTemplate && (
        <PageActions maxWidthMd={Token.breakpoint.lg}>
          <Button pending={isPending} onClick={handleCreateFromTemplate}>
            Get started
          </Button>
        </PageActions>
      )}
    </>
  )
}
