import React from 'react'
import {
  Box,
  Copyable,
  Header,
  Link,
  Portal,
  Text,
  Token,
  VStack,
  Widget,
} from '@revolut/ui-kit'
import { NationalId } from '@revolut/icons'

import SideBar from '@components/SideBar/SideBar'
import { FormPreview } from '@components/FormPreview/FormPreview'
import {
  EMPLOYEE_PROFILE_SUB_SECTIONS,
  EmployeeContactsInterface,
  EmployeeInterface,
  EmployeeBioInterface,
  PayrollInterface,
  EmployeeEmergencyContactInterface,
  EmployeeLaptopDelivery,
  EmployeeAboutInterface,
  DiversityInterface,
} from '@src/interfaces/employees'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import { DynamicGroupIDs, SectionOptions } from '@src/interfaces/customFields'
import { WarnIfProfileDataAnonymised } from '@src/pages/EmployeeProfile/Preview/ProfileSummary/components/WarnIfProfileDataAnonymised'
import { getProfileFields } from '@src/api/employees'
import { formatWithoutTimezone } from '@src/utils/format'
import {
  canViewDiversity,
  canViewPayroll,
  optionalField,
} from '@src/pages/EmployeeProfile/Preview/ProfileSummary/common'
import { canEditSection } from '@src/pages/EmployeeProfile/Preview/common'
import HideIfCommercial from '@components/HideIfCommercial/HideIfCommercial'
import { DIVERSITY_DATA } from '@src/constants/externalLinks'
import { onCopyFieldNotification } from '@src/pages/EmployeeProfile/Layout/common/helpers'
import UserWithAvatar from '@components/UserWithAvatar/UserWithAvatar'
import EditBio from '@src/pages/EmployeeProfile/Forms/Bio'
import EditContactInfo from '@src/pages/EmployeeProfile/Forms/ContactInfo'
import EditBankDetails from '@src/pages/EmployeeProfile/Forms/BankDetails'
import EditEmergencyContact from '@src/pages/EmployeeProfile/Forms/EmergencyContacts'
import EditLaptopDelivery from '@src/pages/EmployeeProfile/Forms/LaptopDelivery'
import EditAbout from '@src/pages/EmployeeProfile/Forms/About'
import EditDiversity from '@src/pages/EmployeeProfile/Forms/Diversity'
import EditCorrespondenceAddress from '@src/pages/EmployeeProfile/Forms/CorrespondenceAddress'
import EditIdentificationDetails from '@src/pages/EmployeeProfile/Forms/Identification'
import { EmployeePersonalDetails } from './hooks'
import {
  SidebarEditMode,
  useActiveProfileSidebar,
  SidebarEditFormWrapper,
  isLoadingSectionData,
} from '../common'
import { useGetEmployeeSettings, useGetLifecycleSettings } from '@src/api/settings'

type Props = {
  data: EmployeeInterface
  personalDetails: EmployeePersonalDetails
  onClose: () => void
  dynamicGroups: DynamicGroupIDs
  refreshWidgetData: () => void
}
export const PersonalDetailsSidebar = ({
  data,
  personalDetails,
  onClose,
  dynamicGroups,
  refreshWidgetData,
}: Props) => {
  const { sidebar, editMode, setSidebarQueryParam } = useActiveProfileSidebar()

  const { data: employeeSettings } = useGetEmployeeSettings()
  const showBankDetailsSection = canViewPayroll(
    data,
    !!employeeSettings?.enable_bank_details,
  )

  const { data: lifecycleSettings } = useGetLifecycleSettings()
  const showLaptopDeliverySection = lifecycleSettings?.enable_equipment_delivery

  const setEditMode = (mode: SidebarEditMode | undefined) =>
    setSidebarQueryParam('personalDetails', true, mode)

  const { sections, customFields } = personalDetails
  const bioSectionLoading = isLoadingSectionData(sections.bio.handler)
  const contactInfoSectionLoading = isLoadingSectionData(sections.contacts.handler)
  const bankDetailsSectionLoading = isLoadingSectionData(sections.bankDetails.handler)
  const emergencyContactSectionLoading = isLoadingSectionData(
    sections.emergencyContact.handler,
  )
  const laptopDeliverySectionLoading = isLoadingSectionData(
    sections.laptopDelivery.handler,
  )
  const aboutSectionLoading = isLoadingSectionData(sections.about.handler)
  const diversitySectionLoading = isLoadingSectionData(sections.diversity.handler)

  return (
    <Portal>
      <SideBar
        title="Personal details"
        subtitle={<UserWithAvatar id={data.id} full_name={data?.display_name} />}
        variant="wide"
        isOpen={sidebar === 'personalDetails'}
        onClose={onClose}
        sideProps={{ resizable: true }}
        useIcon={NationalId}
        avatarProps={{ color: Token.color.greyTone20 }}
        customHeader={
          editMode ? (
            <Header>
              <Header.BackButton onClick={() => setEditMode(undefined)} />
              <Header.Title data-testid="sidebar-title">
                Edit {`${editMode}`}
              </Header.Title>
              <Header.Description>
                <UserWithAvatar id={data.id} full_name={data?.display_name} />
              </Header.Description>
            </Header>
          ) : undefined
        }
        zIndex={0}
      >
        {editMode === 'bio' && (
          <SidebarEditFormWrapper
            to={pathToUrl(ROUTES.FORMS.EMPLOYEE.GENERAL.BIO, { id: data.id })}
          >
            <EditBio
              isSidebarContent
              dynamicGroups={dynamicGroups}
              refreshData={() => {
                refreshWidgetData()
                sections.bio.handler.refetch()
              }}
            />
          </SidebarEditFormWrapper>
        )}
        {editMode === 'contact info' && (
          <SidebarEditFormWrapper
            to={pathToUrl(ROUTES.FORMS.EMPLOYEE.GENERAL.CONTACT_INFO, { id: data.id })}
          >
            <EditContactInfo
              isSidebarContent
              dynamicGroups={dynamicGroups}
              refreshData={() => {
                refreshWidgetData()
                sections.contacts.handler.refetch()
              }}
            />
          </SidebarEditFormWrapper>
        )}
        {editMode === 'correspondence address' && (
          <SidebarEditFormWrapper
            to={pathToUrl(ROUTES.FORMS.EMPLOYEE.GENERAL.CORRESPONDENCE_ADDRESS, {
              id: data.id,
            })}
          >
            <EditCorrespondenceAddress isSidebarContent dynamicGroups={dynamicGroups} />
          </SidebarEditFormWrapper>
        )}
        {editMode === 'identification details' && (
          <SidebarEditFormWrapper
            to={pathToUrl(ROUTES.FORMS.EMPLOYEE.GENERAL.IDENTIFICATION, { id: data.id })}
          >
            <EditIdentificationDetails isSidebarContent dynamicGroups={dynamicGroups} />
          </SidebarEditFormWrapper>
        )}
        {editMode === 'bank details' && (
          <SidebarEditFormWrapper
            to={pathToUrl(ROUTES.FORMS.EMPLOYEE.GENERAL.BANK_DETAILS, { id: data.id })}
          >
            <EditBankDetails
              isSidebarContent
              dynamicGroups={dynamicGroups}
              refreshData={() => {
                refreshWidgetData()
                sections.bankDetails.handler.refetch()
              }}
            />
          </SidebarEditFormWrapper>
        )}
        {editMode === 'emergency contact' && (
          <SidebarEditFormWrapper
            to={pathToUrl(ROUTES.FORMS.EMPLOYEE.GENERAL.EMERGENCY_CONTACTS, {
              id: data.id,
            })}
          >
            <EditEmergencyContact
              isSidebarContent
              refreshData={() => {
                refreshWidgetData()
                sections.emergencyContact.handler.refetch()
              }}
            />
          </SidebarEditFormWrapper>
        )}
        {editMode === 'laptop delivery' && (
          <SidebarEditFormWrapper
            to={pathToUrl(ROUTES.FORMS.EMPLOYEE.GENERAL.LAPTOP_DELIVERY, { id: data.id })}
          >
            <EditLaptopDelivery
              isSidebarContent
              refreshData={() => {
                refreshWidgetData()
                sections.laptopDelivery.handler.refetch()
              }}
            />
          </SidebarEditFormWrapper>
        )}
        {editMode === 'about me' && (
          <SidebarEditFormWrapper
            to={pathToUrl(ROUTES.FORMS.EMPLOYEE.GENERAL.ABOUT, { id: data.id })}
          >
            <EditAbout
              isSidebarContent
              dynamicGroups={dynamicGroups}
              refreshData={() => {
                refreshWidgetData()
                sections.about.handler.refetch()
              }}
            />
          </SidebarEditFormWrapper>
        )}
        {editMode === 'diversity' && (
          <SidebarEditFormWrapper
            to={pathToUrl(ROUTES.FORMS.EMPLOYEE.GENERAL.DIVERSITY, { id: data.id })}
          >
            <EditDiversity
              isSidebarContent
              refreshData={() => {
                refreshWidgetData()
                sections.diversity.handler.refetch()
              }}
            />
          </SidebarEditFormWrapper>
        )}
        {!editMode && (
          <VStack gap="s-32">
            <WarnIfProfileDataAnonymised data={data} title="Bio">
              <FormPreview<Partial<EmployeeBioInterface>>
                title="Bio"
                data={sections.bio.data}
                dataHandler={sections.bio.handler}
                onEdit={() => setEditMode('bio')}
                hideEdit={() => !sections.bio.permissions?.canEdit}
              >
                <Widget overflow="hidden">
                  <FormPreview.Item<Partial<EmployeeBioInterface>>
                    title="Legal sex"
                    field="legal_sex.name"
                    loading={bioSectionLoading}
                  />
                  <FormPreview.Item<Partial<EmployeeBioInterface>>
                    title="Languages"
                    field="languages"
                    insert={d =>
                      d.languages?.map(language => language.name).join(', ') || '-'
                    }
                    emptyMessage="Missing required languages"
                    loading={bioSectionLoading}
                  />
                  <FormPreview.Item<Partial<EmployeeBioInterface>>
                    title="Marital status"
                    field="marital_status.name"
                    emptyMessage="Missing required marital status"
                    loading={bioSectionLoading}
                  />
                  <FormPreview.Item<Partial<EmployeeBioInterface>>
                    title="Birth date"
                    field="birth_date"
                    insert={d =>
                      d.birth_date ? formatWithoutTimezone(d.birth_date) : '-'
                    }
                    emptyMessage="Missing required birth date"
                    loading={bioSectionLoading}
                  />
                  <FormPreview.Item<Partial<EmployeeBioInterface>>
                    title="Nationalities"
                    type="options"
                    field="nationalities"
                    emptyMessage="Missing required nationalities"
                    loading={bioSectionLoading}
                  />
                  <FormPreview.CustomFields
                    withSeparator
                    dynamicGroups={dynamicGroups}
                    sectionId={SectionOptions.EmployeeProfile}
                    subSectionId={EMPLOYEE_PROFILE_SUB_SECTIONS.BIO}
                  />
                </Widget>
              </FormPreview>
            </WarnIfProfileDataAnonymised>
            <WarnIfProfileDataAnonymised data={data} title="Contact info">
              <FormPreview<Partial<EmployeeContactsInterface>>
                title="Contact info"
                data={sections.contacts.data}
                dataHandler={sections.contacts.handler}
                onEdit={() => setEditMode('contact info')}
                hideEdit={() => !sections.contacts.permissions?.canEdit}
              >
                <Widget overflow="hidden">
                  <WarnIfProfileDataAnonymised data={data} title="Contact info">
                    <FormPreview.Item<Partial<EmployeeContactsInterface>>
                      title="Personal email"
                      field="personal_email"
                      insert={d =>
                        d.personal_email ? (
                          <Copyable
                            value={d.personal_email}
                            labelButtonCopy="Copy employee personal email"
                            onClick={() => onCopyFieldNotification('Email')}
                          >
                            {d.personal_email}
                          </Copyable>
                        ) : (
                          '-'
                        )
                      }
                      emptyMessage="Missing required personal email"
                      loading={contactInfoSectionLoading}
                    />
                    <FormPreview.Item<Partial<EmployeeContactsInterface>>
                      title="Mobile number"
                      field="phone_number"
                      emptyMessage="Missing required mobile number"
                      loading={contactInfoSectionLoading}
                    />
                    <FormPreview.Item<Partial<EmployeeContactsInterface>>
                      title="Address"
                      insert={d => {
                        const address = [
                          d.address_line_1 || '',
                          d.address_line_2 || '',
                          d.address_line_3 || '',
                          d.post_code || '',
                          d.city || '',
                          d.county || '',
                          d.country?.name || '',
                        ]
                          .filter(Boolean)
                          .join('\n')
                        return address ? (
                          <Box data-testid="employee-contacts-address">{address}</Box>
                        ) : (
                          '-'
                        )
                      }}
                      emptyMessage="Missing required address"
                      loading={contactInfoSectionLoading}
                    />
                    <FormPreview.CustomFields
                      withSeparator
                      dynamicGroups={dynamicGroups}
                      sectionId={SectionOptions.EmployeeProfile}
                      subSectionId={EMPLOYEE_PROFILE_SUB_SECTIONS.CONTACT_INFO}
                    />
                  </WarnIfProfileDataAnonymised>
                </Widget>
              </FormPreview>
            </WarnIfProfileDataAnonymised>

            {!!customFields.correspondenceAddress.length && (
              <FormPreview
                api={() => getProfileFields(data.id, ['custom_fields'])}
                title="Correspondence address"
                onEdit={() => setEditMode('correspondence address')}
                hideEdit={address => !canEditSection(address)}
              >
                <FormPreview.CustomFields
                  dynamicGroups={dynamicGroups}
                  sectionId={SectionOptions.EmployeeProfile}
                  subSectionId={EMPLOYEE_PROFILE_SUB_SECTIONS.CORRESPONDENCE_ADDRESS}
                />
              </FormPreview>
            )}

            {!!customFields.identification.length && (
              <FormPreview
                api={() => getProfileFields(data.id, ['custom_fields'])}
                title="Identification details"
                onEdit={() => setEditMode('identification details')}
                hideEdit={identification => !canEditSection(identification)}
              >
                <FormPreview.CustomFields
                  dynamicGroups={dynamicGroups}
                  sectionId={SectionOptions.EmployeeProfile}
                  subSectionId={EMPLOYEE_PROFILE_SUB_SECTIONS.IDENTIFICATION_DETAILS}
                  withSpacing
                />
              </FormPreview>
            )}

            {showBankDetailsSection && (
              <WarnIfProfileDataAnonymised data={data} title="Bank details">
                <FormPreview<Partial<PayrollInterface>>
                  title="Bank details"
                  data={sections.bankDetails.data}
                  dataHandler={sections.bankDetails.handler}
                  onEdit={() => setEditMode('bank details')}
                  hideEdit={() => !sections.bankDetails.permissions?.canEdit}
                >
                  <Widget overflow="hidden">
                    <FormPreview.Item<Partial<PayrollInterface>>
                      title="Bank name"
                      field="bank_name"
                      emptyMessage="Missing required bank name"
                      loading={bankDetailsSectionLoading}
                    />
                    <FormPreview.Item<Partial<PayrollInterface>>
                      title="Name on account"
                      field="account_name"
                      emptyMessage="Missing required account name"
                      loading={bankDetailsSectionLoading}
                    />
                    <FormPreview.Item<Partial<PayrollInterface>>
                      title="Bank currency"
                      field="bank_currency.name"
                      emptyMessage="Missing required bank currency"
                      loading={bankDetailsSectionLoading}
                    />
                    <FormPreview.CustomFields
                      dynamicGroups={dynamicGroups}
                      sectionId={SectionOptions.EmployeeProfile}
                      subSectionId={EMPLOYEE_PROFILE_SUB_SECTIONS.BANK_DETAILS}
                    />
                  </Widget>
                </FormPreview>
              </WarnIfProfileDataAnonymised>
            )}

            <WarnIfProfileDataAnonymised data={data} title="Emergency contact">
              <FormPreview<Partial<EmployeeEmergencyContactInterface>>
                title="Emergency contact"
                data={sections.emergencyContact.data}
                dataHandler={sections.emergencyContact.handler}
                onEdit={() => setEditMode('emergency contact')}
                hideEdit={() => !sections.emergencyContact.permissions?.canEdit}
              >
                <Widget overflow="hidden">
                  <FormPreview.Item<Partial<EmployeeEmergencyContactInterface>>
                    title="Name"
                    field="emergency_contact_full_name"
                    emptyMessage="Missing required emergency contact name"
                    loading={emergencyContactSectionLoading}
                  />
                  <FormPreview.Item<Partial<EmployeeEmergencyContactInterface>>
                    title="Relationship with employee"
                    field="emergency_contact_relationship"
                    emptyMessage="Missing required emergency contact relationship"
                    loading={emergencyContactSectionLoading}
                  />
                  <FormPreview.Item<Partial<EmployeeEmergencyContactInterface>>
                    title="Email"
                    field="emergency_contact_email"
                    emptyMessage="Missing required emergency contact email"
                    loading={emergencyContactSectionLoading}
                  />
                  <FormPreview.Item<Partial<EmployeeEmergencyContactInterface>>
                    title="Mobile number"
                    field="emergency_contact_mobile_phone"
                    emptyMessage="Missing required emergency contact mobile phone"
                    loading={emergencyContactSectionLoading}
                  />
                </Widget>
              </FormPreview>
            </WarnIfProfileDataAnonymised>

            {showLaptopDeliverySection && (
              <WarnIfProfileDataAnonymised data={data} title="Laptop delivery">
                <FormPreview<Partial<EmployeeLaptopDelivery>>
                  title="Laptop delivery"
                  data={sections.laptopDelivery.data}
                  dataHandler={sections.laptopDelivery.handler}
                  onEdit={() => setEditMode('laptop delivery')}
                  hideEdit={() => !sections.laptopDelivery.permissions?.canEdit}
                >
                  <Widget overflow="hidden">
                    <FormPreview.Item<Partial<EmployeeLaptopDelivery>>
                      title="Laptop delivery address"
                      insert={d => {
                        if (d.laptop_delivery?.id === 'ship_to_different_address') {
                          return (
                            <Box data-testid="laptop-delivery-ship-to-different">
                              {optionalField(d.laptop_delivery_address_line_1, <br />)}
                              {optionalField(d.laptop_delivery_address_line_2, <br />)}
                              {optionalField(d.laptop_delivery_address_line_3, <br />)}
                              {optionalField(d.laptop_delivery_post_code, <br />)}
                              {optionalField(d.laptop_delivery_city, ', ')}
                              {optionalField(d.laptop_delivery_county, ', ')}
                              {optionalField(d.laptop_delivery_country?.name)}
                            </Box>
                          )
                        }
                        if (d.laptop_delivery?.id === 'ship_to_address') {
                          return (
                            <Box data-testid="laptop-delivery-ship-to-own">
                              {optionalField(
                                sections.contacts.data?.address_line_1,
                                <br />,
                              )}
                              {optionalField(
                                sections.contacts.data?.address_line_2,
                                <br />,
                              )}
                              {optionalField(
                                sections.contacts.data?.address_line_3,
                                <br />,
                              )}
                              {optionalField(sections.contacts.data?.post_code, <br />)}
                              {optionalField(sections.contacts.data?.city, ', ')}
                              {optionalField(sections.contacts.data?.county, ', ')}
                              {optionalField(sections.contacts.data?.country?.name)}
                            </Box>
                          )
                        }
                        return '-'
                      }}
                      loading={laptopDeliverySectionLoading}
                    />
                  </Widget>
                </FormPreview>
              </WarnIfProfileDataAnonymised>
            )}

            <WarnIfProfileDataAnonymised data={data} title="About me">
              <FormPreview<Partial<EmployeeAboutInterface>>
                title="About me"
                data={sections.about.data}
                dataHandler={sections.about.handler}
                onEdit={() => setEditMode('about me')}
                hideEdit={() => !sections.about.permissions?.canEdit}
              >
                <Widget overflow="hidden">
                  <FormPreview.Details<Partial<EmployeeAboutInterface>>
                    field="about"
                    loading={aboutSectionLoading}
                  />
                  <FormPreview.CustomFields
                    withSeparator
                    dynamicGroups={dynamicGroups}
                    sectionId={SectionOptions.EmployeeProfile}
                    subSectionId={EMPLOYEE_PROFILE_SUB_SECTIONS.ABOUT_YOU}
                  />
                </Widget>
              </FormPreview>
            </WarnIfProfileDataAnonymised>

            {canViewDiversity(data) && (
              <FormPreview<Partial<DiversityInterface>>
                title="Diversity"
                data={sections.diversity.data}
                dataHandler={sections.diversity.handler}
                onEdit={() => setEditMode('diversity')}
                hideEdit={() => !sections.diversity.permissions?.canEdit}
              >
                {sections.diversity.data && (
                  <Text
                    variant="caption"
                    mb="s-16"
                    mt="-12px"
                    color={Token.color.greyTone50}
                    display="inline-block"
                  >
                    This information is visible only to you. Inputting the details is
                    optional.{' '}
                    <HideIfCommercial>
                      More about this{' '}
                      <Link
                        href={DIVERSITY_DATA}
                        target="_blank"
                        rel="noopener norefferer"
                      >
                        here
                      </Link>
                      .
                    </HideIfCommercial>
                  </Text>
                )}
                <Widget overflow="hidden">
                  <FormPreview.Item<Partial<DiversityInterface>>
                    title="Age group"
                    field="age_range.name"
                    loading={diversitySectionLoading}
                  />
                  <FormPreview.Item<Partial<DiversityInterface>>
                    title="Gender identity"
                    field="gender.name"
                    loading={diversitySectionLoading}
                  />
                  <FormPreview.Item<Partial<DiversityInterface>>
                    title="Sexual orientation"
                    field="sexual_orientation.name"
                    loading={diversitySectionLoading}
                  />
                  <FormPreview.Item<Partial<DiversityInterface>>
                    title="Parent/carer"
                    insert={d =>
                      d.carer_types?.map(carer => carer.name).join(', ') || '-'
                    }
                    loading={diversitySectionLoading}
                  />
                  <FormPreview.Item<Partial<DiversityInterface>>
                    title="Disabilities"
                    insert={d =>
                      d.disabilities?.map(disability => disability.name).join(', ') || '-'
                    }
                    loading={diversitySectionLoading}
                  />
                  <FormPreview.Item<Partial<DiversityInterface>>
                    title="Ethnicities"
                    insert={d =>
                      d.ethnicities?.map(ethnicity => ethnicity.name).join(', ') || '-'
                    }
                    loading={diversitySectionLoading}
                  />
                  <FormPreview.Item<Partial<DiversityInterface>>
                    title="Socio-economic background"
                    field="socioeconomic_background.name"
                    loading={diversitySectionLoading}
                  />
                  <FormPreview.Item<Partial<DiversityInterface>>
                    title="Educational background"
                    field="educational_background.name"
                    loading={diversitySectionLoading}
                  />
                  <FormPreview.Item<Partial<DiversityInterface>>
                    title="Religion"
                    field="religion.name"
                    loading={diversitySectionLoading}
                  />
                </Widget>
              </FormPreview>
            )}
          </VStack>
        )}
      </SideBar>
    </Portal>
  )
}
