import React from 'react'
import { ViewType } from '../types'
import { IdAndName } from '@src/interfaces'
import AdjustableTable from '@src/components/Table/AdjustableTable'
import { TableNames } from '@src/constants/table'
import { getSurveyAdminResultCommentsTableRequests } from '@src/api/engagement'
import { useTable } from '@src/components/Table/hooks'
import { EngagementResultsComment } from '@src/interfaces/engagement'
import {
  engagementCommentAnswerText,
  engagementResultsFeedbackAnswerScoreColumn,
  engagementResultsFeedbackCategoryColumn,
  engagementResultsFeedbackCreationDateColumn,
  engagementResultsFeedbackQuestionColumn,
} from '@src/constants/columns/engagementResults'

interface Props {
  view: ViewType
  selectedRound?: IdAndName
}

const ROW = {
  cells: [
    {
      ...engagementCommentAnswerText,
      width: 200,
    },
    {
      ...engagementResultsFeedbackAnswerScoreColumn,
      width: 90,
    },
    {
      ...engagementResultsFeedbackQuestionColumn,
      width: 200,
    },
    {
      ...engagementResultsFeedbackCategoryColumn,
      width: 120,
    },
    {
      ...engagementResultsFeedbackCreationDateColumn,
      width: 100,
    },
  ],
}

export const ResultComments = ({ view }: Props) => {
  const table = useTable<EngagementResultsComment>(
    getSurveyAdminResultCommentsTableRequests(),
  )

  return view === 'table' ? (
    <AdjustableTable
      name={TableNames.EngagementSurveysResultComments}
      row={ROW}
      rowHeight="large"
      {...table}
    />
  ) : null
}
