import React, { useEffect, useState } from 'react'
import { Token, Flex, HStack, Text } from '@revolut/ui-kit'

import Stat from '@components/Stat/Stat'
import { getEngagementResultsTableRequests } from '@src/api/engagement'
import { useTable } from '@components/Table/hooks'
import {
  EngagementResultInterface,
  EngagementResultsEntities,
  EngagementResultsScope,
  QuestionResultStatsInterface,
} from '@src/interfaces/engagement'
import {
  useQueryParams,
  useQuestionsOptions,
  useTableSwitchers,
} from '@src/apps/People/Engagement/Results/hooks'
import { ChartTableTabs } from '@src/features/TabBarSwitcher/useChartTableSwitcher'
import { FieldOptions, IdAndName } from '@src/interfaces'
import { AsyncState } from '@src/components/Inputs/hooks/useFetchOptions'
import { SurveySelector } from '../components/SurveySelector'
import { ResultsTable } from './ResultsTable'
import { ResultsChart } from './ResultsChart'
import { QuestionSelector } from './QuestionSelector'
import { CommentsSidebar } from '../components/Comments/CommentsSidebar'
import { SORT_DIRECTION } from '@src/interfaces/data'
import {
  overallScoreToColor,
  participationRateToColor,
} from '@src/apps/People/Engagement/helpers'
import { formatPercentage } from '@src/utils/format'
import { PageStateKeys, QueryParams } from '../constants'
import { PermissionTypes } from '@src/store/auth/types'
import { TableWrapper } from '@src/components/Table/TableWrapper'
import { RoundSelector } from '../components/RoundSelector'
import { getFilterBySurveyId } from '../Feedback/helpers'

type Props<T> = {
  data: T
  scope?: EngagementResultsScope
  survey?: IdAndName
  setSurvey: (survey: IdAndName | undefined) => void
  round?: IdAndName
  setRound: (round: IdAndName | undefined) => void
  surveysOptions: IdAndName[]
  surveysOptionsAsyncState: AsyncState
  setHasAnonymisedData: (hasData: boolean) => void
  isNewLayout?: boolean
  navigation?: React.ReactElement
}
export const Questions = <T extends { id: number; field_options: FieldOptions }>({
  data,
  scope,
  survey,
  setSurvey,
  round,
  setRound,
  surveysOptions,
  surveysOptionsAsyncState,
  setHasAnonymisedData,
  isNewLayout = false,
  navigation,
}: Props<T>) => {
  useEffect(() => {
    if (survey?.id) {
      table.refresh()
    }
  }, [survey?.id])

  const [question, setQuestion] = useState<IdAndName>()

  const { query } = useQueryParams({ question })
  const { isFetching: isQuestionsOptionsFetching, questionsOptions } =
    useQuestionsOptions({})

  const { isAverage, tabBar, currentTab, toggleAverageTabBar, setChartTableTab } =
    useTableSwitchers()

  useEffect(() => {
    if (!survey?.id) {
      return
    }
    if (isAverage) {
      table.resetFiltersAndSorting(table.filterBy, [
        ...table.sortBy.filter(s => s.sortBy !== 'nps_score'),
        {
          sortBy: 'average_score',
          direction: SORT_DIRECTION.ASC,
        },
      ])
    } else {
      table.resetFiltersAndSorting(table.filterBy, [
        ...table.sortBy.filter(s => s.sortBy !== 'average_score'),
        {
          sortBy: 'nps_score',
          direction: SORT_DIRECTION.ASC,
        },
      ])
    }
  }, [isAverage])

  const table = useTable<EngagementResultInterface, QuestionResultStatsInterface>(
    getEngagementResultsTableRequests<QuestionResultStatsInterface>(
      data.id,
      Number(survey?.id),
      scope,
      EngagementResultsEntities.Questions,
    ),
    getFilterBySurveyId(survey?.id, round?.id),
    [
      {
        sortBy: 'nps_score',
        direction: SORT_DIRECTION.ASC,
      },
    ],
    { disable: !survey?.id, omitKeys: PageStateKeys },
  )

  useEffect(() => {
    setHasAnonymisedData(table.stats?.can_show_details === false)
  }, [table.stats?.can_show_details])

  const commentsSidebarQuestionId = query[QueryParams.questionComments]
  const canViewComments = data.field_options.permissions?.includes(
    PermissionTypes.ViewEngagementComments,
  )

  return (
    <TableWrapper>
      <HStack space="s-32" mb="s-24">
        {currentTab === ChartTableTabs.Table && (
          <>
            <SurveySelector
              value={survey}
              options={surveysOptions}
              asyncState={surveysOptionsAsyncState}
              onChange={value => {
                if (value?.id) {
                  setSurvey(value)
                  setRound(undefined)
                }
              }}
            />
            <RoundSelector
              value={round}
              surveyId={survey?.id}
              onChange={value => {
                if (value?.id) {
                  setRound(value)
                  table.onFilterChange({
                    columnName: 'survey_round_id',
                    filters: [value],
                  })
                }
              }}
            />
            <Stat
              label="Overall score"
              color={overallScoreToColor(table.stats?.nps_score)}
              val={
                table.stats?.nps_score === null ? (
                  <Text color={Token.color.greyTone50}>N/A</Text>
                ) : table.stats?.nps_score !== undefined ? (
                  +table.stats.nps_score.toFixed(2)
                ) : undefined
              }
              tooltip="This is the total score across all questions"
            />
            <Stat
              label="Audience size"
              val={
                table.stats?.total_headcount === null ? (
                  <Text color={Token.color.greyTone50}>N/A</Text>
                ) : (
                  table.stats?.total_headcount
                )
              }
              tooltip="This is the number of active employees who were requested to complete the survey"
            />
            <Stat
              label="Participation"
              color={participationRateToColor(
                table.stats?.participation_rate
                  ? Math.round(table.stats.participation_rate * 100)
                  : undefined,
              )}
              val={
                table.stats
                  ? formatPercentage(table.stats.participation_rate, 2)
                  : undefined
              }
              tooltip="This is based on the amount of employees who finished the survey (i.e. who answered all questions)"
            />
          </>
        )}
        {currentTab === ChartTableTabs.Chart && (
          <QuestionSelector
            value={question}
            setValue={setQuestion}
            options={questionsOptions}
            isFetching={isQuestionsOptionsFetching}
            query={query}
          />
        )}
      </HStack>
      <Flex justifyContent="space-between" alignItems="center" mb="s-24">
        {/* TODO: uncomment when we have Confluence page for it */}
        {/* <Button size="sm" variant="secondary" useIcon={InfoOutline}> */}
        {/*  Questions explained */}
        {/* </Button> */}
        {isNewLayout && navigation}
        <HStack space="s-12">
          {tabBar}
          {toggleAverageTabBar}
        </HStack>
      </Flex>
      {currentTab === ChartTableTabs.Table && (
        <ResultsTable
          table={table}
          isAverage={isAverage}
          setQuestion={setQuestion}
          switchToChartTab={() => setChartTableTab(ChartTableTabs.Chart)}
          scope={scope}
          canViewComments={canViewComments}
        />
      )}
      {currentTab === ChartTableTabs.Chart && (
        <ResultsChart
          isAverage={isAverage}
          scope={scope}
          entity={EngagementResultsEntities.Questions}
          scopeId={data.id}
          entityId={question?.id}
        />
      )}
      {commentsSidebarQuestionId && canViewComments && (
        <CommentsSidebar
          surveyId={Number(survey?.id)}
          scope={scope}
          scopeId={data.id}
          resultsEntity={EngagementResultsEntities.Questions}
          questionId={commentsSidebarQuestionId}
        />
      )}
    </TableWrapper>
  )
}
