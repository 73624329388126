import { useSelector } from 'react-redux'
import { copyToClipboard } from '@revolut/ui-kit'

import { pushNotification } from '@src/store/notifications/actions'
import { SUCCESS_DEFAULT_DURATION } from '@src/constants/notifications'
import { NotificationTypes } from '@src/store/notifications/types'
import { useLoginSettings } from '@src/api/login'
import { EntityPermissions, FeatureFlags, PermissionTypes } from '@src/store/auth/types'
import { EmployeeInterface, IdStatuses } from '@src/interfaces/employees'
import { isDev, isStaging } from '@src/utils'
import {
  canAddPipCycle,
  selectFeatureFlags,
  selectPermissions,
  selectUser,
} from '@src/store/auth/selectors'
import {
  useGetCandidateSettings,
  useGetOrganisationSettings,
  useGlobalSettings,
} from '@src/api/settings'
import { useGetEmployeePromotions } from '@src/api/promotions'
import { checkIfPromotionFeatureEnabled } from '@src/pages/Performance/PromotionNominees/common'
import useIsCommercial from '@src/hooks/useIsCommercial'
import { UseEmployeeInactivityDataReturnType } from './ChangeStatusDropdown/SetInactive/hooks'
import { UseEmployeeStatusTransitionsReturnType } from './ChangeStatusDropdown/StatusChange/hooks'
import { isInactivityScheduled } from './ChangeStatusDropdown/SetInactive/helpers'
import { EmployeeButtonProps } from './types'
import { useResignationPermissions } from '@src/pages/Forms/EmployeeResignation/hooks'
import { useGetPerformanceSettings } from '@src/api/performanceSettings'
import { useCanNominateAnyTime } from '@src/utils/promotion'

export const onCopyLinkToClipboard = (link?: string) => {
  if (link) {
    copyToClipboard(link).then(() => {
      pushNotification({
        value: 'Link copied to clipboard',
        duration: SUCCESS_DEFAULT_DURATION,
        type: NotificationTypes.success,
      })
    })
  }
}

export const useCanViewGetActivationLink = ({ data }: EmployeeButtonProps) => {
  const loginSettings = useLoginSettings()

  const canGetUrl = data.field_options.permissions?.includes(
    PermissionTypes.GetActivationUrl,
  )
  const isOnboarding = data.status.id === IdStatuses.onboarding
  const isCredentialsActivationEnabled =
    data.status.id === IdStatuses.active &&
    !!loginSettings?.data?.credentials_authentication_enabled
  const isProd = !isDev() && !isStaging()

  return Boolean((isOnboarding || isCredentialsActivationEnabled) && canGetUrl && !isProd)
}

export const useCanViewGetMagicLink = ({ data }: EmployeeButtonProps) => {
  const permissions = useSelector(selectPermissions)

  const canCreateMagicLinkInProd = permissions.includes(
    PermissionTypes.CanCreateMagicLinkManually,
  )
  const isOnboarding = data.status.id === IdStatuses.onboarding

  const isProd = !isDev() && !isStaging()
  const canGetUrl = isProd ? canCreateMagicLinkInProd : true

  return Boolean(isOnboarding && canGetUrl)
}

export const useCanViewChangePassword = ({ data }: EmployeeButtonProps) => {
  const user = useSelector(selectUser)
  const isThisUser = data?.id && data.id === user?.id

  const { data: loginSettings } = useLoginSettings()

  return Boolean(isThisUser && loginSettings?.credentials_authentication_enabled)
}

export const useCanViewResendActivationEmail = ({ data }: EmployeeButtonProps) => {
  const canSend =
    data.field_options?.actions?.includes(EntityPermissions.Approve) ||
    data.field_options?.actions?.includes(EntityPermissions.ApprovePending)
  const showButton = data.status?.id === IdStatuses.onboarding

  return Boolean(canSend && showButton)
}

export const useCanViewOverdueItems = ({ data }: EmployeeButtonProps) => {
  return data?.field_options?.permissions?.includes(PermissionTypes.ViewInbox)
}

const useCanViewProbationPip = (data: EmployeeInterface) => {
  const {
    settings: { resignations_enabled },
  } = useGlobalSettings()
  const { canAdd } = useResignationPermissions()
  const canCreateResignation = resignations_enabled && canAdd
  const canChangeEmployeeStatus = data.field_options?.permissions?.includes(
    PermissionTypes.ChangeEmployeeStatus,
  )

  return canCreateResignation || canChangeEmployeeStatus
}

export const useCanViewCreateProbation = ({ data }: EmployeeButtonProps) => {
  const canViewButton = useCanViewProbationPip(data)

  const { data: performanceSettings } = useGetPerformanceSettings()
  const probationEnabled = performanceSettings?.enable_probation
  const permissions = useSelector(selectPermissions)
  const canManageProbation =
    probationEnabled &&
    permissions.includes(PermissionTypes.ProbationCommitteeHRPermissions)

  return canViewButton && canManageProbation
}

export const useCanViewCreatePip = ({ data }: EmployeeButtonProps) => {
  const canViewButton = useCanViewProbationPip(data)

  const permissions = useSelector(selectPermissions)
  const canManageCycle = permissions.includes(PermissionTypes.ManagePipCycles)
  const canManageCommitteeDecision = permissions.includes(
    PermissionTypes.ManagePipCommitteeDecision,
  )

  const canAddPip = useSelector(canAddPipCycle)
  const { data: performanceSettings } = useGetPerformanceSettings()
  const pipEnabled = performanceSettings?.enable_pip
  const canManagePip = pipEnabled && canAddPip

  return canViewButton && canManagePip && (canManageCycle || canManageCommitteeDecision)
}

export const useCanViewNominateForPromotion = ({
  data,
  cycleId,
}: EmployeeButtonProps) => {
  const user = useSelector(selectUser)
  const featureFlags = useSelector(selectFeatureFlags)
  const {
    settings: { promotions_enabled },
  } = useGlobalSettings()
  const canNominateAnyTime = useCanNominateAnyTime()

  const isThisUser = data.id && user?.id === data.id
  const canNominate =
    data?.field_options?.permissions?.includes(PermissionTypes.AddPromotionNomination) ||
    canNominateAnyTime
  const { data: pendingNominations } = useGetEmployeePromotions({
    employeeId: canNominate && promotions_enabled ? data.id : undefined,
    cycleId,
    // we don't want to refetch nominations on every opening of "actions" dropdown,
    // however, it makes sense to update data once a minute not to force the user reload
    // the whole page in case the employee became eligible for a promotion
    staleTime: 60 * 1000,
  })
  const isPromotionWindowEnabled = checkIfPromotionFeatureEnabled(
    featureFlags,
    promotions_enabled,
  )
  const hasPendingNominations = !!pendingNominations?.count

  return Boolean(
    !isThisUser &&
      promotions_enabled &&
      canNominate &&
      (canNominateAnyTime || isPromotionWindowEnabled) &&
      !hasPendingNominations,
  )
}

export const useCanViewManageDataAccess = ({ data }: EmployeeButtonProps) => {
  const { data: organisationSettings } = useGetOrganisationSettings()
  const dataAccessRequestsEnabled = organisationSettings?.data_access_requests?.enabled
  const hasViewAccessRequestsPermission = data?.field_options?.permissions?.includes(
    PermissionTypes.ViewAccessRequests,
  )

  return Boolean(dataAccessRequestsEnabled && hasViewAccessRequestsPermission)
}

export const useCanViewCreateTicket = ({ data }: EmployeeButtonProps) => {
  const permissions = useSelector(selectPermissions)
  const isCommercial = useIsCommercial()
  const existingTicketUrl = data.lifecycle_ticket_url
  const canCreateLifecycleTicket =
    permissions?.includes(PermissionTypes.CanCreateLifecycleTicket) && !existingTicketUrl

  return Boolean(canCreateLifecycleTicket && !isCommercial)
}

export const useCanViewFlagPerformance = ({ data }: EmployeeButtonProps) => {
  const isCommercial = useIsCommercial()
  const canInitiateTermination = data?.field_options.permissions?.includes(
    PermissionTypes.CanInitiateTermination,
  )

  return Boolean(data.id && canInitiateTermination && !isCommercial)
}

export const useCanViewAnonymiseProfile = ({ data }: EmployeeButtonProps) => {
  const featureFlags = useSelector(selectFeatureFlags)
  const isAnonymiseFeatureEnabled = featureFlags.includes(
    FeatureFlags.EmployeeAnonymizationEnabled,
  )

  return Boolean(data.id && isAnonymiseFeatureEnabled)
}

export const useCanViewReferrals = ({ data }: { data?: EmployeeInterface }) => {
  const user = useSelector(selectUser)
  const permissions = useSelector(selectPermissions)
  const isCurrentUser = data?.id && data.id === user.id
  const { data: candidatesSettings } = useGetCandidateSettings()

  return Boolean(
    data?.id &&
      candidatesSettings?.enable_referral &&
      (isCurrentUser || permissions.includes(PermissionTypes.ViewApplicationForm)),
  )
}

export const canViewSetInactive = (
  employeeInactivity: UseEmployeeInactivityDataReturnType,
  statusTransitions: UseEmployeeStatusTransitionsReturnType,
) => {
  const { status_transitions } = statusTransitions.data || {}
  return (
    status_transitions?.some(status => status.id === IdStatuses.inactive) &&
    !isInactivityScheduled(employeeInactivity.data)
  )
}

export const canViewTerminate = (data: EmployeeInterface) =>
  data.id &&
  data?.field_options.permissions?.includes(PermissionTypes.CanTerminateEmployees) &&
  !data.has_termination

export const useCanViewAssignPermissionGroup = () => {
  const permissions = useSelector(selectPermissions)
  return !!permissions?.includes(PermissionTypes.AddUserPermissions)
}

export const useCanViewCandidate = (data: EmployeeInterface) => {
  const permissions = useSelector(selectPermissions)
  return !!data.candidate_id && permissions.includes(PermissionTypes.ViewCandidate)
}
