import * as React from 'react'
import { FormEvent } from 'react'
import { useLapeField } from '@src/features/Form/LapeForm'
import { connect } from 'lape'
import {
  Box,
  Input,
  InputProps,
  VStack,
  Text,
  MoneyInputProps,
  MoneyType,
} from '@revolut/ui-kit'
import {
  getCommonInputProps,
  HideInputCalendarIndicatorCss,
} from '@components/Inputs/LapeFields/helpers'
import { useNewChangelogProps } from '@src/utils/form'
import { useFormValidator } from '@src/features/Form/FormValidator'

interface Props extends Omit<InputProps | MoneyInputProps, 'value' | 'onChange'> {
  name: string
  title?: string
  value?: string
  disabled?: boolean
  hasError?: boolean
  flex?: number | string
  onChange?: (e: FormEvent<HTMLInputElement>) => void
  onAfterChange?: (value?: string) => void
  /** Marks field as optional otherwise */
  required?: boolean
  /** Hides the optional text for display only fields */
  hideOptional?: boolean
}

const WithTitle = ({
  title,
  children,
}: {
  title?: string
  children: React.ReactNode
}) => {
  if (title) {
    return (
      <VStack space="s-16">
        <Text variant="tile" use="div" ml="s-4">
          {title}
        </Text>
        {children}
      </VStack>
    )
  }
  return <>{children}</>
}

const LapeNewInput = ({
  title,
  name,
  hideOptional,
  flex,
  onAfterChange = () => {},
  ...props
}: Props) => {
  const lapeProps = useLapeField(name)
  const formValidator = useFormValidator()
  const changelog = useNewChangelogProps(name)
  const { value, onChange, hidden } = lapeProps

  if (hidden) {
    return null
  }

  const inputProps = {
    onWheel: event => event.currentTarget.blur(),
    value: value !== undefined ? value : null,
    css: { HideInputCalendarIndicatorCss },
    ...props,
    renderAction: (...args) => (
      <>
        {props.renderAction && <Box mr="s-8">{props.renderAction(...args)}</Box>}
        {changelog.renderAction && changelog.renderAction()}
      </>
    ),
    ...getCommonInputProps(
      { ...props, hideOptional, name },
      lapeProps,
      !!formValidator?.validated,
    ),
  } as Omit<InputProps, 'onChange'>

  const handleChange = (e: FormEvent<HTMLInputElement> | number | null) => {
    const currentValue = typeof e === 'number' ? e : e?.currentTarget?.value

    if (currentValue == null) {
      onChange(undefined)
      onAfterChange(undefined)
    } else if (props.max && +currentValue > +props.max) {
      onChange(props.max)
      onAfterChange(`${props.max}`)
    } else if (props.min && +currentValue < +props.min) {
      onChange(props.min)
      onAfterChange(`${props.min}`)
    } else {
      onChange(String(currentValue))
      onAfterChange(String(currentValue))
    }
  }

  if (props.type === MoneyType.MONEY || props.type === MoneyType.MONEY_FRACTIONAL) {
    return (
      <WithTitle title={title}>
        <Input
          type={props.type as MoneyType}
          onChange={(e: number | null) => handleChange(e)}
          {...(inputProps as Omit<MoneyInputProps, 'onChange' | 'type'>)}
        />
      </WithTitle>
    )
  }
  return (
    <WithTitle title={title}>
      <Input
        onChange={(e: FormEvent<HTMLInputElement>) => handleChange(e)}
        {...inputProps}
      />
    </WithTitle>
  )
}

export default connect(LapeNewInput)
