import React, { useState, useEffect } from 'react'
import {
  Item,
  Subheader,
  Avatar,
  Color,
  Group,
  ExpandableCell,
  Box,
  chain,
  Text,
  Flex,
  ActionButton,
  Token,
  Icon,
} from '@revolut/ui-kit'
import { useParams, useRouteMatch } from 'react-router-dom'
import { capitalize } from 'lodash'

import {
  downloadDocusignEmbedded,
  signEmbedded,
  getEmbeddedInfo,
} from '@src/api/documents'
import Loader from '@src/components/CommonSC/Loader'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { PageBody } from '@src/components/Page/PageBody'
import { PageWrapper } from '@src/components/Page/Page'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { FormPreview } from '@src/components/FormPreview/FormPreview'
import { EmbeddedDocumentInterface } from '@src/interfaces/documents'
import { PermissionTypes } from '@src/store/auth/types'
import Tooltip from '@components/Tooltip/Tooltip'
import ActionWidget from '@src/components/ActionWidget/ActionWidget'
import NewLoadingButton from '@src/components/Button/NewLoadingButton'
import { getStatusColor } from '@src/components/CommonSC/General'
import { InstructionsSidebar } from '../DocumentUpload/common'
import UserWithAvatar from '@src/components/UserWithAvatar/UserWithAvatar'
import { useGetDocumentsSettings } from '@src/api/settings'

const Embedded = () => {
  const params = useParams<{ id: string; employeeId: string }>()
  const { data: documentsSettings } = useGetDocumentsSettings()

  const [instructionsOpen, setInstructionsOpen] = useState(false)
  const [data, setData] = useState<EmbeddedDocumentInterface>()

  useEffect(() => {
    getEmbeddedInfo(params.id).then(response => setData(response.data))
  }, [])

  const isOnboardingUser = !!useRouteMatch(ROUTES.ONBOARDING.ANY)

  if (!data) {
    return (
      <PageWrapper>
        <Loader />
      </PageWrapper>
    )
  }

  const canDownload =
    data?.status === 'completed' &&
    data?.field_options?.permissions?.includes?.(
      PermissionTypes.DownloadSentenveloperecipientsview,
    )
  const canSign =
    data?.status === 'pending' &&
    data?.field_options?.permissions?.includes?.(
      PermissionTypes.SignSentenveloperecipientsview,
    ) &&
    documentsSettings?.enable_docusign_integration

  const isNext =
    data?.is_recipient && data?.is_next_in_signing && !data?.is_envelope_completed

  const totalCount = data.signers.length
  const pendingCount = data.signers.reduce(
    (count, signer) => (signer.status === 'pending' ? count + 1 : count),
    0,
  )
  const signatureCellContent =
    pendingCount > 0 ? (
      <Text color={Color.ORANGE}>
        {pendingCount}/{totalCount} · Pending signature
      </Text>
    ) : (
      <Text color={Color.GREEN}>Completed</Text>
    )
  const waitingForOtherSigners =
    !isNext && data.is_envelope_recipient && !data.is_envelope_completed

  return (
    <>
      <PageWrapper>
        <PageHeader
          title={chain(
            data.document_name,
            <Text color={getStatusColor(data.status)}>{capitalize(data.status)}</Text>,
          )}
          subtitle={<UserWithAvatar {...data.employee} asText />}
          backUrl={pathToUrl(ROUTES.FORMS.EMPLOYEE.DOCUMENTS, { id: params.employeeId })}
        />

        <PageBody>
          {data.instructions ? (
            <ActionButton
              onClick={() => setInstructionsOpen(!instructionsOpen)}
              useIcon="InfoOutline"
              mb="s-16"
            >
              Instructions
            </ActionButton>
          ) : null}
          <Subheader variant="nested">
            <Subheader.Title>Document</Subheader.Title>
          </Subheader>
          {waitingForOtherSigners && (
            <ActionWidget title="Document is waiting for other signers." mb="s-16" />
          )}
          <Item>
            <Item.Avatar>
              <Avatar useIcon="Document">
                {data.status === 'completed' ? (
                  <Avatar.Badge useIcon="Check" bg={Token.color.green} />
                ) : (
                  <Avatar.Badge useIcon="16/SandWatch" bg={Token.color.orange} />
                )}
              </Avatar>
            </Item.Avatar>
            <Item.Content>
              <Item.Title>{data.document_name}</Item.Title>
            </Item.Content>
            <Item.Side>
              {canSign && (
                <Tooltip
                  hide={isNext}
                  text={
                    data.is_envelope_recipient
                      ? 'It is not your turn to sign the document'
                      : 'You can not sign this document, you are not the recipient'
                  }
                  placement="top"
                >
                  <NewLoadingButton
                    onSubmit={() =>
                      signEmbedded(
                        data.id,
                        isOnboardingUser
                          ? {
                              return_page: 'onboarding_documents',
                            }
                          : undefined,
                      )
                    }
                    disabled={!isNext}
                  >
                    Read &amp; sign
                  </NewLoadingButton>
                </Tooltip>
              )}
              {canDownload && (
                <NewLoadingButton
                  onSubmit={() => downloadDocusignEmbedded(data?.envelope_uuid)}
                  disabled={waitingForOtherSigners}
                >
                  Download
                </NewLoadingButton>
              )}
            </Item.Side>
          </Item>

          <Box mt="s-16">
            <FormPreview title="Details" data={data}>
              <Group>
                {isOnboardingUser ? (
                  <FormPreview.Item title="Issued by" field="sent_by.name" />
                ) : (
                  <FormPreview.Item<EmbeddedDocumentInterface>
                    title="Issued by"
                    field="sent_by.name"
                    to={document =>
                      pathToUrl(ROUTES.FORMS.EMPLOYEE.PROFILE, {
                        id: document.sent_by.id,
                      })
                    }
                  />
                )}
                <FormPreview.Item
                  title="Issued on"
                  field="creation_date_time"
                  type="date"
                />
                <FormPreview.Item title="Document category" field="category.name" />
                <FormPreview.Item title="Signed on" field="signed_on" type="date" />
                <ExpandableCell>
                  <ExpandableCell.Title>Signature status</ExpandableCell.Title>
                  <ExpandableCell.Content>{signatureCellContent}</ExpandableCell.Content>
                  <ExpandableCell.Note>
                    <Flex flexDirection="column" gap="s-16">
                      {data.signers.map(signer => (
                        <Box key={signer.routing_order}>
                          <Flex alignItems="center" mb="s-8">
                            <Box width="s-24">
                              {signer.status === 'completed' ? (
                                <Icon name="Check" color={Token.color.green} size={16} />
                              ) : (
                                <Icon name="Time" color={Token.color.orange} size={16} />
                              )}
                            </Box>
                            <Text variant="h6">
                              {chain(
                                `Signatory ${signer.routing_order}`,
                                capitalize(signer.status),
                              )}
                            </Text>
                          </Flex>
                          <Flex style={{ whiteSpace: 'pre' }}>
                            <UserWithAvatar
                              {...signer.employee}
                              asText={isOnboardingUser}
                              ml="s-24"
                            />
                            {signer.is_next_in_signing ? ` (current signer)` : ''}
                          </Flex>
                        </Box>
                      ))}
                    </Flex>
                  </ExpandableCell.Note>
                </ExpandableCell>
              </Group>
            </FormPreview>
          </Box>
        </PageBody>
      </PageWrapper>

      <InstructionsSidebar
        isOpen={instructionsOpen}
        onClose={() => setInstructionsOpen(false)}
        instructions={data.instructions}
      />
    </>
  )
}

export default Embedded
