import React, { useEffect, useState } from 'react'
import { useGetPerformanceSettings } from '@src/api/performanceSettings'
import { GoalKpiDetails } from '@src/interfaces/goals'
import { KpiInterface } from '@src/interfaces/kpis'

import AddGoalTargetForm from '../../SidebarForms/AddGoalTargetForm'
import { TargetCell } from './TargetCell'

const goalTargetToKpi = (target: GoalKpiDetails): KpiInterface => ({
  ...target,
  status: target.status?.id,
})

export const GoalMetricsItems = ({
  metrics,
  onCopy,
}: {
  metrics: GoalKpiDetails[]
  onCopy: (position: number | undefined) => void
}) => {
  const [expandedId, setExpandedId] = useState<number | undefined>(
    metrics.at(-1)?.tempId || metrics.at(-1)?.id,
  )

  useEffect(() => {
    // open last item when item is added or deleted
    setExpandedId(metrics.at(-1)?.tempId || metrics.at(-1)?.id)
  }, [metrics.length])

  const { data: performanceSettings } = useGetPerformanceSettings()
  const isMultipleGoalsTargetsEnabled =
    performanceSettings?.enable_multiple_goal_targets_per_cycle

  return (
    <>
      {metrics.map((metric, index) => {
        const id = metric.id || metric.tempId
        return (
          <TargetCell
            allowDelete={metrics.length > 1}
            target={metric}
            index={index + 1}
            key={id}
            expanded={expandedId === id}
            onToggle={() => setExpandedId(prev => (prev !== id ? id : undefined))}
            onCopy={isMultipleGoalsTargetsEnabled ? () => onCopy(id) : undefined}
          >
            <AddGoalTargetForm
              initialValues={goalTargetToKpi(metric)}
              // @ts-expect-error it's the expected type but there is multiple diferent review cycle types
              // and changing it in one place causes other places to be incorrect and I don't want to typecast
              initialCycle={metric.targets.at(0)?.review_cycle}
            />
          </TargetCell>
        )
      })}
    </>
  )
}
