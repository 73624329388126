import React, { useMemo } from 'react'
import uniqBy from 'lodash/uniqBy'

import { ReviewerRelation, ReviewInterface } from '@src/interfaces/performance'
import { OptionInterface } from '@src/interfaces/selectors'
import { relationToString } from '@src/features/Scorecard/constants'
import { Queries } from '@src/constants/api'
import Filter, { QuickFilter } from './Filter'

interface Props {
  data: ReviewInterface[]
  onFilterChange?: (options: OptionInterface[] | undefined) => void
  prefix?: string
  isClearable?: boolean
  showFilters?: boolean
  className?: string
  labelAll?: string
  hideDropdown?: boolean
  hideAll?: boolean
  additionalFilters?: QuickFilter[]
  additionalOptions?: ReviewInterface[]
}

const ReviewersFilter = ({
  data,
  additionalFilters,
  additionalOptions,
  ...rest
}: Props) => {
  const initialFilter: QuickFilter[] = [
    ...(additionalFilters || []),
    {
      key: ReviewerRelation.Self,
      name: relationToString(ReviewerRelation.Self, true),
      filters: [],
    },
    {
      key: ReviewerRelation.LineManager,
      name: relationToString(ReviewerRelation.LineManager, true),
      filters: [],
    },
    {
      key: ReviewerRelation.FunctionalManager,
      name: relationToString(ReviewerRelation.FunctionalManager, true),
      filters: [],
    },
    {
      key: ReviewerRelation.Other,
      name: relationToString(ReviewerRelation.Other, true, 'Peers'),
      filters: [],
    },
  ]

  const options = useMemo(() => {
    const adjustedData = additionalOptions ? [...additionalOptions, ...data] : data
    return uniqBy(
      adjustedData.map(review => {
        return {
          id: review.reviewer.id ?? review.id,
          name: review.reviewer_relation
            ? `${review.reviewer.display_name} (${relationToString(
                review.reviewer_relation,
                true,
              )})`
            : review.reviewer.display_name,
        }
      }),
      ({ id }) => id,
    )
  }, [data])

  const quickFilters = useMemo(
    () =>
      data.reduce((acc, review) => {
        const relation =
          review.reviewer_relation === ReviewerRelation.TeamMate
            ? ReviewerRelation.Other
            : review.reviewer_relation

        const index = acc.findIndex(el => el.key === relation)

        if (index >= 0 && !acc[index].filters.includes(review.reviewer.id)) {
          acc[index].filters.push(review.reviewer.id)
        }
        return acc
      }, initialFilter),
    [data],
  )

  return (
    <Filter
      options={options}
      quickFilters={quickFilters}
      filterKey={Queries.ReviewerId}
      {...rest}
    />
  )
}

export default ReviewersFilter
