import { roadmapsRequests } from '@src/api/roadmaps'
import { useTable } from '@src/components/Table/hooks'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { SORT_DIRECTION } from '@src/interfaces/data'
import { GoalsInterface } from '@src/interfaces/goals'
import { RoadmapInterface } from '@src/interfaces/roadmaps'
import { GoalCycleSelectType } from '@src/pages/Forms/GoalForm/Form/Preview/GoalPreviewPage'
import { useEffect } from 'react'

export const useGoalRoadmapsTable = (cycle?: GoalCycleSelectType) => {
  const { values } = useLapeContext<GoalsInterface>()

  const roadmapsTable = useTable<RoadmapInterface>(
    roadmapsRequests,
    [
      {
        columnName: 'goal__id',
        filters: [{ id: values.id, name: values.name }],
        nonResettable: true,
      },
      cycle
        ? {
            columnName: 'review_cycle__id',
            // using "cycle.id!" because type system doesn't support ID as undefined and it's a required field
            // we need undefined here to be able to select "all cycles" as filter value, not just clear the filter
            // refactoring type system seems to be time consuming
            filters: [{ id: cycle.id!, name: values.name }],
            nonResettable: true,
          }
        : null,
    ].filter(Boolean),
    [{ sortBy: 'review_cycle__offset', direction: SORT_DIRECTION.DESC }],
    { disableQuery: true },
  )

  useEffect(() => {
    values.roadmaps = // BE returns children which is not declared in type but required
      // fixing type would need more effort
      (roadmapsTable.data as Array<RoadmapInterface & { children: never }>).map(
        ({ children, ...r }) => r,
      )
  }, [roadmapsTable.data])

  return roadmapsTable
}
