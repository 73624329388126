import React, { useEffect } from 'react'
import pluralize from 'pluralize'

import { ItemSkeleton, VStack, Text, Color } from '@revolut/ui-kit'
import { CommentItem } from '@src/apps/People/Engagement/Results/components/Comments/CommentItem'
import { useInfiniteCommentsLoading } from '@src/apps/People/Engagement/Results/hooks'
import { EngagementResultsScope } from '@src/interfaces/engagement'
import { useResultsCommentsStats } from '@src/api/engagement'
import { FilterByInterface } from '@src/interfaces/data'

type Props<T> = {
  scope?: EngagementResultsScope
  data: T
  filtersParams: Object
  setHasAnonymisedData: (hasData: boolean) => void
}
export const FeedbackCells = <T extends { id: number }>({
  scope,
  data,
  setHasAnonymisedData,
  filtersParams,
}: Props<T>) => {
  const { comments, isLoadingMore, isReloadingFilters, ref, hasMoreData, page } =
    useInfiniteCommentsLoading({
      scope,
      scopeId: data.id,
      filtersParams,
    })

  const stats = useResultsCommentsStats(
    scope,
    data.id,
    filtersParams as FilterByInterface[],
    page,
  )

  useEffect(() => {
    setHasAnonymisedData(stats.data?.can_show_details === false)
  }, [stats.data?.can_show_details])

  return (
    <VStack>
      <Text use="div" mb="s-24" color={Color.GREY_TONE_50}>
        Showing {pluralize('answer', comments.length, true)}
      </Text>
      {!isLoadingMore && isReloadingFilters ? (
        <VStack>
          <ItemSkeleton />
          <ItemSkeleton />
          <ItemSkeleton />
        </VStack>
      ) : (
        <VStack>
          {comments.map(comment => {
            return <CommentItem key={comment.id} comment={comment} />
          })}
        </VStack>
      )}
      <VStack ref={ref}>
        {isLoadingMore && hasMoreData && (
          <>
            <ItemSkeleton />
            <ItemSkeleton />
            <ItemSkeleton />
          </>
        )}
      </VStack>
    </VStack>
  )
}
