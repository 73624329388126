import {
  EmployeeContractsInterface,
  EmployeeContractsStatsInterface,
} from '@src/interfaces/employees'
import { Text, Token } from '@revolut/ui-kit'
import { GetRequestData } from '@src/interfaces'
import React, { useState } from 'react'
import { useEmployeeContracts, useEmployeeContractsStats } from '@src/api/employees'
import { QueryObserverResult } from 'react-query'

const getContractsWidgetHint = (stats?: EmployeeContractsStatsInterface) => {
  if (stats?.pending) {
    return <Text color={Token.color.orange}>{stats.pending} pending</Text>
  }
  return null
}

const getActiveContract = (
  contractsData?: GetRequestData<EmployeeContractsInterface>,
) => {
  return contractsData?.results.filter(contract => contract.is_primary_contract)?.[0]
}

export const useEmployeeContractsWidget = (employeeId: number | string | undefined) => {
  const [loadContracts, setLoadContracts] = useState(false)
  const {
    data: contractsData,
    refetch,
    status,
    isRefetching,
  } = useEmployeeContracts(loadContracts ? employeeId : undefined, [
    { columnName: 'contract_status', filters: [{ id: 'active', name: 'active' }] },
  ])

  const { data: stats } = useEmployeeContractsStats(
    loadContracts ? employeeId : undefined,
  )

  return {
    hint: getContractsWidgetHint(stats),
    data: getActiveContract(contractsData),
    handler: {
      load: () => setLoadContracts(true),
      refetch: async () => {
        const res = await refetch()
        return { ...res, data: getActiveContract(res.data) } as QueryObserverResult<
          EmployeeContractsInterface,
          Error
        >
      },
      isRefetching,
      status,
      enabled: loadContracts,
    },
  }
}
