import React, { useState } from 'react'
import {
  Action,
  IconButton,
  MoreBar,
  StatusPopup,
  Token,
  useStatusPopup,
} from '@revolut/ui-kit'
import { cloneSpecialisationHiringProcess } from '@src/api/hiringProcess'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { SpecialisationHiringProcess } from '@src/interfaces/hiringProccess'
import { useSelector } from 'react-redux'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'

type CloneHiringProcessProps = {
  hiringProcess: SpecialisationHiringProcess
  name: string
  variant: 'more-bar' | 'action' | 'duplicate-icon'
}

export const CloneHiringProcess = ({
  hiringProcess,
  name,
  variant,
}: CloneHiringProcessProps) => {
  const permissions = useSelector(selectPermissions)
  const canCreate = permissions.includes(PermissionTypes.AddHiringProcess)
  const [loading, setLoading] = useState(false)
  const statusPopup = useStatusPopup()
  const handleCreateNewHiringProcess = async () => {
    setLoading(true)
    try {
      const res = await cloneSpecialisationHiringProcess(
        hiringProcess.specialisation.id,
        hiringProcess.id,
        name,
      )
      setLoading(false)
      if (res.data.id) {
        navigateTo(
          pathToUrl(ROUTES.FORMS.SPECIALISATION_HIRING_PROCESS.GENERAL, {
            hiringProcessId: res.data.id,
            specialisationId: hiringProcess.specialisation.id,
          }),
        )
      }
    } catch {
      setLoading(false)
      statusPopup.show(
        <StatusPopup variant="error" onClose={statusPopup.hide}>
          <StatusPopup.Title>
            There was an error {variant === 'duplicate-icon' ? 'duplicating' : 'creating'}{' '}
            hiring process, please refresh and try again
          </StatusPopup.Title>
        </StatusPopup>,
      )
    }
  }
  if (!canCreate) {
    return null
  }
  if (variant === 'more-bar') {
    return (
      <MoreBar.Action
        useIcon="Plus"
        onClick={handleCreateNewHiringProcess}
        pending={loading}
      >
        Create new
      </MoreBar.Action>
    )
  }
  if (variant === 'action') {
    return (
      <Action
        useIcon="Plus"
        onClick={handleCreateNewHiringProcess}
        variant="text"
        pending={loading}
      >
        Create new
      </Action>
    )
  }
  if (variant === 'duplicate-icon') {
    return (
      <IconButton
        color={Token.color.greyTone20}
        useIcon="Copy"
        onClick={handleCreateNewHiringProcess}
      />
    )
  }
  return null
}
