import React, { useEffect } from 'react'

import AdjustableTable from '@src/components/Table/AdjustableTable'
import {
  employeeEntityColumn,
  employeeNameColumn,
  employeeNameIdStatusColumn,
  employeeTypeColumn,
  employeeStartedAtColumn,
  employeeCountryColumn,
} from '@src/constants/columns/employee'
import { teamNameColumn } from '@src/constants/columns/team'
import { roleNameColumn } from '@src/constants/columns/role'
import { seniorityNameColumn } from '@src/constants/columns/seniority'
import { locationNameColumn } from '@src/constants/columns/location'
import { FilterByInterface, RowInterface } from '@src/interfaces/data'
import { useTable } from '@src/components/Table/hooks'
import { getRevolutersItems } from '@src/api/revoluters'
import { RevolutersInterface } from '@src/interfaces/revoluters'
import { departmentNameRevolutersColumn } from '@src/constants/columns/department'
import { TableNames } from '@src/constants/table'

export type TableFilter = Record<string, (string | number)[]>

const Row: RowInterface<RevolutersInterface> = {
  cells: [
    {
      ...employeeNameColumn,
      width: 150,
    },
    {
      ...employeeTypeColumn,
      width: 100,
    },
    {
      ...departmentNameRevolutersColumn,
      width: 100,
    },
    {
      ...teamNameColumn,
      width: 100,
    },
    {
      ...roleNameColumn,
      width: 130,
    },
    {
      ...seniorityNameColumn,
      width: 100,
    },
    {
      ...employeeCountryColumn,
      width: 100,
    },
    {
      ...locationNameColumn,
      width: 100,
    },
    {
      ...employeeEntityColumn,
      width: 120,
    },
    {
      ...employeeStartedAtColumn,
      width: 120,
    },
    {
      ...employeeNameIdStatusColumn,
      width: 160,
    },
  ],
}

interface AudienceTableProps {
  filter?: TableFilter | null
  onFilterChange?: (filters: TableFilter) => void
  isPreview?: boolean
}

export const AudienceTable = ({
  isPreview,
  filter,
  onFilterChange,
}: AudienceTableProps) => {
  const initialFilters: FilterByInterface[] = filter
    ? Object.keys(filter).map(key => ({
        columnName: key,
        filters: filter![key].map((prop: string | number) => ({
          id: prop,
          name: `${prop}`,
        })),
      }))
    : []

  const tableOptions = {
    disableQuery: true,
  }

  const employeesTable = useTable<RevolutersInterface>(
    { getItems: getRevolutersItems },
    initialFilters,
    [],
    tableOptions,
  )

  useEffect(() => {
    onFilterChange?.(
      employeesTable.filterBy.reduce(
        (obj, tableFilter) => ({
          ...obj,
          [tableFilter.columnName]: tableFilter.filters.map(f => f.id),
        }),
        {},
      ),
    )
  }, [employeesTable.filterBy])

  return (
    <AdjustableTable
      name={TableNames.DynamicGroupsAudience}
      row={Row}
      {...employeesTable}
      noReset={isPreview}
      disabledFiltering={isPreview}
      useWindowScroll
      onFilterChange={f => employeesTable?.onFilterChange(f, false)}
    />
  )
}
