import React from 'react'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { Flex, MoreBar, Widget } from '@revolut/ui-kit'
import SettingsButtons from '@src/features/SettingsButtons'
import { Plus } from '@revolut/icons'
import AdjustableTable from '@components/Table/AdjustableTable'
import { GroupAccessManagementInterface } from '@src/interfaces/accessManagement'
import { useTable } from '@components/Table/hooks'
import { groupsRequests } from '@src/api/accessManagement'
import { RowInterface, SORT_DIRECTION } from '@src/interfaces/data'
import { navigateTo } from '@src/actions/RouterActions'
import {
  certificationExpirationDateColumn,
  descriptionColumn,
  groupNameColumn,
  ownerColumn,
  permissionsCountColumn,
} from '@src/constants/columns/accessManagement/accessManagementGroups'
import { useSelector } from 'react-redux'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import SearchTable from '@components/Table/SearchTable/SearchTable'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { TableNames } from '@src/constants/table'
import Stat from '@src/components/Stat/Stat'

const Row: RowInterface<GroupAccessManagementInterface> = {
  linkToForm: ({ id }) =>
    navigateTo(pathToUrl(ROUTES.FORMS.ACCESS_MANAGEMENT_GROUP.DETAILS, { id })),
  cells: [
    {
      ...groupNameColumn,
      width: 300,
    },
    {
      ...descriptionColumn,
      width: 450,
    },
    {
      ...permissionsCountColumn,
      width: 150,
    },
    {
      ...ownerColumn,
      width: 300,
    },
    {
      ...certificationExpirationDateColumn,
      width: 300,
    },
  ],
}

const GroupsTable = () => {
  const permissions = useSelector(selectPermissions)
  const table = useTable<GroupAccessManagementInterface>(groupsRequests, undefined, [
    {
      sortBy: 'settings__certification_expiration_date_time',
      direction: SORT_DIRECTION.DESC,
      nonResettable: true,
    },
  ])

  const canAdd = permissions?.includes(PermissionTypes.AddGroup)

  return (
    <Widget p="s-16">
      <Flex flexWrap="wrap" justifyContent="space-between" mb="s-16">
        <Stat label="Groups" val={table?.loading ? undefined : table?.count} />
        <SearchTable
          placeholder="Search by group or description"
          onFilter={table.onFilterChange}
        />
      </Flex>
      <SettingsButtons pb="s-16">
        {canAdd && (
          <MoreBar.Action
            useIcon={Plus}
            use={InternalLink}
            to={pathToUrl(ROUTES.FORMS.ACCESS_MANAGEMENT_GROUP.GENERAL)}
          >
            Add group
          </MoreBar.Action>
        )}
      </SettingsButtons>
      <Flex flexDirection="column" width="100%">
        <Flex style={{ position: 'relative' }} flex="1 0">
          <AdjustableTable<GroupAccessManagementInterface>
            name={TableNames.Groups}
            useWindowScroll
            dataType="Groups"
            row={Row}
            {...table}
            noDataMessage="Groups will appear here."
          />
        </Flex>
      </Flex>
    </Widget>
  )
}

export default GroupsTable
