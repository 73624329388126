import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { useQuery } from '@src/utils/queryParamsHooks'
import { magicLinkLogin } from '@src/api/login'
import PageLoading from '@src/components/PageLoading/PageLoading'
import { VerificationCodeForm } from './common'
import { setAuthenticatedAction } from '@src/store/auth/actions'
import { cookiesApi } from '@src/utils/cookies'
import { COOKIE } from '@src/constants/api'
import { Authenticated } from '@src/store/auth/constants'
import { useWorkspaceContext } from '@src/features/Workspaces/WorkspaceContext'
import { LocalStorageKeys } from '@src/store/auth/types'

const onSuccessLogin = (
  employeeId: string | number,
  dispatchAuthenticated: () => void,
  workspace?: string,
) => {
  cookiesApi.set(COOKIE.AUTHENTICATED, Authenticated.authenticated)
  dispatchAuthenticated()
  navigateTo(pathToUrl(ROUTES.ONBOARDING.START, { id: employeeId }))
  if (workspace) {
    localStorage.setItem(LocalStorageKeys.ACTIVE_WORKSPACE, workspace)
  }
}

export const MagicLinkLogin = () => {
  const { query } = useQuery<{ email: string; token: string }>()
  const dispatch = useDispatch()

  const workspaceContext = useWorkspaceContext()

  const [loginState, setLoginState] = useState<{ employeeId: number; token: string }>()

  const dispatchAuthenticated = () => dispatch(setAuthenticatedAction(true))

  useEffect(() => {
    magicLinkLogin(query.email, query.token).then(response => {
      if (response.data.two_factor_authentication_enabled) {
        setLoginState({
          employeeId: response.data.employee_id,
          token: response.data.token,
        })
      } else {
        onSuccessLogin(
          response.data.employee_id,
          dispatchAuthenticated,
          workspaceContext?.workspace,
        )
      }
    })
  }, [])

  if (!loginState) {
    return <PageLoading />
  }

  return (
    <VerificationCodeForm
      token={loginState.token}
      onSuccess={() => {
        onSuccessLogin(
          loginState.employeeId,
          dispatchAuthenticated,
          workspaceContext?.workspace,
        )
      }}
    />
  )
}
