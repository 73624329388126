import React, { useEffect, useState } from 'react'
import { Avatar, Cell, Group, Item, Header, MoreBar, Tag, VStack } from '@revolut/ui-kit'
import { navigateTo } from '@src/actions/RouterActions'
import { formDocumentsCollectionsRequests } from '@src/api/documentsCollections'
import {
  downloadDocumentTemplateFile,
  downloadTemplateFileAsBlob,
} from '@src/api/documentsTemplates'
import { ROUTES } from '@src/constants/routes'
import { DocumentsCollectionsInterface } from '@src/interfaces/documentsCollections'
import { DocumentsTemplatesInterface } from '@src/interfaces/documentsTemplates'
import { FormPreview } from '@src/components/FormPreview/FormPreview'
import { PageBody } from '@src/components/Page/PageBody'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import SideBar from '@src/components/SideBar/SideBar'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { EntityPermissions } from '@src/store/auth/types'
import SettingsButtons from '@src/features/SettingsButtons'
import { EditButton } from '@src/features/SettingsButtons/EditButton/EditButton'
import { AttachmentSidebarContents } from '@src/pages/Forms/DocumentUpload/common'
import { DeleteButtonLape } from '@src/pages/Forms/FormButtons'
import { pathToUrl } from '@src/utils/router'

const BACK_URL = ROUTES.APPS.DOCUMENTS.COLLECTIONS

export const Preview = () => {
  const { values } = useLapeContext<DocumentsCollectionsInterface>()
  const [attachment, setAttachment] = useState<string | null>(null)
  const [downloadPending, setDownloadPending] = useState(false)
  const [templatePreview, setTemplatePreview] =
    useState<Partial<DocumentsTemplatesInterface>>()

  const canEdit = values.field_options?.actions?.includes(EntityPermissions.Change)
  const canDelete = values.field_options?.actions?.includes(EntityPermissions.Delete)

  useEffect(() => {
    if (templatePreview?.file_name) {
      downloadTemplateFileAsBlob(templatePreview.id!).then(response =>
        setAttachment(response),
      )
    }
  }, [templatePreview])

  const onDownloadAttachment = () => {
    setDownloadPending(true)

    downloadDocumentTemplateFile(templatePreview?.id!).finally(() =>
      setDownloadPending(false),
    )
  }

  return (
    <>
      <PageHeader backUrl={BACK_URL} title={values.name} />
      <PageBody>
        <SettingsButtons mb="s-16">
          {canEdit && (
            <EditButton
              route={pathToUrl(ROUTES.FORMS.DOCUMENT_COLLECTION.GENERAL, {
                id: values.id,
              })}
            />
          )}
          {canDelete && (
            <DeleteButtonLape
              backUrl={BACK_URL}
              data={values}
              deleteApi={formDocumentsCollectionsRequests.delete!}
              title="collection"
              useMoreBar
            />
          )}
        </SettingsButtons>
        {!!values.document_templates?.length && (
          <Cell px={0}>
            <VStack space="s-16" width="100%">
              {values.document_templates.map(template => (
                <Item
                  key={template.id}
                  onClick={e => {
                    e.preventDefault()
                    e.stopPropagation()
                    setTemplatePreview(template)
                  }}
                  use="button"
                >
                  <Item.Avatar>
                    <Avatar useIcon="Document" />
                  </Item.Avatar>
                  <Item.Content>
                    <Item.Title>{template.name}</Item.Title>
                  </Item.Content>
                  <Item.Side>
                    <Tag variant="faded">
                      {template.template_type?.id === 'request'
                        ? 'Document'
                        : 'Signature'}
                    </Tag>
                  </Item.Side>
                </Item>
              ))}
            </VStack>
          </Cell>
        )}
      </PageBody>

      <SideBar
        headerContent={
          <Header.Bar>
            <MoreBar>
              {templatePreview?.file_name && (
                <MoreBar.Action
                  onClick={onDownloadAttachment}
                  pending={downloadPending}
                  useIcon="Download"
                >
                  Download
                </MoreBar.Action>
              )}
              <MoreBar.Action
                onClick={() =>
                  navigateTo(
                    pathToUrl(ROUTES.FORMS.DOCUMENT_TEMPLATE.PREVIEW, {
                      id: values.id,
                    }),
                  )
                }
                useIcon="LinkExternal"
              >
                Open in Fullscreen
              </MoreBar.Action>
            </MoreBar>
          </Header.Bar>
        }
        isOpen={!!templatePreview}
        onClose={() => setTemplatePreview(undefined)}
        sideProps={{ resizable: true }}
        title={templatePreview?.name}
      >
        <VStack gap="s-16">
          <AttachmentSidebarContents
            file={attachment}
            file_media_type={templatePreview?.file_media_type}
            file_name={templatePreview?.file_name}
          />

          <FormPreview data={templatePreview}>
            <Group>
              <FormPreview.Item field="category.name" title="Category" />
              <FormPreview.Item
                field="creation_date_time"
                title="Created on"
                type="date"
              />
              <FormPreview.Item<DocumentsTemplatesInterface>
                field="owner.name"
                title="Owner"
                to={template =>
                  pathToUrl(ROUTES.FORMS.EMPLOYEE.PROFILE, { id: template.owner?.id })
                }
              />
            </Group>
          </FormPreview>
        </VStack>
      </SideBar>
    </>
  )
}
