import React, { useEffect } from 'react'
import { connect } from 'lape'
import { AxiosPromise } from 'axios'

import { PageBody } from '@components/Page/PageBody'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { PageActions } from '@components/Page/PageActions'
import { useLapeContext } from '@src/features/Form/LapeForm'
import SendEmployeeEmailCommonContent from '@src/pages/Forms/SendEmployeeEmail/SendEmployeeEmailCommonContent'
import {
  EmployeeWhenToSend,
  SendEmployeeEmailInterface,
} from '@src/interfaces/employeeEmails'

export type TemplateCategories =
  | 'other'
  | 'offboarding'
  | 'onboarding_welcome_email'
  | 'onboarding_day_one_instructions'

export type SendEmployeeEmailCommonProps = {
  children?: React.ReactNode
  onSuccess?: (email: SendEmployeeEmailInterface) => void
  fetchEmail?: (templateId: number) => AxiosPromise<SendEmployeeEmailInterface>
  replyEmail?: SendEmployeeEmailInterface
  bottom?: React.ReactNode
  actions?: React.ReactNode
  noEditor?: boolean
  successText?: string
  btnLabel?: string
  insideSidebar?: boolean
  templateFilter?: (option: {
    id: number | string
    category: { id: TemplateCategories }
  }) => boolean
}
const SendEmployeeEmailCommon = ({
  children,
  bottom,
  onSuccess,
  fetchEmail,
  replyEmail,
  actions,
  noEditor,
  successText = 'Email has been sent',
  btnLabel = 'Send email',
  insideSidebar,
  templateFilter,
}: SendEmployeeEmailCommonProps) => {
  const { values } = useLapeContext<SendEmployeeEmailInterface>()

  useEffect(() => {
    if (!values.sender_type) {
      values.sender_type = { id: 'user', name: 'User' }
    }

    if (!values.when_to_send) {
      values.when_to_send = { id: EmployeeWhenToSend.NOW, name: 'Send now' }
    }
  }, [])

  useEffect(() => {
    const fetchPrefilledEmail = async () => {
      if (values.email_template?.id && fetchEmail) {
        const resp = await fetchEmail(+values.email_template.id)
        values.email_body = resp.data.email_body
        values.sender_type = resp.data.sender_type
        values.recipients_cc = resp.data.recipients_cc
        values.recipients_bcc = resp.data.recipients_bcc
        values.subject = resp.data.subject
        values.attachments = resp.data.attachments
      }
    }

    fetchPrefilledEmail()
  }, [values.email_template?.id])

  useEffect(() => {
    if (!replyEmail) {
      return
    }

    values.sender_type = replyEmail.sender_type
    values.recipients_cc = replyEmail.recipients_cc
    values.recipients_bcc = replyEmail.recipients_bcc
    values.subject = `Re: ${replyEmail.subject}`
  }, [replyEmail])

  if (insideSidebar) {
    return (
      <SendEmployeeEmailCommonContent
        bottom={bottom}
        noEditor={noEditor}
        templateFilter={templateFilter}
      >
        {children}
      </SendEmployeeEmailCommonContent>
    )
  }

  return (
    <>
      <PageBody>
        <SendEmployeeEmailCommonContent
          bottom={bottom}
          noEditor={noEditor}
          templateFilter={templateFilter}
        >
          {children}
        </SendEmployeeEmailCommonContent>
      </PageBody>
      <PageActions>
        {actions || (
          <NewSaveButtonWithPopup<SendEmployeeEmailInterface>
            successText={successText}
            onAfterSubmit={onSuccess}
            useValidator
            hideWhenNoChanges={false}
            data-testid="btn-send-email"
          >
            {btnLabel}
          </NewSaveButtonWithPopup>
        )}
      </PageActions>
    </>
  )
}

export default connect(SendEmployeeEmailCommon)
