import React, { useEffect } from 'react'
import { useLapeContext } from '@src/features/Form/LapeForm'
import EmailForm from '@src/features/EmailForm/EmailForm'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import { selectorKeys } from '@src/constants/api'
import { InputGroup } from '@revolut/ui-kit'
import { SendCandidateEmailInterface } from '@src/interfaces/hiringProccess'
import { useSelector } from 'react-redux'
import { selectUser } from '@src/store/auth/selectors'
import { SchedulingEmailPreviewInterface } from '@src/interfaces/interviewTool'

interface ScheduleSidebarEmailProps {
  email?: SchedulingEmailPreviewInterface
}

const ScheduleSidebarEmail = ({ email }: ScheduleSidebarEmailProps) => {
  const { values } = useLapeContext<SendCandidateEmailInterface>()
  const user = useSelector(selectUser)

  useEffect(() => {
    if (email) {
      values.email_body = email.html
      values.sender_type = email.sender_type
      values.recipients_cc = email.recipients_cc
      values.recipients_bcc = email.recipients_bcc
      values.subject = email.subject
      values.attachments = email.attachments
    }
  }, [email])

  return (
    <>
      <InputGroup>
        <LapeRadioSelectInput
          name="email_template"
          label="Email template"
          selector={selectorKeys.email_templates_active}
        />
        <EmailForm userEmail={user.email} showInvalidPlaceholdersWarning />
      </InputGroup>
    </>
  )
}

export default ScheduleSidebarEmail
