import React, { useEffect, useState } from 'react'
import {
  Absolute,
  Box,
  Button,
  Caption,
  CodeInput,
  Header,
  HStack,
  InputGroup,
  Layout,
  Link,
  Text,
  TextButton,
  Token,
  useMultipleCodeInput,
  Widget,
} from '@revolut/ui-kit'
import { ExclamationMarkOutline } from '@revolut/icons'
import { z } from 'zod'

import { Grid } from '@components/CommonSC/Grid'
import {
  get2FAHint,
  getEmailHint,
  resend2FACode,
  sendPhoneNumberVerificationCode,
  submit2FACode,
  verifyPhoneNumber,
} from '@src/api/login'
import { API, COOKIE, env, Environments } from '@src/constants/api'
import {
  CredentialsLoginInterface,
  SendPhoneNumberVerificationCodeInterface,
  VerifyPhoneNumberInterface,
  WhoAmIInterface,
} from '@src/interfaces/auth'
import { cookiesApi } from '@src/utils/cookies'
import { api } from '@src/api'
import {
  setFeatureFlagsAction,
  setPermissionsAction,
  setUserAction,
} from '@src/store/auth/actions'
import Cookies from 'js-cookie'
import { removeSignupStateCookie } from '@src/pages/SignUp/common'
import { Authenticated } from '@src/store/auth/constants'
import { useDispatch } from 'react-redux'
import { MSW_MOCK } from '@api-mocks/localstorage'
import { useLocation } from 'react-router-dom'
import { navigateReplace } from '@src/actions/RouterActions'
import { LocalStorageKeys } from '@src/store/auth/types'
import { useQuery } from '@src/utils/queryParamsHooks'
import { ROUTES } from '@src/constants/routes'
import { InternalUIKitLink } from '@src/components/InternalLink/InternalLink'
import { LapePhoneInput } from '@src/components/Inputs/LapeFields/LapePhoneInput'
import LapeForm, { LapeFormInterface, useLapeContext } from '@src/features/Form/LapeForm'
import { arrayErrorsToFormError } from '@src/utils/form'

export const saveLoginData = (
  data: Pick<
    CredentialsLoginInterface,
    'permissions' | 'token' | 'employee' | 'email' | 'authenticated'
  >,
) => {
  cookiesApi.set(COOKIE.AUTHENTICATED, data.authenticated)
}

export const useLoginDevInterface = () => {
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()

  const validateToken = async () => {
    const data = await api.get<WhoAmIInterface>(API.WHOAMI, undefined, undefined, true)
    if (data?.data) {
      dispatch(setUserAction(data.data.employee))
      dispatch(setPermissionsAction(data.data.permissions))
      dispatch(setFeatureFlagsAction(data.data.feature_flags))
      /**
       * Casting here because /whoami `employee` model is different from login `employee` model.
       * Good enough for development purposes since /whoami employee model just has many more properties
       */
      saveLoginData(data.data as any)
    }
  }

  const loginDevInterface = async (token: string) => {
    removeSignupStateCookie()
    cookiesApi.set(COOKIE.AUTHENTICATED, Authenticated.authenticated)
    cookiesApi.set(COOKIE.API_TOKEN, token)
    setLoading(true)
    try {
      await validateToken()
    } finally {
      setLoading(false)
    }

    window.location?.reload()
  }

  const onSubmitDevInterface = async (
    apiEndpoint: string,
    wssEndpoint: string,
    enableMsw: boolean,
    enableTheme: boolean,
    mswMocks: MSW_MOCK[],
    authToken: string,
  ) => {
    if (!Cookies.get(COOKIE.ORIGINAL_TOKEN)) {
      cookiesApi.set(COOKIE.ORIGINAL_TOKEN, Cookies.get(COOKIE.API_TOKEN) || '')
    }
    localStorage.setItem('api_endpoint', apiEndpoint)
    localStorage.setItem('wss_endpoint', wssEndpoint)
    localStorage.setItem('enable_msw', enableMsw ? 'true' : 'false')
    localStorage.setItem('enable_theme', enableTheme ? 'true' : 'false')
    localStorage.setItem('msw_mocks', JSON.stringify(mswMocks))
    await loginDevInterface(authToken)
  }

  return { onSubmitDevInterface, loginDevInterface, loading }
}

export const useDevSetPipeline = () => {
  const location = useLocation()
  const { onSubmitDevInterface } = useLoginDevInterface()

  const origin = localStorage.getItem(LocalStorageKeys.ORIGIN_DEV_SUBDOMAINS)
  const isDevSubdomains = env === Environments.developmentSubdomains

  useEffect(() => {
    if (location.hash?.startsWith('#devapi:') && isDevSubdomains) {
      const devApi = location.hash.replace('#devapi:', '')
      const url = new URL(devApi)
      localStorage.setItem(LocalStorageKeys.ORIGIN_DEV_SUBDOMAINS, url.origin)
      // to remove the hash
      navigateReplace(`${location.pathname}${location.search}`)
    }
  }, [location.hash, isDevSubdomains])

  useEffect(() => {
    const login = async () => {
      localStorage.removeItem(LocalStorageKeys.ORIGIN_DEV_SUBDOMAINS)
      await onSubmitDevInterface(
        `${origin}/employee/api/`,
        `${origin}/ws/v1/user/`,
        false,
        false,
        [],
        '',
      )
    }

    if (origin && isDevSubdomains) {
      login()
    }
  }, [origin, isDevSubdomains])
}

interface NonFieldErrorsProps {
  nonFieldErrors?: string[]
}

export const NonFieldErrors = ({ nonFieldErrors = [] }: NonFieldErrorsProps) => {
  return nonFieldErrors.length > 0 ? (
    <Widget bg={Token.color.redActionBackground} p="s-16" mt="s-8">
      <HStack gap="s-8" align="center">
        <ExclamationMarkOutline color={Token.color.red} />
        {nonFieldErrors.map(error => (
          <Text color={Token.color.red} key={error}>
            {error}
          </Text>
        ))}
      </HStack>
    </Widget>
  ) : null
}

const CONFIRMATION_CODE_REGEXP = /^[0-9]{6}$/
const CONFIRMATION_CODE_LENGTH = 6

interface VerificationCodeFormProps {
  token: string
  isPhoneNumberVerified?: boolean
  onSuccess: () => void
  onCancel?: () => void
  // indicates if only email will be used to send verification code, otherwise either email or sms could have been used.
  emailOnly?: boolean
}

export const VerificationCodeForm = ({
  token,
  isPhoneNumberVerified = true,
  onSuccess,
  onCancel,
  emailOnly = false,
}: VerificationCodeFormProps) => {
  const { query } = useQuery<{ code?: string }>()
  const [loadingResend, setLoadingResend] = useState(false)
  const [phoneNumberVerificationData, setPhoneNumberVerificationData] =
    useState<SendPhoneNumberVerificationCodeInterface>()
  const [value, setValue] = useState(query.code || '')
  const [loading, setLoading] = useState(false)
  const [hint, setHint] = useState('')
  const [devCode, setDevCode] = useState('')
  const [method, setMethod] = useState<'email' | 'sms'>('email')
  const [error, setError] = useState<string | null>(null)

  useEffect(() => {
    if (query.code?.length === CONFIRMATION_CODE_LENGTH) {
      setValue(query.code)
      setLoading(true)
      onSubmit(query.code)
    }
  }, [query.code])

  useEffect(() => {
    if (emailOnly) {
      getEmailHint(token).then(({ data }) => {
        if (data.code) {
          setDevCode(data.code)
        }
        setHint(data.hint)
        setMethod(data.method)
      })
      return
    }
    if (isPhoneNumberVerified) {
      get2FAHint(token).then(({ data }) => {
        if (data.code) {
          setDevCode(data.code)
        }
        setHint(data.hint)
        setMethod(data.method)
      })
    }
  }, [emailOnly, isPhoneNumberVerified])

  const onResendCode = () => {
    setLoadingResend(true)
    setError(null)

    resend2FACode(token)
      .then(({ data }) => {
        if (data.code) {
          setDevCode(data.code)
        }
      })
      .catch(e => {
        setLoadingResend(false)
        setError(
          e?.code?.[0] ||
            e?.data?.code?.[0] ||
            e?.response?.data?.detail ||
            'Error has occurred',
        )
      })
      .finally(() => {
        setLoadingResend(false)
      })
  }

  const onChangeCode = (val: string) => {
    const newVal = val.trimEnd()
    if (!loading) {
      setValue(newVal)
      if (newVal.length === CONFIRMATION_CODE_LENGTH) {
        setLoading(true)
        onSubmit(val)
      }
    }
  }

  const onSubmit = (code: string) => {
    const submitApi = isPhoneNumberVerified ? submit2FACode : verifyPhoneNumber

    submitApi({ code }, token)
      .then(onSuccess)
      .catch(e => {
        setLoading(false)
        setValue('')
        setError(
          e?.code?.[0] ||
            e?.data?.code?.[0] ||
            e?.response?.data?.detail ||
            'Error has occurred',
        )
      })
  }

  const inputsProps = useMultipleCodeInput({
    size: CONFIRMATION_CODE_LENGTH,
    value,
    onChange: onChangeCode,
    autoFocus: true,
  })

  const handlePaste = (e: React.ClipboardEvent) => {
    e.preventDefault()
    const pastedText = e.clipboardData.getData('text')
    if (loading || !pastedText) {
      return
    }
    const sanitizedText = pastedText.replace('-', '')
    if (sanitizedText.match(CONFIRMATION_CODE_REGEXP)) {
      onChangeCode(sanitizedText)
    }
  }

  if (!isPhoneNumberVerified && !phoneNumberVerificationData && !emailOnly) {
    return (
      <VerifyPhoneNumber
        onSuccess={data => setPhoneNumberVerificationData(data)}
        onCancel={onCancel}
        token={token}
      />
    )
  }

  const hintString = phoneNumberVerificationData?.hint || hint
  const methodString = phoneNumberVerificationData?.method || method
  const verificationDevToolCode = phoneNumberVerificationData?.code || devCode

  return (
    <>
      <Grid alignContent="start">
        <Header variant="item">
          {isPhoneNumberVerified && onCancel ? (
            <Header.BackButton
              onClick={() => {
                onCancel()
                setError(null)
              }}
            />
          ) : null}
          {!isPhoneNumberVerified ? (
            <Header.BackButton
              onClick={() => {
                setPhoneNumberVerificationData(undefined)
                setError(null)
              }}
            />
          ) : null}
          <Header.Title>
            {isPhoneNumberVerified
              ? 'Enter your verification code'
              : 'Verify your mobile number'}
          </Header.Title>
          {hintString ? (
            <Header.Description>Code has been sent to {hintString}</Header.Description>
          ) : null}
        </Header>

        <Text color={Token.color.greyTone20} variant="primary" mt="128px">
          Enter 6-digit code from {methodString === 'sms' ? 'SMS' : 'email'}
        </Text>
        <Box mt="s-8">
          <Grid alignItems="center" flow="column" gap={8}>
            {inputsProps.map((inputProps, index) => (
              <React.Fragment key={inputProps.key}>
                {index === 3 && (
                  <Text mr={{ all: '1px', md: '2px' }} color={Token.color.greyTone50}>
                    –
                  </Text>
                )}
                <Box
                  key={`${inputProps.key}-input`}
                  mr={index === inputsProps.length - 1 ? 0 : { all: '1px', md: '2px' }}
                  minWidth={46}
                >
                  <CodeInput
                    type="number"
                    value={value[index]?.trim() || ''}
                    onPaste={handlePaste}
                    disabled={loading}
                    aria-label="Code input"
                    {...inputProps}
                  />
                </Box>
              </React.Fragment>
            ))}
          </Grid>
          {error && (
            <Text use="div" color={Token.color.danger} pt="s-8">
              {error}
            </Text>
          )}
        </Box>
        <Text mt="s-20" color={Token.color.greyTone20}>
          Code hasn't arrived?{' '}
          {loadingResend ? (
            <Text>New code sent</Text>
          ) : (
            <TextButton onClick={onResendCode}>Re-send code</TextButton>
          )}
        </Text>
      </Grid>

      {verificationDevToolCode ? (
        <Absolute top={100} right={100}>
          <Button
            variant="text"
            onClick={() => {
              if (verificationDevToolCode) {
                onChangeCode(verificationDevToolCode)
              }
            }}
          >
            Fill with: {verificationDevToolCode}
          </Button>
        </Absolute>
      ) : null}
    </>
  )
}

interface VerifyPhoneNumberFormProps {
  onCancel?: () => void
}

const VerifyPhoneNumberForm = ({ onCancel }: VerifyPhoneNumberFormProps) => {
  const { submit, isSubmitting } = useLapeContext()

  const [error, setError] = useState<string | null>(null)

  const onSubmit = () => {
    setError(null)

    return submit().catch(e => {
      setError(
        e?.code?.[0] ||
          e?.data?.code?.[0] ||
          e?.response?.data?.detail ||
          'Error has occurred',
      )
    })
  }

  return (
    <>
      <Grid alignContent="start">
        <Header>
          {onCancel ? <Header.BackButton onClick={onCancel} /> : null}
          <Header.Title>
            Your organisation requires 2&#8209;factor authentication
          </Header.Title>
          <Header.Description>
            To login to the platform, you have to confirm your identity. Please verify
            your mobile number. It will be used to send authentication codes by SMS when
            logging in
          </Header.Description>
        </Header>

        <Box mt="s-16">
          <InputGroup>
            <LapePhoneInput
              prefixName="phone_country_code"
              phoneName="phone_number_short"
              phonePlaceholder="Mobile number"
              required
            />

            {error && (
              <Text use="div" color={Token.color.danger} pt="s-8">
                {error}
              </Text>
            )}
          </InputGroup>
        </Box>
      </Grid>

      <Layout.ActionsFill>
        <Button onClick={onSubmit} pending={isSubmitting} elevated>
          Send verification code
        </Button>
      </Layout.ActionsFill>
    </>
  )
}

interface VerifyPhoneNumberProps {
  token: string
  onSuccess: (data: SendPhoneNumberVerificationCodeInterface) => void
  onCancel?: () => void
}

const VerifyPhoneNumber = ({ onSuccess, onCancel, token }: VerifyPhoneNumberProps) => {
  const onSubmit = (form: LapeFormInterface<VerifyPhoneNumberInterface>) => {
    return sendPhoneNumberVerificationCode(form.values, token)
      .then(response => {
        onSuccess(response.data)
        return response.data as unknown as VerifyPhoneNumberInterface
      })
      .catch(error => {
        const errors = arrayErrorsToFormError<VerifyPhoneNumberInterface>(
          error?.response?.data,
        )
        form.apiErrors = errors

        throw error
      })
  }

  return (
    <LapeForm onSubmit={onSubmit}>
      <VerifyPhoneNumberForm onCancel={onCancel} />
    </LapeForm>
  )
}

export const PASSWORDS_DONT_MATCH_ERROR = 'New password does not match'

export const GetRevolutPeopleCaption = () => {
  if (
    env === Environments.productionCommercialRoot ||
    env === Environments.developmentCommercialRoot
  ) {
    return (
      <Caption color={Token.color.greyTone50} textAlign="center">
        You don't have an account?{' '}
        <Link href={ROUTES.SIGNUP.MAIN}>Get Revolut People</Link>
      </Caption>
    )
  }

  return null
}

export const OnboardingLoginCaption = () => {
  return (
    <Caption color={Token.color.greyTone50}>
      Are you an employee doing your onboarding?{' '}
      <InternalUIKitLink
        // @ts-expect-error
        to={ROUTES.LOGIN.ONBOARDING}
      >
        Login here
      </InternalUIKitLink>
    </Caption>
  )
}

const INVALID_WORKSPACE_ERROR =
  'Workspace is invalid. Only letters, numbers and hyphens are allowed.'

export const validateWorkspace = (workspace?: string) =>
  z
    .string()
    .refine(
      value => /^[A-Za-z0-9](?:[A-Za-z0-9-]{0,61}[A-Za-z0-9])*$/.test(value),
      INVALID_WORKSPACE_ERROR,
    )
    .safeParse(workspace)
