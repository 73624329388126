import React, { useEffect, useMemo, useState } from 'react'
import {
  ProbationRecommendationInterface,
  ProbationRecommendationQuestionInterface,
  ProbationResults,
  QuestionType,
} from '@src/interfaces/probationReview'
import { Box, Color, Text } from '@revolut/ui-kit'
import { ReviewerRelation } from '@src/interfaces/performance'
import { MatrixRowInterface } from '@components/Table/MatrixTable'
import {
  probationRecommendationFMColumn,
  probationRecommendationHOFColumn,
  probationRecommendationLMColumn,
  probationRecommendationQuestionColumn,
} from '@src/constants/columns/performance'
import Tooltip from '@components/Tooltip/Tooltip'
import { getSelectors } from '@src/api/selectors'
import { selectorKeys } from '@src/constants/api'
import { OptionInterface } from '@src/interfaces/selectors'
import { probationDecisionOptions } from '@src/pages/Forms/FinalResultForm/ProbationDecision'
import styled from 'styled-components'
import { RadioSelectOption } from '@components/Inputs/RadioSelectInput/RadioSelectInput'
import AdjustableMatrixTable from '@components/Table/AdjustableMatrixTable'

const StyledTooltip = styled(Tooltip)`
  justify-content: flex-start;
`

type Props = {
  recommendations: ProbationRecommendationInterface[]
  wide?: boolean
}

interface TableData {
  text: string
  questionType?: QuestionType
  lm?: React.ReactNode
  fm?: React.ReactNode
  hof?: React.ReactNode
}

const getValue = (
  question?: ProbationRecommendationQuestionInterface,
  options?: RadioSelectOption<OptionInterface>[],
) => {
  if (!question || !options) {
    return '-'
  }

  switch (question.question_type) {
    case QuestionType.YesNo: {
      return options.find(item => item.value.id === question.value)?.label || '-'
    }
    case QuestionType.Seniority:
      return question.seniority?.name || '-'

    default:
      return '-'
  }
}

const getResult = (result?: ProbationRecommendationInterface) => {
  let color

  if (!result) {
    return '-'
  }

  switch (result.result) {
    case ProbationResults.Passed:
      color = Color.GREEN
      break
    case ProbationResults.ChangeSeniority:
      color = Color.WARNING
      break

    case ProbationResults.Failed:
      color = Color.RED
  }

  const resultLabel = probationDecisionOptions.find(
    item => item.value === result?.result,
  )?.label

  return (
    <StyledTooltip
      placement="left"
      title={result.result}
      body={
        <Box p="s-12" width="max-content" maxWidth={400} color="background">
          <Text fontSize="caption">
            <Text use="p" fontWeight={500} pb="s-8">
              {resultLabel}
            </Text>
            <Text>{result.justification}</Text>
          </Text>
        </Box>
      }
      hide={!result.justification && !resultLabel}
    >
      <Text color={color}>{resultLabel || '-'}</Text>
    </StyledTooltip>
  )
}

const getRows = (wide: boolean): MatrixRowInterface<TableData> => ({
  cells: [
    {
      ...probationRecommendationQuestionColumn,
      noHover: true,
      width: wide ? 180 : 170,
      // because it's too long even for expanding on hover
      insert: ({ data }) => (
        <StyledTooltip placement="top" text={data.text}>
          <Text textOverflow="ellipsis" overflow="hidden">
            {data.text}
          </Text>
        </StyledTooltip>
      ),
    },
    {
      ...probationRecommendationLMColumn,
      width: wide ? 108 : 100,
      insert: ({ data }) => <>{data.lm}</>,
    },
    {
      ...probationRecommendationFMColumn,
      width: wide ? 106 : 100,
      insert: ({ data }) => <>{data.fm}</>,
    },
    {
      ...probationRecommendationHOFColumn,
      width: wide ? 106 : 100,
      insert: ({ data }) => <>{data.hof}</>,
    },
  ],
})

const ProbationRecommendationsTable = ({ recommendations, wide = true }: Props) => {
  const [options, setOptions] = useState<RadioSelectOption<OptionInterface>[]>()

  useEffect(() => {
    const fetchOptions = async () => {
      const result = await getSelectors(selectorKeys.yes_no_range_options)
      if (result && result.data) {
        setOptions(
          result.data.options.map(item => ({
            label: item.name,
            value: item,
          })),
        )
      }
    }

    fetchOptions()
  }, [])

  const data = useMemo(() => {
    const lm = recommendations.find(
      item => item.reviewer_relation === ReviewerRelation.LineManager,
    )
    const fm = recommendations.find(
      item => item.reviewer_relation === ReviewerRelation.FunctionalManager,
    )
    const hof = recommendations.find(
      item => item.reviewer_relation === ReviewerRelation.HeadOfFunction,
    )

    return [
      {
        text: 'Recommendation',
        lm: getResult(lm),
        fm: getResult(fm),
        hof: getResult(hof),
      },
      ...(lm?.questions.map(lmQuestion => {
        return {
          text: lmQuestion.text,
          questionType: lmQuestion.question_type,
          lm: getValue(lmQuestion, options),
          fm: getValue(
            fm?.questions.find(fmQuestion => fmQuestion.text === lmQuestion.text),
            options,
          ),
          hof: getValue(
            hof?.questions.find(hofQuestion => hofQuestion.text === lmQuestion.text),
            options,
          ),
        }
      }) || []),
    ]
  }, [recommendations, options])

  return (
    <Box>
      <AdjustableMatrixTable<TableData>
        rows={getRows(wide)}
        data={data}
        data-testid="skills-table"
        autoResize
      />
    </Box>
  )
}

export default ProbationRecommendationsTable
