import { GlobalSearchTypes } from '@src/interfaces/globalSearch'
import { APPLICATIONS } from '@src/constants/hub'
import { IconName } from '@revolut/ui-kit'

export const getFavouriteTypeIconProps = (
  type: GlobalSearchTypes,
): [IconName | undefined, string] => {
  switch (type) {
    case 'team':
    case 'department':
    case 'company':
      return [APPLICATIONS.teams.icon, APPLICATIONS.teams.bg]
    case 'function':
    case 'role':
    case 'specialisation':
      return [APPLICATIONS.positions.icon, APPLICATIONS.positions.bg]
    case 'skill':
      return [APPLICATIONS.skills.icon, APPLICATIONS.skills.bg]
    case 'team_kpi':
    case 'department_kpi':
    case 'employee_kpi':
    case 'company_kpi':
    case 'company_goal':
      return [APPLICATIONS.kpis.icon, APPLICATIONS.kpis.bg]
    default:
      return [undefined, 'deep-grey']
  }
}
