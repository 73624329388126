import React from 'react'
import { Carousel } from '@revolut/ui-kit'

import { useGetUnreadAnnouncements } from '@src/api/announcements'
import { Announcement } from '@src/pages/Home/HomeSubTabs/Dashboard/components/Announcements/Announcement'
import { useDashboardTiles } from '@src/api/dashboard'
import { DashboardTile } from './DashboardTile'

const Announcements = () => {
  const { data: announcements } = useGetUnreadAnnouncements()
  const { data: dashboardTiles } = useDashboardTiles()

  const dashboardTileResults = dashboardTiles?.results

  return (
    <Carousel>
      {dashboardTileResults?.map(dashboardTile => (
        <DashboardTile {...dashboardTile} key={dashboardTile.title} />
      ))}
      {announcements?.map(announcement => (
        <Announcement announcement={announcement} key={announcement.id} />
      ))}
    </Carousel>
  )
}

export default Announcements
