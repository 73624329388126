import React, { useEffect } from 'react'
import {
  Box,
  ErrorWidget,
  Group,
  Skeleton,
  Subheader,
  createChain,
  HStack,
} from '@revolut/ui-kit'

import { PageBody } from '@src/components/Page/PageBody'
import { FormPreview } from '@src/components/FormPreview/FormPreview'
import {
  DeliveryType,
  NotificationSendingHistoryInterface,
  NotificationTemplateInterface,
  NotificationType,
  NotificationStatus,
} from '@src/interfaces/notificationTemplate'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { useNotificationSqlQuery } from '@src/api/notificationTemplate'
import Alert from '@src/assets/3D/Alert.png'
import NewStaticTable from '@src/components/Table/NewStaticTable'
import { CellTypes, RowInterface } from '@src/interfaces/data'
import { selectorKeys } from '@src/constants/api'
import { useTableReturnType } from '@src/components/Table/hooks'
import AdjustableTable from '@src/components/Table/AdjustableTable'
import {
  notificationSendingHistoryAudience,
  notificationSendingHistoryError,
  notificationSendingHistorySentBy,
  notificationSendingHistorySentOn,
  notificationSendingHistorySuccess,
  notificationSendingHistoryActions,
  notificationSendingHistoryStatus,
  notificationEngagementColumn,
} from '@src/constants/columns/notificationTemplate'
import { getScheduleMessage, useCanSendToTenants } from './common'
import { TableNames } from '@src/constants/table'
import { AudienceTable } from '@src/features/AudienceSelection/AudienceTable'
import CancelNotificationAction from '@src/components/ColumnInserts/CancelNotificationAction/CancelNotificationAction'
import ShowLogsAction from '@components/ColumnInserts/ShowLogsAction/ShowLogsAction'
import { connect } from 'lape'
import ActionWidget from '@components/ActionWidget/ActionWidget'
import { useTenantSelector } from '@src/api/tenants'

const sendingHistoryRow = (
  table: useTableReturnType<NotificationSendingHistoryInterface, {}>,
  type?: NotificationType,
): RowInterface<NotificationSendingHistoryInterface> => ({
  cells: [
    {
      ...notificationSendingHistorySentOn,
      width: 110,
    },
    {
      ...notificationSendingHistorySentBy,
      width: 100,
    },
    {
      ...notificationSendingHistoryAudience,
      width: 60,
    },
    {
      ...notificationSendingHistorySuccess,
      width: 50,
    },
    {
      ...notificationSendingHistoryError,
      width: 50,
    },
    {
      ...notificationEngagementColumn,
      width: 100,
    },
    {
      ...notificationSendingHistoryStatus,
      width: 55,
    },
    {
      ...notificationSendingHistoryActions,
      insert: ({ data }) => (
        <HStack gap="s-6">
          <ShowLogsAction requestId={data.id} />
          <CancelNotificationAction data={data} table={table} type={type} />
        </HStack>
      ),
      width: 55,
    },
  ],
})

interface PreviewProps {
  table: useTableReturnType<NotificationSendingHistoryInterface, {}>
}

const Preview = ({ table }: PreviewProps) => {
  const form = useLapeContext<NotificationTemplateInterface>()
  const { values, initialValues } = form

  const notificationSqlQuery = useNotificationSqlQuery()

  const canSendToTenants = useCanSendToTenants()
  const { data: tenants } = useTenantSelector([], canSendToTenants)

  const isNotAnnouncementOrSystemNotification =
    initialValues.type?.id !== NotificationType.system &&
    initialValues.type?.id !== NotificationType.announcement

  useEffect(() => {
    if (values.audience_selection_type?.id === 'sql') {
      notificationSqlQuery.mutate(values)
    }
  }, [])

  return (
    <PageBody>
      {values.status.id === NotificationStatus.created ? (
        <ActionWidget
          title="The notification is not active"
          text="Notification must be in status active to be able to send it."
        />
      ) : null}
      <FormPreview data={initialValues} title="Template details">
        <Group>
          <FormPreview.Item title="Name" field="name" />
          <FormPreview.Item title="Owner" field="owner.name" />
          <FormPreview.Item title="Created on" field="created_date_time" type="date" />
          <FormPreview.Item title="Channel" field="type.name" />
        </Group>

        <Box mt="s-16">
          <FormPreview.Details title="Description" field="description" />
        </Box>
      </FormPreview>

      <Box mt="s-24">
        <Subheader variant="nested">
          <Subheader.Title>Sending history</Subheader.Title>
        </Subheader>
        <AdjustableTable
          name={TableNames.SendingHistory}
          row={sendingHistoryRow(table, initialValues.type?.id)}
          {...table}
          hideCountAndButtonSection
          hiddenCells={{
            [notificationSendingHistoryActions.idPoint]:
              isNotAnnouncementOrSystemNotification,
            [notificationEngagementColumn.idPoint]: isNotAnnouncementOrSystemNotification,
          }}
        />
      </Box>

      <Box mt="s-24">
        <FormPreview data={initialValues} title="Message settings">
          <Group>
            {initialValues.type?.id === NotificationType.email && (
              <>
                <FormPreview.Item title="Email subject" field="email_subject" />
                <FormPreview.Item title="Sender address" field="email_send_from" />
                <FormPreview.Item title="Sender alias" field="email_send_as" />
                <FormPreview.Item
                  title="Allow personal emails"
                  field="email_allow_external_emails"
                  type="boolean"
                />
              </>
            )}
            {initialValues.type?.id === NotificationType.slack && (
              <>
                <FormPreview.Item title="Bot name" field="slack_send_as" />
                <FormPreview.Item title="Bot" field="slack_bot.name" />
                <FormPreview.Item title="Bot Avatar" field="slack_send_as_icon" />
              </>
            )}
            {initialValues.type?.id === NotificationType.sms && (
              <FormPreview.Item title="Send from" field="sms_send_from" />
            )}
            <FormPreview.Item title="Delivery type" field="delivery_type.name" />
            {initialValues.delivery_type?.id === DeliveryType.scheduled && (
              <FormPreview.Item<NotificationTemplateInterface>
                title="Schedule"
                field="schedule"
                insert={getScheduleMessage}
              />
            )}
          </Group>
        </FormPreview>
      </Box>

      <Box mt="s-24">
        <Box mb="s-16">
          <FormPreview data={initialValues} title="Audience">
            <Group>
              {canSendToTenants ? (
                <FormPreview.Item
                  title="Tenants"
                  loading={!tenants}
                  insert={() => {
                    if (initialValues.is_for_all_tenants) {
                      return 'Send to all tenants'
                    }

                    return values.tenants_filters?.id
                      .map(id => {
                        const option = tenants?.options.find(opt => opt.id === id)
                        if (!option) {
                          return null
                        }
                        return option?.name
                      })
                      .filter(Boolean)
                      .join(', ')
                  }}
                />
              ) : null}
              <FormPreview.Item
                title="Audience selection"
                field="audience_selection_type.name"
              />
              {values.audience_selection_type?.id === 'sql' ? (
                <FormPreview.Item
                  title="Total audience size"
                  insert={() => notificationSqlQuery.data?.data?.data.length || '-'}
                />
              ) : null}
              {initialValues.communication_groups?.length ? (
                <FormPreview.Item<NotificationTemplateInterface>
                  title="Communication groups"
                  insert={({ communication_groups }) => {
                    if (!communication_groups?.length) {
                      return '-'
                    }
                    return createChain(',')(communication_groups.map(({ name }) => name))
                  }}
                />
              ) : null}
            </Group>
          </FormPreview>
        </Box>

        {notificationSqlQuery.isLoading && <Skeleton height="s-32" />}
        {notificationSqlQuery.isError && (
          <ErrorWidget>
            <ErrorWidget.Image src={Alert} />
            <ErrorWidget.Title />
            <ErrorWidget.Description>
              {JSON.stringify(notificationSqlQuery.error?.response?.data)}
            </ErrorWidget.Description>
          </ErrorWidget>
        )}
        {notificationSqlQuery.data?.data?.data.length && (
          <Box mb="s-16" maxHeight={360} overflow="auto">
            <NewStaticTable
              rows={{
                cells: Object.keys(notificationSqlQuery.data.data.data[0]).map(key => ({
                  dataPoint: key,
                  idPoint: key,
                  type: CellTypes.text,
                  title: key,
                  sortKey: null,
                  filterKey: null,
                  width: 200,
                  selectorsKey: selectorKeys.none,
                })),
              }}
              data={notificationSqlQuery.data.data.data}
              hasScrollableRow
            />
          </Box>
        )}
        {initialValues.audience_selection_type?.id === 'filtered_table' ? (
          <AudienceTable filter={initialValues.table_filters} isPreview />
        ) : null}
      </Box>
    </PageBody>
  )
}

export default connect(Preview)
