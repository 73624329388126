import {
  Badge,
  Box,
  FilterButton,
  Flex,
  Icon,
  Text,
  Token,
  Tooltip,
  useTooltip,
  VStack,
} from '@revolut/ui-kit'
import {
  CardContentTypes,
  cardHasMissingValues,
  getRoundedRating,
  CommonGradeOption,
  SelectedFieldInterface,
} from '@src/pages/Forms/EmployeePerformanceLayout/utils'
import { get, isEmpty, lowerCase } from 'lodash'
import {
  ReviewScorecardInterface,
  ReviewScorecardViewInterface,
  ReviewSummaryInterface,
  SkillCardInterface,
} from '@src/interfaces/performance'
import { DeliverableScorecardInterface } from '@src/interfaces/deliverables'
import React, { useEffect, useRef, useState } from 'react'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { CardField } from '@src/pages/Forms/EmployeePerformanceLayout/Card'
import styled from 'styled-components'
import { useCardItemContext } from '@src/pages/Forms/EmployeePerformanceLayout/components/ScorecardCardItemContextProvider'

const CardItemContainer = styled(Flex)`
  cursor: pointer;
  transition: all 0.3s;
  max-height: 62px;
  &:hover {
    background-color: ${Token.color.blue_5};
  }
`

const StyledBadge = styled(Badge)`
  cursor: pointer;
  min-width: 16px;
  height: 16px;
  padding: 0;
  box-sizing: content-box;
`

const DotIcon = styled(Icon)`
  position: absolute;
  top: -6px;
  right: -6px;
`

interface CardItemProps {
  data:
    | ReviewScorecardInterface
    | ReviewSummaryInterface
    | ReviewScorecardViewInterface
    | DeliverableScorecardInterface
  renderExpandedContent: (selectedField: SelectedFieldInterface) => React.ReactNode
  renderExceedingContent?: (selectedField: SelectedFieldInterface) => React.ReactNode
  field: CardField
  isViewMode: boolean
  type: CardContentTypes
  cardItemIndex: number
  isGradeSelectedRule?: (field: string, grade: CommonGradeOption) => boolean
  onGradeClick: (field: CardField, grade: CommonGradeOption) => void
  hideMessageBtn?: boolean
}

interface FilterButtonWithTooltipProps {
  grade: CommonGradeOption
  onGradeClick: (field: CardField, grade: CommonGradeOption) => void
  field: CardField
  selected: boolean
  isViewMode: boolean
  type: CardContentTypes
  cardItemIndex: number
}

const getLoweredType = (type: CardContentTypes | string) => {
  return lowerCase(type).replace(' ', '-')
}

const FilterButtonWithTooltip = ({
  grade,
  onGradeClick,
  field,
  selected,
  isViewMode,
  type,
  cardItemIndex,
}: FilterButtonWithTooltipProps) => {
  const tooltip = useTooltip()
  const { aboveExp, belowExp } = grade
  const differFromExp = aboveExp || belowExp

  return (
    <Flex
      key={grade.key}
      justifyContent="center"
      style={{ position: 'relative' }}
      {...tooltip.getAnchorProps()}
    >
      <FilterButton
        onClick={() => onGradeClick(field, grade)}
        active={selected}
        style={{ height: '30px' }}
        px="s-12"
        disabled={isViewMode && !selected}
        data-testid={`card-${getLoweredType(type)}-${cardItemIndex}-${getLoweredType(
          grade.text,
        )}${selected ? '-active' : ''}`}
      >
        <Text data-testid="button-text">{grade.text}</Text>
      </FilterButton>
      {(grade.tooltip || differFromExp) && (
        <Tooltip {...tooltip.getTargetProps()} placement="top-end" maxWidth={300}>
          <VStack>
            {differFromExp && (
              <Text color={aboveExp ? Token.color.green : Token.color.orange}>
                {aboveExp ? 'Above' : 'Below'} expectations
              </Text>
            )}
            {grade.tooltip}
          </VStack>
        </Tooltip>
      )}
    </Flex>
  )
}

export const CardItem = ({
  data,
  field,
  isViewMode,
  type,
  cardItemIndex,
  isGradeSelectedRule,
  onGradeClick,
  renderExpandedContent,
  renderExceedingContent,
  hideMessageBtn,
}: CardItemProps) => {
  const { errors } = useLapeContext<ReviewScorecardInterface>()
  const [expanded, setExpanded] = useState(false)
  const [scrollToCurrent, setScrollToCurrent] = useState<{
    currentPosition: number
    cardItemHeight: number
  }>()
  const tooltip = useTooltip()
  const context = useCardItemContext()
  const isCurrentCard = context?.currentItem === field.field
  const cardItemRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (expanded && !isCurrentCard) {
      const cardItemHeight = cardItemRef.current?.clientHeight

      const cardItemPosition = cardItemRef.current?.offsetTop
      const currentCardItemPosition = context?.currentItemRef?.current?.offsetTop

      const currentPosition = window.scrollY

      setExpanded(false)
      if (
        cardItemPosition &&
        cardItemHeight &&
        currentCardItemPosition &&
        currentCardItemPosition > cardItemPosition
      ) {
        setScrollToCurrent({
          currentPosition,
          cardItemHeight,
        })
      }
    }
  }, [expanded, isCurrentCard, context?.currentItemRef])

  // when opening another section, if previous and current sections have different heights (pretty often)
  // the screen jumps as the amount of content on the page has changed
  // this is to make the window stable when switching between sections
  useEffect(() => {
    if (scrollToCurrent) {
      const { currentPosition, cardItemHeight } = scrollToCurrent

      const cardItemNewHeight = cardItemRef.current?.clientHeight
      if (cardItemNewHeight) {
        window.scrollTo(0, currentPosition - cardItemHeight + cardItemNewHeight)
      }
    }
  }, [scrollToCurrent])

  const fieldValue = get(data, field.field)
  const hasMissingValues = cardHasMissingValues(
    type,
    fieldValue as SkillCardInterface,
    errors,
  )
  const hasErrors = !isEmpty(get(errors, field.field))
  const validationFailed = hasErrors && hasMissingValues

  const selectedRating = fieldValue?.recommended_rating || fieldValue?.rating || null
  const roundedRating = getRoundedRating(selectedRating)
  const selectedGrade = field.grades.find(grade => grade.key === roundedRating)
  const differFromExpectation = selectedGrade?.aboveExp || selectedGrade?.belowExp

  const initialRender = differFromExpectation && !!renderExceedingContent
  const [renderExceeding, setRenderExceeding] = useState(initialRender)
  const [renderJustificationBtn, setRenderJustificationBtn] = useState(initialRender)

  useEffect(() => {
    setRenderExceeding(differFromExpectation && !field.cardJustification)
  }, [differFromExpectation])

  useEffect(() => {
    setRenderJustificationBtn(!!field.cardJustification)
  }, [field.cardJustification])

  useEffect(() => {
    const hasMandatoryJustification =
      renderExceeding && !expanded && field.cardJustification
    const shouldCloseOnCardChange = hasMandatoryJustification || !differFromExpectation

    if (shouldCloseOnCardChange && !isCurrentCard) {
      setRenderExceeding(false)
    }
  }, [isCurrentCard])

  return (
    <VStack
      data-expandable="expandable"
      onClick={() => {
        context?.setCurrentItem(field.field)
        context?.setCurrentItemRef(cardItemRef)
      }}
      ref={cardItemRef}
    >
      <CardItemContainer
        justifyContent="space-between"
        alignItems="center"
        m="s-4"
        py="s-20"
        px="s-16"
        bg={validationFailed ? Token.color.inputError : undefined}
        borderRadius={Token.radius.r12}
        onClick={e => {
          const dataset = get(e.target, 'dataset')
          // we have a bunch of clicks to track in the CardItem:
          // - clicking on buttons should update the grade/rating
          // - clicking on cell should expand/collapse it
          // - clicking outside when justification required should highlight the justification with error
          // - clicking outside after justification added should collapse the exceeding content
          //
          // we cannot use e.stopPropagation for all these events as it blocks further checks
          // this is a hack for checking where exactly on the cell happened the click
          if (dataset.expandable === 'expandable' || dataset.variant === 'mono') {
            if (!expanded && renderJustificationBtn) {
              setRenderExceeding(false)
            }
            setExpanded(!expanded)
          }
        }}
        data-expandable="expandable"
        data-testid={`card-item-${getLoweredType(type)}-${cardItemIndex}`}
      >
        <Flex alignItems="center" gap="s-8">
          <Text variant="primary" fontWeight={600} data-expandable="expandable">
            {field.title}
          </Text>
          {field.titleButton}
        </Flex>
        <Flex
          alignItems="center"
          justifyContent="flex-end"
          gap="s-12"
          data-expandable="expandable"
        >
          {field.grades[0]?.text && (
            <Flex gap="s-4" alignItems="center">
              {field.grades.map(grade => {
                const selected = isGradeSelectedRule
                  ? isGradeSelectedRule(field.field, grade)
                  : grade?.key === getRoundedRating(fieldValue)
                return (
                  <FilterButtonWithTooltip
                    key={grade.key}
                    grade={grade}
                    onGradeClick={onGradeClick}
                    field={field}
                    selected={selected}
                    isViewMode={isViewMode}
                    type={type}
                    cardItemIndex={cardItemIndex}
                  />
                )
              })}
            </Flex>
          )}
          {hideMessageBtn ? null : differFromExpectation ? (
            <>
              <StyledBadge
                useIcon={selectedGrade?.aboveExp ? 'ArrowUp' : 'ArrowDown'}
                backgroundColor={
                  selectedGrade?.aboveExp ? Token.color.green : Token.color.orange
                }
                {...tooltip.getAnchorProps()}
              />
              <Tooltip
                {...tooltip.getTargetProps()}
                placement="top-end"
                maxWidth={300}
                mb="s-8"
              >
                <VStack>
                  <Text
                    color={
                      selectedGrade?.aboveExp ? Token.color.green : Token.color.orange
                    }
                  >
                    {selectedGrade?.aboveExp ? 'Above' : 'Below'} expectations
                  </Text>
                </VStack>
              </Tooltip>
            </>
          ) : (
            <Box width="s-16" />
          )}
          {hideMessageBtn ? null : renderJustificationBtn ? (
            <Box
              style={{ position: 'relative' }}
              onClick={e => {
                e.stopPropagation()
                if (expanded) {
                  setExpanded(false)
                }
                setRenderExceeding(!renderExceeding)
              }}
            >
              <Icon name="Message" size={20} color={Token.color.greyTone20} />
              <DotIcon name="Dot" size={18} color={Token.color.blue} />
            </Box>
          ) : (
            <Box width="s-20" />
          )}
          <Box data-testid="card-button" data-expandable="expandable">
            <Icon
              name={expanded ? 'ChevronUp' : 'ChevronDown'}
              size={20}
              color={Token.color.greyTone20}
            />
          </Box>
        </Flex>
      </CardItemContainer>
      {expanded && (
        <Box m="s-16" mt={0}>
          {renderExpandedContent({
            type,
            field,
            cardIndex: cardItemIndex,
          })}
        </Box>
      )}
      {renderExceeding && !expanded && (
        <Box m="s-16" mt={0}>
          {renderExceedingContent?.({
            type,
            field,
            cardIndex: cardItemIndex,
          })}
        </Box>
      )}
    </VStack>
  )
}
