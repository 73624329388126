import React from 'react'
import { useSelector } from 'react-redux'
import { MoreBar, TableWidget } from '@revolut/ui-kit'
import { navigateTo } from '@src/actions/RouterActions'
import { employeeOnboardingTemplatesTableRequests } from '@src/api/employeeOnboardingTemplateRequests'
import {
  employeeOnboardingTemplateActionsColumn,
  employeeOnboardingTemplateCreationDateColumn,
  employeeOnboardingTemplateNameColumn,
} from '@src/constants/columns/employeeOnboardingTemplate'
import { ROUTES } from '@src/constants/routes'
import { TableNames } from '@src/constants/table'
import { RowInterface } from '@src/interfaces/data'
import { EmployeeOnboardingTemplateInterface } from '@src/interfaces/employeeOnboardingTemplate'
import { InternalLink } from '@components/InternalLink/InternalLink'
import { PageBody } from '@src/components/Page/PageBody'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import Stat from '@src/components/Stat/Stat'
import AdjustableTable from '@src/components/Table/AdjustableTable'
import { useTable } from '@src/components/Table/hooks'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { pathToUrl } from '@src/utils/router'

const Row: RowInterface<EmployeeOnboardingTemplateInterface> = {
  linkToForm: data =>
    navigateTo(
      pathToUrl(ROUTES.FORMS.EMPLOYEE_ONBOARDING_TEMPLATE.PREVIEW, { id: data.id }),
    ),
  cells: [
    {
      ...employeeOnboardingTemplateNameColumn,
      width: 400,
    },
    {
      ...employeeOnboardingTemplateCreationDateColumn,
      width: 200,
    },
    {
      ...employeeOnboardingTemplateActionsColumn,
      width: 100,
    },
  ],
}

export const Onboarding = () => {
  const permissions = useSelector(selectPermissions)
  const canAdd = permissions.includes(PermissionTypes.HRManagerPermissions)
  const table = useTable<EmployeeOnboardingTemplateInterface>(
    employeeOnboardingTemplatesTableRequests,
  )

  return (
    <>
      <PageHeader
        backUrl={pathToUrl(ROUTES.SETTINGS.EMPLOYEES.GENERAL)}
        subtitle="Settings for all onboarding new employees functionalities"
        title="Onboarding settings"
      />
      <PageBody maxWidth="100%">
        <TableWidget>
          <TableWidget.Info>
            <Stat label="Total" val={table.count} />
          </TableWidget.Info>
          <TableWidget.Info>
            {canAdd && (
              <MoreBar>
                <MoreBar.Action
                  to={pathToUrl(ROUTES.FORMS.EMPLOYEE_ONBOARDING_TEMPLATE.GENERAL)}
                  use={InternalLink}
                  useIcon="Plus"
                >
                  Create new template
                </MoreBar.Action>
              </MoreBar>
            )}
          </TableWidget.Info>
          <TableWidget.Table>
            <AdjustableTable
              name={TableNames.CustomFields}
              noDataMessage="Onboarding templates will appear here."
              row={Row}
              useWindowScroll
              {...table}
            />
          </TableWidget.Table>
        </TableWidget>
      </PageBody>
    </>
  )
}
