import { Action, Flex, Skeleton, Text } from '@revolut/ui-kit'
import { InternalLink } from '@components/InternalLink/InternalLink'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import React from 'react'
import { useGetHealth } from '@src/api/health'

const AppVersion = () => {
  const { data, isLoading } = useGetHealth()
  return (
    <>
      {isLoading ? (
        <Skeleton />
      ) : (
        <Flex alignItems="center" justifyContent="center">
          <Text mr="s-4">Revolut People version:</Text>{' '}
          <Action use={InternalLink} to={pathToUrl(ROUTES.PLATFORM_STATUS)}>
            {data?.version}
          </Action>
        </Flex>
      )}
    </>
  )
}

export default AppVersion
