import React from 'react'
import { Box, Flex, Widget, Text, HStack } from '@revolut/ui-kit'
import {
  InterviewResultToTitle,
  InterviewFeedbackInterface,
} from '@src/interfaces/interviewTool'
import { InterviewResultIcon } from '@components/ColumnInserts/InterviewResult/InterviewResultIcon'
import { formatPercentage } from '@src/utils/format'
import Tooltip from '@components/Tooltip/Tooltip'
import { InfoOutline } from '@revolut/icons'

type Props = {
  expectedSeniority?: string
  scorecard?: InterviewFeedbackInterface
}

const Overview = ({ scorecard, expectedSeniority }: Props) => {
  if (!scorecard) {
    return null
  }

  return (
    <>
      <Widget p="s-16" mb="s-16">
        <Flex>
          <Box width={1 / 2}>
            <Text use="div" color="grey-tone-50" variant="caption">
              Recommendation
            </Text>
            <Text use="div" variant="h5" display="flex">
              <InterviewResultIcon
                result={scorecard.result}
                status={scorecard.status}
                showTooltip
              />
              {scorecard.result ? (
                <Text ml="10px">{InterviewResultToTitle[scorecard.result]}</Text>
              ) : (
                ''
              )}
            </Text>
          </Box>
          <Box width={1 / 2}>
            <Text use="div" color="grey-tone-50" variant="caption">
              Score
            </Text>
            <Text use="div" color="grey-tone-50" variant="h5">
              {scorecard.score ? (
                <>
                  {formatPercentage(scorecard.score)} ({scorecard.points}/
                  {scorecard.maximum_points})
                </>
              ) : (
                '-'
              )}
            </Text>
          </Box>
        </Flex>
      </Widget>
      <Widget p="s-16" mb="s-16">
        <Flex>
          <Box width={1 / 2}>
            <Text use="div" color="grey-tone-50" variant="caption">
              <HStack gap="s-4" align="center">
                Expected seniority
                <Tooltip
                  placement="top"
                  text="This is based on the candidate’s expectations"
                >
                  <InfoOutline size={16} />
                </Tooltip>
              </HStack>
            </Text>
            <Text use="div" color="grey-tone-50" variant="h5">
              {expectedSeniority || '-'}
            </Text>
          </Box>
          <Box width={1 / 2}>
            <Text use="div" color="grey-tone-50" variant="caption">
              <HStack gap="s-4" align="center">
                Evaluated seniority
                <Tooltip placement="left" text="This is evaluation by the interviewer">
                  <InfoOutline size={16} />
                </Tooltip>
              </HStack>
            </Text>
            <Text use="div" color="grey-tone-50" variant="h5">
              {scorecard.evaluated_seniority?.name || '-'}
            </Text>
          </Box>
        </Flex>
      </Widget>
    </>
  )
}

export default Overview
