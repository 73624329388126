import React from 'react'
import { PageBody } from '@src/components/Page/PageBody'
import { InputGroup } from '@revolut/ui-kit'
import LapeNewInput from '@components/Inputs/LapeFields/LapeNewInput'
import { selectorKeys } from '@src/constants/api'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import LapeNewTextArea from '@components/Inputs/LapeFields/LapeNewTextArea'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { PageActions } from '@components/Page/PageActions'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { PageWrapper } from '@components/Page/Page'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { ServiceAccountInterface } from '@src/interfaces/accessManagement'

const General = () => {
  const { values } = useLapeContext<ServiceAccountInterface>()
  const backUrl = pathToUrl(ROUTES.ADMIN.ACCESS_MANAGEMENT.USERS)

  return (
    <PageWrapper>
      <PageHeader
        title={values.id ? 'Edit service account' : 'Add service account'}
        backUrl={backUrl}
      />
      <PageBody>
        <InputGroup>
          <LapeNewInput name="email" label="Service email" required />
          <LapeRadioSelectInput
            name="owner"
            selector={selectorKeys.employee}
            label="Owner"
            required
          />
          <LapeRadioSelectInput
            name="status"
            selector={selectorKeys.service_account_statuses}
            label="Status"
            searchable={false}
            required
          />
          <LapeNewTextArea name="description" label="Description" required rows={3} />
        </InputGroup>
      </PageBody>

      <PageActions>
        <NewSaveButtonWithPopup<ServiceAccountInterface>
          onAfterSubmit={res =>
            navigateTo(
              pathToUrl(ROUTES.FORMS.ACCESS_MANAGEMENT_USER.GENERAL, { id: res.user.id }),
            )
          }
          useValidator
        />
      </PageActions>
    </PageWrapper>
  )
}

export default General
