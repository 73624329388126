import { CycleFilterProps } from '@src/components/Inputs/Filters/FilterSelect/CycleFilter/CycleFilter'
import { useOrgEntity } from '@src/features/OrgEntityProvider/OrgEntityProvider'
import React from 'react'
import { useGetPerformanceSelector } from '@src/api/performance'
import { useGetCycleSelector } from '@src/pages/EmployeeProfile/Preview/KPI/utils'
import { useMemo } from 'react'
import { PerformanceLayoutCycleFilter } from '@src/components/Inputs/Filters/FilterSelect/CycleFilter/PerformanceLayoutCycleFilter'
import { EntityTypes } from '@src/constants/api'

export const useGetEmployeeCyclesSelector = (employeeId?: number) => {
  const { data: cycles } = useGetPerformanceSelector(employeeId)
  const cycleSelector = useGetCycleSelector(cycles)
  const initialCycle = cycles?.find(item => item.offset === 0)

  return useMemo(
    () => ({
      initialCycle,
      cycleSelector,
      getCycleById: (id: string) =>
        cycles?.find(cycle => String(cycle.id) === String(id)),
    }),
    [initialCycle, cycleSelector, cycles],
  )
}

export const CycleFilter = ({
  employeeId,
  columnName = 'review_cycle__offset',
  ...rest
}: CycleFilterProps & { employeeId?: number }) => {
  const { cycleSelector } = useGetEmployeeCyclesSelector(employeeId)
  return (
    <PerformanceLayoutCycleFilter
      label="Cycle"
      columnName={columnName}
      {...rest}
      selector={employeeId ? cycleSelector : rest.selector}
    />
  )
}

export const GoalCycleFilter = ({
  allowEmployeeCycles = true,
  ...cycleFilterProps
}: CycleFilterProps & { allowEmployeeCycles?: boolean }) => {
  const { entity } = useOrgEntity()

  if (!entity) {
    // nice-to-have: add a proper loading state. not needed for now as whole tab mount waits for entity
    return null
  }

  const isEmployee = [EntityTypes.employee, EntityTypes.employees].includes(entity.type)

  return (
    <CycleFilter
      {...cycleFilterProps}
      employeeId={isEmployee && allowEmployeeCycles ? entity.data.id : undefined}
    />
  )
}
