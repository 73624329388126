import React from 'react'
import { Avatar, Item } from '@revolut/ui-kit'

import { TablePreview } from '@components/TablePreview/TablePreview'
import { getRightsToWork } from '@src/api/employees'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { navigateTo } from '@src/actions/RouterActions'
import { getRightToWorkStatusColor } from '@src/constants/columns/rightToWork'
import { formatDateWithFallback } from '../common'
import { CommonPreviewProps } from './types'

export const RightToWork = ({ employeeId, hideTableRoute }: CommonPreviewProps) => {
  return (
    <TablePreview
      title="Right to work"
      api={{ getItems: getRightsToWork(employeeId) }}
      tableRoute={
        hideTableRoute
          ? undefined
          : pathToUrl(ROUTES.FORMS.EMPLOYEE.GENERAL.RIGHT_TO_WORK, { id: employeeId })
      }
      createNewRoute={pathToUrl(ROUTES.FORMS.EMPLOYEE_RIGHT_TO_WORK.GENERAL, {
        employeeId,
      })}
      emptyState={{
        title: 'No right to work item added',
        icon: 'Passport',
      }}
      row={rightToWork => {
        return (
          <Item
            use="button"
            onClick={() =>
              navigateTo(
                pathToUrl(ROUTES.FORMS.EMPLOYEE_RIGHT_TO_WORK.GENERAL, {
                  employeeId,
                  id: rightToWork.id,
                }),
              )
            }
            key={rightToWork.id}
          >
            <Item.Avatar>
              <Avatar useIcon="Passport" />
            </Item.Avatar>
            <Item.Content>
              <Item.Title>
                {rightToWork.country?.name} · {rightToWork.document_type?.name}
              </Item.Title>
              <Item.Description>
                Verified on{' '}
                {formatDateWithFallback(rightToWork.last_verified_on_date, '-')}
              </Item.Description>
            </Item.Content>
            <Item.Side>
              <Item.Value color={getRightToWorkStatusColor(rightToWork)}>
                {rightToWork.status.name}
              </Item.Value>
            </Item.Side>
          </Item>
        )
      }}
    />
  )
}
