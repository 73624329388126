import { PageWrapper } from '@src/components/Page/Page'
import { PageBody } from '@src/components/Page/PageBody'
import { ROUTES } from '@src/constants/routes'
import { OnboardingChecklistHeader } from '@src/pages/OnboardingChecklistV2/components/OnboardingChecklistHeader'
import { pathToUrl } from '@src/utils/router'
import React, { PropsWithChildren } from 'react'

export const InnerRoute = ({
  title,
  description,
  children,
  backPath = ROUTES.ONBOARDING_CHECKLIST_V2.REVIEWS.SETUP_SCORECARD,
}: PropsWithChildren<{ title: string; description?: string; backPath?: string }>) => {
  return (
    <PageWrapper>
      <OnboardingChecklistHeader
        backUrl={pathToUrl(backPath)}
        title={title}
        description={description}
      />
      <PageBody>{children}</PageBody>
    </PageWrapper>
  )
}
