import React, {
  useRef,
  useState,
  useMemo,
  useEffect,
  Ref,
  PropsWithChildren,
} from 'react'
import {
  Input,
  RadioSelect,
  RadioSelectProps,
  SelectOptionItemType,
  InputProps,
  Flex,
  Tooltip,
  useTooltip,
  Token,
  Icon,
  useStatusPopup,
  StatusPopup,
} from '@revolut/ui-kit'
import { ChevronDown, LinkExternal } from '@revolut/icons'
import { LocationDescriptor } from 'history'
import { Link } from 'react-router-dom'
import { matchSorter } from 'match-sorter'

import useFetchOptions, {
  AsyncState,
  getOptionLabel,
} from '@components/Inputs/hooks/useFetchOptions'
import { SelectorType } from '@src/interfaces/selectors'
import { UseGetSelectorsQueryOptions } from '@src/api/selectors'
import { getMessageFromError } from '@src/store/notifications/actions'

const loadingFailedMessage = 'Failed to load options'

export const createNewKey = 'create-new'

export interface RadioSelectOption<T> extends Omit<SelectOptionItemType<T>, 'key'> {}

export interface RadioSelectInputProps<Value>
  extends Omit<
    RadioSelectProps<Value>,
    'options' | 'open' | 'labelList' | 'placeholder'
  > {
  selector?: SelectorType
  options?: RadioSelectOption<Value>[]
  useQuery?: boolean
  useQueryOptions?: UseGetSelectorsQueryOptions<Value>
  loading?: boolean
  labelPath?: string
  valueKey?: string
  message?: React.ReactNode
  inputProps?: InputProps & { 'data-name'?: string }
  referenceText?: React.ReactNode
  referenceUrl?: string | LocationDescriptor
  hasError?: boolean
  clearable?: boolean
  filter?: (optionValue: Value) => boolean
  renderInput?: (
    open: boolean,
    setOpen: (open: boolean) => void,
    ref: Ref<any>,
  ) => React.ReactNode
  disableOptionRule?: (option: RadioSelectOption<Value>) => boolean
  selectDefaultOption?: (
    options: RadioSelectOption<Value>[],
  ) => RadioSelectOption<Value> | undefined
  anchorRef?: React.MutableRefObject<null>
  /** To allow creating a new value while typing in search */
  allowCreateNewOption?: boolean
  onCreateNewOption?: (optionName: string) => Promise<{ id: number; name: string }>
  /** To show a "Create new" button inside the options */
  showCreateNewButton?: boolean
  searchable?: boolean
  searchKeys?: string[]
  refetchOnOpen?: boolean
  allowSetValueToCurrentOption?: boolean
}

const RadioSelectInput = <Value extends { id?: number | string; name?: string | null }>({
  loading,
  useQuery,
  useQueryOptions,
  labelPath,
  message,
  inputProps,
  hasError,
  indicatorStyle = 'highlight',
  disabled,
  filter,
  clearable,
  renderInput,
  disableOptionRule,
  selectDefaultOption,
  options,
  allowCreateNewOption,
  onCreateNewOption,
  showCreateNewButton,
  valueKey,
  searchable = true,
  searchKeys = [],
  children,
  refetchOnOpen,
  allowSetValueToCurrentOption,
  ...props
}: PropsWithChildren<RadioSelectInputProps<Value>>) => {
  const localAnchorRef = useRef(null)
  const anchorRef = props.anchorRef || localAnchorRef
  const tooltip = useTooltip()
  const [defaultValue, setDefaultValue] = useState<Value | null>(null)
  const [isCreatingNewOption, setIsCreatingNewOption] = useState(false)

  const statusPopup = useStatusPopup()

  const {
    options: fetchedOptions,
    asyncState,
    refetch,
  } = useFetchOptions<Value>(
    props.selector || null,
    useQuery,
    labelPath,
    undefined,
    useQueryOptions,
  )

  const [open, setOpen] = useState(false)
  const [currentOption, setCurrentOption] = useState<Value | null>(props.value || null)

  const normalizedOptions = useMemo(() => {
    if (!filter) {
      return options || fetchedOptions
    }

    return (options || (fetchedOptions as SelectOptionItemType<Value>[])).filter(option =>
      filter(option.value),
    )
  }, [options, fetchedOptions, filter])

  useEffect(() => {
    // reset current option if it doesn't match the filter
    if (filter && currentOption && !filter(currentOption)) {
      setCurrentOption(null)
      props.onChange?.(currentOption)
    }
  }, [fetchedOptions, filter])

  useEffect(() => {
    if (props.value == null) {
      setCurrentOption(null)
    }
    const foundOption = (normalizedOptions as SelectOptionItemType<Value>[]).find(opt => {
      /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
      if (valueKey && props.value?.[valueKey]) {
        /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
        return opt.value[valueKey] === props.value[valueKey]
      }

      if (props.value?.id !== undefined) {
        return opt.value.id === props.value?.id
      }

      // often we have a case where we use just a name from options. For example options are: [{ id: 1, name: 'days' }]
      // and we don't need id, we use only name. This makes it possible just to use RadioSelectInput with value={{ name: 'days' }}
      if (props.value?.name !== undefined) {
        return opt.value.name === props.value?.name
      }

      return false
    })?.value

    if (foundOption) {
      setCurrentOption(foundOption)
    }
    if (props.value?.name && allowCreateNewOption) {
      setCurrentOption({ name: props.value.name } as Value)
      return
    }
    if (allowSetValueToCurrentOption && props.value) {
      setCurrentOption(props.value as Value)
    }
  }, [
    props.value,
    normalizedOptions,
    allowCreateNewOption,
    valueKey,
    allowSetValueToCurrentOption,
  ])

  useEffect(() => {
    if (open && refetchOnOpen) {
      refetch()
    }
  }, [open, refetchOnOpen])

  useEffect(() => {
    if (normalizedOptions && selectDefaultOption) {
      setDefaultValue(selectDefaultOption(normalizedOptions)?.value || null)
    }
  }, [normalizedOptions])

  const inputValue = useMemo(
    () => (currentOption ? getOptionLabel(currentOption, labelPath) : ''),
    [currentOption, labelPath],
  )

  const renderAction: InputProps['renderAction'] = (...args) => {
    const actionFromProps = inputProps?.renderAction?.(...args)
    const { referenceText, referenceUrl } = props

    if (referenceUrl) {
      return (
        <Flex alignItems="center">
          {actionFromProps}
          <Link to={referenceUrl} target="_blank">
            <Tooltip {...tooltip.getTargetProps()}>
              {referenceText || 'Open in a new tab'}
            </Tooltip>
            <LinkExternal
              cursor="pointer"
              size={16}
              color="primary"
              {...tooltip.getAnchorProps()}
            />
          </Link>
        </Flex>
      )
    }

    return actionFromProps
  }

  const optionsWithKeys = useMemo(() => {
    return [
      ...(showCreateNewButton
        ? [
            {
              label: (
                <Flex alignItems="center" color={Token.color.blue}>
                  <Icon name="Plus" size={16} />
                  &nbsp; Create new
                </Flex>
              ),
              value: { id: createNewKey } as Value,
              key: createNewKey,
            },
          ]
        : []),
      ...normalizedOptions.map((item, i) => ({
        key: item.value.id || item.value.name || i,
        disabled: disableOptionRule ? disableOptionRule(item) : undefined,
        ...item,
      })),
    ]
  }, [normalizedOptions, showCreateNewButton])

  const onSearch = (input: string, opts: RadioSelectOption<Value>[]) => {
    const searchResults = matchSorter(opts, input, {
      keys: ['label', 'keywords.*', ...searchKeys],
    })

    if (allowCreateNewOption) {
      return (
        searchResults[0]?.label === input
          ? searchResults
          : [
              {
                label: (
                  <Flex
                    alignItems="center"
                    onClick={
                      onCreateNewOption
                        ? async e => {
                            e.preventDefault()
                            try {
                              setIsCreatingNewOption(true)
                              const newOption = await onCreateNewOption(input)
                              setCurrentOption(newOption as Value)
                              props.onChange?.(newOption as Value)
                            } catch (err) {
                              statusPopup.show(
                                <StatusPopup
                                  variant="error"
                                  onClose={() => statusPopup.hide()}
                                >
                                  <StatusPopup.Title>
                                    Something went wrong
                                  </StatusPopup.Title>
                                  <StatusPopup.Description>
                                    {getMessageFromError(err)}
                                  </StatusPopup.Description>
                                </StatusPopup>,
                              )
                            } finally {
                              setIsCreatingNewOption(false)
                              setOpen(false)
                            }
                          }
                        : undefined
                    }
                  >
                    <Icon
                      size={16}
                      color={Token.color.blue}
                      name={isCreatingNewOption ? 'Loading' : 'Plus'}
                    />
                    &nbsp;"{input}"
                  </Flex>
                ),
                value: { name: input },
                key: 'new-option',
              },
              ...searchResults,
            ]
      ) as SelectOptionItemType<Value>[]
    }

    return searchResults as SelectOptionItemType<Value>[]
  }

  let loadingStage: AsyncState = asyncState

  if (loading) {
    loadingStage = 'pending'
  }

  return (
    <>
      {renderInput ? (
        renderInput(open, setOpen, anchorRef)
      ) : (
        <Input
          label={props.label}
          containerRef={anchorRef}
          type="button"
          useIcon={ChevronDown}
          onClick={() => setOpen(!open)}
          onClear={
            clearable
              ? () => {
                  setCurrentOption(null)
                  props.onChange?.(null)
                }
              : undefined
          }
          onKeyUp={event => {
            if (event.currentTarget.matches(':disabled')) {
              return
            }
            switch (event.key) {
              case 'Tab': {
                event.preventDefault()
                setOpen(true)
              }
            }
          }}
          onKeyDown={event => {
            if (event.currentTarget.matches(':disabled')) {
              return
            }
            switch (event.key) {
              case 'ArrowDown':
              case 'Enter': {
                event.preventDefault()
                setOpen(true)
              }
            }
          }}
          aria-invalid={asyncState === 'failed' || hasError}
          aria-haspopup="listbox"
          aria-expanded={open}
          focused={open}
          disabled={disabled}
          value={inputValue}
          {...inputProps}
          pending={loadingStage === 'pending'}
          message={asyncState === 'failed' ? loadingFailedMessage : message}
          renderAction={renderAction}
        />
      )}
      <RadioSelect
        options={optionsWithKeys}
        onSearch={onSearch}
        open={open}
        anchorRef={anchorRef}
        onClose={() => setOpen(false)}
        onChange={option => {
          if (option?.id !== createNewKey) {
            setCurrentOption(option)
          }
          props.onChange?.(option)
        }}
        loadingState={loadingStage}
        fitInAnchor
        autoClose
        searchable={searchable}
        indicatorStyle={indicatorStyle}
        labelList="Options"
        flip
        defaultValue={defaultValue}
        {...props}
        value={currentOption}
      >
        {children}
      </RadioSelect>
    </>
  )
}

export default RadioSelectInput
