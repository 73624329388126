import React from 'react'
import styled from 'styled-components'
import isNil from 'lodash/isNil'
import { Token } from '@revolut/ui-kit'

import Icon from '../../Icon/Icon'
import { formatMoney, formatMoneyMillions, formatNumber } from '@src/utils/format'

export const Colored = styled.div<{ color: string }>`
  color: ${props => props.color};
`

const ColoredWithIcon = styled.div<{ color: string }>`
  display: grid;
  grid-template-columns: auto auto;
  gap: 5px;
  align-items: center;
  color: ${props => props.color};
`

export enum CurrencyColors {
  BLACK = 'black',
  ORANGE = 'orange',
  RED = 'red',
}

interface ColoredPercentProps {
  percent?: number | string
  children?: any
  className?: string
  color?: string
  precision?: number
}

interface ColoredCurrency {
  currency: string
  amount: number
  className?: string
  color?: string
  millions?: boolean
}

export const getPercentColor = (percent?: string | number) => {
  if (!percent) {
    return Token.color.greyTone20
  }

  const num = typeof percent === 'string' ? parseInt(percent, 10) : percent

  if (num === 0) {
    return Token.color.greyTone20
  }
  if (num < 50) {
    return Token.color.red
  }
  if (num < 80) {
    return Token.color.warning
  }
  if (num >= 80) {
    return Token.color.green
  }
  return Token.color.foreground
}

export const getFunctionNIPSColor = (percent?: number | string) => {
  if (!percent) {
    return Token.color.greyTone20
  }

  const num = typeof percent === 'string' ? parseInt(percent, 10) : percent

  if (num >= 15) {
    return Token.color.green
  }
  if (num >= 10) {
    return Token.color.warning
  }
  return Token.color.red
}

export const mapCurrencyColorValues = (num: number) => {
  if (num < 0.9) {
    return Token.color.foreground
  }
  if (num < 1) {
    return Token.color.warning
  }
  if (num > 1) {
    return Token.color.red
  }
  return Token.color.foreground
}

export const FormattedCurrency = ({
  amount,
  currency,
  className,
  millions,
  ...props
}: ColoredCurrency) => {
  const color = props.color || Token.color.foreground

  if (!currency) {
    return null
  }
  return (
    <ColoredWithIcon className={className} color={color}>
      {millions ? formatMoneyMillions(amount, currency) : formatMoney(amount, currency)}
      {color === CurrencyColors.ORANGE && <Icon size="small" type="WarningTriangle" />}
      {color === CurrencyColors.RED && <Icon size="small" type="WarningFilled" />}
    </ColoredWithIcon>
  )
}

export const ColoredPercent = ({
  percent,
  className,
  children,
  color,
  precision = 0,
}: ColoredPercentProps) => {
  return (
    <Colored className={className} color={color || getPercentColor(percent)}>
      {children || !isNil(percent)
        ? `${formatNumber(parseFloat(percent as string), precision)}%`
        : '-'}
    </Colored>
  )
}
