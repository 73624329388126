import React from 'react'
import OrderingPositionCell from '@src/features/OrderingTableControls/OrderingPositionCell'
import { ColumnCellInterface } from '@src/interfaces/data'
import isNumber from 'lodash/isNumber'
import { dragIconColumn, positionNumberColumn } from '@src/constants/columns/ordering'

export const orderingCellsWrapper = <
  T extends { id: number; pipeline_queue_position: number },
>(
  orderingMode: boolean,
  onChangePosition: (id: number, position: number) => void,
  cells: ColumnCellInterface<T>[],
): ColumnCellInterface<T>[] => {
  const list: ColumnCellInterface<T>[] = [
    {
      ...positionNumberColumn,
      insert: ({ data }) => (
        <OrderingPositionCell
          id={data.id}
          value={
            isNumber(data.pipeline_queue_position)
              ? String(data.pipeline_queue_position)
              : '-'
          }
          disabled={!orderingMode}
          onChange={onChangePosition}
        />
      ),
    },
    ...cells,
  ]

  if (orderingMode) {
    list.unshift(dragIconColumn)
  }

  return list
}
