import React, { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import upperFirst from 'lodash/upperFirst'
import { Box, Fixed, Side, Header, Widget, HStack, ActionButton } from '@revolut/ui-kit'

import { ReviewCategory } from '@src/interfaces/performance'
import { OptionInterface } from '@src/interfaces/selectors'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { useGetProbationCheckpoints } from '@src/api/probationReview'
import { useQuery } from '@src/utils/queryParamsHooks'
import { Queries } from '@src/constants/api'
import { navigateTo } from '@src/actions/RouterActions'
import { defaultTheme } from '@src/styles/theme'
import { CheckpointType } from '@src/interfaces/probationReview'
import { SideBarHeaderTitle } from '@components/SideBar/SideBarHeaderTitle'
import { useGetPipCheckpoints } from '@src/api/pip'
import { useGetPerformanceSettings } from '@src/api/performanceSettings'
import NoReviewsWidget from '../Common/NoReviewsWidget'
import SidebarSkeleton from './SidebarSkeleton'
import OpenReviewButton from './OpenReviewButton'
import SidebarFeedbacks from './SidebarFeedbacks'
import SidebarCalibratedGrade from './SidebarCalibratedGrade'
import CheckpointsFilter from './CheckpointsFilter'
import ManagerRecommendations from './ManagerRecommendations'
import ReviewersFilter from './ReviewersFilter'
import CategoryFilter from './CategoryFilter'
import Cards from './Cards'
import { useFetchPerformanceSummary } from './hooks'

export interface SummarySidebarProps {
  cycleId?: string
  cycleName?: string
  employeeId?: number
  employeeSeniorityId?: number
  category?: ReviewCategory
  predefinedCheckpointFilter?: OptionInterface[]
  predefinedReviewer?: boolean
  onClose?: () => void
  hideCategoryFilter?: boolean
  employeeName?: string
  employeeSeniority?: string
  employeeSpecialisation?: string
  isOpen: boolean
  onClickRequestReview?: () => void
  narrativeCell?: React.ReactNode
}

const SummarySidebar = ({
  cycleId,
  employeeId,
  employeeSeniorityId,
  cycleName,
  category,
  predefinedCheckpointFilter,
  predefinedReviewer = false,
  onClose,
  isOpen,
  hideCategoryFilter,
  employeeName,
  employeeSeniority,
  employeeSpecialisation,
  onClickRequestReview,
  narrativeCell,
}: SummarySidebarProps) => {
  const params = useParams<{ id?: string }>()
  const [filters, setFilters] = useState<OptionInterface[]>()
  const { data: performanceSettings } = useGetPerformanceSettings()
  const [checkpointFilters, setCheckpointFilters] = useState<
    OptionInterface[] | undefined
  >(predefinedCheckpointFilter)

  const { deleteQueryParam } = useQuery()
  const { data: checkpoints } = useGetProbationCheckpoints(
    category === ReviewCategory.Probation ? employeeId || null : null,
    cycleId === undefined ? null : cycleId,
  )

  const { data: pipCheckpoints } = useGetPipCheckpoints(
    category === ReviewCategory.PIP_V2 ? employeeId || null : null,
    cycleId === undefined ? null : cycleId,
  )

  const {
    data,
    isLoading,
    reviews,
    refresh: refreshSummary,
  } = useFetchPerformanceSummary(cycleId, employeeId, category)

  const showCategoryFilter = useMemo(() => {
    if (performanceSettings?.enable_upwards_reviews === false) {
      return false
    }

    return (
      !hideCategoryFilter &&
      (category === ReviewCategory.Performance || category === ReviewCategory.Upwards)
    )
  }, [performanceSettings?.enable_upwards_reviews, hideCategoryFilter, category])

  const canSeeRecommendations = !!checkpoints?.checkpoints?.filter?.(
    c => c.checkpoint_type === CheckpointType.Recommendation,
  )?.length

  useEffect(() => {
    if (!category || cycleId === undefined || predefinedReviewer) {
      return
    }

    deleteQueryParam(Queries.CheckpointNumber)
    deleteQueryParam(Queries.ReviewerId)
    setFilters(undefined)
    if (predefinedCheckpointFilter) {
      setCheckpointFilters(predefinedCheckpointFilter)
      refreshSummary(undefined, predefinedCheckpointFilter)
    } else {
      setCheckpointFilters(undefined)
      refreshSummary()
    }
  }, [cycleId, employeeId])

  const switchCategory = (categoryFilter: ReviewCategory) => {
    if (categoryFilter === ReviewCategory.Upwards) {
      navigateTo(pathToUrl(ROUTES.FORMS.EMPLOYEE.PERFORMANCE.UPWARDS, params))
      return
    }

    navigateTo(pathToUrl(ROUTES.FORMS.EMPLOYEE.PERFORMANCE.PERFORMANCE, params))
  }

  const handleReviewerFilterChange = (options?: OptionInterface[]) => {
    setFilters(options)
    refreshSummary(options, checkpointFilters, false)
  }

  const handleCheckpointsFilterChange = (options?: OptionInterface[]) => {
    setCheckpointFilters(options)
    refreshSummary(filters, options, false)
  }

  let title = employeeName

  if (!employeeName) {
    if (category === ReviewCategory.PIP || category === ReviewCategory.PIP_V2) {
      title = 'PIP review'
    } else {
      title = `${
        category !== ReviewCategory.Probation ? cycleName : ''
      } ${category} review`
    }
  }

  if (!isOpen) {
    return null
  }

  return (
    <Fixed
      right={0}
      top={0}
      zIndex={defaultTheme.zIndex.sideBar}
      p="s-16"
      height="100vh"
      overflow="auto"
    >
      <Side open onClose={onClose} variant="wide" height="100%">
        <Header variant="item" pb={0}>
          <Header.CloseButton aria-label="Close" />
          <SideBarHeaderTitle data-testid="title">
            {upperFirst(title?.trim())}
          </SideBarHeaderTitle>
          {employeeSpecialisation && employeeSeniority && (
            <Header.Description>
              {employeeSpecialisation} - {employeeSeniority}
            </Header.Description>
          )}
        </Header>
        {narrativeCell}
        <Box>
          {showCategoryFilter && (
            <CategoryFilter onClick={switchCategory} category={category} />
          )}

          <SidebarCalibratedGrade calibratedGrade={data?.grade_after_calibration} />

          <Widget p="s-16" mt="s-16">
            <ReviewersFilter
              data={reviews}
              onFilterChange={handleReviewerFilterChange}
              showFilters={
                category === ReviewCategory.Performance ||
                category === ReviewCategory.Probation ||
                category === ReviewCategory.PIP_V2 ||
                category === ReviewCategory.Upwards
              }
            />
            {category === ReviewCategory.Probation && !predefinedCheckpointFilter && (
              <Box mt="s-8">
                <CheckpointsFilter
                  checkpoints={checkpoints?.checkpoints}
                  onFilterChange={handleCheckpointsFilterChange}
                  showFilters
                />
              </Box>
            )}

            {category === ReviewCategory.PIP_V2 && !predefinedCheckpointFilter && (
              <Box mt="s-8">
                <CheckpointsFilter
                  checkpoints={pipCheckpoints?.checkpoints}
                  onFilterChange={handleCheckpointsFilterChange}
                  showFilters
                />
              </Box>
            )}

            {isLoading ? (
              <SidebarSkeleton />
            ) : (
              <>
                <HStack gap="s-8" mt="s-16">
                  {!!data?.reviews.length && (
                    <OpenReviewButton
                      cycleId={cycleId}
                      employeeId={employeeId}
                      category={category}
                    />
                  )}

                  {performanceSettings?.enable_peer_reviews && onClickRequestReview && (
                    <ActionButton onClick={onClickRequestReview}>
                      Request Review
                    </ActionButton>
                  )}
                </HStack>

                {!data?.reviews.length && (
                  <NoReviewsWidget
                    filtersApplied={!!filters?.length || !!checkpointFilters?.length}
                  />
                )}

                {canSeeRecommendations && checkpoints?.decision_checkpoints && (
                  <ManagerRecommendations cycleId={cycleId} employeeId={employeeId} />
                )}

                <Box my="s-32">
                  <Cards
                    data={data}
                    cycleId={cycleId}
                    employeeId={employeeId}
                    employeeSeniorityId={employeeSeniorityId}
                    category={category}
                    showCompetencies={category === ReviewCategory.Probation}
                  />
                </Box>

                <SidebarFeedbacks
                  feedbacks={data?.summary?.overall_feedbacks}
                  ratings={data?.summary?.ratings}
                />
              </>
            )}
          </Widget>
        </Box>
      </Side>
    </Fixed>
  )
}

export default SummarySidebar
