import React, { useState } from 'react'

import { Color, HStack } from '@revolut/ui-kit'
import { TableActionButton } from '@components/Button/TableActionButton'
import { KeyPersonSuccessionPlanInterface } from '@src/interfaces/keyPerson'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { useSelector } from 'react-redux'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { archiveSuccessionPlan, unarchiveSuccessionPlan } from '@src/api/keyPerson'

type Props = {
  data: KeyPersonSuccessionPlanInterface
  refresh: () => void
}
export const ActionButtons = ({ data, refresh }: Props) => {
  const [isArchivationPending, setIsArchivationPending] = useState(false)

  const permissions = useSelector(selectPermissions)
  const canEdit = permissions.includes(
    PermissionTypes.ChangeKeyRiskProfileSuccessionPlans,
  )
  const isArchived = data.status.id === 'archived'

  return (
    <>
      <HStack space="s-12">
        {canEdit ? (
          <TableActionButton
            onClick={e => {
              e.stopPropagation()
              navigateTo(
                pathToUrl(ROUTES.APPS.KEY_PERSONS.SUCCESSION_PLAN.EDIT, {
                  id: data.id,
                }),
              )
            }}
          >
            {data.employee ? 'Edit' : 'Assign'}
          </TableActionButton>
        ) : null}
        <>
          {isArchived ? (
            <TableActionButton
              pending={isArchivationPending}
              onClick={async e => {
                e.stopPropagation()
                try {
                  setIsArchivationPending(true)
                  await unarchiveSuccessionPlan(data.id)
                  refresh()
                } finally {
                  setIsArchivationPending(false)
                }
              }}
            >
              Unarchive
            </TableActionButton>
          ) : (
            <TableActionButton
              pending={isArchivationPending}
              color={Color.RED}
              onClick={async e => {
                e.stopPropagation()
                try {
                  setIsArchivationPending(true)
                  await archiveSuccessionPlan(data.id)
                  refresh()
                } finally {
                  setIsArchivationPending(false)
                }
              }}
            >
              Archive
            </TableActionButton>
          )}
        </>
      </HStack>
    </>
  )
}
