import React from 'react'
import { Cell, DetailsCell, Group, Text, textChain, VStack } from '@revolut/ui-kit'
import { CareersApplicationEducationInterface } from '@src/interfaces/careers'
import { formatStatus } from '@src/utils/string'
import { formatExperienceDateRange } from '@src/utils/hiring'

type Props = {
  data?: CareersApplicationEducationInterface[]
}

const EducationInfo = ({ data }: Props) => {
  if (!data?.length) {
    return null
  }

  return (
    <Group>
      <DetailsCell variant="header">
        <DetailsCell.Title>Education</DetailsCell.Title>
      </DetailsCell>
      <Cell>
        <VStack gap="s-4">
          {data.map((item, i) => (
            <Text display="block" key={i}>
              {textChain(
                item.university?.name,
                item.major?.name,
                formatStatus(item.degree),
                item.gpa ? `${item.gpa} gpa` : undefined,
                formatExperienceDateRange(item.start_date, item.end_date),
              )}
            </Text>
          ))}
        </VStack>
      </Cell>
    </Group>
  )
}

export default EducationInfo
