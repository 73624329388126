import React, { useState } from 'react'

import { ExportTypes } from '@src/constants/export'
import { saveFileByBlob } from '@src/utils'
import { AxiosResponse } from 'axios'
import { ExportRequest } from '@src/interfaces'
import { MoreBar, Tooltip, TooltipState, useTooltip } from '@revolut/ui-kit'
import { Download } from '@revolut/icons'

export interface ExportMenuProps {
  fileName: string
  filterQuery?: Object
  request?: ExportRequest
  supportedTypes?: ExportTypes[]
  isDisabled?: boolean
  tooltip?: string
}

const ExportMenu = ({
  filterQuery,
  request,
  fileName,
  supportedTypes,
  isDisabled,
  tooltip,
}: ExportMenuProps) => {
  const [loading, setLoading] = useState(false)
  const csvTooltip = useTooltip()
  const xlsxTooltip = useTooltip()

  if (!request) {
    return null
  }

  const renderTooltip = (tooltipProps: TooltipState<HTMLButtonElement>) => {
    return (
      <Tooltip
        {...tooltipProps.getTargetProps()}
        style={{ textAlign: 'center' }}
        placement="bottom-start"
        width={200}
      >
        {tooltip}
      </Tooltip>
    )
  }

  const onClickExportItem = (exportType?: string | number) => {
    if (!loading && !isDisabled) {
      setLoading(true)
      request(exportType as ExportTypes, filterQuery)
        .then((response: AxiosResponse<string>) => {
          saveFileByBlob(
            response.data,
            `${fileName}.${exportType}`,
            response.headers['content-type'],
          )
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  return (
    <>
      {(!supportedTypes || supportedTypes.includes(ExportTypes.csv)) && (
        <MoreBar.Action
          useIcon={Download}
          onClick={() => onClickExportItem(ExportTypes.csv)}
          aria-disabled={loading || isDisabled}
          {...csvTooltip.getAnchorProps()}
        >
          Download CSV
          {tooltip && renderTooltip(csvTooltip)}
        </MoreBar.Action>
      )}
      {(!supportedTypes || supportedTypes.includes(ExportTypes.xlsx)) && (
        <MoreBar.Action
          useIcon={Download}
          onClick={() => onClickExportItem(ExportTypes.xlsx)}
          aria-disabled={loading || isDisabled}
          {...xlsxTooltip.getAnchorProps()}
        >
          Download XLSX
          {tooltip && renderTooltip(xlsxTooltip)}
        </MoreBar.Action>
      )}
    </>
  )
}

export default ExportMenu
