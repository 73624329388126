import React, { useMemo, useState } from 'react'
import { AxiosError } from 'axios'
import {
  ActionButton,
  Cell,
  Color,
  Flex,
  MoreBar,
  StatusWidget,
  Token,
} from '@revolut/ui-kit'
import format from 'date-fns/format'

import { EmployeeInterface } from '@src/interfaces/employees'
import SettingsButtons from '@src/features/SettingsButtons'
import { getEmployeeShifts, useEmployeesDefaultHours } from '@src/api/attendance'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import {
  canAddScheduleSettings,
  canAddTimeOffRequests,
  canChangeScheduleSettings,
} from '../ProfileSummary/common'
import { useTable } from '@components/Table/hooks'
import AdjustableTable from '@components/Table/AdjustableTable'
import { RowInterface } from '@src/interfaces/data'
import {
  EmployeeShiftDetailsInterface,
  EmployeeShiftsStatsInterface,
} from '@src/interfaces/attendance'
import {
  formatShiftDuration,
  isChildShift,
  scheduleShiftApprovalColumn,
  scheduleShiftDateColumn,
  scheduleShiftDurationColumn,
  scheduleShiftEndColumn,
  scheduleShiftStartColumn,
  scheduleShiftTypesColumn,
} from '@src/constants/columns/attendance'
import Stat from '@components/Stat/Stat'
import SwitchButton from '@components/SwitchButton/SwitchButton'
import { MonthSelect } from '@components/MonthSelect/MonthSelect'
import {
  dateFilterFormat,
  getInitialMonthFilter,
  MonthToggle,
} from '@src/features/Attendance/MonthToggle'
import { ApprovalStatuses } from '@src/interfaces/approvalFlow'
import { selectUser } from '@src/store/auth/selectors'
import { useSelector } from 'react-redux'
import { InternalLink } from '@components/InternalLink/InternalLink'
import { TableNames } from '@src/constants/table'
import { useAttendanceTrackingSettings } from '@src/api/settings'

const ROW: RowInterface<EmployeeShiftDetailsInterface> = {
  noChildrenRequest: true,
  highlight: data => (isChildShift(data) ? Token.color.greyTone2 : ''),
  isChildrenOpener: data => !isChildShift(data),
  cells: [
    {
      ...scheduleShiftDateColumn,
      width: 240,
    },
    {
      ...scheduleShiftTypesColumn,
      width: 360,
    },
    {
      ...scheduleShiftStartColumn,
      width: 60,
    },
    {
      ...scheduleShiftEndColumn,
      width: 60,
    },
    {
      ...scheduleShiftDurationColumn,
      width: 60,
    },
    {
      ...scheduleShiftApprovalColumn,
      width: 60,
    },
  ],
}

interface Props {
  data: EmployeeInterface
}

export const Schedule = ({ data }: Props) => {
  const employeesDefaultHours = useEmployeesDefaultHours(data.id)
  const user = useSelector(selectUser)
  const isThisUser = data.id === user.id
  const { data: attendanceSettings, isLoading: isLoadingAttendanceSettings } =
    useAttendanceTrackingSettings()

  const table = useTable<
    EmployeeShiftDetailsInterface,
    undefined,
    EmployeeShiftsStatsInterface
  >({ getItems: getEmployeeShifts(data.id) }, [
    {
      columnName: 'approval',
      filters: isThisUser
        ? []
        : [
            { id: ApprovalStatuses.Pending, name: ApprovalStatuses.Pending },
            { id: ApprovalStatuses.Approved, name: ApprovalStatuses.Approved },
            { id: ApprovalStatuses.NoStatus, name: ApprovalStatuses.NoStatus },
          ],
    },
  ])

  const tableDate = table.filterBy.find(col => col.columnName === 'date')?.filters[0].id

  const [expandAllDays, setExpandAllDays] = useState(false)
  const [selectedMonth, setSelectedMonth] = useState(
    () => tableDate || getInitialMonthFilter(),
  )

  const hoursNotSet =
    (employeesDefaultHours.error as AxiosError)?.response?.status === 404
  const hoursSet = !!employeesDefaultHours.data

  const canChangeSettings = canChangeScheduleSettings(employeesDefaultHours.data)
  const canAddSettings = canAddScheduleSettings(data)
  const canAddRequest = canAddTimeOffRequests(data)
  const canAddShift = isThisUser
    ? !attendanceSettings?.restrict_employee_overtime_requests
    : canAddSettings

  const tableRow = useMemo(
    () => ({
      ...ROW,
      isOpen: (shift: EmployeeShiftDetailsInterface) =>
        isChildShift(shift) ? false : expandAllDays,
    }),
    [expandAllDays],
  )

  const tableLoading = employeesDefaultHours.isLoading || table.loading

  return (
    <Cell>
      <Flex flexDirection="column" width="100%">
        <Flex justifyContent="space-between">
          <Flex mb="s-24" gap="s-32">
            <MonthSelect
              value={selectedMonth}
              onChange={date => {
                if (date) {
                  const newMonth = format(date, dateFilterFormat)
                  table.onFilterChange(
                    {
                      columnName: 'date',
                      filters: [{ id: newMonth, name: newMonth }],
                      nonResettable: true,
                    },
                    false,
                  )
                  setSelectedMonth(newMonth)
                }
              }}
            />
            <Stat
              val={
                table.metadata?.stats?.work
                  ? formatShiftDuration({
                      hours: table.metadata?.stats.work.hours,
                      minutes: table.metadata?.stats.work.minutes,
                    })
                  : undefined
              }
              label="Work"
            />
            <Stat
              val={
                table.metadata?.stats?.time_off
                  ? formatShiftDuration({
                      days: table.metadata.stats.time_off.days,
                      hours: table.metadata.stats.time_off.hours,
                      minutes: table.metadata.stats.time_off.minutes,
                    })
                  : undefined
              }
              label="Time-off"
            />
            <Stat
              val={
                table.metadata?.stats?.overtime
                  ? formatShiftDuration({
                      hours: table.metadata.stats.overtime.hours,
                      minutes: table.metadata.stats.overtime.minutes,
                    })
                  : undefined
              }
              label="Overtime"
              color={Color.ORANGE}
            />
          </Flex>

          <MonthToggle
            value={selectedMonth}
            onChange={date => {
              setSelectedMonth(date)
              table.onFilterChange([
                { columnName: 'date', filters: [{ id: date, name: date }] },
              ])
            }}
          />
        </Flex>

        <SettingsButtons mb="s-16">
          {canAddShift && (
            <MoreBar.Action
              pending={isLoadingAttendanceSettings}
              onClick={() =>
                navigateTo(
                  pathToUrl(ROUTES.FORMS.SCHEDULE_SHIFT.GENERAL, {
                    employeeId: data.id,
                  }),
                )
              }
              useIcon="Plus"
            >
              Add extra shift
            </MoreBar.Action>
          )}
          {hoursSet && canChangeSettings && (
            <MoreBar.Action
              to={pathToUrl(ROUTES.FORMS.DEFAULT_WORKING_HOURS.GENERAL, {
                id: data.id,
              })}
              use={InternalLink}
              useIcon="Pencil"
            >
              Edit default working hours
            </MoreBar.Action>
          )}
          {canAddRequest && (
            <MoreBar.Action
              use={InternalLink}
              to={pathToUrl(ROUTES.FORMS.EMPLOYEE_TIME_OFF_REQUEST.GENERAL, {
                employeeId: data.id,
              })}
              useIcon="Resort"
            >
              Request time off
            </MoreBar.Action>
          )}
          <SwitchButton
            checked={expandAllDays}
            onClick={() => setExpandAllDays(!expandAllDays)}
          >
            Expand all days
          </SwitchButton>
        </SettingsButtons>

        <Flex style={{ position: 'relative' }} flex="1 0">
          <AdjustableTable
            name={TableNames.Schedule}
            {...table}
            useWindowScroll
            row={tableRow}
            loading={tableLoading}
            data={hoursSet ? table.data : []}
            expandableType="chevron"
            hideCount
            noReset
            count={0}
            onRowClick={row => {
              if (isChildShift(row)) {
                if (row.type.id === 'time_off') {
                  navigateTo(
                    pathToUrl(ROUTES.FORMS.EMPLOYEE_TIME_OFF_REQUEST.PREVIEW, {
                      employeeId: data.id,
                      id: row.id,
                    }),
                  )
                }
                if (row.type.id === 'work') {
                  navigateTo(
                    pathToUrl(ROUTES.FORMS.SCHEDULE_SHIFT.PREVIEW, {
                      employeeId: data.id,
                      id: row.id,
                    }),
                  )
                }
              }
            }}
            emptyState={
              <StatusWidget>
                <StatusWidget.Image
                  src="https://assets.revolut.com/assets/3d-images/3D036.png"
                  srcSet="https://assets.revolut.com/assets/3d-images/3D036@2x.png 2x, https://assets.revolut.com/assets/3d-images/3D036@3x.png 3x"
                />
                <StatusWidget.Title>
                  No attendance scheduled for this month
                </StatusWidget.Title>
                <StatusWidget.Description>
                  Please fill in the scheduler by setting your preferred working hours for
                  this month.
                </StatusWidget.Description>
                {hoursNotSet && canAddSettings ? (
                  <StatusWidget.Action>
                    <ActionButton
                      to={pathToUrl(ROUTES.FORMS.DEFAULT_WORKING_HOURS.GENERAL, {
                        id: data.id,
                        new: 'new',
                      })}
                      use={InternalLink}
                      useIcon="CalendarWeek"
                    >
                      Set working hours
                    </ActionButton>
                  </StatusWidget.Action>
                ) : null}
              </StatusWidget>
            }
          />
        </Flex>
      </Flex>
    </Cell>
  )
}
