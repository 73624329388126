import { Gear } from '@revolut/icons'
import { IconButton } from '@revolut/ui-kit'
import React from 'react'
import { MainHeaderButton } from '@src/features/MainHeader/MainHeaderButton'
import { InternalLink } from '@components/InternalLink/InternalLink'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'

export const Settings = () => {
  return (
    <MainHeaderButton>
      <IconButton
        useIcon={Gear}
        aria-label="Settings"
        size={24}
        use={InternalLink}
        to={pathToUrl(ROUTES.PREFERENCES.ALL)}
        color="grey-tone-50"
      />
    </MainHeaderButton>
  )
}
