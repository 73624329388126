import React from 'react'
import { Box, chain, Text } from '@revolut/ui-kit'
import { Route, Switch, useParams } from 'react-router-dom'
import { connect } from 'lape'

import { ROUTES } from '@src/constants/routes'
import { scheduleShiftsRequests } from '@src/api/attendance'
import Form from '@src/features/Form/Form'
import General from './General'
import Preview from './Preview'
import Changelog from './Changelog'
import { pathToUrl } from '@src/utils/router'
import { PageWrapper } from '@src/components/Page/Page'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import TabBarNavigation from '@src/features/TabBarNavigation/TabBarNavigation'
import { EmployeeUserWithAvatar } from '@src/pages/EmployeeProfile/Preview/ProfileSummary/common'
import { getStatusColor } from '@src/components/CommonSC/General'
import { formatWithoutTimezone } from '@src/utils/format'
import { ScheduleShiftInterface } from '@src/interfaces/attendance'
import { useLapeContext } from '@src/features/Form/LapeForm'

const SchedulingPolicyShift = () => {
  return (
    <Switch>
      <Route exact path={ROUTES.FORMS.SCHEDULE_SHIFT.GENERAL} component={General} />
      <Route
        path={[
          ROUTES.FORMS.SCHEDULE_SHIFT.PREVIEW,
          ROUTES.FORMS.SCHEDULE_SHIFT.CHANGELOG,
        ]}
        component={Tabs}
      />
    </Switch>
  )
}

const Tabs = () => {
  const { values } = useLapeContext<ScheduleShiftInterface>()

  const params = useParams<{ employeeId: string; id: string }>()

  const backUrl = pathToUrl(ROUTES.FORMS.EMPLOYEE.SCHEDULE, { id: params.employeeId })

  const tabs = [
    {
      title: 'Preview',
      path: ROUTES.FORMS.SCHEDULE_SHIFT.PREVIEW,
      to: pathToUrl(ROUTES.FORMS.SCHEDULE_SHIFT.PREVIEW, params),
      component: Preview,
    },
    {
      title: 'Changelog',
      path: ROUTES.FORMS.SCHEDULE_SHIFT.CHANGELOG,
      to: pathToUrl(ROUTES.FORMS.SCHEDULE_SHIFT.CHANGELOG, params),
      component: Changelog,
    },
  ]

  return (
    <PageWrapper>
      <PageHeader
        title={chain(
          'Work',
          formatWithoutTimezone(values.date),
          values.approval_status.id === 'none' ? null : (
            <Text color={getStatusColor(values.approval_status.id)}>
              {values.approval_status.name}
            </Text>
          ),
        )}
        subtitle={<EmployeeUserWithAvatar id={params.employeeId} />}
        backUrl={backUrl}
      >
        {tabs.length > 1 && (
          <Box pb="s-8" maxWidth="100vw">
            <TabBarNavigation tabs={tabs} />
          </Box>
        )}
      </PageHeader>
      <Switch>
        {tabs.map(tab => (
          <Route exact path={tab.path} key={tab.path}>
            <tab.component />
          </Route>
        ))}
      </Switch>
    </PageWrapper>
  )
}

export default connect(() => (
  <Form api={scheduleShiftsRequests} disableLocalStorageCaching>
    <SchedulingPolicyShift />
  </Form>
))
