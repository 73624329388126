import { Bar, ItemSkeleton, Search, VStack } from '@revolut/ui-kit'
import FilterButtonDateRange from '@components/FilterButtonDateRange/FilterButtonDateRange'
import {
  EngagementResultsEntities,
  EngagementResultsScope,
} from '@src/interfaces/engagement'
import FilterButtonCheckboxSelect from '@components/FilterButtonCheckboxSelect/FilterButtonCheckboxSelect'
import { CommentItem } from '@src/apps/People/Engagement/Results/components/Comments/CommentItem'
import React, { useEffect } from 'react'
import {
  useCommentsFilters,
  useDriversOptions,
  useInfiniteCommentsLoading,
  useQuestionsOptions,
} from '@src/apps/People/Engagement/Results/hooks'
import useFetchOptions from '@components/Inputs/hooks/useFetchOptions'
import { OptionInterface } from '@src/interfaces/selectors'
import { selectorKeys } from '@src/constants/api'

type Props = {
  surveyId: number
  scope?: EngagementResultsScope
  scopeId: number
  driverId?: string
  questionId?: string
  resultsEntity: EngagementResultsEntities
  onLoaded?: (total?: number) => void
}
export const CommentsSidebarContent = ({
  scope,
  scopeId,
  questionId,
  driverId,
  resultsEntity,
  surveyId,
  onLoaded,
}: Props) => {
  const { search, filters, filtersParams } = useCommentsFilters({
    defaultDriverId: driverId,
    defaultQuestionId: questionId,
    defaultSurveyId: surveyId ? String(surveyId) : undefined,
  })
  const { comments, total, isLoadingMore, isReloadingFilters, ref, hasMoreData } =
    useInfiniteCommentsLoading({
      scope,
      scopeId,
      filtersParams,
    })

  const { driversOptions } = useDriversOptions({
    enabled: resultsEntity === EngagementResultsEntities.Questions,
    surveyId: surveyId ? String(surveyId) : undefined,
    questionId,
  })
  const { questionsOptions } = useQuestionsOptions({
    enabled: resultsEntity === EngagementResultsEntities.Drivers,
    surveyId: surveyId ? String(surveyId) : undefined,
    driverId,
  })
  const scoreOptionsData = useFetchOptions<OptionInterface>(
    selectorKeys.engagement_answer_scores,
  )
  const scoreOptions = scoreOptionsData.options.map(({ value }) => value)

  const questionTypesOptionsData = useFetchOptions<OptionInterface>(
    selectorKeys.engagement_question_types,
  )
  const questionTypesOptions = questionTypesOptionsData.options.map(({ value }) => value)

  useEffect(() => {
    onLoaded?.(total)
  }, [total])

  return (
    <VStack space="s-12">
      <Search
        value={search.searchValue}
        placeholder="Search"
        onChange={newValue => {
          search.setSearchValue(newValue)
          filters.setTextFilter(newValue)
        }}
      />
      <Bar pb="s-20">
        <FilterButtonDateRange
          label="Date"
          value={filters.dateFilter}
          onChange={value => {
            if (value) {
              filters.setDateFilter(value)
            }
          }}
        />
        {resultsEntity === EngagementResultsEntities.Questions &&
          !!driversOptions.length && (
            <FilterButtonCheckboxSelect
              width={300}
              options={driversOptions}
              label="Driver"
              value={filters.driversFilter}
              onChange={value => {
                if (value) {
                  filters.setDriversFilter(value)
                }
              }}
            />
          )}
        {resultsEntity === EngagementResultsEntities.Drivers &&
          !!questionsOptions.length && (
            <FilterButtonCheckboxSelect
              width={400}
              options={questionsOptions}
              label="Question"
              value={filters.questionsFilter}
              onChange={value => {
                if (value) {
                  filters.setQuestionsFilter(value)
                }
              }}
            />
          )}
        <FilterButtonCheckboxSelect
          options={scoreOptions}
          label="Rating"
          value={filters.scoreFilter}
          onChange={value => {
            if (value) {
              filters.setScoreFilter(value)
            }
          }}
        />
        <FilterButtonCheckboxSelect
          options={questionTypesOptions}
          label="Type"
          value={filters.questionTypeFilter}
          onChange={value => {
            if (value) {
              filters.setQuestionTypeFilter(value)
            }
          }}
        />
      </Bar>
      {!isLoadingMore && isReloadingFilters ? (
        <>
          <ItemSkeleton />
          <ItemSkeleton />
          <ItemSkeleton />
        </>
      ) : (
        <>
          {comments.map(comment => {
            return <CommentItem key={comment.id} comment={comment} />
          })}
        </>
      )}
      <VStack ref={ref} space="s-12">
        {isLoadingMore && hasMoreData && (
          <>
            <ItemSkeleton />
            <ItemSkeleton />
            <ItemSkeleton />
          </>
        )}
      </VStack>
    </VStack>
  )
}
