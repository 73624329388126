import React, { Suspense, useEffect, useState, useMemo } from 'react'
import { useTable } from '@components/Table/hooks'
import {
  getQueryRunResult,
  useGetQuery,
  queryRun,
  useGetQueryRunWithInterval,
} from '@src/api/dataAnalytics'
import { ChartContent } from '@src/pages/Forms/QueryForm/components/VisualisationResult'
import { Box, Skeleton, Text, Ellipsis, Flex } from '@revolut/ui-kit'
import { getQueryRunData, isQueryRunning } from '@src/pages/Forms/QueryForm/utils'
import { RunQueryResponseInterface, QueryInterface } from '@src/interfaces/dataAnalytics'
import Loader from '@components/CommonSC/Loader'
import { TableNames } from '@src/constants/table'
import AdjustableTable from '@src/components/Table/AdjustableTable'
import {
  getFilters,
  orderingToSort,
  getTableRows,
} from '@src/pages/Forms/DataAnalyticsReportForm/hooks/useTableSettings'
import { MetricsItemTooltip } from '@src/pages/Forms/DataAnalyticsInternalDashboardForm/components/MetricsItemTooltip'

interface MetricsItemProps {
  queryId: string
}

interface MetricsItemVisualisationProps {
  query: QueryInterface
  queryRunId: number
}

interface MetricsItemTitleWrapperProps {
  children: React.ReactNode
  query: QueryInterface
}

const MetricsItemVisualisation = ({
  query,
  queryRunId,
}: MetricsItemVisualisationProps) => {
  const table = useTable({
    getItems: getQueryRunResult(queryRunId, query.id),
  })

  if (table.loading) {
    return <Loader />
  }

  return (
    <Suspense fallback={<Skeleton />}>
      <ChartContent
        chartType={query.visualisation.chartType || 'line'}
        chartSettings={query.visualisation}
        data={table.data}
      />
    </Suspense>
  )
}

const MetricsItemTable = ({ query, queryRunId }: MetricsItemVisualisationProps) => {
  const initialFilterBy = useMemo(
    () =>
      query.output_format?.columns
        ? getFilters(query.output_format.columns, query.output_format?.filters || [])
        : undefined,
    [],
  )

  const initialSort = query.output_format?.ordering
    ? orderingToSort(query.output_format?.ordering)
    : undefined

  const table = useTable(
    {
      getItems: getQueryRunResult(queryRunId, query.id),
    },
    initialFilterBy,
    initialSort,
    { disableQuery: true },
  )

  const tableRows = useMemo(() => {
    return getTableRows(
      query.id,
      query.output_format?.columns!,
      query.output_format?.filters,
      query.output_format?.highlighting,
      queryRunId,
    )
  }, [])

  if (table.loading) {
    return <Loader />
  }

  return (
    <Box overflow="auto" height="100%">
      <AdjustableTable
        hideCountAndButtonSection
        name={TableNames.ReportingAppQueriesRunResult}
        noDataMessage="No data to display"
        row={tableRows}
        {...table}
      />
    </Box>
  )
}

const MetricsItemTitleWrapper = ({ children, query }: MetricsItemTitleWrapperProps) => {
  if (query.visualisation?.chartType === 'counter') {
    return (
      <Box>
        <Flex justifyContent="end">
          <MetricsItemTooltip description={query.description} />
        </Flex>
        {children}
      </Box>
    )
  }

  return (
    <Box height="100%" width="100%">
      <Flex alignItems="center" pb="s-8">
        <Ellipsis style={{ flexGrow: 1 }}>
          <Text variant="tile" use="div" textAlign="center">
            {query.name}
          </Text>
        </Ellipsis>
        <MetricsItemTooltip description={query.description} />
      </Flex>
      <Box height="calc(100% - 32px)" width="100%">
        {children}
      </Box>
    </Box>
  )
}

export const MetricsItem = ({ queryId }: MetricsItemProps) => {
  const [isLoading, setIsLoading] = useState(false)
  const [queryData, setQueryData] = useState<RunQueryResponseInterface>()
  const { data: query } = useGetQuery(queryId)
  const { data: queryRunResult } = useGetQueryRunWithInterval(queryId, queryData?.id)

  useEffect(() => {
    if (!query || isLoading) {
      return
    }

    handleQueryRun()
  }, [query])

  const handleQueryRun = async () => {
    if (!query) {
      return
    }

    setIsLoading(true)

    const queryRunParameters = query.parameters?.reduce(
      (o, key) =>
        Object.assign(o, {
          [key.name]: key.default || null,
        }),
      {},
    )

    const queryRunData = getQueryRunData(query, queryRunParameters)
    const queryResponse = await queryRun(query.id, queryRunData)

    setQueryData(queryResponse.data)
  }

  if (
    !query ||
    !queryRunResult?.id ||
    !queryRunResult?.status ||
    isQueryRunning(queryRunResult.status)
  ) {
    return <Loader />
  }

  if (query.visualisation_type === 'chart') {
    return (
      <MetricsItemTitleWrapper query={query}>
        <MetricsItemVisualisation query={query} queryRunId={queryRunResult.id} />
      </MetricsItemTitleWrapper>
    )
  }

  return (
    <MetricsItemTitleWrapper query={query}>
      <MetricsItemTable query={query} queryRunId={queryRunResult.id} />
    </MetricsItemTitleWrapper>
  )
}
