import React from 'react'
import { HStack, Icon, Token } from '@revolut/ui-kit'

import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import UserWithAvatar from '@src/components/UserWithAvatar/UserWithAvatar'
import { WorkScheduleReviewEligibleEmployeesInterface } from '@src/interfaces/workSchedule'
import { selectorKeys } from '../api'
import Tooltip from '@src/components/Tooltip/Tooltip'

export const timeOffWorkScheduleReviewEmployeeColumn: ColumnInterface<WorkScheduleReviewEligibleEmployeesInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'employee.id',
    dataPoint: 'employee.name',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Employee',
    insert: ({ data }) => <UserWithAvatar {...data} />,
  }

export const timeOffWorkScheduleReviewLocationColumn: ColumnInterface<WorkScheduleReviewEligibleEmployeesInterface> =
  {
    type: CellTypes.text,
    idPoint: 'location.id',
    dataPoint: 'location.name',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Location',
  }

export const timeOffWorkScheduleReviewRoleColumn: ColumnInterface<WorkScheduleReviewEligibleEmployeesInterface> =
  {
    type: CellTypes.text,
    idPoint: 'specialisation.id',
    dataPoint: 'specialisation.name',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Role',
  }

export const timeOffWorkScheduleReviewStatusColumn: ColumnInterface<WorkScheduleReviewEligibleEmployeesInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'status.id',
    dataPoint: 'status.name',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Status',
    insert: ({ data }) => (
      <HStack space="s-4" align="center">
        {!!data.auto_assignments_details.errors.length && (
          <Tooltip placement="top" text={data.auto_assignments_details.errors.join(', ')}>
            <Icon size={16} name="InfoOutline" color={Token.color.greyTone50} />
          </Tooltip>
        )}
        {data.auto_assignments_details?.valid ? 'Will apply' : 'Will not apply'}
      </HStack>
    ),
  }
