import { ROUTES } from '@src/constants/routes'
import { PermissionTypes } from '@src/store/auth/types'
import {
  HomeSectionId,
  TabBarWithPinningTabInterface,
  Subtab,
} from '@src/interfaces/tabPinning'
import TeamsTable from '@src/pages/Organisation/OrganisationSubTabs/Teams/TeamsTable'
import DepartmentsTable from '@src/pages/Organisation/OrganisationSubTabs/Departments/DepartmentsTable'
import RolesTable from '@src/pages/Organisation/OrganisationSubTabs/Roles/RolesTable'
import FunctionsTable from '@src/pages/Organisation/OrganisationSubTabs/Functions/FunctionsTable'
import SpecialisationsTable from '@src/pages/Organisation/OrganisationSubTabs/Specialisations/SpecialisationsTable'

const teamsTabs: Subtab[] = [
  {
    id: HomeSectionId.departments,
    title: 'Departments',
    path: ROUTES.ORGANISATION.TEAMS.DEPARTMENTS,
    to: ROUTES.ORGANISATION.TEAMS.DEPARTMENTS,
    permission: PermissionTypes.ViewDepartment,
    component: DepartmentsTable,
  },
  {
    id: HomeSectionId.teams,
    title: 'Teams',
    path: ROUTES.ORGANISATION.TEAMS.TEAMS,
    to: ROUTES.ORGANISATION.TEAMS.TEAMS,
    permission: PermissionTypes.ViewTeams,
    component: TeamsTable,
  },
]

const rolesTabs: Subtab[] = [
  {
    id: HomeSectionId.functions,
    title: 'Functions',
    path: ROUTES.ORGANISATION.ROLES.FUNCTIONS,
    to: ROUTES.ORGANISATION.ROLES.FUNCTIONS,
    permission: PermissionTypes.ViewFunction,
    component: FunctionsTable,
  },
  {
    id: HomeSectionId.roles,
    title: 'Roles',
    path: ROUTES.ORGANISATION.ROLES.ROLES,
    to: ROUTES.ORGANISATION.ROLES.ROLES,
    permission: PermissionTypes.ViewRoles,
    component: RolesTable,
  },
  {
    id: HomeSectionId.specialisations,
    title: 'Specialisations',
    path: ROUTES.ORGANISATION.ROLES.SPECIALISATIONS,
    to: ROUTES.ORGANISATION.ROLES.SPECIALISATIONS,
    permission: PermissionTypes.ViewRoles,
    component: SpecialisationsTable,
  },
]

export const organisationSubtabs: TabBarWithPinningTabInterface[] = [
  {
    id: HomeSectionId.teams,
    title: 'Teams',
    path: ROUTES.ORGANISATION.TEAMS.ANY,
    url: ROUTES.ORGANISATION.TEAMS.DEPARTMENTS,
    icon: 'People',
    subtabs: teamsTabs,
    isNewNavigation: true,
  },
  {
    id: HomeSectionId.roles,
    title: 'Roles',
    path: ROUTES.ORGANISATION.ROLES.ANY,
    url: ROUTES.ORGANISATION.ROLES.FUNCTIONS,
    icon: 'RepairTool',
    subtabs: rolesTabs,
    isNewNavigation: true,
  },
  {
    id: HomeSectionId.company,
    title: 'Company',
    url: ROUTES.FORMS.COMPANY.KPI.GOALS,
    icon: 'Bank',
  },
]
