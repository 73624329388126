import React, { useState } from 'react'
import { Route, Switch } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Color, Flex, IconButton } from '@revolut/ui-kit'
import { Pin } from '@revolut/icons'

import MainHeader from '@src/features/MainHeader/MainHeader'
import ImportantKpiBanner from '@src/features/Banners/ImportantKpiBanner'
import { ROUTES } from '@src/constants/routes'
import Dashboard from '@src/pages/Home/HomeSubTabs/Dashboard/Dashboard'
import { SystemStatusBanner } from '@src/features/Banners/SystemStatusBanner'
import { Box, Search, Token } from '@revolut/ui-kit'
import globalSearchState from '@components/GlobalSearchSidebar/GlobalSearchSidebarState'
import { usePinnedTabs } from '@src/features/TabPinning/pinnedTabs'
import { selectPermissions } from '@src/store/auth/selectors'
import { TableWithSubtabs } from '@src/features/TabPinning/TableWithSubtabs'
import { pathToUrl } from '@src/utils/router'
import { TabBarWithPinningTabConfig } from '@src/interfaces/tabPinning'
import { TabBarWithPinning } from '@src/features/TabPinning/TabBarWithPinning'
import { PinTabsSidebar } from './PinTabsSidebar'
import Tooltip from '@src/components/Tooltip/Tooltip'
import { useGetEmployeeSettings } from '@src/api/settings'
import { globalSettingsAppTabsFilter } from '@src/constants/hub'
import { useGetPerformanceSettings } from '@src/api/performanceSettings'
import { InternalRedirect } from '@src/components/InternalLink/InternalRedirect'
import { PageWithTabs } from '@components/PageWithTabs/PageWithTabs'

const homeTabs = [
  {
    id: 'dashboard' as const,
    title: 'Dashboard',
    path: ROUTES.MAIN,
    url: ROUTES.MAIN,
    component: Dashboard,
  },
]

interface HomeLandingPageProps {
  sectionName: string
  tabs: TabBarWithPinningTabConfig[]
}

const HomeLandingPage = ({ sectionName, tabs }: HomeLandingPageProps) => {
  const { pinnedTabs } = usePinnedTabs()
  const permissions = useSelector(selectPermissions)

  const { data: performanceSettings } = useGetPerformanceSettings()
  const { data: employeeSettings } = useGetEmployeeSettings()

  const [pinnedTabsSidebarOpen, setPinnedTabsSidebarOpen] = useState(false)

  const filteredTabs = tabs.filter((tab: TabBarWithPinningTabConfig) => {
    const hasPermission = tab.permissions
      ? tab.permissions.some(permission => permissions.includes(permission))
      : true
    const settingEnabled =
      tab.id &&
      tab.id !== 'dashboard' &&
      globalSettingsAppTabsFilter(tab.id, { employeeSettings, performanceSettings })

    return hasPermission && settingEnabled
  })

  const visibleTabs = [
    ...homeTabs,
    ...pinnedTabs
      .map(tab => {
        const actualTab = filteredTabs.find(t => t.id === tab)

        if (actualTab) {
          const tabOverrideUrl = pathToUrl(ROUTES.PINNED, { id: actualTab.id })
          const subtabs = actualTab.subtabs?.map(subtab => {
            const subtabOverrideUrl = pathToUrl(ROUTES.PINNED, {
              id: actualTab.id,
              subtabId: subtab.id,
            })
            return {
              ...subtab,
              path: subtabOverrideUrl,
              to: subtabOverrideUrl,
            }
          })

          return {
            ...actualTab,
            path: actualTab.path
              ? `${subtabs?.length ? `${tabOverrideUrl}/*` : tabOverrideUrl}`
              : actualTab.path,
            url: actualTab.path
              ? `${subtabs?.length ? subtabs[0].path : tabOverrideUrl}`
              : actualTab.url,
            subtabs,
          }
        }
        return null
      })
      .filter(Boolean),
  ]

  const openGlobalSearch = () => {
    globalSearchState.open = !globalSearchState.open
  }

  return (
    <>
      <Flex pt="s-8" pb="s-24" flexDirection="column">
        <MainHeader title={sectionName}>
          <Box maxWidth={Token.breakpoint.xl}>
            <Box pb="s-16">
              <Search onClick={openGlobalSearch} placeholder="Search" />
            </Box>
            <Flex alignItems="center" mb="s-16" mr="s-4" gap="s-12">
              <Box mx="-s-4" minWidth={0}>
                <TabBarWithPinning tabs={visibleTabs} isHomeTab mx={0} />
              </Box>
              <Tooltip text="Pin and unpin tabs" placement="left" delay={500}>
                <IconButton
                  onClick={() => setPinnedTabsSidebarOpen(true)}
                  useIcon={Pin}
                  aria-label="Open pin tabs sidebar"
                  color={Color.GREY_TONE_20}
                  size={20}
                  tapArea={36}
                />
              </Tooltip>
            </Flex>
            <SystemStatusBanner mb="s-8" />
            <ImportantKpiBanner disabled mb="s-8" />
          </Box>
        </MainHeader>
        <Switch>
          {visibleTabs.map(tab => {
            if (tab.component || ('subtabs' in tab && tab.subtabs)) {
              return (
                <Route
                  exact
                  key={tab.id}
                  path={tab.path}
                  render={() => {
                    document.title = `${sectionName} - ${tab.title}`
                    if (tab.component) {
                      return <tab.component />
                    }
                    if ('subtabs' in tab && tab.subtabs) {
                      if (tab.isNewNavigation) {
                        return <PageWithTabs tabs={tab.subtabs} id={tab.id} />
                      }
                      return <TableWithSubtabs subtabs={tab.subtabs} />
                    }
                    return null
                  }}
                />
              )
            }
            return null
          })}
          <InternalRedirect to={visibleTabs[0].url} />
        </Switch>
      </Flex>

      {pinnedTabsSidebarOpen ? (
        <PinTabsSidebar
          isOpen
          onClose={() => setPinnedTabsSidebarOpen(false)}
          tabs={filteredTabs}
        />
      ) : null}
    </>
  )
}

export default HomeLandingPage
