import React from 'react'
import styled from 'styled-components'
import { Box, Flex, Link, TextButton, Token } from '@revolut/ui-kit'
import { RepairTool } from '@revolut/icons'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { hasTestCycleCookie } from '@src/utils/reviewCycles'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { disableSandboxMode } from './common'
import { defaultTheme } from '@src/styles/theme'

const Container = styled.div`
  position: relative;
  top: 0;
  left: 0;
  width: 100vw;
  height: 42px;
  font-size: 13px;
  color: ${Token.color.white};
  z-index: ${defaultTheme.zIndex.popup};
  background-color: ${Token.color.warning};
`

const TestingCycleWarningBar = () => {
  const testCycleCookie = hasTestCycleCookie()

  if (!testCycleCookie) {
    return null
  }

  return (
    <Container>
      <Flex justifyContent="center" alignItems="center" gap="s-8" height="100%">
        <RepairTool size={16} />
        <Box>
          Sandbox mode enabled,{' '}
          <Link
            use={InternalLink}
            to={pathToUrl(ROUTES.SETTINGS.USER_PREFERENCES)}
            color={Token.color.white}
            textDecoration="underline"
          >
            limitations apply
          </Link>
          . You can control Sandbox mode in{' '}
          <Link
            use={InternalLink}
            to={pathToUrl(ROUTES.SETTINGS.USER_PREFERENCES)}
            color={Token.color.white}
            textDecoration="underline"
          >
            Settings
          </Link>{' '}
          or{' '}
          <TextButton
            color={Token.color.white}
            style={{ textDecoration: 'underline' }}
            onClick={disableSandboxMode}
          >
            Disable
          </TextButton>
        </Box>
      </Flex>
    </Container>
  )
}

export default TestingCycleWarningBar
