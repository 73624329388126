import isNumber from 'lodash/isNumber'
import get from 'lodash/get'
import mapValues from 'lodash/mapValues'

import {
  EngagementDriverChartDatum,
  EngagementQuestionChartDatum,
} from '@src/interfaces/engagement'
import { formatPercentage } from '@src/utils/format'
import { NON_ANSWERED_KEY } from '@src/apps/People/Engagement/Results/components/constants'

export const useChartDataByEntityId = <
  T extends EngagementDriverChartDatum | EngagementQuestionChartDatum,
>({
  id,
  data,
  scoreAccessor,
}: {
  id: number | undefined
  data: Record<number, T[]>
  scoreAccessor: (datum: T) => number
}) => {
  if (!id) {
    return {
      data: [],
    }
  }
  const dataById =
    data[id]
      ?.map((d, idx) => {
        const total = d.number_of_answers + d.unanswered

        if (!d.can_show_details || !total || !isNumber(total)) {
          return null
        }
        return {
          xAxis: idx,
          score: scoreAccessor(d),
          label: d.survey.name,
          benchmark: d.benchmark,
          promoters: d.promoters,
          passives: d.passives,
          detractors: d.detractors,
          unanswered: d.unanswered,
          scoresDistribution: {
            ...mapValues(d.scores_distribution, scoresNumber => ({
              percentage: formatPercentage(scoresNumber / total, 2),
              width: scoresNumber / total,
              scoresNumber,
            })),
            [NON_ANSWERED_KEY]: {
              percentage: formatPercentage(d.unanswered / total, 2),
              width: d.unanswered / total,
              scoresNumber: d.unanswered,
            },
          },
        }
      })
      .filter(Boolean) || []

  return {
    data: dataById,
    xAxis: {
      key: 'xAxis',
      toLabel: (xAxisValue: number) => {
        return get(dataById[xAxisValue], 'label', '')
      },
    },
  }
}
