import React, { useState } from 'react'
import { useOrdering } from '@components/Table/hooks'
import { RowInterface, SORT_DIRECTION } from '@src/interfaces/data'
import { Statuses } from '@src/interfaces'
import {
  initialRequisitionStatusFilter,
  RequisitionInterface,
} from '@src/interfaces/requisitions'
import { requisitionsRequests, updateRequisitionsOrder } from '@src/api/requisitions'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import {
  requisitionCandidatesColumn,
  requisitionHeadcountColumn,
  requisitionIdColumn,
  requisitionPotentialStartDateColumn,
  requisitionPriorityColumn,
  requisitionQueuePosition,
  requisitionStatusColumn,
  createRequisitionTitleColumn,
  requisitionBackfillColumn,
} from '@src/constants/columns/requisition'
import { teamDepartmentColumn, teamNameColumn } from '@src/constants/columns/team'
import { seniorityNameRequisitionsColumn } from '@src/constants/columns/seniority'
import { locationNameRequisitionColumn } from '@src/constants/columns/location'
import { lineManagerColumn, recruiterNameColumn } from '@src/constants/columns/employee'
import { SpecialisationInterface } from '@src/interfaces/roles'
import { filterSortPageIntoQuery } from '@src/utils/table'
import { Flex, MoreBar, HStack } from '@revolut/ui-kit'
import Stat from '@components/Stat/Stat'
import AdjustableTable from '@components/Table/AdjustableTable'
import { orderingCellsWrapper } from '@src/utils/ordering'
import OrderingTableControls from '@src/features/OrderingTableControls/OrderingTableControls'
import { PermissionTypes } from '@src/store/auth/types'
import { OrderingDataType, UpdateOrderingInterface } from '@src/interfaces/ordering'
import ReferCandidateButton from '@components/ReferCandidateButton/ReferCandidateButton'
import AddRequisitionButton from '@src/features/CommonRequisitionTable/AddRequisitionButton'
import { TableNames } from '@src/constants/table'
import { TableWrapper } from '@components/Table/TableWrapper'
import { useRequisitionTable } from '@src/features/CommonRequisitionTable/CommonRequisitionTable'
import { useGetRequisitionSettings } from '@src/api/settings'
import { TalentType } from '@src/interfaces/talent/talent'
import { AllowedExportMenu } from '@src/features/ExportMenu/AllowedExportMenu'
import { useLapeContext } from '@src/features/Form/LapeForm'

interface StructureProps {
  data: SpecialisationInterface
}

const ROW = (
  orderingMode: boolean,
  onChangePosition: (id: number, position: number) => void,
): RowInterface<RequisitionInterface> => ({
  disabled: data => data.status === Statuses.rejected || data.status === Statuses.closed,
  cells: orderingCellsWrapper<RequisitionInterface>(orderingMode, onChangePosition, [
    {
      ...requisitionIdColumn,
      width: 90,
    },
    {
      ...createRequisitionTitleColumn(false),
      width: 200,
    },
    {
      ...teamDepartmentColumn,
      width: 170,
    },
    {
      ...teamNameColumn,
      width: 170,
    },
    {
      ...seniorityNameRequisitionsColumn,
      width: 100,
    },
    {
      ...locationNameRequisitionColumn,
      width: 150,
    },
    {
      ...lineManagerColumn,
      width: 150,
    },
    {
      ...requisitionHeadcountColumn,
      width: 100,
    },
    {
      ...requisitionPriorityColumn,
      width: 100,
    },
    {
      ...recruiterNameColumn,
      width: 150,
    },
    {
      ...requisitionPotentialStartDateColumn,
      width: 150,
    },
    {
      ...requisitionCandidatesColumn,
      width: 100,
    },
    {
      ...requisitionStatusColumn,
      width: 100,
    },
    {
      ...requisitionBackfillColumn,
      width: 100,
    },
  ]),
})

const Requisitions = ({ data }: StructureProps) => {
  const { values } = useLapeContext<SpecialisationInterface>()
  const [orderingMode, setOrderingMode] = useState(false)
  const [activeOrderingRow, setActiveOrderingRow] = useState<number | null>()
  const { data: requisitionSettings } = useGetRequisitionSettings()

  const isHiringColumnsEnabled = requisitionSettings?.enable_table_hiring_fields

  const hiddenCells: Partial<Record<keyof RequisitionInterface, boolean>> = {
    [requisitionPotentialStartDateColumn.idPoint]: !isHiringColumnsEnabled,
    [requisitionQueuePosition.idPoint]: !isHiringColumnsEnabled,
    [requisitionCandidatesColumn.idPoint]: !isHiringColumnsEnabled,
    [requisitionPriorityColumn.idPoint]: !isHiringColumnsEnabled,
  }

  const initialFilter = [
    {
      filters: [{ name: data.name, id: data.id }],
      columnName: 'specialisation__id',
      nonResettable: true,
    },
    {
      filters: initialRequisitionStatusFilter,
      columnName: 'status',
    },
    {
      filters: [
        { name: OrderingDataType.Specialisation, id: OrderingDataType.Specialisation },
      ],
      columnName: 'parent_type',
      nonResettable: true,
    },
    {
      filters: [{ name: data.name, id: data.id }],
      columnName: 'parent_id',
      nonResettable: true,
    },
    {
      filters: [{ name: 'True', id: 'True' }],
      columnName: 'include_non_prioritised_items',
      nonResettable: true,
    },
  ]
  const initialSortBy = [
    {
      sortBy: 'pipeline_queue_position',
      direction: SORT_DIRECTION.DESC,
      nonResettable: true,
    },
  ]

  const table = useRequisitionTable({
    filterBy: initialFilter,
    sortBy: initialSortBy,
    statsData: {
      filters: [
        {
          filters: [{ name: data.name, id: data.id }],
          columnName: 'specialisation__id',
        },
      ],
    },
  })

  const onAfterChange = async (requestData: UpdateOrderingInterface) => {
    try {
      await updateRequisitionsOrder(requestData, initialFilter)
    } catch (e) {
      // if something went wrong - cancel optimistic changes
      table.refresh()
    }
  }

  const {
    onChangeOrder,
    onChangePosition,
    moveToTop,
    moveToBottom,
    selectedOrderingIds,
    setSelectedOrderingIds,
  } = useOrdering(table.data, table.setData, table.count, table.refresh, onAfterChange)

  const onHandleChangePosition = (id: number, positionNumber: number) => {
    onChangePosition(id, positionNumber)

    setActiveOrderingRow(id)
    setTimeout(() => {
      setActiveOrderingRow(null)
    }, 1200)
  }

  const onChangeOrderingMode = (isOrdering: boolean) => {
    if (isOrdering) {
      table.resetFiltersAndSorting([
        {
          filters: [{ name: 'False', id: 'False' }],
          columnName: 'include_non_prioritised_items',
          nonResettable: true,
        },
        {
          filters: [{ name: Statuses.opened, id: Statuses.opened }],
          columnName: 'status',
        },
      ])
    } else {
      table.resetFiltersAndSorting(initialFilter)
    }
    setOrderingMode(isOrdering)
  }

  const handleRowEdit = (row: RequisitionInterface) => {
    navigateTo(pathToUrl(ROUTES.FORMS.REQUISITION.ROLE, { id: row.id }))
  }

  const filterQuery = filterSortPageIntoQuery(table.sortBy, table.filterBy, 1)
  const canReorder =
    isHiringColumnsEnabled &&
    data.field_options.permissions?.includes(PermissionTypes.ReorderRequisitions)

  return (
    <TableWrapper>
      <Flex mb="s-24">
        <Stat
          mr="s-16"
          label="Total Headcount"
          val={table?.stats?.requisition_total_headcount}
        />
        <Stat
          label="Remaining Headcount"
          val={table?.stats?.requisition_remaining_headcount}
        />
      </Flex>
      <HStack space="s-12" align="center" mb="s-24">
        {canReorder && (
          <OrderingTableControls
            disabled={false}
            orderingMode={orderingMode}
            onChangeMode={onChangeOrderingMode}
            moveToTop={moveToTop}
            moveToBottom={moveToBottom}
            disabledMoveButtons={!selectedOrderingIds?.length}
          />
        )}
        {!orderingMode && (
          <MoreBar>
            <AddRequisitionButton
              newItemInitialValues={{
                specialisation: {
                  id: values.id,
                  name: values.name,
                  role: values.role,
                },
              }}
            />
            <ReferCandidateButton />
            <AllowedExportMenu
              request={requisitionsRequests.getExport}
              filterQuery={filterQuery}
              fileName="Requisitions"
              type={TalentType.Specialisation}
            />
          </MoreBar>
        )}
      </HStack>

      <AdjustableTable
        name={TableNames.SpecialisationRequisitions}
        dataType="Requisition"
        row={ROW(orderingMode, onHandleChangePosition)}
        {...table}
        onRowClick={orderingMode ? undefined : handleRowEdit}
        noDataMessage="All requisitions will appear here."
        orderingMode={orderingMode}
        disabledFiltering={orderingMode}
        setSelectedOrderingIds={setSelectedOrderingIds}
        selectedOrderingIds={selectedOrderingIds}
        onChangeOrder={onChangeOrder}
        activeOrderingRow={activeOrderingRow}
        hiddenCells={hiddenCells}
        useWindowScroll
        tableSettings={{
          hidden: ['Type'],
          visible: [],
        }}
      />
    </TableWrapper>
  )
}

export default Requisitions
