import React from 'react'
import { MoreBar } from '@revolut/ui-kit'
import { useParams } from 'react-router-dom'
import { ArchiveButton, EditButton } from '@src/features/SettingsButtons'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { JobPostingInterface } from '@src/interfaces/jobPosting'
import { EntityPermissions } from '@src/store/auth/types'
import { useGetJobPostingSettings } from '@src/api/settings'
import { ApproveButton, RejectButton } from '@src/features/SettingsButtons'
import { handleError } from '@src/api'
import { ApprovalStatuses } from '@src/interfaces/approvalFlow'
import { useGetJobDescriptionApprovals } from '@src/api/jobPosting'
import { useNavigateToJobPostingPath } from '@src/features/JobPostingFlow/utils'
import PublishButton from '@src/pages/Forms/JobPosting/Components/PublishButton'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { AutomationAction } from '@src/pages/Forms/JobPosting/Components/AutomationAction'
import { BookingLinkAction } from '@src/pages/Forms/JobPosting/Components/BookingLinkAction'
import { ShareCandidatesAccessAction } from '@src/pages/Forms/JobPosting/Components/ShareCandidatesAccessAction'

type JobPostingActionsProps = {
  onApproving: (isApproving: boolean) => void
}

const JobPostingActions = ({ onApproving }: JobPostingActionsProps) => {
  const { values } = useLapeContext<JobPostingInterface>()
  const params = useParams<{ id: string; specId: string }>()
  const { data: jobPostingSettings } = useGetJobPostingSettings()
  const enableApprovals = jobPostingSettings?.enable_approvals
  const { refetch: refetchApproval } = useGetJobDescriptionApprovals(
    enableApprovals ? values?.id : undefined,
  )
  const canChange = !!values.field_options?.actions?.includes?.(EntityPermissions.Change)
  const canApprove =
    values?.approval_status === ApprovalStatuses.Pending &&
    values?.field_options?.actions?.includes(EntityPermissions.Approve)
  const navigateToJobPosting = useNavigateToJobPostingPath()
  const onBeforeApproval = () => onApproving(true)
  const onAfterApproval = () => {
    onApproving(false)
    refetchApproval()
  }
  return (
    <MoreBar maxCount={3}>
      {canApprove && (
        <>
          <ApproveButton
            isVisible={enableApprovals}
            onBeforeSubmit={onBeforeApproval}
            onAfterSubmit={onAfterApproval}
            onSubmitFailed={handleError}
            statusFieldName="approval_status"
          />
          <RejectButton
            isVisible={enableApprovals}
            onBeforeSubmit={onBeforeApproval}
            onAfterSubmit={onAfterApproval}
            onSubmitFailed={handleError}
            statusFieldName="approval_status"
            dialog={{
              title: 'Reason for rejection',
              placeholder: 'a couple of words',
              fieldName: 'rejection_comment',
            }}
          />
        </>
      )}
      <PublishButton />
      <EditButton
        onClick={() => {
          navigateToJobPosting({
            id: params.id,
            specialisationId: params.specId,
            name: values.name,
          })
        }}
        isVisible={canChange}
      />
      <MoreBar.Action
        useIcon="Questionnaire"
        use={InternalLink}
        to={pathToUrl(ROUTES.FORMS.JOB_POSTING.APPLICATION_FORM, params)}
      >
        Application form
      </MoreBar.Action>
      <AutomationAction />
      <BookingLinkAction />
      <ShareCandidatesAccessAction />
      <ArchiveButton
        entityPermissions={EntityPermissions.Change}
        isVisible={!!values?.id}
        statusFieldName="approval_status"
        showDialog
        dialogLabel="Are you sure?"
        dialogText="This action will archive the job posting. It might require approvals to open it again after archiving"
        archiveButtonLabel="Archive job posting"
        yesMessage="Archive"
        noMessage="Cancel"
        yesBtnVariant="negative"
        noBtnVariant="primary"
      />
    </MoreBar>
  )
}

export default JobPostingActions
