import React, { useMemo } from 'react'
import { RowInterface } from '@src/interfaces/data'
import AdjustableTable from '@components/Table/AdjustableTable'
import { useTable } from '@components/Table/hooks'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { Flex, MoreBar, Bar, Box, FilterButton, Widget } from '@revolut/ui-kit'
import { Plus } from '@revolut/icons'
import Stat from '@components/Stat/Stat'
import { PermissionTypes } from '@src/store/auth/types'
import { useSelector } from 'react-redux'
import { selectPermissions, selectUser } from '@src/store/auth/selectors'
import { nipsColumn } from '@src/constants/columns/department'
import { SpecialisationInterface } from '@src/interfaces/roles'
import { specialisationsRequests } from '@src/api/roles'
import {
  specialisationAllSkillsColumn,
  specialisationGenericNameColumn,
  specialisationHeadcountColumn,
  specialisationManagerColumn,
  specialisationRequisitionsColumn,
  specialisationRoleColumn,
  specialisationStatusColumn,
} from '@src/constants/columns/specialisation'
import SearchTable from '@components/Table/SearchTable/SearchTable'
import { Statuses } from '@src/interfaces'
import { useLocalStorage } from '@src/hooks/useLocalStorage'
import { LOCAL_STORAGE } from '@src/constants/api'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { TableNames } from '@src/constants/table'
import { useGetOrganisationSettings } from '@src/api/settings'
import { withFavourites } from '@src/features/FavouritesFilter/withFavourites'
import { useFavouritesFilter } from '@src/features/FavouritesFilter/useFavouritesFilter'
import { SettingsButton } from '@src/features/SettingsButtons/SettingsButton/SettingsButton'
import { settingsConfig } from '@src/pages/Settings/SettingsLandingPage/constants'

const SpecialisationsTable = () => {
  const user = useSelector(selectUser)
  const [showMySpecialisations, setShowMySpecialisations] = useLocalStorage(
    LOCAL_STORAGE.SHOW_MY_SPECIALISATIONS,
    false,
  )
  const [showArchivedSpecialisations, setShowArchivedSpecialisations] = useLocalStorage(
    LOCAL_STORAGE.SHOW_ARCHIVED_SPECIALISATIONS,
    false,
  )
  const { FavouritesFilter, initialFilter } = useFavouritesFilter('specialisation')

  const getFilterByOwner = (setFilter: boolean) => ({
    filters: setFilter ? [{ name: user.display_name, id: user.id }] : [],
    columnName: 'owner__id',
    nonResettable: true,
  })

  const getFilterByStatus = (archived: boolean) => ({
    filters: archived
      ? [{ name: Statuses.archived, id: Statuses.archived }]
      : [
          { id: Statuses.pending, name: Statuses.pending },
          { id: Statuses.approved, name: Statuses.approved },
          { id: Statuses.draft, name: Statuses.draft },
        ],
    columnName: 'status',
  })

  const getInitialFilter = () => {
    const filter = []

    filter.push(getFilterByStatus(showArchivedSpecialisations))
    filter.push(getFilterByOwner(showMySpecialisations))

    if (initialFilter) {
      filter.push(initialFilter)
    }

    return filter
  }

  const filterBy = getInitialFilter()
  const table = useTable<SpecialisationInterface>(specialisationsRequests, filterBy)
  const permissions = useSelector(selectPermissions)

  const canAdd = permissions.includes(PermissionTypes.AddRoles)

  const { data: settings } = useGetOrganisationSettings()

  const row = useMemo((): RowInterface<SpecialisationInterface> => {
    return {
      linkToForm({ id, status }) {
        if (status === Statuses.draft) {
          return navigateTo(pathToUrl(ROUTES.FORMS.SPECIALISATIONS.GENERAL, { id }))
        }
        return navigateTo(pathToUrl(ROUTES.FORMS.SPECIALISATIONS.PREVIEW, { id }))
      },
      cells: [
        {
          ...specialisationGenericNameColumn,
          width: 270,
        },
        {
          ...specialisationRoleColumn,
          width: 250,
        },
        {
          ...specialisationManagerColumn,
          width: 250,
        },
        {
          ...specialisationHeadcountColumn,
          width: 100,
        },
        {
          ...specialisationRequisitionsColumn,
          width: 110,
        },
        {
          ...nipsColumn,
          width: 100,
        },
        settings?.enable_specialisations_approvals
          ? {
              ...specialisationStatusColumn,
              width: 200,
            }
          : null,
        {
          ...specialisationAllSkillsColumn,
          width: 350,
        },
      ].filter(Boolean),
    }
  }, [settings])

  const onToggleMySpecialisations = () => {
    setShowMySpecialisations(!showMySpecialisations)
    table.onFilterChange(getFilterByOwner(!showMySpecialisations))
  }

  const onToggleArchivedSpecialisations = () => {
    setShowArchivedSpecialisations(!showArchivedSpecialisations)
    table.onFilterChange(getFilterByStatus(!showArchivedSpecialisations))
  }

  return (
    <Widget display="flex" p="s-16" width="100%">
      <Flex flexDirection="column" width="100%">
        <Flex flexWrap="wrap" justifyContent="space-between">
          <Flex mb="s-24">
            <Stat
              label="Total"
              val={table?.loading ? undefined : table?.count}
              mr="s-32"
            />
          </Flex>
          <SearchTable
            placeholder="Search by specialisation and function names"
            mb="s-24"
            onFilter={table.onFilterChange}
          />
        </Flex>
        <Flex mb="s-16" justifyContent="space-between">
          <Box>
            <MoreBar>
              {canAdd && (
                <MoreBar.Action
                  use={InternalLink}
                  to={pathToUrl(ROUTES.FORMS.SPECIALISATIONS.GENERAL)}
                  useIcon={Plus}
                >
                  Add Specialisation
                </MoreBar.Action>
              )}
              <SettingsButton
                path={ROUTES.SETTINGS.ROLES.GENERAL}
                canView={settingsConfig.roles.canView}
              />
            </MoreBar>
          </Box>
          <Bar>
            <FilterButton
              onClick={onToggleMySpecialisations}
              active={showMySpecialisations}
            >
              My specialisations
            </FilterButton>
            <FilterButton
              onClick={onToggleArchivedSpecialisations}
              active={showArchivedSpecialisations}
            >
              Archived specialisations
            </FilterButton>
            <FavouritesFilter table={table} />
          </Bar>
        </Flex>
        <Flex style={{ position: 'relative' }} flex="1 0">
          <AdjustableTable<SpecialisationInterface>
            name={TableNames.Specialisations}
            useWindowScroll
            dataType="Specialisation"
            row={row}
            {...table}
            noDataMessage="Specialisations will appear here."
          />
        </Flex>
      </Flex>
    </Widget>
  )
}

export default withFavourites(SpecialisationsTable)
