import React from 'react'
import { Text } from '@revolut/ui-kit'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { useLapeContext } from '@src/features/Form/LapeForm'
import {
  CommitteeResultInterface,
  ProbationResults,
} from '@src/interfaces/probationReview'
import { ConfirmationDialogInterface } from '@src/features/Popups/ConfirmationDialog'

type Props = {
  backUrl: string
  lineManager: string
}

const SaveButton = ({ backUrl, lineManager }: Props) => {
  const { values } = useLapeContext<CommitteeResultInterface>()

  const getConfirmationDialog = (): Partial<ConfirmationDialogInterface> | undefined => {
    switch (values.result) {
      case ProbationResults.Failed: {
        return {
          label: 'Fail PIP?',
          body: (
            <>
              <Text use="p" variant="caption" fontWeight={500} mb="s-16">
                This will terminate the employee.
              </Text>
              <Text use="p" variant="caption" color="grey-tone-50">
                By clicking on Submit, you are confirming that you have talked with the
                employee’s line manager: {lineManager} and understand the impact of this
                process
              </Text>
            </>
          ),
          yesMessage: 'Proceed',
          yesBtnVariant: 'negative',
          noMessage: 'Cancel',
        }
      }

      default:
        return undefined
    }
  }

  return (
    <NewSaveButtonWithPopup
      afterSubmitUrl={backUrl}
      confirmationDialogue={getConfirmationDialog()}
      data-testid="submit-btn"
      successText="Committee result for PIP Review successfully updated."
      useValidator
    />
  )
}

export default SaveButton
