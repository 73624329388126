import React from 'react'
import capitalize from 'lodash/capitalize'
import {
  Copyable,
  Flex,
  Group,
  Portal,
  Separator,
  Text,
  Token,
  VStack,
  Header,
} from '@revolut/ui-kit'
import { Services } from '@revolut/icons'

import SideBar from '@components/SideBar/SideBar'
import { DataHandlerInterface, FormPreview } from '@components/FormPreview/FormPreview'
import {
  ContractorInterface,
  EmployeeInterface,
  InternalOrExternalEmployee,
  EMPLOYEE_PROFILE_SUB_SECTIONS,
} from '@src/interfaces/employees'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import { Status } from '@components/CommonSC/General'
import { useGetEmployeeSettings } from '@src/api/settings'
import { DynamicGroupIDs, SectionOptions } from '@src/interfaces/customFields'
import {
  ChangeRequestsPendingWidget,
  EmployeeUserWithAvatar,
} from '@src/pages/EmployeeProfile/Preview/ProfileSummary/common'
import { ProfileSectionsData } from '@src/pages/EmployeeProfile/Preview/hooks'
import { useOrganisationPendingChanges } from './hooks'
import { onCopyFieldNotification } from '@src/pages/EmployeeProfile/Layout/common/helpers'
import UserWithAvatar from '@components/UserWithAvatar/UserWithAvatar'
import { useGetPerformanceSettings } from '@src/api/performanceSettings'
import EditName from '@src/pages/EmployeeProfile/Forms/Name'
import EditPosition from '@src/pages/EmployeeProfile/Forms/Position'
import EditOrganisation from '@src/pages/EmployeeProfile/Forms/Organisation'
import EditNotes from '@src/pages/EmployeeProfile/Forms/Notes'
import {
  isLoadingSectionData,
  SidebarEditFormWrapper,
  SidebarEditMode,
  useActiveProfileSidebar,
} from '../common'

type Props = {
  data?: InternalOrExternalEmployee
  primaryDataHandler: DataHandlerInterface<EmployeeInterface>
  onClose: () => void
  dynamicGroups: DynamicGroupIDs
  sectionsData: ProfileSectionsData
}
export const WorkDetailsSidebar = ({
  data = {} as EmployeeInterface,
  primaryDataHandler,
  onClose,
  dynamicGroups,
  sectionsData,
}: Props) => {
  const { sidebar, editMode, setSidebarQueryParam } = useActiveProfileSidebar()

  const setEditMode = (mode: SidebarEditMode | undefined) =>
    setSidebarQueryParam('workDetails', true, mode)

  const employeeId = data.id
  const isInternal = data?.employee_type === 'internal'

  const { data: employeeSettings } = useGetEmployeeSettings()
  const { data: performanceSettings } = useGetPerformanceSettings()

  const nameSectionLoading = isLoadingSectionData(sectionsData.name.handler)
  const organisationSectionLoading = isLoadingSectionData(
    sectionsData.organisation.handler,
  )
  const positionSectionLoading = isLoadingSectionData(sectionsData.position.handler)
  const notesSectionLoading = isLoadingSectionData(sectionsData.notes.handler)

  const hasPendingOrganisationChanges = useOrganisationPendingChanges(
    employeeId,
    !!sectionsData.organisation.permissions?.canEdit,
    !!sectionsData.organisation.permissions?.canRequestChange,
  )

  return (
    <Portal>
      <SideBar
        title="Work details"
        subtitle={<UserWithAvatar id={data.id} full_name={data?.display_name} />}
        variant="wide"
        isOpen={sidebar === 'workDetails'}
        onClose={onClose}
        sideProps={{ resizable: true }}
        useIcon={Services}
        avatarProps={{ color: Token.color.greyTone20 }}
        customHeader={
          editMode ? (
            <Header>
              <Header.BackButton onClick={() => setEditMode(undefined)} />
              <Header.Title data-testid="sidebar-title">
                Edit {`${editMode}`}
              </Header.Title>
              <Header.Description>
                <UserWithAvatar id={data.id} full_name={data?.display_name} />
              </Header.Description>
            </Header>
          ) : undefined
        }
        zIndex={0}
      >
        {editMode === 'name' && (
          <SidebarEditFormWrapper
            to={pathToUrl(ROUTES.FORMS.EMPLOYEE.GENERAL.NAME, { id: employeeId })}
          >
            <EditName
              isSidebarContent
              data={data as EmployeeInterface}
              refreshData={() => {
                primaryDataHandler.refetch()
                sectionsData.name.handler.refetch()
              }}
              dynamicGroups={dynamicGroups}
            />
          </SidebarEditFormWrapper>
        )}
        {editMode === 'position' && (
          <SidebarEditFormWrapper
            to={pathToUrl(ROUTES.FORMS.EMPLOYEE.GENERAL.POSITION, { id: employeeId })}
          >
            <EditPosition
              isSidebarContent
              data={data as EmployeeInterface}
              refreshData={() => {
                primaryDataHandler.refetch()
                sectionsData.position.handler.refetch()
              }}
              dynamicGroups={dynamicGroups}
            />
          </SidebarEditFormWrapper>
        )}
        {editMode === 'organisation' && (
          <SidebarEditFormWrapper
            to={pathToUrl(ROUTES.FORMS.EMPLOYEE.GENERAL.ORGANISATION, { id: employeeId })}
          >
            <EditOrganisation
              isSidebarContent
              data={data as EmployeeInterface}
              refreshData={() => {
                primaryDataHandler.refetch()
                sectionsData.organisation.handler.refetch()
              }}
            />
          </SidebarEditFormWrapper>
        )}
        {editMode === 'notes' && (
          <SidebarEditFormWrapper
            to={pathToUrl(ROUTES.FORMS.EMPLOYEE.GENERAL.NOTES, { id: employeeId })}
          >
            <EditNotes
              isSidebarContent
              data={data as EmployeeInterface}
              dynamicGroups={dynamicGroups}
              refreshData={() => {
                sectionsData.notes.handler.refetch()
              }}
            />
          </SidebarEditFormWrapper>
        )}
        {!editMode && (
          <VStack gap="s-32">
            <FormPreview<Partial<InternalOrExternalEmployee>>
              title="Name"
              data={sectionsData.name.data}
              dataHandler={sectionsData.name.handler}
              onEdit={() => setEditMode('name')}
              hideEdit={() => !sectionsData.name.permissions?.canEdit}
            >
              <Group>
                <FormPreview.Item<Partial<InternalOrExternalEmployee>>
                  title="Legal first name"
                  field="first_name"
                  loading={nameSectionLoading}
                />
                <FormPreview.Item<Partial<InternalOrExternalEmployee>>
                  title="Legal middle name"
                  field="middle_name"
                  loading={nameSectionLoading}
                />
                <FormPreview.Item<Partial<InternalOrExternalEmployee>>
                  title="Legal last name"
                  field="last_name"
                  loading={nameSectionLoading}
                />
                <FormPreview.Item<Partial<InternalOrExternalEmployee>>
                  title="Preferred name"
                  field="display_name"
                  loading={nameSectionLoading}
                />
                <FormPreview.Item<Partial<InternalOrExternalEmployee>>
                  title="Work email"
                  field="email"
                  insert={d =>
                    d.email ? (
                      <Copyable
                        value={d.email}
                        labelButtonCopy="Copy employee email"
                        onClick={() => onCopyFieldNotification('Email')}
                      >
                        {d.email}
                      </Copyable>
                    ) : (
                      '-'
                    )
                  }
                  loading={nameSectionLoading}
                />
                <FormPreview.Item
                  hidden={!employeeSettings?.enable_show_employee_id}
                  title="Employee ID"
                  insert={() =>
                    employeeId ? (
                      <Copyable
                        value={String(employeeId)}
                        labelButtonCopy="Copy employee ID"
                        onClick={() => onCopyFieldNotification('Employee ID')}
                      >
                        {employeeId}
                      </Copyable>
                    ) : (
                      '-'
                    )
                  }
                />
                <FormPreview.CustomFields
                  withSeparator
                  dynamicGroups={dynamicGroups}
                  sectionId={SectionOptions.EmployeeProfile}
                  subSectionId={EMPLOYEE_PROFILE_SUB_SECTIONS.BASIC_INFO}
                />
              </Group>
            </FormPreview>

            <FormPreview<Partial<InternalOrExternalEmployee>>
              title="Position"
              data={sectionsData.position.data}
              dataHandler={sectionsData.position.handler}
              onEdit={() => setEditMode('position')}
              hideEdit={() => !sectionsData.position.permissions?.canEdit}
            >
              <Group>
                <FormPreview.Item<Partial<InternalOrExternalEmployee>>
                  title="Contract type"
                  field="contract_type.name"
                  loading={positionSectionLoading}
                />
                <FormPreview.Item<Partial<InternalOrExternalEmployee>>
                  title="Employee type"
                  field="employee_type"
                  insert={d =>
                    isInternal
                      ? capitalize((d as EmployeeInterface).employee_type)
                      : (data as Partial<ContractorInterface>).employee_type?.name
                  }
                  loading={positionSectionLoading}
                />
                <FormPreview.Item<Partial<InternalOrExternalEmployee>>
                  title="Status"
                  field="status"
                  insert={d =>
                    d.status?.id ? (
                      <Status status={data.status.id}>{data.status?.name}</Status>
                    ) : (
                      '-'
                    )
                  }
                  loading={positionSectionLoading}
                />
                <Separator />
                <FormPreview.Item<Partial<InternalOrExternalEmployee>>
                  title="Role (Specialisation)"
                  field="specialisation.name"
                  to={d =>
                    pathToUrl(ROUTES.FORMS.SPECIALISATIONS.PREVIEW, {
                      id: d.specialisation?.id,
                    })
                  }
                  loading={positionSectionLoading}
                />
                <FormPreview.Item<EmployeeInterface>
                  hidden={!isInternal}
                  title="Seniority"
                  insert={d => (
                    <Flex>
                      <Text>{d.seniority?.name || '-'}</Text>
                      {d.specialisation_seniority_sublevel && (
                        <Text pl="s-6">({d.specialisation_seniority_sublevel.name})</Text>
                      )}
                    </Flex>
                  )}
                  loading={positionSectionLoading}
                />
                <FormPreview.Item<Partial<InternalOrExternalEmployee>>
                  title="Job title"
                  field="job_title"
                  hidden={!isInternal || !employeeSettings?.enable_job_title}
                  loading={positionSectionLoading}
                />
                <Separator />
                <FormPreview.Item<Partial<InternalOrExternalEmployee>>
                  title="Location"
                  field="location.name"
                  loading={positionSectionLoading}
                />
                <FormPreview.Item<Partial<InternalOrExternalEmployee>>
                  title="Entity"
                  field="entity.name"
                  loading={positionSectionLoading}
                />
                <FormPreview.Item<Partial<InternalOrExternalEmployee>>
                  title="Start date"
                  field="joining_date_time"
                  type="date"
                  loading={positionSectionLoading}
                />
                <FormPreview.Item<Partial<InternalOrExternalEmployee>>
                  hidden={!performanceSettings?.enable_probation}
                  title="Probation end date"
                  field="end_of_probation_date_time"
                  type="date"
                  loading={positionSectionLoading}
                />
                <FormPreview.Context<Partial<ContractorInterface>>
                  insert={d => (
                    <FormPreview.Item<ContractorInterface>
                      hidden={isInternal || !('termination_date_time' in d)}
                      title="Termination date"
                      field="termination_date_time"
                      type="date"
                      loading={positionSectionLoading}
                    />
                  )}
                />
                <FormPreview.CustomFields
                  withSeparator
                  dynamicGroups={dynamicGroups}
                  sectionId={SectionOptions.EmployeeProfile}
                  subSectionId={EMPLOYEE_PROFILE_SUB_SECTIONS.WORK}
                />
              </Group>
            </FormPreview>

            <FormPreview<Partial<InternalOrExternalEmployee>>
              title="Organisation"
              data={sectionsData.organisation.data}
              dataHandler={sectionsData.organisation.handler}
              onEdit={() => setEditMode('organisation')}
              hideEdit={() => !sectionsData.organisation.permissions?.canEdit}
            >
              <ChangeRequestsPendingWidget
                id={employeeId}
                isVisible={hasPendingOrganisationChanges}
              />
              <Group>
                <FormPreview.Item<Partial<InternalOrExternalEmployee>>
                  title="Team"
                  field="team.name"
                  to={d => pathToUrl(ROUTES.FORMS.TEAM.SUMMARY, { id: d.team?.id })}
                  loading={organisationSectionLoading}
                />
                <FormPreview.Item<Partial<InternalOrExternalEmployee>>
                  title={isInternal ? 'Line manager' : 'Point of contact 1'}
                  field="line_manager.name"
                  insert={d =>
                    d.line_manager?.id ? (
                      <EmployeeUserWithAvatar
                        id={d.line_manager.id}
                        full_name={d.line_manager.display_name}
                      />
                    ) : (
                      '-'
                    )
                  }
                  loading={organisationSectionLoading}
                />
                <FormPreview.Item<Partial<InternalOrExternalEmployee>>
                  title={isInternal ? 'Functional manager' : 'Point of contact 2'}
                  field="quality_control.name"
                  insert={d =>
                    d.quality_control?.id ? (
                      <EmployeeUserWithAvatar
                        id={d.quality_control.id}
                        full_name={d.quality_control.display_name}
                      />
                    ) : (
                      '-'
                    )
                  }
                  loading={organisationSectionLoading}
                />
                <FormPreview.Item<EmployeeInterface>
                  hidden={!isInternal || !employeeSettings?.enable_buddy}
                  title="Buddy"
                  field="mentor.name"
                  insert={d =>
                    d.mentor?.id ? (
                      <EmployeeUserWithAvatar
                        id={d.mentor.id}
                        full_name={d.mentor.display_name}
                      />
                    ) : (
                      '-'
                    )
                  }
                  loading={organisationSectionLoading}
                />
              </Group>
            </FormPreview>

            {!!employeeSettings?.enable_notes && (
              <FormPreview<Partial<InternalOrExternalEmployee>>
                title="Notes"
                data={sectionsData.notes.data}
                dataHandler={sectionsData.notes.handler}
                onEdit={() => setEditMode('notes')}
                hideEdit={() => !sectionsData.notes.permissions?.canEdit}
              >
                <Group>
                  <FormPreview.Details<Partial<InternalOrExternalEmployee>>
                    title="HR notes"
                    field="info"
                    loading={notesSectionLoading}
                  />
                  <FormPreview.Item<Partial<InternalOrExternalEmployee>>
                    title="Work authorisation status"
                    field="work_authorisation_status.name"
                    loading={notesSectionLoading}
                  />
                  <FormPreview.Item<Partial<InternalOrExternalEmployee>>
                    title="Relocation type"
                    field="relocation_type.name"
                    loading={notesSectionLoading}
                  />
                  <FormPreview.Item<Partial<InternalOrExternalEmployee>>
                    type="boolean"
                    title="Regulated role"
                    field="is_regulated"
                    loading={notesSectionLoading}
                  />
                  <FormPreview.CustomFields
                    withSeparator
                    dynamicGroups={dynamicGroups}
                    sectionId={SectionOptions.EmployeeProfile}
                    subSectionId={EMPLOYEE_PROFILE_SUB_SECTIONS.NOTES}
                  />
                </Group>
              </FormPreview>
            )}
          </VStack>
        )}
      </SideBar>
    </Portal>
  )
}
