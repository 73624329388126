import React from 'react'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import AdjustableTable from '@components/Table/AdjustableTable'
import { useTable } from '@components/Table/hooks'
import { ReferCandidateInterface } from '@src/interfaces/referCandidate'
import { referCandidateTableRequests } from '@src/api/referCandidate'
import { RowInterface } from '@src/interfaces/data'
import { navigateTo } from '@src/actions/RouterActions'
import {
  referralCandidateCreatedOnColumn,
  referralCandidateInteractedOnColumn,
  referralCandidateNameColumn,
  referralCandidateRecruiterColumn,
  referralCandidateSpecialisationColumn,
  referralCandidateStateColumn,
  referralBonusStatusColumn,
} from '@src/constants/columns/referrals'
import { Box, Flex, Link, List, MoreBar, Widget } from '@revolut/ui-kit'
import ReferCandidateButton from '@components/ReferCandidateButton/ReferCandidateButton'
import { EmployeeInterface } from '@src/interfaces/employees'
import ActionWidget from '@components/ActionWidget/ActionWidget'
import { useSelector } from 'react-redux'
import { selectUser } from '@src/store/auth/selectors'
import { PAYROLL_SERVICE_DESK, REFERRAL_BONUSES } from '@src/constants/externalLinks'
import { TableNames } from '@src/constants/table'
import HideIfCommercial from '@components/HideIfCommercial/HideIfCommercial'
import { InternalLink } from '@src/components/InternalLink/InternalLink'

const row: RowInterface<ReferCandidateInterface> = {
  linkToForm: ({ id }) =>
    navigateTo(pathToUrl(ROUTES.FORMS.REFER_CANDIDATE.PREVIEW, { id })),
  cells: [
    {
      ...referralCandidateNameColumn,
      width: 175,
    },
    {
      ...referralCandidateSpecialisationColumn,
      width: 200,
    },
    {
      ...referralCandidateRecruiterColumn,
      width: 175,
    },
    {
      ...referralCandidateStateColumn,
      width: 100,
    },
    {
      ...referralCandidateCreatedOnColumn,
      width: 125,
    },
    {
      ...referralCandidateInteractedOnColumn,
      width: 125,
    },
    {
      ...referralBonusStatusColumn,
      width: 200,
    },
  ],
}

interface Props {
  data: EmployeeInterface
}

export const Referrals = ({ data }: Props) => {
  const user = useSelector(selectUser)

  const initialFilter = [
    {
      filters: [{ name: String(data.id), id: data.id }],
      columnName: 'employee_id',
      nonResettable: true,
    },
  ]

  const table = useTable<ReferCandidateInterface, undefined>(
    referCandidateTableRequests,
    initialFilter,
  )

  return (
    <>
      <HideIfCommercial>
        <ActionWidget
          title="Important information regarding referrals"
          text={
            <>
              <List
                variant="compact"
                ml="s-16"
                color="grey-tone-50"
                style={{ listStyle: 'lower-latin' }}
              >
                <List.Item color="grey-tone-50">
                  Please review the referral bonus policy{' '}
                  <Link
                    href={REFERRAL_BONUSES}
                    textDecoration="underlined"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    here
                  </Link>
                  . It's important to understand that{' '}
                  <strong>
                    not all referrals, even if tracked, qualify for the bonus.
                  </strong>
                </List.Item>
                <List.Item color="grey-tone-50">
                  Once a candidate you referred is hired and passes their probation
                  period, and if other{' '}
                  <Link
                    href={REFERRAL_BONUSES}
                    textDecoration="underlined"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    policy
                  </Link>{' '}
                  criteria are met, you can view the referral bonus details{' '}
                  <Link
                    to={pathToUrl(ROUTES.FORMS.EMPLOYEE.REWARDS.BONUSES, {
                      id: user.id,
                    })}
                    use={InternalLink}
                    textDecoration="underlined"
                    target="_blank"
                  >
                    here
                  </Link>
                </List.Item>
                <List.Item>
                  If you do not receive your bonus by the specified date, please create a
                  service desk ticket for the payroll team{' '}
                  <Link
                    href={PAYROLL_SERVICE_DESK}
                    textDecoration="underlined"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    here
                  </Link>
                </List.Item>
              </List>
            </>
          }
          mb="s-16"
        />
      </HideIfCommercial>

      <Widget p="s-16" width="100%">
        <Box mb="s-16">
          <MoreBar>
            <ReferCandidateButton />
          </MoreBar>
        </Box>
        <Flex style={{ position: 'relative' }} flex="1 0">
          <AdjustableTable
            name={TableNames.EmployeeReferrals}
            useWindowScroll
            dataType="Referrals"
            row={row}
            {...table}
            noDataMessage="Referrals will appear here."
          />
        </Flex>
      </Widget>
    </>
  )
}
