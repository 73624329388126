import React, { useState } from 'react'
import { TableWidget, useStatusPopup, StatusPopup } from '@revolut/ui-kit'
import { captureException } from '@sentry/react'
import { kpisRequests } from '@src/api/kpis'
import AdjustableTable from '@src/components/Table/AdjustableTable'
import { TableNames } from '@src/constants/table'
import { GoalKpiDetails } from '@src/interfaces/goals'
import { UpdateTypes } from '@src/interfaces/kpis'
import { useConfirmationDialog } from '../../../common/utils'
import {
  goalTargetActionsColumn,
  goalTargetCurrentValueColumn,
  goalTargetInitialValueColumn,
  goalTargetNameColumn,
  goalTargetPerformanceColumn,
  goalTargetReviewCycleColumn,
  goalTargetTargetValueColumn,
  goalTargetUnitColumn,
  goalTargetUpdateTypeColumn,
} from '@src/constants/columns/goals'
import { useTableReturnType } from '@src/components/Table/hooks'

export const TargetsList = ({
  onSelected,
  viewMode,
  table,
}: {
  onSelected?: (kpi: GoalKpiDetails) => void
  table: useTableReturnType<GoalKpiDetails>
  viewMode: boolean
}) => {
  const [pendingDeleteId, setPendingDeleteId] = useState<number>()
  const statusPopup = useStatusPopup()
  const { confirmationDialog, confirm } = useConfirmationDialog({
    variant: 'compact',
  })

  const showError = (title: string) =>
    statusPopup.show(
      <StatusPopup variant="error">
        <StatusPopup.Title>{title}</StatusPopup.Title>
      </StatusPopup>,
    )

  const deleteTarget = async (data: GoalKpiDetails) => {
    try {
      const confirmed = await confirm({
        body: `Are you sure you want to delete ${data.name} target`,
        yesBtnVariant: 'negative',
        yesMessage: 'Delete',
      })

      if (confirmed.status === 'confirmed') {
        setPendingDeleteId(data.id)
        await kpisRequests.deleteItem(data.id)
      }
    } catch (err) {
      captureException(err)
      showError('Failed to delete target')
    } finally {
      setPendingDeleteId(undefined)
    }
  }

  const cells = viewMode
    ? [
        {
          ...goalTargetNameColumn,
          width: 300,
        },
        {
          ...goalTargetReviewCycleColumn,
          width: 100,
        },
        {
          ...goalTargetUpdateTypeColumn,
          width: 100,
        },
        {
          ...goalTargetPerformanceColumn,
          width: 100,
        },
        {
          ...goalTargetInitialValueColumn,
          width: 80,
        },
        {
          ...goalTargetCurrentValueColumn,
          width: 80,
        },
        {
          ...goalTargetTargetValueColumn,
          width: 80,
        },
        {
          ...goalTargetUnitColumn,
          width: 100,
        },
        {
          ...goalTargetActionsColumn({ onSelect: onSelected }),
          width: 50,
        },
      ]
    : [
        {
          ...goalTargetNameColumn,
          width: 300,
        },
        {
          ...goalTargetReviewCycleColumn,
          width: 100,
        },
        {
          ...goalTargetUpdateTypeColumn,
          width: 150,
        },
        {
          ...goalTargetInitialValueColumn,
          width: 80,
        },
        {
          ...goalTargetCurrentValueColumn,
          width: 80,
        },
        {
          ...goalTargetTargetValueColumn,
          width: 80,
        },
        {
          ...goalTargetUnitColumn,
          width: 100,
        },
        {
          ...goalTargetActionsColumn({
            onSelect: onSelected,
            onDelete: deleteTarget,
            pendingDeleteId,
          }),
          width: 80,
        },
      ]

  const onlyRoadmapTargets = table.data?.every(
    kpi => kpi.update_type === UpdateTypes.roadmap,
  )

  return (
    <TableWidget style={{ padding: 0 }}>
      <TableWidget.Table>
        <AdjustableTable<GoalKpiDetails & { actions_column?: never }>
          rowHeight="medium"
          hideCountAndButtonSection
          childrenOpenByDefault
          expandableType="chevron"
          name={TableNames.KPIs}
          dataType="Target"
          row={{ cells, noChildrenRequest: true }}
          hiddenCells={{
            unit: onlyRoadmapTargets,
            initial_value: onlyRoadmapTargets,
            target: onlyRoadmapTargets,
          }}
          {...table}
        />
      </TableWidget.Table>
      {confirmationDialog}
    </TableWidget>
  )
}
