import React from 'react'
import { useParams } from 'react-router-dom'
import { VStack } from '@revolut/ui-kit'

import { navigateReplace } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { activateWorkScheduleCustomApprovers } from '@src/api/workSchedule'
import SectionTitle from '@src/pages/OnboardingChecklist/components/SectionTitle'
import { PageActions } from '@src/components/Page/PageActions'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { CustomApproversEligibilityTable } from '../components/EligibilityTable'

export const WorkScheduleCustomApproversReview = () => {
  const params = useParams<{ id: string }>()

  return (
    <>
      <VStack>
        <SectionTitle title="Review who your custom approvers group will impact" />
        <CustomApproversEligibilityTable isReviewTab />
      </VStack>
      <PageActions pt="s-64">
        <NewSaveButtonWithPopup
          successText="Custom approvers flow successfully created"
          hideWhenNoChanges={false}
          onClick={async () => {
            await activateWorkScheduleCustomApprovers(params.id)
            navigateReplace(
              pathToUrl(
                ROUTES.APPS.TIME_MANAGEMENT.WORK_SCHEDULES_CUSTOM_APPROVERS.PREVIEW,
                { id: params.id },
              ),
            )
          }}
        >
          Create & apply
        </NewSaveButtonWithPopup>
      </PageActions>
    </>
  )
}
