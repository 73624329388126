import React, { useEffect, useState } from 'react'

import { chain, TextSkeleton } from '@revolut/ui-kit'
import SideBar from '@components/SideBar/SideBar'
import {
  EngagementResultsEntities,
  EngagementResultsScope,
} from '@src/interfaces/engagement'
import { engagementDriverFormRequests } from '@src/api/engagement'
import { useQuery } from '@src/utils/queryParamsHooks'
import { CommentsSidebarContent } from '@src/apps/People/Engagement/Results/components/Comments/CommentsSidebarContent'
import { QueryParams } from '../../constants'

type Props = {
  surveyId: number
  scope?: EngagementResultsScope
  scopeId: number
  driverId?: string
  questionId?: string
  resultsEntity: EngagementResultsEntities
}
export const CommentsSidebar = ({
  surveyId,
  scope,
  scopeId,
  driverId,
  questionId,
  resultsEntity,
}: Props) => {
  const { query, deleteQueryParam } = useQuery()

  const [isOpen, setIsOpen] = useState(false)
  const [driverName, setDriverName] = useState<string | undefined>()
  const [commentsNum, setCommentsNum] = useState<number>()

  useEffect(() => {
    if (driverId || questionId) {
      setIsOpen(true)
    }
    if (resultsEntity === 'drivers' && driverId) {
      engagementDriverFormRequests.get({ id: driverId }).then(({ data: driver }) => {
        setDriverName(driver.name)
      })
    }
  }, [query])

  const handleClose = () => {
    setIsOpen(false)
    deleteQueryParam(QueryParams.driverComments)
    deleteQueryParam(QueryParams.questionComments)
  }

  return (
    <SideBar
      variant="wide"
      title={
        commentsNum !== undefined ? (
          chain('Answers', commentsNum)
        ) : (
          <TextSkeleton size={8} />
        )
      }
      subtitle={driverName ? `Driver: ${driverName}` : ''}
      onClose={handleClose}
      isOpen={isOpen}
    >
      <CommentsSidebarContent
        surveyId={surveyId}
        scope={scope}
        scopeId={scopeId}
        driverId={driverId}
        questionId={questionId}
        resultsEntity={resultsEntity}
        onLoaded={total => setCommentsNum(total)}
      />
    </SideBar>
  )
}
