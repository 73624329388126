import { CellTypes, ColumnInterface, FilterType } from '@src/interfaces/data'
import { selectorKeys } from '../api'
import { Priority, RequisitionInterface } from '@src/interfaces/requisitions'
import React from 'react'
import { Text, Token } from '@revolut/ui-kit'
import StartDate from '@components/ColumnInserts/StartDate/StartDate'
import { TooltipContainer } from '@components/CommonSC/Tooltip'
import capitalize from 'lodash/capitalize'
import { getStatusColor } from '@components/CommonSC/General'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import Pipeline from '@src/features/Pipeline/Pipeline'
import ChartInsideTooltip from '@components/Charts/ChartInsideTooltip/ChartInsideTooltip'
import { getRequisitionsSelectorOptions } from '@src/api/requisitions'

export const requisitionStatusColumn: ColumnInterface<RequisitionInterface> = {
  type: CellTypes.text,
  idPoint: 'status',
  dataPoint: 'status_display',
  sortKey: 'status',
  filterKey: 'status',
  selectorsKey: selectorKeys.requisitions_status,
  colors: data => getStatusColor(data.status),
  title: 'Status',
}

export const requisitionIdColumn: ColumnInterface<RequisitionInterface> = {
  type: CellTypes.text,
  idPoint: 'id',
  dataPoint: 'id',
  sortKey: 'id',
  filterKey: 'id',
  filterType: FilterType.number,
  selectorsKey: selectorKeys.none,
  title: 'ID',
}

export const createRequisitionTitleColumn = (
  isConfidential: boolean,
): ColumnInterface<RequisitionInterface> => ({
  type: CellTypes.text,
  idPoint: 'requisition_title',
  dataPoint: 'requisition_title',
  sortKey: 'requisition_title',
  filterKey: 'id',
  dynamicHyperlinks: data => pathToUrl(ROUTES.FORMS.REQUISITION.ROLE, { id: data.id }),
  selectorsKey: () => getRequisitionsSelectorOptions(isConfidential),
  title: 'Title',
})

export const requisitionPriorityColumn: ColumnInterface<RequisitionInterface> = {
  type: CellTypes.insert,
  idPoint: 'priority',
  dataPoint: 'priority',
  sortKey: 'priority',
  filterKey: 'priority',
  selectorsKey: selectorKeys.requisitions_priority,
  colors: (data: RequisitionInterface) => {
    if (data.priority === Priority.High || data.priority === Priority.Company) {
      return Token.color.red
    }

    return Token.color.foreground
  },
  title: 'Priority',
  insert: ({ data }) => (
    <Text fontWeight={data.priority === Priority.Company ? 500 : 300}>
      {capitalize(data.priority)}
    </Text>
  ),
}

export const requisitionHeadcountColumn: ColumnInterface<RequisitionInterface> = {
  type: CellTypes.text,
  idPoint: 'headcount',
  dataPoint: 'remaining_vs_total_headcount',
  sortKey: 'headcount',
  filterType: FilterType.range,
  filterKey: 'remaining_headcount',
  selectorsKey: selectorKeys.none,
  colors: (data: RequisitionInterface) =>
    data.headcount ? Token.color.foreground : Token.color.greyTone20,
  textAlign: 'right',
  title: 'Remaining headcount',
}

export const requisitionBackfillColumn: ColumnInterface<RequisitionInterface> = {
  type: CellTypes.insert,
  idPoint: 'backfill',
  dataPoint: 'backfill',
  sortKey: 'backfill',
  filterKey: 'backfill',
  filterType: FilterType.boolean,
  selectorsKey: () =>
    Promise.resolve({
      options: [
        {
          id: 'False',
          name: 'New hire',
        },
        {
          id: 'True',
          name: 'Backfill (Replacement)',
        },
      ],
    }),
  insert: ({ data }) => (data.backfill ? 'Backfill (Replacement)' : 'New hire'),
  title: 'Type',
}

export const requisitionPotentialStartDateColumn: ColumnInterface<RequisitionInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'estimated_fill_date',
    dataPoint: 'estimated_fill_date',
    sortKey: 'estimated_fill_date',
    filterKey: 'estimated_fill_date',
    filterType: FilterType.date,
    selectorsKey: selectorKeys.none,
    title: 'Est TTH',
    headerTooltip: <TooltipContainer>Estimated time to hire</TooltipContainer>,
    insert: ({ data }) => (
      <StartDate
        estimatedStartDate={data?.estimated_fill_date}
        idealStartDate={data?.potential_start_date}
      />
    ),
  }

export const requisitionQueuePosition: ColumnInterface<RequisitionInterface> = {
  type: CellTypes.insert,
  idPoint: 'pipeline_queue_position',
  dataPoint: 'pipeline_queue_position',
  sortKey: 'pipeline_queue_position',
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Q position',
  insert: ({ data }) =>
    data.pipeline_queue_position
      ? `${data.pipeline_queue_position}/${data.pipeline_queue_total}`
      : '-',
}

export const requisitionCandidatesColumn: ColumnInterface<RequisitionInterface> = {
  type: CellTypes.insert,
  idPoint: 'candidates',
  dataPoint: 'candidates',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Pipeline',
  insert: ({ data }) =>
    data.specialisation?.id ? (
      <ChartInsideTooltip>
        <Pipeline
          requisitionId={data.id}
          specialisationId={+data.specialisation.id}
          headcountBefore={data.headcount_before}
          tthDate={data.estimated_fill_date}
        />
      </ChartInsideTooltip>
    ) : (
      ''
    ),
}
