import { useGetSelectors } from '@src/api/selectors'
import LapeNewRadioButtons from '@src/components/Inputs/LapeFields/LapeNewRadioButtons'
import { selectorKeys } from '@src/constants/api'
import { CYCLE_DURATION } from '@src/interfaces/settings'
import { RecommendedLabel } from '@src/pages/OnboardingChecklist/components/Recommended'
import React, { useMemo } from 'react'

export const CycleSettings = () => {
  const { data: durations } = useGetSelectors(selectorKeys.cycle_durations)
  const options = useMemo(() => {
    if (durations && durations.length) {
      const descriptionsById = {
        quarterly:
          'Conducted every three months, these reviews offer a more frequent opportunity for feedback and goal adjustments',
        biannually:
          'Held twice a year, these reviews provide a midpoint check-in to assess progress and set goals for the next period',
        annually:
          "Traditional annual reviews are conducted once a year. They offer a comprehensive overview of an employee's performance over the past year",
      }
      return durations.map(option => ({
        value: option,
        description: descriptionsById[option.id as CYCLE_DURATION],
        label:
          option.id === 'quarterly' ? (
            <RecommendedLabel label={option.name} />
          ) : (
            option.name
          ),
      }))
    }
    return []
  }, [durations])

  return (
    <>
      <LapeNewRadioButtons
        variant="group-items"
        highlightSelected
        name="cycle_duration"
        options={options}
      />
    </>
  )
}
