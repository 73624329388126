import React from 'react'

import { ErrorWidget, VStack } from '@revolut/ui-kit'

export const ResultsTableAnonymised = () => (
  <VStack space="s-16">
    <ErrorWidget>
      <ErrorWidget.Image src="https://assets.revolut.com/assets/3d-images/3D173a.png" />
      <ErrorWidget.Title>Scores are hidden to protect anonymity</ErrorWidget.Title>
      <ErrorWidget.Description>
        We can’t reveal results as fewer than 3 employees answered the survey.
      </ErrorWidget.Description>
    </ErrorWidget>
  </VStack>
)
