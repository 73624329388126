import React from 'react'
import { ROUTES } from '@src/constants/routes'
import { EngagementResults } from '@src/apps/People/Engagement/Results'
import { EngagementResultsScope } from '@src/interfaces/engagement'
import { SpecialisationInterface } from '@src/interfaces/roles'

export const Engagement = ({ data }: { data: SpecialisationInterface }) => {
  return (
    <EngagementResults
      scope={EngagementResultsScope.Specialisations}
      driversPath={ROUTES.FORMS.SPECIALISATIONS.ENGAGEMENT.DRIVERS}
      questionsPath={ROUTES.FORMS.SPECIALISATIONS.ENGAGEMENT.QUESTIONS}
      feedbackPath={ROUTES.FORMS.SPECIALISATIONS.ENGAGEMENT.FEEDBACK}
      data={data}
    />
  )
}
