import React from 'react'
import { ReviewCycleStatus } from '@src/interfaces/reviewCycles'
import { Color, Flex, Text } from '@revolut/ui-kit'
import {
  getReviewCycleStatusColor,
  reviewCycleStatusNames,
} from '@src/utils/reviewCycles'

type Props = {
  name: string
  status?: ReviewCycleStatus
}

const CycleWithStatus = ({ name, status }: Props) => {
  const statusColor = status
    ? getReviewCycleStatusColor(status, { showClosedAsGrey: true })
    : Color.GREY_TONE_50

  return (
    <Flex>
      <Text fontWeight={500}>{name}</Text>
      <Text fontWeight={500} ml="1em" color={statusColor}>
        {status && reviewCycleStatusNames[status]}
      </Text>
    </Flex>
  )
}

export default CycleWithStatus
