import React from 'react'
import { Box, Token } from '@revolut/ui-kit'
import { css } from 'styled-components'

const boxWithHighlightOnHover = css`
  :hover {
    background-color: ${Token.color.greyTone10};
  }
  transition: ${Token.transition.background};

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
`

interface MainHeaderButtonProps {
  children: React.ReactNode
}

export const MainHeaderButton = ({ children }: MainHeaderButtonProps) => {
  return (
    <Box css={boxWithHighlightOnHover} size={36} radius="widget">
      {children}
    </Box>
  )
}
