import React from 'react'
import GenericNotificationTable from '@src/pages/Forms/Notifications/GenericNotificationTable'
import { RowInterface, TabComponentProps } from '@src/interfaces/data'
import { NotificationsInterface } from '@src/interfaces/notifications'
import {
  notificationsActionColumn,
  notificationsDatabaseType,
  notificationsDeadline,
  notificationsDocumentTitle,
  notificationsEmployeedColumn,
  notificationsPermissionType,
  notificationsPriorityColumn,
  notificationsReceivedColumn,
} from '@src/constants/columns/notifications'
import { notificationsDataAccessRequests } from '@src/api/notifications'

const DataAccess = ({
  refreshStats,
  type,
  employeeId,
  filterBy,
  tableRef,
}: TabComponentProps) => {
  const isDashboard = type === 'dashboard'

  const RowNotifications: RowInterface<NotificationsInterface> = {
    cells: [
      {
        ...notificationsPermissionType,
        width: 100,
      },
      {
        ...notificationsDatabaseType,
        width: 140,
      },
      {
        ...notificationsDocumentTitle,
        title: 'Access type',
        filterKey: null,
        width: 200,
      },
      {
        ...notificationsEmployeedColumn(isDashboard),
        width: isDashboard ? 100 : 200,
      },
      {
        ...notificationsReceivedColumn,
        width: 120,
      },
      {
        ...notificationsDeadline,
        width: 110,
      },
      {
        ...notificationsPriorityColumn,
        width: 100,
      },
      {
        ...notificationsActionColumn,
        width: 200,
      },
    ],
  }

  return (
    <GenericNotificationTable
      rows={RowNotifications}
      request={notificationsDataAccessRequests(employeeId)}
      refreshStats={refreshStats}
      type={type}
      filterBy={filterBy}
      tableRef={tableRef}
    />
  )
}

export default DataAccess
