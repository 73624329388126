import React from 'react'
import { LOCAL_STORAGE, selectorKeys } from '@src/constants/api'
import { Flex, InputGroup, Text, Widget } from '@revolut/ui-kit'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { HiringProcessInterface } from '@src/interfaces/hiringProccess'
import LapeNewMultiSelect from '@components/Inputs/LapeFields/LapeNewMultiSelect'
import LapeNewRadioButtons from '@components/Inputs/LapeFields/LapeNewRadioButtons'
import { RadioOption } from '@components/Inputs/NewRadioButtons/NewRadioButtons'
import { createNewKey } from '@src/components/Inputs/RadioSelectInput/RadioSelectInput'
import { OptionInterface } from '@src/interfaces/selectors'
import { ROUTES } from '@src/constants/routes'
import { useCreateOptionInNewTab } from '@src/utils/useCreateOptionInNewTab'

export const TypeOfInterview: RadioOption[] = [
  { value: 'employees', label: 'Employees' },
  { value: 'groups', label: 'Groups' },
]

type EligibleInterviewersProps = {
  isDisabled?: boolean
  isRequired: boolean
}

const EligibleInterviewers = ({ isDisabled, isRequired }: EligibleInterviewersProps) => {
  const { values, errors } = useLapeContext<HiringProcessInterface>()
  const { openNewTab } = useCreateOptionInNewTab(LOCAL_STORAGE.GROUP_CREATED, {
    ref: 'fill-interviewer-groups-after-submit',
    route: ROUTES.FORMS.DYNAMIC_GROUPS.GENERAL,
    selector: selectorKeys.dynamic_groups,
    onLoad: option => {
      if (option) {
        values.interviewers_groups = [...(values.interviewers_groups || []), option]
      }
    },
  })
  if (!values.interviewer_type) {
    values.interviewer_type = {
      id: 'groups',
      name: 'Groups',
    }
    values.interviewers_groups = []
  }
  return (
    <InputGroup>
      <Widget p="s-16">
        <Text use="p" fontSize="primary" pb="s-6">
          Select type of interviewers
        </Text>
        <Flex justifyContent="space-between" width="40%" height={42} my="s-16">
          <LapeNewRadioButtons
            disabled={isDisabled}
            name="interviewer_type"
            options={TypeOfInterview}
            labelProps={{ pr: '26px' }}
            useMappingAsValue
            onAfterChange={() => {
              if (
                values?.interviewer_type?.id === 'employees' &&
                values?.interviewers_groups?.length
              ) {
                values.interviewers_groups = []
              }
              if (
                values?.interviewer_type?.id === 'groups' &&
                values?.interviewers?.length
              ) {
                values.interviewers = []
              }
              delete errors.interviewers
            }}
          />
        </Flex>
      </Widget>
      {values?.interviewer_type?.id === 'groups' ? (
        <>
          {/* backend returns errors in interviewers property regardless of
           ** interviewer_type property so this hidden div is just so lape doesn't
           ** display a error notification and renders the error in the field bellow it
           */}
          <div style={{ display: 'none' }} data-name="interviewers" />
          <LapeNewMultiSelect<OptionInterface>
            disabled={isDisabled}
            name="interviewers_groups"
            placeholder="Select groups"
            selector={selectorKeys.dynamic_groups}
            required={isRequired}
            hasError={!!errors.interviewers}
            message={errors.interviewers}
            showCreateNewButton
            useQuery
            useQueryOptions={{
              refetchOnWindowFocus: true,
            }}
            onChange={options => {
              if (options) {
                if (options.find(({ value }) => value.id === createNewKey)) {
                  openNewTab()
                } else {
                  values.interviewers_groups = options.map(({ value }) => value)
                }
              }
            }}
            onAfterChange={() => {
              // same reason above we render a hidden div, in here we remove the errors
              // from interviewers so error disappears from ui when user adds a group
              delete errors.interviewers
            }}
          />
        </>
      ) : (
        <LapeNewMultiSelect<HiringProcessInterface>
          required={isRequired}
          placeholder="Select interviewer"
          name="interviewers"
          selector={selectorKeys.employee}
          disabled={isDisabled}
        />
      )}
    </InputGroup>
  )
}

export default EligibleInterviewers
