import React from 'react'
import { FilterByInterface, SORT_DIRECTION } from '@src/interfaces/data'
import { initialRequisitionStatusFilter } from '@src/interfaces/requisitions'
import { DepartmentInterface } from '@src/interfaces/deparment'
import { CommonRequisitionTableWidget } from '@src/features/CommonRequisitionTable/CommonRequisitionTable'

interface StructureProps {
  data: DepartmentInterface
  navigation?: React.ReactElement
}

export const getRequisitionsFilter = (data: DepartmentInterface): FilterByInterface[] => [
  {
    filters: [{ name: data.name, id: data.id }],
    columnName: 'team__department__id',
    nonResettable: true,
  },
  {
    filters: initialRequisitionStatusFilter,
    columnName: 'status',
    nonResettable: true,
  },
]

const Requisitions = ({ data, navigation }: StructureProps) => {
  const filterBy = getRequisitionsFilter(data)
  const sortBy = [
    {
      sortBy: 'pipeline_queue_position',
      direction: SORT_DIRECTION.DESC,
    },
    {
      sortBy: 'seniority__level',
      direction: SORT_DIRECTION.ASC,
    },
    {
      sortBy: 'priority',
      direction: SORT_DIRECTION.DESC,
    },
    {
      sortBy: 'team__name',
      direction: SORT_DIRECTION.DESC,
    },
  ]
  const statsData = {
    filters: [
      {
        filters: [{ name: data.name, id: data.id }],
        columnName: 'team__department__id',
      },
    ],
  }
  return (
    <CommonRequisitionTableWidget
      filterBy={filterBy}
      sortBy={sortBy}
      statsData={statsData}
      type="department"
      navigation={navigation}
    />
  )
}

export default Requisitions
