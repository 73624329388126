import React from 'react'
import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import { selectorKeys } from '../api'
import { EngagementQuestionInterface } from '@src/interfaces/engagement'
import UserWithAvatar from '@components/UserWithAvatar/UserWithAvatar'
import { mapStatusToColor } from '@src/apps/People/Engagement/helpers'

export const engagementQuestionText: ColumnInterface<EngagementQuestionInterface> = {
  type: CellTypes.text,
  idPoint: 'id',
  dataPoint: 'question_text',
  sortKey: 'question_text',
  filterKey: 'id',
  selectorsKey: selectorKeys.engagement_question_texts,
  title: 'Question',
}

export const engagementQuestionDriver: ColumnInterface<EngagementQuestionInterface> = {
  type: CellTypes.text,
  idPoint: 'driver.id',
  dataPoint: 'driver.name',
  sortKey: 'driver__name',
  filterKey: 'driver__id',
  selectorsKey: selectorKeys.engagement_drivers,
  title: 'Driver',
}

export const engagementQuestionType: ColumnInterface<EngagementQuestionInterface> = {
  type: CellTypes.text,
  idPoint: 'type',
  dataPoint: 'type.name',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Type',
}

export const engagementQuestionIsCommentRequired: ColumnInterface<EngagementQuestionInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'comment.id',
    dataPoint: 'comment.name',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Comment required',
    insert: ({ data }) => {
      if (data.comment.id === 'mandatory') {
        return 'Yes'
      }
      return 'No'
    },
  }

export const engagementQuestionUpdatedOn: ColumnInterface<EngagementQuestionInterface> = {
  type: CellTypes.date,
  idPoint: 'updated_date_time',
  dataPoint: 'updated_date_time',
  sortKey: 'updated_date_time',
  filterKey: 'updated_date_time',
  selectorsKey: selectorKeys.none,
  title: 'Updated on',
}

export const engagementQuestionOwner: ColumnInterface<EngagementQuestionInterface> = {
  type: CellTypes.insert,
  idPoint: 'owner.id',
  dataPoint: 'owner.name',
  sortKey: 'owner__full_name',
  filterKey: 'owner__id',
  selectorsKey: selectorKeys.employee,
  title: 'Owner',
  insert: ({ data }) => <UserWithAvatar {...data.owner} />,
}

export const engagementQuestionStatus: ColumnInterface<EngagementQuestionInterface> = {
  type: CellTypes.text,
  idPoint: 'status.id',
  dataPoint: 'status.name',
  sortKey: 'status',
  filterKey: 'status',
  colors: mapStatusToColor,
  selectorsKey: selectorKeys.engagement_question_status,
  title: 'Status',
}
