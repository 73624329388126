import React from 'react'
import {
  ActionButton,
  Avatar,
  Button,
  Group,
  Item,
  StatusPopup,
  useStatusPopup,
} from '@revolut/ui-kit'
import { useSelector } from 'react-redux'

import { selectFeatureFlags } from '@src/store/auth/selectors'
import { FeatureFlags } from '@src/store/auth/types'
import { PageWrapper } from '@src/components/Page/Page'
import { PageBody } from '@src/components/Page/PageBody'
import { askGoogleWorkspacePermissions } from '@src/api/officeSuiteProvider'
import { createEmployeeUpload } from '@src/api/bulkDataImport'
import { getStringMessageFromError } from '@src/store/notifications/actions'
import { navigateReplace } from '@src/actions/RouterActions'
import { ROUTES } from '@src/constants/routes'
import { InternalRedirect } from '@src/components/InternalLink/InternalRedirect'
import { OnboardingChecklistHeader } from '../components/OnboardingChecklistHeader'
import { pathToUrl } from '@src/utils/router'

export const SelectFlow = () => {
  const statusPopup = useStatusPopup()

  const featureFlags = useSelector(selectFeatureFlags)

  const googleWorkspaceIntegrationEnabled = featureFlags.includes(
    FeatureFlags.GoogleWorkspaceIntegration,
  )

  if (!window.createEmployeeUploadFromGoogleWorkspace) {
    window.createEmployeeUploadFromGoogleWorkspace = () => {
      createGoogleEmployeeUpload()
    }
  }

  const createGoogleEmployeeUpload = () => {
    statusPopup.show(
      <StatusPopup variant="loading" preventUserClose>
        <StatusPopup.Title>Importing data</StatusPopup.Title>
      </StatusPopup>,
    )

    createEmployeeUpload('google')
      .then(response => {
        statusPopup.hide()
        navigateReplace(
          pathToUrl(ROUTES.ONBOARDING_CHECKLIST_V2.IMPORT_EMPLOYEES.UPLOAD.SESSION, {
            id: response.data.id,
          }),
        )
      })
      .catch(error => {
        statusPopup.show(
          <StatusPopup variant="error">
            <StatusPopup.Title>Failed to import data</StatusPopup.Title>
            <StatusPopup.Description>
              {getStringMessageFromError(error)}
            </StatusPopup.Description>
            <StatusPopup.Actions>
              <Button onClick={statusPopup.hide} variant="secondary" elevated>
                Close
              </Button>
            </StatusPopup.Actions>
          </StatusPopup>,
        )
      })
  }

  const onConnectGoogleWorkspace = () => {
    askGoogleWorkspacePermissions()
  }

  if (!googleWorkspaceIntegrationEnabled) {
    return (
      <InternalRedirect
        to={ROUTES.ONBOARDING_CHECKLIST_V2.IMPORT_EMPLOYEES.UPLOAD.IMPORT}
      />
    )
  }

  return (
    <PageWrapper>
      <OnboardingChecklistHeader
        title="Import employees"
        backUrl={ROUTES.ONBOARDING_CHECKLIST_V2.IMPORT_EMPLOYEES.IMPORT}
      />
      <PageBody>
        <Group>
          <Item
            use="button"
            onClick={() =>
              navigateReplace(
                ROUTES.ONBOARDING_CHECKLIST_V2.IMPORT_EMPLOYEES.UPLOAD.IMPORT,
              )
            }
          >
            <Item.Avatar>
              <Avatar useIcon="Upload" />
            </Item.Avatar>
            <Item.Content>
              <Item.Title>File upload</Item.Title>
              <Item.Description>
                Upload a .xlsx or .csv file to import you employees
              </Item.Description>
            </Item.Content>
          </Item>
          <Item>
            <Item.Avatar>
              <Avatar useIcon="LogoGoogle|image" bg="background" />
            </Item.Avatar>
            <Item.Content>
              <Item.Title>Google Workspace</Item.Title>
              <Item.Description>
                Import employees from your google workspace
              </Item.Description>
            </Item.Content>
            <Item.Side>
              <ActionButton onClick={() => onConnectGoogleWorkspace()}>
                Connect
              </ActionButton>
            </Item.Side>
          </Item>
        </Group>
      </PageBody>
    </PageWrapper>
  )
}
