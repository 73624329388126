import React from 'react'
import { Flex, Spinner } from '@revolut/ui-kit'
import { PageWrapper } from '@components/Page/Page'

const PageLoading = () => {
  return (
    <PageWrapper justifyContent="center">
      <Flex justifyContent="center" alignItems="center" data-testid="loader">
        <Spinner color="blue" size={96} />
      </Flex>
    </PageWrapper>
  )
}

export default PageLoading
