import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import { Color, HStack } from '@revolut/ui-kit'
import { TableActionButton } from '@components/Button/TableActionButton'
import { KeyPersonResponsibilityAssignmentInterface } from '@src/interfaces/keyPerson'
import {
  archiveResponsibilityAssignment,
  unarchiveResponsibilityAssignment,
} from '@src/api/keyPerson'
import { PermissionTypes } from '@src/store/auth/types'
import { selectPermissions } from '@src/store/auth/selectors'

type Props = {
  data: KeyPersonResponsibilityAssignmentInterface
  refresh: () => void
  onEdit: (data: KeyPersonResponsibilityAssignmentInterface) => void
}
export const ActionButtons = ({ data, refresh, onEdit }: Props) => {
  const [isArchiveActionPending, setIsArchiveActionPending] = useState(false)

  const permissions = useSelector(selectPermissions)
  const canEdit = permissions.includes(PermissionTypes.ChangeKeyRiskProfile)
  const showArchive =
    !!data.assignment_status &&
    data.assignment_status?.id !== 'unassigned' &&
    data.assignment_status?.id !== 'archived'
  const showUnarchive = data.assignment_status?.id === 'archived'
  const isAssignedToActiveEmployee =
    data.employee && data.employee?.status !== 'terminated'

  return (
    <HStack space="s-12">
      {canEdit ? (
        <TableActionButton
          use="div"
          onClick={e => {
            e.stopPropagation()
            onEdit(data)
          }}
        >
          {isAssignedToActiveEmployee ? 'Edit' : 'Assign'}
        </TableActionButton>
      ) : null}
      {showArchive && (
        <TableActionButton
          color={Color.RED}
          pending={isArchiveActionPending}
          onClick={async e => {
            e.stopPropagation()
            try {
              setIsArchiveActionPending(true)
              await archiveResponsibilityAssignment(data.id)
              refresh()
            } finally {
              setIsArchiveActionPending(false)
            }
          }}
        >
          Archive
        </TableActionButton>
      )}
      {showUnarchive && (
        <TableActionButton
          pending={isArchiveActionPending}
          onClick={async e => {
            e.stopPropagation()
            try {
              setIsArchiveActionPending(true)
              await unarchiveResponsibilityAssignment(data.id)
              refresh()
            } finally {
              setIsArchiveActionPending(false)
            }
          }}
        >
          Unarchive
        </TableActionButton>
      )}
    </HStack>
  )
}
