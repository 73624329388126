import { ReviewCycleCategory } from '@src/interfaces/reviewCycles'
import { PerformanceSelector } from '@src/interfaces/performance'
import { FilterByInterface } from '@src/interfaces/data'
import { OptionInterface } from '@src/interfaces/selectors'
import { format } from 'date-fns'
import { useMemo } from 'react'
import { KPITypes } from '@src/constants/table'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { isOnboardingPath } from '@src/pages/OnboardingChecklistV2/common/helpers'

export const getCurrentCycle = (selector?: PerformanceSelector[]) => {
  if (!selector) {
    return undefined
  }

  return selector.find(item => item.offset === 0)
}

export const setCurrentCycleFilter = (
  selector: PerformanceSelector[],
  onFilterChange: (filter: FilterByInterface, resetDefaultFilters?: boolean) => void,
) => {
  const initialCycleId = getCurrentCycle(selector)?.id

  if (initialCycleId !== undefined) {
    onFilterChange({
      filters: [{ name: String(initialCycleId), id: String(initialCycleId) }],
      columnName: 'cycle__id',
    })
  }
}

export const useGetCycleSelector = (selector?: PerformanceSelector[]) => {
  const buildCycleSelector = async (): Promise<{
    options: OptionInterface[]
  }> => {
    return {
      options:
        selector?.map(cycle => {
          let name = cycle.name
          if (cycle.category === ReviewCycleCategory.Probation) {
            const startDate = format(new Date(cycle.start_date_time), 'd MMM')
            const endDate = format(new Date(cycle.end_date_time), 'd MMM')

            name = `${cycle.name} · ${startDate} - ${endDate}`
          }

          return {
            id: cycle.id,
            name,
            offset: cycle.offset,
          }
        }) || [],
    }
  }

  return useMemo(() => buildCycleSelector, [selector])
}

export const onKPITableRowClick = (row: { id: number; type: KPITypes }) => {
  if (row.id === -1 || isOnboardingPath()) {
    return
  }

  if (row.type === KPITypes.company_goal || !row.id) {
    row.id
      ? navigateTo(pathToUrl(ROUTES.FORMS.COMPANY_GOALS.PREVIEW, { id: row.id }))
      : navigateTo(pathToUrl(ROUTES.FORMS.COMPANY_GOALS.GENERAL))
  } else {
    navigateTo(pathToUrl(ROUTES.FORMS.KPI.PREVIEW, { id: row.id }))
  }
}
