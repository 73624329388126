import React from 'react'
import { HStack, Fixed, Token, Text, ActionButton, Icon } from '@revolut/ui-kit'
import { differenceInDays } from 'date-fns'
import pluralize from 'pluralize'

import { useCurrentTenantInfo } from '@src/api/tenants'
import { navigateTo } from '@src/actions/RouterActions'
import { ROUTES } from '@src/constants/routes'

export const DemoModeWarningBar = () => {
  const tenantInfo = useCurrentTenantInfo()

  if (!tenantInfo.isDemoMode) {
    return null
  }

  const endOfTrial = tenantInfo.demo_mode_expiration_date_time

  const daysLeftInTrial = endOfTrial
    ? differenceInDays(new Date(endOfTrial), new Date())
    : null

  return (
    <Fixed
      top={0}
      left={0}
      right={0}
      height={44}
      bg={Token.color.accent}
      color={Token.color.white}
      display="flex"
      style={{ alignItems: 'center', justifyContent: 'flex-end' }}
      pr="s-40"
      zIndex={1}
    >
      <HStack space="s-16" align="center">
        <Text>
          {daysLeftInTrial != null ? (
            <HStack align="center" space="s-4">
              <Icon name="TimeOutline" size={14} />
              {daysLeftInTrial > 0 ? (
                <Text>
                  {pluralize('day', daysLeftInTrial, true)} left in testing mode
                </Text>
              ) : (
                <Text>Last day left in testing mode</Text>
              )}
            </HStack>
          ) : (
            'Demo mode is on'
          )}
        </Text>
        <ActionButton
          onClick={() => navigateTo(ROUTES.PLANS.SELECT_PLAN)}
          useIcon="ArrowUpgrade"
          variant="white"
          size="xs"
        >
          Upgrade now
        </ActionButton>
      </HStack>
    </Fixed>
  )
}
