import React, { useState } from 'react'
import { Button, Header, Popup, StatusPopup, useStatusPopup } from '@revolut/ui-kit'
import StagePicker from '@src/pages/Forms/Candidate/InterviewProgress/components/StagePicker'
import { changeStage } from '@src/pages/Forms/Candidate/utils'
import {
  InterviewRoundInterface,
  InterviewStageWithoutRoundInterface,
} from '@src/interfaces/interviewTool'

type Props = {
  open: boolean
  onClose: () => void
  data: InterviewStageWithoutRoundInterface[]
  currentStageId?: number
  roundId: number
  onChange: (round: InterviewRoundInterface) => void
  noSuccessPopup?: boolean
}

export const ChangeStagePopup = ({
  open,
  onClose,
  data,
  currentStageId,
  roundId,
  onChange,
  noSuccessPopup,
}: Props) => {
  const statusPopup = useStatusPopup()
  const [stageId, setStageId] = useState<number>()
  const [pending, setPending] = useState(false)

  const onChangeState = async () => {
    if (!stageId) {
      return
    }

    setPending(true)

    try {
      const round = await changeStage(roundId, stageId)

      if (!noSuccessPopup) {
        statusPopup.show(
          <StatusPopup variant="success">
            <StatusPopup.Title>
              Changed stage to {round.latest_interview_stage?.title}
            </StatusPopup.Title>
          </StatusPopup>,
        )
      }

      onChange(round)
      onClose()
    } finally {
      setPending(false)
    }
  }

  return (
    <Popup open={open} onClose={onClose} variant="bottom-sheet">
      <Header variant="bottom-sheet">
        <Header.Title>Change stage</Header.Title>
      </Header>
      <StagePicker
        data={data}
        onClick={setStageId}
        selectedId={currentStageId}
        currentId={currentStageId}
      />
      <Popup.Actions horizontal>
        <Button variant="secondary" onClick={onClose} autoFocus>
          Cancel
        </Button>
        <Button
          elevated
          disabled={!stageId || pending || currentStageId === stageId}
          onClick={onChangeState}
          pending={pending}
        >
          Submit
        </Button>
      </Popup.Actions>
    </Popup>
  )
}
