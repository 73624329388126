import isNil from 'lodash/isNil'
import { filterSortPageIntoQuery } from '../utils/table'
import { api, apiWithoutHandling } from './index'
import { API } from '../constants/api'
import {
  GetRequestData,
  GetRequestInterface,
  IdAndName,
  RequestInterface,
  RequestInterfaceNew,
  UseFetchResult,
} from '../interfaces'
import {
  ReviewCyclesInterface,
  ReviewCyclesPreviewInterface,
  EligibleGroupInterface,
  ReviewCyclesScorecardSectionInterface,
  TestReviewCycleCurrentStage,
  SandboxInvitation,
} from '../interfaces/reviewCycles'
import { FetchDataQueryInterface } from '@src/interfaces/data'
import { useFetch } from '@src/utils/reactQuery'
import { AxiosPromise } from 'axios'
import { AnytimeFeedbackInterface } from '@src/interfaces/anytimeFeedback'
import { EmployeeOptionInterface } from '@src/interfaces/employees'

export const reviewCyclesRequests: RequestInterface<ReviewCyclesInterface> = {
  getItems: async ({ sortBy, filters, page }) =>
    api.get(`${API.REVIEW_CYCLES}`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    }),
  getItem: async id => api.get(`${API.REVIEW_CYCLES}/${id}`),
  patchItem: async (data, id) => api.patch(`${API.REVIEW_CYCLES}/${id}`, data),
  putItem: async (data, id) => api.put(`${API.REVIEW_CYCLES}/${id}`, data),
  deleteItem: async id => api.delete(`${API.REVIEW_CYCLES}/${id}`),
  postItem: async data => api.post(API.REVIEW_CYCLES, data),
}

export const getReciewCyclesSilently = async ({
  sortBy,
  filters,
  page,
}: FetchDataQueryInterface) =>
  apiWithoutHandling.get<GetRequestInterface<ReviewCyclesInterface>>(
    `${API.REVIEW_CYCLES}`,
    {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    },
  )

export const startReviewCycle = (id: number | string) => {
  return api.get<ReviewCyclesInterface>(`${API.REVIEW_CYCLES}/${id}/start`)
}

export const closeReviewCycle = (id: number | string) => {
  return api.get<ReviewCyclesInterface>(`${API.REVIEW_CYCLES}/${id}/close`)
}

export const reopenReviewCycle = (id: number | string) => {
  return api.get<ReviewCyclesInterface>(`${API.REVIEW_CYCLES}/${id}/reopen`)
}

export const reviewCyclesRequestsNew: RequestInterfaceNew<ReviewCyclesInterface> = {
  get: async ({ id }) => api.get<ReviewCyclesInterface>(`${API.REVIEW_CYCLES}/${id}`),
  update: async (data, { id }) =>
    apiWithoutHandling.put(`${API.REVIEW_CYCLES}/${id}`, data),
  submit: async data =>
    apiWithoutHandling.post(
      `${API.SUPPORT_TOOL}${API.REVIEW_CYCLE_TEMPLATES}`,
      {
        name: data.name,
        cycle_template_id: data.template_id,
      },
      {},
      'v2',
    ),
  delete: async ({ id }) => api.delete(`${API.REVIEW_CYCLES}/${id}`),
}

export const useGetReviewCycle = (id?: string) => {
  return useFetch<ReviewCyclesInterface>(id ? `${API.REVIEW_CYCLES}/${id}` : null)
}

export const useGetReviewCycles = (options?: { disable?: boolean }) => {
  return useFetch<GetRequestInterface<ReviewCyclesInterface>>(
    !options?.disable ? API.REVIEW_CYCLES : null,
  )
}

interface UseGeReviewCycleByOffsetReturnType
  extends Omit<UseFetchResult<GetRequestInterface<ReviewCyclesInterface>>, 'data'> {
  data: ReviewCyclesInterface | undefined
}

export const useGetReviewCycleByOffset = (
  offset: string | number | null,
): UseGeReviewCycleByOffsetReturnType => {
  const { data, ...rest } = useFetch<GetRequestInterface<ReviewCyclesInterface>>(
    !isNil(offset) ? API.REVIEW_CYCLES : null,
    'v1',
    { params: { offset: `${offset},${offset}` } },
  )
  return { data: data?.results?.[0], ...rest }
}

export const useGetReviewCycleGeneralInfo = (id: string) => {
  return useFetch<ReviewCyclesInterface>(
    `${API.SUPPORT_TOOL}${API.REVIEW_CYCLE_GENERAL_INFO}/${id}`,
    'v2',
  )
}

export const useGetReviewCycleTemplates = () => {
  return useFetch<ReviewCyclesPreviewInterface[]>(
    `${API.SUPPORT_TOOL}${API.REVIEW_CYCLE_TEMPLATES}`,
    'v2',
  )
}

export const useGetEligibleGroupsInfo = (id: string) => {
  return useFetch<GetRequestData<EligibleGroupInterface>>({
    url: `${API.SUPPORT_TOOL}${API.ELIGIBLE_GROUPS}`,
    version: 'v2',
    params: { params: { cycle_id: id } },
    queryOptions: {
      staleTime: 1000 * 60 * 5, // 5 minutes
    },
  })
}

export const useGetScorecardSectionsInfo = (id: string) => {
  return useFetch<GetRequestData<ReviewCyclesScorecardSectionInterface>>(
    `${API.SUPPORT_TOOL}${API.SCORECARD_SECTIONS}`,
    'v2',
    {
      params: { cycle_id: id },
    },
  )
}

export const deleteEligibleGroup = (id: number): AxiosPromise<AnytimeFeedbackInterface> =>
  api.delete(`${API.SUPPORT_TOOL}${API.ELIGIBLE_GROUPS}/${id}`, undefined, 'v2')

export const reviewCyclesEligibleEmployees: RequestInterfaceNew<EligibleGroupInterface> =
  {
    get: async ({ id }) =>
      api.get(`${API.SUPPORT_TOOL}${API.ELIGIBLE_GROUPS}/${id}`, undefined, 'v2'),
    update: async (data, { id }) => {
      return apiWithoutHandling.put(
        `${API.SUPPORT_TOOL}${API.ELIGIBLE_GROUPS}/${id}`,
        data,
        undefined,
        'v2',
      )
    },
    submit: async data =>
      apiWithoutHandling.post(
        `${API.SUPPORT_TOOL}${API.ELIGIBLE_GROUPS}`,
        data,
        undefined,
        'v2',
      ),
  }

export const reviewCyclesAdditionalScorecards: RequestInterfaceNew<ReviewCyclesScorecardSectionInterface> =
  {
    get: async ({ id }) =>
      api.get(`${API.SUPPORT_TOOL}${API.SCORECARD_SECTIONS}/${id}`, undefined, 'v2'),
    update: async (data, { id }) => {
      return apiWithoutHandling.put(
        `${API.SUPPORT_TOOL}${API.SCORECARD_SECTIONS}/${id}`,
        data,
        undefined,
        'v2',
      )
    },
    submit: async data => {
      return apiWithoutHandling.post(
        `${API.SUPPORT_TOOL}${API.SCORECARD_SECTIONS}`,
        data,
        undefined,
        'v2',
      )
    },
  }

export const deleteAdditionalScorecardSection = (
  id: number,
): AxiosPromise<ReviewCyclesScorecardSectionInterface> =>
  api.delete(`${API.SUPPORT_TOOL}${API.SCORECARD_SECTIONS}/${id}`, undefined, 'v2')

export const getCurrentTestStage = (): AxiosPromise<TestReviewCycleCurrentStage> =>
  api.get(`${API.TESTING_STAGE}`)

export const changeTestCycleStage = (stage: TestReviewCycleCurrentStage) =>
  api.post(`${API.TESTING_STAGE}`, stage)

export const enableTestCycle = (enabled: boolean): AxiosPromise =>
  api.put(`${API.SANDBOX_MODE}`, { is_enabled: enabled })

export const resetTestCycleData = (): AxiosPromise => api.put(`${API.SANDBOX_MODE_RESET}`)

export const sandboxEmployeeInvitations: RequestInterfaceNew<SandboxInvitation> = {
  get: async () => api.get(API.SANDBOX_INVITATION),
  update: async (data, { id }) =>
    apiWithoutHandling.patch(`${API.SANDBOX_INVITATION}/${id}`, data),
  submit: async data => apiWithoutHandling.post(API.SANDBOX_INVITATION, data),
}

// TODO: withoutHandling is temporary here, remove after BE sorted errors
/** `withoutHandling` param is set to `true` to prevent user from being logged out if it fails with 403 */
export const useGetSandboxInvitedEmployees = () =>
  useFetch<{ results: SandboxInvitation[] }>(
    API.SANDBOX_INVITATION,
    undefined,
    undefined,
    true,
  )

// TODO: withoutHandling is temporary here, remove after BE sorted errors
/** `withoutHandling` param is set to `true` to prevent user from being logged out if it fails with 403 */
export const useGetSandboxEmployees = () =>
  useFetch<{ options: EmployeeOptionInterface[] }>(
    '/sandboxEmployeesSelector',
    undefined,
    undefined,
    true,
  )

// TODO: withoutHandling is temporary here, remove after BE sorted errors
/** `withoutHandling` param is set to `true` to prevent user from being logged out if it fails with 403 */
export const useGetSandboxPermissionGroups = () =>
  useFetch<{ options: IdAndName[] }>('/sandboxGroupSelector', undefined, undefined, true)

export const deleteSandboxEmployee = (invitationId: number | string): AxiosPromise =>
  api.delete(`${API.SANDBOX_INVITATION}/${invitationId}`)

export const validateInvitedEmployeeLink = (invite: string) =>
  api.get(`${API.SANDBOX_INVITATION}/validate`, {
    params: { invite },
  })

export const generateInitialCycles = async (): Promise<void> => {
  await api.post(`${API.REVIEW_CYCLES}/generate`)
}

export const useGetReviewCycleDetails = (cycleId?: number | string | undefined) => {
  return useFetch<
    ReviewCyclesInterface & {
      is_goals_calibration_active: boolean
      goals_calibration_days_left?: number
    }
  >({
    url: cycleId ? `${API.REVIEW_CYCLES}/${cycleId}` : null,
    queryOptions: { cacheTime: Infinity, staleTime: Infinity },
  })
}
