import React, { useEffect } from 'react'
import { Box, Flex, Text, Token } from '@revolut/ui-kit'
import useResizeObserver from 'use-resize-observer'
import TabBarNavigation from '@src/features/TabBarNavigation/TabBarNavigation'
import { TabBarTableNavigationInterface } from '@src/interfaces/data'
import { connect } from 'lape'
import { css } from 'styled-components'
import mainHeaderState from './MainHeaderState'
import { GlobalSearch } from './GlobalSearch'
import { HelpCenter } from './HelpCenter'
import { Settings } from '@src/features/MainHeader/Settings'
import { Warning } from '@src/features/MainHeader/Warning'
import { UserProfileLink } from '@src/features/MainHeader/UserProfileLink'
import Notifications from '../Notifications/Notifications'
import { useCurrentTenantInfo } from '@src/api/tenants'
import { defaultTheme } from '@src/styles/theme'

interface MainHeaderProps {
  title?: string
  tabs?: TabBarTableNavigationInterface[] | null
  withGlobalSearch?: boolean
  children?: React.ReactNode
}

const stickCss = css<{ opacity?: boolean }>`
  position: sticky;
  top: 0;
  z-index: ${defaultTheme.zIndex.aboveMain + 1};
  background-color: ${Token.color.layoutBackground};
  padding-top: 14px;
`

const withDemoBarCss = css`
  top: 44px;
  margin-bottom: 28px;
  padding-top: 20px;
`

const containerCss = css`
  position: sticky;
  z-index: ${defaultTheme.zIndex.aboveMain + 1};
`

const MainHeader = ({ title, tabs, withGlobalSearch, children }: MainHeaderProps) => {
  const { ref: stickyRef, height: headerHeight } = useResizeObserver()
  const { isDemoMode } = useCurrentTenantInfo()

  useEffect(() => {
    if (headerHeight) {
      mainHeaderState.height = headerHeight + (isDemoMode ? 64 : 14)
    }
  }, [headerHeight, isDemoMode])

  return (
    <Box width="100%" css={[stickCss, isDemoMode && withDemoBarCss]} ref={stickyRef}>
      <Flex pb="s-16" justifyContent="space-between" alignItems="center">
        <Text use="h1" variant="h5" data-event-key={title}>
          {title}
        </Text>
        <Flex alignItems="center" gap="s-8">
          {withGlobalSearch && <GlobalSearch />}
          <Notifications />
          <HelpCenter />
          <Settings />
          <Warning />
          <UserProfileLink />
        </Flex>
      </Flex>
      <Box css={containerCss}>
        {children}
        {tabs != null ? (
          <Box mb="s-16">
            <TabBarNavigation isSmall tabs={tabs} />
          </Box>
        ) : null}
      </Box>
    </Box>
  )
}

export default connect(MainHeader)
