export enum TableTypes {
  Form = 'form',
  Popup = 'popup',
  Stepper = 'stepper',
  NewStepper = 'stepper-new',
  Matrix = 'matrix',
  Adjustable = 'adjustable',
}

export type RowHeight = 'large' | 'medium' | 'small'
