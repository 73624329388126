import { api } from '@src/api/index'
import { API, GRANT_EMAIL_PERMISSIONS_REDIRECT_PARAM } from '@src/constants/api'
import { getTenantSubdomain } from '@src/utils'

export type OfficeSuiteProviderSourceType = 'linked_accounts' | 'candidate_settings'
export type OfficeSuiteProviderType =
  | 'googleCalendar'
  | 'googleEmail'
  | 'googleWorkspace'
  | 'microsoftCalendar'
  | 'microsoftEmail'

export interface GoogleConsentStateInterface {
  subdomain?: string
  source?: OfficeSuiteProviderSourceType
  type?: OfficeSuiteProviderType
}

export const connectGoogleEmail = (
  code: string,
  redirectUrl: string,
  source?: OfficeSuiteProviderSourceType,
) =>
  api.post(API.CONNECT_GOOGLE_EMAIL, {
    code,
    source,
    redirect_url: redirectUrl,
  })

export const connectGoogleCalendar = (code: string, redirectUrl: string) =>
  api.post(API.CONNECT_GOOGLE_CALENDAR, {
    code,
    redirect_url: redirectUrl,
  })

export const connectGoogleWorkspace = (code: string, redirectUrl: string) =>
  api.post(API.CONNECT_GOOGLE_WORKSPACE, {
    code,
    redirect_url: redirectUrl,
  })

export const askGoogleEmailPermissions = async (
  source: OfficeSuiteProviderSourceType,
) => {
  let state: GoogleConsentStateInterface = {
    source,
    type: 'googleEmail',
  }

  state.subdomain = getTenantSubdomain('askGoogleEmailPermissions')

  const loginResponse = await api.get<{ auth_url: string }>(API.CONNECT_GOOGLE_EMAIL, {
    params: {
      redirect_url: GRANT_EMAIL_PERMISSIONS_REDIRECT_PARAM,
      state: state ? JSON.stringify(state) : undefined,
    },
  })

  const authUrl = loginResponse.data.auth_url
  window.open(authUrl, `_blank`, 'width=600,height=800')
}

export const askGoogleCalendarPermissions = async () => {
  let state: GoogleConsentStateInterface = {
    type: 'googleCalendar',
  }

  state.subdomain = getTenantSubdomain('askGoogleCalendarPermissions')

  const loginResponse = await api.get<{ auth_url: string }>(API.CONNECT_GOOGLE_CALENDAR, {
    params: {
      redirect_url: GRANT_EMAIL_PERMISSIONS_REDIRECT_PARAM,
      state: state ? JSON.stringify(state) : undefined,
    },
  })

  const authUrl = loginResponse.data.auth_url
  window.open(authUrl, `_blank`, 'width=600,height=800')
}

export const connectMicrosoftEmail = (
  code: string,
  redirectUrl: string,
  source?: OfficeSuiteProviderSourceType,
) =>
  api.post(API.CONNECT_MICROSOFT_EMAIL, {
    code,
    source,
    redirect_url: redirectUrl,
  })

export const connectMicrosoftCalendar = (code: string, redirectUrl: string) =>
  api.post(API.CONNECT_MICROSOFT_CALENDAR, {
    code,
    redirect_url: redirectUrl,
  })

export const askMicrosoftEmailPermissions = async (
  source: OfficeSuiteProviderSourceType,
) => {
  let state: GoogleConsentStateInterface = {
    source,
    type: 'microsoftEmail',
  }

  state.subdomain = getTenantSubdomain('askMicrosoftEmailPermissions')

  const loginResponse = await api.get<{ auth_url: string }>(API.CONNECT_MICROSOFT_EMAIL, {
    params: {
      redirect_url: GRANT_EMAIL_PERMISSIONS_REDIRECT_PARAM,
      state: state ? JSON.stringify(state) : undefined,
    },
  })

  const authUrl = loginResponse.data.auth_url
  window.open(authUrl, `_blank`, 'width=600,height=800')
}

export const askMicrosoftCalendarPermissions = async () => {
  let state: GoogleConsentStateInterface = {
    type: 'microsoftCalendar',
  }

  state.subdomain = getTenantSubdomain('askMicrosoftCalendarPermissions')

  const loginResponse = await api.get<{ auth_url: string }>(
    API.CONNECT_MICROSOFT_CALENDAR,
    {
      params: {
        redirect_url: GRANT_EMAIL_PERMISSIONS_REDIRECT_PARAM,
        state: state ? JSON.stringify(state) : undefined,
      },
    },
  )

  const authUrl = loginResponse.data.auth_url
  window.open(authUrl, `_blank`, 'width=600,height=800')
}

export const askGoogleWorkspacePermissions = async () => {
  let state: GoogleConsentStateInterface = { type: 'googleWorkspace' }

  state.subdomain = getTenantSubdomain('askGoogleWorkspacePermissions')

  const loginResponse = await api.get<{ auth_url: string }>(
    API.CONNECT_GOOGLE_WORKSPACE,
    {
      params: {
        redirect_url: GRANT_EMAIL_PERMISSIONS_REDIRECT_PARAM,
        state: state ? JSON.stringify(state) : undefined,
      },
    },
  )

  const authUrl = loginResponse.data.auth_url
  window.open(authUrl, `_blank`, 'width=600,height=800')
}
