import React from 'react'
import { Box, DetailsCell, Flex, Group, Token } from '@revolut/ui-kit'
import { css } from 'styled-components'
import { COMPLAINTS_REVOLUT_PEOPLE, CONTACT_REVOLUT_PEOPLE } from '@src/constants/emails'
import { pathToUrl } from '@src/utils/router'
import { PUBLIC_ROUTES } from '@src/constants/routes'

const MainCss = css`
  ol,
  ul {
    counter-reset: item;
    padding-left: 16px;
    list-style-position: outside;
  }
  ol > li {
    display: block;
    padding: 4px 0;
  }
  ol > li:before {
    content: counters(item, '.') ' ';
    counter-increment: item;
    padding-right: 10px;
  }
`

export const TermsAndConditions = () => {
  return (
    <Flex justifyContent="center" p="s-32">
      <Box maxWidth={1000} css={MainCss} color={Token.color.foreground}>
        <h1>Revolut People</h1>
        <h2>TERMS AND CONDITIONS</h2>
        <p>
          These Terms govern the use of the Revolut People product by the legal entity
          using the Services under these Terms. Please read the Terms carefully before you
          start using Revolut People. By accepting these Terms, you agree to be legally
          bound by these Terms.
        </p>
        <ol>
          <li>
            <strong>DEFINITIONS</strong>

            <p>The following definitions apply in these Terms:&nbsp;</p>
            <ol>
              <li>
                <strong>Affiliate </strong>means any entity that directly or indirectly
                controls, is controlled by, or is under common control with, a party.
              </li>
              <li>
                <strong>Applicable Laws </strong>means any statutes, statutory
                instruments, laws, directives, regulations, orders or other legal
                requirements in the UK and EEA, relevant to the activities contemplated by
                these Terms.
              </li>
              <li>
                <strong>Business Day</strong> means a day other than a Saturday, Sunday or
                public holiday in England when banks in London are open for business.
              </li>
              <li>
                <strong>Confidential Information </strong>means all information that has
                been or will be disclosed by or on behalf of either party (as applicable,
                such entities collectively, the &ldquo;<strong>Disclosing Party</strong>
                &rdquo;) to the other party or its Affiliates (collectively, the &ldquo;
                <strong>Receiving Party</strong>&rdquo;) that is designated as
                confidential or that, given the nature of the information or the
                circumstances surrounding its disclosure, reasonably should be considered
                as confidential, including any third party information that the Disclosing
                Party may have access to under these Terms or other agreement, regardless
                of whether or not such disclosure or access is made verbally, in writing,
                in hard copy or electronic format, or otherwise. &ldquo;Confidential
                Information&rdquo; includes, without limitation, all information regarding
                Revolut People, the Services, the Documentation, marketing data, business
                plans, and technical information.
              </li>
              <li>
                <strong>Customer Data </strong>means any data (excluding User Data or
                personal data) which Revolut accesses or obtains as a result of your use
                of the Services, including (but not limited to) the number of current
                active employees in your organisation, the number of your daily active
                Users, the projected growth of your organisation and the most used
                features by your employees.
              </li>
              <li>
                <strong>Data Processing Addendum </strong>means the data processing
                addendum appended to these Terms.
              </li>
              <li>
                <strong>Dispute Notice </strong>has the meaning given to it in clause 23 (
                <em>Dispute Resolution</em>).
              </li>
              <li>
                <strong>Documentation </strong>has the meaning given to it in clause 2 (
                <em>Revolut People, the Services and the Documentation</em>).
              </li>
              <li>
                <strong>Effective Date </strong>means the date on which we provide you
                with access to Revolut People and the Services.
              </li>
              <li>
                <strong>Force Majeure </strong>means any cause affecting, preventing or
                hindering the performance by a party of its obligations (other than
                payment obligations) under these Terms arising from acts, events,
                omissions or non-events beyond its reasonable control, including, by way
                of example, power outages, third party technical malfunctions, acts of
                God, riots, war, acts of terrorism, fire, flood, storm, pandemics (other
                than COVID-19 and its variants), earthquake, governmental action
                (excluding regulatory change), labour dispute (save where such dispute
                involves personnel of the non-performing party or its Affiliates or
                subcontractors) and any similar event beyond the reasonable control of the
                non-performing party, but does not include interruptions to internet,
                other communications or utilities.
              </li>
              <li>
                <strong>Indemnified Claim</strong> has the meaning given to it in clause
                16 (Indemnification).
              </li>
              <li>
                <strong>Indemnified Party</strong> has the meaning given to it in clause
                16 (Indemnification).
              </li>
              <li>
                <strong>Indemnifying Party</strong> has the meaning given to it in clause
                16 (Indemnification).
              </li>
              <li>
                <strong>Intellectual Property Rights </strong>means patents, utility
                models, rights to inventions, copyright and neighbouring and related
                rights, moral rights, trademarks and service marks, business names and
                domain names, rights in get-up and trade dress, goodwill and the right to
                sue for passing off or unfair competition, rights in designs, rights in
                computer software, database rights, rights to use, and protect the
                confidentiality of, information (including know-how and trade secrets),
                and all other intellectual property rights, in each case whether
                registered or unregistered, and including all applications and rights to
                apply for and be granted, renewals or extensions of, and rights to claim
                priority from, such rights and all similar or equivalent rights or forms
                of protection which subsist, or will subsist now or in the future, in any
                part of the world.
              </li>
              <li>
                <strong>Invoice </strong>means the invoice sent to you containing the
                order details related to the Services.
              </li>
              <li>
                <strong>Revolut </strong>means Revolut Ltd, a company incorporated in
                England and Wales with company number 08804411 and whose registered office
                is at 7 Westferry Circus, Canary Wharf, London, E14 4HD, United Kingdom.
              </li>
              <li>
                <strong>Revolut Indemnified Parties </strong>has the meaning given to it
                in clause 16<em> (Indemnification</em>).
              </li>
              <li>
                <strong>Revolut People Landing Page</strong> means the website page found
                at this hyperlink:{' '}
                <a
                  href="https://revolutpeople.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://revolutpeople.com
                </a>
                .
              </li>
              <li>
                <strong>Sales Tax </strong>has the meaning given to it in clause 3{' '}
                <em>(Payment Terms)</em>.
              </li>
              <li>
                <strong>Services </strong>has the meaning given to it in clause 2 (
                <em>Revolut People, the Services and the Documentation</em>).&nbsp;
              </li>
              <li>
                <strong>Subscription Fees </strong>means the fees you will pay for using
                Revolut People and the Services, as set out on the Revolut People Landing
                Page. The Subscription Fees may be updated from time to time in accordance
                with clause 3.1 (<em>Payment Terms)</em>.
              </li>
              <li>
                <strong>Subscription Term </strong>means the period set out in the Invoice
                starting on the Effective Date, unless terminated earlier in accordance
                with these Terms.&nbsp;
              </li>
              <li>
                <strong>Third Party Software</strong> has the meaning given to it in
                clause 8 <em>(Disclaimers)</em>.
              </li>
              <li>
                <strong>Users </strong>means employees of your organisation (including
                employees of your Affiliates) that use Revolut People.
              </li>
              <li>
                <strong>User Data </strong>means your personal data and personal data of
                your Users gathered pursuant to your use of Revolut People.
              </li>
            </ol>
          </li>

          <li>
            <strong>REVOLUT PEOPLE, THE SERVICES AND THE DOCUMENTATION</strong>

            <ol>
              <li>
                Revolut People is a cloud-based SaaS platform, which provides a solution
                to enable businesses to manage their employees. Pursuant to these Terms,
                Revolut will grant you with a licence to use Revolut People for your
                organisation.
              </li>
              <li>
                Revolut and our Affiliates will provide you with the services, as
                described on the Revolut People Landing Page and in the Documentation (the
                &ldquo;
                <strong>Services</strong>&rdquo;).
              </li>
              <li>
                These Terms shall be read in conjunction with the documentation that
                Revolut will make reasonable efforts to provide to you at the start of the
                Subscription Term. This documentation outlines the technical
                specifications and requirements to enable the delivery of the Services to
                you and supports your use of Revolut People (the &ldquo;
                <strong>Documentation</strong>
                &rdquo;).&nbsp;
              </li>
              <li>
                If there is any inconsistency or conflict between these Terms, or any
                other documents referred to in it or annexed to it, the following order of
                precedence shall apply: (i) the Data Processing Addendum; (ii) these
                Terms; and (iii) the Documentation.
              </li>
              <li>You acknowledge that Revolut People is an unregulated service.</li>
            </ol>
          </li>

          <li>
            <strong>PAYMENT TERMS</strong>

            <ol>
              <li>
                The Subscription Fees are charged on a per User basis, and are set out in
                more detail on the Revolut People Landing Page. Revolut reserves the right
                to change the Subscription Fees at any time subject to providing you with
                a minimum of fifty (50) Business Days written notice.
              </li>
              <li>
                We will charge you the Subscription Fees by sending you the Invoice at the
                beginning of the Subscription Term.
              </li>
              <li>
                In the event that the total number of Users of Revolut People has exceeded
                the amount that is specified in your Invoice, Revolut may charge you in
                arrears for the additional Subscription Fees that are due based on the
                number of additional Users.
              </li>
              <li>
                Invoices for the Subscription Fees are payable in full within seven (7)
                days of the applicable Invoice date.
              </li>
              <li>
                If you do not pay any amount payable under these Terms by the due date for
                such payment, we may charge you interest, which will accrue on a daily
                basis at a rate of 5% per annum above the Bank of England base rate and if
                the Bank of England base rate is below zero, at a rate of 5% per annum.
                Interest is calculated from the due date until such date as the payment is
                actually made.
              </li>
              <li>
                The Subscription Fees are exclusive of any applicable sales tax, value
                added tax (VAT), goods and services tax, or other equivalent tax that is
                chargeable in any relevant jurisdiction ("<strong>Sales Tax</strong>").
                You shall, on receipt of a valid Sales Tax invoice from us, pay such
                additional amounts in respect of Sales Tax as are chargeable on a supply
                of Revolut People and the Services.
              </li>
            </ol>
          </li>
          <li>
            <strong>CREATING AN ACCOUNT&nbsp;</strong>

            <ol>
              <li>
                In order to use Revolut People, a designated employee of your organisation
                will need to create a Revolut People account.&nbsp;
              </li>
              <li>
                In order to create a Revolut People account, your designated employee will
                be asked to provide details including their name, email address, phone
                number, your organisation name and the number of Users at your
                organisation. These details will be used in accordance with our{' '}
                <a
                  href={pathToUrl(PUBLIC_ROUTES.PRIVACY_NOTICE)}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Customer Privacy Notice
                </a>
                .&nbsp;
              </li>
              <li>
                You will also be required to create a password at this stage. You are
                responsible for maintaining the confidentiality of your password. You must
                notify us immediately in the event that you become aware of unauthorised
                access to your account or password.&nbsp;
              </li>
            </ol>
          </li>
          <li>
            <strong>GRANT OF LICENCE</strong>
            <ol>
              <li>
                Revolut grants you a limited, non-exclusive, non-sublicensable,
                non-transferable licence, to access and use Revolut People, the Services
                and the Documentation during the Subscription Term.&nbsp;
              </li>
              <li>
                Revolut People, the Services and the Documentation may be used by your
                Affiliates on the same terms as those that apply to you.
              </li>
            </ol>
          </li>
          <li>
            <strong>RESTRICTIONS ON USE&nbsp;</strong>
            <ol>
              <li>
                You must only use the Revolut People product, Services and Documentation
                for internal business purposes.
              </li>
              <li>You warrant that all Users will be 18 years of age or older.&nbsp;</li>
              <li>
                You must not, and you will not permit any third party to:
                <ol>
                  <li>
                    use Revolut People other than in accordance with these Terms and all
                    Applicable Laws;
                  </li>
                  <li>
                    use Revolut People in any manner or for any purpose that infringes,
                    misappropriates, or otherwise violates the right of any third party,
                    including (but not limited to) the Intellectual Property Rights, data
                    protection and privacy rights of any third party;
                  </li>
                  <li>
                    install, or otherwise introduce, harmful code or viruses onto Revolut
                    People;&nbsp;
                  </li>
                  <li>
                    use Revolut People for illegal or prohibited purposes such as
                    uploading content which is fraudulent, defamatory, sexually explicit,
                    abusive, knowingly false or misleading, libellous, racist or
                    encourages criminal behaviour;&nbsp;
                  </li>
                  <li>
                    rent, lease, lend, sell, sub-licence, assign, distribute or transfer
                    in whole or in part the right granted to you to use Revolut People;
                  </li>
                  <li>
                    work around, or attempt to bypass, any of the technical limitations of
                    Revolut People and/or enable functionality that is disabled or
                    prohibited;
                  </li>
                  <li>
                    reverse engineer or attempt to reverse engineer, de-compile,
                    disassemble or otherwise attempt to gain access to the source code,
                    object code or underlying structure, ideas or algorithms of Revolut
                    People;&nbsp;
                  </li>
                  <li>
                    copy, modify, translate, or create derivative works based on Revolut
                    People;
                  </li>
                  <li>
                    access Revolut People, the Services or the Documentation in order to
                    build a product that competes with Revolut People;&nbsp;
                  </li>
                  <li>
                    use Revolut People, the Services or the Documentation to provide
                    services to third parties;
                  </li>
                  <li>
                    attempt to remove any proprietary notices from Revolut People; or
                  </li>
                  <li>
                    perform or attempt to perform any actions that interfere with the
                    normal operation of Revolut People or affect use of Revolut People by
                    other users.
                  </li>
                </ol>
              </li>
              <li>
                If you fail to comply with any of the above restrictions, without
                prejudice to any other rights or remedies that we may have, Revolut may
                suspend or terminate your access to Revolut People with immediate
                effect.&nbsp;
              </li>
            </ol>
          </li>
          <li>
            <strong>REPRESENTATIONS AND WARRANTIES</strong>

            <ol>
              <li>
                Revolut warrants to you that during an applicable Subscription Term the
                Services shall be performed with reasonable care and in accordance with
                the Documentation in all material aspects. In the event that the Services
                fail to conform to this warranty, your exclusive remedy and
                Revolut&rsquo;s entire liability shall be that Revolut shall exercise
                commercially reasonable efforts at its expense to:
                <ol>
                  <li>
                    modify the Service to materially conform to the functionality set
                    forth in the Documentation, and
                  </li>
                  <li>re-perform the Services in compliance with this warranty.</li>
                </ol>
              </li>
              <li>
                By accepting these Terms, you represent and warrant that:
                <ol>
                  <li>
                    You have full capacity, power and authority to accept these Terms;
                  </li>
                  <li>
                    There are no material actions, suits or proceedings or regulatory
                    investigations pending or, to your knowledge, threatened against or
                    affecting you before any court or administrative body or arbitration
                    tribunal that might affect your ability to meet and carry out your
                    obligations under these Terms;
                  </li>
                  <li>
                    You have obtained all requisite regulatory approvals, licences,
                    consents, rights and permits to perform your obligations under these
                    Terms; and&nbsp;
                  </li>
                  <li>
                    You have obtained all relevant permissions and consent to provide the
                    User Data to us.
                  </li>
                </ol>
              </li>
              <li>
                By accepting these Terms, you undertake that:
                <ol>
                  <li>
                    You will be liable for the acts and omissions of your Affiliates,
                    Users, and you Affiliates&rsquo; Users; and
                  </li>
                  <li>
                    You will provide reasonable assistance to Revolut to allow it to
                    perform its obligations under these Terms.&nbsp;
                  </li>
                  <li>
                    You will be solely responsible for ensuring your compliance with all
                    Applicable Laws.
                  </li>
                </ol>
              </li>
            </ol>
          </li>
          <li>
            <strong>DISCLAIMERS</strong>

            <p>By accepting these Terms, you acknowledge and agree that:</p>
            <ol>
              <li>
                Except as expressly set forth in these Terms, Revolut People and the
                Services are provided on an &ldquo;as is&rdquo; basis, and Revolut
                disclaims all warranties, representations and conditions whether express,
                implied, statutory or otherwise to the fullest extent permitted by law.
              </li>
              <li>
                Revolut does not warrant or undertake that Revolut People and the Services
                will enable or ensure that you comply with Applicable Laws.
              </li>
              <li>
                Certain features or functionalities of Revolut People and the Services may
                rely on or integrate with third-party software, applications, or services
                (the &ldquo;<strong>Third Party Software</strong>&rdquo;). Your use of
                Third Party Software may be subject to the terms and conditions imposed by
                the respective third-party providers, and Revolut makes no representations
                or warranties regarding the quality, performance, or security of any Third
                Party Software.
              </li>
            </ol>
          </li>

          <li>
            <strong>UPDATES TO THE PRODUCT AND UPTIME</strong>

            <p>
              Revolut reserves the right at any time to make changes to Revolut People
              without notice to you and at its sole discretion. Revolut will not be liable
              to you for any disruption as a result of such changes and Revolut does not
              guarantee uptime of Revolut People.&nbsp;
            </p>
          </li>

          <li>
            <strong>INTELLECTUAL PROPERTY RIGHTS</strong>

            <ol>
              <li>
                Revolut shall retain all Intellectual Property Rights in Revolut People,
                the Services and the Documentation including all improvements, enhancement
                and modifications thereto. You are not granted any Intellectual Property
                Rights in the foregoing, or any right to use the Revolut trademark, logo,
                or brand features.&nbsp;
              </li>
              <li>
                You grant Revolut and our Affiliates a worldwide, non-exclusive, royalty
                free licence to use, adapt and modify the User Data solely for the purpose
                of providing the Services pursuant to these Terms.&nbsp;
              </li>
              <li>You retain all rights, title and interest in the User Data.&nbsp;</li>
            </ol>
          </li>

          <li>
            <strong>FEEDBACK AND CUSTOMER DATA</strong>

            <ol>
              <li>
                If you or any of your Users provide Revolut with ideas, suggestions,
                enhancement requests, feedback or recommendations regarding Revolut
                People, the Services or the Documentation (&ldquo;
                <strong>Feedback</strong>&rdquo;), the Feedback shall be deemed to be
                provided on a non-confidential and non-proprietary basis. Revolut shall be
                able to use the Feedback without being subject to any restriction, or any
                requirement to provide compensation or attribution to you.
              </li>
              <li>
                You acknowledge and agree that Revolut may use Customer Data to:&nbsp;
              </li>
              <ol>
                <li>provide you with the Services; and&nbsp;</li>
                <li>
                  aggregate and de-identify Customer Data and use this for its own
                  commercial purposes, including for the purpose of carrying out
                  analytics, research and for marketing purposes.&nbsp;
                </li>
              </ol>
            </ol>
          </li>

          <li>
            <strong>DATA PROTECTION</strong>
            <p>
              In order to provide Revolut People, Revolut acts as Data Processor and
              collects, processes and stores data including personal information of the
              Users. The legal entity using the Services under these Terms is acting as
              Data Controller and is fully responsible for evaluating whether its use of
              the Services is compliant with any Data Protection Laws that may be
              applicable to it. Data protection related obligations of the Parties are
              established in the Data Processing Addendum.
            </p>
          </li>

          <li>
            <strong>CONFIDENTIALITY</strong>

            <ol>
              <li>
                Each party undertakes that it shall at any time during the Subscription
                Term, and for a period of five (5) years after termination or expiry of
                the Subscription Term, not disclose to any person, or authorise the
                disclosure of, any Confidential Information, except as permitted by this
                clause 13 (<em>Confidentiality</em>).
              </li>
              <li>
                Each party may disclose the other party's Confidential Information:
                <ol>
                  <li>
                    to its Affiliates and its and their employees, officers,
                    representatives, subcontractors or advisers who need to know such
                    information for the purposes of carrying out the relevant party's
                    obligations under these Terms (&ldquo;
                    <strong>Permitted Recipients</strong>&rdquo;). Each party shall ensure
                    that its Permitted Recipients are aware of the confidential nature of
                    the Confidential Information and comply with this clause 13 (
                    <em>Confidentiality</em>); and
                  </li>
                  <li>
                    as may be required by law or a court, governmental body or regulatory
                    authority of competent jurisdiction, provided that, to the extent it
                    is permitted to do so, it shall:
                    <ol>
                      <li>
                        notify the other party as soon as practicable upon becoming aware
                        of the obligation to disclose and, to the extent that it is
                        prevented from notifying the other party, it shall use
                        commercially reasonable efforts to challenge any restriction on
                        disclosure of the request to the other party, which shall include
                        applying to the court for the removal of such restriction where
                        applicable; and
                      </li>
                      <li>
                        at the other party&rsquo;s request, use commercially reasonable
                        efforts (and, where applicable, in cooperation with the other
                        party) to avoid or limit the disclosure and obtain assurances as
                        to the confidentiality and use of the data from the body to whom
                        the Confidential Information is to be disclosed.
                      </li>
                    </ol>
                  </li>
                </ol>
              </li>
              <li>
                Neither party shall use the other party's Confidential Information for any
                purpose other than to perform its obligations under these Terms.
              </li>
              <li>
                The Receiving Party will notify the Disclosing Party as soon as possible
                of any incident of unauthorised access to or use of Confidential
                Information of the Disclosing Party or and any other breach in the
                Receiving Party&rsquo;s security that affects the Disclosing Party or
                Confidential Information relating to the Disclosing Party.
              </li>
              <li>
                The Receiving Party will take any and all appropriate actions to address
                any incident of unauthorised access to or use of Confidential Information
                relating to the Disclosing Party.
              </li>
              <li>
                This clause 13 (<em>Confidentiality</em>) shall not apply to Confidential
                Information to the extent that:
                <ol>
                  <li>
                    it is or becomes publicly available without breach of these
                    Terms;&nbsp;
                  </li>
                  <li>
                    was known by the Receiving Party without any obligation of
                    confidentiality prior to its receipt from the Disclosing Party;&nbsp;
                  </li>
                  <li>
                    is rightfully disclosed to the Receiving Party from any third party
                    without any obligation of confidentiality towards the Disclosing
                    Party; or&nbsp;
                  </li>
                  <li>
                    is independently developed by the Receiving Party without use of or
                    reference to any Confidential Information.
                  </li>
                </ol>
              </li>
              <li>
                Confidential Information remains the exclusive property of the party
                owning it.
              </li>
            </ol>
          </li>

          <li>
            <strong>PROMOTIONAL USES AND ACTIVITIES</strong>
            <ol>
              <li>
                You grant to Revolut a non-exclusive, non-transferable, revocable,
                worldwide, royalty-free right during the Term to use your name and
                trademark to identify Revolut as your provider of people management
                services.&nbsp;
              </li>
              <li>
                Revolut may use your name and logo as follows:
                <ol>
                  <li>
                    on Revolut&rsquo;s webpages that identify customers of Revolut People,
                    for the sole purpose of identifying you as a customer of Revolut
                    People; and
                  </li>
                  <li>
                    in Revolut&rsquo;s sales and marketing materials and communications
                    (including in press announcements and blog posts on Revolut&rsquo;s
                    websites), and financial disclosure documents, for the sole purpose of
                    identifying you as a user of Revolut People.
                  </li>
                </ol>
              </li>
              <li>
                Revolut may request that you participate as a reference customer in a case
                study regarding your use of Revolut People. By accepting these Terms, you
                agree to collaborate with Revolut to create a case study.
              </li>
            </ol>
          </li>

          <li>
            <strong>INDEPENDENT CONTRACTORS</strong>

            <p>
              The relationship between you and Revolut shall at all times be that of
              independent contractors. No employment relationship, partnership, joint
              venture or agency is formed between us by these Terms.
            </p>
          </li>

          <li>
            <strong>INDEMNIFICATION</strong>

            <ol>
              <li>
                Revolut shall indemnify you from and against any direct claims, suits,
                hearings, actions and all reasonably foreseeable and legally enforceable
                direct losses, damages, liabilities, fines, penalties, costs, losses,
                judgments or expenses (including reasonable attorneys’ fees, legal costs
                and VAT), incurred by you as a result of a third party claim that the use
                of Revolut People or the Services in accordance with these Terms infringes
                any Intellectual Property Rights belonging to a third party (each an “
                <strong>Indemnified Claim</strong>”).
              </li>
              <li>
                Notwithstanding the foregoing, if Revolut reasonably believes that your
                use of any portion of Revolut People or the Services is likely to be
                enjoined by reason of any Indemnified Claims then Revolut may, at its
                expense and in its sole discretion: (i) procure for you the right to
                continue using Revolut People or the Services; (ii) replace the same with
                other products having substantially equivalent functions that are not
                subject to any Indemnified Claims of infringement; or (iii) modify Revolut
                People or the Services so that there is no longer any infringement,
                provided that such modification does not materially and adversely affect
                the functional capabilities of Revolut People or the Services. If (i),
                (ii), and (iii) above are not available on commercially reasonable terms
                in Revolut’s judgement, Revolut may terminate the affected parts of
                Revolut People or the Services and refund to you the fees paid by you
                covering the remaining portion of the applicable Subscription Term after
                the date of termination.
              </li>
              <li>
                Revolut’s obligations in this clause 16 (Indemnification) do not apply to
                the extent the Indemnified Claim is caused by or related to:
                <ol>
                  <li>
                    your failure to implement Revolut People or the Services in accordance
                    with the Documentation, unless modifications or amendments to Revolut
                    People or the Services are agreed by the parties in writing;
                  </li>
                  <li>your breach of these Terms; or</li>
                  <li>your gross negligence, fraud or willful misconduct.</li>
                </ol>
              </li>
              <li>
                You agree to indemnify, defend and hold harmless Revolut and each of its
                Affiliates, and their officers, directors, employees, subcontractors and
                agents (&ldquo;<strong>Revolut Indemnified Parties</strong>&rdquo;),
                against all claims, actions, proceedings, losses, damages, expenses and
                costs brought against any Revolut Indemnified Parties (including, without
                limitation, reasonable legal fees and other litigation expenses) incurred
                by the Revolut Indemnified Parties, arising out of or relating to:
                <ol>
                  <li>
                    a claim that the User Data provided by you or any Affiliate in
                    connection with your use of Revolut People infringes any third
                    parties&rsquo; Intellectual Property Rights;
                  </li>
                  <li>
                    a claim that arises as a result of you, an Affiliate, your Users or
                    the Users of your Affiliates using Revolut People in a manner contrary
                    to these Terms;
                  </li>
                  <li>
                    a violation of Applicable Laws in connection with use by you or any
                    Affiliate of Revolut People; and
                  </li>
                  <li>a violation of these Terms by you or any Affiliate.</li>
                </ol>
              </li>
              <li>
                As a condition for the indemnifying party (
                <strong>“Indemnifying Party”</strong>) to fulfil its obligations outlined
                in this clause 16 (Indemnification), the party to be indemnified (
                <strong>“Indemnified Party”</strong>) shall:
                <ol>
                  <li>
                    promptly notify Indemnifying Party in writing upon becoming aware of
                    the Indemnified Claim;
                  </li>
                  <li>
                    allow the Indemnifying Party, at its own cost, to conduct all
                    negotiations and proceedings and to settle the Indemnified Claim,
                    always provided that the Indemnifying Party shall obtain Indemnified
                    Party’s prior approval of any settlement terms, such approval not to
                    be unreasonably withheld;
                  </li>
                  <li>
                    provide the Indemnifying Party with such reasonable assistance
                    regarding the Indemnified Claim as is required by the Indemnifying
                    Party, subject to reimbursement by the Indemnifying Party of
                    Indemnified Party’s costs incurred; and
                  </li>
                  <li>
                    not make any admission relating to the Indemnified Claim or attempt to
                    settle it without prior consultation with the Indemnifying Party,
                    provided that the Indemnifying Party considers and defends any
                    Indemnified Claim diligently, using competent counsel and in such a
                    way as not to bring the reputation of Indemnified Party into
                    disrepute.
                  </li>
                </ol>
              </li>
              <li>
                If Indemnifying Party does not elect to defend the Indemnified Claim or
                does not actively defend the Indemnified Claim, then Indemnified Party
                will have the right to defend or settle the Indemnified Claim in the
                manner it considers appropriate, at the cost of the Indemnifying Party and
                the Indemnifying Party will give Indemnified Party all reasonable
                assistance with the conduct of the defence and settlement of the
                Indemnified Claim.
              </li>
            </ol>
          </li>

          <li>
            <strong>LIMITATION OF LIABILITY</strong>

            <ol>
              <li>
                To the extent permitted by law, neither party shall be liable for any
                indirect or consequential losses arising from or in connection with these
                Terms (including any of its addendums).&nbsp;
              </li>
              <li>
                Nothing in these Terms excludes your liability for any breach,
                infringement or misappropriation of Revolut&rsquo;s Intellectual Property
                Rights.
              </li>
              <li>
                The maximum liability of Revolut in respect of any claims in connection
                with these Terms whether in tort (including negligence), contract,
                misrepresentation, pursuant to warranty or otherwise pursuant to these
                Terms, shall be limited to an amount equal to the value of 12 months of
                Subscription Fees paid or payable under these Terms.&nbsp;
              </li>
              <li>
                You agree that Revolut shall not be liable for any breach by you of local
                labour laws.&nbsp;
              </li>
              <li>
                Liability is only limited to the extent permitted by Applicable Laws, and
                these Terms do not limit liability for fraud, fraudulent
                misrepresentation, death or personal injury caused by negligence or
                willful misconduct, or any other liability which cannot be lawfully
                excluded or limited.
              </li>
              <li>
                Each party shall take reasonable steps to mitigate the effects of any
                loss, damage, injury, claims, demands, costs, liability, proceedings and
                expenses which may give rise to a claim by it under these Terms, and shall
                give the other party notice as soon as possible once it becomes aware of
                any matter or event which could lead to an amount becoming due under these
                Terms.&nbsp;
              </li>
            </ol>
          </li>

          <li>
            <strong>TERM</strong>
            <ol>
              <li>
                These Terms will apply for the duration of the Subscription Term and will
                automatically renew for successive periods of the same length as the
                initial Subscription Term, unless terminated earlier in accordance with
                clause 19 (<em>Suspension and Termination</em>).
              </li>
              <li>
                On termination of these Terms for any reason:
                <ol>
                  <li>
                    all licences granted under these Terms shall immediately terminate and
                    you shall immediately cease all use of Revolut People, the Services
                    and/or the Documentation;&nbsp;
                  </li>
                  <li>
                    you will immediately destroy the Documentation that you have received
                    if requested to do so by Revolut; and
                  </li>
                  <li>
                    any rights, remedies, obligations or liabilities of the parties that
                    have accrued up to the date of termination, including the right to
                    claim damages in respect of any breach of the Terms which existed at
                    or before the date of termination shall not be affected or prejudiced.
                  </li>
                </ol>
              </li>
              <li>
                The provisions of clauses 6 (<em>Restrictions on Use</em>),<em> </em>
                clauses 13 (<em>Confidentiality</em>), 14 (
                <em>Promotional Uses and Activities</em>
                ), 16 (<em>Indemnification</em>), 17 (<em>Limitation of Liability)</em>{' '}
                and 23 (<em>Dispute Resolution</em>) will survive the termination or
                expiration of these Terms.&nbsp;
              </li>
            </ol>
          </li>

          <li>
            <strong>SUSPENSION AND TERMINATION</strong>

            <ol>
              <li>
                Either party may terminate these Terms or your access to Revolut People at
                any time for any reason, subject to giving at least forty-five (45) days
                prior written notice of such termination, with the termination taking
                effect at the end of then-current Subscription Term. No refunds of prepaid
                Subscription Fees shall be made to you in connection with any termination
                or expiration of these Terms, unless the termination results from a breach
                of these Terms by Revolut.
              </li>
              <li>
                We may immediately suspend your access to Services if you do not pay your
                Invoice by the due date.
              </li>
              <li>
                Without prejudice to any other right or remedy it may have, either party
                (the "<strong>Non-Defaulting Party</strong>") may terminate these Terms
                with immediate effect by giving written notice to the other party within
                ten (10) days of becoming aware that any of the following events have
                occurred in respect of the other party (the "
                <strong>Defaulting Party</strong>"):
                <ol>
                  <li>
                    the Defaulting Party commits a material breach of these Terms and,
                    within twenty (20) days after receiving written notice from the
                    Non-Defaulting Party specifying the breach and requiring the breach to
                    be remedied, the Defaulting Party fails to remedy such breach (if
                    capable of remedy);
                  </li>
                  <li>
                    the Defaulting Party commits a series of breaches of these Terms that
                    may not themselves be material, are notified to the Non-Defaulting
                    party and in each case, fail to be uncured within twenty (20) days of
                    such notice &ndash; if in aggregate such uncured breaches would amount
                    to a material breach;
                  </li>
                  <li>
                    the Defaulting Party ceases or threatens to cease to carry on business
                    or has become insolvent within the meaning of any Applicable Law;
                  </li>
                  <li>
                    if an annual vendor due diligence or sanction audit shows the other
                    Party is on any list of prohibited or restricted parties list
                    maintained by the United Kingdom, European Union or its Member States
                    or other applicable government authority.
                  </li>
                </ol>
              </li>
              <li>
                Revolut may terminate these Terms with immediate effect by giving written
                notice to you if you do anything which in the opinion of Revolut (acting
                reasonably) materially and detrimentally affects the reputation of
                Revolut.
              </li>
            </ol>
          </li>

          <li>
            <strong>VARIATION OF TERMS</strong>
            <p>
              We may vary these Terms at any time subject to providing you with notice. It
              is your responsibility to comply with our Terms, and your continued use of
              Revolut People following any variations will be deemed as acceptance of the
              modified Terms.
            </p>
          </li>

          <li>
            <strong>NOTICES</strong>
            <ol>
              <li>
                Any notice given under or in connection with these Terms shall be in
                English, in writing and sent by email. Notices shall be deemed to have
                been received on the day the email was sent.&nbsp;
              </li>
              <li>
                All notices to Revolut shall be sent to{' '}
                <a
                  href={`mailto:${CONTACT_REVOLUT_PEOPLE}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {CONTACT_REVOLUT_PEOPLE}
                </a>
                , with a copy to <u>legalnotices@revolut.com</u>.
              </li>
            </ol>
          </li>

          <li>
            <strong>HOW TO MAKE A COMPLAINT</strong>

            <p>
              If you&rsquo;re unhappy with our service, please contact us. You can contact
              us by email at:{' '}
              <a
                href={`mailto:${COMPLAINTS_REVOLUT_PEOPLE}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {COMPLAINTS_REVOLUT_PEOPLE}
              </a>
              . We&rsquo;ll look into your complaint and respond to you by email.&nbsp;
            </p>
          </li>

          <li>
            <strong>DISPUTE RESOLUTION</strong>
            <p>
              If any dispute arises in connection with these Terms, then a party shall
              give to the other party written notice of the dispute, setting out its
              nature and full particulars of the dispute, together with relevant
              supporting documents ("
              <strong>Dispute Notice</strong>"). On service of the Dispute Notice, the
              representatives of the parties shall attempt in good faith to resolve the
              dispute. If such persons are unable to resolve the dispute within thirty
              (30) calendar days (or such a longer period of time as mutually agreed in
              writing between the parties) from the date one party has served a Dispute
              Notice on the other party, either party may commence legal proceedings in
              relation to the dispute.
            </p>
          </li>

          <li>
            <strong>GOVERNING LAW AND JURISDICTION</strong>

            <p>
              These Terms and any dispute or claim (including non-contractual disputes or
              claims) arising out of or in connection with it or its subject matter or
              formation shall be governed by and construed in accordance with the laws of
              England and Wales. Each party agrees that the courts of England shall have
              exclusive jurisdiction to settle any dispute that may arise out of or in
              connection with these Terms.&nbsp;
            </p>
          </li>

          <li>
            <strong>MISCELLANEOUS</strong>

            <ol>
              <li>
                These Terms represent the entire agreement between you and Revolut and
                supersede any previous related discussions or agreements in relation to
                the subject matter.
              </li>
              <li>
                Only you and Revolut have any rights under these Terms. No other person
                will have any right under the Contracts (Rights of Third Parties) Act 1999
                to enforce any of its terms.
              </li>
              <li>
                You may not assign your rights under these Terms or subcontract any of
                your obligations to anyone else without our prior written consent. We may
                assign our rights or obligations under these Terms at any time.
              </li>
              <li>
                Neither party will be in breach of these Terms if such delay or failure
                results from Force Majeure. The party whose performance has been delayed,
                shall promptly give notice to the other party of the delay. In the event
                of Force Majeure persisting for more than 20 continuous Business Days,
                either party shall be entitled to terminate your subscription in Revolut
                People with immediate effect by giving notice to the other party.
              </li>
              <li>
                A delay in exercising, or a failure to exercise, any rights in these Terms
                does not operate as a waiver of those rights. A waiver of a breach under
                these Terms shall not constitute a waiver of any subsequent breach.
              </li>
              <li>
                If any provision of these Terms is invalid, illegal or unenforceable, then
                that provision will be severed, and the remainder of the provisions will
                continue in full force and effect.
              </li>
              <li>
                These Terms are non-exclusive and Revolut may enter into similar
                agreements with third parties. Revolut makes no representation that these
                Terms are similar to or the same as the terms of any other agreement it
                has entered, or may enter into, with any third party.
              </li>
            </ol>
          </li>
        </ol>

        <p>
          <strong>DATA PROCESSING ADDENDUM</strong>
        </p>
        <p>
          This Data Processing Addendum (&ldquo;<strong>DPA</strong>&rdquo;) is
          incorporated into the Revolut People Terms and Conditions (&ldquo;
          <strong>Principal Agreement</strong>&rdquo;). For the Purposes of this DPA, the
          Party accepting the Terms is the <strong>Controller</strong>.&nbsp;
        </p>
        <ol>
          <li>
            <strong>DEFINITIONS</strong>
            <ol>
              <li>
                &lsquo;<strong>Data Protection Laws</strong>&rsquo; means any applicable
                law relating to the protection of personal data or privacy, including the
                UK Data Protection Laws and the EU Data Protection Laws (as applicable).
              </li>
              <li>
                &lsquo;<strong>EU Data Protection Laws</strong>&rsquo; means the General
                Data Protection Regulation ((EU) 2016/679) (&lsquo;<strong>GDPR</strong>
                &rsquo;) and any applicable national implementing laws, regulations and
                secondary laws, as amended or updated from time to time.
              </li>
              <li>
                &lsquo;<strong>In-Scope Personal Data</strong>&rsquo; has the meaning
                given to it in Annex A (<em>Data Processing Information</em>).
              </li>
              <li>
                &lsquo;<strong>Processing Purpose</strong>&rsquo; has the meaning given to
                it in Annex A (<em>Data Processing Information</em>).
              </li>
              <li>
                &lsquo;<strong>Services</strong>&rsquo; means the services to be provided
                by Revolut under the Principal Agreement.
              </li>
              <li>
                &lsquo;<strong>UK Data Protection Laws</strong>&rsquo; means (i) the UK
                GDPR; and (ii) the Data Protection Act 2018, together with any laws
                implementing, supplementing or replacing the same.
              </li>
              <li>
                &lsquo;<strong>UK GDPR</strong>&rsquo; has the meaning given to it in
                section 3(10) (as supplemented by section 205(4)) of the Data Protection
                Act 2018.
              </li>
              <li>
                The terms &lsquo;<strong>Controller</strong>&rsquo;, &lsquo;
                <strong>Processor</strong>&rsquo;, &lsquo;<strong>Data Subject</strong>
                &rsquo;, &lsquo;<strong>Personal Data</strong>&rsquo;, &lsquo;
                <strong>Personal Data Breach</strong>&rsquo;, &lsquo;
                <strong>Process</strong>
                &rsquo; (including &lsquo;<strong>processing</strong>&rsquo;) and &lsquo;
                <strong>Supervisory Authority</strong>&rsquo; have the meanings given to
                them in the Data Protection Laws.&nbsp;
              </li>
              <li>
                Any other terms used, but not defined in, this DPA will have the meanings
                given to them in the Principal Agreement.
              </li>
            </ol>
          </li>

          <li>
            <strong>RELATIONSHIP OF THE PARTIES</strong>

            <ol>
              <li>
                The parties acknowledge that for the purposes of the Data Protection Laws,
                Controller is the Controller and Revolut is a Processor in respect of the
                In-Scope Personal Data.
              </li>
              <li>
                Both parties will comply with all applicable requirements of the Data
                Protection Laws. This obligation is in addition to, and does not relieve,
                remove or replace, a party's obligations under the Data Protection Laws.
              </li>
              <li>
                Annex A (<em>Data Processing Information</em>) describes the subject
                matter, duration, nature and purpose of the processing, as well as the
                Personal Data categories and Data Subject types which Revolut may process
                to fulfil the Processing Purpose.
              </li>
              <li>
                Revolut will not process In-Scope Personal Data for any purpose other than
                the Processing Purpose.&nbsp;
              </li>
            </ol>
          </li>

          <li>
            <strong>CONTROLLER OBLIGATIONS</strong>

            <ol>
              <li>
                Without prejudice to the generality of this DPA, Controller warrants that
                it has all necessary appropriate consents and notices in place to enable
                the lawful transfer of In-Scope Personal Data to Revolut for the duration
                and purposes of this DPA and the Principal Agreement.
              </li>
              <li>
                Controller will be fully responsible for ensuring that it has an
                appropriate legal basis for any collection or processing of personal data
                of employees or prospective employees through the Services.
              </li>
            </ol>
          </li>

          <li>
            <strong>PROCESSOR OBLIGATIONS</strong>

            <ol>
              <li>
                Without prejudice to the generality of this DPA, Revolut will, in relation
                to any In-Scope Personal Data processed for the Processing Purpose under
                this DPA:
                <ol>
                  <li>
                    process In-Scope Personal Data only on the instructions of the
                    Controller (as set out in this Agreement) unless Revolut is required
                    by the laws of the United Kingdom, any member state of the European
                    Union or by the laws of the European Union applicable to Revolut
                    (&ldquo;
                    <strong>Applicable Laws</strong>
                    &rdquo;) to otherwise process such Personal Data. Where Revolut relies
                    on Applicable Laws as the basis for processing In-Scope Personal Data,
                    Revolut will promptly notify Controller of this before performing the
                    processing required by the Applicable Laws, unless those Applicable
                    Laws prohibit Revolut from doing so;
                  </li>
                  <li>
                    notify Controller without undue delay if it considers that any of
                    Controller&rsquo;s instructions conflict with Data Protection Laws;
                  </li>
                  <li>
                    implement appropriate technical and organisational measures, including
                    the measures set out in Annex C<em> </em>(
                    <em>Technical and Organisational Measures</em>), to protect against
                    unauthorised or unlawful processing of In-Scope Personal Data and
                    against accidental loss or destruction of, or damage to, In-Scope
                    Personal Data, appropriate to the harm that might result from the
                    unauthorised or unlawful processing or accidental loss, destruction or
                    damage and the nature of In-Scope Personal Data, having regard to the
                    state of technological development and the cost of implementing any
                    measures;&nbsp;
                  </li>
                  <li>
                    ensure that all personnel who have access to and/or process In-Scope
                    Personal Data are obliged to keep the In-Scope Personal Data
                    confidential;
                  </li>
                  <li>
                    to the extent necessary and technically possible, assist Controller in
                    responding to any request from a Data Subject and in ensuring
                    compliance with Controller&rsquo;s obligations under Data Protection
                    Laws with respect to data security, impact assessments and
                    consultations with Supervisory Authorities or regulators;
                  </li>
                  <li>
                    notify Controller without undue delay after becoming aware of a
                    Personal Data Breach, and provide Controller with such assistance as
                    it may reasonably require to comply with its obligations in respect of
                    such Personal Data Breach under Data Protection Laws;
                  </li>
                  <li>
                    at the written direction of the Controller, delete or return In-Scope
                    Personal Data and copies thereof to Controller on termination of the
                    Principal Agreement unless required by Applicable Laws to store the
                    In-Scope Personal Data. If Controller does not instruct Revolut to
                    either delete or return the In-Scope Personal Data upon the
                    termination of the Principal Agreement, Revolut will delete the
                    In-Scope Personal Data within 90 days following the date of
                    termination;&nbsp;
                  </li>
                  <li>
                    maintain complete and accurate records and information to demonstrate
                    its compliance with this DPA, and provide Controller with a copy of
                    such information upon request;
                  </li>
                  <li>
                    in the event Controller does not consider the information provided
                    pursuant to paragraph 4.1.8 to be sufficient to determine
                    Revolut&rsquo;s compliance with its obligations under this DPA, allow
                    Controller or its designated auditor (provided such auditor is not a
                    competitor of Revolut) to conduct audits of Revolut&rsquo;s
                    facilities, provided that:
                    <ol>
                      <li>
                        Controller must give at least 30 days&rsquo; prior written notice
                        of any such audit;
                      </li>
                      <li>
                        any audit shall be conducted during normal business hours and
                        Controller shall minimise the disruption caused by the audit to
                        the greatest extent possible;
                      </li>
                      <li>Controller shall bear the costs involved in the audit; and</li>
                      <li>
                        Controller shall only be permitted to carry out an audit once per
                        calendar year during the Subscription Term.
                      </li>
                    </ol>
                  </li>
                </ol>
              </li>
            </ol>
          </li>

          <li>
            <strong>SUB-PROCESSORS</strong>

            <ol>
              <li>
                Controller grants its general authorisation to Revolut to appoint third
                parties to process In-Scope Personal Data on Revolut&rsquo;s behalf for
                the Processing Purpose (&ldquo;<strong>Sub-Processor</strong>&rdquo;). If
                Revolut wishes to appoint or change any Sub-Processor, Revolut will notify
                the Controller in advance thereby giving Revolut a reasonable opportunity
                to object to such appointment or change. For the avoidance of doubt,
                Revolut consents to Revolut appointing the Sub-Processor(s) listed in
                Annex B (<em>Approved Sub-Processors</em>) as at the date of this DPA
                <strong>
                  <em>.&nbsp;</em>
                </strong>
              </li>
              <li>
                In respect of each Sub-Processor, Revolut will:
                <ol>
                  <li>
                    enter into a written agreement incorporating terms which are
                    substantially similar to those set out in this DPA; and&nbsp;
                  </li>
                  <li>
                    remain fully liable to the Controller for all acts or omissions of the
                    Sub-Processor.
                  </li>
                </ol>
              </li>
            </ol>
          </li>

          <li>
            <strong>COSTS</strong>
            <p>
              Revolut will be entitled to charge Controller reasonable costs in connection
              with any assistance it provides to Controller upon Controller&rsquo;s
              request pursuant to paragraph 4.1.5.
            </p>
          </li>

          <li>
            <strong>ORDER OF PRECEDENCE</strong>
            <p>
              In the event of any conflict between any provision of this DPA and any
              provision of the Principal Agreement, the provision of this DPA will
              prevail.
            </p>
          </li>

          <li>
            <strong>LIMITATION OF LIABILITY</strong>

            <p>
              Revolut&rsquo;s liability in connection with this DPA will be subject to the
              same exclusions and caps on liability as set out in the Principal Agreement.
            </p>
          </li>
        </ol>

        <h3>ANNEX A</h3>
        <p>
          <strong>Data Processing Information</strong>
        </p>

        <Group>
          <DetailsCell>
            <DetailsCell.Title>
              Subject matter and purpose of the processing
            </DetailsCell.Title>
            <DetailsCell.Note>
              Revolut will only process the In-Scope Personal Data for the provision of
              the Revolut People services (&ldquo;
              <strong>Processing Purpose</strong>
              &rdquo;).
            </DetailsCell.Note>
          </DetailsCell>
          <DetailsCell>
            <DetailsCell.Title>Nature of processing</DetailsCell.Title>
            <DetailsCell.Note>
              The specific processing activities carried out by Revolut to perform the
              Processing Purpose may include:
              <ul>
                <li>data hosting;</li>
                <li>transmission;</li>
                <li>de-bugging (technical analysis and remediation);</li>
                <li>structuring;</li>
                <li>analysis and research.</li>
              </ul>
            </DetailsCell.Note>
          </DetailsCell>
          <DetailsCell>
            <DetailsCell.Title>Duration of the processing</DetailsCell.Title>
            <DetailsCell.Note>The term of the Principal Agreement.</DetailsCell.Note>
          </DetailsCell>
          <DetailsCell>
            <DetailsCell.Title>Types of Personal Data</DetailsCell.Title>
            <DetailsCell.Note>
              Revolut may process all types of Personal Data uploaded to Revolut People by
              the Controller when performing the Processing Purpose, including but not
              limited to names, surnames, contact details, diversity data, salary
              information and other employment details.&nbsp;(collectively, &ldquo;
              <strong>In-Scope Personal Data</strong>&rdquo;)
            </DetailsCell.Note>
          </DetailsCell>
          <DetailsCell>
            <DetailsCell.Title>Categories of Data Subjects</DetailsCell.Title>
            <DetailsCell.Note>
              Data Subjects include Users (employees, prospective employees and job
              candidates of Controller)
            </DetailsCell.Note>
          </DetailsCell>
        </Group>
        <h3>ANNEX B</h3>
        <p>
          <strong>Approved Sub-Processors</strong>
        </p>

        <Group>
          <DetailsCell>
            <DetailsCell.Title>Sub-Processor name</DetailsCell.Title>
            <DetailsCell.Content>GCP (Google Cloud)</DetailsCell.Content>
          </DetailsCell>
          <DetailsCell>
            <DetailsCell.Title>Location of processing</DetailsCell.Title>
            <DetailsCell.Content>Processing done in, UK (West2C)</DetailsCell.Content>
          </DetailsCell>
          <DetailsCell>
            <DetailsCell.Title>Description of processing</DetailsCell.Title>
            <DetailsCell.Content>Hosting the product</DetailsCell.Content>
          </DetailsCell>
        </Group>

        <h3>ANNEX C</h3>
        <p>
          <strong>Technical and Organisational Measures</strong>
        </p>
        <p>
          The technical and organisational measures implemented by Revolut to protect the
          In-Scope Personal Data will include the following:
        </p>
        <p>
          <strong>Security Programme and Policies</strong>
        </p>
        <p>
          Revolut maintains a security programme which includes documented policies which
          are updated and approved annually and communicated to appropriate personnel,
          assigned roles and responsibilities for security management and activities, and
          ongoing monitoring and review of security controls, systems and procedures.
        </p>
        <p>
          <strong>Information Security Controls</strong>
        </p>
        <p>
          Revolut has implemented a comprehensive suite of preventative, detective and
          corrective controls to protect the confidentiality, integrity and availability
          of customer data, these cover:
        </p>
        <ul>
          <li>Human resource security</li>
          <li>Asset management</li>
          <li>Access control management</li>
          <li>Physical &amp; environmental controls</li>
          <li>Vulnerability management</li>
          <li>Logging &amp; monitoring</li>
          <li>Anti-malware protections</li>
          <li>Network security</li>
          <li>Software development &amp; change management</li>
          <li>Third party risk management</li>
          <li>Incident management</li>
          <li>Operational resilience &amp; business continuity testing</li>
        </ul>
        <p>
          <strong>Governance &amp; Compliance</strong>
        </p>
        <p>
          Revolut maintains a programme of internal and external audit &amp; assurance to
          ensure continuing compliance with over-arching regulatory and industry
          requirements. Revolut has completed a SOC2 Type 2 audit to provide an
          independent report on the operating effectiveness of all of its controls in
          respect to Security, Confidentiality and Availability. Revolut maintains a PCI
          DSS and PCI 3DS compliant environment to ensure the security of card-handling
          processes &amp; systems.
        </p>
      </Box>
    </Flex>
  )
}
