import { useGetSelectors } from '@src/api/selectors'
import { EntityTypes, selectorKeys } from '@src/constants/api'
import useIsCommercial from '@src/hooks/useIsCommercial'
import { FilterByInterface } from '@src/interfaces/data'
import { GoalContentType } from '@src/interfaces/goals'

import { useMemo } from 'react'

export const useGetGoalContentTypeFilter = (
  entityType?: EntityTypes,
): FilterByInterface | null => {
  const isCommercial = useIsCommercial()
  const { data } = useGetSelectors<GoalContentType>(
    selectorKeys.goal_content_types,
    undefined,
    {
      cacheTime: Infinity,
      staleTime: Infinity,
    },
  )

  const filter = useMemo(() => {
    const getTypeFilter = (type: GoalContentType['model']): FilterByInterface | null => {
      if (!data || !entityType) {
        return null
      }
      const typeObject = data.find(({ model }) => model === type)

      return typeObject
        ? {
            columnName: 'content_type_id',
            filters: [{ id: typeObject.id, name: typeObject.model }],
            nonResettable: true,
            nonInheritable: true,
          }
        : null
    }

    switch (entityType) {
      case EntityTypes.company:
      case EntityTypes.companyV2:
        return {
          columnName: isCommercial ? 'is_company' : 'is_top_level',
          filters: [{ name: 'True', id: 'True' }],
          nonResettable: true,
          nonInheritable: true,
        }

      case EntityTypes.team:
      case EntityTypes.teams:
        return getTypeFilter('teams')

      case EntityTypes.department:
        return getTypeFilter('department')

      case EntityTypes.employee:
      case EntityTypes.employees:
        return getTypeFilter('employees')

      default:
        return null
    }
  }, [data, entityType])

  return filter
}
