import React from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Box, MoreBar } from '@revolut/ui-kit'

import { EmployeeInterface } from '@src/interfaces/employees'
import { selectUser } from '@src/store/auth/selectors'
import { useGetDocumentsSettings } from '@src/api/settings'
import { useGetSelectors } from '@src/api/selectors'
import { selectorKeys } from '@src/constants/api'
import { PermissionTypes } from '@src/store/auth/types'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { hasDocumentManagerPermissions } from '../common'
import { RequestDocumentButton } from './RequestDocumentButton'

type Props = {
  data: EmployeeInterface
  showSecondary?: boolean
}
export const ActionButtons = ({ data, showSecondary }: Props) => {
  const user = useSelector(selectUser)
  const { data: documentsSettings } = useGetDocumentsSettings()

  const { categoryId } = useParams<{ categoryId: string }>()
  const { data: documentCategories, isLoading: isLoadingCategories } = useGetSelectors(
    selectorKeys.document_categories,
  )
  const selectedCategory = documentCategories?.find(
    category => String(category.id) === categoryId,
  )

  const canAddDocuments = !!data?.field_options?.permissions?.includes(
    PermissionTypes.UploadDocuments,
  )
  const handleAddDocument = () => {
    navigateTo(pathToUrl(ROUTES.FORMS.DOCUMENT.GENERAL, { employeeId: data.id }), {
      initialValues: {
        employee: { id: data.id, name: data.full_name },
        issuer: { id: user.id, name: user.full_name },
        category: selectedCategory,
      },
    })
  }

  const showRequestGeneratedDocuments = documentsSettings?.enable_document_requests
  const showGenerateFromTemplate =
    documentsSettings?.enable_document_generation_from_templates &&
    hasDocumentManagerPermissions(data)

  const handleGenerate = () => {
    navigateTo(
      pathToUrl(ROUTES.FORMS.DOCUMENT_GENERATE.GENERAL, { employeeId: data.id }),
      {
        initialValues: {
          generated_by: { id: user.id, name: user.full_name },
        },
      },
    )
  }

  return (
    <Box>
      <MoreBar>
        {canAddDocuments && (
          <MoreBar.Action
            useIcon="Plus"
            variant="accent"
            onClick={handleAddDocument}
            disabled={isLoadingCategories}
          >
            Add document
          </MoreBar.Action>
        )}
        {showSecondary && (
          <>
            {showRequestGeneratedDocuments && <RequestDocumentButton data={data} />}
            {showGenerateFromTemplate && (
              <MoreBar.Action useIcon="Document" onClick={handleGenerate}>
                Generate
              </MoreBar.Action>
            )}
          </>
        )}
      </MoreBar>
    </Box>
  )
}
