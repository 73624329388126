import {
  VStack,
  Cell,
  Input,
  Checkbox,
  LineClamp,
  Icon,
  HStack,
  IconName,
  Grid,
  IconButton,
  Token,
} from '@revolut/ui-kit'
import SideBar from '@src/components/SideBar/SideBar'
import React, { useState, useMemo } from 'react'
import { matchSorter } from 'match-sorter'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { InternalDashboardInterface } from '@src/interfaces/analyticsDashboards'
import { useQueriesOptions } from '@src/api/dataAnalytics'
import {
  VisualisationType,
  visualisationTypeIconMap,
} from '@src/pages/Forms/QueryForm/components/VisualisationSidebar/common'
import { InternalLink } from '@components/InternalLink/InternalLink'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { Statuses } from '@src/interfaces'

interface MagicLinkInstructionsSidebarProps {
  isOpen: boolean
  onClose: () => void
  onRemove: (id: string) => void
  onAdd: (id: string) => void
}

const getIcon = (type?: VisualisationType): IconName => {
  if (type) {
    return visualisationTypeIconMap[type]
  }
  return visualisationTypeIconMap.line
}

export const MetricsSidebar = ({
  isOpen,
  onClose,
  onRemove,
  onAdd,
}: MagicLinkInstructionsSidebarProps) => {
  const { values } = useLapeContext<InternalDashboardInterface>()
  const [querySearch, setQuerySearch] = useState('')
  const queries = useQueriesOptions([
    {
      filters: [{ id: Statuses.active, name: Statuses.active }],
      columnName: 'status',
    },
  ])

  const options = useMemo(() => {
    if (!queries || querySearch === '') {
      return queries
    }

    return matchSorter(queries, querySearch, {
      keys: ['name', 'description'],
    })
  }, [querySearch, queries])

  return (
    <SideBar
      title="Add metric"
      subtitle="Select queries you want to see on the dashboard"
      isOpen={isOpen}
      onClose={onClose}
      useLayout
    >
      <VStack space="s-16">
        <Input
          label="Search query"
          value={querySearch}
          onChange={e => setQuerySearch(e.currentTarget.value)}
        />
        <Cell>
          <VStack space="s-16">
            {options?.map(option => (
              <Grid columns="1fr 16px" columnGap="s-16" key={option.id}>
                <Checkbox
                  onChange={e =>
                    e.currentTarget.checked
                      ? onAdd(String(option.id))
                      : onRemove(String(option.id))
                  }
                  checked={
                    values.layout &&
                    values.layout.some(item => item.query.id === String(option.id))
                  }
                >
                  <Checkbox.Label>
                    <HStack align="center" gap="s-8">
                      <Icon
                        name={
                          option.visualisation_type === 'chart'
                            ? getIcon(option.visualisation?.chartType)
                            : '16/ListBullet'
                        }
                        size={16}
                        color={Token.color.blue}
                      />
                      {option.name}
                    </HStack>
                  </Checkbox.Label>
                  {option.description ? (
                    <Checkbox.Description>
                      <LineClamp max={1}>{option.description}</LineClamp>
                    </Checkbox.Description>
                  ) : null}
                </Checkbox>
                <IconButton
                  useIcon="LinkExternal"
                  color={Token.color.blue}
                  size={16}
                  use={InternalLink}
                  target="_blank"
                  to={pathToUrl(ROUTES.FORMS.QUERY.GENERAL, { id: option.id })}
                />
              </Grid>
            ))}
          </VStack>
        </Cell>
      </VStack>
    </SideBar>
  )
}
