import React from 'react'
import { Box, HStack, Icon, Subheader } from '@revolut/ui-kit'
import AdjustableTable from '@components/Table/AdjustableTable'
import { useTable } from '@components/Table/hooks'
import { AxiosResponse } from 'axios'
import { GetRequestData } from '@src/interfaces'
import { RowInterface } from '@src/interfaces/data'
import { RecruiterLocationInterface } from '@src/interfaces/jobPosting'
import {
  recruiterLocationLocations,
  recruiterLocationName,
} from '@src/constants/columns/jobDescription'
import Tooltip from '@components/Tooltip/Tooltip'
import { Text } from '@revolut/ui-kit'
import { TableNames } from '@src/constants/table'

type Props = {
  data?: RecruiterLocationInterface[]
}

const row: RowInterface<RecruiterLocationInterface> = {
  cells: [
    {
      ...recruiterLocationName,
      width: 300,
    },
    {
      ...recruiterLocationLocations,
      width: 600,
    },
  ],
}

const RecruitersAssigned = ({ data = [] }: Props) => {
  const table = useTable<RecruiterLocationInterface>({
    getItems: () =>
      Promise.resolve({
        data: {
          results: data || [],
        },
      } as AxiosResponse<GetRequestData<RecruiterLocationInterface>>),
  })

  if (!data.length) {
    return null
  }

  return (
    <Box mt="s-16">
      <Subheader variant="nested">
        <Subheader.Title>
          <HStack align="center" space="s-8">
            <Text>Recruiters assigned</Text>
            <Tooltip
              placement="top"
              text="Recruiters are assigned based on automation rules. To change a recruiter, check automation rule set for the specific location"
            >
              <Icon name="InfoOutline" size={16} />
            </Tooltip>
          </HStack>
        </Subheader.Title>
      </Subheader>
      <AdjustableTable
        name={TableNames.RecruitersAssigned}
        hideCount
        row={row}
        {...table}
      />
    </Box>
  )
}

export default RecruitersAssigned
