import React, { useEffect } from 'react'
import PageLoading from '@components/PageLoading/PageLoading'
import { GRANT_EMAIL_PERMISSIONS_REDIRECT_PARAM } from '@src/constants/api'
import {
  connectGoogleEmail,
  connectGoogleCalendar,
  OfficeSuiteProviderSourceType,
  OfficeSuiteProviderType,
  connectMicrosoftEmail,
  connectMicrosoftCalendar,
  connectGoogleWorkspace,
} from '@src/api/officeSuiteProvider'
import * as Sentry from '@sentry/react'
import { LocalStorageKeys } from '@src/store/auth/types'
import { UNKNOWN_ERROR } from '@src/pages/Forms/QueryForm/constants'
import { workspaceLocalStorage } from '@src/features/Workspaces/workspaceLocalStorage'

declare global {
  interface Window {
    createEmployeeUploadFromGoogleWorkspace?: () => void
  }
}

const GrantPermissionsRedirect = () => {
  useEffect(() => {
    const validateToken = async () => {
      const currentURL = new URL(window.location.href)
      const token = currentURL.searchParams.get('code')
      const state = currentURL.searchParams.get('state')
      let source: OfficeSuiteProviderSourceType | undefined
      let type: OfficeSuiteProviderType | undefined

      try {
        const parsedState: unknown = state && JSON.parse(state)

        if (typeof parsedState === 'object' && parsedState !== null) {
          if ('source' in parsedState) {
            source = parsedState.source as OfficeSuiteProviderSourceType | undefined
          }

          if ('type' in parsedState) {
            type = parsedState.type as OfficeSuiteProviderType | undefined
          }
        }
      } catch (e) {
        Sentry.captureException(e)
        source = undefined
        type = undefined
      }

      try {
        if (token) {
          switch (type) {
            case 'googleEmail': {
              await connectGoogleEmail(
                token,
                GRANT_EMAIL_PERMISSIONS_REDIRECT_PARAM,
                source,
              )
              break
            }

            case 'googleCalendar': {
              await connectGoogleCalendar(token, GRANT_EMAIL_PERMISSIONS_REDIRECT_PARAM)
              break
            }

            case 'googleWorkspace': {
              await connectGoogleWorkspace(token, GRANT_EMAIL_PERMISSIONS_REDIRECT_PARAM)
              break
            }

            case 'microsoftEmail': {
              await connectMicrosoftEmail(
                token,
                GRANT_EMAIL_PERMISSIONS_REDIRECT_PARAM,
                source,
              )
              break
            }

            case 'microsoftCalendar': {
              await connectMicrosoftCalendar(
                token,
                GRANT_EMAIL_PERMISSIONS_REDIRECT_PARAM,
              )
              break
            }

            default: {
              Sentry.captureMessage(
                '[GrantPermissionsRedirect] type is required but not defined',
              )
            }
          }
        }
      } catch (e) {
        Sentry.captureException(e, {
          tags: {
            type,
          },
        })

        // save the error in the localstorage as we close the window
        workspaceLocalStorage.setItem(
          LocalStorageKeys.GOOGLE_PERMISSIONS_ERROR,
          JSON.stringify({
            type,
            message: e?.response?.data?.[0] || UNKNOWN_ERROR,
          }),
        )
      } finally {
        if (window && window.opener && window.opener.location) {
          if (type === 'googleWorkspace') {
            window.opener?.focus?.()
            window.opener.createEmployeeUploadFromGoogleWorkspace?.()
          } else {
            window.opener.location.reload()
          }
          window.close()
        }
      }
    }

    validateToken()
  }, [])

  return <PageLoading />
}

export default GrantPermissionsRedirect
