import React, { useState, useEffect } from 'react'
import {
  Flex,
  Text,
  HStack,
  Cell,
  VStack,
  Input,
  RadioGroup,
  ColorSelector,
  Token,
  ActionButton,
  Item,
  IconButton,
  Avatar,
  Bar,
  Checkbox,
} from '@revolut/ui-kit'
import {
  QueryColumnInterface,
  ReportHighlightInterface,
  ReportHighlightColor,
  ReportHighlightColors,
  ConditionTypes,
  ConditionType,
} from '@src/interfaces/dataAnalytics'
import RadioSelectInput, {
  RadioSelectOption,
} from '@components/Inputs/RadioSelectInput/RadioSelectInput'
import uniqueId from 'lodash/uniqueId'
import { valueColorMap } from '../../common'
import { z, ZodFormattedError } from 'zod'

interface HighlightCellProps {
  options: RadioSelectOption<QueryColumnInterface>[]
  onChange: (data: ReportHighlightInterface) => void
  onOpen: () => void
  onClose: () => void
  onRemove: () => void
  isEditing?: boolean
  canOpen?: boolean
  data?: ReportHighlightInterface
}

interface Props {
  options: RadioSelectOption<QueryColumnInterface>[]
  highlightSettings: ReportHighlightInterface[] | null
  setHighlightSettings: React.Dispatch<
    React.SetStateAction<ReportHighlightInterface[] | null>
  >
}

const OPTION_HIGHLIGHT_ROW = {
  id: 'row',
  name: 'Row',
} as ReportHighlightInterface['target']
const OPTION_HIGHLIGHT_VALUE = {
  id: 'value',
  name: 'Value',
} as ReportHighlightInterface['target']

interface ItemInterface {
  id: string
  editing: boolean
  data?: ReportHighlightInterface
}

const reportHighlightSchema: z.ZodType<ReportHighlightInterface> = z.object({
  condition: z
    .object({
      key: z.string(),
      value: z.string().min(1),
      condition_type: z.enum(ConditionTypes),
    })
    .required(),
  target: z.object({ id: z.enum(['row', 'value']), name: z.string() }).required(),
  color: z.enum(ReportHighlightColors),
})

const comparisonOptions: { label: string; value: { id: ConditionType; name: string } }[] =
  [
    {
      label: 'Equals',
      value: { id: 'eq', name: 'Equals' },
    },
    {
      label: 'Is greater than',
      value: { id: 'gt', name: 'Is greater than' },
    },
    {
      label: 'Is less than',
      value: { id: 'lt', name: 'Is less than' },
    },
  ]

const HighlightCell = ({
  options,
  data,
  onChange,
  onOpen,
  onClose,
  onRemove,
  canOpen = false,
  isEditing = false,
}: HighlightCellProps) => {
  const [value, setValue] = useState(data?.condition.value)
  const [column, setColumn] = useState(
    options.find(o => o.value.name === data?.condition.key)?.value,
  )
  const [conditionType, setConditionType] = useState(
    data?.condition?.condition_type || 'eq',
  )
  const [color, setColor] = useState(data?.color || 'green')
  const [target, setTarget] = useState<ReportHighlightInterface['target']>(
    data?.target || OPTION_HIGHLIGHT_ROW,
  )
  const [errors, setErrors] = useState<
    ZodFormattedError<ReportHighlightInterface> | undefined
  >(undefined)

  const handleSave = () => {
    const item = {
      condition: { key: column?.name, value, condition_type: conditionType },
      target,
      color,
    }

    const result = reportHighlightSchema.safeParse(item)

    if (!result.success) {
      setErrors(result.error.format())
      return
    }

    setErrors(undefined)
    onChange(result.data)

    onClose()
  }

  const handleCancel = () => {
    if (!data) {
      onRemove()
      return
    }
    setValue(data?.condition.value)
    setColumn(options.find(o => o.value.name === data?.condition.key)?.value)
    setColor(data?.color)
    setTarget(data?.target)
    onClose()
  }

  if (!isEditing) {
    return (
      <Item use="button" onClick={onOpen} disabled={!canOpen}>
        <Item.Prefix>
          <IconButton
            aria-label="Remove"
            color={Token.color.red}
            onClick={e => {
              e.stopPropagation()
              onRemove()
            }}
            size={16}
            useIcon={'MinusCircle'}
          />
        </Item.Prefix>
        <Item.Content>
          <Item.Title>
            When a value in <Text fontWeight="bold">{column?.name}</Text> is equal to{' '}
            <Text fontWeight="bold">{value}</Text>, highlight the{' '}
            {target?.id === 'row' ? 'row' : 'value'} this colour:
          </Item.Title>
        </Item.Content>
        <Item.Side>
          <Avatar color={valueColorMap[color!]} />
        </Item.Side>
      </Item>
    )
  }

  return (
    <Cell>
      <VStack gap="s-16" width="100%">
        <Text>When value in this column:</Text>
        <RadioSelectInput
          clearable
          label="Column"
          options={options}
          value={column}
          onChange={val => setColumn(val || undefined)}
          hasError={!!errors?.condition?.key && !column}
        />
        <RadioSelectInput
          label="Condition"
          options={comparisonOptions}
          value={comparisonOptions.find(c => c.value.id === conditionType)?.value}
          onChange={val => setConditionType(val?.id || 'eq')}
          hasError={!!errors?.condition?.condition_type && !conditionType}
        />
        <Input
          label="Add a value"
          value={value}
          onChange={e => setValue(e.currentTarget.value)}
          aria-invalid={!!errors?.condition?.value && !value}
        />
        <Checkbox
          value="foo"
          onChange={event =>
            setTarget(
              event.target.checked ? OPTION_HIGHLIGHT_ROW : OPTION_HIGHLIGHT_VALUE,
            )
          }
          checked={target?.id === 'row'}
        >
          <Text>Highlight entire row</Text>
        </Checkbox>
        <Text>
          then highlight the {target?.id === 'row' ? 'row' : 'value'} this colour
        </Text>
        <RadioGroup<ReportHighlightColor>
          onChange={val => {
            if (val) {
              setColor(val)
            }
          }}
          value={color}
          aria-invalid={!!errors?.color}
        >
          {group => (
            <HStack space="s-16">
              <ColorSelector
                bg={valueColorMap.green}
                aria-label="green"
                {...group.getInputProps({ value: 'green' })}
              />
              <ColorSelector
                bg={valueColorMap.yellow}
                aria-label="yellow"
                {...group.getInputProps({ value: 'yellow' })}
              />
              <ColorSelector
                bg={valueColorMap.orange}
                aria-label="orange"
                {...group.getInputProps({ value: 'orange' })}
              />
              <ColorSelector
                bg={valueColorMap.red}
                aria-label="red"
                {...group.getInputProps({ value: 'red' })}
              />
            </HStack>
          )}
        </RadioGroup>
        <Bar alignSelf="flex-end">
          <ActionButton
            useIcon="16/Cross"
            variant="negative"
            size="xs"
            onClick={handleCancel}
          >
            Cancel
          </ActionButton>
          <ActionButton useIcon="16/Check" size="xs" onClick={handleSave}>
            Save
          </ActionButton>
        </Bar>
      </VStack>
    </Cell>
  )
}

export const Highlight = ({
  options,
  highlightSettings,
  setHighlightSettings,
}: Props) => {
  const [items, setItems] = useState<ItemInterface[]>(
    highlightSettings?.map(data => ({ id: uniqueId(), editing: false, data })) || [],
  )

  const handleChange = (id: string, data: ReportHighlightInterface) => {
    const idx = items.findIndex(i => i.id === id)
    setItems(curr => {
      return [
        ...curr.slice(0, idx),
        {
          ...curr[idx],
          editing: false,
          data,
        },
        ...curr.slice(idx + 1),
      ]
    })
  }

  const setEditing = (id?: string) => {
    setItems(curr =>
      curr.map(item =>
        item.id === id ? { ...item, editing: true } : { ...item, editing: false },
      ),
    )
  }

  const addItem = () => {
    setItems(curr => [...curr, { id: uniqueId(), editing: true, data: undefined }])
  }

  const removeItem = (id: string) => {
    setItems(curr => {
      return curr!.filter(item => item.id !== id)
    })
  }

  useEffect(() => {
    setHighlightSettings(
      items
        .map(item => item.data)
        .filter(data => data !== undefined) as ReportHighlightInterface[],
    )
  }, [items])

  const isEditing = !!items.find(item => item.editing)

  return (
    <Flex flexDirection="column" gap="s-16">
      <Text>Add conditional row formatting rules below</Text>
      {items?.map(item => (
        <HighlightCell
          options={options}
          key={item.id}
          data={item.data}
          onChange={val => handleChange(item.id, val)}
          onOpen={() => setEditing(item.id)}
          onClose={() => setEditing(undefined)}
          onRemove={() => removeItem(item.id)}
          isEditing={item.editing}
          canOpen={!isEditing}
        />
      ))}

      <Flex justifyContent="center" py="s-8">
        <ActionButton
          useIcon="16/Plus"
          variant="accent"
          size="xs"
          onClick={addItem}
          disabled={isEditing}
        >
          Add new rule
        </ActionButton>
      </Flex>
    </Flex>
  )
}
