import { api, apiWithoutHandling, normalizeCaptchaError } from '@src/api/index'
import { API } from '@src/constants/api'
import {
  ChangePasswordInterface,
  CredentialsLoginInterface,
  HintInterface,
  SendPhoneNumberVerificationCodeInterface,
  VerifyPhoneNumberInterface,
  WhoAmIInterface,
} from '@src/interfaces/auth'
import { useFetch } from '@src/utils/reactQuery'

export const sendMagicLink = (email: string) =>
  apiWithoutHandling.post(`/sendMagicLink`, { email }, undefined, undefined, true)

export const magicLinkLogin = (email: string, token: string) =>
  api.post<{
    employee_id: number
    token: string
    two_factor_authentication_enabled: boolean
  }>(`/magicLinkLogin`, { email, token }, undefined, undefined, true)

export const sendActivationEmail = (id: number | string) =>
  api.post(`${API.ACCOUNT}/activationEmail/${id}`)

export const activateAccount = (token: string) =>
  api.post(
    `${API.ACCOUNT}/activate`,
    {},
    {
      headers: {
        apitoken: window.atob(token),
      },
    },
    undefined,
    true,
  )

export const emailLoginRequest = (data: { email: string; password: string }) =>
  apiWithoutHandling.post<CredentialsLoginInterface>(
    `/credentialsLogin`,
    data,
    undefined,
    undefined,
    true,
  )

export const setPassword = (
  data: { password: string; retype_password: string },
  token: string,
) =>
  apiWithoutHandling.post(
    `${API.ACCOUNT}/setPassword`,
    data,
    {
      headers: {
        apitoken: window.atob(token),
      },
    },
    undefined,
    true,
  )

export const resetPassword = (data: { email: string }) =>
  apiWithoutHandling
    .post(`${API.ACCOUNT}/resetPassword`, data, undefined, undefined, true)
    .catch(normalizeCaptchaError)

export const useLoginSettings = () =>
  useFetch<{ google_sso_enabled: boolean; credentials_authentication_enabled: boolean }>(
    API.LOGIN_SETTINGS,
    undefined,
    undefined,
    true,
    {
      staleTime: Infinity,
      cacheTime: Infinity,
    },
    true,
  )

export const getEmailHint = (token: string) =>
  api.get<HintInterface>(
    `${API.ACCOUNT}/activate/emailHint`,
    {
      headers: {
        apitoken: token,
      },
    },
    undefined,
    true,
  )

export const getResetHint = (token: string) =>
  api.get<HintInterface>(
    `${API.ACCOUNT}/resetPassword/hint`,
    {
      headers: {
        apitoken: token,
      },
    },
    undefined,
    true,
  )

export const get2FAHint = (token: string) =>
  api.get<HintInterface>(
    `/twoFactorAuthentication/hint`,
    {
      headers: {
        apitoken: token,
      },
    },
    undefined,
    true,
  )

export const submit2FACode = (data: { code: string }, token: string) =>
  apiWithoutHandling.post<{ phone_number: string }>(
    `/twoFactorAuthentication`,
    data,
    {
      headers: {
        apitoken: token,
      },
    },
    undefined,
    true,
  )

export const resend2FACode = (token: string) =>
  apiWithoutHandling.post<{ phone_number: string; code?: string }>(
    `/sendTwoFactorAuthenticationCode`,
    undefined,
    {
      headers: {
        apitoken: token,
      },
    },
    undefined,
    true,
  )

export const sendPhoneNumberVerificationCode = (
  data: VerifyPhoneNumberInterface,
  token: string,
) =>
  apiWithoutHandling.post<SendPhoneNumberVerificationCodeInterface>(
    `/twoFactorAuthentication/sendPhoneNumberVerificationCode`,
    data,
    {
      headers: {
        apitoken: token,
      },
    },
    undefined,
    true,
  )

export const verifyPhoneNumber = (data: { code: string }, token: string) =>
  apiWithoutHandling.post<{ code: string }>(
    `/twoFactorAuthentication/phoneNumberVerification`,
    data,
    {
      headers: {
        apitoken: token,
      },
    },
    undefined,
    true,
  )

export const getWSToken = () =>
  apiWithoutHandling.get<{ wss_token: string }>('/wss_token')

export const changePassword = (data: ChangePasswordInterface) =>
  apiWithoutHandling.post(`${API.ACCOUNT}/changePassword`, data)

export const findWorkspace = (data: { email: string }) =>
  apiWithoutHandling
    .post(`/userTenantInfoReminder`, data, undefined, 'v2')
    .catch(normalizeCaptchaError)

export const useGetWhoAmI = (enabled: boolean) =>
  useFetch<WhoAmIInterface>(
    enabled ? API.WHOAMI : null,
    undefined,
    undefined,
    undefined,
    {
      staleTime: Infinity,
      cacheTime: Infinity,
    },
    true,
  )
