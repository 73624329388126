import React from 'react'
import KPIPage from '@src/pages/Forms/TeamForm/KPI/KPI'
import { useTable } from '@components/Table/hooks'
import { KpiInterface } from '@src/interfaces/kpis'
import { FilterByInterface, SORT_DIRECTION, Stats } from '@src/interfaces/data'
import { kpisRequests } from '@src/api/kpis'
import { TeamInterface } from '@src/interfaces/teams'
import { getBaseKpiFilters } from '@src/features/KPI'

interface StructureProps {
  data: TeamInterface
}

const getKpiFilters = (data: TeamInterface): FilterByInterface[] => [
  ...getBaseKpiFilters(),
  {
    filters: [{ name: data.id.toString(), id: data.id }],
    columnName: 'team__id',
    nonResettable: true,
    nonInheritable: true,
  },
]

export const kpiSorting = [
  {
    sortBy: 'weight',
    direction: SORT_DIRECTION.ASC,
    nonResettable: true,
  },
]

const KPI = ({ data }: StructureProps) => {
  const table = useTable<KpiInterface, Stats>(
    kpisRequests,
    getKpiFilters(data),
    kpiSorting,
  )

  return (
    <KPIPage
      data={data}
      table={table}
      warnAboutMandatoryKPIs={!!table?.stats?.mandatory_kpi_incomplete}
    />
  )
}

export default KPI
