import {
  ActionButton,
  DetailsCell,
  ErrorWidget,
  Flex,
  HStack,
  Icon,
  IconName,
  Token,
  VStack,
  Widget,
  Text,
} from '@revolut/ui-kit'
import { CellWithItem } from '@src/pages/Forms/GoalForm/common/CellWithItem'
import React, { PropsWithChildren } from 'react'

type BaseWidgetProps = {
  icon: IconName
  title: string
  description?: string
  preferSideAction?: boolean
  emptyState?: {
    description: string
    icon?: IconName
  }
  action?: { handler: () => void; label: string; icon?: IconName; disabled?: boolean }
  variant?: 'preview' | 'form'
}

export const BaseWidget = (props: PropsWithChildren<BaseWidgetProps>) => {
  const {
    icon,
    title,
    description,
    preferSideAction = false,
    children,
    emptyState,
    action,
    variant = 'form',
  } = props

  return variant === 'form' ? (
    <CellWithItem icon={icon} title={title} description={description}>
      {children ? (
        <VStack space="s-16">
          {!!action && (
            <ActionButton
              onClick={action?.handler}
              disabled={action.disabled}
              useIcon={action.icon || '16/Plus'}
            >
              {action.label}
            </ActionButton>
          )}
          {children}
        </VStack>
      ) : (
        <Widget>
          <Flex
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            p="s-24"
          >
            <Icon name={emptyState?.icon} color={Token.color.greyTone20} />
            <ErrorWidget.Description color={Token.color.greyTone20}>
              {emptyState?.description}
            </ErrorWidget.Description>
            {!preferSideAction && !!action && (
              <ErrorWidget.Action onClick={action.handler} disabled={action.disabled}>
                {action.label}
              </ErrorWidget.Action>
            )}
          </Flex>
        </Widget>
      )}
    </CellWithItem>
  ) : (
    <DetailsCell>
      <DetailsCell.Title>
        <HStack space="s-8">
          <Icon name="Map" color={Token.color.greyTone20} />
          <Text variant="h6" color={Token.color.greyTone50}>
            Roadmaps
          </Text>
        </HStack>
      </DetailsCell.Title>
      <DetailsCell.Note>{children}</DetailsCell.Note>
    </DetailsCell>
  )
}
