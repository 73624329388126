import React, {
  useEffect,
  useRef,
  createRef,
  RefObject,
  forwardRef,
  useImperativeHandle,
  useState,
} from 'react'
import 'gridstack/dist/gridstack.min.css'
import 'gridstack/dist/gridstack-extra.min.css'
import { GridStack } from 'gridstack'
import { Box, Token, Flex, StatusWidget } from '@revolut/ui-kit'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { InternalDashboardInterface } from '@src/interfaces/analyticsDashboards'
import { MetricsItem } from '@src/pages/Forms/DataAnalyticsInternalDashboardForm/components/MetricsItem'
import ErrorBoundary from '@src/features/ErrorBoundary/ErrorBoundary'

export interface MetricsGridItemInterface {
  id: string
  x?: number
  y?: number
  w?: number
  h?: number
}

interface GridRefs {
  [id: string]: RefObject<HTMLDivElement>
}

export interface MetricsGridHandle {
  add: (id: string) => void
  remove: (id: string) => void
}

export const MetricsGrid = forwardRef<MetricsGridHandle>((_, ref) => {
  const { values, initialValues } = useLapeContext<InternalDashboardInterface>()
  const [items, setItems] = useState<MetricsGridItemInterface[]>(
    () =>
      initialValues?.layout?.map(val => ({
        id: String(val.query.id),
        x: val.column,
        y: val.row,
        w: val.width,
        h: val.height,
      })) || [],
  )

  const refs = useRef<GridRefs>({})
  const gridRef = useRef<GridStack>()

  if (Object.keys(refs.current).length !== items.length) {
    items.forEach(({ id }) => {
      refs.current[id] = refs.current[id] || createRef()
    })
  }

  useEffect(() => {
    gridRef.current = gridRef.current || GridStack.init({ float: true, column: 12 })
    const grid = gridRef.current
    grid.batchUpdate()
    grid.removeAll(false)
    items.forEach(({ id }) => grid.makeWidget(refs?.current?.[id].current!))

    grid.batchUpdate(false)
    saveLayout()
  }, [items])

  useEffect(() => {
    const grid = gridRef.current
    grid?.on('change', () => {
      saveLayout()
    })
  }, [])

  useImperativeHandle(ref, () => ({
    add(id: string) {
      refs.current[id] = refs.current[id] || createRef()
      setItems(prev => [...prev, { id }])
    },
    remove(id: string) {
      delete refs.current[id]
      setItems(prev => prev.filter(i => i.id !== id))
    },
  }))

  const saveLayout = () => {
    const gridNodes = gridRef.current?.save(false)
    if (Array.isArray(gridNodes)) {
      values.layout = gridNodes.map(node => ({
        query: { id: node.id!, name: node.id! },
        column: node.x || 0,
        row: node.y || 0,
        width: node.w || 1,
        height: node.h || 1,
      }))
    }
  }

  return (
    <div className="grid-stack">
      {items.map(item => {
        let coords = {
          'gs-id': item.id,
          'gs-x': item.x,
          'gs-y': item.y,
          'gs-w': item.w || 4,
          'gs-h': item.h || 4,
        }
        return (
          <div
            ref={refs.current[item.id]}
            key={item.id}
            className={'grid-stack-item'}
            {...coords}
          >
            <div className="grid-stack-item-content">
              <Box
                bg={Token.color.widgetBackground}
                radius="widget"
                width="100%"
                height="100%"
                p="s-16"
              >
                <ErrorBoundary
                  fallback={
                    <Flex
                      width="100%"
                      height="100%"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <StatusWidget>
                        <StatusWidget.Image src="https://assets.revolut.com/assets/3d-images-v2/3D018.png" />
                        <StatusWidget.Title>Failed to load</StatusWidget.Title>
                      </StatusWidget>
                    </Flex>
                  }
                >
                  <MetricsItem queryId={item.id} />
                </ErrorBoundary>
              </Box>
            </div>
          </div>
        )
      })}
    </div>
  )
})
