import React, { useEffect, useState } from 'react'
import { addPipGoals, deletePipGoal, getPipGoals } from '@src/api/pip'
import { SUCCESS_DEFAULT_DURATION } from '@src/constants/notifications'
import {
  JiraIssueInterface,
  ProbationGoalInterface,
} from '@src/interfaces/probationReview'
import { pushNotification } from '@src/store/notifications/actions'
import { NotificationTypes } from '@src/store/notifications/types'
import Loader from '@components/CommonSC/Loader'
import { PageBody } from '@src/components/Page/PageBody'
import Tickets from '@src/pages/Forms/ProbationOverview/ProbationGoals/Tickets'
import GoalsJiraDisabled from '@src/pages/EmployeeProfile/Preview/Performance/Goals/GoalsJiraDisabled'
import { ReviewCycleCategory } from '@src/interfaces/reviewCycles'
import { useGetPerformanceSettings } from '@src/api/performanceSettings'
import { useSelector } from 'react-redux'
import { selectFeatureFlags } from '@src/store/auth/selectors'
import { FeatureFlags } from '@src/store/auth/types'
import { useCanManageProbationPipGoals } from '@src/utils/performance'
import { EmployeeInterface } from '@src/interfaces/employees'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { CycleDetailInterface } from '@src/pages/Forms/PipV2/interfaces'

interface PipGoalsProps {
  data?: EmployeeInterface
  cycleId: string
  employeeId: number
  canSetGoals?: boolean
}

export const PipGoals = ({ data, employeeId, cycleId, canSetGoals }: PipGoalsProps) => {
  const { values } = useLapeContext<CycleDetailInterface>()
  const [tickets, setTickets] = useState<ProbationGoalInterface[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { data: performanceSettings } = useGetPerformanceSettings()
  const featureFlags = useSelector(selectFeatureFlags)
  const pipJiraDisabled = featureFlags.includes(
    FeatureFlags.PipGoalsJiraIntegrationDisabled,
  )
  const jiraIntegrationEnabled =
    performanceSettings?.enable_probation_and_pip_goals_via_jira && !pipJiraDisabled
  const { canManageJiraGoals, canManageNonJiraGoals } =
    useCanManageProbationPipGoals(data)

  const probationCycle = data?.performance_cycles?.find(cycle => cycle.id === cycleId)

  useEffect(() => {
    const fetchTickets = async () => {
      try {
        setIsLoading(true)
        const result = await getPipGoals(employeeId, cycleId)

        if (result.data) {
          setTickets(result.data.results)
        }
      } finally {
        setIsLoading(false)
      }
    }

    fetchTickets()
  }, [cycleId])

  const onDelete = async (id: number) => {
    try {
      setIsSubmitting(true)
      const result = await deletePipGoal(employeeId, id, cycleId)

      if (result.status === 204) {
        pushNotification({
          duration: SUCCESS_DEFAULT_DURATION,
          type: NotificationTypes.success,
          value: 'The goal was deleted.',
        })

        const updated = tickets.filter(item => item.id !== id)
        setTickets(updated)
      }
    } finally {
      setIsSubmitting(false)
    }
  }

  const onChange = async (option?: JiraIssueInterface) => {
    if (!option) {
      return
    }

    try {
      setIsSubmitting(true)
      const result = await addPipGoals(
        employeeId,
        {
          employee: employeeId,
          cycle: {
            id: cycleId,
          },
          jira_issue: +option.id,
          jira_issue_key: option.key,
          jira_issue_type: option.fields.issuetype.name,
          jira_issue_summary: option.fields.summary,
          jira_issue_status: option.fields.status.name,
          jira_issue_duedate: option.fields.duedate,
          jira_issue_updated: option.fields.updated,
        },
        cycleId,
      )

      if (result.data) {
        pushNotification({
          duration: SUCCESS_DEFAULT_DURATION,
          type: NotificationTypes.success,
          value: 'The goal was added.',
        })

        const updated = [...tickets]
        updated.push(result.data)
        setTickets(updated)
      }
    } finally {
      setIsSubmitting(false)
    }
  }

  if (isLoading) {
    return <Loader size="medium" />
  }

  return (
    <PageBody>
      {jiraIntegrationEnabled ? (
        <Tickets
          canEdit={(canSetGoals || canManageJiraGoals) && !isSubmitting}
          onChange={onChange}
          onDelete={onDelete}
          tickets={tickets}
        />
      ) : (
        <GoalsJiraDisabled
          category={ReviewCycleCategory.PIP}
          reviewCycleId={cycleId}
          reviewCycleEndDate={probationCycle?.end_date_time || values?.end_date_time}
          reviewCycleStartDate={
            probationCycle?.start_date_time || values?.start_date_time
          }
          canManageGoals={canSetGoals || canManageNonJiraGoals}
          employeeId={employeeId}
        />
      )}
    </PageBody>
  )
}
