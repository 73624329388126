import React from 'react'
import GenericNotificationTable from '@src/pages/Forms/Notifications/GenericNotificationTable'
import {
  CellTypes,
  FilterType,
  RowInterface,
  TabComponentProps,
} from '@src/interfaces/data'
import { NotificationsInterface } from '@src/interfaces/notifications'
import { selectorKeys } from '@src/constants/api'
import {
  notificationDescription,
  notificationsActionColumn,
  notificationsDeadline,
  notificationsEmployeedColumn,
  notificationsPriorityColumn,
  notificationsReceivedColumn,
} from '@src/constants/columns/notifications'
import { notificationsRequisitionsRequests } from '@src/api/notifications'

const Requisitions = ({
  refreshStats,
  type,
  employeeId,
  filterBy,
  tableRef,
}: TabComponentProps) => {
  const isDashboard = type === 'dashboard'

  const RowNotifications: RowInterface<NotificationsInterface> = {
    cells: [
      {
        ...notificationDescription(employeeId),
        width: 160,
      },
      {
        type: CellTypes.text,
        idPoint: 'team.id',
        dataPoint: 'team.name',
        sortKey: 'team__name',
        filterKey: 'team_id',
        selectorsKey: selectorKeys.team,
        title: 'Team',
        width: 150,
      },
      {
        type: CellTypes.text,
        idPoint: 'main_location.id',
        dataPoint: 'main_location.name',
        sortKey: 'main_location__name',
        filterKey: 'main_location_id',
        selectorsKey: selectorKeys.location,
        title: 'Location',
        width: 100,
      },
      {
        type: CellTypes.text,
        idPoint: 'seniority_max.id',
        dataPoint: 'seniority_max.name',
        sortKey: 'seniority_max__level',
        filterKey: 'seniority_max_id',
        selectorsKey: selectorKeys.seniority,
        title: 'Seniority',
        width: 90,
      },
      {
        type: CellTypes.text,
        idPoint: 'headcount',
        dataPoint: 'headcount',
        sortKey: 'headcount',
        filterKey: 'headcount',
        filterType: FilterType.range,
        selectorsKey: selectorKeys.seniority,
        title: 'Headcount',
        width: 90,
      },
      {
        ...notificationsEmployeedColumn(isDashboard),
        title: 'Creator',
        width: isDashboard ? 90 : 180,
      },
      {
        ...notificationsReceivedColumn,
        title: 'Created',
        width: 90,
      },
      {
        ...notificationsDeadline,
        width: 130,
      },
      {
        ...notificationsPriorityColumn,
        width: 90,
      },
      {
        ...notificationsActionColumn,
        width: 200,
      },
    ],
  }

  return (
    <GenericNotificationTable
      rows={RowNotifications}
      request={notificationsRequisitionsRequests(employeeId)}
      refreshStats={refreshStats}
      type={type}
      filterBy={filterBy}
      tableRef={tableRef}
    />
  )
}

export default Requisitions
