import { StatusPopup, useStatusPopup, StatusPopupVariant } from '@revolut/ui-kit'
import React from 'react'

type UseShowStatusPopupData = {
  title: React.ReactNode
  description?: React.ReactNode
  status?: StatusPopupVariant
}

export const useShowStatusPopup = () => {
  const statusPopup = useStatusPopup()

  return ({ status = 'success', title, description }: UseShowStatusPopupData) => {
    statusPopup.show(
      <StatusPopup variant={status}>
        <StatusPopup.Title>{title}</StatusPopup.Title>
        {description && <StatusPopup.Description>{description}</StatusPopup.Description>}
      </StatusPopup>,
    )
  }
}
