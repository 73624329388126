import React from 'react'
import GenericNotificationTable from '@src/pages/Forms/Notifications/GenericNotificationTable'
import { RowInterface, TabComponentProps } from '@src/interfaces/data'
import { NotificationsInterface } from '@src/interfaces/notifications'
import {
  notificationDescription,
  notificationsActionColumn,
  notificationsEmployeedColumn,
  notificationsReceivedColumn,
} from '@src/constants/columns/notifications'
import { notificationsKPIsUpload } from '@src/api/notifications'

const KPIsUpload = ({
  refreshStats,
  type,
  employeeId,
  filterBy,
  tableRef,
}: TabComponentProps) => {
  const isDashboard = type === 'dashboard'

  const RowNotifications: RowInterface<NotificationsInterface> = {
    cells: [
      {
        ...notificationDescription(employeeId),
        width: 270,
      },
      {
        ...notificationsEmployeedColumn(isDashboard),
        width: isDashboard ? 90 : 260,
      },
      {
        ...notificationsReceivedColumn,
        width: 160,
      },
      {
        ...notificationsActionColumn,
        width: 200,
      },
    ],
  }

  return (
    <GenericNotificationTable
      rows={RowNotifications}
      request={notificationsKPIsUpload(employeeId)}
      refreshStats={refreshStats}
      type={type}
      filterBy={filterBy}
      tableRef={tableRef}
    />
  )
}

export default KPIsUpload
