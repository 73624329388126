import React, { useState, useMemo, useEffect } from 'react'
import { Action, Flex, TableWidget } from '@revolut/ui-kit'
import { pathToUrl } from '@src/utils/router'
import { useGetNotificationStats } from '@src/api/notifications'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { StatFilter, StatFilters } from '@src/components/StatFilters/StatFilters'
import {
  commonDashboardSubtabs,
  NotificationsContextProvider,
} from '@src/apps/General/Todo/common'
import { TODO_LIMIT } from '@src/pages/Forms/Notifications/GenericNotificationTable'

type DashboardSubtabComponent = typeof commonDashboardSubtabs[number]['component']

const todoStatsPaths = commonDashboardSubtabs.map(
  ({ statPath, title, component, url }) => ({
    statPath,
    title,
    component,
    url,
  }),
)
const primaryTodoTab = todoStatsPaths[0]

export const DashboardToDo = () => {
  const [selectedFilter, setSelectedFilter] = useState(primaryTodoTab.statPath)

  const {
    data: stats,
    isLoading: isStatsLoading,
    refetch: refetchStats,
  } = useGetNotificationStats()

  const statFilters: (StatFilter & {
    component: DashboardSubtabComponent
    url: string
  })[] = useMemo(() => {
    if (isStatsLoading) {
      return [
        {
          id: primaryTodoTab.statPath,
          title: primaryTodoTab.title,
          value: null,
          loading: true,
          component: primaryTodoTab.component,
          url: primaryTodoTab.url,
        },
      ]
    }

    /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
    const filteredTodoPaths = todoStatsPaths.filter(tab => Boolean(stats?.[tab.statPath]))

    if (!filteredTodoPaths.length) {
      return [
        {
          id: primaryTodoTab.statPath,
          title: primaryTodoTab.title,
          value: 0,
          loading: false,
          component: primaryTodoTab.component,
          url: primaryTodoTab.url,
        },
      ]
    }

    return filteredTodoPaths.map(tab => ({
      id: tab.statPath,
      title: tab.title,
      /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
      value: stats?.[tab.statPath],
      component: tab.component,
      url: tab.url,
    }))
  }, [stats, isStatsLoading])

  const selectedTab = statFilters.find(f => f.id === selectedFilter)
  const SelectedTabComponent = selectedTab?.component

  useEffect(() => {
    /** If the last todo item was cleared from a tab, stats refresh and that tab is gone. So we need to fallback to the primary tab */
    if (!SelectedTabComponent) {
      setSelectedFilter(primaryTodoTab.statPath)
    }
  }, [SelectedTabComponent])

  return (
    <TableWidget>
      <TableWidget.Info>
        <StatFilters
          selectedFilter={selectedFilter}
          filters={statFilters}
          onClick={setSelectedFilter}
        />
      </TableWidget.Info>

      <TableWidget.Table>
        {SelectedTabComponent ? (
          <NotificationsContextProvider>
            <SelectedTabComponent type="dashboard" refreshStats={() => refetchStats()} />
          </NotificationsContextProvider>
        ) : null}

        {typeof selectedTab?.value === 'number' && selectedTab?.value > TODO_LIMIT ? (
          <Flex justifyContent="center" mt="s-16">
            <Action use={InternalLink} to={pathToUrl(selectedTab.url)}>
              View all ({selectedTab.value})
            </Action>
          </Flex>
        ) : null}
      </TableWidget.Table>
    </TableWidget>
  )
}
