import React, { useEffect, useState } from 'react'
import {
  Box,
  chain,
  MoreBar,
  Popup,
  Text,
  Link as UIKitLink,
  copyToClipboard,
  Button,
  useToggle,
  Header,
  Group,
  RadioGroup,
  Item,
  Radio,
  ItemSkeleton,
} from '@revolut/ui-kit'
import { useParams, Switch, Route } from 'react-router-dom'
import { connect } from 'lape'

import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { AccountsSettingsInterface } from '@src/interfaces/settings'
import { getAccountStatusColor, getAccountStatusTitle } from '../common'
import { ROUTES } from '@src/constants/routes'
import SettingsButtons, { EditButton } from '@src/features/SettingsButtons'
import { pathToUrl } from '@src/utils/router'
import {
  accountSettings,
  getAccountActivationLink,
  getAccountSettings,
  setupTenant,
  useTenantDBClusters,
} from '@src/api/settings'
import { pushNotification } from '@src/store/notifications/actions'
import {
  ERROR_DEFAULT_DURATION,
  SUCCESS_DEFAULT_DURATION,
} from '@src/constants/notifications'
import { NotificationTypes } from '@src/store/notifications/types'
import Form from '@src/features/Form/Form'
import ConfirmationDialog from '@src/features/Popups/ConfirmationDialog'
import {
  activateFromDemoMode,
  retryDemoModeSetup,
  useCloseAccount,
  useSuspendAccount,
} from '@src/api/tenants'
import TabBarNavigation from '@src/features/TabBarNavigation/TabBarNavigation'
import { Information } from './Information'
import { Features } from './Features'
import { Invoices } from './Invoices'
import { AccountDiscounts } from './Discounts'

const REFRESH_STATUS_INTERVAL = 5000

const DetailsPage = () => {
  const { initialValues, values, reset } = useLapeContext<AccountsSettingsInterface>()
  const params = useParams()
  const [openDBClusterPopup, toggleDBClusterPopup] = useToggle()

  const waitingListOrSetupFailed =
    values.state === 'waiting_list' || values.state === 'setup_failed'
  const inDemoMode = values.state === 'demo'
  const isActiveOrDemo = values.state === 'demo' || values.state === 'active'

  const dbClusters = useTenantDBClusters(waitingListOrSetupFailed)

  const [checkNextState, setCheckNextState] = useState(false)
  const [copyPopupOpen, setCopyPopupOpen] = useState(false)
  const [activationLink, setActivationLink] = useState<string>()
  const [activationLinkPending, setActivationLinkPending] = useState(false)
  const [setupPending, setSetupPending] = useState(false)
  const [selectedDBCluster, setSelectedDBCluster] = useState<number | null>(null)

  const { mutateAsync: closeAccount, isLoading: isClosePending } = useCloseAccount(
    String(values.id),
  )
  const { mutateAsync: suspendAccount, isLoading: isSuspendPending } = useSuspendAccount(
    String(values.id),
  )
  const [closeDialogOpen, setCloseDialogOpen] = useState<boolean>(false)
  const [suspendDialogOpen, setSuspendDialogOpen] = useState<boolean>(false)

  if (dbClusters?.length === 1 && dbClusters[0].enabled && !selectedDBCluster) {
    setSelectedDBCluster(dbClusters[0].id)
  }

  useEffect(() => {
    if (
      (values.state === 'setup_failed' ||
        values.state === 'active' ||
        values.state === 'closed' ||
        values.state === 'demo') &&
      !checkNextState
    ) {
      return undefined
    }
    const refreshInterval = setInterval(async () => {
      const response = await getAccountSettings(values.id)

      if (response.data.state !== values.state) {
        setCheckNextState(false)

        if (response.data.state === 'active') {
          pushNotification({
            value: 'Account set to active',
            duration: SUCCESS_DEFAULT_DURATION,
            type: NotificationTypes.success,
          })
        }
        if (response.data.state === 'demo') {
          pushNotification({
            value: 'Demo mode enabled',
            duration: SUCCESS_DEFAULT_DURATION,
            type: NotificationTypes.success,
          })
        }
        if (response.data.state === 'setup_failed') {
          pushNotification({
            value: 'Setup failed',
            duration: ERROR_DEFAULT_DURATION,
            type: NotificationTypes.error,
          })
        }
        reset(response.data)
      }
    }, REFRESH_STATUS_INTERVAL)

    return () => clearInterval(refreshInterval)
  }, [values.state, checkNextState])

  const getLink = () => {
    setActivationLinkPending(true)
    setActivationLink(undefined)

    getAccountActivationLink(values.id)
      .then(response => {
        setActivationLink(response.data.activation_url)
        setCopyPopupOpen(true)
      })
      .finally(() => setActivationLinkPending(false))
  }

  const onCloseAccount = () => {
    closeAccount([undefined, undefined])
      .then(() => {
        values.state = 'closed'
      })
      .finally(() => {
        setCloseDialogOpen(false)
      })
  }

  const onSuspendAccount = () => {
    suspendAccount([undefined, undefined])
      .then(() => {
        values.state = 'suspended'
      })
      .finally(() => {
        setSuspendDialogOpen(false)
      })
  }

  const onCopy = () => {
    if (activationLink) {
      copyToClipboard(activationLink).then(() => {
        pushNotification({
          value: 'Link copied to clipboard',
          duration: SUCCESS_DEFAULT_DURATION,
          type: NotificationTypes.success,
        })
      })
    }
  }

  const setup = () => {
    if (!selectedDBCluster) {
      return
    }

    setSetupPending(true)

    setupTenant(values.id, selectedDBCluster)
      .then(() => {
        setCheckNextState(true)
        onDBClusterPopupClose()
        pushNotification({
          value:
            values.state === 'setup_failed'
              ? 'Retrying tenant setup'
              : 'Starting tenant setup',
          duration: SUCCESS_DEFAULT_DURATION,
          type: NotificationTypes.success,
        })
      })
      .finally(() => setSetupPending(false))
  }

  const setupDemoMode = () => {
    setSetupPending(true)

    retryDemoModeSetup(values.id)
      .then(() => {
        setCheckNextState(true)
        pushNotification({
          value:
            values.state === 'setup_failed'
              ? 'Retrying demo mode setup'
              : 'Starting demo mode setup',
          duration: SUCCESS_DEFAULT_DURATION,
          type: NotificationTypes.success,
        })
      })
      .finally(() => setSetupPending(false))
  }

  const activate = () => {
    setSetupPending(true)

    activateFromDemoMode(values.id)
      .then(() => {
        setCheckNextState(true)
        pushNotification({
          value: 'Activating tenant',
          duration: SUCCESS_DEFAULT_DURATION,
          type: NotificationTypes.success,
        })
      })
      .finally(() => setSetupPending(false))
  }

  const onDBClusterPopupClose = () => {
    toggleDBClusterPopup(false)
    setSelectedDBCluster(null)
  }

  return (
    <>
      <PageHeader
        title={chain(
          initialValues.company_name,
          <Text color={getAccountStatusColor(values.state!)}>
            {getAccountStatusTitle(values.state!)}
          </Text>,
        )}
        subtitle="Company account"
        backUrl={ROUTES.SETTINGS.ACCOUNTS.LIST}
      />

      <SettingsButtons mb="s-16">
        {waitingListOrSetupFailed ? (
          <>
            <MoreBar.Action
              onClick={() => toggleDBClusterPopup(true)}
              useIcon={values.state === 'setup_failed' ? 'Retry' : 'Check'}
            >
              {values.state === 'setup_failed' ? 'Retry setup' : 'Approve'}
            </MoreBar.Action>
            <MoreBar.Action
              onClick={setupDemoMode}
              useIcon={values.state === 'setup_failed' ? 'Retry' : 'Check'}
              pending={setupPending}
            >
              {values.state === 'setup_failed'
                ? 'Retry demo mode setup'
                : 'Enable demo mode'}
            </MoreBar.Action>
          </>
        ) : null}
        {inDemoMode ? (
          <MoreBar.Action onClick={activate} useIcon="Check" pending={setupPending}>
            Activate
          </MoreBar.Action>
        ) : null}
        <EditButton route={pathToUrl(ROUTES.SETTINGS.ACCOUNTS.GENERAL, params)} />
        <MoreBar.Action
          useIcon="Copy"
          onClick={getLink}
          pending={activationLinkPending}
          disabled={!isActiveOrDemo}
        >
          Copy activation URL
        </MoreBar.Action>
        <MoreBar.Action
          variant="negative"
          disabled={!isActiveOrDemo}
          useIcon="LockClosed"
          onClick={() => setSuspendDialogOpen(true)}
        >
          Suspend
        </MoreBar.Action>
        <MoreBar.Action
          variant="negative"
          disabled={values.state !== 'active'}
          useIcon="LogoutDoor"
          onClick={() => setCloseDialogOpen(true)}
        >
          Close account
        </MoreBar.Action>
      </SettingsButtons>

      <TabBarNavigation
        tabs={[
          {
            title: 'Information',
            path: ROUTES.SETTINGS.ACCOUNTS.INFORMATION,
            to: pathToUrl(ROUTES.SETTINGS.ACCOUNTS.INFORMATION, params),
          },
          {
            title: 'Account features',
            path: ROUTES.SETTINGS.ACCOUNTS.FEATURES,
            to: pathToUrl(ROUTES.SETTINGS.ACCOUNTS.FEATURES, params),
          },
          {
            title: 'Invoices',
            path: ROUTES.SETTINGS.ACCOUNTS.INVOICES,
            to: pathToUrl(ROUTES.SETTINGS.ACCOUNTS.INVOICES, params),
          },
          {
            title: 'Discounts',
            path: ROUTES.SETTINGS.ACCOUNTS.DISCOUNTS,
            to: pathToUrl(ROUTES.SETTINGS.ACCOUNTS.DISCOUNTS, params),
          },
        ]}
      />

      <Switch>
        <Route path={ROUTES.SETTINGS.ACCOUNTS.INFORMATION}>
          <Information />
        </Route>
        <Route path={ROUTES.SETTINGS.ACCOUNTS.FEATURES}>
          <Features />
        </Route>
        <Route path={ROUTES.SETTINGS.ACCOUNTS.INVOICES}>
          <Invoices />
        </Route>
        <Route path={ROUTES.SETTINGS.ACCOUNTS.DISCOUNTS}>
          <AccountDiscounts />
        </Route>
      </Switch>

      <Popup
        open={copyPopupOpen}
        variant="bottom-sheet"
        onClose={() => setCopyPopupOpen(false)}
      >
        <Box style={{ overflowWrap: 'break-word' }}>
          <UIKitLink href={activationLink} target="_blank">
            {activationLink}
          </UIKitLink>
        </Box>
        <Popup.Actions horizontal>
          <Button onClick={() => setCopyPopupOpen(false)} variant="secondary">
            Close
          </Button>
          <Button onClick={onCopy} useIcon="Copy">
            Copy link
          </Button>
        </Popup.Actions>
      </Popup>

      <ConfirmationDialog
        open={closeDialogOpen}
        onClose={() => setCloseDialogOpen(false)}
        onConfirm={onCloseAccount}
        loading={isClosePending}
        onReject={() => setCloseDialogOpen(false)}
        label="Close account"
        yesMessage="Close account"
        noMessage="Go back"
      />

      <ConfirmationDialog
        open={suspendDialogOpen}
        onClose={() => setSuspendDialogOpen(false)}
        onConfirm={onSuspendAccount}
        loading={isSuspendPending}
        onReject={() => setSuspendDialogOpen(false)}
        label="Suspend account"
        yesMessage="Suspend account"
        noMessage="Go back"
      />

      <Popup
        open={openDBClusterPopup}
        onClose={onDBClusterPopupClose}
        variant="bottom-sheet"
      >
        <Header variant="bottom-sheet">
          <Header.Title>Select Database cluster for {values.company_name}</Header.Title>
        </Header>

        {dbClusters == null ? (
          <ItemSkeleton />
        ) : dbClusters?.length ? (
          <Group>
            <RadioGroup value={selectedDBCluster} onChange={setSelectedDBCluster}>
              {group =>
                dbClusters.map(cluster => (
                  <Item use="label" aria-disabled={!cluster.enabled} key={cluster.id}>
                    <Item.Prefix>
                      <Radio
                        {...group.getInputProps({ value: cluster.id })}
                        disabled={!cluster.enabled}
                      />
                    </Item.Prefix>
                    <Item.Content>
                      <Item.Title>{cluster.title}</Item.Title>
                      <Item.Description>
                        {cluster.tenant_count}/{cluster.tenant_limit} tenants
                      </Item.Description>
                    </Item.Content>
                  </Item>
                ))
              }
            </RadioGroup>
          </Group>
        ) : (
          <Item>
            <Item.Content>
              <Item.Title>No Database clusters found</Item.Title>
            </Item.Content>
          </Item>
        )}

        <Popup.Actions horizontal>
          <Button onClick={onDBClusterPopupClose} variant="secondary">
            Cancel
          </Button>
          <Button
            onClick={setup}
            pending={setupPending}
            disabled={!selectedDBCluster}
            elevated
          >
            Confirm
          </Button>
        </Popup.Actions>
      </Popup>
    </>
  )
}

export const Details = connect(() => (
  <Form api={accountSettings} disableLocalStorageCaching>
    <DetailsPage />
  </Form>
))
