import React from 'react'
import { performanceSettings } from '@src/api/performanceSettings'
import { API } from '@src/constants/api'
import Form from '@src/features/Form/Form'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { PerformanceSettingsInterface } from '@src/interfaces/settings'
import { ScorecardQuestionsWidget } from '@src/pages/OnboardingChecklist/Performance/ScorecardQuestions'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { PageActions } from '@components/Page/PageActions'
import { InnerRoute } from '@src/pages/OnboardingChecklistV2/PerformanceReviews/common/InnerRoute'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'

const CustomQuestionsGeneralFormContent = () => {
  const form = useLapeContext<PerformanceSettingsInterface>()
  const backUrl = pathToUrl(ROUTES.ONBOARDING_CHECKLIST_V2.REVIEWS.SETUP_SCORECARD)

  return (
    <InnerRoute
      title="Manage custom questions"
      description="Define custom questions you want to have in the performance scorecards"
    >
      <ScorecardQuestionsWidget />
      <PageActions>
        <NewSaveButtonWithPopup
          onClick={() => {
            return form.submit()
          }}
          useValidator
          successText="Custom questions saved"
          hideWhenNoChanges
          afterSubmitUrl={backUrl}
        />
      </PageActions>
    </InnerRoute>
  )
}

export const CustomQuestionsGeneralForm = () => {
  return (
    <Form
      api={performanceSettings}
      forceParams={{ id: '1' }}
      invalidateQueries={[API.PERFORMANCE_SETTINGS]}
      fieldsToExclude={['ui_only_grade_calculation_method']}
    >
      <CustomQuestionsGeneralFormContent />
    </Form>
  )
}
