import React from 'react'
import pluralize from 'pluralize'

import { Box, Color } from '@revolut/ui-kit'
import AdjustableTable from '@components/Table/AdjustableTable'
import {
  DriverResultStatsInterface,
  EngagementResultInterface,
  EngagementResultsScope,
} from '@src/interfaces/engagement'
import { TableNames } from '@src/constants/table'
import { RowInterface } from '@src/interfaces/data'
import { IdAndName } from '@src/interfaces'
import { useTableReturnType } from '@src/components/Table/hooks'
import {
  engagementResultsAverageDistributionColumn,
  engagementResultsAverageScoreColumn,
  engagementResultsBenchmarkColumn,
  engagementResultsAnswersColumn,
  engagementResultsDriverNameColumn,
  engagementResultsNpsDistributionColumn,
  engagementResultsNpsScoreColumn,
  engagementResultsTrendAverageScoreColumn,
  engagementResultsTrendNpsScoreColumn,
} from '@src/constants/columns/engagementResults'
import { ResultsTableAnonymised } from '../components/ResultsTableAnonymised'
import { scopeToString } from '../helpers'

const trendColumnTooltip = (
  <Box color={Color.BACKGROUND} p="s-8" minWidth={250}>
    This indicates how much the score for this driver has increased or decreased since the
    last time it was asked to employees
  </Box>
)

const getRow = (
  isAverage: boolean,
  scope?: EngagementResultsScope,
  canViewComments?: boolean,
): RowInterface<EngagementResultInterface> => ({
  cells: [
    { ...engagementResultsDriverNameColumn, width: 250 },
    {
      ...(isAverage
        ? engagementResultsAverageScoreColumn
        : engagementResultsNpsScoreColumn),
      width: 105,
    },
    {
      ...(isAverage
        ? engagementResultsAverageDistributionColumn
        : engagementResultsNpsDistributionColumn),
      width: 300,
    },
    {
      ...(isAverage
        ? engagementResultsTrendAverageScoreColumn
        : engagementResultsTrendNpsScoreColumn),
      width: 95,
      headerTooltip: trendColumnTooltip,
    },
    ...(!!scope && scope !== EngagementResultsScope.Company
      ? [
          {
            ...engagementResultsBenchmarkColumn,
            width: 125,
            headerTooltip: scope ? (
              <Box color={Color.BACKGROUND} p="s-8" minWidth={250}>
                This indicates how your {scopeToString(scope)} score compares across all
                other {pluralize(scopeToString(scope))}
              </Box>
            ) : (
              ''
            ),
          },
        ]
      : []),
    ...(canViewComments ? [{ ...engagementResultsAnswersColumn, width: 120 }] : []),
  ],
})

type Props = {
  table: useTableReturnType<EngagementResultInterface, DriverResultStatsInterface>
  isAverage: boolean
  setDriver: (driver: IdAndName | undefined) => void
  switchToChartTab: () => void
  scope?: EngagementResultsScope
  canViewComments?: boolean
}
export const ResultsTable = ({
  table,
  isAverage,
  setDriver,
  switchToChartTab,
  scope,
  canViewComments,
}: Props) => {
  return (
    <AdjustableTable<EngagementResultInterface, DriverResultStatsInterface>
      name={TableNames.EngagementDriversResults}
      useWindowScroll
      row={getRow(isAverage, scope, canViewComments)}
      {...table}
      onRowClick={row => {
        if (!row.can_show_details) {
          return
        }
        setDriver(row.driver)
        switchToChartTab()
      }}
      noDataMessage="Drivers results data will appear here"
      rowHeight="large"
      emptyState={
        table.stats?.can_show_details === false ? <ResultsTableAnonymised /> : undefined
      }
    />
  )
}
