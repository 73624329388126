import React, { useEffect } from 'react'

import AdjustableTable from '@components/Table/AdjustableTable'
import {
  EngagementResultsComment,
  EngagementResultsCommentsStats,
  EngagementResultsScope,
} from '@src/interfaces/engagement'
import { TableNames } from '@src/constants/table'
import { RowInterface } from '@src/interfaces/data'
import { useTableReturnType } from '@src/components/Table/hooks'
import {
  engagementResultsFeedbackAnswerColumn,
  engagementResultsFeedbackQuestionColumn,
  engagementResultsFeedbackScoreColumn,
} from '@src/constants/columns/engagementResults'
import { ResultsTableAnonymised } from '../components/ResultsTableAnonymised'

const row: RowInterface<EngagementResultsComment> = {
  cells: [
    { ...engagementResultsFeedbackQuestionColumn, width: 250 },
    { ...engagementResultsFeedbackScoreColumn, width: 100 },
    { ...engagementResultsFeedbackAnswerColumn, width: 250 },
  ],
}

type Props<T> = {
  scope?: EngagementResultsScope
  data: T
  surveyId?: number
  setHasAnonymisedData: (hasData: boolean) => void
  table: useTableReturnType<EngagementResultsComment, EngagementResultsCommentsStats>
}
export const FeedbackTable = <T extends { id: number }>({
  surveyId,
  setHasAnonymisedData,
  table,
}: Props<T>) => {
  useEffect(() => {
    if (surveyId) {
      table.refresh()
    }
  }, [surveyId])

  useEffect(() => {
    setHasAnonymisedData(table.stats?.can_show_details === false)
  }, [table.stats?.can_show_details])

  return (
    <AdjustableTable<EngagementResultsComment, EngagementResultsCommentsStats>
      name={TableNames.EngagementDriversResults}
      useWindowScroll
      row={row}
      {...table}
      noDataMessage="Answers will appear here"
      rowHeight="large"
      dataType="Answer"
      emptyState={
        table.stats?.can_show_details === false ? <ResultsTableAnonymised /> : undefined
      }
    />
  )
}
