import React from 'react'
import { ROUTES } from '@src/constants/routes'
import { EngagementResults } from '@src/apps/People/Engagement/Results'
import { EngagementResultsScope } from '@src/interfaces/engagement'
import { EmployeeInterface } from '@src/interfaces/employees'

export const Engagement = ({ data }: { data: EmployeeInterface }) => {
  return (
    <EngagementResults
      scope={EngagementResultsScope.Employees}
      driversPath={ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.ENGAGEMENT.DRIVERS}
      questionsPath={ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.ENGAGEMENT.QUESTIONS}
      feedbackPath={ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.ENGAGEMENT.FEEDBACK}
      data={data}
    />
  )
}
