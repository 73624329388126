import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  Avatar,
  Cell,
  InputGroup,
  Item,
  Link,
  StatusPopup,
  Tag,
  VStack,
  useStatusPopup,
} from '@revolut/ui-kit'
import get from 'lodash/get'

import { selectorKeys } from '@src/constants/api'
import { IdAndName } from '@src/interfaces'
import { DocumentInterface, DocumentRequestInterface } from '@src/interfaces/documents'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import { useLapeContext } from '@src/features/Form/LapeForm'
import {
  requestDocumentByCollection,
  requestDocumentBySelfServe,
  requestDocumentByTemplateRequests,
  requestDocumentRequest,
} from '@src/api/documents'
import { PageWrapper } from '@src/components/Page/Page'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import AutoStepper from '@src/components/Stepper/AutoStepper'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import LapeDatePickerInput from '@src/components/Inputs/LapeFields/LapeDatePickerInput'
import LapeNewTextArea from '@src/components/Inputs/LapeFields/LapeNewTextArea'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { PageBody } from '@src/components/Page/PageBody'
import { PageActions } from '@src/components/Page/PageActions'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import RadioSelectInput from '@src/components/Inputs/RadioSelectInput/RadioSelectInput'
import { DOCUMENT_LABEL_REQUEST_SERVICE_DESK } from '@src/constants/externalLinks'
import NewStepperTitle from '@src/components/Stepper/NewStepperTitle'
import useIsCommercial from '@src/hooks/useIsCommercial'
import { workspaceLocalStorage } from '@src/features/Workspaces/workspaceLocalStorage'
import { useGetDocumentCollections } from '@src/api/documentsCollections'
import { getMessageFromError } from '@src/store/notifications/actions'
import { useGlobalSettings } from '@src/api/settings'
import { useQuery } from '@src/utils/queryParamsHooks'
import { DocumentRequestType } from './common'

const requestLabelByType: Record<DocumentRequestType, string> = {
  manual: 'Request documents manually',
  byTemplateRule: 'Request documents by template rule',
  byCollection: 'Request documents collection',
  selfServe: 'Self serve',
}

type RequestTypeSelectorValue = IdAndName<DocumentRequestType>

const getValueByType = (type: DocumentRequestType): RequestTypeSelectorValue => ({
  id: type,
  name: requestLabelByType[type],
})

const getOptionByType = (type: DocumentRequestType) => ({
  label: requestLabelByType[type],
  value: getValueByType(type),
})

const getAvailableRequestTypes = (
  collectionsEnabled: boolean | undefined,
  isInternalProduct: boolean | undefined,
): DocumentRequestType[] =>
  [
    'manual',
    collectionsEnabled ? 'byCollection' : null,
    isInternalProduct ? 'byTemplateRule' : null,
  ].filter(Boolean)

const getRequestTypeSelectorOptions = (
  collectionsEnabled: boolean | undefined,
  isCommercial: boolean | undefined,
) => getAvailableRequestTypes(collectionsEnabled, !isCommercial).map(getOptionByType)

const General = () => {
  const params = useParams<{ employeeId?: string }>()
  const isCommercial = useIsCommercial()
  const { values } = useLapeContext<DocumentInterface>()
  const employeeId = params.employeeId || `${values.employee?.id}`

  const { query } = useQuery<{ requestType?: DocumentRequestType }>()
  const forcedRequestType = query.requestType
  const initTypeSelectorValue = forcedRequestType
    ? getValueByType(forcedRequestType)
    : undefined

  const [requestTypeSelectorValue, setRequestTypeSelectorValue] = useState<
    RequestTypeSelectorValue | undefined
  >(initTypeSelectorValue)
  const [collectionValue, setCollectionValue] = useState<IdAndName>()

  useEffect(() => {
    if (!values.issue_date_time) {
      values.issue_date_time = new Date().toISOString()
    }
  }, [])

  const statusPopup = useStatusPopup()
  const { settings } = useGlobalSettings()
  const collectionsEnabled = settings.enable_document_generation_from_templates
  const { data: collectionData } = useGetDocumentCollections(collectionValue?.id)

  const backUrl = values.employee
    ? pathToUrl(ROUTES.FORMS.EMPLOYEE.DOCUMENTS, {
        id: values.employee.id,
      })
    : ROUTES.PEOPLE.EMPLOYEES

  const handleChange = (value: RequestTypeSelectorValue | null) => {
    if (value) {
      setRequestTypeSelectorValue(value)
    }
  }

  const showError = (text?: string) => {
    statusPopup.show(
      <StatusPopup variant="error">
        <StatusPopup.Title>Failed to request documents</StatusPopup.Title>
        {text && <StatusPopup.Description>{text}</StatusPopup.Description>}
      </StatusPopup>,
    )
  }

  const clearLocalStorage = (requestType?: DocumentRequestType) => {
    workspaceLocalStorage.removeItem(
      pathToUrl(
        ROUTES.FORMS.DOCUMENT_REQUEST.GENERAL,
        { employeeId },
        requestType ? { requestType } : undefined,
      ),
    )
  }

  const requestTypeId = requestTypeSelectorValue?.id

  const handleSubmit = () => {
    if (requestTypeId === 'manual') {
      return requestDocumentRequest
        .submit(
          {
            name: values.name,
            issue_date_time: values.issue_date_time,
            issuer: values.issuer,
            category: values.category,
            description: values.description,
            employee: values.employee,
          },
          {},
        )
        .then(() => {
          clearLocalStorage()
        })
    }
    if (requestTypeId === 'byTemplateRule') {
      const requestByTemplateValues = values as unknown as DocumentRequestInterface
      return requestDocumentByTemplateRequests
        .submit(
          {
            document_rule: requestByTemplateValues.document_rule,
          },
          { employeeId },
        )
        .then(() => {
          clearLocalStorage()
        })
    }
    if (requestTypeId === 'byCollection' && collectionValue?.id) {
      return requestDocumentByCollection(collectionValue.id, {
        employee: { id: employeeId },
      }).then(() => clearLocalStorage())
    }
    if (requestTypeId === 'selfServe') {
      return requestDocumentBySelfServe(get(values, 'self_serve_template.id')).then(() =>
        clearLocalStorage('selfServe'),
      )
    }
    throw new Error('Request type is not selected')
  }

  return (
    <PageWrapper>
      <PageHeader
        title={
          forcedRequestType === 'selfServe'
            ? 'Self serve document'
            : 'Request document from employee'
        }
        backUrl={backUrl}
      />

      <PageBody>
        <AutoStepper>
          {!params.employeeId && (
            <>
              <NewStepperTitle title="Employee" />
              <InputGroup>
                <LapeRadioSelectInput
                  name="employee"
                  selector={selectorKeys.employee}
                  label="Employee"
                />
              </InputGroup>
            </>
          )}
          <NewStepperTitle title="Method" />
          <RadioSelectInput<RequestTypeSelectorValue>
            label="Request method"
            onChange={handleChange}
            value={requestTypeSelectorValue}
            searchable={false}
            options={getRequestTypeSelectorOptions(collectionsEnabled, isCommercial)}
            disabled={!!forcedRequestType}
          />
          {requestTypeId === 'manual' && (
            <>
              <NewStepperTitle title="Details" />
              <InputGroup>
                <LapeNewInput name="name" label="File Name" required />
                <LapeDatePickerInput name="issue_date_time" label="Issue Date" required />
                <LapeRadioSelectInput
                  label="Issuer"
                  name="issuer"
                  selector={selectorKeys.employee}
                />
                <LapeRadioSelectInput
                  name="category"
                  label="Category"
                  message={
                    !isCommercial ? (
                      <>
                        Please make sure you select the appropriate document label for the
                        file you are uploading. If you cannot find a label that describes
                        precisely the document type, you can request one on the Revoluters
                        service desk{' '}
                        <Link target="_blank" href={DOCUMENT_LABEL_REQUEST_SERVICE_DESK}>
                          here
                        </Link>
                      </>
                    ) : undefined
                  }
                  selector={selectorKeys.document_categories}
                />
                <LapeNewTextArea
                  name="description"
                  label="Description"
                  message="Add some description to explain what the document is about."
                  rows={3}
                />
              </InputGroup>
            </>
          )}
          {requestTypeId === 'byTemplateRule' && (
            <>
              <NewStepperTitle title="Details" />
              <InputGroup>
                <LapeRadioSelectInput
                  label="Rule"
                  name="document_rule"
                  selector={selectorKeys.document_template_rules_individual_requesting}
                />
              </InputGroup>
            </>
          )}
          {requestTypeId === 'byCollection' && (
            <>
              <NewStepperTitle title="Details" />
              <InputGroup>
                <RadioSelectInput<IdAndName>
                  label="Collection"
                  onChange={val => {
                    if (val) {
                      setCollectionValue(val)
                    }
                  }}
                  selector={selectorKeys.document_collections}
                  value={collectionValue}
                />
              </InputGroup>
              {!!collectionData?.document_templates.length && (
                <Cell mt="s-32" px={0}>
                  <VStack space="s-16" width="100%">
                    {collectionData.document_templates.map(template => (
                      <Item key={template.id}>
                        <Item.Avatar>
                          <Avatar useIcon="Document" />
                        </Item.Avatar>
                        <Item.Content>
                          <Item.Title>{template.name}</Item.Title>
                        </Item.Content>
                        <Item.Side>
                          <Tag variant="faded">
                            {template.template_type?.id === 'request'
                              ? 'Document'
                              : 'Signature'}
                          </Tag>
                        </Item.Side>
                      </Item>
                    ))}
                  </VStack>
                </Cell>
              )}
            </>
          )}
          {requestTypeId === 'selfServe' && (
            <>
              <NewStepperTitle title="Details" />
              <InputGroup>
                <LapeRadioSelectInput
                  label="Document template"
                  name="self_serve_template"
                  selector={selectorKeys.document_self_serve_templates}
                />
              </InputGroup>
            </>
          )}
        </AutoStepper>
      </PageBody>
      {requestTypeId && (
        <PageActions>
          <NewSaveButtonWithPopup
            useValidator
            disabled={!values.employee}
            successText="Documents requested"
            afterSubmitUrl={backUrl}
            onClick={handleSubmit}
            errorHandler={err => {
              const errorMessage =
                err.response?.status === 400
                  ? getMessageFromError(err)
                  : 'Something went wrong. Please, try again later'
              showError(errorMessage)
            }}
          />
        </PageActions>
      )}
    </PageWrapper>
  )
}

export default General
