import React from 'react'
import { StatFilter, StatFilters } from '@src/components/StatFilters/StatFilters'
import { Statuses } from '@src/interfaces'
import { FilterByInterface } from '@src/interfaces/data'
import { DataPoint, DataPointSkeleton, Highlights, Token } from '@revolut/ui-kit'
import { useGetInterviewStats } from '@src/api/recruitment/interviews'

type InterviewStatFilterId = 'all_interviews' | 'pending' | 'completed' | 'rejected'

const allInterviews = {
  filters: [],
  columnName: 'status',
}

const pendingFilter = {
  filters: [
    { id: Statuses.pending, name: Statuses.pending },
    { id: Statuses.draft, name: Statuses.draft },
  ],
  columnName: 'status',
}

const completedFilter = {
  filters: [{ id: Statuses.completed, name: Statuses.completed }],
  columnName: 'status',
}

const rejectedFilter = {
  filters: [{ id: Statuses.rejected, name: Statuses.rejected }],
  columnName: 'status',
}

const getSelectedStat = (filters: FilterByInterface[]): InterviewStatFilterId => {
  const statusFilter = filters.find(({ columnName }) => columnName === 'status')
  if (statusFilter) {
    return statusFilter.filters[0].id as InterviewStatFilterId
  }
  return 'all_interviews'
}

type InterviewStatsProps = {
  filters: FilterByInterface[]
  onFilterChange: (filter: FilterByInterface[]) => void
}

export const InterviewStats = ({ filters, onFilterChange }: InterviewStatsProps) => {
  const { data, isLoading } = useGetInterviewStats(
    filters.filter(({ columnName }) => columnName !== 'status'),
  )
  const currentStatFilter = getSelectedStat(filters)
  const statsFilterConfig: StatFilter<InterviewStatFilterId>[] = [
    {
      id: 'all_interviews',
      title: 'All interviews',
      value: data?.total,
    },
    {
      id: 'completed',
      title: 'Completed',
      value: data?.status?.completed,
      color: Token.color.green,
    },
    {
      id: 'pending',
      title: 'Pending',
      value: (data?.status?.pending || 0) + (data?.status?.draft || 0),
      color: Token.color.orange,
    },
    {
      id: 'rejected',
      title: 'Rejected',
      value: data?.status?.rejected,
      color: Token.color.greyTone50,
    },
  ]
  if (isLoading) {
    return (
      <Highlights>
        {statsFilterConfig.map(({ id, title, color }) => (
          <DataPointSkeleton key={id}>
            <DataPointSkeleton.Value />
            <DataPoint.Label color={color}>{title}</DataPoint.Label>
          </DataPointSkeleton>
        ))}
      </Highlights>
    )
  }
  const handleClick = (id: InterviewStatFilterId) => {
    if (id !== currentStatFilter) {
      let filter: FilterByInterface = allInterviews
      switch (id) {
        case 'pending': {
          filter = pendingFilter
          break
        }
        case 'completed': {
          filter = completedFilter
          break
        }
        case 'rejected': {
          filter = rejectedFilter
          break
        }
      }
      onFilterChange([filter])
    }
  }
  return (
    <StatFilters
      filters={statsFilterConfig}
      onClick={handleClick}
      selectedFilter={currentStatFilter}
    />
  )
}
