import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { useLape, connect } from 'lape'
import {
  MoreBar,
  Box,
  InputGroup,
  Text,
  Banner,
  Token,
  TextButton,
} from '@revolut/ui-kit'
import { ExclamationTriangle, Retry } from '@revolut/icons'
import { useParams } from 'react-router-dom'

import { ROUTES } from '@src/constants/routes'
import { API, LOCAL_STORAGE, selectorKeys } from '@src/constants/api'
import LapeFileUploader from '@src/components/Inputs/LapeFields/LapeFileUploader'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { api } from '@src/api'
import { DeleteButtonLape } from '../FormButtons'
import { DynamicGroupInerface } from '@src/interfaces/dynamicGroups'
import { dynamicGroups, runDynamicGroupSQL, updateMembers } from '@src/api/dynamicGroups'
import { selectFeatureFlags, selectUser } from '@src/store/auth/selectors'
import { PageWrapper } from '@src/components/Page/Page'
import { PageBody } from '@src/components/Page/PageBody'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import AutoStepper from '@src/components/Stepper/AutoStepper'
import NewStepperTitle from '@src/components/Stepper/NewStepperTitle'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import LapeNewTextArea from '@src/components/Inputs/LapeFields/LapeNewTextArea'
import { Grid } from '@src/components/CommonSC/Grid'
import { PageActions } from '@src/components/Page/PageActions'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import { useGetEmployeeSettings } from '@src/api/settings'
import { pushNotification } from '@src/store/notifications/actions'
import { SUCCESS_DEFAULT_DURATION } from '@src/constants/notifications'
import { NotificationTypes } from '@src/store/notifications/types'
import { AudienceTable } from '@src/features/AudienceSelection/AudienceTable'
import { SQLQuery } from '@src/features/AudienceSelection/SQLQuery'
import HideIfCommercial from '@src/components/HideIfCommercial/HideIfCommercial'
import { FeatureFlags } from '@src/store/auth/types'
import { useCreateOptionInNewTab } from '@src/utils/useCreateOptionInNewTab'
import { useQuery } from '@src/utils/queryParamsHooks'
import { FormattedMessage } from 'react-intl'

const BadgePreview = styled.img`
  width: 32px;
  height: 24px;
  object-fit: cover;
  object-position: center;
`

type DynamicGroupsFormState = {
  showUpload: boolean
}

const DynamicGroupsForm = () => {
  const initialBadgeId = useRef<number>()
  const { data: settings } = useGetEmployeeSettings()

  const [updatePending, setUpdatePending] = useState(false)

  const { query } = useQuery<{ ref?: 'fill-interviewer-groups-after-submit' }>()
  const fillInterviewerGroupAfterSubmit =
    query?.ref === 'fill-interviewer-groups-after-submit'
  const { keepOptionIdInLocalStorage, showStatusPopup } = useCreateOptionInNewTab(
    LOCAL_STORAGE.GROUP_CREATED,
  )

  const dynamicGroupSqlEnabled = !!settings?.enable_dynamic_groups_sql

  const { id } = useParams<{ id: any }>()

  const { values, initialValues } = useLapeContext<Partial<DynamicGroupInerface>>()
  const user = useSelector(selectUser)
  const featureFlags = useSelector(selectFeatureFlags)
  const isCommercial = featureFlags?.includes(FeatureFlags.CommercialProduct)

  const isInternal = values.type?.id === 'internal'

  const state = useLape<DynamicGroupsFormState>({
    showUpload: !values.employee_badge,
  })

  useEffect(() => {
    // We can't save file to LocalStorage that is why I remove it if the data comes from there.
    if (values.file && !values.file.name) {
      values.file = undefined
      state.showUpload = true
    }
  })

  useEffect(() => {
    if (!values.owner) {
      values.owner = { id: user.id, name: user.full_name }
    }

    initialBadgeId.current = values.employee_badge?.id
  }, [])

  if (
    (settings?.enable_dynamic_groups_sql === false && !values.audience_selection_type) ||
    (isCommercial && values.audience_selection_type?.id !== 'filtered_table')
  ) {
    values.audience_selection_type = { id: 'filtered_table', name: 'Filtered table' }
  }

  const backUrl = ROUTES.APPS.GROUPS

  const afterSubmit = (result: DynamicGroupInerface) => {
    if (
      initialBadgeId.current &&
      result.employee_badge &&
      result.employee_badge.id !== initialBadgeId.current
    ) {
      api.delete(`${API.FILES}/${initialBadgeId.current}`, undefined, undefined, true)
    }
    if (fillInterviewerGroupAfterSubmit) {
      keepOptionIdInLocalStorage(result.id)
      showStatusPopup(
        <FormattedMessage
          id="dynamicGroups.successCreate.title"
          defaultMessage="Dynamic group successfully created"
        />,
        <FormattedMessage
          id="dynamicGroups.successCreate.description"
          defaultMessage="Please close this tab and return back to page."
        />,
      )
    }
  }

  const onUpdateGroup = () => {
    if (!values.id) {
      return
    }

    setUpdatePending(true)

    updateMembers(values.id)
      .then(() =>
        pushNotification({
          value: 'Group membership updated',
          duration: SUCCESS_DEFAULT_DURATION,
          type: NotificationTypes.success,
        }),
      )
      .finally(() => setUpdatePending(false))
  }

  return (
    <PageWrapper>
      <PageHeader title={values.id ? values.name : 'Create group'} backUrl={backUrl} />

      <PageBody>
        {!!values.id && (
          <MoreBar>
            <MoreBar.Action
              onClick={onUpdateGroup}
              pending={updatePending}
              useIcon={Retry}
            >
              Update members
            </MoreBar.Action>
            <DeleteButtonLape
              data={values as DynamicGroupInerface}
              backUrl={backUrl}
              deleteApi={dynamicGroups.delete!}
              title="Dynamic group"
              useMoreBar
            />
          </MoreBar>
        )}

        {isInternal && (
          <Box mt="s-16">
            <Banner>
              <Banner.Avatar>
                <ExclamationTriangle size={56} color={Token.color.blue} />
              </Banner.Avatar>
              <Banner.Content>
                <Banner.Title>This is an internal group</Banner.Title>
                <Banner.Description>
                  Internal groups are system-generated and cannot be edited.
                </Banner.Description>
              </Banner.Content>
            </Banner>
          </Box>
        )}

        <AutoStepper>
          <NewStepperTitle title="Details" />
          <InputGroup>
            <LapeNewInput disabled={isInternal} name="name" label="Group name" required />
            <LapeRadioSelectInput
              disabled={isInternal}
              name="owner"
              label="Group owner"
              selector={selectorKeys.employee}
            />
            {values.id && (
              <>
                <LapeRadioSelectInput
                  name="type"
                  label="Type"
                  message="Whether the dynamic group was created by the system or the a user"
                  selector={selectorKeys.dynamic_group_types}
                  optional
                />
                <LapeRadioSelectInput
                  disabled={isInternal}
                  name="status"
                  label="Status"
                  selector={selectorKeys.dynamic_group_statuses}
                  message="Change to enable/disable the dynamic group"
                  optional
                />
              </>
            )}
            <LapeRadioSelectInput
              disabled={isInternal}
              name="visibility"
              label="Label visibility"
              selector={selectorKeys.employee_label_visibilities}
              message="Which groups of employees can view this label in the employee profile"
            />
            <LapeNewTextArea
              disabled={isInternal}
              name="description"
              label="Description"
              rows={3}
            />
          </InputGroup>

          {!!settings?.enable_dynamic_groups_badges && (
            <>
              <NewStepperTitle title="Upload badge" />
              <InputGroup>
                {state.showUpload && (
                  <Box>
                    <LapeFileUploader label="Badge image" name="file" />
                    <Text
                      color={Token.color.greyTone50}
                      fontSize="small"
                      lineHeight="18px"
                      mt="s-24"
                      use="div"
                    >
                      1. Ideal dimension: 20px X 15px
                      <br />
                      2. Max size 720kb
                      <br />
                      3. File formats supported: png, svg
                    </Text>
                  </Box>
                )}
                {values.employee_badge && !state.showUpload && (
                  <Grid
                    flow="column"
                    gap={10}
                    justifyContent="start"
                    alignItems="center"
                    mb="-12px"
                  >
                    <Text fontSize="bullet" fontWeight={600}>
                      {values.employee_badge.name}
                    </Text>
                    <BadgePreview src={values.employee_badge.url} />
                  </Grid>
                )}
                {values.id && !state.showUpload && (
                  <TextButton
                    textStyle="h6"
                    onClick={() => {
                      state.showUpload = true
                    }}
                  >
                    Reupload badge
                  </TextButton>
                )}
              </InputGroup>
            </>
          )}

          <NewStepperTitle title="Group rule" />
          <InputGroup>
            <HideIfCommercial>
              {dynamicGroupSqlEnabled && (
                <LapeRadioSelectInput<DynamicGroupInerface['audience_selection_type']>
                  name="audience_selection_type"
                  label="Selection mode"
                  selector={selectorKeys.dynamic_group_audience_selection_types}
                  onAfterChange={mode => {
                    if (mode?.id === 'sql') {
                      values.table_filters = undefined
                    }
                    if (mode?.id === 'filtered_table') {
                      values.sql_query = undefined
                      values.sql_query_db = undefined
                    }
                  }}
                />
              )}
              {dynamicGroupSqlEnabled && values.audience_selection_type?.id === 'sql' && (
                <SQLQuery
                  type="dynamic-groups"
                  api={() => runDynamicGroupSQL(values.sql_query, values.sql_query_db)}
                  sqlQueryDbName={values.sql_query_db?.name}
                  queryFieldsToFormFields={{
                    query: 'sql_query',
                    query_db: 'sql_query_db',
                  }}
                />
              )}
            </HideIfCommercial>
            {values.audience_selection_type?.id === 'filtered_table' && (
              <AudienceTable
                filter={initialValues.table_filters}
                onFilterChange={tableFilters => {
                  values.table_filters = tableFilters
                }}
              />
            )}
          </InputGroup>
        </AutoStepper>
      </PageBody>
      <PageActions>
        <NewSaveButtonWithPopup
          disabled={isInternal}
          successText={
            id
              ? 'Dynamic group successfully updated.'
              : 'Dynamic group successfully created.'
          }
          noPopup={fillInterviewerGroupAfterSubmit}
          onAfterSubmit={result => afterSubmit(result as DynamicGroupInerface)}
          afterSubmitUrl={fillInterviewerGroupAfterSubmit ? undefined : backUrl}
          useValidator
        />
      </PageActions>
    </PageWrapper>
  )
}

export default connect(() => <DynamicGroupsForm />)
