import React from 'react'
import { useSelector } from 'react-redux'
import { MoreBar, TableWidget } from '@revolut/ui-kit'
import { queriesTableRequests } from '@src/api/dataAnalytics'
import { navigateTo } from '@src/actions/RouterActions'
import { ROUTES } from '@src/constants/routes'
import {
  queriesNameTableColumn,
  queriesLastRunTableColumn,
  queriesCreatedOnTableColumn,
  queriesOwnerColumn,
  queriesStatusColumn,
  queriesDescriptionTableColumn,
  queriesTenantDeploymentsColumn,
} from '@src/constants/columns/dataAnalytics'
import { TableNames } from '@src/constants/table'
import { RowInterface, FilterByInterface } from '@src/interfaces/data'
import { QueryInterface } from '@src/interfaces/dataAnalytics'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import Stat from '@components/Stat/Stat'
import AdjustableTable from '@components/Table/AdjustableTable'
import { useTable } from '@components/Table/hooks'
import { selectFeatureFlags } from '@src/store/auth/selectors'
import { FeatureFlags, PermissionTypes } from '@src/store/auth/types'
import { pathToUrl } from '@src/utils/router'
import { SettingsButton } from '@src/features/SettingsButtons/SettingsButton/SettingsButton'
import SearchTable from '@components/Table/SearchTable/SearchTable'
import { Statuses } from '@src/interfaces'

const getRow = (canManageDeployment: boolean): RowInterface<QueryInterface> => ({
  linkToForm: ({ id }) => navigateTo(pathToUrl(ROUTES.FORMS.QUERY.GENERAL, { id })),
  cells: [
    {
      ...queriesNameTableColumn,
      width: 200,
    },
    {
      ...queriesLastRunTableColumn,
      width: 200,
    },
    {
      ...queriesCreatedOnTableColumn,
      width: 200,
    },
    {
      ...queriesOwnerColumn,
      width: 200,
    },
    {
      ...queriesStatusColumn,
      width: 200,
    },
    ...(canManageDeployment
      ? [
          {
            ...queriesTenantDeploymentsColumn,
            width: 200,
          },
        ]
      : [
          {
            ...queriesDescriptionTableColumn,
            width: 200,
          },
        ]),
  ],
})

export const QueriesTable = () => {
  const featureFlags = useSelector(selectFeatureFlags)
  const canAdd = featureFlags?.includes(FeatureFlags.ReportingQueriesManagement)
  const canManageDeployment = featureFlags?.includes(FeatureFlags.QueryDeployments)
  const canEditSettings = featureFlags?.includes(
    FeatureFlags.ReportingConnectionsManagement,
  )

  const initialFilterBy: FilterByInterface[] = [
    {
      filters: [
        { name: Statuses.active, id: Statuses.active },
        { name: Statuses.draft, id: Statuses.draft },
      ],
      columnName: 'status',
    },
  ]

  const table = useTable<QueryInterface>(queriesTableRequests, initialFilterBy)

  return (
    <TableWidget>
      <TableWidget.Info>
        <Stat label="Total" mb="s-8" val={table?.loading ? undefined : table?.count} />
      </TableWidget.Info>
      <TableWidget.Search>
        <SearchTable
          placeholder="Search by name or description"
          onFilter={table.onFilterChange}
        />
      </TableWidget.Search>
      <TableWidget.Actions>
        <MoreBar>
          {canAdd && (
            <MoreBar.Action
              to={pathToUrl(ROUTES.FORMS.QUERY.GENERAL)}
              use={InternalLink}
              useIcon="Plus"
            >
              Add new query
            </MoreBar.Action>
          )}
          {canEditSettings ? (
            <SettingsButton
              path={ROUTES.SETTINGS.ANALYTICS}
              canView={[PermissionTypes.UseReportingApp]}
            />
          ) : null}
        </MoreBar>
      </TableWidget.Actions>
      <TableWidget.Table>
        <AdjustableTable
          {...table}
          dataType="Queries"
          hideCount
          name={TableNames.ReportingAppQueries}
          noDataMessage="List of all queries will appear here."
          row={getRow(canManageDeployment)}
          useWindowScroll
        />
      </TableWidget.Table>
    </TableWidget>
  )
}
