import { ProbationTemplateForm } from '@src/pages/Forms/ProbationTemplate'
import { OnboardingActions } from './common/PageActions'
import { ROUTES } from '@src/constants/routes'
import { connect } from 'lape'
import React from 'react'
import { ProbationTemplateFormBody } from '@src/pages/Forms/ProbationTemplate/General'

export const OnboardingProbationTemplateForm = connect(() => {
  return (
    <ProbationTemplateForm>
      <ProbationTemplateFormBody />
      <OnboardingActions
        backPath={ROUTES.ONBOARDING_CHECKLIST_V2.REVIEWS.SETTINGS.PROBATION}
      />
    </ProbationTemplateForm>
  )
})
