import React from 'react'
import { useParams } from 'react-router-dom'
import { AddContact } from '@revolut/icons'
import { Dropdown, MoreBar } from '@revolut/ui-kit'

import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { useCanViewReferrals } from '../common'
import { EmployeeButtonProps } from '../types'

export const ViewReferrals = ({ data, isDropdownItem }: EmployeeButtonProps) => {
  const params = useParams()
  const canView = useCanViewReferrals({ data })

  if (!canView) {
    return null
  }
  return isDropdownItem ? (
    <Dropdown.Item
      use={InternalLink}
      to={pathToUrl(ROUTES.FORMS.EMPLOYEE.REFERRALS, params)}
      useIcon={AddContact}
    >
      View referrals
    </Dropdown.Item>
  ) : (
    <MoreBar.Action
      use={InternalLink}
      to={pathToUrl(ROUTES.FORMS.EMPLOYEE.REFERRALS, params)}
      useIcon={AddContact}
    >
      View referrals
    </MoreBar.Action>
  )
}
