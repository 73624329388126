import React from 'react'
import GenericNotificationTable from '@src/pages/Forms/Notifications/GenericNotificationTable'
import { RowInterface, TabComponentProps } from '@src/interfaces/data'
import { NotificationsInterface } from '@src/interfaces/notifications'
import {
  notificationsActionColumn,
  notificationsEmployeedColumn,
  notificationDescription,
  notificationCategory,
  notificationsDeadline,
} from '@src/constants/columns/notifications'
import { notificationsReviewCalibration } from '@src/api/notifications'
import ChatTagsManager from '@src/components/Chat/ChatTagsManager'
import { Flex } from '@revolut/ui-kit'

const ReviewCalibration = ({
  refreshStats,
  type,
  employeeId,
  filterBy,
  tableRef,
}: TabComponentProps) => {
  const isDashboard = type === 'dashboard'

  const row: RowInterface<NotificationsInterface> = {
    cells: [
      {
        ...notificationDescription(employeeId),
        width: 270,
      },
      {
        ...notificationCategory,
        width: 150,
      },
      {
        ...notificationsEmployeedColumn(isDashboard),
        width: isDashboard ? 90 : 260,
      },
      {
        ...notificationsDeadline,
        width: 150,
      },
      {
        ...notificationsActionColumn,
        width: 140,
      },
    ],
  }

  return (
    <Flex flexDirection="column" width="100%">
      <ChatTagsManager noTooltip>
        <GenericNotificationTable
          rows={row}
          request={notificationsReviewCalibration(employeeId)}
          refreshStats={refreshStats}
          type={type}
          filterBy={filterBy}
          tableRef={tableRef}
        />
      </ChatTagsManager>
    </Flex>
  )
}

export default ReviewCalibration
