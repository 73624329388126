import React, { useEffect, useState } from 'react'
import { selectorKeys } from '@src/constants/api'
import { ActionButton, Group, InputGroup } from '@revolut/ui-kit'
import { ROUTES } from '@src/constants/routes'
import { connect } from 'lape'
import AutoStepper from '@components/Stepper/AutoStepper'
import NewStepperTitle from '@components/Stepper/NewStepperTitle'
import { useLapeContext } from '@src/features/Form/LapeForm'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { pathToUrl } from '@src/utils/router'
import { LapeCopyButton } from '@src/pages/Forms/FormButtons'
import { Grid } from '@components/CommonSC/Grid'
import {
  HiringProcessInterface,
  HiringStageType,
  OnlineTestPlatform,
} from '@src/interfaces/hiringProccess'
import LapeNewSwitch from '@components/Inputs/LapeFields/LapeNewSwitch'
import { PageBody } from '@components/Page/PageBody'
import { PageActions } from '@components/Page/PageActions'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import { navigateTo } from '@src/actions/RouterActions'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { RoleInterface, SpecialisationInterface } from '@src/interfaces/roles'
import HiringStageDeleteAction, {
  getBackUrl,
} from '@src/features/HiringStageDeleteAction/HiringStageDeleteAction'
import { useGetSelectors } from '@src/api/selectors'
import {
  useGetCompanyHiringStage,
  useGetRoleHiringStage,
  useGetSpecialisationHiringProcess,
} from '@src/api/hiringProcess'
import StageDetails from '@src/pages/Forms/HiringStage/FormSections/StageDetails'
import LapeScorecardField from '@src/pages/Forms/HiringStage/FormSections/LapeScorecardField'
import EligibleInterviewers from '@src/pages/Forms/HiringStage/FormSections/EligibleInterviewers'
import { IdAndName } from '@src/interfaces'
import { HiringStagesBankSidebar } from '@src/components/HiringStagesBank/HiringStagesBankSidebar'

const getAfterCopyLink = (type: HiringStageType) => {
  switch (type) {
    case 'general':
      return pathToUrl(ROUTES.FORMS.HIRING_STAGES.GENERAL, {})
    case 'specialisation':
      return pathToUrl(ROUTES.FORMS.HIRING_STAGES.SPECIALISATION, {})
    case 'role':
      return pathToUrl(ROUTES.FORMS.HIRING_STAGES.ROLE, {})
    default:
      return ''
  }
}

export interface Props {
  companyHiringStageId?: number
  roleHiringStageId?: number
  type: HiringStageType
  specialisation?: Partial<SpecialisationInterface>
}

const usePrefillHiringStage = (
  companyHiringStageId?: number,
  roleHiringStageId?: number,
) => {
  const { data: companyHiringStage, isLoading: loadingCompanyHiringStage } =
    useGetCompanyHiringStage(companyHiringStageId)
  const { data: roleHiringStage, isLoading: loadingRoleHiringStage } =
    useGetRoleHiringStage(roleHiringStageId)
  return {
    prefillHiringStage: companyHiringStage || roleHiringStage,
    loadingPrefillHiringStage: loadingCompanyHiringStage || loadingRoleHiringStage,
  }
}

const usePrefillHiringProcess = (hiringProcessId: string) => {
  const { values } = useLapeContext<HiringProcessInterface>()
  const { data } = useGetSpecialisationHiringProcess(
    values.specialisation?.id,
    hiringProcessId,
  )
  if (!values.hiring_process && data) {
    values.hiring_process = data
  }
}

export type HiringStageHistoryState = {
  history?: string[]
  role?: Partial<RoleInterface>
  specialisation?: Partial<SpecialisationInterface>
  companyHiringStageId?: number
  roleHiringStageId?: number
}

export const HiringStage = ({
  companyHiringStageId,
  roleHiringStageId,
  specialisation,
  type,
}: Props) => {
  const location = useLocation<HiringStageHistoryState | undefined>()
  const { values } = useLapeContext<HiringProcessInterface>()
  const { data: testPlatformOptions } = useGetSelectors<IdAndName<OnlineTestPlatform>>(
    selectorKeys.hiring_stage_test_platforms,
  )
  const [showHiringStagesBank, setShowHiringStagesBank] = useState(false)

  const history = useHistory<HiringStageHistoryState>()

  const params = useParams<{ hiringProcessId: string }>()
  usePrefillHiringProcess(params.hiringProcessId)

  const { data: hiringProcess } = useGetSpecialisationHiringProcess(
    values.specialisation?.id,
    params.hiringProcessId,
  )
  if (!values.hiring_process && hiringProcess) {
    values.hiring_process = hiringProcess
  }

  const { prefillHiringStage, loadingPrefillHiringStage } = usePrefillHiringStage(
    companyHiringStageId,
    roleHiringStageId,
  )

  const copyStage = (stage: HiringProcessInterface) => {
    values.title = stage.title
    values.stage_type = stage.stage_type
    values.test_platform = stage.test_platform
    values.skills = stage.skills
    values.scorecard_template = stage.scorecard_template
    values.duration = stage.duration
    values.duration_unit = stage.duration_unit
    values.playbook_link = stage.playbook_link
    values.interviewer_type = stage.interviewer_type
    values.interviewers_groups = stage.interviewers_groups
    values.interviewers = stage.interviewers
    values.is_limited_by_seniorities = stage.is_limited_by_seniorities
    if (values.is_limited_by_seniorities) {
      values.seniority_min = stage.seniority_min
      values.seniority_max = stage.seniority_max
    }
  }

  useEffect(() => {
    if (prefillHiringStage) {
      copyStage(prefillHiringStage)
      // calling history.replace to remove companyHiringStageId and roleHiringStageId
      // to avoid prefilling again
      history.replace(history.location.pathname, {
        history: history.location.state.history,
        role: history.location.state.role,
        specialisation: history.location.state.specialisation,
      })
    }
  }, [prefillHiringStage])

  const isGeneral = type === 'general'
  const isRole = type === 'role'
  const isSpecialisation =
    type === 'specialisation' || type === 'specialisation-hiring-process'

  const isDisabled =
    !isGeneral &&
    (values?.is_company_standard_round ||
      (isSpecialisation && values?.limit_editing_in_specialisation))

  const isLastStage =
    values?.is_company_standard_round ||
    values?.limit_editing_in_specialisation ||
    isSpecialisation

  useEffect(() => {
    if (isRole && !values.id && location.state?.role) {
      values.role = { id: location.state.role.id!, name: location.state.role.name }
    } else if (isSpecialisation && !values.id && specialisation) {
      values.specialisation = {
        id: specialisation.id!,
        name: specialisation.name,
      }
    }
  }, [])

  const isOnlineTest = values.stage_type?.id === 'online_test'
  const isCVScreening = values.stage_type?.id === 'cv_screening'

  useEffect(() => {
    if (isOnlineTest && testPlatformOptions?.length && !values.test_platform) {
      values.test_platform = testPlatformOptions[0]
    }

    if (!isOnlineTest) {
      values.test_platform = undefined
      values.online_test = undefined
    }
  }, [testPlatformOptions, isOnlineTest])
  const renderAdvancedSettings = () => {
    return (
      <InputGroup>
        <Group>
          <LapeNewSwitch
            disabled={isDisabled}
            name="is_limited_by_seniorities"
            itemTypeProps={{ title: 'Limited by seniorities' }}
          />
        </Group>
        {values?.is_limited_by_seniorities && (
          <InputGroup variant="horizontal">
            <LapeRadioSelectInput<HiringProcessInterface>
              name="seniority_min"
              label="Min Seniority"
              selector={selectorKeys.seniority}
            />
            <LapeRadioSelectInput<HiringProcessInterface>
              name="seniority_max"
              label="Max Seniority"
              selector={selectorKeys.seniority}
            />
          </InputGroup>
        )}
      </InputGroup>
    )
  }

  return (
    <>
      {showHiringStagesBank && (
        <HiringStagesBankSidebar
          confirmLabel="Copy stage"
          disableCVScreening={false}
          hasCVScreening={false}
          includeRoles={!isGeneral}
          loading={false}
          pending={false}
          singleSelect
          specialisationId={values.specialisation?.id}
          onConfirm={res => {
            const stage = res.companyStages[0] || res.roleStages[0]
            copyStage(stage)
            setShowHiringStagesBank(false)
          }}
          onClose={() => {
            setShowHiringStagesBank(false)
          }}
        />
      )}
      <PageBody>
        <AutoStepper>
          <Grid
            mb="s-32"
            flow="column"
            justifyContent="start"
            alignItems="center"
            gap={16}
          >
            {values.id && <HiringStageDeleteAction type={type} label="Delete" />}
            <LapeCopyButton type="hiring-stage" afterSubmitUrl={getAfterCopyLink(type)} />
            {!values.id && (
              <ActionButton
                useIcon="Library"
                onClick={() => {
                  setShowHiringStagesBank(!showHiringStagesBank)
                }}
              >
                Copy from hiring stages bank
              </ActionButton>
            )}
          </Grid>
          <NewStepperTitle title="Stage details" />
          <StageDetails
            isDisabled={isDisabled}
            isSpecialisation={isSpecialisation}
            showOnlineTestSettings
            scorecard={
              <LapeScorecardField
                canAddNewScorecard
                isClearable
                isDisabled={!!isDisabled}
                isRequired
              />
            }
            isRole={isRole}
          />
          {!isOnlineTest && !isCVScreening && (
            <>
              <NewStepperTitle title="Eligible interviewers" />
              {<EligibleInterviewers isDisabled={isDisabled} isRequired={isLastStage} />}
            </>
          )}
          {!isCVScreening && (
            <>
              <NewStepperTitle title="Advanced settings" />
              {renderAdvancedSettings()}
            </>
          )}
        </AutoStepper>
      </PageBody>
      {!isDisabled && (
        <PageActions>
          <NewSaveButtonWithPopup<HiringProcessInterface>
            successText={values.id ? 'Hiring stage updated' : 'Hiring stage created'}
            onAfterSubmit={() => {
              navigateTo(getBackUrl(type, values), {
                stageId: values.id,
              })
            }}
            disabled={loadingPrefillHiringStage}
            pending={loadingPrefillHiringStage}
            useValidator
          />
        </PageActions>
      )}
    </>
  )
}

export default connect((props: Props) => {
  const location = useLocation<HiringStageHistoryState | undefined>()
  const { companyHiringStageId, roleHiringStageId, specialisation } = location.state ?? {}
  return (
    <HiringStage
      {...props}
      companyHiringStageId={companyHiringStageId}
      roleHiringStageId={roleHiringStageId}
      specialisation={specialisation}
    />
  )
})
