import React from 'react'
import ScheduleSidebar from '@src/pages/Forms/Candidate/ScheduleSidebar/ScheduleSidebar'
import { useGetCandidateSettings } from '@src/api/settings'
import { useCandidateProfileContext } from '@src/pages/Forms/Candidate/CandidateProfileContext'
import { useFetchInterviewStages } from '@src/pages/Forms/Candidate/InterviewProgress/useFetchStagesTable'
import { InterviewRoundInterface } from '@src/interfaces/interviewTool'

type Props = {
  round?: InterviewRoundInterface
  refreshStats: () => void
}

export const CandidateSchedulingInterview = ({ round, refreshStats }: Props) => {
  const {
    activeAction,
    activeStage,
    resetActiveAction,
    setActiveStage,
    setActiveAction,
  } = useCandidateProfileContext()
  const { data: candidateSettings } = useGetCandidateSettings()
  const { data: stages, refetch: refetchStages } = useFetchInterviewStages(round?.id)

  if (!candidateSettings?.enable_scheduling || activeAction?.type !== 'schedule') {
    return null
  }

  return (
    <ScheduleSidebar
      selectedStageId={activeStage?.id}
      stages={stages}
      totalStagesCount={stages.length}
      roundId={round?.id}
      onClose={() => {
        setActiveStage(undefined)
        resetActiveAction()
      }}
      onRefresh={() => {
        refetchStages()
        refreshStats()
      }}
      onSchedulingSuccess={stage => {
        setActiveStage(stage)
      }}
      initialMode={activeAction?.mode}
      interviewId={activeAction?.interviewId}
      isPrepCall={activeAction?.isPrepCall}
      onChangeInitialMode={mode => {
        setActiveAction({
          ...activeAction,
          mode,
        })
      }}
    />
  )
}
