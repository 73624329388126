import React from 'react'
import get from 'lodash/get'
import { PermissionTypes } from '@src/store/auth/types'
import { isAfter, isSameDay } from 'date-fns'
import {
  PerformanceSelector,
  PerfReviewRequestFeedbackInterface,
  Ratings,
  RelationsCannotReject,
  RequestFeedbackInterface,
  ReviewCategory,
  ReviewInterface,
} from '@src/interfaces/performance'
import { ReviewCycleCategory } from '@src/interfaces/reviewCycles'
import { EmployeeInterface } from '@src/interfaces/employees'
import {
  useGetFeedbackRequests,
  useGetPerfReviewFeedbackRequests,
} from '@src/api/performance'
import { filterSortPageIntoQuery } from '@src/utils/table'
import { Statuses } from '@src/interfaces'
import { relationToString } from '@src/features/Scorecard/constants'
import { Color, Text } from '@revolut/ui-kit'

export const checkCanRequestFeedback = (data: EmployeeInterface) =>
  get(data, 'field_options.permissions', []).includes(PermissionTypes.RequestReview)

export const checkCanViewPIPv2Settings = (data?: PerformanceSelector) => {
  return data?.extra_permissions_by_version?.[0]?.permissions.includes(
    PermissionTypes.CanViewPipSettings,
  )
}

export const checkIsProbationPeriod = (data: EmployeeInterface) =>
  isAfter(new Date(data.end_of_probation_date_time), new Date()) ||
  isSameDay(new Date(data.end_of_probation_date_time), new Date())

export const checkIsPipPeriod = (data?: PerformanceSelector) => {
  if (data && data.category === ReviewCycleCategory.PIP) {
    return (
      isAfter(new Date(data.end_date_time), new Date()) ||
      isSameDay(new Date(data.start_date_time), new Date())
    )
  }
  return false
}

export const isNewPerformancePeriod = (period: PerformanceSelector) => {
  return (
    period.category === ReviewCycleCategory.Performance &&
    isAfter(new Date(period.end_date_time), new Date('2020-05-01'))
  )
}

export const getReviewer = (
  request: PerfReviewRequestFeedbackInterface | RequestFeedbackInterface,
  isNewFlow: boolean,
) => {
  const reviewer = isNewFlow
    ? (request as PerfReviewRequestFeedbackInterface).reviewer
    : (request as RequestFeedbackInterface).reviewed_by

  return reviewer
}

export interface NewFlowRequestsResponse {
  results: PerfReviewRequestFeedbackInterface[]
  status?: Statuses
  requester_upwards_review_status: Statuses
}

export interface PerformanceCycleIdCategory
  extends Pick<PerformanceSelector, 'id' | 'category'> {}

export const useFetcherPerformanceRequests = ({
  performanceCycle,
  category,
  isNewFlow,
  id,
}: {
  performanceCycle?: PerformanceCycleIdCategory
  category: ReviewCategory | ReviewCycleCategory
  isNewFlow: boolean
  id?: number
}) => {
  let selectorsFilters

  if (performanceCycle) {
    selectorsFilters =
      performanceCycle.category === ReviewCycleCategory.Probation ||
      category === ReviewCategory.PIP_V2
        ? [
            {
              filters: [
                {
                  name: 'category',
                  id:
                    performanceCycle.category === ReviewCycleCategory.Probation
                      ? ReviewCycleCategory.Probation
                      : ReviewCategory.PIP_V2,
                },
              ],
              columnName: 'category',
            },
          ]
        : [
            {
              filters: [{ name: `${performanceCycle.id}`, id: `${performanceCycle.id}` }],
              columnName: 'cycle__id',
            },
            {
              filters: [
                {
                  name: 'category',
                  id:
                    category === ReviewCategory.Upwards
                      ? ReviewCategory.Upwards
                      : performanceCycle.category === ReviewCycleCategory.PIP
                      ? ReviewCategory.PIP_V2
                      : performanceCycle.category,
                },
              ],
              columnName: 'category',
            },
          ]
  }

  const perfReviewContext = useGetPerfReviewFeedbackRequests(
    isNewFlow && selectorsFilters && id !== undefined ? id : null,
    performanceCycle?.id !== undefined ? String(performanceCycle.id) : null,
    filterSortPageIntoQuery(undefined, selectorsFilters, undefined),
  )

  // for old probation/performance and PIP
  const feedbacksContext = useGetFeedbackRequests(
    !isNewFlow && selectorsFilters && id !== undefined ? id : null,
    filterSortPageIntoQuery(undefined, selectorsFilters, undefined),
  )

  return isNewFlow ? perfReviewContext : feedbacksContext
}

export const checkRejectAvailability = (
  request?: PerfReviewRequestFeedbackInterface | RequestFeedbackInterface,
) => {
  if (!request) {
    return false
  }

  // we could reject only performance review
  if (request.category !== ReviewCategory.Performance) {
    return false
  }

  if (
    RelationsCannotReject.includes(
      (request as PerfReviewRequestFeedbackInterface).reviewer_relation,
    )
  ) {
    return false
  }

  if (request.status !== Statuses.pending && request.status !== Statuses.draft) {
    return false
  }

  return true
}

export const getReviewerTitleAndRating = (
  review: ReviewInterface,
  ratings?: Ratings[],
) => {
  const rating = ratings?.find(item => item.review.id === review.id)?.value
  let title = <>{review.reviewer.display_name}</>
  if (review.reviewer_relation) {
    title = (
      <>
        {title}{' '}
        <Text color={Color.GREY_TONE_50}>
          · {relationToString(review.reviewer_relation, true)}
        </Text>
      </>
    )
  }
  if (review.probation_checkpoint) {
    title = (
      <>
        CP{review.probation_checkpoint.number} · {title}
      </>
    )
  }

  return {
    rating,
    title,
  }
}
