import { lape } from 'lape'

export interface GlobalSearchState {
  open: boolean
  showAction: boolean
}

const defaultState: GlobalSearchState = {
  open: false,
  showAction: false,
}

export default lape(defaultState)
