import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { ROUTES } from '@src/constants/routes'
import { InviteTeam } from './InviteTeam'
import { selectFeatureFlags } from '@src/store/auth/selectors'
import { FeatureFlags } from '@src/store/auth/types'
import { InternalRedirect } from '@src/components/InternalLink/InternalRedirect'

export const PlatformOnboarding = () => {
  const featureFlags = useSelector(selectFeatureFlags)
  const isOnboardingV2 = featureFlags.includes(FeatureFlags.OnboardingFlowV2)

  return (
    <Switch>
      <Route path={ROUTES.PLATFORM_ONBOARDING.INVITE_TEAM} exact>
        {isOnboardingV2 ? (
          <InternalRedirect to={ROUTES.INVITE_TEAM.REVIEW} />
        ) : (
          <InviteTeam />
        )}
      </Route>
    </Switch>
  )
}
