import {
  ContractorInterface,
  EmployeeInterface,
  AvatarType,
} from '@src/interfaces/employees'
import { localDateToUtc } from '@src/utils/timezones'

export const getInitials = (fullName: string) =>
  fullName.split(' ').reduce((acc, chunk, index, arr) => {
    if (index === 0 || index === arr.length - 1) {
      return acc + (chunk[0] || '')
    }
    return acc
  }, '')

export const getProbationEndDate = (startDateTimestamp: string, months: number) => {
  const startDate = new Date(startDateTimestamp)
  const endDate = new Date()
  endDate.setMonth(startDate.getMonth() + months)
  endDate.setDate(startDate.getDate() - 1)

  return localDateToUtc(endDate)
}

export const updateProbationEndDate = (
  startDateTimestamp: string,
  months: number,
  values:
    | EmployeeInterface
    | ContractorInterface
    | Pick<EmployeeInterface, 'end_of_probation_date_time'>,
) => {
  values.end_of_probation_date_time = getProbationEndDate(startDateTimestamp, months)
}

export const getAvatarUrl = (avatar?: AvatarType, size: 'sm' | 'md' = 'sm') => {
  if (!avatar) {
    return undefined
  }
  if (typeof avatar === 'string') {
    return avatar
  }
  return size === 'sm' ? avatar.image_72 : avatar.image_192
}
