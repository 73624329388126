import React, { useEffect, useState } from 'react'
import {
  Box,
  Cell,
  DragAndDrop,
  Flex,
  InputGroup,
  VStack,
  ActionButton,
} from '@revolut/ui-kit'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import Tooltip from '@components/Tooltip/Tooltip'
import { selectorKeys } from '@src/constants/api'
import { IdAndName } from '@src/interfaces'
import useFetchOptions from '@components/Inputs/hooks/useFetchOptions'
import DragIcon from '@src/components/DragIcon'
import {
  WorkScheduleApprovalChainInterface,
  WorkScheduleApprovalsStepInterface,
  WorkScheduleApproverType,
} from '@src/interfaces/workSchedule'
import { EmployeeOptionInterface } from '@src/interfaces/employees'
import SectionTitle from '@src/pages/OnboardingChecklist/components/SectionTitle'
import { RadioSelectOption } from '@components/Inputs/RadioSelectInput/RadioSelectInput'

type ApproverInputsProps<T> = {
  values: T
  inputIdx: number
  employeesOptions: RadioSelectOption<IdAndName>[]
  dynamicGroupsOptions: RadioSelectOption<IdAndName>[]
}
const ApproverInputs = <T extends WorkScheduleApprovalChainInterface>({
  values,
  inputIdx,
  employeesOptions,
  dynamicGroupsOptions,
}: ApproverInputsProps<T>) => {
  const approverTypePath =
    `approval_steps_configuration[${inputIdx}].approver_type` as keyof WorkScheduleApprovalsStepInterface
  const inputsConfig = values.approval_steps_configuration[inputIdx]
  const approverType = inputsConfig?.approver_type.id

  return (
    <Flex width="100%">
      <Box width={180}>
        <LapeRadioSelectInput
          label="Select approver type"
          searchable={false}
          name={approverTypePath}
          selector={selectorKeys.approval_step_approver_types}
          onChange={newValue => {
            if (newValue) {
              values.approval_steps_configuration[inputIdx] = {
                ...values.approval_steps_configuration[inputIdx],
                approver_type: newValue as IdAndName<WorkScheduleApproverType>,
                relationship: null,
                employee_approver: null,
                group_approver: null,
              }
            }
          }}
        />
      </Box>
      <Box width="100%" ml="s-8">
        <InputGroup>
          {approverType === 'employee' && (
            <Flex flex="1 0" width="100%">
              <LapeRadioSelectInput
                label="Select employee"
                name={`approval_steps_configuration[${inputIdx}].employee_approver`}
                options={employeesOptions}
              />
            </Flex>
          )}
          {approverType === 'group' && (
            <LapeRadioSelectInput
              label="Select dynamic group"
              name={`approval_steps_configuration[${inputIdx}].group_approver`}
              options={dynamicGroupsOptions}
            />
          )}
          {approverType === 'relationship' && (
            <LapeRadioSelectInput
              label="Select relationship"
              name={`approval_steps_configuration[${inputIdx}].relationship`}
              options={[
                {
                  label: 'Line manager',
                  value: { id: 'line_manager', name: 'Line manager' },
                },
                {
                  label: 'Department head',
                  value: { id: 'department_head', name: 'Department head' },
                },
              ]}
            />
          )}
        </InputGroup>
      </Box>
    </Flex>
  )
}

type Props<T> = {
  values: T
}
export const ApprovalChainInput = <T extends WorkScheduleApprovalChainInterface>({
  values,
}: Props<T>) => {
  useEffect(() => {
    if (!values.approval_steps_configuration?.length) {
      values.approval_steps_configuration = [
        {
          id: 0,
          approver_type: { id: 'employee', name: 'Employee' },
          relationship: null,
          employee_approver: null,
          group_approver: null,
        },
      ]
    }
  }, [])

  const dynamicGroupsOptionsData = useFetchOptions<IdAndName>(
    selectorKeys.dynamic_groups,
    undefined,
    'label',
  )
  const dynamicGroupsOptions = dynamicGroupsOptionsData.options.map(({ value }) => ({
    value,
    label: value.name,
  })) as RadioSelectOption<IdAndName>[]

  const employeesOptionsData = useFetchOptions<EmployeeOptionInterface>(
    selectorKeys.all_employees_avatar_email,
  )
  const employeesOptions = employeesOptionsData.options as RadioSelectOption<IdAndName>[]

  const [nextAddedStepNum, setNextAddedStepNum] = useState(1)
  const [activeId, setActiveId] = useState<string | number | null>(null)
  const approvalStepsConfigs = values.approval_steps_configuration || []

  const activeApproverIdx = activeId
    ? approvalStepsConfigs.findIndex(({ id }) => String(id) === activeId)
    : -1
  const activeApprover =
    activeApproverIdx >= 0 ? approvalStepsConfigs[activeApproverIdx] : undefined

  return (
    <Cell>
      <VStack gap="s-8" width="100%">
        <Box mt="-s-8">
          <SectionTitle
            title="Who should approve these requests?"
            subtitle="Define the approvers and the order for overtime & night shift requests"
          />
        </Box>
        <>
          <InputGroup>
            <DragAndDrop.Provider
              onDragStart={event => {
                setActiveId(event.active.id)
              }}
              onDragEnd={({ over }) => {
                const overIdx = approvalStepsConfigs.findIndex(
                  approver => String(approver.id) === over?.id,
                )
                const approversWithoutDragged = approvalStepsConfigs.filter(
                  ({ id }) => String(id) !== activeId,
                )
                if (overIdx >= 0 && activeApprover) {
                  values.approval_steps_configuration = [
                    ...approversWithoutDragged.slice(0, overIdx),
                    activeApprover,
                    ...approversWithoutDragged.slice(
                      overIdx,
                      approvalStepsConfigs.length - 1,
                    ),
                  ].map((approver, idx) => ({ ...approver, sort_order: idx }))
                }
                setActiveId(null)
              }}
            >
              <DragAndDrop.Sortable
                id="sortable"
                items={approvalStepsConfigs.map(({ id }) => String(id))}
              >
                {sortable => {
                  const approverIdx = approvalStepsConfigs.findIndex(
                    ({ id }) => String(id) === sortable.id,
                  )
                  const approver = approvalStepsConfigs[approverIdx]

                  if (!approver) {
                    return null
                  }
                  return (
                    <Flex
                      gap="s-8"
                      alignItems="center"
                      ref={sortable?.setNodeRef}
                      {...sortable?.attributes}
                      {...sortable?.listeners}
                      style={
                        sortable
                          ? {
                              transform: sortable.transform
                                ? `translate3d(${sortable.transform.x}px, ${sortable.transform.y}px, 0)`
                                : undefined,
                              transition: sortable.transition || 'none',
                              opacity: sortable.isDragging ? 0 : undefined,
                              zIndex: 10,
                            }
                          : undefined
                      }
                    >
                      <DragIcon />
                      <ApproverInputs
                        values={values}
                        inputIdx={approverIdx}
                        employeesOptions={employeesOptions}
                        dynamicGroupsOptions={dynamicGroupsOptions}
                      />
                      <Tooltip
                        placement="bottom"
                        hide={approvalStepsConfigs.length !== 1}
                        text="At least one approver should be added"
                      >
                        <ActionButton
                          iconOnly
                          useIcon="Minus"
                          onClick={() => {
                            values.approval_steps_configuration =
                              approvalStepsConfigs.filter((_, idx) => idx !== approverIdx)
                          }}
                          disabled={approvalStepsConfigs.length === 1}
                        />
                      </Tooltip>
                    </Flex>
                  )
                }}
              </DragAndDrop.Sortable>
              <DragAndDrop.DragOverlay>
                {activeApprover && (
                  <Flex gap="s-8" alignItems="center">
                    <DragIcon />
                    <ApproverInputs
                      values={values}
                      inputIdx={activeApproverIdx}
                      employeesOptions={[]}
                      dynamicGroupsOptions={[]}
                    />
                    <ActionButton iconOnly useIcon="Minus" />
                  </Flex>
                )}
              </DragAndDrop.DragOverlay>
            </DragAndDrop.Provider>
          </InputGroup>
          <ActionButton
            mt="s-16"
            width={160}
            useIcon="Plus"
            onClick={() => {
              values.approval_steps_configuration[approvalStepsConfigs.length] = {
                id: nextAddedStepNum,
                approver_type: { id: 'employee', name: 'Employee' },
                relationship: null,
                employee_approver: null,
                group_approver: null,
              }
              setNextAddedStepNum(nextAddedStepNum + 1)
            }}
          >
            Add approver
          </ActionButton>
        </>
      </VStack>
    </Cell>
  )
}
