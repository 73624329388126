import React, { useEffect, useState } from 'react'
import { useStatusPopup, StatusPopup, Button } from '@revolut/ui-kit'
import { useParams } from 'react-router-dom'

import { PageWrapper } from '@src/components/Page/Page'
import {
  deleteInvoice,
  downloadInvoice,
  useSubscriptionInvoiceDetails,
  payInvoice,
  usePayInvoiceState,
} from '@src/api/plans'
import { PageActions } from '@src/components/Page/PageActions'
import { getStringMessageFromError } from '@src/store/notifications/actions'
import { InvoiceDetailsPage } from './InvoiceDetailsPage'
import { navigateTo } from '@src/actions/RouterActions'
import { ROUTES } from '@src/constants/routes'

export const InvoiceDetails = () => {
  const params = useParams<{ id: string }>()

  const [paymentId, setPaymentId] = useState<number>()

  const statusPopup = useStatusPopup()

  const data = useSubscriptionInvoiceDetails(params.id)

  const { data: invoiceState, isError } = usePayInvoiceState(params.id, paymentId)

  useEffect(() => {
    if (invoiceState?.status.id === 'failure') {
      data.refetch()
      statusPopup.show(
        <StatusPopup variant="error" onClose={statusPopup.hide}>
          <StatusPopup.Title>Payment failed</StatusPopup.Title>
          <StatusPopup.Description>
            There was a problem with your payment method. Please check payment details.
          </StatusPopup.Description>
          <StatusPopup.Actions>
            <Button
              onClick={() => {
                statusPopup.hide()
                navigateTo(ROUTES.PLANS.PAYMENT_DETAILS)
              }}
              elevated
            >
              Review payment details
            </Button>
            <Button onClick={statusPopup.hide} variant="secondary">
              Close
            </Button>
          </StatusPopup.Actions>
        </StatusPopup>,
      )
    }

    if (isError) {
      data.refetch()
      statusPopup.show(
        <StatusPopup variant="error" onClose={statusPopup.hide}>
          <StatusPopup.Title>Payment failed, you were not charged</StatusPopup.Title>
          <StatusPopup.Description>
            Something went wrong on our side. Try again later
          </StatusPopup.Description>
          <StatusPopup.Actions>
            <Button onClick={statusPopup.hide} elevated>
              Got it
            </Button>
          </StatusPopup.Actions>
        </StatusPopup>,
      )
    }

    if (invoiceState?.status.id === 'success') {
      data.refetch()
      statusPopup.show(
        <StatusPopup variant="success">
          <StatusPopup.Title>Success</StatusPopup.Title>
          <StatusPopup.Description>
            Invoice has been paid. Your payment was processed successfully
          </StatusPopup.Description>
        </StatusPopup>,
      )
    }
  }, [invoiceState, isError])

  const onPayInvoice = () => {
    statusPopup.show(
      <StatusPopup variant="loading" preventUserClose>
        <StatusPopup.Title>Processing payment</StatusPopup.Title>
        <StatusPopup.Description>
          This should take a couple of seconds
        </StatusPopup.Description>
      </StatusPopup>,
    )

    payInvoice(params.id)
      .then(response => {
        setPaymentId(response.data.id)
      })
      .catch(err => {
        statusPopup.show(
          <StatusPopup variant="error" onClose={statusPopup.hide}>
            <StatusPopup.Title>Failed to pay invoice</StatusPopup.Title>
            <StatusPopup.Description>
              {getStringMessageFromError(err)}
            </StatusPopup.Description>
            <StatusPopup.Actions>
              <Button onClick={statusPopup.hide} elevated variant="secondary">
                Close
              </Button>
            </StatusPopup.Actions>
          </StatusPopup>,
        )
      })
  }

  return (
    <PageWrapper>
      <InvoiceDetailsPage
        data={data}
        downloadInvoiceCallback={() => downloadInvoice(params.id)}
        deleteInvoice={() => deleteInvoice(params.id)}
        backUrl={ROUTES.PLANS.INVOICES}
        showMarkAsPaidButton={false}
      />
      {data.data?.status?.id === 'open' && !data.data?.manual_payment ? (
        <PageActions>
          <Button onClick={onPayInvoice} elevated>
            Pay invoice
          </Button>
        </PageActions>
      ) : null}
    </PageWrapper>
  )
}
