import React, { useMemo } from 'react'
import get from 'lodash/get'
import groupBy from 'lodash/groupBy'

import { MultiLineChart } from '@components/MultiLineChart/Chart'
import { Box, Color, Flex, Spinner, StatusWidget } from '@revolut/ui-kit'
import { useEngagementSurveyResultsChart } from '@src/api/engagement'
import {
  EngagementQuestionChartDatum,
  EngagementResultsEntities,
  EngagementResultsScope,
} from '@src/interfaces/engagement'
import {
  ChartTooltip,
  DataPoint,
} from '@src/apps/People/Engagement/Results/components/ChartTooltip/ChartTooltip'
import { useChartDataByEntityId } from '@src/apps/People/Engagement/Results/components/ChartTooltip/hooks'

const TABLE_HEIGHT = 300
const MAX_NUM_TICKS = 5

type Props = {
  isAverage: boolean
  scope?: EngagementResultsScope
  entity: EngagementResultsEntities
  scopeId: number
  entityId: number | undefined
}
export const ResultsChart = ({
  isAverage,
  scope,
  entity,
  scopeId,
  entityId: questionId,
}: Props) => {
  const { data, isFetching } =
    useEngagementSurveyResultsChart<EngagementQuestionChartDatum>({
      scope,
      entity,
      scopeId,
    })
  const chartData = data?.results || []

  const chartDataByQuestion = useMemo(() => {
    return groupBy(chartData, d => d.question.id)
  }, [chartData])

  const { data: dataBySelectedQuestion, xAxis } = useChartDataByEntityId({
    id: questionId,
    data: chartDataByQuestion,
    scoreAccessor: d => (isAverage ? d.average_score : d.nps_score),
  })

  if (isFetching || !questionId || !xAxis) {
    return (
      <Box display="flex" height={TABLE_HEIGHT}>
        <Flex flex="1 0" alignItems="center" justifyContent="center">
          <Spinner size={48} color={Color.BLUE} />
        </Flex>
      </Box>
    )
  }

  if (dataBySelectedQuestion.length < 2) {
    return (
      <StatusWidget>
        <StatusWidget.Image
          src="https://assets.revolut.com/assets/3d-images/3D086.png"
          srcSet="https://assets.revolut.com/assets/3d-images/3D086@2x.png 2x, https://assets.revolut.com/assets/3d-images/3D086@3x.png 3x"
        />
        <StatusWidget.Title>No results found</StatusWidget.Title>
        <StatusWidget.Description>
          The question must have been answered in at least two surveys
        </StatusWidget.Description>
      </StatusWidget>
    )
  }

  return (
    <Box height={TABLE_HEIGHT}>
      <MultiLineChart<DataPoint>
        role="img"
        dataSet={dataBySelectedQuestion}
        showLegend
        alignLegendItemsToLines
        xAxis={{
          key: 'xAxis',
          numTicks: Math.min(MAX_NUM_TICKS, dataBySelectedQuestion.length - 1),
          toLabel: xAxisValue => {
            return get(dataBySelectedQuestion[xAxisValue], 'label', '')
          },
        }}
        yAxis={{
          numTicks: 6,
          minTickValue: isAverage ? 0 : -100,
          maxTickValue: isAverage ? 5 : 100,
        }}
        lines={{
          [chartDataByQuestion[questionId][0].question.name]: {
            name: chartDataByQuestion[questionId][0].question.name,
            accessor: d => d.score,
            color: Color.GREEN,
          },
        }}
        margin={{ left: 80 }}
        renderDetailedTooltip={(dataPoint: DataPoint) => (
          <ChartTooltip dataPoint={dataPoint} isAverage={isAverage} />
        )}
      />
    </Box>
  )
}
