import React, { useState } from 'react'
import { connect } from 'lape'
import {
  TabBar,
  Box,
  Calendar,
  InputGroup,
  useTooltip,
  Tooltip,
  Text,
  VStack,
  TextWidget,
  Flex,
} from '@revolut/ui-kit'
import NewStepperTitle from '@src/components/Stepper/NewStepperTitle'
import AutoStepper from '@components/Stepper/AutoStepper'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import { ExclamationMarkOutline, NavigationForward } from '@revolut/icons'
import { reviewCyclesRequestsNew } from '@src/api/reviewCycles'
import LapeDatePickerInput from '@src/components/Inputs/LapeFields/LapeDatePickerInput'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { PageWrapper } from '@components/Page/Page'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { ROUTES } from '@src/constants/routes'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { PageBody } from '@src/components/Page/PageBody'
import { PageActions } from '@src/components/Page/PageActions'
import LapeNewTimeInput from '@components/Inputs/LapeFields/LapeNewTimeInput'
import { getDate, getTimeFromString } from '@src/utils/timezones'
import Form from '@src/features/Form/Form'
import { pathToUrl } from '@src/utils/router'
import { useParams } from 'react-router'
import { getFormTitle } from '@src/pages/Forms/ReviewCycle/GeneralInfoForm'
import { format } from 'date-fns'
import { useSelector } from 'react-redux'
import { selectFeatureFlags } from '@src/store/auth/selectors'
import { FeatureFlags } from '@src/store/auth/types'

const formatDate = (date: Date, time?: string) => {
  const day = format(date, 'yyyy-MM-dd')
  return `${day}T${time || '14:00'}:00Z`
}

export enum tabsCategory {
  All = 'all',
  Separate = 'separate',
}

const Timeline = connect(() => {
  const { values } = useLapeContext<ReviewCyclesInterface>()
  const [additionalOpened, setAdditionalOpened] = useState(false)
  const params = useParams<{ id: string }>()

  const featureFlags = useSelector(selectFeatureFlags)
  const goalsEnabled = featureFlags.includes(FeatureFlags.CanAddGoals)
  const goalsTitle = goalsEnabled ? 'Goals' : 'KPI'

  const kpiDatesAligned =
    values.department_kpi_period_start_day === values.team_kpi_period_start_day &&
    values.department_kpi_period_end_day === values.team_kpi_period_end_day
  const [kpiCurrentTab, setKpiCurrentTab] = useState<tabsCategory>(
    kpiDatesAligned ? tabsCategory.All : tabsCategory.Separate,
  )

  const reviewDatesAligned =
    values.managers_reviews_last_day === values.self_and_peer_reviews_last_day
  const [reviewCurrentTab, setReviewCurrentTab] = useState<tabsCategory>(
    reviewDatesAligned ? tabsCategory.All : tabsCategory.Separate,
  )

  const kpiTooltip = useTooltip()
  const reviewTooltip = useTooltip()

  const kpiTabs = [
    {
      title: 'For all levels',
      id: tabsCategory.All,
    },
    {
      title: 'For separate levels',
      id: tabsCategory.Separate,
      tooltip:
        'This is an advanced setting which will overwrite the calendar for all levels',
    },
  ]

  const reviewTabs = [
    {
      title: 'For all reviewers',
      id: tabsCategory.All,
    },
    {
      title: 'For separate reviewers',
      id: tabsCategory.Separate,
      tooltip:
        'This is an advanced setting which will overwrite the calendar for all levels',
    },
  ]

  const onDateTimeChange = (field: string, value?: Date | null) => {
    if (!value) {
      return
    }
    /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
    const time = values[field] ? getTimeFromString(values[field]) : '00:00'
    /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
    values[field] = `${getDate(value)}T${time}:00Z`
  }

  const onTimeChange = (field: string, value?: string) => {
    if (!value) {
      return
    }
    /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
    const date = values[field]
    if (date) {
      /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
      values[field] = `${getDate(new Date(date))}T${value}:00Z`
    }
  }

  return (
    <PageWrapper>
      <PageHeader
        title={getFormTitle(values)}
        backUrl={pathToUrl(ROUTES.FORMS.REVIEW_CYCLES.GENERAL, params)}
      />

      <PageBody>
        <AutoStepper>
          <>
            <NewStepperTitle
              title={`${goalsTitle} setting: When should ${
                goalsEnabled ? 'Goals' : 'KPIs'
              } be added for this cycle?`}
              subtitle="Select the start and end dates"
            />
            <InputGroup>
              <TabBar
                value={kpiCurrentTab}
                variant="segmented"
                mb="s-16"
                maxWidth="400px"
              >
                {kpiTabs.map(tab => (
                  <TabBar.Item
                    key={tab.id}
                    onClick={() => setKpiCurrentTab(tab.id)}
                    aria-selected={kpiCurrentTab === tab.id}
                  >
                    <Text>{tab.title}</Text>
                    {tab.tooltip && (
                      <Box ml="s-4">
                        <ExclamationMarkOutline
                          size={15}
                          {...kpiTooltip.getAnchorProps()}
                        />
                        <Tooltip
                          {...kpiTooltip.getTargetProps()}
                          maxWidth={230}
                          placement="right"
                        >
                          {tab.tooltip}
                        </Tooltip>
                      </Box>
                    )}
                  </TabBar.Item>
                ))}
              </TabBar>
              {kpiCurrentTab === tabsCategory.All ? (
                <Box maxWidth="400px">
                  <Calendar
                    variant="range"
                    value={
                      kpiDatesAligned &&
                      values.department_kpi_period_start_day &&
                      values.department_kpi_period_end_day
                        ? {
                            from: new Date(values.department_kpi_period_start_day),
                            to: new Date(values.department_kpi_period_end_day),
                          }
                        : undefined
                    }
                    onChange={value => {
                      const from = value?.from
                      const to = value?.to

                      if (from) {
                        values.department_kpi_period_start_day = formatDate(from, '00:00')
                        values.team_kpi_period_start_day = formatDate(from, '00:00')
                      }
                      if (to) {
                        values.department_kpi_period_end_day = formatDate(to, '00:00')
                        values.team_kpi_period_end_day = formatDate(to, '00:00')
                      }
                    }}
                  />
                </Box>
              ) : (
                <TextWidget>
                  <TextWidget.Title mb="s-16">
                    Set different {goalsTitle} setting dates for each organisational level
                  </TextWidget.Title>
                  <TextWidget.Content>
                    <VStack space="s-16">
                      <InputGroup variant="horizontal">
                        <LapeDatePickerInput
                          required
                          name="department_kpi_period_start_day"
                          label={`Department ${goalsTitle} Period Start Day`}
                          onChange={value =>
                            onDateTimeChange('department_kpi_period_start_day', value)
                          }
                        />
                        <LapeDatePickerInput
                          required
                          name="department_kpi_period_end_day"
                          label={`Department ${goalsTitle} Period End Day`}
                          onChange={value =>
                            onDateTimeChange('department_kpi_period_end_day', value)
                          }
                        />
                      </InputGroup>
                      <InputGroup variant="horizontal">
                        <LapeDatePickerInput
                          required
                          name="team_kpi_period_start_day"
                          label={`Team ${goalsTitle} Period Start Day`}
                          onChange={value =>
                            onDateTimeChange('team_kpi_period_start_day', value)
                          }
                        />
                        <LapeDatePickerInput
                          required
                          name="team_kpi_period_end_day"
                          label={`Team ${goalsTitle} Period End Day`}
                          onChange={value =>
                            onDateTimeChange('team_kpi_period_end_day', value)
                          }
                        />
                      </InputGroup>
                    </VStack>
                  </TextWidget.Content>
                </TextWidget>
              )}
            </InputGroup>
          </>
          <>
            <NewStepperTitle
              title="Performance review: When should the window be live for scorecard feedback?"
              subtitle="Select the start and end dates"
            />
            <InputGroup>
              <TabBar
                value={reviewCurrentTab}
                variant="segmented"
                mb="s-16"
                maxWidth="400px"
              >
                {reviewTabs.map(tab => (
                  <TabBar.Item
                    key={tab.id}
                    onClick={() => setReviewCurrentTab(tab.id)}
                    aria-selected={reviewCurrentTab === tab.id}
                  >
                    <Text>{tab.title}</Text>
                    {tab.tooltip && (
                      <Box ml="s-4">
                        <ExclamationMarkOutline
                          size={15}
                          {...reviewTooltip.getAnchorProps()}
                        />
                        <Tooltip
                          {...reviewTooltip.getTargetProps()}
                          maxWidth={230}
                          placement="right"
                        >
                          {tab.tooltip}
                        </Tooltip>
                      </Box>
                    )}
                  </TabBar.Item>
                ))}
              </TabBar>
              {reviewCurrentTab === tabsCategory.All ? (
                <Box maxWidth="400px">
                  <Calendar
                    variant="range"
                    value={
                      reviewDatesAligned &&
                      values.review_period_start_day &&
                      values.managers_reviews_last_day
                        ? {
                            from: new Date(values.review_period_start_day),
                            to: new Date(values.managers_reviews_last_day),
                          }
                        : undefined
                    }
                    onChange={value => {
                      const from = value?.from
                      const to = value?.to

                      if (from) {
                        values.review_period_start_day = formatDate(from)
                      }
                      if (to) {
                        values.managers_reviews_last_day = formatDate(to)
                        values.self_and_peer_reviews_last_day = formatDate(to)
                      }
                    }}
                  />
                  <Box mt="s-16">
                    <LapeNewTimeInput
                      name="managers_reviews_last_day"
                      label="Time"
                      hideOptional
                      onAfterChange={e => {
                        onTimeChange('review_period_start_day', e.target.value)
                        onTimeChange('self_and_peer_reviews_last_day', e.target.value)
                      }}
                    />
                  </Box>
                </Box>
              ) : (
                <TextWidget>
                  <TextWidget.Title mb="s-16">
                    Set different review dates for managers and employees
                  </TextWidget.Title>
                  <TextWidget.Content>
                    <VStack space="s-16">
                      <InputGroup variant="horizontal">
                        <LapeDatePickerInput
                          onChange={value =>
                            onDateTimeChange('review_period_start_day', value)
                          }
                          required
                          name="review_period_start_day"
                          label="Perf Review Start Date"
                        />
                        <LapeNewTimeInput
                          width="50%"
                          name="review_period_start_day"
                          label="Time"
                          hideOptional
                        />
                      </InputGroup>
                      <InputGroup variant="horizontal">
                        <LapeDatePickerInput
                          onChange={value =>
                            onDateTimeChange('self_and_peer_reviews_last_day', value)
                          }
                          required
                          name="self_and_peer_reviews_last_day"
                          label="Non Manager End Date"
                        />
                        <LapeNewTimeInput
                          width="50%"
                          name="self_and_peer_reviews_last_day"
                          label="Time"
                          hideOptional
                        />
                      </InputGroup>
                      <InputGroup variant="horizontal">
                        <LapeDatePickerInput
                          onChange={value =>
                            onDateTimeChange('managers_reviews_last_day', value)
                          }
                          required
                          name="managers_reviews_last_day"
                          label="Manager End Date"
                        />
                        <LapeNewTimeInput
                          width="50%"
                          name="managers_reviews_last_day"
                          label="Time"
                          hideOptional
                        />
                      </InputGroup>
                    </VStack>
                  </TextWidget.Content>
                </TextWidget>
              )}
            </InputGroup>
            <Box my="s-32">
              <Flex
                alignItems="center"
                gap="s-8"
                mb="s-8"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setAdditionalOpened(!additionalOpened)
                }}
              >
                <NavigationForward
                  size={16}
                  color="grey-20"
                  css={{ rotate: additionalOpened ? '90deg' : undefined }}
                />
                <Text variant="primary">Additional settings</Text>
              </Flex>
              <Text variant="caption" color="grey-tone-50" ml="22px">
                Calibration and publishing dates
              </Text>
            </Box>
            {additionalOpened && (
              <>
                <TextWidget style={{ marginBottom: '16px' }}>
                  <TextWidget.Title mb="s-16">
                    <VStack space="s-4">
                      <Text>Calibration dates</Text>
                      <Text variant="caption" color="grey-tone-50">
                        Dates for department and function calibration (if enabled)
                      </Text>
                    </VStack>
                  </TextWidget.Title>
                  <TextWidget.Content>
                    <VStack space="s-16">
                      <InputGroup variant="horizontal">
                        <LapeDatePickerInput
                          onChange={value =>
                            onDateTimeChange(
                              'department_owner_calibration_start_day',
                              value,
                            )
                          }
                          required
                          name="department_owner_calibration_start_day"
                          label="HoD Calibration Start Date"
                        />
                        <LapeNewTimeInput
                          width="50%"
                          name="department_owner_calibration_start_day"
                          label="Time"
                          hideOptional
                        />
                      </InputGroup>
                      <InputGroup variant="horizontal">
                        <LapeDatePickerInput
                          onChange={value =>
                            onDateTimeChange(
                              'head_of_function_calibration_start_day',
                              value,
                            )
                          }
                          required
                          name="head_of_function_calibration_start_day"
                          label="HoF Calibration Start Date"
                        />
                        <LapeNewTimeInput
                          width="50%"
                          name="head_of_function_calibration_start_day"
                          label="Time"
                          hideOptional
                        />
                      </InputGroup>
                      <InputGroup variant="horizontal">
                        <LapeDatePickerInput
                          onChange={value =>
                            onDateTimeChange(
                              'head_of_function_and_department_last_calibration_day',
                              value,
                            )
                          }
                          required
                          name="head_of_function_and_department_last_calibration_day"
                          label="Calibration Last Date"
                        />
                        <LapeNewTimeInput
                          width="50%"
                          name="head_of_function_and_department_last_calibration_day"
                          label="Time"
                          hideOptional
                        />
                      </InputGroup>
                    </VStack>
                  </TextWidget.Content>
                </TextWidget>
                <TextWidget style={{ marginBottom: '16px' }}>
                  <TextWidget.Title mb="s-16">
                    <VStack space="s-4">
                      <Text>Publishing dates for managers and employees</Text>
                      <Text variant="caption" color="grey-tone-50">
                        Dates for when managers can view final grades and feedback,
                        separately from their reports
                      </Text>
                    </VStack>
                  </TextWidget.Title>
                  <TextWidget.Content>
                    <VStack space="s-16">
                      <InputGroup variant="horizontal">
                        <LapeDatePickerInput
                          onChange={value =>
                            onDateTimeChange('managers_publishing_day', value)
                          }
                          required
                          name="managers_publishing_day"
                          label="Results published to managers"
                        />
                        <LapeNewTimeInput
                          width="50%"
                          name="managers_publishing_day"
                          label="Time"
                          hideOptional
                        />
                      </InputGroup>
                      <InputGroup variant="horizontal">
                        <LapeDatePickerInput
                          onChange={value =>
                            onDateTimeChange('reviews_publishing_day', value)
                          }
                          required
                          name="reviews_publishing_day"
                          label="Results published to employees"
                        />
                        <LapeNewTimeInput
                          width="50%"
                          name="reviews_publishing_day"
                          label="Time"
                          hideOptional
                        />
                      </InputGroup>
                    </VStack>
                  </TextWidget.Content>
                </TextWidget>
              </>
            )}
          </>
        </AutoStepper>
      </PageBody>
      <PageActions>
        <NewSaveButtonWithPopup
          afterSubmitUrl={pathToUrl(ROUTES.FORMS.REVIEW_CYCLES.GENERAL, params)}
          useValidator
        />
      </PageActions>
    </PageWrapper>
  )
})

const TimelineForm = () => {
  return (
    <Form api={reviewCyclesRequestsNew} disableDataCleanup>
      <Timeline />
    </Form>
  )
}

export default TimelineForm
