import React, { useState } from 'react'
import { StatusPopup, useStatusPopup, TableWidget, VStack } from '@revolut/ui-kit'
import { useParams } from 'react-router-dom'
import noop from 'lodash/noop'

import Stat from '@components/Stat/Stat'
import { RowInterface } from '@src/interfaces/data'
import {
  EmployeeInterface,
  EmployeeOptionInterface,
  EmployeeStats,
} from '@src/interfaces/employees'
import { selectorKeys } from '@src/constants/api'
import useFetchOptions from '@components/Inputs/hooks/useFetchOptions'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import {
  candidatesHiringManagerOwnershipTransfer,
  candidatesRecruiterOwnershipTransfer,
  departmentOwnershipTransfer,
  directReportsOwnershipTransfer,
  functionalReportsOwnershipTransfer,
  getCandidatesHiringManagerOwnershipPayload,
  getCandidatesRecruiterOwnershipPayload,
  getDepartmentOwnershipPayload,
  getDirectReportsOwnershipPayload,
  getFunctionalReportsOwnershipPayload,
  getJobPostingsRecruiterOwnershipPayload,
  getOffersOwnershipPayload,
  getRequisitionsManagerOwnershipPayload,
  getRequisitionsRecruiterOwnershipPayload,
  getTeamOwnershipPayload,
  jobPostingsRecruiterOwnershipTransfer,
  offersOwnershipTransfer,
  requisitionManagerOwnershipTransfer,
  requisitionRecruiterOwnershipTransfer,
  teamOwnershipTransfer,
} from '@src/api/ownership'
import { successNotification } from '@src/actions/NotificationActions'
import AdjustableTable from '@components/Table/AdjustableTable'
import {
  ownershipActionColumn,
  ownershipCategoryColumn,
  ownershipDescriptionColumn,
  ownershipItemsColumn,
  ownershipTransferToColumn,
} from '@src/constants/columns/ownership'

import { Banner, TransferSelect, TransferWarningButton } from './common'
import { vendorBannerTitle } from './VendorOwnership'
import { dataBannerTitle } from './DataOwnership'
import { TableNames } from '@src/constants/table'
import { RadioSelectOption } from '@components/Inputs/RadioSelectInput/RadioSelectInput'
import { useGlobalSettings } from '@src/api/settings'
import useIsCommercial from '@src/hooks/useIsCommercial'
import { TableActionButton } from '@components/Button/TableActionButton'

type TransferableCategory =
  | 'directReports'
  | 'functionalReports'
  | 'teams'
  | 'departments'
  | 'requisitionsManager'
  | 'requisitionsRecruiter'
  | 'jobPostingsRecruiter'
  | 'candidatesRecruiter'
  | 'candidatesHiringManager'
  | 'offers'

type ReadonlyCategory = 'dataAccess' | 'keyPerson' | 'vendors' | 'data'

type Category = TransferableCategory | ReadonlyCategory

interface AllOwnershipInterface {
  id: number
  type: Category
  category: string
  items?: number
  description: React.ReactNode
  onTransfer?: (owner: EmployeeOptionInterface) => Promise<void>
}

type TransferSelections = Record<TransferableCategory, EmployeeOptionInterface>

type PendingTransfers = Record<TransferableCategory, boolean>

type OwnershipActionCellProps = {
  data: AllOwnershipInterface
  disabled: boolean
  params: { id: string }
  pendingTransfers: PendingTransfers
  selections: TransferSelections
}

const OwnershipActionCell = ({
  data,
  disabled,
  params,
  pendingTransfers,
  selections,
}: OwnershipActionCellProps) => {
  const [transferPending, setTransferPending] = useState(false)
  const statusPopup = useStatusPopup()

  const reviewable = ['dataAccess', 'keyPerson', 'data', 'vendors'].includes(data.type)

  const title = reviewable ? 'Review' : 'Transfer'

  const isDisabled = disabled || !data.items

  const onClick = () => {
    switch (data.type) {
      case 'dataAccess':
        return navigateTo(pathToUrl(ROUTES.FORMS.EMPLOYEE.OWNERSHIP.ACCESS, params))
      case 'keyPerson':
        return navigateTo(pathToUrl(ROUTES.FORMS.EMPLOYEE.OWNERSHIP.KEY_PERSON, params))
      case 'vendors':
        return navigateTo(pathToUrl(ROUTES.FORMS.EMPLOYEE.OWNERSHIP.VENDORS, params))
      case 'data':
        return navigateTo(pathToUrl(ROUTES.FORMS.EMPLOYEE.OWNERSHIP.DATA, params))
      default: {
        return undefined
      }
    }
  }

  if (reviewable) {
    return (
      <TableActionButton
        onClick={onClick}
        pending={pendingTransfers[data.type as TransferableCategory]}
        disabled={isDisabled}
      >
        {title}
      </TableActionButton>
    )
  }

  const onTransfer = async () => {
    setTransferPending(true)
    try {
      await data.onTransfer?.(selections[data.type as TransferableCategory])
      statusPopup.show(
        <StatusPopup variant="success" onClose={statusPopup.hide}>
          <StatusPopup.Title>
            All items were successfully transferred to new owners
          </StatusPopup.Title>
        </StatusPopup>,
      )
    } catch {
      statusPopup.show(
        <StatusPopup variant="error" onClose={statusPopup.hide}>
          <StatusPopup.Title>Something went wrong</StatusPopup.Title>
        </StatusPopup>,
      )
    } finally {
      setTransferPending(false)
    }
  }

  return (
    <TransferWarningButton
      isTableAction
      disabled={isDisabled}
      pending={pendingTransfers[data.type as TransferableCategory] || transferPending}
      title={title}
      onTransfer={onTransfer}
    />
  )
}

const ROW = (
  params: { id: string },
  selections: TransferSelections,
  options: RadioSelectOption<EmployeeOptionInterface>[],
  pendingTransfers: PendingTransfers,
  disabled: boolean,
  onSelect: (owner: EmployeeOptionInterface, category: TransferableCategory) => void,
): RowInterface<AllOwnershipInterface> => ({
  cells: [
    {
      ...ownershipCategoryColumn,
      width: 200,
    },
    {
      ...ownershipItemsColumn,
      width: 50,
    },
    {
      ...ownershipDescriptionColumn,
      width: 500,
    },
    {
      ...ownershipTransferToColumn,
      width: 300,
      insert: ({ data }) => {
        const { type, items } = data

        if (
          type === 'dataAccess' ||
          type === 'keyPerson' ||
          type === 'data' ||
          type === 'vendors' ||
          !items
        ) {
          return null
        }

        return (
          <TransferSelect
            id={type}
            value={selections[type]}
            options={options}
            disabled={disabled}
            onSelect={onSelect}
          />
        )
      },
    },
    {
      ...ownershipActionColumn,
      width: 100,
      insert: ({ data }) => {
        return (
          <OwnershipActionCell
            data={data}
            disabled={disabled}
            params={params}
            pendingTransfers={pendingTransfers}
            selections={selections}
          />
        )
      },
    },
  ],
})

interface Props {
  data: EmployeeInterface
  stats?: EmployeeStats
  refreshStats: () => void
}

const AllOwnership = ({ data, stats, refreshStats }: Props) => {
  const params = useParams<{ id: string }>()

  const isCommercial = useIsCommercial()

  const [selections, setSelections] = useState<TransferSelections>({
    directReports: data.line_manager,
    functionalReports: data.line_manager,
    teams: data.line_manager,
    departments: data.line_manager,
    requisitionsRecruiter: data.line_manager,
    requisitionsManager: data.line_manager,
    jobPostingsRecruiter: data.line_manager,
    candidatesRecruiter: data.line_manager,
    candidatesHiringManager: data.line_manager,
    offers: data.line_manager,
  })
  const [pendingTransfers, setPendingTransfers] = useState<PendingTransfers>({
    directReports: false,
    functionalReports: false,
    teams: false,
    departments: false,
    requisitionsRecruiter: false,
    requisitionsManager: false,
    jobPostingsRecruiter: false,
    candidatesRecruiter: false,
    candidatesHiringManager: false,
    offers: false,
  })
  const [transferAllPending, setTransferAllPending] = useState(false)

  const { options, asyncState } = useFetchOptions<EmployeeOptionInterface>(
    selectorKeys.employee,
  )
  const { settings } = useGlobalSettings()

  const optionsSuccess = asyncState === 'ready'
  const someTransfersPending = Object.values(pendingTransfers).some(Boolean)

  const noDirectReports = Number(stats?.direct_reports_count) === 0
  const noFunctionalReports = Number(stats?.functional_reports_count) === 0
  const noDepartments = Number(stats?.owned_departments_count) === 0
  const noTeams = Number(stats?.owned_teams_count) === 0
  const noRequisitionsManager = Number(stats?.line_manager_requisition_count) === 0
  const noRequisitionsRecruiter = Number(stats?.recruiter_requisition_count) === 0
  const noJobPostingsRecruiter = Number(stats?.recruiter_job_posting_count) === 0
  const noCandidatesRecruiter = Number(stats?.recruiter_interview_round_count) === 0
  const noCandidatesHiringManager =
    Number(stats?.hiring_manager_interview_round_count) === 0
  const noOffers = Number(stats?.owner_offer_form_count) === 0

  const disabled = !optionsSuccess || transferAllPending
  const applyAllDisabled =
    !optionsSuccess ||
    someTransfersPending ||
    (stats &&
      noDirectReports &&
      noFunctionalReports &&
      noDepartments &&
      noTeams &&
      noRequisitionsManager &&
      noRequisitionsRecruiter &&
      noJobPostingsRecruiter &&
      noCandidatesRecruiter &&
      noCandidatesHiringManager &&
      noOffers)

  const onSelect = (option: EmployeeOptionInterface, category: TransferableCategory) => {
    setSelections(prevSelections => ({ ...prevSelections, [category]: option }))
  }

  const onDirectReportsTransfer = async (owner: EmployeeOptionInterface) => {
    setPendingTransfers(prevPending => ({ ...prevPending, directReports: true }))

    try {
      const ownership = await getDirectReportsOwnershipPayload(data.id, owner)
      await directReportsOwnershipTransfer(data.id, ownership)
      successNotification('Transfer successful')
      refreshStats()
    } finally {
      setPendingTransfers(prevPending => ({ ...prevPending, directReports: false }))
    }
  }

  const onFunctionalReportsTransfer = async (owner: EmployeeOptionInterface) => {
    setPendingTransfers(prevPending => ({ ...prevPending, functionalReports: true }))

    try {
      const ownership = await getFunctionalReportsOwnershipPayload(data.id, owner)
      await functionalReportsOwnershipTransfer(data.id, ownership)
      successNotification('Transfer successful')
      refreshStats()
    } finally {
      setPendingTransfers(prevPending => ({
        ...prevPending,
        functionalReports: false,
      }))
    }
  }

  const onTeamsOwnershipTransfer = async (owner: EmployeeOptionInterface) => {
    setPendingTransfers(prevPending => ({ ...prevPending, teams: true }))

    try {
      const ownership = await getTeamOwnershipPayload(data.id, owner)
      await teamOwnershipTransfer(data.id, ownership)
      successNotification('Transfer successful')
      refreshStats()
    } finally {
      setPendingTransfers(prevPending => ({ ...prevPending, teams: false }))
    }
  }

  const onRequisitionsRecruiterOwnershipTransfer = async (
    recruiter: EmployeeOptionInterface,
  ) => {
    setPendingTransfers(prevPending => ({ ...prevPending, requisitions: true }))

    try {
      const ownership = await getRequisitionsRecruiterOwnershipPayload(data.id, recruiter)
      await requisitionRecruiterOwnershipTransfer(data.id, ownership)
      successNotification('Transfer successful')
      refreshStats()
    } finally {
      setPendingTransfers(prevPending => ({
        ...prevPending,
        requisitions_recruiter: false,
      }))
    }
  }

  const onRequisitionsManagerOwnershipTransfer = async (
    recruiter: EmployeeOptionInterface,
  ) => {
    setPendingTransfers(prevPending => ({ ...prevPending, requisitions: true }))

    try {
      const ownership = await getRequisitionsManagerOwnershipPayload(data.id, recruiter)
      await requisitionManagerOwnershipTransfer(data.id, ownership)
      successNotification('Transfer successful')
      refreshStats()
    } finally {
      setPendingTransfers(prevPending => ({
        ...prevPending,
        requisitions_manager: false,
      }))
    }
  }

  const onDepartmentsOwnershipTransfer = async (owner: EmployeeOptionInterface) => {
    setPendingTransfers(prevPending => ({ ...prevPending, departments: true }))

    try {
      const ownership = await getDepartmentOwnershipPayload(data.id, owner)
      await departmentOwnershipTransfer(data.id, ownership)
      successNotification('Transfer successful')
      refreshStats()
    } finally {
      setPendingTransfers(prevPending => ({ ...prevPending, departments: false }))
    }
  }

  const transferJobPostingsOwnership = async (owner: EmployeeOptionInterface) => {
    const ownership = await getJobPostingsRecruiterOwnershipPayload(data.id, owner)
    return ownership.length
      ? jobPostingsRecruiterOwnershipTransfer(data.id, ownership)
      : null
  }

  const onJobPostingsRecruiterOwnershipTransfer = async (
    owner: EmployeeOptionInterface,
  ) => {
    setPendingTransfers(prevPending => ({
      ...prevPending,
      jobPostings: true,
    }))
    try {
      await transferJobPostingsOwnership(owner)
      successNotification('Transfer successful')
      refreshStats()
    } finally {
      setPendingTransfers(prevPending => ({
        ...prevPending,
        jobPostings: false,
      }))
    }
  }

  const transferCandidatesRecruiterOwnership = async (
    recruiter: EmployeeOptionInterface,
  ) => {
    const ownership = await getCandidatesRecruiterOwnershipPayload(data.id, recruiter)
    return ownership.length
      ? candidatesRecruiterOwnershipTransfer(data.id, ownership)
      : null
  }

  const onCandidatesRecruiterOwnershipTransfer = async (
    recruiter: EmployeeOptionInterface,
  ) => {
    setPendingTransfers(prevPending => ({ ...prevPending, candidatesRecruiter: true }))
    try {
      await transferCandidatesRecruiterOwnership(recruiter)
      successNotification('Transfer successful')
      refreshStats()
    } finally {
      setPendingTransfers(prevPending => ({ ...prevPending, candidatesRecruiter: false }))
    }
  }

  const transferCandidatesHiringManagerOwnership = async (
    manager: EmployeeOptionInterface,
  ) => {
    const ownership = await getCandidatesHiringManagerOwnershipPayload(data.id, manager)
    return ownership.length
      ? candidatesHiringManagerOwnershipTransfer(data.id, ownership)
      : null
  }

  const onCandidatesHiringManagerOwnershipTransfer = async (
    manager: EmployeeOptionInterface,
  ) => {
    setPendingTransfers(prevPending => ({
      ...prevPending,
      candidatesHiringManager: true,
    }))
    try {
      await transferCandidatesHiringManagerOwnership(manager)
      successNotification('Transfer successful')
      refreshStats()
    } finally {
      setPendingTransfers(prevPending => ({
        ...prevPending,
        candidatesHiringManager: false,
      }))
    }
  }

  const transferOffersOwnership = async (recruiter: EmployeeOptionInterface) => {
    const ownership = await getOffersOwnershipPayload(data.id, recruiter)
    return ownership.length ? offersOwnershipTransfer(data.id, ownership) : null
  }

  const onOffersOwnershipTransfer = async (recruiter: EmployeeOptionInterface) => {
    setPendingTransfers(prevPending => ({ ...prevPending, offers: true }))
    try {
      await transferOffersOwnership(recruiter)
      successNotification('Transfer successful')
      refreshStats()
    } finally {
      setPendingTransfers(prevPending => ({ ...prevPending, offers: false }))
    }
  }

  const onTransferAllClick = async () => {
    setTransferAllPending(true)

    const {
      departments,
      teams,
      functionalReports,
      directReports,
      requisitionsManager,
      requisitionsRecruiter,
      jobPostingsRecruiter,
      candidatesRecruiter,
      candidatesHiringManager,
      offers,
    } = selections

    // We can't trigger functional and direct report ownership transfer at the same time because changes
    // from one will overwrite the other. That's why we await for one of them to resolve first
    let functionalReportsError = false
    try {
      const functionalOwnershipPayload = await getFunctionalReportsOwnershipPayload(
        data.id,
        functionalReports,
      )
      if (functionalOwnershipPayload.length) {
        await functionalReportsOwnershipTransfer(data.id, functionalOwnershipPayload)
      }
    } catch (error) {
      functionalReportsError = true
    }

    const departmentsOwnership = getDepartmentOwnershipPayload(data.id, departments).then(
      ownership =>
        ownership.length ? departmentOwnershipTransfer(data.id, ownership) : null,
    )
    const teamsOwnership = getTeamOwnershipPayload(data.id, teams).then(ownership =>
      ownership.length ? teamOwnershipTransfer(data.id, ownership) : null,
    )
    const directReportsOwnership = getDirectReportsOwnershipPayload(
      data.id,
      directReports,
    ).then(ownership =>
      ownership.length ? directReportsOwnershipTransfer(data.id, ownership) : null,
    )
    const requisitionsRecruiterOwnership = getRequisitionsRecruiterOwnershipPayload(
      data.id,
      requisitionsRecruiter,
    ).then(ownership =>
      ownership.length ? requisitionRecruiterOwnershipTransfer(data.id, ownership) : null,
    )
    const requisitionsManagerOwnership = getRequisitionsManagerOwnershipPayload(
      data.id,
      requisitionsManager,
    ).then(ownership =>
      ownership.length ? requisitionManagerOwnershipTransfer(data.id, ownership) : null,
    )

    const transferResults = await Promise.allSettled([
      departmentsOwnership,
      teamsOwnership,
      directReportsOwnership,
      requisitionsRecruiterOwnership,
      requisitionsManagerOwnership,
      transferJobPostingsOwnership(jobPostingsRecruiter),
      transferCandidatesRecruiterOwnership(candidatesRecruiter),
      transferCandidatesHiringManagerOwnership(candidatesHiringManager),
      transferOffersOwnership(offers),
    ])

    if (
      transferResults.every(res => res.status === 'fulfilled') &&
      !functionalReportsError
    ) {
      successNotification('Transfer all successful')
    }

    refreshStats()
    setTransferAllPending(false)
  }

  const tableData = [
    {
      id: 2,
      type: 'directReports' as const,
      category: 'Direct reports',
      items: stats?.direct_reports_count,
      description: 'Re-assign direct reports to a new manager from the department',
      onTransfer: onDirectReportsTransfer,
      canView: true,
    },
    {
      id: 3,
      type: 'functionalReports' as const,
      category: 'Functional reports',
      items: stats?.functional_reports_count,
      description:
        'Re-assign functional reports to a functional manager from the function',
      onTransfer: onFunctionalReportsTransfer,
      canView: true,
    },
    {
      id: 4,
      type: 'teams' as const,
      category: 'Teams',
      items: stats?.owned_teams_count,
      description:
        'Change the ownership of the team to a different employee in the department',
      onTransfer: onTeamsOwnershipTransfer,
      canView: true,
    },
    {
      id: 5,
      type: 'departments' as const,
      category: 'Departments',
      items: stats?.owned_departments_count,
      description: 'Change the ownership of the department to a different employee',
      onTransfer: onDepartmentsOwnershipTransfer,
      canView: true,
    },
    {
      id: 6,
      type: 'keyPerson' as const,
      category: 'Key person',
      items: stats?.key_person_count,
      description: 'Review key person details and take appropriate action',
      canView: settings?.key_person_enabled,
    },
    {
      id: 7,
      type: 'requisitionsRecruiter' as const,
      category: 'Requisitions recruiter',
      items: stats?.recruiter_requisition_count,
      description: 'Change the recruiter of the requisition to a different recruiter',
      onTransfer: onRequisitionsRecruiterOwnershipTransfer,
      canView: true,
    },
    {
      id: 8,
      type: 'requisitionsManager' as const,
      category: 'Requisitions manager',
      items: stats?.line_manager_requisition_count,
      description: 'Change the line manager of the requisition to a different employee',
      onTransfer: onRequisitionsManagerOwnershipTransfer,
      canView: true,
    },
    {
      id: 9,
      type: 'vendors' as const,
      category: 'Vendors',
      items: stats?.owned_vendors_count,
      description: vendorBannerTitle,
      canView: !isCommercial,
    },
    {
      id: 10,
      type: 'data' as const,
      category: 'Data',
      items: stats?.owned_data_items_count,
      description: dataBannerTitle,
      canView: !isCommercial,
    },
    {
      id: 11,
      type: 'jobPostingsRecruiter' as const,
      category: 'Job postings recruiter',
      items: stats?.recruiter_job_posting_count,
      description: 'Change the recruiter of the job postings to a different recruiter',
      onTransfer: onJobPostingsRecruiterOwnershipTransfer,
      canView: settings?.job_postings_enabled,
    },
    {
      id: 12,
      type: 'candidatesRecruiter' as const,
      category: 'Candidates recruiter',
      items: stats?.recruiter_interview_round_count,
      description: 'Change the recruiter of the candidates to a different recruiter',
      onTransfer: onCandidatesRecruiterOwnershipTransfer,
      canView: settings?.candidates_enabled,
    },
    {
      id: 13,
      type: 'candidatesHiringManager' as const,
      category: 'Candidates hiring manager',
      items: stats?.hiring_manager_interview_round_count,
      description:
        'Change the hiring manager of the candidates to a different hiring manager',
      onTransfer: onCandidatesHiringManagerOwnershipTransfer,
      canView: settings?.candidates_enabled,
    },
    {
      id: 14,
      type: 'offers' as const,
      category: 'Offers',
      items: stats?.owner_offer_form_count,
      description: 'Change the recruiter of the offers to a different recruiter',
      onTransfer: onOffersOwnershipTransfer,
      canView: true,
    },
  ].filter(({ canView }) => !!canView)

  return (
    <VStack gap="s-16">
      <Banner
        title="Employee is scheduled for termination"
        description="Requires ownership transfer for all the owned resources until the last day in office"
        hidden={!data.has_termination}
      />

      <TableWidget>
        <TableWidget.Info>
          <Stat label="Total items" val={stats?.all_ownership_items_count} mb="s-24" />
        </TableWidget.Info>
        <TableWidget.Actions>
          <TransferWarningButton
            disabled={applyAllDisabled === true}
            pending={transferAllPending}
            title="Transfer all"
            onTransfer={onTransferAllClick}
          />
        </TableWidget.Actions>
        <TableWidget.Table>
          <AdjustableTable
            name={TableNames.EmployeeOwnership}
            useWindowScroll
            data={tableData}
            count={tableData?.length || 0}
            row={ROW(params, selections, options, pendingTransfers, disabled, onSelect)}
            dataType="Category"
            // These are here just because the table requires them:
            filterBy={[]}
            sortBy={[]}
            setData={noop}
            onSortChange={noop}
            fetchSelectors={() => () => Promise.resolve({ options: [] })}
            resetFiltersAndSorting={noop}
            onFilterChange={noop}
            refresh={noop}
            refreshStats={noop}
            fetchNextPage={noop}
            fetchChildren={() => Promise.resolve()}
            loading={false}
          />
        </TableWidget.Table>
      </TableWidget>
    </VStack>
  )
}

export default AllOwnership
