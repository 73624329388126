import { EmployeeInterface } from '@src/interfaces/employees'
import {
  PerformanceSelector,
  PerformanceTimelineInterface,
} from '@src/interfaces/performance'
import React from 'react'
import { Avatar, Box, Group, Item, Token, VStack } from '@revolut/ui-kit'
import { ProgressionSection } from '@src/pages/EmployeeProfile/Preview/PerformanceSummary'
import { ChevronRight, Timeline } from '@revolut/icons'
import { PerformanceSection } from '@src/pages/EmployeeProfile/Layout/Performance/PerformanceSection'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'

export const PerformanceHistorySection = ({
  data,
  canViewProgression,
  onRowClick,
  selectedPeriod,
}: {
  data: EmployeeInterface
  canViewProgression?: boolean
  onRowClick: (rowData: PerformanceTimelineInterface) => void
  selectedPeriod?: PerformanceSelector
}) => {
  const onHistoryClick = () => {
    navigateTo(
      pathToUrl(ROUTES.FORMS.EMPLOYEE.PERFORMANCE_TIMELINE.HISTORY, {
        id: data.id,
      }),
    )
  }

  return (
    <Group>
      <Item use="button" onClick={onHistoryClick}>
        <Item.Avatar>
          <Avatar useIcon={Timeline} />
        </Item.Avatar>
        <Item.Content>
          <Item.Title>History</Item.Title>
          <Item.Description>Your data for each review cycle</Item.Description>
        </Item.Content>
        <Item.Side>
          <ChevronRight color={Token.color.greyTone50} />
        </Item.Side>
      </Item>

      <Box p="s-16" pt={0}>
        <VStack>
          {canViewProgression && (
            <ProgressionSection
              data={data}
              renderHeader={false}
              sticky
              withBorder
              noError
            />
          )}
          <Box mt="s-16">
            <PerformanceSection
              data={data}
              selectedCycle={selectedPeriod}
              onRowClick={onRowClick}
            />
          </Box>
        </VStack>
      </Box>
    </Group>
  )
}
