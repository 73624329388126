import React from 'react'
import { useParams } from 'react-router-dom'
import { TableWidget, Token } from '@revolut/ui-kit'

import { TableNames } from '@src/constants/table'
import AdjustableTable from '@components/Table/AdjustableTable'
import { RowInterface } from '@src/interfaces/data'
import {
  WorkScheduleReviewCustomApproversListItemInterface,
  WorkScheduleReviewCustomApproversStats,
} from '@src/interfaces/workSchedule'
import { getWorkScheduleReviewCustomApproversTableRequests } from '@src/api/workSchedule'
import { useTable } from '@components/Table/hooks'
import Stat from '@components/Stat/Stat'
import {
  timeOffWorkScheduleReviewCustomApproversEmployeeColumn,
  timeOffWorkScheduleReviewCustomApproversLocationColumn,
  timeOffWorkScheduleReviewCustomApproversRoleColumn,
  timeOffWorkScheduleReviewCustomApproversStatusColumn,
} from '@src/constants/columns/timeOffWorkScheduleReviewCustomApprovers'

const ROW: RowInterface<WorkScheduleReviewCustomApproversListItemInterface> = {
  cells: [
    {
      ...timeOffWorkScheduleReviewCustomApproversEmployeeColumn,
      width: 200,
    },
    {
      ...timeOffWorkScheduleReviewCustomApproversLocationColumn,
      width: 200,
    },
    {
      ...timeOffWorkScheduleReviewCustomApproversRoleColumn,
      width: 200,
    },
    {
      ...timeOffWorkScheduleReviewCustomApproversStatusColumn,
      width: 200,
    },
  ],
}

type Props = {
  isReviewTab?: boolean
}
export const CustomApproversEligibilityTable = ({ isReviewTab }: Props) => {
  const params = useParams<{ id: string }>()
  const tableRequests = getWorkScheduleReviewCustomApproversTableRequests(params.id)
  const table = useTable<
    WorkScheduleReviewCustomApproversListItemInterface,
    WorkScheduleReviewCustomApproversStats
  >(tableRequests)

  return (
    <TableWidget>
      {isReviewTab && (
        <TableWidget.Info>
          <Stat label="Total" val={table.stats?.total} color={Token.color.foreground} />
          <Stat label="Warning" val={table.stats?.warning} color={Token.color.orange} />
          <Stat label="OK" val={table.stats?.ok} color={Token.color.green} />
        </TableWidget.Info>
      )}
      <TableWidget.Table>
        <AdjustableTable
          {...table}
          dataType="Eligible employee"
          name={TableNames.WorkSchedulesCustomApprovers}
          noDataMessage="Eligible employees not found"
          row={ROW}
          useWindowScroll
          hiddenCells={{
            [timeOffWorkScheduleReviewCustomApproversStatusColumn.idPoint]: !isReviewTab,
          }}
        />
      </TableWidget.Table>
    </TableWidget>
  )
}
