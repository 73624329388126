import React, { useState } from 'react'
import {
  Box,
  copyToClipboard,
  Flex,
  InputGroup,
  useStatusPopup,
  StatusPopup,
  Text,
  Token,
  VStack,
  TextButton,
  HStack,
  Icon,
  ActionButton,
} from '@revolut/ui-kit'
import { useLapeContext } from '@src/features/Form/LapeForm'
import LapeRadioSelectInput from '@src/components/Inputs/LapeFields/LapeRadioSelectInput'
import { SandboxInvitation } from '@src/interfaces/reviewCycles'
import {
  useGetSandboxEmployees,
  useGetSandboxInvitedEmployees,
  useGetSandboxPermissionGroups,
} from '@src/api/reviewCycles'
import { pushNotification } from '@src/store/notifications/actions'
import { SUCCESS_DEFAULT_DURATION } from '@src/constants/notifications'
import { NotificationTypes } from '@src/store/notifications/types'
import { InvitationActions } from '@src/features/Sandbox/SandboxInvitationActions'

const CopyLinkButton = ({ url }: { url: string }) => {
  const [isCopied, setIsCopied] = useState(false)
  return (
    <TextButton
      p={0}
      height="min-content"
      onClick={() => {
        copyToClipboard(url).then(() => {
          setIsCopied(true)
          pushNotification({
            value: 'Invite link copied to clipboard',
            duration: SUCCESS_DEFAULT_DURATION,
            type: NotificationTypes.success,
          })
        })
      }}
    >
      <HStack align="center" space="s-8">
        <Icon name="Copy" size={16} />
        <Text>{isCopied ? 'Copied invite link' : 'Copy invite link'}</Text>
      </HStack>
    </TextButton>
  )
}

export const SandboxInvitationsForm = () => {
  const { values, submit } = useLapeContext<SandboxInvitation>()
  const [invitePending, setInvitePending] = useState(false)
  const statusPopup = useStatusPopup()

  const { data: employees } = useGetSandboxEmployees()
  const { data: groups } = useGetSandboxPermissionGroups()
  const { data: invitations, refetch } = useGetSandboxInvitedEmployees()

  const employeesOptions = employees?.options.map(employee => ({
    label: employee.name,
    value: employee,
  }))
  const employeeValue = employeesOptions?.find(
    el => el.value.id === values.employee?.id,
  )?.value

  const groupsOptions = groups?.options.map(group => ({
    label: group.name,
    value: group,
  }))
  const groupValue = groupsOptions?.find(
    el => el.value.id === values.permission_group?.id,
  )?.value

  const onSubmit = () => {
    setInvitePending(true)
    return submit()
      .then(() => {
        refetch()
        setInvitePending(false)
      })
      .catch(err => {
        statusPopup.show(
          <StatusPopup variant="error">
            <StatusPopup.Title>
              {err.response?.data?.employee?.[0] || 'Could not invite employee'}
            </StatusPopup.Title>
          </StatusPopup>,
        )
      })
      .finally(() => {
        setInvitePending(false)
      })
  }

  return (
    <>
      <Flex gap="s-24" alignItems="center">
        <Box flex="1">
          <InputGroup variant="horizontal">
            <LapeRadioSelectInput
              label="Employee"
              options={employeesOptions}
              name="employee"
              value={employeeValue}
              onChange={option => {
                if (option) {
                  values.employee = option
                }
              }}
            />
            <LapeRadioSelectInput
              label="Permission group"
              options={groupsOptions}
              name="permission_group"
              value={groupValue}
              onChange={option => {
                if (option) {
                  values.permission_group = option
                }
              }}
            />
          </InputGroup>
        </Box>
        <ActionButton
          onClick={onSubmit}
          disabled={!values.employee}
          pending={invitePending}
        >
          Invite
        </ActionButton>
      </Flex>
      {!!invitations?.results?.length && (
        <VStack space="s-8" mt="s-16">
          {invitations.results.map(invitation => {
            return (
              <Box key={invitation.id}>
                <Flex justifyContent="space-between" alignItems="center">
                  <Text variant="h6" color={Token.color.greyTone50}>
                    {invitation.employee?.name}
                  </Text>
                  <InvitationActions
                    invitation={invitation}
                    groups={groups?.options || []}
                    onAfterChange={refetch}
                  />
                </Flex>
                <CopyLinkButton url={invitation.invitation_link} />
              </Box>
            )
          })}
        </VStack>
      )}
    </>
  )
}
