import React, { useEffect, useState, useMemo } from 'react'
import SideBar from '@components/SideBar/SideBar'
import {
  Box,
  Checkbox,
  Flex,
  Input,
  Text,
  Widget,
  DetailsSkeleton,
  Spinner,
  Color,
  VStack,
} from '@revolut/ui-kit'
import { CrossCircle } from '@revolut/icons'
import { matchSorter } from 'match-sorter'

import { defaultTheme } from '@src/styles/theme'
import {
  assignUserGroupRequest,
  revokeGroup,
  useGetAssignedGroups,
} from '@src/api/accessManagement'
import {
  UserAccessManagementInterface,
  AssignedGroup,
} from '@src/interfaces/accessManagement'
import UserWithAvatar from '@src/components/UserWithAvatar/UserWithAvatar'
import { formatWithoutTimezone } from '@src/utils/format'

export interface AssignGroupsSidebarProps {
  selectedUser?: UserAccessManagementInterface
  onClose: () => void
}

export const AssignGroupsSidebar = ({
  selectedUser,
  onClose,
}: AssignGroupsSidebarProps) => {
  const { data: assignedGroups, isLoading: assignedGroupsLoading } = useGetAssignedGroups(
    selectedUser?.id,
  )

  const [searchValue, setSearchValue] = useState('')
  const [pendingGroups, setPendingGroups] = useState<number[]>([])
  const [groupsWithChanges, setGroupsWithChanges] = useState<AssignedGroup[]>([])

  useEffect(() => {
    setGroupsWithChanges(assignedGroups || [])
  }, [assignedGroups])

  useEffect(() => {
    setSearchValue('')
  }, [selectedUser])

  const options = useMemo(() => {
    return matchSorter(groupsWithChanges, searchValue, { keys: ['name', 'description'] })
  }, [searchValue, groupsWithChanges])

  const onSearchChange = (value: string) => {
    setSearchValue(value)
  }

  const onItemClick = async (group: AssignedGroup, checked: boolean) => {
    if (pendingGroups.includes(group.id)) {
      return
    }

    setPendingGroups(prev => [...prev, group.id])

    try {
      if (checked) {
        const response = await assignUserGroupRequest.submit(
          {
            user: selectedUser,
            start_date_time: new Date().toISOString(),
            end_date_time: null,
            group,
          },
          { groupId: `${group.id}` },
        )
        setGroupsWithChanges(prev =>
          prev.map(g =>
            g.id === group.id
              ? { ...g, assigned: true, expiring_group_id: response.data.id }
              : g,
          ),
        )
      }
      if (!checked && group.expiring_group_id) {
        await revokeGroup(group.expiring_group_id)
        setGroupsWithChanges(prev =>
          prev.map(g =>
            g.id === group.id
              ? {
                  ...g,
                  end_date_time: null,
                  expiring_group_id: undefined,
                  assigned: false,
                }
              : g,
          ),
        )
      }
    } finally {
      setPendingGroups(prev => prev.filter(id => id !== group.id))
    }
  }

  return (
    <SideBar
      title="Assign permission groups"
      subtitle={
        selectedUser?.employee ? (
          <UserWithAvatar {...selectedUser?.employee} mt="s-8" asText />
        ) : (
          selectedUser?.email
        )
      }
      isOpen={selectedUser != null}
      onClose={onClose}
      zIndex={defaultTheme.zIndex.sideBar}
    >
      <Flex flexDirection="column">
        <Box mb="s-24">
          <Input
            placeholder="Search permission groups"
            value={searchValue}
            onChange={e => onSearchChange(e.currentTarget.value)}
            renderAction={() =>
              searchValue && (
                <CrossCircle
                  size={24}
                  cursor="pointer"
                  color={Color.GREY_TONE_50}
                  hoverColor={Color.GREY_TONE_50}
                  onClick={e => {
                    e.preventDefault()
                    onSearchChange('')
                  }}
                />
              )
            }
          />
        </Box>

        <Widget p="s-16">
          {assignedGroupsLoading && <DetailsSkeleton />}
          {!assignedGroupsLoading && (
            <Flex gap="s-32" flexDirection="column">
              {options.length ? (
                options.map(item => {
                  return (
                    <Checkbox
                      checked={item.assigned}
                      value={item.id}
                      onChange={e => onItemClick(item, e.currentTarget.checked)}
                      align="start"
                      key={item.id}
                      render={
                        pendingGroups.includes(item.id)
                          ? () => (
                              <Flex
                                width={24}
                                height={24}
                                justifyContent="center"
                                alignItems="center"
                              >
                                <Spinner color={Color.BLUE} size={16} />
                              </Flex>
                            )
                          : undefined
                      }
                      disabled={item.source?.id === 'group'}
                    >
                      <Checkbox.Label>
                        <Text style={{ overflowWrap: 'break-word' }}>{item.name}</Text>
                      </Checkbox.Label>
                      {item.end_date_time || item.description ? (
                        <Checkbox.Description
                          use={VStack}
                          data-testid={`checkbox-description:${item.name}`}
                        >
                          {item.description ? <Text>{item.description}</Text> : null}
                          {item.end_date_time ? (
                            <Text>
                              Valid until: {formatWithoutTimezone(item.end_date_time)}
                            </Text>
                          ) : null}
                        </Checkbox.Description>
                      ) : null}
                    </Checkbox>
                  )
                })
              ) : (
                <Text color={Color.GREY_TONE_50}>No groups...</Text>
              )}
            </Flex>
          )}
        </Widget>
      </Flex>
    </SideBar>
  )
}
