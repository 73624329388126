import React from 'react'

import { ChevronRight, LinkExternal } from '@revolut/icons'
import { Color, Group, Item, ItemSkeleton, Text, VStack } from '@revolut/ui-kit'
import { CategoryIcon } from './CategoryIcon'
import { useFaqCategories } from '@src/api/faq'
import { FaqCategoryInterface } from '@src/interfaces/faq'
import { RevBetsCategory } from '@components/HelpCenter/components/RevBetsCategory'
import { OptionInterface } from '@src/interfaces/selectors'

type Props = {
  onSelect: (category: FaqCategoryInterface) => void
  country?: OptionInterface
}
export const CategoriesSidebarContent = ({ onSelect, country }: Props) => {
  const { data: categoriesOptions } = useFaqCategories(country?.id)

  return (
    <VStack space="s-24">
      <Group>
        {categoriesOptions ? (
          categoriesOptions.results.map(category => {
            if (category.is_empty && category.link_to_support) {
              return (
                <Item
                  key={category.id}
                  use="a"
                  target="_blank"
                  rel="noreferrer noopener"
                  href={category.link_to_support}
                  padding="s-16"
                >
                  <Item.Avatar mr="s-16">
                    <CategoryIcon name={category.icon_id} size={40} />
                  </Item.Avatar>
                  <Item.Content>
                    <Text>{category.name}</Text>
                  </Item.Content>
                  <Item.Side>
                    <LinkExternal color={Color.GREY_TONE_50} />
                  </Item.Side>
                </Item>
              )
            }
            return (
              <Item
                key={category.id}
                use="button"
                padding="s-16"
                onClick={() => onSelect(category)}
              >
                <Item.Avatar mr="s-16">
                  <CategoryIcon name={category.icon_id} size={40} />
                </Item.Avatar>
                <Item.Content>
                  <Text>{category.name}</Text>
                </Item.Content>
                <Item.Side>
                  <ChevronRight color={Color.GREY_TONE_50} />
                </Item.Side>
              </Item>
            )
          })
        ) : (
          <VStack>
            <ItemSkeleton />
            <ItemSkeleton />
            <ItemSkeleton />
          </VStack>
        )}
        {/* TODO: Temporary added link for RevBets - remove when HelpCentre is ready */}
        <RevBetsCategory />
      </Group>
    </VStack>
  )
}
