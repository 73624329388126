import React, { useEffect } from 'react'
import { Group } from '@revolut/ui-kit'
import { useParams } from 'react-router-dom'
import { connect } from 'lape'

import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { goBack } from '@src/actions/RouterActions'
import SendEmailCommon, {
  TemplateCategories,
} from '@src/pages/Forms/SendEmployeeEmail/SendEmployeeEmailCommon'
import { FormPreview } from '@components/FormPreview/FormPreview'
import Form from '@src/features/Form/Form'
import { PageWrapper } from '@src/components/Page/Page'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { employeeEmailsRequests, getPrefilledTemplate } from '@src/api/employeeEmails'
import { OnboardingSendEmailAction, OnboardingSendEmailCategories } from './common'
import { useEmployeeFields } from '@src/api/employees'
import { EmployeeInterface } from '@src/interfaces/employees'
import UserWithAvatar from '@src/components/UserWithAvatar/UserWithAvatar'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { SendEmployeeEmailInterface } from '@src/interfaces/employeeEmails'

interface RouteParams {
  employeeId: string
  processId: string
  action: OnboardingSendEmailAction
}

const actionToTitle = {
  [OnboardingSendEmailAction.Welcome]: 'Send welcome email',
  [OnboardingSendEmailAction.Day1Instructions]: 'Send Day 1 Instructions email',
  [OnboardingSendEmailAction.FollowUp]: 'Send follow up email',
}

const actionToBackUrl = {
  [OnboardingSendEmailAction.Welcome]: ROUTES.FORMS.ONBOARDING_TIMELINE.START,
  [OnboardingSendEmailAction.Day1Instructions]: ROUTES.FORMS.ONBOARDING_TIMELINE.FINISH,
  [OnboardingSendEmailAction.FollowUp]: ROUTES.FORMS.ONBOARDING_TIMELINE.DOCUMENTS,
}

type EmployeeDetails = Pick<EmployeeInterface, 'full_name' | 'avatar' | 'position'>

const OnboardingSendEmail = () => {
  const { employeeId, processId, action } = useParams<RouteParams>()
  const { values } = useLapeContext<SendEmployeeEmailInterface>()

  const { data: employee, isLoading } = useEmployeeFields<EmployeeDetails>(employeeId, [
    'full_name',
    'avatar',
    'position',
  ])

  const backUrl = pathToUrl(actionToBackUrl[action], {
    employeeId,
    id: processId,
  })

  useEffect(() => {
    if (OnboardingSendEmailCategories[action]) {
      values.category = OnboardingSendEmailCategories[action]
    }
  }, [action])

  const fetchEmail = (templateId: number) => {
    return getPrefilledTemplate(templateId, +employeeId)
  }

  const templateFilter = (option: { category: { id: TemplateCategories } }) => {
    if (action === OnboardingSendEmailAction.Day1Instructions) {
      return option.category.id === 'onboarding_day_one_instructions'
    }
    if (action === OnboardingSendEmailAction.Welcome) {
      return option.category.id === 'onboarding_welcome_email'
    }
    return true
  }

  return (
    <PageWrapper>
      <PageHeader title={actionToTitle[action]} backUrl={backUrl} />
      <SendEmailCommon
        onSuccess={() => goBack(backUrl)}
        fetchEmail={fetchEmail}
        templateFilter={
          action === OnboardingSendEmailAction.Day1Instructions ||
          action === OnboardingSendEmailAction.Welcome
            ? templateFilter
            : undefined
        }
      >
        <FormPreview data={employee}>
          <Group>
            <FormPreview.Item
              title="Employee"
              loading={isLoading}
              insert={() => employee && <UserWithAvatar {...employee} />}
            />
            <FormPreview.Item title="Position" field="position.name" />
          </Group>
        </FormPreview>
      </SendEmailCommon>
    </PageWrapper>
  )
}

export default connect(() => (
  <Form api={employeeEmailsRequests}>
    <OnboardingSendEmail />
  </Form>
))
