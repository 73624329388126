import { useFetch } from '@src/utils/reactQuery'
import { API } from '@src/constants/api'
import {
  SystemNotificationsUnreadInterface,
  SystemNotificationInterface,
} from '@src/interfaces/systemNotifications'
import { FetchDataQueryInterface } from '@src/interfaces/data'
import { AxiosPromise } from 'axios'
import { GetRequestInterface } from '@src/interfaces'
import { api } from '@src/api/index'
import { filterSortPageIntoQuery } from '@src/utils/table'

export const useGetUnreadSystemNotifications = () => {
  return useFetch<SystemNotificationsUnreadInterface>(
    `${API.SYSTEM_NOTIFICATIONS}/unread`,
    undefined,
    undefined,
    true,
    {
      staleTime: Infinity,
      cacheTime: Infinity,
    },
  )
}

export const getSystemNotifications = ({
  sortBy,
  filters,
  page,
}: FetchDataQueryInterface): AxiosPromise<
  GetRequestInterface<SystemNotificationInterface>
> =>
  api.get(
    API.SYSTEM_NOTIFICATIONS,
    { params: filterSortPageIntoQuery(sortBy, filters, page) },
    'v1',
  )

export const markNotificationAsRead = (
  id: number,
): AxiosPromise<SystemNotificationInterface> =>
  api.post(`${API.SYSTEM_NOTIFICATIONS}/${id}/read`)

export const markAllNotificationsAsRead = (): AxiosPromise<SystemNotificationInterface> =>
  api.post(`${API.SYSTEM_NOTIFICATIONS}/allRead`)

export const dismissNotification = (
  id: number,
): AxiosPromise<SystemNotificationInterface> =>
  api.post(`${API.SYSTEM_NOTIFICATIONS}/${id}/dismiss`)
