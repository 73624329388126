import React, { useState } from 'react'
import { Button, Header, Popup } from '@revolut/ui-kit'

import { CellTypes } from '@src/interfaces/data'
import { selectorKeys } from '../api'
import {
  EmployeeSelectCell,
  GenericEditableTableColumn,
  RadioSelectInputCell,
  TextCell,
} from '@src/features/GenericEditableTable/components'
import { ImportGoalsInterface } from '@src/interfaces/importGoals'
import { TableCellInputType } from '@src/components/Inputs/TableCellInput/TableCellInput'
import NewResponsiveCodeEditor from '@src/components/Inputs/NewCodeEditor/NewResponsiveCodeEditor'
import { ImportInterface } from '@src/interfaces/bulkDataImport'

type ImportGoalColumn = GenericEditableTableColumn<ImportGoalsInterface>

export const importGoalsNameColumn: ImportGoalColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'name',
  dataPoint: 'name',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Name',
  insert: ({ data }) => <TextCell data={data} onChange={onChange} field="name" />,
})

export const importGoalsDescriptionColumn: ImportGoalColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'description',
  dataPoint: 'description',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Description',
  insert: ({ data }) => <TextCell data={data} onChange={onChange} field="description" />,
})

export const importGoalsInitialValueColumn: ImportGoalColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'initial_value',
  dataPoint: 'initial_value',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Initial value',
  insert: ({ data }) => (
    <TextCell
      data={data}
      onChange={onChange}
      field="initial_value"
      type={TableCellInputType.float}
    />
  ),
})

export const importGoalsIsCompanyColumn: ImportGoalColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'is_company',
  dataPoint: 'is_company',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Is company',
  insert: ({ data }) => (
    <RadioSelectInputCell
      data={data}
      onChange={onChange}
      field="is_company"
      fieldName="is company"
      selector={selectorKeys.yes_no_value_options}
      selectorField="name"
    />
  ),
})

export const importGoalsTargetColumn: ImportGoalColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'target',
  dataPoint: 'target',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Target',
  insert: ({ data }) => (
    <TextCell
      data={data}
      onChange={onChange}
      field="target"
      type={TableCellInputType.float}
    />
  ),
})

export const importGoalsTargetNameColumn: ImportGoalColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'target_name',
  dataPoint: 'target_name',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Metric name',
  insert: ({ data }) => <TextCell data={data} onChange={onChange} field="target_name" />,
})

export const importGoalsUnitColumn: ImportGoalColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'unit',
  dataPoint: 'unit',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.kpi_units,
  title: 'Unit',
  insert: ({ data }) => (
    <RadioSelectInputCell
      data={data}
      onChange={onChange}
      field="unit"
      selector={selectorKeys.kpi_units}
      selectorField="name"
    />
  ),
})

export const importGoalsOwnerColumn: ImportGoalColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'owner',
  dataPoint: 'owner',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Owner',
  insert: ({ data }) => (
    <EmployeeSelectCell data={data} onChange={onChange} field="owner" />
  ),
})

export const importGoalsReviewCycleColumn: ImportGoalColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'review_cycle',
  dataPoint: 'review_cycle',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.review_cycles,
  title: 'Review cycle',
  insert: ({ data }) => (
    <RadioSelectInputCell
      data={data}
      onChange={onChange}
      field="review_cycle"
      fieldName="review cycle"
      selector={selectorKeys.review_cycles}
      selectorField="name"
    />
  ),
})

export const importGoalsTeamColumn: ImportGoalColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'team',
  dataPoint: 'team',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.team,
  title: 'Team',
  insert: ({ data }) => (
    <RadioSelectInputCell
      data={data}
      onChange={onChange}
      field="team"
      selector={selectorKeys.team}
      selectorField="name"
    />
  ),
})

export const importGoalsDepartmentColumn: ImportGoalColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'department',
  dataPoint: 'department',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.department,
  title: 'Department',
  insert: ({ data }) => (
    <RadioSelectInputCell
      data={data}
      onChange={onChange}
      field="department"
      selector={selectorKeys.department}
      selectorField="name"
    />
  ),
})

export const importGoalsEmployeeColumn: ImportGoalColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'employee',
  dataPoint: 'employee',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Employee',
  insert: ({ data }) => (
    <EmployeeSelectCell data={data} onChange={onChange} field="employee" />
  ),
})

export const importGoalsTargetIdColumn: ImportGoalColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'target_id',
  dataPoint: 'target_id',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Metric ID',
  insert: ({ data }) => (
    <RadioSelectInputCell
      data={data}
      onChange={onChange}
      field="target_id"
      fieldName="metric ID"
      selector={selectorKeys.goal_target_ids}
      selectorField="name"
    />
  ),
})

export const importGoalsIdColumn: ImportGoalColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'id',
  dataPoint: 'id',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'ID',
  insert: ({ data }) => (
    <RadioSelectInputCell
      data={data}
      onChange={onChange}
      field="id"
      selector={selectorKeys.goal_ids}
      selectorField="name"
    />
  ),
})

export const importGoalsTargetTypeColumn: ImportGoalColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'target_type',
  dataPoint: 'target_type',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Metric type',
  insert: ({ data }) => (
    <RadioSelectInputCell
      data={data}
      onChange={onChange}
      field="target_type"
      fieldName="metric type"
      selector={selectorKeys.goal_target_types}
      selectorField="name"
    />
  ),
})

const ChangeSQLQueryPopup = ({
  data,
  onChange,
}: {
  data: ImportInterface<ImportGoalsInterface>
  onChange: (val: string) => void
}) => {
  const [open, setOpen] = useState(false)
  const [sqlQuery, setSqlQuery] = useState(data.data.sql_query || '')

  return (
    <>
      <TextCell
        data={data}
        field="sql_query"
        onChange={() => {}}
        onClick={() => setOpen(true)}
      />
      <Popup open={open} onClose={() => setOpen(false)} size="md">
        <Header variant="item">
          <Header.CloseButton aria-label="Close" />
          <Header.Title>Change SQL query</Header.Title>
        </Header>
        <NewResponsiveCodeEditor onChange={setSqlQuery} value={sqlQuery} />

        <Popup.Actions>
          <Button
            onClick={() => {
              onChange(sqlQuery)
              setOpen(false)
            }}
          >
            Save
          </Button>
        </Popup.Actions>
      </Popup>
    </>
  )
}

export const importGoalsSQLQueryColumn: ImportGoalColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'sql_query',
  dataPoint: 'sql_query',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'SQL query',
  insert: ({ data }) => (
    <ChangeSQLQueryPopup
      onChange={val => {
        onChange(data.id, val, 'sql_query')
      }}
      data={data}
    />
  ),
})

export const importGoalsSQLQueryDbColumn: ImportGoalColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'sql_query_db',
  dataPoint: '',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'SQL query DB',
  insert: ({ data }) => (
    <RadioSelectInputCell
      data={data}
      onChange={onChange}
      field="sql_query_db"
      fieldName="SQL query DB"
      selector={selectorKeys.kpi_sql_query_databases}
      selectorField="name"
    />
  ),
})

export const importGoalsKPIGoalColumn: ImportGoalColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'kpi_goal',
  dataPoint: 'kpi_goal',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.kpi_goals,
  title: 'KPI goal',
  insert: ({ data }) => (
    <RadioSelectInputCell
      data={data}
      onChange={onChange}
      field="kpi_goal"
      fieldName="KPI goal"
      selector={selectorKeys.kpi_goals}
      selectorField="name"
    />
  ),
})

export const importGoalsLookUrlColumn: ImportGoalColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'look_url',
  dataPoint: 'look_url',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Look URL',
  insert: ({ data }) => <TextCell data={data} onChange={onChange} field="look_url" />,
})

export const importGoalsTargetEpicsColumn: ImportGoalColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'target_epics',
  dataPoint: 'target_epics',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Target epics',
  insert: ({ data }) => <TextCell data={data} onChange={onChange} field="target_epics" />,
})
