import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import debounce from 'lodash/debounce'

import { useIntersectViewport } from '@revolut/ui-kit'
import useChartTableSwitcher, {
  ChartTableTabs,
} from '@src/features/TabBarSwitcher/useChartTableSwitcher'
import useTabBarSwitcher from '@src/features/TabBarSwitcher/useTabBarSwitcher'
import {
  getResultsComments,
  useEngagementResultsDrivers,
  useEngagementResultsQuestions,
  useEngagementSurveyRounds,
} from '@src/api/engagement'
import {
  EngagementResultsComment,
  EngagementResultsScope,
} from '@src/interfaces/engagement'
import { OptionInterface } from '@src/interfaces/selectors'
import { FilterByInterface, FilterOption } from '@src/interfaces/data'
import { getFilters } from './helpers'
import { useQuery } from '@src/utils/queryParamsHooks'
import { QueryParams } from './constants'
import { IdAndName } from '@src/interfaces'
import { filterSortPageIntoQuery } from '@src/utils/table'
import { formatDate } from '@src/utils/format'
import { AsyncState } from '@src/components/Inputs/hooks/useFetchOptions'

export interface PageStateQueryInterface {
  [QueryParams.survey]: string
  [QueryParams.driver]: string
  [QueryParams.question]: string
  [QueryParams.average]: string
  [QueryParams.chartView]: string
  [QueryParams.driverComments]: string
  [QueryParams.questionComments]: string
  [QueryParams.round]: string
}

type UpdateQueryParams = {
  surveyId?: string
  questionId?: string
  driverId?: string
  isAverage?: boolean
  isChartView?: boolean
  roundId?: string
}

export const useQueryParams = ({
  survey,
  driver,
  question,
  round,
}: {
  survey?: IdAndName
  driver?: IdAndName
  question?: IdAndName
  round?: IdAndName
}): {
  query: PageStateQueryInterface
  updateQueryParams: (params: UpdateQueryParams) => void
} => {
  const { query, changeQueryParam, deleteQueryParam } =
    useQuery<PageStateQueryInterface>(true)

  const updateQueryParams = (params: UpdateQueryParams) => {
    if (params.surveyId) {
      changeQueryParam(QueryParams.survey, params.surveyId)
    }
    if (params.roundId) {
      changeQueryParam(QueryParams.round, params.roundId)
    }
    if (params.questionId) {
      changeQueryParam(QueryParams.question, params.questionId)
    }
    if (params.driverId) {
      changeQueryParam(QueryParams.driver, params.driverId)
    }
    if (params.isAverage) {
      changeQueryParam(QueryParams.average, 'true')
    } else if (params.isAverage === false) {
      deleteQueryParam(QueryParams.average)
    }
    if (params.isChartView) {
      changeQueryParam(QueryParams.chartView, 'true')
    } else if (params.isChartView === false) {
      deleteQueryParam(QueryParams.chartView)
    }
  }

  useEffect(() => {
    if (survey?.id) {
      updateQueryParams({ surveyId: String(survey.id) })
    }
  }, [survey?.id])

  useEffect(() => {
    if (round?.id) {
      updateQueryParams({ roundId: String(round.id) })
    }
  }, [round?.id])

  useEffect(() => {
    if (driver?.id) {
      updateQueryParams({ driverId: String(driver.id) })
    }
  }, [driver?.id])

  useEffect(() => {
    if (question?.id) {
      updateQueryParams({ questionId: String(question.id) })
    }
  }, [question?.id])

  return { query, updateQueryParams }
}

export enum ToggleAverageTabs {
  Average = 'Average',
  NPS = 'NPS',
}

export type TableSwitchersReturnType = {
  isAverage: boolean
  tabBar: React.ReactElement | null
  currentTab: ChartTableTabs | undefined
  toggleAverageTabBar: React.ReactElement | null
  setChartTableTab: (newTab: ChartTableTabs) => void
}
export const useTableSwitchers = (): TableSwitchersReturnType => {
  const { query, updateQueryParams } = useQueryParams({})

  const [isAverage, setIsAverage] = useState<boolean>(
    query[QueryParams.average] === 'true',
  )

  const {
    tabBar,
    currentTab,
    setCurrentTab: setChartTableTab,
  } = useChartTableSwitcher({
    showTableFirst: true,
    showTableByDefault: query[QueryParams.chartView] !== 'true',
    highlightSelected: false,
    onTabChange: newTab => {
      updateQueryParams({ isChartView: newTab === 'Chart' })
    },
  })
  const { tabBar: toggleAverageTabBar } = useTabBarSwitcher({
    tabs: [ToggleAverageTabs.NPS, ToggleAverageTabs.Average],
    onTabChange: tab => {
      const newIsAverage = tab === ToggleAverageTabs.Average

      setIsAverage(newIsAverage)
      updateQueryParams({ isAverage: newIsAverage })
    },
    defaultTab:
      query[QueryParams.average] === 'true'
        ? ToggleAverageTabs.Average
        : ToggleAverageTabs.NPS,
    highlightSelected: false,
    tooltips: {
      [ToggleAverageTabs.NPS]: {
        placement: 'left',
        text: 'NPS (net promoter score) is equal to the percentage of promoters minus the percentage of detractors, it ranges from -100 to +100',
      },
      [ToggleAverageTabs.Average]: {
        placement: 'left',
        text: 'Average score is equal to the average of all answers for each particular question or driver, it ranges from 1 to 5',
      },
    },
  })

  return {
    isAverage,
    tabBar,
    currentTab,
    toggleAverageTabBar,
    setChartTableTab: (newTab: ChartTableTabs) => {
      setChartTableTab(newTab)
      updateQueryParams({ isChartView: newTab === 'Chart' })
    },
  }
}

export const useDriversOptions = ({
  enabled = true,
  surveyId,
  questionId,
  defaultFilters,
}: {
  enabled?: boolean
  surveyId?: string
  questionId?: string
  defaultFilters?: FilterByInterface[]
}) => {
  const filtersParams = filterSortPageIntoQuery(
    undefined,
    getFilters({
      defaultSurveyId: surveyId,
      defaultQuestionId: questionId,
    })({ defaultFilters }),
  )

  const { data: driversData, isFetching } = useEngagementResultsDrivers({
    enabled,
    filtersParams,
  })
  const driversOptions = useMemo(
    () =>
      driversData?.results
        ? driversData.results.map(({ id, name }) => ({ id, name }))
        : [],
    [driversData],
  )

  return {
    isFetching,
    driversOptions,
  }
}

export const useQuestionsOptions = ({
  enabled = true,
  surveyId,
  driverId,
  defaultFilters,
}: {
  enabled?: boolean
  surveyId?: string
  driverId?: string
  defaultFilters?: FilterByInterface[]
}) => {
  const filtersParams = filterSortPageIntoQuery(
    undefined,
    getFilters({
      defaultSurveyId: surveyId,
      defaultDriverId: driverId,
    })({ defaultFilters }),
  )
  const { data: questionsData, isFetching } = useEngagementResultsQuestions({
    enabled,
    filtersParams,
  })
  const questionsOptions = useMemo(
    () =>
      questionsData?.results
        ? questionsData.results.map(({ id, question_text }) => ({
            id,
            name: question_text,
          }))
        : [],
    [questionsData],
  )

  return {
    isFetching,
    questionsOptions,
  }
}

export const useCommentsFilters = ({
  defaultSurveyId,
  defaultDriverId,
  defaultQuestionId,
}: {
  defaultSurveyId?: string
  defaultDriverId?: string
  defaultQuestionId?: string
}) => {
  const [searchValue, setSearchValue] = useState<string>('')
  const [textFilter, setTextFilter] = useState<string>('')
  const [scoreFilter, setScoreFilter] = useState<OptionInterface[]>([])
  const [dateFilter, setDateFilter] = useState<FilterOption[]>([])
  const [driversFilter, setDriversFilter] = useState<OptionInterface[]>([])
  const [questionsFilter, setQuestionsFilter] = useState<OptionInterface[]>([])
  const [questionTypeFilter, setQuestionTypeFilter] = useState<OptionInterface[]>([])

  const tableFilters = getFilters({
    defaultSurveyId,
    defaultDriverId,
    defaultQuestionId,
  })({
    textFilter,
    scoreFilter,
    driversFilter,
    questionsFilter,
    dateFilter,
    questionTypeFilter,
  })

  const filtersParams = useMemo(() => {
    return filterSortPageIntoQuery(undefined, tableFilters)
  }, [
    textFilter,
    scoreFilter,
    driversFilter,
    questionsFilter,
    dateFilter,
    defaultSurveyId,
    defaultDriverId,
    defaultQuestionId,
    questionTypeFilter,
  ])

  const setTextFilterDebounced = useCallback(debounce(setTextFilter, 500), [])

  return {
    tableFilters,
    filtersParams,
    search: {
      searchValue,
      setSearchValue,
    },
    filters: {
      setTextFilter: setTextFilterDebounced,
      scoreFilter,
      setScoreFilter,
      dateFilter,
      setDateFilter,
      driversFilter,
      setDriversFilter,
      questionsFilter,
      setQuestionsFilter,
      questionTypeFilter,
      setQuestionTypeFilter,
    },
  }
}

export const useInfiniteCommentsLoading = ({
  scope,
  scopeId,
  filtersParams,
}: {
  scope?: EngagementResultsScope
  scopeId: number
  filtersParams: Object
}) => {
  const [comments, setComments] = useState<EngagementResultsComment[]>([])
  const [total, setTotal] = useState<number>()
  const [isLoadingMore, setIsLoadingMore] = useState(false)
  const [isReloadingFilters, setIsReloadingFilters] = useState(false)
  const [page, setPage] = useState<number>(1)
  const [hasMoreData, setHasMoreData] = useState<boolean>(false)
  const ref = useRef(null)

  useIntersectViewport(ref, isIntersecting => {
    if (isReloadingFilters) {
      return
    }
    if (hasMoreData && isIntersecting) {
      setPage(page + 1)
    }
  })

  const loadComments = (newPage = 1) => {
    setPage(newPage)

    return getResultsComments({
      scope,
      scopeId,
      filters: { ...filtersParams, page: newPage },
    }).then(({ data }) => {
      const { results, pages, count } = data

      setHasMoreData(!!pages.next)
      setTotal(count)

      return results
    })
  }

  useEffect(() => {
    if (isReloadingFilters || page === 1) {
      return
    }
    setIsLoadingMore(true)

    loadComments(page)
      .then(newPageComments => {
        setComments([...comments, ...newPageComments])
      })
      .finally(() => {
        setIsLoadingMore(false)
      })
  }, [page])

  useEffect(() => {
    if (isLoadingMore) {
      return
    }
    setIsReloadingFilters(true)

    loadComments()
      .then(filteredComments => {
        setComments(filteredComments)
      })
      .finally(() => {
        setIsReloadingFilters(false)
      })
  }, [filtersParams])

  return { comments, total, isLoadingMore, isReloadingFilters, ref, hasMoreData, page }
}

export const useSurveyRounds = (surveyId?: number) => {
  const {
    data: surveyRoundsResponse,
    isLoading: isLoadingRounds,
    isError: isErrorRounds,
  } = useEngagementSurveyRounds(surveyId)
  const surveyRounds =
    surveyRoundsResponse?.results
      .filter(item => !!item.sent_on)
      .map(item => ({
        id: item.id,
        name: formatDate(`${item.sent_on}`),
      })) || []

  const surveyRoundsAsyncState = (): AsyncState => {
    if (isLoadingRounds) {
      return 'pending'
    }

    if (isErrorRounds) {
      return 'failed'
    }

    return 'ready'
  }

  return { rounds: surveyRounds, asyncState: surveyRoundsAsyncState() }
}
