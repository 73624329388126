import { Box, Item, Spacer, TransitionCollapse } from '@revolut/ui-kit'
import LapeNewSwitch, {
  type LapeNewSwitchProps,
} from '@src/components/Inputs/LapeFields/LapeNewSwitch'
import { useLapeContext } from '@src/features/Form/LapeForm'
import React, { MutableRefObject, PropsWithChildren, ReactNode } from 'react'

export interface CollapsibleRadioSwitchProps extends LapeNewSwitchProps {
  alwaysShownContent?: ReactNode
  blockRef?: MutableRefObject<HTMLDivElement | null>
}

export const CollapsibleRadioSwitch = <T extends {}>({
  children,
  alwaysShownContent,
  blockRef,
  ...switchProps
}: PropsWithChildren<CollapsibleRadioSwitchProps>) => {
  const { values } = useLapeContext<T>()
  const expanded = !!values[switchProps.name as keyof T]

  return (
    <Item ref={blockRef}>
      <Item.Content>
        <Box m="-s-16">
          <LapeNewSwitch {...switchProps} />
        </Box>

        {alwaysShownContent ? <Box mt="s-32">{alwaysShownContent}</Box> : null}
        <TransitionCollapse in={expanded}>
          <Spacer height={alwaysShownContent ? 's-16' : 's-32'} />
          {children}
        </TransitionCollapse>
      </Item.Content>
    </Item>
  )
}
