import isObject from 'lodash/isObject'
import get from 'lodash/get'
import { ROUTES } from '@src/constants/routes'
import { selectFeatureFlags } from '@src/store/auth/selectors'
import { useSelector } from 'react-redux'
import { FeatureFlags } from '@src/store/auth/types'

// This is a temp intermediary for redirecting old layout performance tab routes
// to the new ones, should be removed after complete switching to the new layout.
// Full redirects list reference can be found in helper's test assertion
export const getRedirects = () => {
  const redirects: Record<string, string> = {
    '/employees/performance-summary/:id?': '/employees/performance-tab/:id/summary',
    '/employees/performance/:id?/performance':
      '/employees/performance-tab/:id/performance/summary',
  }
  const performanceTabs = [
    'SUMMARY',
    'PERFORMANCE',
    'KPI',
    'ROADMAP',
    'MEETINGS',
    'HIRING',
    'TALENT',
    'ENGAGEMENT',
    'AUDIT',
    'RISK',
  ]

  performanceTabs.forEach(performanceTab => {
    if (isObject(get(ROUTES.FORMS.EMPLOYEE, performanceTab))) {
      Object.keys(get(ROUTES.FORMS.EMPLOYEE, performanceTab)).forEach(
        performanceSubTab => {
          const from = get(ROUTES.FORMS.EMPLOYEE, [performanceTab, performanceSubTab])
          const to = get(ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT, [
            performanceTab,
            performanceSubTab,
          ])
          if (performanceSubTab !== 'ANY' && from && to && !redirects[from]) {
            redirects[from] = to
          }
        },
      )
    } else {
      const from = get(ROUTES.FORMS.EMPLOYEE, performanceTab)
      const to = get(ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT, performanceTab)
      if (from && to && !redirects[from]) {
        redirects[from] = to
      }
    }
  })
  return redirects
}

export const performanceRedirects = getRedirects()

export const mapPerfRoute = (isNewLayout: boolean | undefined, route: string) => {
  if (isNewLayout) {
    return performanceRedirects[route]
  }
  return route
}

// Left until full cleanup in case of urgent need to switch back to the old view
export const useIsNewLayout = () => {
  const featureFlags = useSelector(selectFeatureFlags)
  return !featureFlags.includes(FeatureFlags.EmployeeProfileOldLayout)
}
