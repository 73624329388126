import React, { useMemo } from 'react'
import { Box, ErrorWidget, Group, Item, ItemSkeleton, MoreBar } from '@revolut/ui-kit'
import { Envelope } from '@revolut/icons'
import { useParams } from 'react-router-dom'

import { PageBody } from '@components/Page/PageBody'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import EmployeeEmailThreadItemAvatar from '@src/features/EmailThread/EmailThreadItemAvatar'
import { EmployeeEmailContent } from '@src/features/EmployeeEmails/EmployeeEmailContent'
import AddBubbles from '@src/assets/3D/AddBubbles@2x.png'
import SettingsButtons from '@src/features/SettingsButtons'
import { SyncEmployeeEmails } from '@src/features/EmployeeEmails/SyncEmployeeEmails'
import { useEmployeeEmailThreads } from '@src/api/employeeEmails'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import HideIfGmailDisabled from '@components/HideIfGmailDisabled/HideIfGmailDisabled'
import { formatDateTime } from '@src/utils/format'

interface EmailsInterface {
  employeeId?: number
  emailUrl?: string
}

export const EmployeeEmails = ({ employeeId, emailUrl }: EmailsInterface) => {
  const params = useParams<{ id: string }>()
  const id = employeeId || params.id
  const { data, isLoading } = useEmployeeEmailThreads(id)

  const threads = useMemo(
    () => data?.results.filter(thread => thread.emails.length > 0),
    [data?.results],
  )

  return (
    <PageBody>
      <SettingsButtons pb="s-16">
        <MoreBar.Action
          useIcon={Envelope}
          use={InternalLink}
          to={
            emailUrl ||
            pathToUrl(ROUTES.FORMS.SEND_EMPLOYEE_EMAIL.EMPLOYEE, { employeeId: id })
          }
        >
          Send email
        </MoreBar.Action>
        <HideIfGmailDisabled>
          <SyncEmployeeEmails id={id} />
        </HideIfGmailDisabled>
      </SettingsButtons>
      {isLoading ? (
        <ItemSkeleton />
      ) : (
        <Box>
          <Group>
            {threads?.length ? (
              threads.map(thread => (
                <Item
                  use="button"
                  key={thread.id}
                  onClick={() => {
                    navigateTo(
                      pathToUrl(ROUTES.FORMS.SEND_EMPLOYEE_EMAIL.VIEW, {
                        employeeId: id,
                        threadId: thread.id,
                        emailId: thread.emails[0].id,
                      }),
                    )
                  }}
                >
                  <EmployeeEmailThreadItemAvatar email={thread.emails[0]} />
                  <EmployeeEmailContent thread={thread} />
                  <Item.Side>
                    <Item.Value variant="secondary">
                      {formatDateTime(thread.latest_sent_date_time)}
                    </Item.Value>
                  </Item.Side>
                </Item>
              ))
            ) : (
              <ErrorWidget>
                <ErrorWidget.Image src={AddBubbles} />
                <ErrorWidget.Title>No email communication found</ErrorWidget.Title>
              </ErrorWidget>
            )}
          </Group>
        </Box>
      )}
    </PageBody>
  )
}
