import React, { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { format } from 'date-fns'
import {
  FilterButton,
  Flex,
  IconButton,
  MoreBar,
  TableWidget,
  Text,
  Token,
  VStack,
} from '@revolut/ui-kit'
import {
  getDepartmentTimeOffCalendar,
  getEmployeesTimeOffCalendar,
  getRequestsOfReportsCalendar,
  getTeamTimeOffCalendar,
} from '@src/api/timeOff'
import { useGetTeam } from '@src/api/teams'
import { requestOfReportsEmployeeColumn } from '@src/constants/columns/timeOff'
import { LOCAL_STORAGE } from '@src/constants/api'
import { ROUTES } from '@src/constants/routes'
import { TableNames } from '@src/constants/table'
import { EmployeeTimeOffRequestsCalendarInterface } from '@src/interfaces/timeOff'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import AdjustableTable from '@src/components/Table/AdjustableTable'
import { useTable } from '@src/components/Table/hooks'
import { selectPermissions, selectUser } from '@src/store/auth/selectors'
import useTabBarSwitcher from '@src/features/TabBarSwitcher/useTabBarSwitcher'
import { WeekMonthTabs } from '@src/features/TimeOffCalendarTable/constants'
import {
  useCurrentMonthRow,
  useCurrentWeekRow,
  useTimeOffCalendarControls,
  useTimeOffCalendarFilters,
} from '@src/features/TimeOffCalendarTable/hooks'
import { useLocalStorage } from '@src/hooks/useLocalStorage'
import { PermissionTypes } from '@src/store/auth/types'
import { pathToUrl } from '@src/utils/router'
import { ApprovalsWidget } from './ApprovalsWidget'
import { OnboardingAppScreen } from '@src/pages/OnboardingChecklistV2/components/OnboardingAppScreen'

type CalendarType = 'team' | 'department' | 'reports'

const TimeManagementBase = () => {
  const user = useSelector(selectUser)
  const permissions = useSelector(selectPermissions)
  const { data: teamData } = useGetTeam(user.team?.id)
  const [calendarType, setCalendarType] = useLocalStorage(
    LOCAL_STORAGE.TIME_MANAGEMENT_FILTER,
    user.team?.id ? 'team' : 'all',
  )

  const canViewTeamRequestsPolicy = !!teamData?.field_options.permissions?.includes(
    PermissionTypes.ViewTimeOffRequestPolicy,
  )
  const canViewPolicyColumn = calendarType === 'team' ? canViewTeamRequestsPolicy : false
  const canViewTimeOffPolicies = permissions.includes(PermissionTypes.ViewTimeOffPolicies)
  const canViewTimeOffPreferences = permissions.includes(
    PermissionTypes.ViewTimeOffPreferences,
  )

  const { tabBar: periodTabBar, currentTab: periodCurrentTab } = useTabBarSwitcher({
    tabs: [WeekMonthTabs.Week, WeekMonthTabs.Month],
    defaultTab: WeekMonthTabs.Month,
    highlightSelected: false,
  })

  const {
    currentDay,
    onClickNextWeek,
    onClickPrevWeek,
    onClickNextMonth,
    onClickPrevMonth,
  } = useTimeOffCalendarControls()

  const { startOfWeek, endOfWeek, getFilters } = useTimeOffCalendarFilters(
    periodCurrentTab === 'Week',
    currentDay,
  )

  const tableApi = useMemo(() => {
    const getItems =
      user.team?.id && calendarType === 'team'
        ? getTeamTimeOffCalendar(user.team?.id)
        : user.team?.department_id && calendarType === 'department'
        ? getDepartmentTimeOffCalendar(user.team?.department_id)
        : calendarType === 'reports'
        ? getRequestsOfReportsCalendar(user.id)
        : getEmployeesTimeOffCalendar

    return { getItems }
  }, [calendarType, user])

  const table = useTable(tableApi, getFilters(), undefined, {
    disableQuery: true,
    refetchOnApiChange: true,
  })

  useEffect(() => {
    table.onFilterChange(getFilters())
  }, [currentDay, periodCurrentTab])

  const weekRow = useCurrentWeekRow(
    currentDay,
    [
      {
        ...requestOfReportsEmployeeColumn,
        width: 220,
      },
    ],
    canViewPolicyColumn,
  )

  const monthRow = useCurrentMonthRow(
    currentDay,
    [
      {
        ...requestOfReportsEmployeeColumn,
        width: 220,
      },
    ],
    canViewPolicyColumn,
  )

  const handleFilterButtonChange = (type: CalendarType) => {
    setCalendarType(calendarType !== type ? type : 'all')
    table.resetFiltersAndSorting()
  }

  return (
    <VStack space="s-16">
      <ApprovalsWidget />
      <TableWidget>
        <TableWidget.Actions>
          <MoreBar>
            <MoreBar.Action
              to={pathToUrl(ROUTES.FORMS.EMPLOYEE_TIME_OFF_REQUEST.GENERAL, {
                employeeId: user.id,
              })}
              use={InternalLink}
              useIcon="Plus"
            >
              Request time off
            </MoreBar.Action>
            {canViewTimeOffPolicies && (
              <MoreBar.Action
                to={pathToUrl(ROUTES.APPS.TIME_MANAGEMENT.TIME_OFF.POLICIES)}
                use={InternalLink}
                useIcon="Pencil"
              >
                Manage policies
              </MoreBar.Action>
            )}
            {canViewTimeOffPreferences && (
              <MoreBar.Action
                to={pathToUrl(ROUTES.SETTINGS.TIME_OFF.GENERAL)}
                use={InternalLink}
                useIcon="Gear"
              >
                Settings
              </MoreBar.Action>
            )}
          </MoreBar>
        </TableWidget.Actions>
        <TableWidget.Filters>
          {user.team?.id && (
            <FilterButton
              active={calendarType === 'team'}
              onClick={() => handleFilterButtonChange('team')}
            >
              My team
            </FilterButton>
          )}
          {user.team?.department_id && (
            <FilterButton
              active={calendarType === 'department'}
              onClick={() => handleFilterButtonChange('department')}
            >
              My department
            </FilterButton>
          )}
          <FilterButton
            active={calendarType === 'reports'}
            onClick={() => handleFilterButtonChange('reports')}
          >
            My reports
          </FilterButton>
        </TableWidget.Filters>
        <TableWidget.Table>
          <VStack gap="s-16">
            <Flex>{periodTabBar}</Flex>
            <Flex alignItems="center" justifyContent="space-between" width="100%">
              <IconButton
                aria-label={
                  periodCurrentTab === 'Week' ? 'Previous week' : 'Previous month'
                }
                color={Token.color.greyTone50}
                onClick={periodCurrentTab === 'Week' ? onClickPrevWeek : onClickPrevMonth}
                useIcon="ChevronLeft"
              />
              {periodCurrentTab === 'Week' ? (
                <Text variant="h5">
                  {format(startOfWeek, 'dd MMM yyyy')} -{' '}
                  {format(endOfWeek, 'dd MMM yyyy')}
                </Text>
              ) : (
                <Text variant="h5">{format(currentDay, 'MMMM yyyy')}</Text>
              )}
              <IconButton
                aria-label={periodCurrentTab === 'Week' ? 'Next week' : 'Next month'}
                color={Token.color.greyTone50}
                onClick={periodCurrentTab === 'Week' ? onClickNextWeek : onClickNextMonth}
                useIcon="ChevronRight"
              />
            </Flex>
            <AdjustableTable<EmployeeTimeOffRequestsCalendarInterface>
              enableSettings={false}
              hideCountAndButtonSection
              name={TableNames.PeopleTimeOffCalendar}
              row={periodCurrentTab === 'Week' ? weekRow : monthRow}
              rowHeight="large"
              useWindowScroll
              {...table}
            />
          </VStack>
        </TableWidget.Table>
      </TableWidget>
    </VStack>
  )
}

export const TimeManagement = () => {
  return (
    <OnboardingAppScreen category="timeManagement">
      <TimeManagementBase />
    </OnboardingAppScreen>
  )
}
