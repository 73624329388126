import React from 'react'
import { Box, IconButton, Search } from '@revolut/ui-kit'

import globalSearchState from '@components/GlobalSearchSidebar/GlobalSearchSidebarState'
import Tooltip from '@components/Tooltip/Tooltip'
import { TooltipContainer } from '@src/components/CommonSC/Tooltip'
import { MainHeaderButton } from '@src/features/MainHeader/MainHeaderButton'
import { Search as SearchIcon } from '@revolut/icons'

export const GlobalSearch = () => {
  const isUsingWindows = navigator.platform.indexOf('Win') >= 0

  const openGlobalSearch = () => {
    globalSearchState.open = !globalSearchState.open
  }

  return (
    <Tooltip
      placement="bottom"
      body={
        <TooltipContainer minWidth={72}>
          {isUsingWindows ? 'Ctrl' : '⌘'} + K
        </TooltipContainer>
      }
    >
      <Box display={{ md: 'none' }}>
        <MainHeaderButton>
          <IconButton
            useIcon={SearchIcon}
            aria-label="Search"
            size={24}
            onClick={openGlobalSearch}
            color="grey-tone-50"
          />
        </MainHeaderButton>
      </Box>
      <Box
        ml="s-16"
        width="100%"
        minWidth={150}
        maxWidth={200}
        display={{ all: 'none', md: 'flex' }}
      >
        <Search onClick={openGlobalSearch} placeholder="Search" />
      </Box>
    </Tooltip>
  )
}
