import { useLapeContext } from '@src/features/Form/LapeForm'
import { GoalsInterface } from '@src/interfaces/goals'
import { useState, useEffect } from 'react'
import { getCache, setCache } from './helpers'
import { isEqual } from 'lodash'

export const useGoalFormCache = () => {
  const { values, initialValues } = useLapeContext<GoalsInterface>()
  const [cacheUpdated, setCacheUpdated] = useState(false)

  const lsKey = `goal-${values.id}-cache`
  const cache = getCache(lsKey)

  useEffect(() => {
    if (cache) {
      Object.keys(cache).forEach(key => {
        // key is keyof GoalsInterface but typescript marks it as string
        // @ts-expect-error
        values[key] = cache[key]
      })
    }
    setCacheUpdated(true)
  }, [])

  useEffect(() => {
    if (
      values.name !== initialValues.name ||
      values.description !== initialValues.description ||
      values.owner?.id !== initialValues.owner?.id ||
      values.update_type?.id !== initialValues.update_type?.id ||
      values.content_object?.id !== initialValues.content_object?.id ||
      values.parent?.id !== initialValues.parent?.id ||
      !isEqual(values.kpis, initialValues.kpis) ||
      values.kpis.length === initialValues.kpis?.length // need to check if we don't had empty new metric added then deleted so that it doesn't reappear
    ) {
      setCache(lsKey, {
        name: values.name,
        description: values.description,
        owner: values.owner ? { id: values.owner.id } : undefined,
        parent: values.parent ? ({ id: values.parent.id } as GoalsInterface) : null,
        update_type: values.update_type
          ? { id: values.update_type.id, name: values.update_type.name }
          : undefined,
        content_object: values.content_object,
        kpis: values.kpis,
      })
    }
  }, [
    values.name,
    values.description,
    values.owner?.id,
    values.parent?.id,
    values.update_type?.id,
    values.content_object?.id,
    values.kpis.map(kpi => Object.values(kpi)),
  ])

  return {
    cleanCache: () => localStorage.removeItem(lsKey),
    cacheUpdated,
  }
}
