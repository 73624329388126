import React, { useState } from 'react'
import { Flex, InputGroup } from '@revolut/ui-kit'
import { useSelector } from 'react-redux'

import NewStepperTitle, { NewStepperTitleType } from '@components/Stepper/NewStepperTitle'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import { selectorKeys } from '@src/constants/api'
import LapeDatePickerInput from '@components/Inputs/LapeFields/LapeDatePickerInput'
import LapeNewTimeInput from '@components/Inputs/LapeFields/LapeNewTimeInput'
import AutoStepper from '@components/Stepper/AutoStepper'
import { useLapeContext } from '@src/features/Form/LapeForm'
import EmployeeEmailForm from '@src/features/EmployeeEmailForm/EmployeeEmailForm'
import { selectUser } from '@src/store/auth/selectors'
import {
  SendEmployeeEmailCommonProps,
  TemplateCategories,
} from './SendEmployeeEmailCommon'
import RadioSelectInput from '@src/components/Inputs/RadioSelectInput/RadioSelectInput'
import {
  EmployeeWhenToSend,
  SendEmployeeEmailInterface,
} from '@src/interfaces/employeeEmails'

interface TemplateOption {
  id: string
  category: { id: TemplateCategories }
}

interface Props
  extends Pick<
    SendEmployeeEmailCommonProps,
    'children' | 'noEditor' | 'bottom' | 'templateFilter'
  > {}

const SendEmployeeEmailCommonContent = ({
  children,
  noEditor,
  bottom,
  templateFilter,
}: Props) => {
  const user = useSelector(selectUser)
  const { values } = useLapeContext<SendEmployeeEmailInterface>()

  const [filterKey, setFilterKey] = useState<TemplateCategories>()

  const emailTemplateFilter = (optionValue: TemplateOption) => {
    if (templateFilter) {
      return templateFilter(optionValue)
    }
    if (filterKey) {
      return optionValue.category?.id === filterKey
    }
    return true
  }

  return (
    <AutoStepper>
      {children}

      {!noEditor && (
        <>
          <NewStepperTitle title="Email details" type={NewStepperTitleType.compact} />
          <InputGroup>
            {templateFilter ? null : (
              <RadioSelectInput<{ id: TemplateCategories }>
                label="Category"
                onChange={option => {
                  values.email_template = null
                  setFilterKey(option?.id)
                }}
                value={filterKey !== undefined ? { id: filterKey } : null}
                selector={selectorKeys.employee_email_template_categories}
              />
            )}
            {filterKey === null ? null : (
              <LapeRadioSelectInput<TemplateOption>
                name="email_template"
                label="Email template"
                selector={selectorKeys.employee_email_templates_active}
                filter={emailTemplateFilter}
              />
            )}
            <LapeRadioSelectInput
              name="when_to_send"
              label="When to send"
              selector={selectorKeys.employee_email_when_to_send}
            />
            {values.when_to_send?.id === EmployeeWhenToSend.CUSTOM && (
              <InputGroup variant="horizontal">
                <Flex flex="1">
                  <LapeDatePickerInput
                    clearable={false}
                    name="custom_sending_datetime"
                    label="Schedule date"
                    disabledDays={{ before: new Date() }}
                    required
                  />
                </Flex>
                <Flex flex="1">
                  <LapeNewTimeInput
                    name="custom_sending_datetime"
                    label="Schedule time"
                    required
                  />
                </Flex>
              </InputGroup>
            )}
            <EmployeeEmailForm userEmail={user.email} noInvalidPlaceholders />
          </InputGroup>
        </>
      )}

      {bottom}
    </AutoStepper>
  )
}

export default SendEmployeeEmailCommonContent
