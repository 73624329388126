import React from 'react'
import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import { selectorKeys } from '../api'
import { EngagementDriverInterface } from '@src/interfaces/engagement'
import UserWithAvatar from '@components/UserWithAvatar/UserWithAvatar'
import { mapStatusToColor } from '@src/apps/People/Engagement/helpers'

export const engagementDriverName: ColumnInterface<EngagementDriverInterface> = {
  type: CellTypes.text,
  idPoint: 'id',
  dataPoint: 'name',
  sortKey: 'name',
  filterKey: 'id',
  selectorsKey: selectorKeys.engagement_drivers,
  title: 'Driver name',
}

export const engagementDriverOwner: ColumnInterface<EngagementDriverInterface> = {
  type: CellTypes.insert,
  idPoint: 'owner.id',
  dataPoint: 'owner.name',
  sortKey: 'owner__full_name',
  filterKey: 'owner__id',
  selectorsKey: selectorKeys.employee,
  title: 'Owner',
  insert: ({ data }) => <UserWithAvatar {...data.owner} />,
}

export const engagementDriverCreatedOn: ColumnInterface<EngagementDriverInterface> = {
  type: CellTypes.date,
  idPoint: 'creation_date_time',
  dataPoint: 'creation_date_time',
  sortKey: 'creation_date_time',
  filterKey: 'creation_date_time',
  selectorsKey: selectorKeys.none,
  title: 'Created on',
}

export const engagementDriverLastUpdateOn: ColumnInterface<EngagementDriverInterface> = {
  type: CellTypes.date,
  idPoint: 'update_date_time',
  dataPoint: 'update_date_time',
  sortKey: 'update_date_time',
  filterKey: 'update_date_time',
  selectorsKey: selectorKeys.none,
  title: 'Last update on',
}

export const engagementDriverStatus: ColumnInterface<EngagementDriverInterface> = {
  type: CellTypes.text,
  idPoint: 'status.id',
  dataPoint: 'status.name',
  sortKey: 'status',
  filterKey: 'status',
  colors: mapStatusToColor,
  selectorsKey: selectorKeys.engagement_driver_statuses,
  title: 'Status',
}
