import React from 'react'
import { Box, Bullet, Flex, Link, Text, Widget } from '@revolut/ui-kit'
import { connect } from 'lape'

interface GeneralExamplesProps {
  hideInstructions?: boolean
}

const GeneralSpecialisationExamples = ({ hideInstructions }: GeneralExamplesProps) => {
  return (
    <Box>
      {!hideInstructions && (
        <>
          <Text mb="s-16" color="grey-tone-50" use="div">
            Instructions
          </Text>
          <Widget mb="s-32" padding="s-16">
            <Box>
              <Flex alignItems="flex-start">
                <Bullet style={{ flexShrink: 0 }} m="s-8">
                  1
                </Bullet>
                <Text use="div" mt="s-12" ml="5px">
                  Define a specialisation name in the format: Parent role
                  (Specialisation). eg Product Owner (Accounting)
                </Text>
              </Flex>
              <Flex alignItems="flex-start">
                <Bullet style={{ flexShrink: 0 }} m="s-8">
                  2
                </Bullet>
                <Text use="div" mt="s-12" ml="5px">
                  Specialisation owner should belong to the parent role
                </Text>
              </Flex>
              <Flex alignItems="flex-start">
                <Bullet style={{ flexShrink: 0 }} m="s-8">
                  3
                </Bullet>
                <Text use="div" mt="s-12" ml="5px">
                  Write a short summary of the job’s core purpose so everybody (employees
                  and prospective candidates) understands why we need it.
                  <br />
                  <Link
                    target="_blank"
                    rel="noreferrer noopener"
                    href="https://revolut.atlassian.net/wiki/spaces/REV/pages/1271432279/Defining+role+missions"
                  >
                    How to define specialisation mission?
                  </Link>
                </Text>
              </Flex>
            </Box>
          </Widget>
        </>
      )}
    </Box>
  )
}

export default connect(GeneralSpecialisationExamples)
