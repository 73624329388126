import React, { useEffect } from 'react'
import { Group } from '@revolut/ui-kit'
import { connect } from 'lape'

import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { goBack } from '@src/actions/RouterActions'
import SendEmailCommon from '@src/pages/Forms/SendEmployeeEmail/SendEmployeeEmailCommon'
import { FormPreview } from '@components/FormPreview/FormPreview'
import Form from '@src/features/Form/Form'
import { PageWrapper } from '@src/components/Page/Page'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { OffboardingScreenProps, useOffboardingParams } from './common'
import { employeeEmailsRequests, getPrefilledTemplate } from '@src/api/employeeEmails'
import { useEmployeeFields } from '@src/api/employees'
import { EmployeeInterface } from '@src/interfaces/employees'
import UserWithAvatar from '@src/components/UserWithAvatar/UserWithAvatar'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { SendEmployeeEmailInterface } from '@src/interfaces/employeeEmails'

type EmployeeDetails = Pick<EmployeeInterface, 'full_name' | 'avatar' | 'position'>

const OFFBOARDING_CATEGORY = {
  id: 'offboarding',
  name: 'Offboarding',
}

const SendEmail = ({ data, refreshDashboard }: OffboardingScreenProps) => {
  const params = useOffboardingParams()
  const { values } = useLapeContext<SendEmployeeEmailInterface>()

  const { data: employee, isLoading } = useEmployeeFields<EmployeeDetails>(
    data.employee.id,
    ['full_name', 'avatar', 'position'],
  )

  useEffect(() => {
    values.category = OFFBOARDING_CATEGORY
  }, [])

  const onSuccess = () => {
    refreshDashboard()
    goBack()
  }

  const fetchEmail = (templateId: number) => {
    return getPrefilledTemplate(templateId, +params.employeeId)
  }

  return (
    <PageWrapper>
      <PageHeader
        title="Offboarding email"
        backUrl={pathToUrl(ROUTES.FORMS.OFFBOARDING.DASHBOARD, params)}
      />
      <SendEmailCommon
        onSuccess={onSuccess}
        fetchEmail={fetchEmail}
        templateFilter={item => item.category.id === 'offboarding'}
      >
        <FormPreview data={employee}>
          <Group>
            <FormPreview.Item
              title="Employee"
              loading={isLoading}
              insert={() => employee && <UserWithAvatar {...employee} />}
            />
            <FormPreview.Item title="Position" field="position.name" />
          </Group>
        </FormPreview>
      </SendEmailCommon>
    </PageWrapper>
  )
}

export default connect((props: OffboardingScreenProps) => (
  <Form api={employeeEmailsRequests}>
    <SendEmail {...props} />
  </Form>
))
