import React, { MouseEvent, useState } from 'react'
import { Action, ActionProps, Box } from '@revolut/ui-kit'

interface LoadingActionProps extends ActionProps {
  onSubmit: () => Promise<any>
}

const LoadingAction = ({ onSubmit, children, ...rest }: LoadingActionProps) => {
  const [loading, setLoading] = useState(false)
  const handleClick = async (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    try {
      setLoading(true)
      await onSubmit()
    } finally {
      setLoading(false)
    }
  }

  return (
    <Action
      style={{ fontSize: 13 }}
      {...rest}
      disabled={loading}
      pending={loading}
      onClick={handleClick}
    >
      {loading ? <Box minWidth={80} /> : children}
    </Action>
  )
}

export default LoadingAction
