import React from 'react'
import { ActionButton, ActionButtonProps } from '@revolut/ui-kit'
import { ReviewCategory } from '@src/interfaces/performance'
import { useLocation } from 'react-router-dom'
import { getReviewsUrl } from './common'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { useHasNewScorecards } from '@src/utils/performance'

interface Props extends ActionButtonProps {
  category?: ReviewCategory
  cycleId?: string
  employeeId?: number
}

const OpenReviewButton = ({ category, cycleId, employeeId, ...buttonProps }: Props) => {
  const location = useLocation()
  const showNewScorecards = useHasNewScorecards()

  return (
    <ActionButton
      use={InternalLink}
      useIcon="LinkExternal"
      to={getReviewsUrl(category, cycleId, employeeId, location, showNewScorecards)}
      target="_blank"
      data-testid="open-review-btn"
      {...buttonProps}
    >
      Open Review
    </ActionButton>
  )
}

export default OpenReviewButton
