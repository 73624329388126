import React, { useState } from 'react'
import { useTable } from '@components/Table/hooks'
import { RowInterface, SORT_DIRECTION, Stats } from '@src/interfaces/data'
import { RoadmapInterface } from '@src/interfaces/roadmaps'
import { companyRoadmapsRequests } from '@src/api/roadmaps'
import { getPercentColor } from '@components/ColumnInserts/ColoredPercent/ColoredPercent'
import { FETCH_JIRA_ROADMAP } from '@src/constants/externalLinks'
import {
  roadmapCompanyOrgUnitColumn,
  roadmapDueDate,
  roadmapEpicUrlColumn,
  roadmapGenericNameColumn,
  roadmapProgressColumn,
  roadmapResolutionAtColumn,
  roadmapStartDate,
  roadmapStatusColumn,
} from '@src/constants/columns/roadmap'
import { ownerNameColumn } from '@src/constants/columns/employee'
import {
  CycleFilter,
  CycleFilterType,
} from '@components/Inputs/Filters/FilterSelect/CycleFilter/CycleFilter'
import { formatPercentage } from '@src/utils/format'
import { getLocalDateTime } from '@src/utils/roadmap'
import { Cell, Color, Flex, MoreBar, TableWidget, Text } from '@revolut/ui-kit'
import Stat from '@components/Stat/Stat'
import AdjustableTable from '@components/Table/AdjustableTable'
import MoreInfoButton from '@components/MoreInfoButton/MoreInfoButton'
import { TableNames } from '@src/constants/table'
import { useGetRoadmapSettings } from '@src/api/settings'
import { selectorKeys } from '@src/constants/api'
import ButtonFilter from '@components/ButtonFilters/ButtonFilter'
import SwitchButton from '@components/SwitchButton/SwitchButton'
import { useShowRoadmapDetails, useRoadmapContext } from '@src/features/Roadmaps'

interface FilterOption {
  name: string
  id: number | string
}

const ROW: RowInterface<RoadmapInterface> = {
  cells: [
    {
      ...roadmapGenericNameColumn,
    },
    {
      ...roadmapCompanyOrgUnitColumn,
      width: 170,
    },
    {
      ...roadmapProgressColumn,
      width: 170,
    },
    {
      ...roadmapStatusColumn,
      width: 100,
    },
    {
      ...roadmapStartDate,
      width: 130,
    },
    {
      ...roadmapDueDate,
      width: 130,
    },
    {
      ...roadmapResolutionAtColumn,
      width: 150,
    },
    {
      ...ownerNameColumn,
      width: 160,
    },
    {
      ...roadmapEpicUrlColumn,
      width: 120,
    },
  ],
}

export const Roadmap = () => {
  const { data: roadmapSettings } = useGetRoadmapSettings()
  const ctx = useRoadmapContext()
  const jiraEnabled = roadmapSettings?.jira_epics_enabled

  const [value, setValue] = useState<FilterOption[]>([])
  const [expandAllChildren, setExpandAllChildren] = useState(false)
  const initialFilter = [
    {
      filters: [{ name: `0`, id: 0 }],
      columnName: 'review_cycle__offset',
      nonResettable: true,
    },
    {
      filters: [{ name: `Department Roadmap`, id: `department_roadmap` }],
      columnName: 'type',
      nonResettable: true,
    },
  ]

  const initialSortBy = [
    {
      sortBy: 'department__name',
      direction: SORT_DIRECTION.DESC,
    },
  ]

  const table = useTable<RoadmapInterface, Stats>(
    companyRoadmapsRequests,
    initialFilter,
    initialSortBy,
  )

  const getFilters = (filterOptions: FilterOption[]) => {
    const additionalFilters = filterOptions.map(filter => ({
      id: filter.id,
      name: filter.name,
    }))

    return additionalFilters.length
      ? [
          {
            filters: additionalFilters,
            columnName: 'status',
            nonResettable: true,
          },
        ]
      : []
  }

  const refetchFilterChildren = (filterOptions: FilterOption[]) => {
    const filterBy = getFilters(filterOptions)
    table.data.forEach((parent, ind) => {
      table.fetchChildren([ind], parent.id, filterBy)
    })
  }

  const { renderSidebar, showRoadmapDetails } = useShowRoadmapDetails({
    changelogState: false,
    jiraEnabled: !!jiraEnabled,
  })

  return (
    <Cell mt="s-8" minHeight={0}>
      <Flex flexDirection="column" width="100%">
        <Flex mb="s-24">
          <Stat
            label="Progress"
            val={
              table?.stats?.avg_done !== undefined ? (
                <Text color={getPercentColor((table?.stats?.avg_done || 0) * 100)}>
                  {formatPercentage(table?.stats?.avg_done)}
                </Text>
              ) : (
                'N/A'
              )
            }
            mr="s-32"
          />
          <CycleFilter
            onFilterChange={table.onFilterChange}
            columnName="review_cycle__offset"
            filter={table.filterBy}
            type={CycleFilterType.NewUI}
          />
        </Flex>
        <Flex justifyContent="space-between" width="100%" mb="s-16">
          {roadmapSettings?.jira_epics_enabled && (
            <>
              <MoreBar>
                <MoreInfoButton href={FETCH_JIRA_ROADMAP} />
                <SwitchButton
                  checked={expandAllChildren}
                  onClick={() => {
                    setExpandAllChildren(!expandAllChildren)
                  }}
                >
                  Show all sub-tasks
                </SwitchButton>
              </MoreBar>
              <ButtonFilter
                title="Sub-task status"
                onChange={selection => {
                  setValue(selection)
                  refetchFilterChildren(selection)
                }}
                selector={selectorKeys.roadmap_child_status}
                value={value.map(el => ({ ...el, value: el }))}
                type="MultiSelect"
                useIcon="Filter"
              />
            </>
          )}
        </Flex>
        {!!table?.stats?.refresh_date_time && (
          <Text mb="s-12" mt="s-8">
            Progress last updated:{' '}
            <Text color={Color.GREY_TONE_50}>
              {getLocalDateTime(table?.stats?.refresh_date_time)}
            </Text>
          </Text>
        )}
        <TableWidget>
          <TableWidget.Table>
            <AdjustableTable<RoadmapInterface>
              name={TableNames.CompanyRoadmap}
              useWindowScroll
              row={ROW}
              onRowClick={showRoadmapDetails}
              {...table}
              dataType={ctx.dataType}
              pendingDataType={ctx.isLoading}
              noDataMessage={ctx.noDataMessage}
              expandableType="chevron"
              fetchChildren={(parentIndexes, id) =>
                table.fetchChildren(parentIndexes, id, getFilters(value))
              }
              childrenAlwaysOpen={expandAllChildren}
            />
          </TableWidget.Table>
        </TableWidget>
      </Flex>
      {renderSidebar({ canEdit: false })}
    </Cell>
  )
}

export default Roadmap
