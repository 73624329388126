import React from 'react'
import { useGetGoals } from '@src/api/performance'
import { VStack, Text, Token } from '@revolut/ui-kit'
import { ReviewCycleCategory } from '@src/interfaces/reviewCycles'
import { ProbationLayoutGoalsJiraDisabled } from '@src/pages/EmployeeProfile/Preview/Performance/PerformanceReview/ProbationLayoutGoalsJiraDisabled'
import { SectionLoader } from '@src/pages/EmployeeProfile/Layout/Performance/SectionLoader'

interface GoalsProps {
  category: ReviewCycleCategory
  employeeId: number
  reviewCycleId?: number | string
}

export const SummaryGoalsObsoleteReview = ({
  category,
  employeeId,
  reviewCycleId,
}: GoalsProps) => {
  const filters = [
    {
      filters: [{ name: `${employeeId}`, id: `${employeeId}` }],
      columnName: 'employee__id',
    },
    category !== ReviewCycleCategory.Probation
      ? {
          filters: [{ name: `${reviewCycleId}`, id: `${reviewCycleId}` }],
          columnName: 'review_cycle__id',
        }
      : {
          filters: [{ name: category, id: category }],
          columnName: 'category',
        },
  ]

  const { data: goals = [], isLoading } = useGetGoals(
    category && employeeId ? employeeId : null,
    filters,
  )

  if (isLoading) {
    return <SectionLoader />
  }

  if (!goals.length) {
    return null
  }

  return (
    <VStack space="s-8">
      <Text color={Token.color.greyTone50} fontWeight={500}>
        Goals
      </Text>
      <ProbationLayoutGoalsJiraDisabled goals={goals} />
    </VStack>
  )
}
