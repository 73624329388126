import { Flex, MoreBar, Text, Token, VStack } from '@revolut/ui-kit'
import { CardTypes, ReviewScorecardInterface } from '@src/interfaces/performance'
import { CultureValuesCard } from '@src/pages/Forms/EmployeePerformanceLayout/Cards/CultureValuesCard'
import { ManagerSkillsCard } from '@src/pages/Forms/EmployeePerformanceLayout/Cards/ManagerSkillsCard'
import { SkillsCard } from '@src/pages/Forms/EmployeePerformanceLayout/Cards/SkillsCard'
import {
  RecommendedGradesContext,
  getRecommendationAndType,
} from '@src/pages/Forms/EmployeePerformanceLayout/ScorecardContent'
import { AdditionalQuestions } from '@src/pages/Forms/EmployeePerformanceLayout/Sections/AdditionalQuestions'
import { BarRaiser } from '@src/pages/Forms/EmployeePerformanceLayout/Sections/BarRaiser'
import { Feedback } from '@src/pages/Forms/EmployeePerformanceLayout/Sections/Feedback'
import { getRecommendedGrade } from '@src/pages/Forms/EmployeePerformanceLayout/utils'
import React from 'react'
import { DeliverablesCard } from '@src/pages/Forms/EmployeePerformanceLayout/Cards/DeliverablesCard'
import { PerformanceHeader } from '@src/pages/Forms/EmployeePerformanceLayout/components/PerformanceHeader'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { useGetReviewGradesMap } from '@src/utils/grades'
import get from 'lodash/get'
import { BrokenHeart, Pencil } from '@revolut/icons'
import { navigateTo, useOpenNewTab } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { previewState } from '@src/pages/OnboardingChecklistV2/PerformanceReviews/ScorecardSetup/previewState'
import { InternalLink } from '@components/InternalLink/InternalLink'
import { PerformanceSettingsInterface } from '@src/interfaces/settings'
import { ScorecardCardItemContextProvider } from '@src/pages/Forms/EmployeePerformanceLayout/components/ScorecardCardItemContextProvider'

enum SkillTitleButtonType {
  SKILL = 'SKILL',
  VALUE = 'VALUE',
  MANAGER = 'MANAGER',
}
const MissingDataContent = ({
  title,
  actionMsg,
}: {
  title: string
  actionMsg: React.ReactNode
}) => {
  return (
    <Flex
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      minHeight="148px"
      color={Token.color.greyTone20}
    >
      <BrokenHeart size={24} />
      <Text variant="h6" mt="s-4">
        {title}
      </Text>
      {actionMsg}
    </Flex>
  )
}

export const ScorecardPreview = ({
  performanceSettings,
  isSegmentedDeliverables,
}: {
  performanceSettings?: PerformanceSettingsInterface
  isSegmentedDeliverables?: boolean
}) => {
  const { values } = useLapeContext<ReviewScorecardInterface>()
  const grades = getRecommendedGrade(!!isSegmentedDeliverables, values)
  const { gradesMap } = useGetReviewGradesMap()
  const recommendationSection = getRecommendationAndType(values)
  const { role } = previewState
  const navigateToNewTab = useOpenNewTab()

  const commonProps = {
    gradesMap,
  }

  const getSkillTitleButton = (id?: string, type?: SkillTitleButtonType) => {
    if (!id) {
      return null
    }

    switch (type) {
      case SkillTitleButtonType.VALUE:
        return (
          <Pencil
            size={20}
            color={Token.color.greyTone20}
            cursor="pointer"
            onClick={() => {
              navigateTo(
                pathToUrl(ROUTES.ONBOARDING_CHECKLIST_V2.REVIEWS.VALUES_FORM, {
                  id,
                }),
              )
            }}
          />
        )
      case SkillTitleButtonType.SKILL:
      case SkillTitleButtonType.MANAGER:
        return (
          <Pencil
            size={20}
            color={Token.color.greyTone20}
            cursor="pointer"
            onClick={() => {
              navigateTo(
                pathToUrl(
                  type === SkillTitleButtonType.SKILL
                    ? ROUTES.FORMS.SKILL.GENERAL
                    : ROUTES.FORMS.VALUE.GENERAL,
                  {
                    id,
                  },
                ),
              )
            }}
          />
        )
      default:
        return null
    }
  }

  const getCustomQuestionTitleButton = (scorecardId: number) => {
    return (
      <Pencil
        size={20}
        color={Token.color.greyTone20}
        cursor="pointer"
        onClick={() => {
          navigateTo(
            pathToUrl(ROUTES.ONBOARDING_CHECKLIST_V2.REVIEWS.CUSTOM_QUESTIONS_FORM, {
              id: scorecardId,
            }),
          )
        }}
      />
    )
  }

  return (
    <RecommendedGradesContext.Provider value={grades}>
      <ScorecardCardItemContextProvider>
        <VStack space="s-16" use="div" width="100%">
          <PerformanceHeader
            forceEmployeeId={values.reviewed_employee.id}
            variant="onboarding"
            role={role}
          />
          <DeliverablesCard
            {...commonProps}
            isSegmentedDeliverables={isSegmentedDeliverables}
            showGoals
          />
          <SkillsCard
            {...commonProps}
            skillsMissingJustification={[]}
            renderTitleButton={field => {
              const card = get(values, field)
              const isSkill = !!card?.skill_id
              return getSkillTitleButton(
                isSkill ? card?.skill_id : card?.value_id,
                isSkill ? SkillTitleButtonType.SKILL : SkillTitleButtonType.MANAGER,
              )
            }}
            renderActions={section => {
              const isBehaviour = section?.cards?.[0].type === CardTypes.companyValueCard
              return (
                <MoreBar.Action
                  use={InternalLink}
                  to={
                    isBehaviour
                      ? pathToUrl(ROUTES.PERFORMANCE.VALUES)
                      : pathToUrl(ROUTES.FORMS.ROLE.COMPETENCY_MATRIX, {
                          id: role?.role_id,
                        })
                  }
                >
                  Manage {isBehaviour ? 'behaviours' : 'skills'}
                </MoreBar.Action>
              )
            }}
            missingDataContent={
              <MissingDataContent
                title="No skills assigned to selected role"
                actionMsg={
                  <Text variant="h6">
                    You can manage skills{' '}
                    <Text
                      color={Token.color.blue}
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        navigateToNewTab(
                          pathToUrl(ROUTES.FORMS.ROLE.COMPETENCY_MATRIX, {
                            id: role?.role_id,
                          }),
                        )
                      }}
                    >
                      here
                    </Text>
                  </Text>
                }
              />
            }
          />
          <ManagerSkillsCard {...commonProps} />
          {performanceSettings?.enable_values && (
            <CultureValuesCard
              {...commonProps}
              renderTitleButton={field => {
                const card = get(values, field)
                return getSkillTitleButton(card?.value_id, SkillTitleButtonType.VALUE)
              }}
              actions={
                <MoreBar.Action
                  use={InternalLink}
                  to={pathToUrl(ROUTES.PERFORMANCE.VALUES)}
                >
                  Manage values
                </MoreBar.Action>
              }
              missingDataContent={
                <MissingDataContent
                  title="No values added"
                  actionMsg={
                    <Text
                      variant="h6"
                      color={Token.color.blue}
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        navigateTo(
                          pathToUrl(ROUTES.ONBOARDING_CHECKLIST_V2.REVIEWS.VALUES_FORM),
                        )
                      }}
                    >
                      Add company values
                    </Text>
                  }
                />
              }
            />
          )}
          {recommendationSection && (
            <BarRaiser
              isViewMode={false}
              questions={
                recommendationSection.recommendation.keeper_test_section.questions
              }
              type={recommendationSection.type}
              field={recommendationSection.field}
            />
          )}
          <AdditionalQuestions
            data={values.review_data}
            isViewMode={false}
            renderTitleButton={scorecardId => getCustomQuestionTitleButton(scorecardId)}
            actions={
              <MoreBar.Action
                use={InternalLink}
                to={pathToUrl(
                  ROUTES.ONBOARDING_CHECKLIST_V2.REVIEWS.CUSTOM_QUESTIONS_GENERAL_FORM,
                )}
              >
                Manage questions
              </MoreBar.Action>
            }
          />
          <Feedback
            recommendationData={recommendationSection?.recommendation}
            type={recommendationSection?.type}
            showMissingJustificationError={false}
            gradesMap={gradesMap}
          />
        </VStack>
      </ScorecardCardItemContextProvider>
    </RecommendedGradesContext.Provider>
  )
}
