import { Statuses } from '@src/interfaces'
import {
  NotCompletedReviewInterface,
  ReviewScorecardInterface,
} from '@src/interfaces/performance'
import React, { useState } from 'react'
import { connect } from 'lape'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { Button, StatusPopup, useStatusPopup } from '@revolut/ui-kit'
import { goBack, navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { useParams } from 'react-router-dom'
import { getCleanValuesBeforeSave } from '@src/utils/performance'
import { shouldOpenNextReview } from '@src/pages/Forms/EmployeePerformanceLayout/utils'
import { getReviewUrl } from '@src/pages/Forms/EmployeePerformanceLayout/SumbitButton'

interface Props {
  reviewsList?: NotCompletedReviewInterface[]
}

export const SaveDraftButton = connect(({ reviewsList }: Props) => {
  const { id, employeeId } = useParams<{ id: string; employeeId: string }>()
  const form = useLapeContext<ReviewScorecardInterface>()
  const { values, submit } = form
  const reviewUrl = getReviewUrl(id, employeeId, true, reviewsList)
  const [loading, setLoading] = useState(false)
  const statusPopup = useStatusPopup()

  if (
    values.status !== Statuses.draft &&
    values.status !== Statuses.pending &&
    values.status !== Statuses.rejected
  ) {
    return null
  }

  const showSuccess = (goNext: boolean) => {
    statusPopup.show(
      <StatusPopup
        variant="success"
        onClose={() => {
          statusPopup.hide()
          if (goNext) {
            navigateTo(reviewUrl)
          } else {
            goBack(
              pathToUrl(ROUTES.FORMS.EMPLOYEE.PERFORMANCE.PERFORMANCE, {
                id: employeeId,
              }),
            )
          }
        }}
      >
        <StatusPopup.Title>Feedback saved to drafts</StatusPopup.Title>
      </StatusPopup>,
    )
  }

  const showError = () =>
    statusPopup.show(
      <StatusPopup variant="error">
        <StatusPopup.Title>
          Could not save to drafts, please try again later
        </StatusPopup.Title>
        <StatusPopup.Actions>
          <Button onClick={statusPopup.hide}>Close</Button>
        </StatusPopup.Actions>
      </StatusPopup>,
    )

  const onSaveDraft = async () => {
    form.values = getCleanValuesBeforeSave(values)
    form.values.status = Statuses.draft
    const canOpenNextReview = shouldOpenNextReview(values.category)

    setLoading(true)
    try {
      await submit()
      showSuccess(canOpenNextReview)
    } catch {
      showError()
    } finally {
      setLoading(false)
    }
  }

  return (
    <Button
      onClick={onSaveDraft}
      variant="secondary"
      pending={loading && values.status === Statuses.draft}
      disabled={loading}
      width={167}
      elevated
      data-testid="btn-save-draft"
    >
      Save to Drafts
    </Button>
  )
})
