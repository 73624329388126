import React, { RefObject, useEffect, useState } from 'react'
import {
  Box,
  HStack,
  Text,
  Tag,
  TextButton,
  Group,
  Item,
  ActionButton,
  VStack,
  Avatar,
  IconButton,
  Token,
  Placeholder,
  Cell,
  Flex,
  Spinner,
  Popup,
  Header,
  Button,
  useStatusPopup,
  StatusPopup,
} from '@revolut/ui-kit'

import {
  WorkScheduleEligibilityConfigInterface,
  WorkScheduleEligibilityType,
} from '@src/interfaces/workSchedule'
import SectionTitle from '@src/pages/OnboardingChecklist/components/SectionTitle'
import { LapeRadioSwitch } from '@src/pages/OnboardingChecklist/components/RadioSwitch'
import { IdAndName } from '@src/interfaces'
import { ROUTES } from '@src/constants/routes'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { AudienceTable, TableFilter } from '@src/features/AudienceSelection/AudienceTable'
import RadioSelectInput, {
  RadioSelectOption,
} from '@src/components/Inputs/RadioSelectInput/RadioSelectInput'
import { useGetSelectors } from '@src/api/selectors'
import { selectorKeys } from '@src/constants/api'
import {
  getCustomApproversFlowEligibilityCustomFiltersWithoutHandling,
  getWorkScheduleEligibilityCustomFiltersWithoutHandling,
} from '@src/api/workSchedule'

type Props<T> = {
  values: T
  eligibilityGroupError: string | undefined
  setEligibilityGroupError: (newError: string | undefined) => void
  customFilters: TableFilter
  setCustomFilters: (newFilters: TableFilter) => void
  placeholderRef: RefObject<HTMLDivElement>
  entity: 'workSchedule' | 'customApprovers'
  isSchedule?: boolean
}
export const EligibilityConfig = <T extends WorkScheduleEligibilityConfigInterface>({
  values,
  eligibilityGroupError,
  setEligibilityGroupError,
  customFilters,
  setCustomFilters,
  isSchedule,
  entity,
  placeholderRef,
}: Props<T>) => {
  const [isLoadingCustomFilters, setIsLoadingCustomFilters] = useState(false)
  const [showSelectGroupPopup, setShowSelectGroupPopup] = useState(false)
  const [eligibilityGroupToAdd, setEligibilityGroupToAdd] = useState<IdAndName>()

  useEffect(() => {
    if (values.eligibility_group_type == null) {
      values.eligibility_group_type = {
        id: 'eligibility_group',
        name: 'Eligibility group',
      }
    }
  }, [])

  const { data: groupsOptionsData } = useGetSelectors(selectorKeys.dynamic_groups)
  const groupsOptions = groupsOptionsData
    ?.map(
      option =>
        ({
          label: option.name,
          value: option,
        } as RadioSelectOption<IdAndName>),
    )
    .filter(
      option =>
        !values.eligibility_groups?.some(addedGroup => addedGroup.id === option.value.id),
    )

  const loadCustomFilters = async (entityId: number, filterId: number) => {
    const getFilters =
      entity === 'workSchedule'
        ? () => getWorkScheduleEligibilityCustomFiltersWithoutHandling(entityId, filterId)
        : () => getCustomApproversFlowEligibilityCustomFiltersWithoutHandling(filterId)
    let filtersResponse
    try {
      filtersResponse = await getFilters()
    } catch (err) {
      if (err.response?.status === 404) {
        console.warn(
          'Could not find eligibility custom filters data for an existing group',
        )
      } else {
        throw err
      }
    }
    return filtersResponse
  }

  const statusPopup = useStatusPopup()

  useEffect(() => {
    if (entity === 'customApprovers' && !values.id) {
      return
    }
    if (
      values.eligibility_group_type?.id === 'employee_filters' &&
      values.custom_filters_eligibility_group?.id
    ) {
      setIsLoadingCustomFilters(true)
      loadCustomFilters(values.id, values.custom_filters_eligibility_group.id)
        .then(filtersResponse => {
          if (filtersResponse?.data?.table_filters) {
            setCustomFilters(filtersResponse.data.table_filters)
          }
        })
        .catch(() => {
          statusPopup.show(
            <StatusPopup variant="error">
              <StatusPopup.Title>
                Couldn't load custom filters for this eligibility group
              </StatusPopup.Title>
              <StatusPopup.Description>
                Please refresh the page or try again later
              </StatusPopup.Description>
            </StatusPopup>,
          )
        })
        .finally(() => setIsLoadingCustomFilters(false))
    }
  }, [
    entity,
    values.id,
    values.eligibility_group_type?.id,
    values.custom_filters_eligibility_group?.id,
  ])

  useEffect(() => {
    setEligibilityGroupError(undefined)
  }, [values.eligibility_groups])

  const placeholderImage = eligibilityGroupError ? '3D018' : '3D028'

  return (
    <>
      <Box>
        <SectionTitle
          title={
            isSchedule
              ? 'Who should we assign this schedule to?'
              : 'Who’s time off policy should the approvers be overridden for?'
          }
        />
        <LapeRadioSwitch<IdAndName<WorkScheduleEligibilityType>>
          name="eligibility_group_type"
          variant="horizontal"
          options={[
            {
              id: 'eligibility_group',
              label: (
                <HStack space="s-8" align="center">
                  <Text>Groups</Text>
                  <Tag variant="faded">Recommended</Tag>
                </HStack>
              ),
              description: (
                <>
                  Assign employees meeting the eligibility criteria automatically
                  {isSchedule ? ' to this schedule' : ''}. You can create groups{' '}
                  <TextButton to={ROUTES.APPS.GROUPS} use={InternalLink}>
                    here
                  </TextButton>
                  .
                </>
              ),
              value: {
                id: 'eligibility_group',
                name: 'Eligibility group',
              },
            },
            {
              id: 'employee_filters',
              label: 'Custom filters',
              description: isSchedule
                ? 'Assign eligibility for this schedule manually per employee.'
                : 'Assign eligibility manually by configuring employees filters',
              value: {
                id: 'employee_filters',
                name: 'Employee Filters',
              },
            },
          ]}
        />
        <Group mt="s-16">
          {values.eligibility_group_type?.id === 'eligibility_group' && (
            <>
              <Item>
                <Item.Content>
                  <Item.Title>Target groups</Item.Title>
                  <Item.Description>
                    {isSchedule
                      ? 'Whom should this schedule be applied to?'
                      : 'What are the target groups for this approval flow?'}
                  </Item.Description>
                </Item.Content>
                <Item.Side>
                  <ActionButton
                    useIcon="Plus"
                    onClick={() => setShowSelectGroupPopup(true)}
                  >
                    Add new
                  </ActionButton>
                </Item.Side>
              </Item>
              <VStack width="100%" p="s-8">
                {values.eligibility_groups?.length ? (
                  values.eligibility_groups.map(group => (
                    <Item key={group.id}>
                      <Item.Avatar>
                        <Avatar useIcon="People" />
                      </Item.Avatar>
                      <Item.Content>
                        <Item.Title>{group.name}</Item.Title>
                      </Item.Content>
                      <Item.Side>
                        <IconButton
                          useIcon="Delete"
                          color={Token.color.greyTone20}
                          onClick={() => {
                            values.eligibility_groups =
                              values.eligibility_groups?.filter(
                                addedGroup => addedGroup.id !== group.id,
                              ) || []
                          }}
                        />
                      </Item.Side>
                    </Item>
                  ))
                ) : (
                  <div ref={placeholderRef}>
                    <Placeholder>
                      <Placeholder.Image
                        image={{
                          default: `https://assets.revolut.com/assets/3d-images-v2/${placeholderImage}.png`,
                          '2x': `https://assets.revolut.com/assets/3d-images-v2/${placeholderImage}@2x.png`,
                          '3x': `https://assets.revolut.com/assets/3d-images-v2/${placeholderImage}@3x.png`,
                        }}
                      />
                      <Placeholder.Title
                        color={
                          eligibilityGroupError
                            ? Token.color.danger
                            : Token.color.greyTone50
                        }
                      >
                        {eligibilityGroupError || 'No groups selected'}
                      </Placeholder.Title>
                      <Placeholder.Action>
                        <TextButton onClick={() => setShowSelectGroupPopup(true)}>
                          Add new
                        </TextButton>
                      </Placeholder.Action>
                    </Placeholder>
                  </div>
                )}
              </VStack>
            </>
          )}
          {values.eligibility_group_type?.id === 'employee_filters' && (
            <Box p="s-16">
              {isLoadingCustomFilters ? (
                <Cell>
                  <Flex width="100%" justifyContent="center">
                    <Spinner color={Token.color.blue} />
                  </Flex>
                </Cell>
              ) : (
                <AudienceTable
                  filter={customFilters}
                  onFilterChange={newFilters => {
                    setCustomFilters(newFilters)
                  }}
                />
              )}
            </Box>
          )}
        </Group>
      </Box>
      <Popup
        open={showSelectGroupPopup}
        onClose={() => setShowSelectGroupPopup(false)}
        variant="bottom-sheet"
      >
        <Header variant="bottom-sheet">
          <Header.Title>Select eligibility group</Header.Title>
        </Header>
        <RadioSelectInput<IdAndName>
          value={eligibilityGroupToAdd}
          options={groupsOptions}
          label="Eligibility group"
          onChange={newGroup => {
            if (newGroup) {
              setEligibilityGroupToAdd(newGroup)
            }
          }}
        />
        <Popup.Actions horizontal>
          <Button variant="secondary" onClick={() => setShowSelectGroupPopup(false)}>
            Cancel
          </Button>
          <Button
            elevated
            onClick={() => {
              if (eligibilityGroupToAdd) {
                const addedGroups = values.eligibility_groups || []
                values.eligibility_groups = [...addedGroups, eligibilityGroupToAdd]
                setEligibilityGroupToAdd(undefined)
              }
              setShowSelectGroupPopup(false)
            }}
          >
            Confirm
          </Button>
        </Popup.Actions>
      </Popup>
    </>
  )
}
