import React, { useEffect, useState } from 'react'
import { Action, Box, Flex, MoreBar, Subheader, TabBar, Text } from '@revolut/ui-kit'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { SpecialisationInterface } from '@src/interfaces/roles'
import Loader from '@components/CommonSC/Loader'
import { JobPostingInterface } from '@src/interfaces/jobPosting'
import JobDescription from '@src/pages/Forms/JobPosting/Components/JobPosting'
import { jobPostingsRequests, useGetJobDescription } from '@src/api/jobPosting'
import SwitchButton from '@components/SwitchButton/SwitchButton'
import { Plus } from '@revolut/icons'
import { SORT_DIRECTION } from '@src/interfaces/data'
import { connect } from 'lape'
import { Statuses } from '@src/interfaces'
import PreviewJobPostingSidebar from '@src/pages/Forms/JobPosting/Components/PreviewJobPostingSidebar'
import { PageBody } from '@components/Page/PageBody'
import { JobPostingsTableWidget } from '@src/features/JobPostingsTable/JobPostingsTable'
import { PermissionTypes } from '@src/store/auth/types'
import RadioSelectInput from '@components/Inputs/RadioSelectInput/RadioSelectInput'
import { useNavigateToJobPostingPath } from '@src/features/JobPostingFlow/utils'

interface PostingsViewerProps {
  noTable?: boolean
  noButtons?: boolean
  noEditing?: boolean
  isSelector?: boolean
}

const PostingsViewer = ({
  noTable,
  noButtons,
  noEditing,
  isSelector,
}: PostingsViewerProps) => {
  const [loading, setLoading] = useState(true)
  const [showPreview, setShowPreview] = useState(false)
  const [jobPosting, setJobPosting] = useState<JobPostingInterface>()
  const { data: previewJobPosting } = useGetJobDescription(jobPosting?.id)
  const [jobPostings, setJobPostings] = useState<JobPostingInterface[]>()
  const { values } = useLapeContext<SpecialisationInterface>()
  const [openJobPostingId, setOpenJobPostingId] = useState<number | null>(null)

  const canAddPosting = values.field_options?.permissions?.includes(
    PermissionTypes.AddSpecialisationJobPosting,
  )

  const navigateToJobPosting = useNavigateToJobPostingPath()

  const initialFilterBy = [
    {
      columnName: 'specialisation__id',
      filters: [{ id: values.id, name: `${values.id}` }],
      nonResettable: true,
    },
    {
      columnName: 'approval_status',
      filters: [
        { id: Statuses.pending, name: Statuses.pending },
        { id: Statuses.approved, name: Statuses.approved },
      ],
    },
  ]

  const initialSortBy = [
    {
      sortBy: 'id',
      direction: SORT_DIRECTION.DESC,
    },
  ]

  const fetchJobDescriptions = async () => {
    try {
      setLoading(true)
      const selectorData = await jobPostingsRequests.getItems({
        filters: initialFilterBy,
        sortBy: initialSortBy,
      })

      if (selectorData?.data?.results) {
        setJobPostings(selectorData?.data?.results)
        const generatedPosting = selectorData?.data?.results.find(d => d.is_generated)
        if (!generatedPosting) {
          setJobPosting(selectorData?.data?.results?.[0])
        } else {
          setJobPosting(generatedPosting)
        }
      }
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchJobDescriptions()
  }, [])

  const renderActions = () => {
    if (isSelector) {
      return (
        <Box mb="s-16">
          <RadioSelectInput<JobPostingInterface>
            label="Job postings"
            value={jobPosting}
            clearable={false}
            onChange={s => setJobPosting(s as JobPostingInterface)}
            options={jobPostings?.map(jp => ({ label: jp.name, value: jp }))}
          />
        </Box>
      )
    }

    if (jobPostings?.length && jobPostings?.length > 1) {
      return (
        <Box mb="s-16">
          <TabBar
            display={jobPostings?.length && jobPostings?.length > 1 ? undefined : 'none'}
            behaviour="scroll"
          >
            {jobPostings?.map(posting => (
              <TabBar.Item
                aria-selected={posting.id === jobPosting?.id}
                onClick={() => setJobPosting(posting)}
                key={posting.id}
                // use="button" has some issues when the text is long, something with UI kit width calculations
                style={{ cursor: 'pointer' }}
                use="div"
              >
                <Text use="div" wrap="nowrap">
                  {posting.name}
                </Text>
              </TabBar.Item>
            ))}
          </TabBar>
        </Box>
      )
    }

    return null
  }

  const renderJobPosting = () => {
    if (loading) {
      return <Loader />
    }

    if (!jobPosting) {
      return (
        <Text use="div" my="s-16" color="grey-tone-50">
          No posting available
        </Text>
      )
    }

    return (
      <Box>
        {renderActions()}
        {!noEditing && (
          <Subheader variant="nested">
            <Subheader.Title>Job posting preview</Subheader.Title>
            <Subheader.Side>
              <Action
                onClick={() => {
                  navigateToJobPosting({
                    id: jobPosting?.id,
                    specialisationId: values.id,
                  })
                }}
              >
                Edit
              </Action>
            </Subheader.Side>
          </Subheader>
        )}
        {previewJobPosting ? (
          <JobDescription
            id={previewJobPosting.id}
            locations={previewJobPosting.locations}
          />
        ) : (
          <Loader />
        )}
      </Box>
    )
  }

  const renderButtons = () => {
    return (
      <>
        {canAddPosting && (
          <MoreBar.Action
            useIcon={Plus}
            onClick={() => {
              navigateToJobPosting({
                specialisationId: values.id,
              })
            }}
          >
            Add new
          </MoreBar.Action>
        )}

        {!noTable && (
          <SwitchButton
            checked={showPreview}
            onClick={() => setShowPreview(!showPreview)}
          >
            Show preview
          </SwitchButton>
        )}
      </>
    )
  }

  if (showPreview || noTable) {
    return (
      <PageBody pt={0} maxWidth={782}>
        {!noButtons && (
          <Box mb="s-16">
            <MoreBar>{renderButtons()}</MoreBar>
          </Box>
        )}
        {renderJobPosting()}
      </PageBody>
    )
  }

  return (
    <>
      <PreviewJobPostingSidebar
        onClose={() => setOpenJobPostingId(null)}
        postingId={openJobPostingId}
        isOpen={!!openJobPostingId}
      />
      <Flex flexDirection="column" width="100%">
        <Flex style={{ position: 'relative' }} flex="1 0">
          <JobPostingsTableWidget
            specialisationId={values.id}
            setOpenJobPostingId={setOpenJobPostingId}
            actions={renderButtons()}
          />
        </Flex>
      </Flex>
    </>
  )
}

export default connect(PostingsViewer)
