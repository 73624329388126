import React, { useState } from 'react'
import capitalize from 'lodash/capitalize'
import {
  Box,
  chain,
  ExpandableCell,
  Flex,
  Group,
  MoreBar,
  Text,
  TextButton,
  Token,
  VStack,
} from '@revolut/ui-kit'
import { ArrowThinRight, Check, Download, Time } from '@revolut/icons'

import Loader from '@components/CommonSC/Loader'
import { AttachedDocument } from '@src/features/DocumentSidebar/DocumentSidebar'
import { FormPreview } from '@components/FormPreview/FormPreview'
import {
  DocumentUploadRequestInterface,
  DocusignDocumentInterface,
  EmbeddedDocumentInterface,
  EmployeeDocumentListItemInterface,
} from '@src/interfaces/documents'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import SideBar from '@components/SideBar/SideBar'
import {
  downloadDocusignEmbedded,
  useGetDocusignInfo,
  useGetEmbeddedDocumentInfo,
  useGetUploadedDocument,
} from '@src/api/documents'
import { getDocumentStatusColor } from '@src/constants/columns/documents'
import { navigateTo } from '@src/actions/RouterActions'
import { InternalLink } from '@components/InternalLink/InternalLink'
import { formatDate } from '@src/utils/format'
import { PermissionTypes } from '@src/store/auth/types'
import { getStatusColor } from '@components/CommonSC/General'
import UserWithAvatar from '@components/UserWithAvatar/UserWithAvatar'

type Props = {
  employeeId: number
  onClose: () => void
  document?: EmployeeDocumentListItemInterface
}
export const PreviewSidebar = ({ employeeId, document, onClose }: Props) => {
  const [isDownloadPending, setIsDownloadPending] = useState(false)

  const { data: embeddedDocument, isLoading: isLoadingEmbeddedDocument } =
    useGetEmbeddedDocumentInfo(
      document?.source === 'embedded' ? document.recipient_id : undefined,
    )
  const { data: docusignDocument, isLoading: isLoadingDocusignDocument } =
    useGetDocusignInfo(
      document?.source === 'docusign' ? document.recipient_id : undefined,
    )
  const { data: uploadedDocument, isLoading: isLoadingUploadedDocument } =
    useGetUploadedDocument(
      employeeId,
      document?.source === 'upload' || document?.source === 'request'
        ? document.document_id
        : undefined,
    )

  const renderSidebarContent = () => {
    if (document?.source === 'embedded') {
      if (!embeddedDocument || isLoadingEmbeddedDocument) {
        return <Loader />
      }
      const canDownload =
        embeddedDocument.status === 'completed' &&
        embeddedDocument.field_options?.permissions?.includes?.(
          PermissionTypes.DownloadSentenveloperecipientsview,
        )
      const totalCount = embeddedDocument.signers.length
      const pendingCount = embeddedDocument.signers.reduce(
        (count, signer) => (signer.status === 'pending' ? count + 1 : count),
        0,
      )
      const signatureCellContent =
        pendingCount > 0 ? (
          <Text color={Token.color.orange}>
            {chain(`${pendingCount}/${totalCount}`, 'Pending signature')}
          </Text>
        ) : (
          <Text color={Token.color.green}>Completed</Text>
        )
      return (
        <VStack space="s-16">
          <MoreBar>
            {canDownload && (
              <MoreBar.Action
                onClick={async () => {
                  try {
                    setIsDownloadPending(true)
                    await downloadDocusignEmbedded(embeddedDocument?.envelope_uuid)
                  } finally {
                    setIsDownloadPending(false)
                  }
                }}
                useIcon={Download}
                pending={isDownloadPending}
              >
                Download
              </MoreBar.Action>
            )}
            <MoreBar.Action
              use={InternalLink}
              to={pathToUrl(ROUTES.FORMS.DOCUMENT_EMBEDDED.GENERAL, {
                id: document.recipient_id,
                employeeId,
              })}
              useIcon={ArrowThinRight}
            >
              Go to preview page
            </MoreBar.Action>
          </MoreBar>
          <FormPreview data={embeddedDocument}>
            <Group>
              <FormPreview.Item<EmbeddedDocumentInterface>
                title="Status"
                field="status"
                insert={d =>
                  d.status ? (
                    <Text color={getStatusColor(d.status)}>{capitalize(d.status)}</Text>
                  ) : (
                    '-'
                  )
                }
              />
              <FormPreview.Item<EmbeddedDocumentInterface>
                title="Issued by"
                field="sent_by.name"
                to={d =>
                  pathToUrl(ROUTES.FORMS.EMPLOYEE.PROFILE, {
                    id: d.sent_by.id,
                  })
                }
              />
              <FormPreview.Item
                title="Issued on"
                field="creation_date_time"
                type="date"
              />
              <FormPreview.Item title="Document category" field="category.name" />
              <FormPreview.Item title="Signed on" field="signed_on" type="date" />
              <ExpandableCell>
                <ExpandableCell.Title>Signature status</ExpandableCell.Title>
                <ExpandableCell.Content>{signatureCellContent}</ExpandableCell.Content>
                <ExpandableCell.Note>
                  <Flex flexDirection="column" gap="s-16">
                    {embeddedDocument.signers.map(signer => (
                      <Box key={signer.routing_order}>
                        <Flex alignItems="center" mb="s-8">
                          <Box width="s-24">
                            {signer.status === 'completed' ? (
                              <Check color={Token.color.green} size={16} />
                            ) : (
                              <Time color={Token.color.orange} size={16} />
                            )}
                          </Box>
                          <Text variant="h6">
                            {chain(
                              `Signatory ${signer.routing_order}`,
                              capitalize(signer.status),
                            )}
                          </Text>
                        </Flex>
                        <Flex style={{ whiteSpace: 'pre' }}>
                          <UserWithAvatar {...signer.employee} ml="s-24" />
                          {signer.is_next_in_signing ? ` (current signer)` : ''}
                        </Flex>
                      </Box>
                    ))}
                  </Flex>
                </ExpandableCell.Note>
              </ExpandableCell>
            </Group>
          </FormPreview>
        </VStack>
      )
    }
    if (document?.source === 'docusign') {
      if (!docusignDocument || isLoadingDocusignDocument) {
        return <Loader />
      }
      const canDownload =
        docusignDocument?.status === 'completed' &&
        docusignDocument?.field_options?.permissions?.includes?.(
          PermissionTypes.DownloadDocusignenveloperecipients,
        )

      return (
        <VStack space="s-16">
          <MoreBar>
            {canDownload && (
              <MoreBar.Action
                onClick={async () => {
                  try {
                    setIsDownloadPending(true)
                    await downloadDocusignEmbedded(docusignDocument?.envelope?.id)
                  } finally {
                    setIsDownloadPending(false)
                  }
                }}
                useIcon={Download}
                pending={isDownloadPending}
              >
                Download
              </MoreBar.Action>
            )}
            <MoreBar.Action
              use={InternalLink}
              to={pathToUrl(ROUTES.FORMS.DOCUMENT_DOCUSIGN.GENERAL, {
                id: document.recipient_id,
                employeeId,
              })}
              useIcon={ArrowThinRight}
            >
              Go to preview page
            </MoreBar.Action>
          </MoreBar>
          <FormPreview data={docusignDocument}>
            <Group>
              <FormPreview.Item<DocusignDocumentInterface>
                title="Status"
                field="status"
                insert={d =>
                  d.status ? (
                    <Text color={getStatusColor(d.status)}>{capitalize(d.status)}</Text>
                  ) : (
                    '-'
                  )
                }
              />
              <FormPreview.Item<DocusignDocumentInterface>
                title="Issued by"
                field="sender.name"
                to={d =>
                  pathToUrl(ROUTES.FORMS.EMPLOYEE.PROFILE, {
                    id: d.sender.id,
                  })
                }
              />
              <FormPreview.Item title="Sent date" field="sent_date_time" type="date" />
              <FormPreview.Item<DocusignDocumentInterface>
                title="Signed on"
                field="signed_date_time"
                insert={d => {
                  if (d.signed_date_time) {
                    return formatDate(d.signed_date_time)
                  }
                  return 'Not signed yet'
                }}
              />
            </Group>
          </FormPreview>
        </VStack>
      )
    }
    if (document?.source === 'upload' || document?.source === 'request') {
      return !uploadedDocument || isLoadingUploadedDocument ? (
        <Loader />
      ) : (
        <AttachedDocument
          id={Number(document.document_id)}
          document={uploadedDocument}
          renderActions={downloadButton => (
            <MoreBar>
              {downloadButton}
              <MoreBar.Action
                use={InternalLink}
                to={pathToUrl(ROUTES.FORMS.DOCUMENT_UPLOAD.GENERAL, {
                  id: document?.recipient_id,
                  employeeId,
                })}
                useIcon={ArrowThinRight}
              >
                Go to preview page
              </MoreBar.Action>
            </MoreBar>
          )}
          renderDetails={() => (
            <FormPreview data={uploadedDocument}>
              <Group>
                <FormPreview.Item<DocumentUploadRequestInterface>
                  title="Category"
                  field="category.name"
                  insert={d => {
                    return (
                      <TextButton
                        onClick={() => {
                          onClose()
                          navigateTo(
                            pathToUrl(ROUTES.FORMS.EMPLOYEE.DOCUMENTS_BY_CATEGORY, {
                              employeeId,
                              categoryId: d.category?.id,
                            }),
                          )
                        }}
                      >
                        {d.category?.name}
                      </TextButton>
                    )
                  }}
                />
                <FormPreview.Item
                  title="Status"
                  field="status.name"
                  color={() => getDocumentStatusColor(uploadedDocument.status.id)}
                />
                <FormPreview.Item<DocumentUploadRequestInterface>
                  title="Issued by"
                  field="issuer.name"
                  to={d =>
                    pathToUrl(ROUTES.FORMS.EMPLOYEE.PROFILE, {
                      id: d.issuer.id,
                    })
                  }
                />
                <FormPreview.Item
                  title="Created on"
                  field="creation_date_time"
                  type="date"
                />
                <FormPreview.Item
                  title="Updated on"
                  field="updated_date_time"
                  type="date"
                />
              </Group>
            </FormPreview>
          )}
        />
      )
    }
    return null
  }

  return (
    <SideBar
      title={document?.document_name}
      isOpen={!!document}
      onClose={onClose}
      sideProps={{ resizable: true }}
    >
      {renderSidebarContent()}
    </SideBar>
  )
}
