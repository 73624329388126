import { createMainSectionsConfig } from '@src/constants/mainSections'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { selectFeatureFlags } from '@src/store/auth/selectors'
import { FeatureFlags } from '@src/store/auth/types'

export const useMainSectionsConfig = () => {
  const featureFlags = useSelector(selectFeatureFlags)
  const isCommercial = featureFlags?.includes(FeatureFlags.CommercialProduct)

  return useMemo(() => createMainSectionsConfig(isCommercial), [isCommercial])
}
