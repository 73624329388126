import React from 'react'
import { EmployeeGoal } from '@src/interfaces/employees'
import { formatDate } from '@src/utils/format'
import { CellTypes, RowInterface } from '@src/interfaces/data'
import AdjustableTable from '@components/Table/AdjustableTable'
import { TableNames } from '@src/constants/table'
import { PerformanceLayoutTableContainer } from '@src/pages/EmployeeProfile/Layout/Performance/utils'
import { selectorKeys } from '@src/constants/api'

interface GoalRowData {
  name: string
  date?: Date | null
}

const row: RowInterface<GoalRowData> = {
  cells: [
    {
      type: CellTypes.text,
      idPoint: 'name',
      dataPoint: 'name',
      sortKey: null,
      filterKey: null,
      selectorsKey: selectorKeys.none,
      title: 'Goal name',
      width: 300,
    },
    {
      type: CellTypes.insert,
      idPoint: 'date',
      dataPoint: 'date',
      sortKey: null,
      filterKey: null,
      selectorsKey: selectorKeys.none,
      title: 'Due',
      insert: ({ data }) => {
        return data.date ? formatDate(data.date) : ''
      },
      width: 170,
    },
  ],
}

type Props = {
  goals?: EmployeeGoal[]
}

export const ProbationLayoutGoalsJiraDisabled = ({ goals }: Props) => {
  const data = goals?.map(el => ({ name: el.name, date: el.deadline_date_time })) || []

  return (
    <PerformanceLayoutTableContainer>
      <AdjustableTable<GoalRowData>
        name={TableNames.ProbationGoals}
        row={row}
        count={data.length}
        data={data}
        noDataMessage="All your goals fot this cycle will appear here"
        hideCountAndButtonSection
      />
    </PerformanceLayoutTableContainer>
  )
}
