import React, { useState } from 'react'

import { GoalsTab } from '@src/features/Goals/GoalsTab'
import OnboardingActions from '../components/OnboardingActions'
import { goalsConfig } from '../common/checkpointsConfig'
import { setupCompanyGoalsTitle } from '.'

export const GoalsSetup = () => {
  const [manageModeEnabled, setManageModeEnabled] = useState(false)

  return (
    <>
      <GoalsTab
        onManageModeChange={enabled => {
          setManageModeEnabled(enabled)
        }}
      />
      {manageModeEnabled ? null : (
        <OnboardingActions
          currentStep={setupCompanyGoalsTitle}
          config={goalsConfig}
          isLastStep
          disableNext={false}
          pendingNext={false}
          nextRoute=""
          updateSteps
          isForm={false}
        />
      )}
    </>
  )
}
