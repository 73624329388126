import { EmployeeInterface } from '@src/interfaces/employees'
import { PerformanceSelector, ReviewCategory } from '@src/interfaces/performance'
import React, { Dispatch, SetStateAction } from 'react'
import SummaryWidget from '@src/pages/EmployeeProfile/Preview/Performance/Summary/SummaryWidget'
import { AnytimeFeedbackListInterface } from '@src/interfaces/anytimeFeedback'
import { useGetPeriodTypes } from '@src/utils/performance'

interface Props {
  data: EmployeeInterface
  selectedPeriod: PerformanceSelector
  setPerformanceLink: Dispatch<SetStateAction<string | undefined>>
  feedback?: AnytimeFeedbackListInterface
  refetchFeedbackList: () => void
}

export const ProbationSummarySection = ({
  data,
  selectedPeriod,
  setPerformanceLink,
  feedback,
  refetchFeedbackList,
}: Props) => {
  const cycleId = selectedPeriod?.id

  const { isNewProbation, isPIPv2 } = useGetPeriodTypes(selectedPeriod)

  if (!isPIPv2 && !isNewProbation) {
    return null
  }

  return (
    <SummaryWidget
      cycleId={cycleId !== undefined ? String(cycleId) : undefined}
      cycleName={selectedPeriod?.name}
      category={isNewProbation ? ReviewCategory.Probation : ReviewCategory.PIP_V2}
      employee={data}
      selectedPeriod={selectedPeriod}
      setPerformanceLink={setPerformanceLink}
      showGoals
      feedback={feedback}
      refetchFeedbackList={refetchFeedbackList}
    />
  )
}
