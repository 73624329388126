import React from 'react'
import { useTable } from '@components/Table/hooks'
import { WhitelistRecordInterface } from '@src/interfaces/supportTool'
import { RowInterface } from '@src/interfaces/data'
import {
  whitelistActionColumn,
  whitelistCreatedByColumn,
  whitelistCycleColumn,
  whitelistEndDateColumn,
  whitelistReasonColumn,
  whitelistReviewerColumn,
  whitelistStartDateColumn,
  whitelistStatusColumn,
} from '@src/constants/columns/whitelist'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { Statuses } from '@src/interfaces'
import { getWhitelist, revokeWhitelist } from '@src/api/whitelist'
import { Action, Box, Flex, MoreBar, TextWidget, Widget } from '@revolut/ui-kit'
import { Plus } from '@revolut/icons'
import AdjustableTable from '@components/Table/AdjustableTable'
import { TableNames } from '@src/constants/table'

const ROW = (
  handleRevokeClick: (id: number) => void,
): RowInterface<WhitelistRecordInterface> => ({
  cells: [
    {
      ...whitelistReviewerColumn,
      width: 300,
    },
    {
      ...whitelistCycleColumn,
      width: 200,
    },
    {
      ...whitelistStartDateColumn,
      width: 200,
    },
    {
      ...whitelistEndDateColumn,
      width: 200,
    },
    {
      ...whitelistCreatedByColumn,
      width: 200,
    },
    {
      ...whitelistReasonColumn,
      width: 200,
    },
    {
      ...whitelistStatusColumn,
      width: 200,
    },
    {
      ...whitelistActionColumn,
      insert: ({ data }) => {
        if (data.status !== Statuses.active) {
          return ''
        }
        return (
          <Action
            onClick={e => {
              e.stopPropagation()
              handleRevokeClick(data.id)
            }}
          >
            Revoke
          </Action>
        )
      },
      width: 200,
    },
  ],
})

const Whitelist = () => {
  const table = useTable<WhitelistRecordInterface>({ getItems: getWhitelist() }, [], [])

  const handleNewRowClick = () => {
    navigateTo(pathToUrl(ROUTES.FORMS.WHITELIST.GENERAL))
  }

  const handleRowClick = (record: WhitelistRecordInterface) => {
    navigateTo(
      pathToUrl(ROUTES.FORMS.WHITELIST.GENERAL, {
        id: record.id,
      }),
    )
  }

  const handleRevokeClick = (id: number) => {
    revokeWhitelist(id).then(() => {
      table.refresh()
    })
  }

  return (
    <>
      <Box maxWidth={500} mb="s-16">
        <TextWidget>
          <TextWidget.Content>
            This tool is designed for performance team to accommodate reviewers who need
            to access performance review before or after the review cycle.
          </TextWidget.Content>
        </TextWidget>
      </Box>
      <Widget p="s-16">
        <Box mb="s-16">
          <MoreBar>
            <MoreBar.Action useIcon={Plus} onClick={handleNewRowClick}>
              Add new
            </MoreBar.Action>
          </MoreBar>
        </Box>
        <Flex style={{ position: 'relative' }} flex="1 0">
          <AdjustableTable<WhitelistRecordInterface>
            name={TableNames.Whitelist}
            useWindowScroll
            row={ROW(handleRevokeClick)}
            {...table}
            noDataMessage="There are no whitelist records"
            onRowClick={handleRowClick}
          />
        </Flex>
      </Widget>
    </>
  )
}

export default Whitelist
