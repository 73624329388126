import type { Token } from '@revolut/ui-kit'
import { opacity } from '@revolut/ui-kit'
import { CHART_COLORS } from '@src/pages/Forms/QueryForm/components/Charts/constants'
import { ValueOf } from '@src/pages/Forms/QueryForm/components/Charts/types'

const LABEL_WIDTH_MULTIPLIER = 6.5
const LABEL_PADDING = 20

/* 
  Manually calculate numeric ticks for chart labels based on values and number of ticks.
  Automatic calculation provided by recharts is not working well for very small values.
*/
export const getTicks = (values: number[], tickCount: number): number[] => {
  if (tickCount <= 0 || values.length < 1) {
    return []
  }

  const minValue = Math.min(...values)
  const maxValue = Math.max(...values)

  if (tickCount === 1) {
    return [maxValue]
  }

  const delta = (maxValue - minValue) / (tickCount - 1)
  const ticks: number[] = []

  for (let i = 0; i < tickCount; i++) {
    // Push ticks from minValue plus delta in every iteration
    ticks.push(minValue + delta * i)
  }

  return ticks
}

// Dynamically calculate width based on the size of the longest label
export const getAxisWidth = (ticks: number[], formatter?: (value: number) => string) => {
  const maxSize = ticks.reduce((acc, curr) => {
    const length = formatter ? formatter(curr).length : curr.toString().length
    return length > acc ? length : acc
  }, 0)

  return maxSize * LABEL_WIDTH_MULTIPLIER + LABEL_PADDING
}

export const getPercentageLabel = (percentage: number) =>
  `${percentage > 0 && percentage < 1 ? '<1' : percentage.toFixed(0)}%`

export const getMainShapeColor = (color: ValueOf<typeof Token.colorChannel>) =>
  opacity(color, 0.8)
export const getSecondaryShapeColor = (color: ValueOf<typeof Token.colorChannel>) =>
  opacity(color, 0.7)
export const getActiveShapeColor = (color: ValueOf<typeof Token.colorChannel>) =>
  opacity(color, 1)

export const getColorByIndex = (id: number) => {
  return CHART_COLORS[id % CHART_COLORS.length]
}
