import React from 'react'
import { ROUTES } from '@src/constants/routes'
import { EngagementResults } from '@src/apps/People/Engagement/Results'
import { EngagementResultsScope } from '@src/interfaces/engagement'
import { CompanyInterface } from '@src/interfaces/company'

const DEFAULT_COMPANY_ID = 1

export const Engagement = ({ data }: { data: CompanyInterface }) => {
  return (
    <EngagementResults
      scope={EngagementResultsScope.Company}
      driversPath={ROUTES.FORMS.COMPANY.ENGAGEMENT.DRIVERS}
      questionsPath={ROUTES.FORMS.COMPANY.ENGAGEMENT.QUESTIONS}
      feedbackPath={ROUTES.FORMS.COMPANY.ENGAGEMENT.FEEDBACK}
      // as we have only one company, id will be hardcoded for now
      data={{
        ...data,
        id: DEFAULT_COMPANY_ID,
      }}
    />
  )
}
