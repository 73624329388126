import React, { useState } from 'react'
import {
  Cell,
  VStack,
  Item,
  Flex,
  Text,
  Button,
  Image,
  HStack,
  ActionButton,
  Avatar,
  chain,
  IconButton,
  Token,
} from '@revolut/ui-kit'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { EngagementSurveyInterface } from '@src/interfaces/engagement'
import { FormattedMessage } from 'react-intl'
import { AddAudienceSidebar } from '@src/apps/People/Engagement/Survey/components/AddAudienceSidebar'
import pluralize from 'pluralize'
import { MULTIPLE_ITEMS_SELECTOR_CELL_MIN_HEIGHT } from './consts'

export const Audience = () => {
  const { values } = useLapeContext<EngagementSurveyInterface>()
  const [isSidebarOpen, setSidebarOpen] = useState(false)

  return (
    <>
      <Cell>
        <VStack width="100%">
          <Item p="0">
            <Item.Content>
              <Item.Title>
                <FormattedMessage
                  id="engagement.form.audience.title"
                  defaultMessage="Audience"
                />
              </Item.Title>
              <Item.Description>
                <FormattedMessage
                  id="engagement.form.audience.description"
                  defaultMessage="Consider which employees should receive this survey"
                />
              </Item.Description>
            </Item.Content>
          </Item>
          <Flex
            minHeight={MULTIPLE_ITEMS_SELECTOR_CELL_MIN_HEIGHT}
            flexDirection="column"
            pt="s-8"
            justifyContent={values.audiences.length ? 'flex-start' : 'center'}
          >
            {values.audiences.length ? (
              <VStack gap="s-16">
                <HStack gap="s-16">
                  <ActionButton useIcon="Plus" onClick={() => setSidebarOpen(true)}>
                    <FormattedMessage
                      id="engagement.form.audience.addButton"
                      defaultMessage="Select audience"
                    />
                  </ActionButton>
                </HStack>

                {values.audiences?.map(audience => (
                  <Item
                    key={audience.id}
                    p="s-8"
                    style={{ border: `1px solid ${Token.color.greyTone10}` }}
                  >
                    <Item.Avatar>
                      <Avatar useIcon="People" />
                    </Item.Avatar>
                    <Item.Content>
                      <Item.Title>{audience.name}</Item.Title>
                      <Item.Description>
                        {chain(
                          audience.audience_type.name,
                          `${pluralize('member', audience.headcount, true)}`,
                        )}
                      </Item.Description>
                    </Item.Content>
                    <Item.Side>
                      <IconButton
                        useIcon="Delete"
                        variant="default"
                        color={Token.color.greyTone10}
                        onClick={() => {
                          values.audiences = values.audiences.filter(
                            currentAudience => currentAudience.id !== audience.id,
                          )
                        }}
                      />
                    </Item.Side>
                  </Item>
                ))}
              </VStack>
            ) : (
              <Flex
                flexDirection="column"
                alignItems="center"
                width="100%"
                height="100%"
                gap="s-16"
              >
                <Image
                  src="https://assets.revolut.com/assets/3d-images-v2/3D395.png"
                  size={104}
                />
                <Flex flexDirection="column" alignItems="center">
                  <Text variant="h5">
                    <FormattedMessage
                      id="engagement.form.audience.empty.title"
                      defaultMessage="You don’t have people who will see this survey"
                    />
                  </Text>
                  <Text>
                    <FormattedMessage
                      id="engagement.form.audience.empty.description"
                      defaultMessage="Select teams, departments or particular roles to share them this survey"
                    />
                  </Text>
                </Flex>
                <Button
                  useIcon="Plus"
                  variant="text"
                  onClick={() => setSidebarOpen(true)}
                >
                  <FormattedMessage
                    id="engagement.form.audience.addButton"
                    defaultMessage="Select audience"
                  />
                </Button>
              </Flex>
            )}
          </Flex>
        </VStack>
      </Cell>
      <AddAudienceSidebar
        open={isSidebarOpen}
        onClose={() => setSidebarOpen(false)}
        audiences={values.audiences}
        onAddAudiences={addedAudiences => {
          values.audiences = [...(values.audiences || []), ...addedAudiences]
        }}
      />
    </>
  )
}
