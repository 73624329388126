import React from 'react'
import { ActionMenu, IconButton, useDropdown } from '@revolut/ui-kit'
import { Warning as WarningIcon } from '@revolut/icons'
import { MainHeaderButton } from './MainHeaderButton'
import { navigateTo } from '@src/actions/RouterActions'
import { ROUTES } from '@src/constants/routes'
import { useGetBanners } from '@src/api/banners'
import { severityToColor } from '@src/features/Banners/SystemStatusBanner'
import isEmpty from 'lodash/isEmpty'

export const Warning = () => {
  const { data, isLoading } = useGetBanners()
  const dropdown = useDropdown()

  if (isLoading || !data || isEmpty(data)) {
    return null
  }

  return (
    <>
      <MainHeaderButton>
        <IconButton
          useIcon={WarningIcon}
          aria-label="Warning"
          size={20}
          color={severityToColor[data.severity]}
          {...dropdown.getAnchorProps()}
        />
      </MainHeaderButton>
      <ActionMenu {...dropdown.getTargetProps()}>
        <ActionMenu.Group>
          <ActionMenu.Item
            useIcon={WarningIcon}
            color={severityToColor[data.severity]}
            onClick={() => navigateTo(ROUTES.MAIN)}
          >
            <ActionMenu.Title>{data.title}</ActionMenu.Title>
            <ActionMenu.Description>{data.message}</ActionMenu.Description>
          </ActionMenu.Item>
        </ActionMenu.Group>
      </ActionMenu>
    </>
  )
}
