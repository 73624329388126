import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { ActionButton, Box, Flex, IconName, Text, Token, Widget } from '@revolut/ui-kit'
import { EmployeeInterface } from '@src/interfaces/employees'
import {
  CheckpointType,
  ProbationCheckpoints,
  ProbationRecommendationInterface,
} from '@src/interfaces/probationReview'
import { getProbationRecommendations } from '@src/api/probationReview'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import {
  PerformanceSidebarManager,
  ReviewCategory,
  ReviewStatuses,
} from '@src/interfaces/performance'
import SummarySidebar from '@src/pages/EmployeeProfile/Preview/Performance/Summary/SummarySidebar'
import RecommendationReviewers from '@src/pages/Forms/ProbationOverview/Recommendations/RecommendationReviewers'
import Tooltip from '@components/Tooltip/Tooltip'
import { formatDate } from '@src/utils/format'
import { PageBody } from '@src/components/Page/PageBody'
import Loader from '@components/CommonSC/Loader'
import { getStartOfCheckpoint } from '@src/pages/EmployeeProfile/Preview/PerformanceSummary/ProbationPipTimeline/utils'

const Actions = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 8px;
  margin: 16px 0;
  justify-content: flex-start;
`

enum ButtonStates {
  Add = 'Add',
  Edit = 'Edit',
}

const ButtonTitles = {
  [ButtonStates.Add]: 'Add Recommendation',
  [ButtonStates.Edit]: 'Edit Recommendation',
}

const ButtonIcon: Record<ButtonStates, IconName> = {
  [ButtonStates.Add]: 'Plus',
  [ButtonStates.Edit]: 'Pencil',
}

type Props = {
  data: EmployeeInterface
  checkpoints?: ProbationCheckpoints
  cycleId: string
}

const Recommendations = ({ data, checkpoints, cycleId }: Props) => {
  const [recommendations, setRecommendations] =
    useState<ProbationRecommendationInterface[]>()
  const [loading, setLoading] = useState(false)
  const [sidebarOpen, setSidebarOpen] = useState<PerformanceSidebarManager>(
    PerformanceSidebarManager.SummaryOpened,
  )

  const fetchRecommendations = async () => {
    setLoading(true)
    const resp = await getProbationRecommendations(data.id, cycleId)
    setRecommendations(resp.data.results)
    setLoading(false)
  }

  const checkpoint = useMemo(
    () =>
      checkpoints?.checkpoints.find(
        item => item.checkpoint_type === CheckpointType.Recommendation,
      ),
    [checkpoints],
  )

  useEffect(() => {
    fetchRecommendations()
  }, [])

  const onClickViewReview = () => {
    if (sidebarOpen === PerformanceSidebarManager.SummaryOpened) {
      setSidebarOpen(PerformanceSidebarManager.Closed)
    } else {
      setSidebarOpen(PerformanceSidebarManager.SummaryOpened)
    }
  }

  const onClickAddReview = () => {
    navigateTo(
      pathToUrl(ROUTES.FORMS.PROBATION_FINAL_RESULT.GENERAL, {
        employeeId: data.id,
        cycleId,
        id: checkpoint?.recommendation?.id,
      }),
    )
  }

  if (loading) {
    return (
      <Flex height="100%" alignItems="center" justifyContent="center">
        <Loader />
      </Flex>
    )
  }

  const state =
    !checkpoint?.recommendation ||
    checkpoint.recommendation.status === ReviewStatuses.pending
      ? ButtonStates.Add
      : ButtonStates.Edit

  const startOfCheckpoint = checkpoint && getStartOfCheckpoint(checkpoint)

  return (
    <>
      <PageBody maxWidth={676}>
        <Widget p="s-16">
          {startOfCheckpoint && (
            <Text color={Token.color.greyTone50}>
              Recommendation checkpoint: {formatDate(startOfCheckpoint)}
            </Text>
          )}
          <Actions>
            <Tooltip
              placement="top"
              text="You are not allowed to submit review at this time"
              hide={checkpoint?.can_be_submitted}
            >
              <ActionButton
                useIcon={ButtonIcon[state]}
                onClick={onClickAddReview}
                disabled={!checkpoint?.can_be_submitted}
                variant={state === ButtonStates.Add ? 'accent' : undefined}
              >
                {ButtonTitles[state]}
              </ActionButton>
            </Tooltip>

            <ActionButton onClick={onClickViewReview}>View Reviews</ActionButton>
          </Actions>
          <Box mt="s-32">
            <Text color={Token.color.greyTone50} fontWeight={500}>
              Recommendations
            </Text>
            <Box variant="plain" mt="s-16">
              {recommendations?.length ? (
                <RecommendationReviewers recommendations={recommendations} />
              ) : (
                <Text use="div" py="s-16">
                  No recommendations
                </Text>
              )}
            </Box>
          </Box>{' '}
        </Widget>
      </PageBody>
      <SummarySidebar
        isOpen={sidebarOpen === PerformanceSidebarManager.SummaryOpened}
        cycleId={cycleId}
        employeeId={data.id}
        employeeSeniorityId={data.seniority?.id}
        cycleName={''}
        category={ReviewCategory.Probation}
        onClose={() => setSidebarOpen(PerformanceSidebarManager.Closed)}
      />
    </>
  )
}

export default Recommendations
