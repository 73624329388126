import React, { useState } from 'react'
import { GoalsPreviewHeader } from './Header'
import { GoalsPreviewLeftSide } from './LeftSide'
import { PageWrapper } from '@src/components/Page/Page'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { GoalPreviewInterface } from '@src/interfaces/goals'
import { captureException } from '@sentry/react'
import { Token } from '@revolut/ui-kit'
import { PageBody } from '@src/components/Page/PageBody'
import { UpdateTypes } from '@src/interfaces/kpis'
import { useQuery } from '@src/utils/queryParamsHooks'
import get from 'lodash/get'
import set from 'lodash/set'
import { IdAndName } from '@src/interfaces'
import { isOnboardingPath } from '@src/pages/OnboardingChecklistV2/common/helpers'

export type GoalCycleSelectType = IdAndName<string | number | undefined> & {
  offset?: number
}

const isReviewCycle = (cycle: GoalCycleSelectType): cycle is ReviewCyclesInterface => {
  return !!cycle?.id
}

const getGoalTargetsCycles = (goal: GoalPreviewInterface): GoalCycleSelectType[] => {
  const uniqueCycleIds: Record<number, never> = {}

  const goalCycles = (
    goal.update_type?.id === 'aggregated'
      ? goal.goal_cycles.map(goalCycle => {
          const cycle = goalCycle.review_cycle || goalCycle.employee_cycle
          if (!cycle || get(uniqueCycleIds, cycle.id)) {
            return undefined
          }
          set(uniqueCycleIds, cycle.id, true)
          return cycle as ReviewCyclesInterface
        })
      : goal.kpis.map(kpi => {
          const target =
            kpi.update_type === UpdateTypes.roadmap
              ? kpi.target_epics?.[0]
              : kpi.targets?.[0]

          const cycle = target?.review_cycle || target?.employee_cycle

          if (!cycle || get(uniqueCycleIds, cycle.id)) {
            return undefined
          }

          set(uniqueCycleIds, cycle.id, true)
          return cycle as ReviewCyclesInterface
        })
  )
    .filter(Boolean)
    .sort((a, b) => Number(b.offset) - Number(a.offset))

  return [{ id: undefined, name: 'All cycles' }, ...goalCycles]
}

export const GoalPreviewPage = () => {
  const { values } = useLapeContext<GoalPreviewInterface>()
  const { query } = useQuery()
  const defaultCycleId = query.cycle__id
  const [availableCycles] = useState(getGoalTargetsCycles(values))
  const isOnboarding = isOnboardingPath()

  const initialCycle =
    availableCycles.find(({ offset, id }) =>
      defaultCycleId ? Number(id) === Number(defaultCycleId) : offset === 0,
    ) || availableCycles[0]

  const [cycle, setCycle] = useState<GoalCycleSelectType>(initialCycle)

  return (
    <PageWrapper>
      <PageBody
        maxWidth={{ all: Token.breakpoint.lg, xxl: Token.breakpoint.xl }}
        // we need it here to fix the positioning of a back button.
        // there are few different ways we use headers across the app and they very position
        style={isOnboarding ? { paddingTop: '0px !important' } : {}}
      >
        <GoalsPreviewHeader cycle={isReviewCycle(cycle) ? cycle : undefined} />
        {cycle ? (
          <GoalsPreviewLeftSide
            cycle={cycle}
            availableCycles={availableCycles}
            onCycleChanged={id => {
              const newCycle = availableCycles.find(c => c.id === id)!
              setCycle(newCycle)
              if (!newCycle) {
                // should not ever happen
                const meta = {
                  cycleId: id,
                  availableCyclesId: availableCycles.map(c => c.id),
                }
                captureException(`Failed to update cycle in the goal preview page`, {
                  extra: meta,
                })
              }
            }}
          />
        ) : null}
      </PageBody>
    </PageWrapper>
  )
}
