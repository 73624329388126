import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  Button,
  Color,
  Text,
  Box,
  HStack,
  VStack,
  Popup,
  Widget,
  FilterButton,
  Flex,
  Token,
  ActionButton,
} from '@revolut/ui-kit'
import { Success } from '@revolut/icons'
import styled, { keyframes } from 'styled-components'
import mean from 'lodash/mean'

import {
  EngagementAnswerInterface,
  EngagementQuestionVoicesInterface,
} from '@src/interfaces/engagement'
import { Grid } from '@src/components/CommonSC/Grid'
import { engagementQuestionBoxFormRequests } from '@src/api/engagement'
import {
  ArrowThinRight,
  IconComponentType,
  SocialDislike,
  SocialLike,
} from '@revolut/icons'
import { IdAndName } from '@src/interfaces'
import Tooltip from '@components/Tooltip/Tooltip'
import LapeNewTextArea from '@components/Inputs/LapeFields/LapeNewTextArea'
import Form from '@src/features/Form/Form'
import { connect } from 'lape'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { useFormValidator } from '@src/features/Form/FormValidator'
import { GLASS_DOOR_URL } from '@src/constants/externalLinks'
import HTMLEditor from '@components/HTMLEditor/HTMLEditor'
import { useQuery } from '@src/utils/queryParamsHooks'
import { useKeyUp } from '@src/hooks/useKeyUp'

const appear = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const Comment = styled(Box)`
  animation: ${appear} 200ms linear;
`

const optionButtonsOptions = [
  { name: '1', id: 1, icon: SocialDislike },
  { name: '2', id: 2 },
  { name: '3', id: 3 },
  { name: '4', id: 4 },
  { name: '5', id: 5, icon: SocialLike },
  { name: "I don't know", id: null },
] as const

type Option = IdAndName<number | null> & { icon?: IconComponentType }

interface OptionButtonsProps {
  value: Option | null
  onChange: (option: Option) => void
}

const OptionButtons = ({ value, onChange }: OptionButtonsProps) => {
  const getButton = (option: Option) => (
    <ActionButton
      useIcon={option.icon}
      variant={value === option ? 'accent' : undefined}
      onClick={() => onChange(option)}
      width="100%"
      key={option.id}
    >
      {option.name}
    </ActionButton>
  )

  return (
    <Grid flow="column" gap={24} mb="auto" pb="s-8">
      <VStack>
        <Tooltip text="Strongly disagree" placement="top" delay={750}>
          {getButton(optionButtonsOptions[0])}
        </Tooltip>
      </VStack>
      {getButton(optionButtonsOptions[1])}
      {getButton(optionButtonsOptions[2])}
      {getButton(optionButtonsOptions[3])}
      <VStack>
        <Tooltip text="Strongly agree" placement="top" delay={750}>
          {getButton(optionButtonsOptions[4])}
        </Tooltip>
      </VStack>
      <Box />
      {getButton(optionButtonsOptions[5])}
    </Grid>
  )
}

type CategoryButtonsProps = {
  categories: string[]
  value: string | null
  onChange: (newValue: string) => void
}

const CategoryButtons = ({ categories, value, onChange }: CategoryButtonsProps) => {
  return (
    <Flex gap="s-8" mb="s-8" width="100%" flexWrap="wrap">
      {categories.map(category => (
        <FilterButton
          key={category}
          active={value === category}
          onClick={() => onChange(category)}
        >
          {category}
        </FilterButton>
      ))}
    </Flex>
  )
}

interface QuestionBoxProps {
  questions: EngagementQuestionVoicesInterface[]
  onComplete: () => void
  onClose: () => void
  isPreview?: boolean
  questionIndex: number
  setQuestionIndex: React.Dispatch<React.SetStateAction<number>>
}

const QuestionBox = ({
  questions,
  onComplete,
  onClose,
  isPreview,
  questionIndex,
  setQuestionIndex,
}: QuestionBoxProps) => {
  const { initialValues, values, reset } = useLapeContext<EngagementAnswerInterface>()
  const formValidator = useFormValidator()

  const [pending, setPending] = useState(false)
  const [glassdoorPopupOpen, setGlassDoorPopupOpen] = useState(false)
  const [selectedOption, setSelectedOption] = useState<Option | null>(null)
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null)
  const scores = useRef<number[]>([])
  const textAreaRef = useRef<HTMLTextAreaElement>(null)

  const { question, id } = questions[questionIndex]
  const isLastQuestion = questionIndex + 1 === questions.length

  const { query } = useQuery<{ is_test: string }>()
  const isTestSurvey = query.is_test === 'true'

  if (isTestSurvey) {
    values.is_test = true
  }

  const createSubmitHandler = (onSuccess?: () => void, onError?: () => void) =>
    formValidator!.validate(async () => {
      setPending(true)
      try {
        await engagementQuestionBoxFormRequests.submit(values, {})
      } catch (e) {
        onError?.()
        throw e
      } finally {
        setPending(false)
      }
      onSuccess?.()
    })

  const submitQuestion = (onSuccess?: () => void, onError?: () => void) => {
    if (isPreview || !canSubmit) {
      return
    }

    values.answer_score = null // answer_score is required, should be null if no score
    if (selectedOption?.id) {
      scores.current = [...scores.current, selectedOption.id]
      values.answer_score = selectedOption.id
    }
    values.question = { id: question.id }
    values.question_queue_item_id = id

    if (selectedCategory) {
      values.categories = {
        names: [selectedCategory],
      }
    }

    const handleSubmit = createSubmitHandler(onSuccess, onError)

    handleSubmit()
  }

  const submitAndAdvanceQuestion = () => {
    submitQuestion(() => {
      setSelectedOption(null)
      setSelectedCategory(null)
      setQuestionIndex(prev => prev + 1)
      reset(initialValues as EngagementAnswerInterface)
    })
  }

  const submitLastQuestion = () => {
    if (isPreview) {
      return
    }
    submitQuestion(() => {
      const averageScore = mean(scores.current)

      if (averageScore > 4) {
        setGlassDoorPopupOpen(true)
      } else {
        onComplete()
      }
    })
  }

  const canSubmit = useMemo(() => {
    const isCommentRequired = question.comment.id === 'mandatory'
    const isCommentValidated = isCommentRequired ? !!values.answer_text : true

    const isCategoriesAvailable = !!question.categories?.names?.length
    const isCategoriesValidated = isCategoriesAvailable ? !!selectedCategory : true

    if (!isCommentValidated) {
      return false
    }

    if (question.type?.id === 'open_ended') {
      return isCategoriesValidated
    }

    return !!selectedOption
  }, [question, values.answer_text, selectedCategory, selectedOption])

  const keyUpHandler = useCallback(
    event => {
      if (event.target !== textAreaRef.current) {
        switch (true) {
          case Number(event.key) >= 1 && Number(event.key) <= 6:
            setSelectedOption(optionButtonsOptions[Number(event.key) - 1])
            question.comment.id === 'mandatory' &&
              textAreaRef.current &&
              textAreaRef.current.focus()
            break
          case event.key === 'Enter' && canSubmit:
            submitAndAdvanceQuestion()
            break
        }
      }
    },
    [question, canSubmit],
  )

  useKeyUp(keyUpHandler)

  useEffect(() => {
    if (question.comment.id === 'mandatory' && question.type?.id === 'open_ended') {
      textAreaRef.current && textAreaRef.current.focus()
    }
  }, [questionIndex])

  return (
    <>
      <VStack space="s-32" mb="s-32">
        <Widget p="s-16" width={Token.breakpoint.md}>
          <VStack space="s-40">
            <VStack space="s-8">
              <Text use="h1" fontSize="h1" fontWeight="600">
                {question.question_text}
              </Text>
              {!!question.description && (
                <HTMLEditor
                  readOnly
                  nonResizable
                  noPadding
                  color={Color.GREY_TONE_50}
                  style={{ background: Token.color.widgetBackground }}
                  height="auto"
                  onChange={() => {}}
                  value={question.description}
                />
              )}
            </VStack>
            {
              <VStack space="s-16">
                {question.type?.id === 'open_ended' ? (
                  <CategoryButtons
                    categories={question.categories?.names || []}
                    value={selectedCategory}
                    onChange={setSelectedCategory}
                  />
                ) : (
                  <OptionButtons value={selectedOption} onChange={setSelectedOption} />
                )}
                {(question.comment?.id !== 'no_comment' ||
                  (question.type?.id === 'open_ended' &&
                    !question.categories?.names?.length)) && (
                  <Comment>
                    <LapeNewTextArea
                      elementRef={textAreaRef}
                      required
                      name="answer_text"
                      rows={3}
                      maxRows={3}
                      label={
                        question.comment.id === 'mandatory'
                          ? 'Comment (required)'
                          : 'Comment (optional)'
                      }
                      placeholder={
                        question.comment.id === 'mandatory'
                          ? 'Written feedback for this question is mandatory'
                          : 'Written feedback for this question is optional'
                      }
                    />
                  </Comment>
                )}
              </VStack>
            }
          </VStack>
        </Widget>
        <Box>
          <HStack space="s-8">
            {isLastQuestion ? (
              <ActionButton
                variant="accent"
                onClick={submitLastQuestion}
                disabled={!canSubmit}
                pending={pending}
              >
                Submit
              </ActionButton>
            ) : (
              <ActionButton
                variant="accent"
                onClick={submitAndAdvanceQuestion}
                disabled={!canSubmit}
                useIcon={ArrowThinRight}
                pending={pending}
              >
                Next question
              </ActionButton>
            )}
          </HStack>
        </Box>
      </VStack>
      <Popup
        open={glassdoorPopupOpen}
        variant="bottom-sheet"
        closeOnEsc={false}
        closeOnOverlayClick={false}
      >
        <VStack align="center" space="s-24" my="s-8">
          <Success color={Color.BLUE} size={104} />
          <Text use="p" variant="h4" fontWeight={600}>
            Thanks for your feedback!
          </Text>
          <Text use="p" variant="caption" color={Color.GREY_TONE_50} textAlign="center">
            Rate us on Glassdoor and share your experience with future Revoluters
          </Text>
        </VStack>
        <Popup.Actions mt="s-24">
          <Button
            elevated
            onClick={() => {
              window.open(GLASS_DOOR_URL, '_blank', 'noopener noreferrer')
              onClose()
            }}
          >
            Go to Glassdoor
          </Button>
          <Button
            variant="secondary"
            onClick={e => {
              e.stopPropagation()
              setGlassDoorPopupOpen(false)
              onComplete()
            }}
          >
            Maybe later
          </Button>
        </Popup.Actions>
      </Popup>
    </>
  )
}

export default connect((props: QuestionBoxProps) => (
  <Form disableLocalStorageCaching>
    <QuestionBox {...props} />
  </Form>
))
