import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { KeyPerson } from '@src/pages/EmployeeProfile/Preview/ProfileSummary/components/KeyPerson'
import { EmployeeInterface, EmployeeStats } from '@src/interfaces/employees'
import { ROUTES } from '@src/constants/routes'
import { useGetOrganisationSettings, useGlobalSettings } from '@src/api/settings'
import { EmployeePageHeader } from '../common/EmployeePageHeader'
import { Ownership } from '../../Preview/Ownership'
import {
  canViewFindings,
  canViewKeyPerson,
  canViewLinkedAccounts,
  canViewOwnership,
  canViewRisk,
} from '../../Preview/ProfileSummary/common'
import { LinkedAccountsList } from '../../Preview/ProfileSummary/components/LinkedAccountsList'
import { Findings } from '@src/pages/EmployeeProfile/Preview/Findings'
import { Risk } from '@src/pages/EmployeeProfile/Preview/Risk'
import useIsCommercial from '@src/hooks/useIsCommercial'

type Props = {
  data: EmployeeInterface
  stats: EmployeeStats | undefined
  refreshStats: () => void
}
export const CompliancePages = (props: Props) => {
  const isCommercial = useIsCommercial()
  const { settings } = useGlobalSettings()
  const { data: organisationSettings } = useGetOrganisationSettings()
  const tabs = [
    {
      title: 'Findings',
      path: ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.FINDINGS,
      component: Findings,
      canView: canViewFindings(props.data, !!organisationSettings?.findings?.enabled),
    },
    {
      title: 'Risk',
      path: ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.RISK.ANY,
      component: Risk,
      canView: canViewRisk(props.data, !!organisationSettings?.risk?.enabled),
    },
    {
      title: 'Ownership',
      path: ROUTES.FORMS.EMPLOYEE.OWNERSHIP.ANY,
      component: Ownership,
      canView: canViewOwnership(props.data),
    },
    {
      title: 'Key person',
      path: ROUTES.FORMS.EMPLOYEE.KEY_PERSON,
      component: KeyPerson,
      canView: canViewKeyPerson(props.data, !isCommercial),
    },
    {
      title: 'Linked accounts',
      path: ROUTES.FORMS.EMPLOYEE.LINKED_ACCOUNTS,
      component: LinkedAccountsList,
      canView: canViewLinkedAccounts(props.data, !!settings.linked_accounts_enabled),
    },
  ].filter(({ canView }) => canView)

  return (
    <Switch>
      {tabs.map(tab => {
        return (
          <Route exact path={tab.path} key={tab.path}>
            <EmployeePageHeader title={tab.title} data={props.data} />
            <tab.component {...props} />
          </Route>
        )
      })}
    </Switch>
  )
}
