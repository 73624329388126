import { RequestInterfaceNew, TableRequestInterface } from '@src/interfaces'
import { api, apiWithoutHandling } from '@src/api/index'
import { API } from '@src/constants/api'
import { filterSortPageIntoQuery } from '@src/utils/table'
import { PermissionsInterface } from '@src/interfaces/permissions'

export const createPermissionAPI = (endpoint: string) => {
  return {
    permissionRequests: (
      entityId: string | number,
    ): TableRequestInterface<PermissionsInterface> => ({
      getItems: async ({ sortBy, filters, page }) =>
        api.get(`${endpoint}/${entityId}/objectPermissions`, {
          params: filterSortPageIntoQuery(sortBy, filters, page),
        }),
    }),
    permissionRequestsNew: (
      entityId: string | number,
    ): RequestInterfaceNew<PermissionsInterface> => ({
      get: async ({ id }) => api.get(`${endpoint}/${entityId}/objectPermissions/${id}`),
      update: async (data, { id }) =>
        apiWithoutHandling.patch(`${endpoint}/${entityId}/objectPermissions/${id}`, data),
      submit: async data =>
        apiWithoutHandling.post(`${endpoint}/${entityId}/objectPermissions`, data),
      delete: async ({ id }) =>
        api.post(`${endpoint}/${entityId}/objectPermissions/${id}/revoke`),
    }),
  }
}

export const {
  permissionRequests: departmentPermissionsRequests,
  permissionRequestsNew: departmentPermissionsRequestsNew,
} = createPermissionAPI(API.DEPARTMENT)

export const {
  permissionRequests: teamPermissionsRequests,
  permissionRequestsNew: teamPermissionsRequestsNew,
} = createPermissionAPI(API.TEAMS)

export const {
  permissionRequests: functionPermissionsRequests,
  permissionRequestsNew: functionPermissionsRequestsNew,
} = createPermissionAPI(API.FUNCTIONS)

export const {
  permissionRequests: rolePermissionsRequests,
  permissionRequestsNew: rolePermissionsRequestsNew,
} = createPermissionAPI(API.ROLES)

export const {
  permissionRequests: specialisationPermissionsRequests,
  permissionRequestsNew: specialisationPermissionsRequestsNew,
} = createPermissionAPI(API.SPECIALISATIONS)

export const {
  permissionRequests: candidatePermissionsRequests,
  permissionRequestsNew: candidatePermissionsRequestsNew,
} = createPermissionAPI(`${API.INTERVIEWS}/candidates`)

export const { permissionRequestsNew: hiringPipelinePermissionsRequestsNew } =
  createPermissionAPI('/hiringPipelines')

export const {
  permissionRequests: requisitionPermissionsRequests,
  permissionRequestsNew: requisitionPermissionsRequestsNew,
} = createPermissionAPI(API.REQUISITIONS)

export const {
  permissionRequests: connectionPermissionsRequests,
  permissionRequestsNew: connectionPermissionsRequestsNew,
} = createPermissionAPI('/data/connections')

export const {
  permissionRequests: queryPermissionsRequests,
  permissionRequestsNew: queryPermissionsRequestsNew,
} = createPermissionAPI('/data/reporting/queries')
