import React from 'react'
import styled from 'styled-components'
import { CellTypes, RowInterface } from '@src/interfaces/data'
import { Flex, Link, Text, Token, Widget } from '@revolut/ui-kit'
import Icon from '@components/Icon/Icon'
import { ProbationGoalInterface } from '@src/interfaces/probationReview'
import { getIconKeyByIssue } from '@src/utils/performance'
import AdjustableTable from '@components/Table/AdjustableTable'
import { TableNames } from '@src/constants/table'
import { selectorKeys } from '@src/constants/api'
import { PerformanceLayoutTableContainer } from '@src/pages/EmployeeProfile/Layout/Performance/utils'

const Cell = styled.div`
  padding: 0 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  font-size: 14px;
`

interface Props {
  tickets: ProbationGoalInterface[]
}

const statusToColor = (status: string) => {
  switch (status) {
    case 'To Do':
      return Token.color.greyTone50
    case 'Done':
      return Token.color.green
    case 'In Progress':
      return Token.color.foreground
    default:
      return Token.color.foreground
  }
}

const ROW: RowInterface<ProbationGoalInterface> = {
  cells: [
    {
      type: CellTypes.insert,
      idPoint: 'jira_issue_type',
      dataPoint: 'jira_issue_type',
      sortKey: null,
      filterKey: null,
      selectorsKey: selectorKeys.none,
      title: 'Type',
      width: 40,
      insert: ({ data }) =>
        data.jira_issue_type ? (
          <Icon type={getIconKeyByIssue(data.jira_issue_type)} size="medium" />
        ) : (
          ''
        ),
    },
    {
      type: CellTypes.insert,
      idPoint: 'name',
      dataPoint: 'name',
      sortKey: null,
      filterKey: null,
      selectorsKey: selectorKeys.none,
      title: 'Jira',
      width: 344,
      insert: ({ data }) => (
        <Cell title={data.jira_issue_summary!}>
          <Link href={data.jira_issue_url!} target="_blank" rel="noreferrer noopener">
            {data.jira_issue_key}
            <Text color="foreground">: {data.jira_issue_summary}</Text>
          </Link>
        </Cell>
      ),
    },
    {
      type: CellTypes.insert,
      idPoint: 'status',
      dataPoint: 'status',
      sortKey: null,
      filterKey: null,
      selectorsKey: selectorKeys.none,
      title: 'Progress',
      width: 86,
      insert: ({ data }) => (
        <Cell>
          <Flex justifyContent="flex-end">
            {data.jira_issue_status ? (
              <Text
                color={statusToColor(data.jira_issue_status)}
                style={{ textTransform: 'capitalize' }}
              >
                {data.jira_issue_status.toLowerCase()}
              </Text>
            ) : (
              ' - '
            )}
          </Flex>
        </Cell>
      ),
    },
  ],
}

export const TicketsView = ({ tickets }: Props) => {
  return (
    <Widget mt="s-16">
      <PerformanceLayoutTableContainer>
        <AdjustableTable<ProbationGoalInterface>
          name={TableNames.ProbationGoals}
          row={ROW}
          count={tickets.length}
          data={tickets}
          noDataMessage="All your goals fot this cycle will appear here"
          hideCountAndButtonSection
        />
      </PerformanceLayoutTableContainer>
    </Widget>
  )
}
