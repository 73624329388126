import React from 'react'
import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import { selectorKeys } from '@src/constants/api'
import { WhitelistRecordInterface } from '@src/interfaces/supportTool'
import { Status } from '@components/CommonSC/General'
import { Action } from '@revolut/ui-kit'
import Tooltip from '@components/Tooltip/Tooltip'

export const whitelistStartDateColumn: ColumnInterface<WhitelistRecordInterface> = {
  type: CellTypes.date,
  idPoint: 'start_date',
  dataPoint: 'start_date',
  sortKey: 'start_date',
  filterKey: 'start_date',
  selectorsKey: selectorKeys.none,
  title: 'Start Date',
}

export const whitelistEndDateColumn: ColumnInterface<WhitelistRecordInterface> = {
  type: CellTypes.date,
  idPoint: 'end_date',
  dataPoint: 'end_date',
  sortKey: 'end_date',
  filterKey: 'end_date',
  selectorsKey: selectorKeys.none,
  title: 'End Date',
}

export const whitelistReviewerColumn: ColumnInterface<WhitelistRecordInterface> = {
  type: CellTypes.insert,
  idPoint: 'whitelisted_reviewers',
  dataPoint: 'whitelisted_reviewers',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  insert: ({ data }) => data.whitelisted_reviewers.map(r => r.display_name).join(', '),
  title: 'Reviewer',
}

export const whitelistCycleColumn: ColumnInterface<WhitelistRecordInterface> = {
  type: CellTypes.text,
  idPoint: 'cycle.id',
  dataPoint: 'cycle.name',
  sortKey: 'cycle__name',
  filterKey: 'cycle_id',
  selectorsKey: selectorKeys.review_cycles,
  title: 'Cycle',
}

export const whitelistCreatedByColumn: ColumnInterface<WhitelistRecordInterface> = {
  type: CellTypes.text,
  idPoint: 'creator.id',
  dataPoint: 'creator.display_name',
  sortKey: 'creator__display_name',
  filterKey: 'creator_id',
  selectorsKey: selectorKeys.employee,
  title: 'Created by',
}

export const whitelistReasonColumn: ColumnInterface<WhitelistRecordInterface> = {
  type: CellTypes.insert,
  idPoint: 'whitelisted_reviewers',
  dataPoint: 'whitelisted_reviewers',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  insert: ({ data }) => (
    <Action onClick={e => e.stopPropagation()}>
      <Tooltip text={data.reason} placement="top" useFormatting>
        View
      </Tooltip>
    </Action>
  ),
  title: 'Reason',
}

export const whitelistStatusColumn: ColumnInterface<WhitelistRecordInterface> = {
  type: CellTypes.insert,
  idPoint: 'status',
  dataPoint: 'status',
  sortKey: 'status',
  filterKey: 'status',
  selectorsKey: selectorKeys.performance_whitelist_status_choices,
  insert: ({ data }) => <Status status={data.status}>{data.status}</Status>,
  title: 'Status',
}

export const whitelistActionColumn: ColumnInterface<WhitelistRecordInterface> = {
  type: CellTypes.insert,
  idPoint: 'whitelisted_reviewers',
  dataPoint: 'whitelisted_reviewers',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Action',
}
