import React from 'react'
import { Route } from 'react-router-dom'

import { ROUTES } from '@src/constants/routes'
import { dynamicGroups } from '@src/api/dynamicGroups'
import General from './General'
import Form from '@src/features/Form/Form'

const DynamicGroups = () => {
  return (
    <Route path={ROUTES.FORMS.DYNAMIC_GROUPS.GENERAL}>
      <Form api={dynamicGroups}>
        <General />
      </Form>
    </Route>
  )
}

export default DynamicGroups
