import React from 'react'

import { EmployeeInterface } from '@src/interfaces/employees'
import { SetInactive, SetInactiveAction } from '../SetInactive'
import { UseEmployeeInactivityDataReturnType } from '../SetInactive/hooks'
import { UseEmployeeStatusTransitionsReturnType } from './hooks'
import { Terminate } from './Terminate'
import { getStatusTransitionButtons } from '..'

type Props = {
  employee: EmployeeInterface
  statusTransitions: UseEmployeeStatusTransitionsReturnType
  employeeInactivity: UseEmployeeInactivityDataReturnType
  isDropdownItem?: boolean
  statusTransitionButtons?: React.ReactNode[]
}
export const StatusChangeButtonsGroup = ({
  employee,
  statusTransitions,
  employeeInactivity,
  isDropdownItem,
  statusTransitionButtons,
}: Props) => {
  return (
    <>
      {statusTransitionButtons ||
        getStatusTransitionButtons(employee, statusTransitions, isDropdownItem)}
      {isDropdownItem ? (
        <>
          <SetInactiveAction
            isDropdownItem
            data={employee}
            statusTransitions={statusTransitions}
            employeeInactivity={employeeInactivity}
          />
          <Terminate isDropdownItem data={employee} />
        </>
      ) : (
        <>
          <SetInactive
            data={employee}
            statusTransitions={statusTransitions}
            employeeInactivity={employeeInactivity}
          />
          <Terminate data={employee} />
        </>
      )}
    </>
  )
}
