import { Avatar, Box, IconName, Item, Switch, VStack } from '@revolut/ui-kit'
import React, { ReactNode } from 'react'
import SectionTitle from '../components/SectionTitle'
import { GroupWithRequired, RequiredItem } from '../Performance/common'
import { useLapeField } from '@src/features/Form/LapeForm'
import { IdAndName } from '@src/interfaces'

interface ArraySwitchItemProps {
  name: string
  title: ReactNode
  description: ReactNode
  icon: IconName
  switchId: string
}

const LapeArraySwitchItem = ({
  name,
  title,
  description,
  icon,
  switchId,
}: ArraySwitchItemProps) => {
  const { value, onChange } = useLapeField<IdAndName<string>[]>(name)
  const ids = value.map(({ id }) => id)
  const checked = ids.includes(switchId)

  return (
    <Item use="label" variant="compact" aria-pressed={checked}>
      <Item.Avatar>
        <Avatar useIcon={icon} />
      </Item.Avatar>
      <Item.Content>
        <Item.Title>{title}</Item.Title>
        <Item.Description>{description}</Item.Description>
      </Item.Content>
      <Item.Side>
        <Switch
          onChange={e => {
            if (e.target.value === 'TRUE') {
              onChange(value.filter(({ id }) => id !== switchId))
            } else {
              onChange([...value, { id: switchId, name: switchId }])
            }
          }}
          value={checked ? 'TRUE' : 'FALSE'}
          checked={checked}
        />
      </Item.Side>
    </Item>
  )
}

export const Types = () => {
  return (
    <VStack space="s-24">
      <Box>
        <SectionTitle
          title="Select which types of KPIs your company will be using"
          subtitle="Choose the available methods of measurement that your key performance indicators can use"
        />
        <GroupWithRequired
          requiredItems={[
            {
              title: 'Manual KPIs',
              description: 'The performance will be measured by manual inputs to the KPI',
              icon: 'Pencil',
            },
          ]}
        >
          <LapeArraySwitchItem
            title="Cascaded KPIs"
            description="The performance will be the same as the parent KPI"
            icon="ArrowDowngrade"
            switchId="cascaded"
            name="allowed_kpi_types"
          />
          <LapeArraySwitchItem
            title="Roadmap KPIs"
            description="Progress will be determined by the completion of linked roadmap deliverables"
            icon="Map"
            switchId="roadmap"
            name="allowed_kpi_types"
          />
        </GroupWithRequired>
      </Box>
      <Box>
        <SectionTitle
          title="What KPI strategies do you want to use?"
          subtitle="The direction you want to measure the progress in, and whether are there any boundaries you want to maintain a KPI between"
        />
        <GroupWithRequired
          requiredItems={[
            {
              title: 'Increase',
              description: 'Aim for growth by setting a higher target, from X to Y',
              icon: 'ArrowRates',
            },
          ]}
        >
          <RequiredItem
            icon="ArrowRatesDown"
            title="Decrease"
            description="Minimize defects or risk metrics by setting a lower target, from Y to X"
          />
          <LapeArraySwitchItem
            title="Maintain"
            description="Set upper and lower boundaries for your KPI, between X and Y"
            icon="MarketOrder"
            switchId="keep_target"
            name="allowed_kpi_strategies"
          />
          <LapeArraySwitchItem
            title="Maintain-up"
            description="Define boundaries where passing the upper limit is OK, between X and Y but no lower"
            icon="OrderDownwards"
            switchId="keep_target_up"
            name="allowed_kpi_strategies"
          />
          <LapeArraySwitchItem
            title="Maintain-down"
            description="Define boundaries where passing the lower limit is OK, between X and Y but no higher"
            icon="OrderUpwards"
            switchId="keep_target_down"
            name="allowed_kpi_strategies"
          />
        </GroupWithRequired>
      </Box>
    </VStack>
  )
}
