import { Item, Avatar, chain, Text, AvatarProps } from '@revolut/ui-kit'
import { InternalLink } from '@components/InternalLink/InternalLink'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { formatDateTime } from '@src/utils/format'
import React from 'react'
import { TicketFullInterface } from '@src/interfaces/tickets'
import { statusColorMap } from '@src/pages/Tickets/common'
import { formatStatus } from '@src/utils/string'

interface BugReportTicketProps {
  ticket: TicketFullInterface
  iconColor?: AvatarProps['color']
}

export const BugReportTicket = ({ ticket, iconColor }: BugReportTicketProps) => {
  return (
    <Item use={InternalLink} to={pathToUrl(ROUTES.TICKETS.DETAILS, { id: ticket.id })}>
      <Item.Avatar>
        <Avatar
          useIcon="Bug"
          color={iconColor || statusColorMap[ticket.current_status.status]}
        />
      </Item.Avatar>
      <Item.Content>
        <Item.Title>{ticket.title}</Item.Title>
        <Item.Description>
          {chain(
            formatDateTime(ticket.current_status.creation_date_time),
            <Text color={statusColorMap[ticket.current_status.status]}>
              {formatStatus(
                ticket.current_status.sub_status || ticket.current_status.status,
              )}
            </Text>,
          )}
        </Item.Description>
      </Item.Content>
    </Item>
  )
}
