import { useSelector } from 'react-redux'
import { selectFeatureFlags, selectPermissions } from '@src/store/auth/selectors'
import { useCallback, useMemo } from 'react'
import { HUB_APPLICATIONS } from '@src/constants/hub'
import produce from 'immer'
import { HubAppType, HubInterface } from '@src/interfaces/hub'
import { FeatureFlags, PermissionTypes } from '@src/store/auth/types'
import { matchSorter } from 'match-sorter'
import { useGlobalSettings, useGetTimeOffSettings } from '@src/api/settings'
import useIsCommercial from '@src/hooks/useIsCommercial'

export const useHubApps = (options?: { showDisabled: boolean }) => {
  const permissions = useSelector(selectPermissions)
  const featureFlags = useSelector(selectFeatureFlags)
  const isCommercial = useIsCommercial()
  const { data: timeOffSettings } = useGetTimeOffSettings()
  const timeOffEnabled = !!timeOffSettings?.enabled

  const globalSettings = useGlobalSettings()

  const checkAccess = (canView?: PermissionTypes[], appFeatureFlags?: FeatureFlags[]) => {
    const checkPermissions = canView
      ? canView.some(permission => permissions.includes(permission))
      : true
    const checkFeatureFlags = appFeatureFlags
      ? appFeatureFlags.some(flag => featureFlags.includes(flag))
      : true

    return checkPermissions && checkFeatureFlags
  }

  const checkTabsAccess = (app: HubAppType) => {
    return 'tabs' in app
      ? Object.values(app.tabs).some(tab => checkAccess(tab.canView, tab.featureFlags))
      : checkAccess(app.canView)
  }

  const checkFeatureFlags = (app: HubAppType) => {
    return 'featureFlags' in app
      ? app.featureFlags?.some(featureFlag => featureFlags.includes(featureFlag))
      : true
  }

  return useMemo(() => {
    const hubApps: HubInterface = produce(HUB_APPLICATIONS, groups => {
      groups.forEach(group => {
        group.items = group.items.reduce((acc: HubAppType[], item) => {
          const hasAccess = checkTabsAccess(item)
          const hasFeatureFlag = checkFeatureFlags(item)

          /** If feature disabled or no permission - show as disabled */
          if ('globalSetting' in item && item.globalSetting) {
            const settingEnabled = !!globalSettings.settings[item.globalSetting]

            /** temp fix to hide engagement in commercial products when disabled as we'd need BE to create a feature flag */
            if (isCommercial && item.id === 'engagement' && !settingEnabled) {
              return acc
            }

            if (settingEnabled || (!settingEnabled && options?.showDisabled)) {
              acc.push({ ...item, disabled: !settingEnabled })
            }
            return acc
          }
          // TODO: when BE returns time off settings in global settings response, move to hub config object, `globalSetting` property
          if (item.id === 'timeOff') {
            if (timeOffEnabled || (!timeOffEnabled && options?.showDisabled)) {
              acc.push({ ...item, disabled: !timeOffEnabled })
            }

            return acc
          }

          if (!hasFeatureFlag) {
            return acc
          }

          if (hasAccess || (!hasAccess && options?.showDisabled)) {
            acc.push({ ...item, disabled: !hasAccess })
          }
          return acc
        }, [])
      })
    })

    return hubApps.filter(appGroup => appGroup.items.length > 0)
  }, [globalSettings, timeOffEnabled])
}

export const useHubAppSearch = () => {
  const hubApps = useHubApps({ showDisabled: false })

  const apps = useMemo(() => {
    const initialValue: HubAppType[] = []
    return hubApps.reduce(
      (previousValue, currentValue) => previousValue.concat(currentValue.items),
      initialValue,
    )
  }, [hubApps])

  const findApps = useCallback(
    (query: string) => {
      return apps.length
        ? matchSorter(apps, query, {
            keys: [
              item => item.title,
              item => ('tabs' in item ? Object.values(item.tabs).map(i => i.title) : []),
            ],
          })
        : []
    },
    [apps],
  )

  return { findApps, apps }
}
