import React from 'react'
import { Box, Layout, Token } from '@revolut/ui-kit'
import { css } from 'styled-components'

import Sidebar from './SideBar/SideBar'
import { PinnedAppsProvider } from '@src/pages/Hub/Apps/common'
import { PinnedTabsProvider } from '@src/features/TabPinning/pinnedTabs'
import { DemoModeWarningBar } from '@src/features/DemoMode/DemoModeWarningBar'
import { useCurrentTenantInfo } from '@src/api/tenants'
import { BugReportSide } from '@components/HelpCenter/components/BugReportSide'
import bugReportSideState from '@components/HelpCenter/components/BugReportSideState'

const withDemoBarCss = css`
  @media ${Token.media.md} {
    top: 30px;
    height: calc(100vh - 30px);
  }
`

export const MainLayout: React.FC = ({ children }) => {
  const { isDemoMode } = useCurrentTenantInfo()

  return (
    <Box width="100%">
      <DemoModeWarningBar />
      <PinnedAppsProvider>
        <PinnedTabsProvider>
          <Layout variant="container(wide) main(wide) side(wide)">
            <Layout.Menu css={isDemoMode ? withDemoBarCss : undefined}>
              <Sidebar />
            </Layout.Menu>
            <Layout.Main style={{ isolation: 'isolate' }}>{children}</Layout.Main>
            <Layout.Side />
            <BugReportSide
              open={bugReportSideState.open}
              onClose={() => {
                bugReportSideState.open = false
              }}
            />
          </Layout>
        </PinnedTabsProvider>
      </PinnedAppsProvider>
    </Box>
  )
}
