import React from 'react'
import { ErrorWidget, Flex } from '@revolut/ui-kit'
import * as Sentry from '@sentry/react'

import Lock from '@src/assets/3D/Lock@2x.png'
import { navigateTo } from '@src/actions/RouterActions'
import { ROUTES } from '@src/constants/routes'

interface FormErrorGuardProps {
  error?: any
  notFoundMessage?: string
}

export const NoAccessError = () => {
  return <Error title="You don't have the permission to view this page" image={Lock} />
}

export const GenericError = () => (
  <Error image="https://assets.revolut.com/assets/3d-images/3D087@2x.png" />
)

export const NotFoundError = ({
  message = "The page you're looking for could not be found",
}: {
  message?: string
}) => (
  <Error
    title={message}
    image="https://assets.revolut.com/assets/3d-images/3D087@2x.png"
  />
)

export const FormErrorGuard: React.FC<FormErrorGuardProps> = ({
  error,
  children,
  notFoundMessage,
}) => {
  const responseStatus = error?.response?.status

  // Show generic error state for all server errors
  if (responseStatus >= 500 && responseStatus < 600) {
    Sentry.captureException(error)
    return <GenericError />
  }

  if (responseStatus === 403) {
    return <NoAccessError />
  }

  if (responseStatus === 404) {
    return <NotFoundError message={notFoundMessage} />
  }

  return <>{children}</>
}

interface ErrorProps {
  title?: string
  image: string
}

export const Error = ({ title, image }: ErrorProps) => {
  return (
    <Flex height="100%" alignItems="center" justifyContent="center">
      <ErrorWidget>
        <ErrorWidget.Image src={image} />
        <ErrorWidget.Title>{title}</ErrorWidget.Title>
        <ErrorWidget.Action onClick={() => navigateTo(ROUTES.MAIN)}>
          Go to dashboard
        </ErrorWidget.Action>
      </ErrorWidget>
    </Flex>
  )
}
