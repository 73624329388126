import React from 'react'
import { Dropdown, MoreBar } from '@revolut/ui-kit'
import { Rocket } from '@revolut/icons'

import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { useCanViewNominateForPromotion } from '../common'
import { EmployeeButtonProps } from '../types'

export const NominateForPromotion = ({
  data,
  isDropdownItem,
  cycleId,
}: EmployeeButtonProps) => {
  const canView = useCanViewNominateForPromotion({ data, cycleId })

  const onClick = () => {
    navigateTo(
      pathToUrl(ROUTES.FORMS.PROMOTION_NOMINATION.MANAGER_FORM, {
        employeeId: data.id,
      }),
    )
  }

  if (!canView) {
    return null
  }

  if (isDropdownItem) {
    return (
      <Dropdown.Item use="button" useIcon={Rocket} onClick={onClick}>
        Nominate for promotion
      </Dropdown.Item>
    )
  }
  return <MoreBar.Action onClick={onClick}>Nominate for promotion</MoreBar.Action>
}
