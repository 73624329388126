import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import {
  MoreBar,
  Flex,
  useStatusPopup,
  StatusPopup,
  Text,
  Color,
  Button,
} from '@revolut/ui-kit'
import { SendMessage } from '@revolut/icons'
import pluralize from 'pluralize'

import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { PageWrapper } from '@src/components/Page/Page'
import { TableWrapper } from '@src/components/Table/TableWrapper'
import { ROUTES } from '@src/constants/routes'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import Stat from '@src/components/Stat/Stat'
import SearchTable from '@src/components/Table/SearchTable/SearchTable'
import { RowInterface } from '@src/interfaces/data'
import { UserAccessManagementInterface } from '@src/interfaces/accessManagement'
import {
  emailColumn,
  lastLoginColumn,
  statusColumn,
  userNameColumn,
} from '@src/constants/columns/accessManagement/accessManagementUsers'
import { TableNames } from '@src/constants/table'
import AdjustableTable from '@src/components/Table/AdjustableTable'
import { useTable } from '@src/components/Table/hooks'
import { usersRequests, useUsersSelector } from '@src/api/accessManagement'
import { getSelectCellConfig } from '@src/components/Table/AdvancedCells/SelectCell/SelectCell'
import SelectTableWrapper, {
  SelectTableWrapperOnChangeData,
} from '@src/components/Table/AdvancedCells/SelectCell/SelectTableWrapper'
import SettingsButtons from '@src/features/SettingsButtons'
import { goBack } from '@src/actions/RouterActions'
import { bulkActivationEmail } from '@src/api/platformOnboarding'
import { Statuses } from '@src/interfaces'

const Row: RowInterface<UserAccessManagementInterface> = {
  cells: [
    {
      ...getSelectCellConfig(),
    },
    {
      ...userNameColumn,
      width: 300,
    },
    {
      ...emailColumn,
      width: 300,
    },
    {
      ...lastLoginColumn,
      width: 300,
    },
    {
      ...statusColumn,
      width: 300,
    },
  ],
}

export const SendActivationEmails = () => {
  const statusPopup = useStatusPopup()

  const permissions = useSelector(selectPermissions)
  const canSendInvitations = permissions.includes(PermissionTypes.HRManagerPermissions)

  const [selectedData, setSelectedData] =
    useState<SelectTableWrapperOnChangeData<UserAccessManagementInterface>>()
  const [sendInvitationsPending, setSendInvitationsPending] = useState(false)

  const table = useTable<UserAccessManagementInterface>(usersRequests, [
    {
      columnName: 'status',
      filters: [
        { id: Statuses.deactivated, name: Statuses.deactivated },
        { id: Statuses.inactive, name: Statuses.inactive },
      ],
      nonResettable: true,
    },
    {
      columnName: 'user_type',
      filters: [{ id: 'employee', name: 'employee' }],
      nonResettable: true,
    },
  ])

  const users = useUsersSelector(table.filterBy)

  const onSendInvitationEmails = async () => {
    if (!selectedData || !users) {
      return
    }

    const employeeToSendTo = (() => {
      if (selectedData.selectedRowsData.length) {
        return selectedData.selectedRowsData
          .filter(row => !!row.employee?.id)
          .map(row => ({ id: row.employee!.id }))
      }

      if (selectedData.isAllSelected) {
        return users
          .filter(
            user => !!user.employee?.id && !selectedData.excludeListIds.has(`${user.id}`),
          )
          .map(user => ({ id: user.employee.id }))
      }

      return []
    })()

    setSendInvitationsPending(true)

    try {
      const response = await bulkActivationEmail(employeeToSendTo)

      if (response.data.success) {
        statusPopup.show(
          <StatusPopup variant="success-result" onClose={statusPopup.hide}>
            <StatusPopup.Title>
              Invitation sent to{' '}
              <Text color={Color.BLUE}>
                {employeeToSendTo.length} team{' '}
                {pluralize('member', employeeToSendTo.length)}
              </Text>
            </StatusPopup.Title>
            <StatusPopup.Description>
              They will receive an invitation email to sign up immediately
            </StatusPopup.Description>
            <StatusPopup.Actions>
              <Button
                onClick={() => {
                  statusPopup.hide()
                  goBack(ROUTES.ADMIN.ACCESS_MANAGEMENT.USERS)
                }}
              >
                Return to users table
              </Button>
            </StatusPopup.Actions>
          </StatusPopup>,
        )
      } else {
        statusPopup.show(
          <StatusPopup variant="error" onClose={statusPopup.hide}>
            <StatusPopup.Title>Something went wrong</StatusPopup.Title>
            <StatusPopup.Description>Emails were not sent</StatusPopup.Description>
            <StatusPopup.Actions>
              <Button onClick={statusPopup.hide}>Close</Button>
            </StatusPopup.Actions>
          </StatusPopup>,
        )
      }
    } finally {
      setSendInvitationsPending(false)
    }
  }

  return (
    <PageWrapper>
      <PageHeader
        title="Send invitation emails"
        subtitle="Select the users you want to send action emails. The selected ones will receive an activation URL and they will be able to set their password and login in the system"
        backUrl={ROUTES.ADMIN.ACCESS_MANAGEMENT.USERS}
      />

      <TableWrapper>
        <Flex flexWrap="wrap" justifyContent="space-between" mb="s-16">
          <Stat label="Users" val={table?.loading ? undefined : table?.count} />
          <SearchTable
            placeholder="Search by name or email"
            onFilter={table.onFilterChange}
          />
        </Flex>

        {canSendInvitations && (
          <SettingsButtons mb="s-16">
            <MoreBar.Action
              onClick={onSendInvitationEmails}
              useIcon={SendMessage}
              disabled={!selectedData?.someSelected}
              pending={sendInvitationsPending}
            >
              Send activation emails
            </MoreBar.Action>
          </SettingsButtons>
        )}

        <SelectTableWrapper
          enabled
          onChange={setSelectedData}
          filters={table.filterBy}
          tableDataLength={table.data.length}
        >
          <AdjustableTable
            name={TableNames.SendInvitationEmails}
            useWindowScroll
            dataType="Users"
            row={Row}
            {...table}
            noDataMessage="Users will appear here."
          />
        </SelectTableWrapper>
      </TableWrapper>
    </PageWrapper>
  )
}
