import React from 'react'
import { Spinner, StatusWidget, VStack, Text } from '@revolut/ui-kit'

import { PageBody } from '@src/components/Page/PageBody'

export const ProcessingFileState = () => {
  return (
    <PageBody>
      <StatusWidget>
        <StatusWidget.Title>
          <VStack align="center" space="s-16" pt="s-16">
            <Spinner color="blue" size={60} />
            <Text>Upload in progress</Text>
          </VStack>
        </StatusWidget.Title>
        <StatusWidget.Description>
          Please wait while the file is being processed
        </StatusWidget.Description>
      </StatusWidget>
    </PageBody>
  )
}
