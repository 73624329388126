import React from 'react'
import GenericNotificationTable from '@src/pages/Forms/Notifications/GenericNotificationTable'
import { CellTypes, RowInterface, TabComponentProps } from '@src/interfaces/data'
import { NotificationsInterface } from '@src/interfaces/notifications'
import { selectorKeys } from '@src/constants/api'
import {
  notificationDescription,
  notificationsActionColumn,
  notificationsDeadline,
  notificationsEmployeedColumn,
  notificationsPriorityColumn,
  notificationsReceivedColumn,
} from '@src/constants/columns/notifications'
import { notificationsProbationRequests } from '@src/api/notifications'

const Reviews = ({
  refreshStats,
  type,
  employeeId,
  filterBy,
  tableRef,
}: TabComponentProps) => {
  const isDashboard = type === 'dashboard'

  const RowNotifications: RowInterface<NotificationsInterface> = {
    cells: [
      {
        ...notificationDescription(employeeId),
        width: 400,
      },
      {
        type: CellTypes.insert,
        idPoint: 'reviewer_relation',
        dataPoint: 'reviewer_relation',
        sortKey: null,
        filterKey: null,
        selectorsKey: selectorKeys.notification_reviewer_relation,
        insert: ({ data }) => {
          switch (data.reviewer_relation) {
            case 'line_manager':
              return 'Direct report review'
            case 'self':
              return 'Self review'
            case 'team_mate':
              return 'Additional review'
            case 'committee':
              return 'Committee'
            default:
              return 'Other review'
          }
        },
        title: 'Relation',
        width: 140,
      },
      {
        ...notificationsEmployeedColumn(isDashboard),
        width: isDashboard ? 100 : 210,
      },
      {
        ...notificationsReceivedColumn,
        width: 130,
      },
      {
        ...notificationsDeadline,
        width: 130,
      },
      {
        ...notificationsPriorityColumn,
        width: 100,
      },
      {
        ...notificationsActionColumn,
        width: 160,
      },
    ],
  }

  return (
    <GenericNotificationTable
      rows={RowNotifications}
      request={notificationsProbationRequests(employeeId)}
      refreshStats={refreshStats}
      type={type}
      filterBy={filterBy}
      tableRef={tableRef}
    />
  )
}

export default Reviews
