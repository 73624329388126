import React from 'react'

import { Text, Flex, Widget, MoreBar } from '@revolut/ui-kit'
import { navigateTo } from '@src/actions/RouterActions'
import { ROUTES } from '@src/constants/routes'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { EngagementSurveyInterface } from '@src/interfaces/engagement'
import { EngagementEditStep } from '@src/pages/Performance/Engagement/types'
import { pathToUrl } from '@src/utils/router'
import { Timing } from '../summaryComponents/Timing'
import { Questions } from '../summaryComponents/Questions'
import { Audience } from '../summaryComponents/Audience'
import { Notification } from '../summaryComponents/Notification'
import { isOnboardingPath } from '@src/pages/OnboardingChecklistV2/common/helpers'
import { useTestSurvey } from '../hooks/useTestSurvey'

export const Review = () => {
  const { values } = useLapeContext<EngagementSurveyInterface>()
  const { runTestSurvey, isPending } = useTestSurvey({
    surveyId: values.id,
    preSubmit: false,
  })
  const isOnboarding = isOnboardingPath()

  const onEdit = async (step?: EngagementEditStep) => {
    if (!values?.id) {
      return
    }
    navigateTo(
      pathToUrl(
        isOnboarding
          ? ROUTES.ONBOARDING_CHECKLIST_V2.ENGAGEMENT.CUSTOMISE
          : ROUTES.FORMS.ENGAGEMENT.EDIT,
        { step, id: values?.id },
      ),
    )
  }
  return (
    <>
      <Widget p="s-16">
        <Flex flexDirection="column" width="100%" gap="s-16">
          <Text variant="h5">{values.title}</Text>
          <MoreBar>
            <MoreBar.Action disabled={isPending} useIcon="Play" onClick={runTestSurvey}>
              Preview survey
            </MoreBar.Action>
          </MoreBar>
        </Flex>
      </Widget>
      <Timing {...values} onEdit={() => onEdit('basic')} />
      <Questions {...values} onEdit={() => onEdit('questions')} />
      <Audience {...values} onEdit={() => onEdit('audience')} />
      <Notification {...values} onEdit={() => onEdit('notifications')} />
    </>
  )
}
