import React, { useEffect } from 'react'
import { Flex, MoreBar, Text, TableWidget, DataPoint, Token } from '@revolut/ui-kit'
import { BarChart, Pencil } from '@revolut/icons'

import { useTable, UseTableOptions } from '@components/Table/hooks'
import { FilterByInterface, RowInterface } from '@src/interfaces/data'
import { KpiInterface } from '@src/interfaces/kpis'
import { kpisRequests } from '@src/api/kpis'
import { getPercentColor } from '@components/ColumnInserts/ColoredPercent/ColoredPercent'
import { Statuses } from '@src/interfaces'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import {
  kpiCalibratedPerformanceColumn,
  kpiCurrentValueColumn,
  kpiGenericNameColumn,
  kpiInitialValueColumn,
  kpiParentColumn,
  kpiPerformanceColumn,
  kpiStatusColumn,
  kpiStrategyColumn,
  kpiTargetColumn,
  kpiUnitColumn,
  kpiUpdateTypeColumn,
  kpiWeightColumn,
} from '@src/constants/columns/kpi'
import { useSelector } from 'react-redux'
import { canAddTeamKpi } from '@src/store/auth/selectors'
import {
  CycleFilter,
  CycleFilterType,
} from '@components/Inputs/Filters/FilterSelect/CycleFilter/CycleFilter'
import { formatPercentage } from '@src/utils/format'
import { PermissionTypes } from '@src/store/auth/types'
import { EmployeeInterface } from '@src/interfaces/employees'
import AdjustableTable from '@components/Table/AdjustableTable'
import Stat from '@components/Stat/Stat'
import Tooltip from '@components/Tooltip/Tooltip'
import GraphIconChart from '@components/Charts/GraphIconChart/GraphIconChart'
import { getKpiPerformanceGraph } from '@src/api/employees'
import { useQuery } from '@src/utils/queryParamsHooks'
import { useGetPerformanceSelector } from '@src/api/performance'
import { FilterSelectType } from '@components/Inputs/Filters/FilterSelect/FilterSelect'
import { TableNames } from '@src/constants/table'
import { onKPITableRowClick, setCurrentCycleFilter, useGetCycleSelector } from './utils'
import { rowHighlight } from '@src/features/KPI'
import { useRenderRefreshKPIsTableNote } from '@src/utils/kpi'
import { KpiHelpButton } from '@src/features/KPI/KpiHelpButton'
import { useIsNewLayout } from '@src/pages/EmployeeProfile/Layout/helpers'
import { PerformanceLayoutCycleFilter } from '@components/Inputs/Filters/FilterSelect/CycleFilter/PerformanceLayoutCycleFilter'
import { getKPIFormInitialValues } from '@src/pages/Forms/KpiForm/General'

interface StructureProps {
  data: EmployeeInterface
  navigation?: React.ReactElement
}

export const ROW: RowInterface<KpiInterface> = {
  highlight: data => rowHighlight(data.target_status),
  disabled: data => data.target_status === Statuses.archived,
  cells: [
    {
      ...kpiGenericNameColumn,
      width: 400,
    },
    {
      ...kpiWeightColumn,
      width: 110,
    },
    {
      ...kpiPerformanceColumn,
      width: 120,
    },
    {
      ...kpiCalibratedPerformanceColumn,
      width: 100,
    },
    {
      ...kpiInitialValueColumn,
      width: 90,
    },
    {
      ...kpiCurrentValueColumn,
      width: 90,
    },
    {
      ...kpiTargetColumn,
      width: 90,
    },
    {
      ...kpiUnitColumn,
      width: 90,
    },
    {
      ...kpiStrategyColumn,
      width: 90,
    },
    {
      ...kpiParentColumn,
      width: 80,
    },
    {
      ...kpiUpdateTypeColumn,
      width: 120,
    },
    {
      ...kpiStatusColumn,
      width: 80,
    },
  ],
}

export const usePersonalKPITable = (
  data: EmployeeInterface,
  options?: UseTableOptions,
  filters: FilterByInterface[] = [],
  skipStatusFilter?: boolean,
  goalsEnabled?: boolean,
) => {
  const initialFilter = [
    {
      columnName: 'goals_related',
      filters: [
        { id: goalsEnabled ? 'true' : 'false', name: goalsEnabled ? 'true' : 'false' },
      ],
      nonResettable: true,
    },
    ...(skipStatusFilter
      ? []
      : [
          {
            filters: [
              { name: Statuses.active, id: Statuses.active },
              { name: Statuses.draft, id: Statuses.draft },
              { name: 'new', id: 'new' },
            ],
            columnName: 'status',
          },
        ]),
    {
      filters: [{ name: 'True', id: 'True' }],
      columnName: 'is_employee',
      nonResettable: true,
    },
    {
      filters: [{ name: data.id.toString(), id: data.id }],
      columnName: 'owner__id',
      nonResettable: true,
      nonInheritable: true,
    },
    {
      filters: [
        { name: Statuses.pending, id: Statuses.pending },
        { name: Statuses.approved, id: Statuses.approved },
        { name: Statuses.future, id: Statuses.future },
        { name: Statuses.completed, id: Statuses.completed },
      ],
      columnName: 'target_status',
      nonResettable: true,
    },
    ...filters,
  ]

  return useTable<KpiInterface>(kpisRequests, initialFilter, [], options)
}

const PersonalKPI = ({ data, navigation }: StructureProps) => {
  const { query, deleteQueryParam } =
    useQuery<{ cycle__id: string; review_cycle__offset?: string }>()
  const isNewLayout = useIsNewLayout()
  const performanceCycleSelector = useGetPerformanceSelector(data.id)
  const table = usePersonalKPITable(data, { disable: !query.cycle__id })
  const canAdd = useSelector(canAddTeamKpi)

  useEffect(() => {
    if (!performanceCycleSelector?.data || query.cycle__id !== undefined) {
      return
    }

    setCurrentCycleFilter(performanceCycleSelector.data, table.onFilterChange)
  }, [performanceCycleSelector?.data])

  useEffect(() => {
    if (query.review_cycle__offset !== undefined) {
      const cycleId = performanceCycleSelector.data?.find(
        item => item.id === query.review_cycle__offset?.split(',')?.[0],
      )?.id

      deleteQueryParam('review_cycle__offset')

      if (cycleId !== undefined) {
        table.onFilterChange({
          filters: [{ name: String(cycleId), id: String(cycleId) }],
          columnName: 'cycle__id',
        })
      }
    }
  }, [query.review_cycle__offset, performanceCycleSelector.data, table])

  const cycleSelector = useGetCycleSelector(performanceCycleSelector?.data)

  const canChangeKpiWeight = data.field_options.permissions?.includes(
    PermissionTypes.ChangeKpiWeight,
  )

  const canRefreshKpis = data.field_options.permissions?.includes(
    PermissionTypes.CanRefreshKpis,
  )

  const { refreshNote, refreshButton } = useRenderRefreshKPIsTableNote(
    table,
    canRefreshKpis,
  )

  const handleEditKPIs = () => {
    const baseUrl = pathToUrl(ROUTES.FORMS.ASSIGN_KPIS.PERSONAL, { id: data.id })
    const cycle = performanceCycleSelector.data?.find(
      item => String(item.id) === String(query.cycle__id?.split(',')?.[0]),
    )

    if (cycle?.category === 'probation') {
      navigateTo(
        pathToUrl(ROUTES.FORMS.PROBATION_OVERVIEW.PROBATION_GOALS, {
          employeeId: data.id,
          cycleId: cycle.id,
        }),
      )
      return
    }

    const offset = cycle?.offset || 0
    navigateTo(`${baseUrl}?review_cycle__offset=${offset}`)
  }

  const handleNewRow = () => {
    navigateTo(pathToUrl(ROUTES.FORMS.KPI.GENERAL, {}), {
      initialValues: getKPIFormInitialValues(
        {
          id: data.id,
          name: data.full_name,
          team: {
            // @ts-ignore FIXME: REVPI-19 support optional team value
            id: data.team.id,
            // @ts-ignore FIXME: REVPI-19 support optional team value
            name: data.team.name,
          },
        },
        { is_employee: true },
      ),
    })
  }

  return (
    <TableWidget>
      <TableWidget.Info>
        <DataPoint>
          <Stat
            label="Progress"
            val={
              table?.count !== undefined ? (
                <Flex>
                  <Tooltip
                    text="Overall progress is calculated as the weighted average of all approved KPIs."
                    placement="right"
                  >
                    <Text
                      use="div"
                      mr="5px"
                      color={getPercentColor((table?.stats?.avg_performance || 0) * 100)}
                    >
                      {formatPercentage(table?.stats?.avg_performance || 0)}
                    </Text>
                  </Tooltip>
                  <GraphIconChart
                    id={data.id}
                    vertical="right"
                    fetchData={getKpiPerformanceGraph}
                  >
                    <Flex pr="s-12">
                      <BarChart
                        cursor="pointer"
                        size={16}
                        color={Token.color.greyTone20}
                      />
                    </Flex>
                  </GraphIconChart>
                </Flex>
              ) : undefined
            }
          />
        </DataPoint>
        <DataPoint>
          {isNewLayout ? (
            <PerformanceLayoutCycleFilter
              onFilterChange={table.onFilterChange}
              columnName="cycle__id"
              filter={table.filterBy}
              selector={cycleSelector}
            />
          ) : (
            <CycleFilter
              type={CycleFilterType.NewUI}
              onFilterChange={table.onFilterChange}
              columnName="cycle__id"
              filter={table.filterBy}
              selector={cycleSelector}
              filterInputType={FilterSelectType.SingleSelect}
            />
          )}
        </DataPoint>
      </TableWidget.Info>
      <TableWidget.Actions>
        <MoreBar>
          {canAdd && (
            <MoreBar.Action onClick={handleNewRow} useIcon="Plus">
              Add new KPI
            </MoreBar.Action>
          )}
          {canChangeKpiWeight && (
            <MoreBar.Action onClick={handleEditKPIs} useIcon={Pencil}>
              Edit KPIs
            </MoreBar.Action>
          )}
          {refreshButton}
          <KpiHelpButton />
        </MoreBar>
      </TableWidget.Actions>
      {navigation && (
        <TableWidget.Search>
          <Flex justifyContent="flex-end">{navigation}</Flex>
        </TableWidget.Search>
      )}
      <TableWidget.Table>
        <AdjustableTable<KpiInterface>
          name={TableNames.EmployeePersonalKPIs}
          useWindowScroll
          dataType="KPI"
          row={ROW}
          {...table}
          noDataMessage="Please add your personal KPIs."
          onRowClick={onKPITableRowClick}
        />
        {refreshNote}
      </TableWidget.Table>
    </TableWidget>
  )
}

export default PersonalKPI
