import React, { useEffect, useState } from 'react'
import { Item, Subheader, Avatar, Color, Group, Box, chain, Text } from '@revolut/ui-kit'
import { Check, Document, SandWatch } from '@revolut/icons'
import { useParams, useRouteMatch } from 'react-router-dom'
import { capitalize } from 'lodash'

import { downloadDocusignEmbedded, getDocusignInfo } from '@src/api/documents'
import Loader from '@src/components/CommonSC/Loader'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { PageBody } from '@src/components/Page/PageBody'
import { PageWrapper } from '@src/components/Page/Page'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { FormPreview } from '@src/components/FormPreview/FormPreview'
import { DocusignDocumentInterface } from '@src/interfaces/documents'
import { PermissionTypes } from '@src/store/auth/types'
import NewLoadingButton from '@src/components/Button/NewLoadingButton'
import { getStatusColor } from '@src/components/CommonSC/General'
import { formatDate } from '@src/utils/format'
import UserWithAvatar from '@src/components/UserWithAvatar/UserWithAvatar'
import { Statuses } from '@src/interfaces'

const Docusign = () => {
  const params = useParams<{ id: string; employeeId: string }>()

  const [data, setData] = useState<DocusignDocumentInterface>()

  const isOnboardingUser = !!useRouteMatch(ROUTES.ONBOARDING.ANY)

  useEffect(() => {
    getDocusignInfo(params.id).then(response => setData(response.data))
  }, [])

  if (!data) {
    return (
      <PageWrapper>
        <Loader />
      </PageWrapper>
    )
  }

  const canDownload =
    data?.status === 'completed' &&
    data?.field_options?.permissions?.includes?.(
      PermissionTypes.DownloadDocusignenveloperecipients,
    )

  const subtitle = (() => {
    if (isOnboardingUser && data.status === Statuses.pending) {
      return 'We have sent this document to your email address. To sign it, please check your inbox.'
    }

    if (isOnboardingUser) {
      return null
    }

    return <UserWithAvatar {...data.employee} asText />
  })()

  return (
    <PageWrapper>
      <PageHeader
        title={chain(
          'DocuSign document',
          <Text color={getStatusColor(data.status)}>{capitalize(data.status)}</Text>,
        )}
        subtitle={subtitle}
        backUrl={pathToUrl(ROUTES.FORMS.EMPLOYEE.DOCUMENTS, { id: params.employeeId })}
      />

      <PageBody>
        <Subheader variant="nested">
          <Subheader.Title>Document</Subheader.Title>
        </Subheader>
        <Item>
          <Item.Avatar>
            <Avatar useIcon={Document}>
              {data.status === 'completed' ? (
                <Avatar.Badge useIcon={Check} bg={Color.GREEN} />
              ) : (
                <Avatar.Badge useIcon={SandWatch} bg={Color.WARNING} />
              )}
            </Avatar>
          </Item.Avatar>
          <Item.Content>
            <Item.Title>{data.envelope.email_subject}</Item.Title>
          </Item.Content>
          {canDownload && (
            <Item.Side>
              <NewLoadingButton
                onSubmit={() => downloadDocusignEmbedded(data?.envelope?.id)}
              >
                Download
              </NewLoadingButton>
            </Item.Side>
          )}
        </Item>

        <Box mt="s-16">
          <FormPreview title="Details" data={data}>
            <Group>
              {isOnboardingUser ? (
                <FormPreview.Item title="Issued by" field="sender.name" />
              ) : (
                <FormPreview.Item<DocusignDocumentInterface>
                  title="Issued by"
                  field="sender.name"
                  to={document =>
                    pathToUrl(ROUTES.FORMS.EMPLOYEE.PROFILE, { id: document.sender.id })
                  }
                />
              )}
              <FormPreview.Item title="Sent date" field="sent_date_time" type="date" />
              <FormPreview.Item<DocusignDocumentInterface>
                title="Signed on"
                insert={document => {
                  if (document.signed_date_time) {
                    return formatDate(document.signed_date_time)
                  }
                  return 'Not signed yet'
                }}
              />
            </Group>
          </FormPreview>
        </Box>
      </PageBody>
    </PageWrapper>
  )
}

export default Docusign
