import React, { useMemo } from 'react'
import styled from 'styled-components'
import { LinesData } from '@components/MultiLineChart/Chart/types'
import { scaleLinear } from '@visx/scale'
import { HStack, Text, Token } from '@revolut/ui-kit'
import {
  getNormalizedYAxisPoints,
  getYPointsByIndex,
} from '@components/MultiLineChart/Chart/helpers'

const LEGEND_LABEL_HEIGHT = 10
const LEGEND_TEXT_LINE_HEIGHT = LEGEND_LABEL_HEIGHT * 2

const LegendLabel = styled.div<{ color?: string }>(({ color }) => ({
  height: `${LEGEND_LABEL_HEIGHT}px`,
  width: `${LEGEND_LABEL_HEIGHT}px`,
  minWidth: `${LEGEND_LABEL_HEIGHT}px`,
  background: color,
  borderRadius: '3px',
}))

export const LegendContainer = styled.div<{ top: number }>(({ top }) => ({
  position: 'absolute',
  right: 0,
  top,
}))

type Props<T> = {
  linesData: LinesData<T>
  yScale: ReturnType<typeof scaleLinear>
  alignLegendItemsToLines?: boolean
}
export const Legend = <T,>({ linesData, yScale, alignLegendItemsToLines }: Props<T>) => {
  const dataLength = linesData[0]?.data?.length

  const points = useMemo(() => {
    if (!dataLength) {
      return []
    }
    const lastIdxPoints = getYPointsByIndex(linesData, yScale, dataLength - 1).sort(
      (point, prevPoint) => point.top - prevPoint.top,
    )
    if (alignLegendItemsToLines) {
      return getNormalizedYAxisPoints(lastIdxPoints, LEGEND_TEXT_LINE_HEIGHT)
    }
    return lastIdxPoints.map(point => ({ ...point, top: 0 }))
  }, [linesData, yScale])

  if (!points.length) {
    return null
  }
  return (
    <>
      {points.map(point => {
        return (
          <HStack key={point.name} space="s-4" align="center" mt={`${point.top}px`}>
            <LegendLabel color={point.color} />
            <Text
              lineHeight={`${LEGEND_TEXT_LINE_HEIGHT}px`}
              variant="small"
              color={Token.color.greyTone50}
            >
              {point.name}
            </Text>
          </HStack>
        )
      })}
    </>
  )
}
