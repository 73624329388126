import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { connect } from 'lape'
import {
  MoreBar,
  VStack,
  Text,
  Token,
  Box,
  Group,
  useStatusPopup,
  StatusPopup,
} from '@revolut/ui-kit'

import {
  archiveWorkScheduleCustomApprovers,
  workScheduleCustomApproversRequests,
} from '@src/api/workSchedule'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { PageBody } from '@src/components/Page/PageBody'
import { PageWrapper } from '@src/components/Page/Page'
import { ROUTES } from '@src/constants/routes'
import Form from '@src/features/Form/Form'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { WorkScheduleCustomApprovalFlowInterface } from '@src/interfaces/workSchedule'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { pathToUrl } from '@src/utils/router'
import { CustomApproversEligibilityTable } from '../components/EligibilityTable'
import { FormPreview } from '@src/components/FormPreview/FormPreview'

const Preview = connect(() => {
  const params = useParams<{ id: string }>()
  const { initialValues } = useLapeContext<WorkScheduleCustomApprovalFlowInterface>()

  const [archivePending, setArchivePending] = useState(false)

  const statusPopup = useStatusPopup()

  return (
    <PageWrapper>
      <PageHeader
        title={initialValues.name}
        backUrl={ROUTES.APPS.TIME_MANAGEMENT.WORK_SCHEDULES_CUSTOM_APPROVERS.LIST}
      />
      <PageBody>
        <VStack space="s-24">
          <MoreBar>
            <MoreBar.Action
              useIcon="Pencil"
              use={InternalLink}
              to={pathToUrl(
                ROUTES.APPS.TIME_MANAGEMENT.WORK_SCHEDULES_CUSTOM_APPROVERS.EDIT.DETAILS,
                params,
              )}
            >
              Edit
            </MoreBar.Action>
            <MoreBar.Action
              useIcon="Archive"
              pending={archivePending}
              variant="negative"
              onClick={async () => {
                if (!initialValues.id) {
                  return
                }
                try {
                  setArchivePending(true)
                  await archiveWorkScheduleCustomApprovers(initialValues.id)
                } catch (e) {
                  statusPopup.show(
                    <StatusPopup variant="error">
                      <StatusPopup.Title>Something went wrong</StatusPopup.Title>
                      <StatusPopup.Description>
                        Please refresh the page or try again later
                      </StatusPopup.Description>
                    </StatusPopup>,
                  )
                } finally {
                  setArchivePending(false)
                }
              }}
            >
              Archive
            </MoreBar.Action>
          </MoreBar>
          <FormPreview title="General" data={initialValues}>
            <Group>
              <FormPreview.Item title="Name" field="name" />
              <FormPreview.Item
                title="Created on"
                field="creation_date_time"
                type="dateTime"
              />
            </Group>
          </FormPreview>
          <Box>
            <Text variant="h6" color={Token.color.greyTone50} use="div" my="s-12">
              Eligible employees
            </Text>
            <CustomApproversEligibilityTable />
          </Box>
        </VStack>
      </PageBody>
    </PageWrapper>
  )
})

export const PreviewWorkScheduleCustomApprovers = () => (
  <Form api={workScheduleCustomApproversRequests}>
    <Preview />
  </Form>
)
