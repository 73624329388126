import styled from 'styled-components'
import { Token } from '@revolut/ui-kit'

export const TooltipContainer = styled.div`
  background-color: ${Token.color.foreground};
  border-radius: ${Token.radius.r16};
  padding: ${Token.space.s8};
  color: ${Token.color.background};
  opacity: 0.9;
`
