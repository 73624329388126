import React, { useState } from 'react'
import { Calendar, Input, InputGroup, Popup } from '@revolut/ui-kit'
import { EmployeeOptionInterface } from '@src/interfaces/employees'
import { formatDate } from '@src/utils/format'
import { selectorKeys } from '@src/constants/api'
import TimezoneSelect from '@src/pages/Forms/Candidate/ScheduleSidebar/TimezoneSelect'
import { css } from 'styled-components'
import { ScheduleSidebarModeType } from '@src/interfaces/interviewTool'
import {
  CustomDate,
  getTimeZoneId,
  isBeforeCurrent,
  parseDay,
} from '@src/pages/Forms/Candidate/ScheduleSidebar/utils'
import { getDate } from '@src/utils/timezones'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { SendCandidateEmailInterface } from '@src/interfaces/hiringProccess'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import LapeNewMultiSelect from '@components/Inputs/LapeFields/LapeNewMultiSelect'
import LapeSingleCheckbox from '@components/Inputs/LapeFields/LapeSingleCheckbox'

type Props = {
  mode: ScheduleSidebarModeType
}

const TimeInputCss = css`
  input::-webkit-calendar-picker-indicator {
    position: relative;
    top: -8px;
  }
`

const EditManualDate = ({ mode }: Props) => {
  const { values } = useLapeContext<SendCandidateEmailInterface>()
  const timeZoneId = getTimeZoneId(values.scheduling_timezone)
  const [isOpen, setOpen] = useState(false)
  const onChangeCustomDate = (
    newCustomDate: CustomDate,
    newTimezoneId: string = timeZoneId,
  ) => {
    values.custom_date = {
      ...newCustomDate,
      timeError: isBeforeCurrent(newCustomDate, newTimezoneId)
        ? 'The time is in the past'
        : '',
    }
  }
  return (
    <InputGroup>
      <LapeRadioSelectInput<EmployeeOptionInterface>
        label="Lead interviewer"
        name="interviewer"
        selector={selectorKeys.employee}
        data-testid="lead-select"
      />
      <LapeNewMultiSelect<EmployeeOptionInterface>
        name="additional_interviewers"
        placeholder="Additional interviewers"
        selector={selectorKeys.employee}
        variant="grey"
      />
      {mode !== 'editing' && (
        <LapeSingleCheckbox name="is_candidate_involved" label="Invite candidate" />
      )}
      <TimezoneSelect
        onChangeTimeZone={newTimezone => {
          if (values.custom_date) {
            onChangeCustomDate(values.custom_date, newTimezone?.id)
          }
        }}
      />
      <InputGroup variant="horizontal">
        <Input
          readOnly
          label="Date"
          onClick={() => setOpen(true)}
          value={
            values.custom_date?.day ? formatDate(parseDay(values.custom_date.day)) : ''
          }
          data-testid="day-input"
        />
        <Input
          label="Time"
          placeholder="--:--"
          type="time"
          value={values.custom_date?.time}
          errorMessage={values.custom_date?.timeError}
          aria-invalid={!!values.custom_date?.timeError}
          onChange={e => {
            onChangeCustomDate({
              ...values.custom_date,
              time: e.currentTarget.value,
            })
          }}
          data-testid="time-input"
          css={TimeInputCss}
        />
        <Popup open={isOpen} onClose={() => setOpen(false)} variant="bottom-sheet">
          <Calendar
            value={values.custom_date?.day ? parseDay(values.custom_date.day) : undefined}
            onChange={value => {
              onChangeCustomDate({
                ...values.custom_date,
                day: value ? getDate(value) : undefined,
              })
              setOpen(false)
            }}
            labelEmptyValue="Select date"
            labelButtonClear="Clear"
          />
        </Popup>
      </InputGroup>
    </InputGroup>
  )
}

export default EditManualDate
