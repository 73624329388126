import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { ROUTES } from '@src/constants/routes'
import { PageWrapper } from '@src/components/Page/Page'
import { NoAccessError } from '@src/features/Form/FormErrorGuard'
import { AttributesSettings } from '@src/pages/Settings/Employees/Attributes'
import { GeneralSettings } from '@src/pages/Settings/Employees/General'
import { ProfileSettings } from '@src/pages/Settings/Employees/Profile'
import { selectFeatureFlags, selectPermissions } from '@src/store/auth/selectors'
import { FeatureFlags, PermissionTypes } from '@src/store/auth/types'
import { DataRetention } from './DataRetention'
import { Onboarding } from './Onboarding'

const routes = [
  {
    path: ROUTES.SETTINGS.EMPLOYEES.GENERAL,
    url: ROUTES.SETTINGS.EMPLOYEES.GENERAL,
    canView: [
      PermissionTypes.ViewEmployeePreferences,
      PermissionTypes.ChangeEmployeePreferences,
    ],
    component: GeneralSettings,
  },
  {
    path: ROUTES.SETTINGS.EMPLOYEES.PROFILE,
    url: ROUTES.SETTINGS.EMPLOYEES.PROFILE,
    canView: [PermissionTypes.ViewEmployeePreferences],
    component: ProfileSettings,
  },
  {
    path: ROUTES.SETTINGS.EMPLOYEES.ATTRIBUTES,
    url: ROUTES.SETTINGS.EMPLOYEES.ATTRIBUTES,
    canView: [PermissionTypes.ViewCustomField],
    component: AttributesSettings,
  },
  {
    path: ROUTES.SETTINGS.EMPLOYEES.DATA_RETENTION,
    url: ROUTES.SETTINGS.EMPLOYEES.DATA_RETENTION,
    canView: [PermissionTypes.ViewDataRetentionPeriod],
    component: DataRetention,
  },
  {
    path: ROUTES.SETTINGS.EMPLOYEES.ONBOARDING,
    url: ROUTES.SETTINGS.EMPLOYEES.ONBOARDING,
    canView: [PermissionTypes.HRManagerPermissions],
    featureFlags: [FeatureFlags.EmployeeOnboardingV2],
    component: Onboarding,
  },
]

export const EmployeeSettings = () => {
  const permissions = useSelector(selectPermissions)
  const featureFlags = useSelector(selectFeatureFlags)

  const filteredRoutes = routes.filter(
    route =>
      route.canView.some(permission => permissions.includes(permission)) &&
      (route.featureFlags
        ? route.featureFlags.some(featureFlag => featureFlags.includes(featureFlag))
        : true),
  )

  return (
    <PageWrapper>
      <Switch>
        {filteredRoutes.map(route => {
          return (
            <Route exact key={route.path} path={route.path}>
              <route.component />
            </Route>
          )
        })}
        <Route>
          <NoAccessError />
        </Route>
      </Switch>
    </PageWrapper>
  )
}
