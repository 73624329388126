import React from 'react'
import Section from '@components/CommonSC/Section'
import { Action, InputGroup } from '@revolut/ui-kit'
import { selectorKeys } from '@src/constants/api'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { AutomationInterface } from '@src/interfaces/jobPostings/automation'
import {
  ApplicationFormEnumQuestionIds,
  ApplicationFormSectionInterface,
} from '@src/interfaces/applicationForm'
import { connect } from 'lape'
import LapeNewMultiSelect from '@components/Inputs/LapeFields/LapeNewMultiSelect'
import RadioSelectInput from '@components/Inputs/RadioSelectInput/RadioSelectInput'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'

const VALID_QUESTION_TYPES = [
  ApplicationFormEnumQuestionIds.Option,
  ApplicationFormEnumQuestionIds.Checkbox,
  ApplicationFormEnumQuestionIds.Dropdown,
]

const MULTI_INPUT_QUESTIONS = [ApplicationFormEnumQuestionIds.Checkbox]
const NO_ANSWER_CONDITIONS = ['empty', 'not_empty']

type Props = {
  idx: number
  sections: ApplicationFormSectionInterface[]
  onDelete: (idx: number) => void
}

const ConditionSection = ({ idx, sections, onDelete }: Props) => {
  const { values, errors } = useLapeContext<AutomationInterface>()

  const sectionId = values.conditions?.[idx]?.section?.id
  const questionId = values.conditions?.[idx]?.question?.id

  const questions =
    sections
      .find(item => item.id === sectionId)
      ?.questions?.filter(question =>
        VALID_QUESTION_TYPES.includes(question.question_type.id),
      ) || []

  const answers =
    sections
      .find(item => item.id === sectionId)
      ?.questions?.find(question => question.id === questionId)?.options || []

  const questionType = questions.find(
    item => item.id === values.conditions?.[idx]?.question?.id,
  )?.question_type?.id

  const conditionId = values.conditions[idx].condition?.id

  const noAnswerNeeded = !!conditionId && NO_ANSWER_CONDITIONS.includes(conditionId)

  const isMultiInputQuestion =
    questionType && MULTI_INPUT_QUESTIONS.includes(questionType)

  const getAnswerInputMessage = () => {
    if (errors?.conditions?.[idx]?.answers) {
      return errors.conditions[idx]!.answers
    }

    if (noAnswerNeeded) {
      return 'There is no need to define the answer for this condition'
    }

    if (isMultiInputQuestion) {
      if (conditionId === 'equal') {
        return 'Multiple answers will be joined with a "OR" condition'
      }

      if (conditionId === 'not_equal') {
        return 'Multiple answers will be joined with a "AND" condition'
      }
    }

    return undefined
  }

  const commonAnswerProps = {
    disabled: noAnswerNeeded,
    message: getAnswerInputMessage(),
    options: answers.map(item => ({
      label: item.text!,
      value: {
        text: item.text,
        id: item.id!,
      },
    })),
    hasError: !!errors?.conditions?.[idx]?.answers,
  }

  return (
    <Section key={idx}>
      <Section.Title
        actions={
          values.conditions.length > 1 ? (
            <Action onClick={() => onDelete(idx)}>Delete</Action>
          ) : null
        }
      >
        {idx > 0 ? 'And check if' : 'Check if'}
      </Section.Title>
      <InputGroup>
        <LapeRadioSelectInput
          label="Select section"
          name={`conditions.${idx}.section`}
          options={sections.map(item => ({
            label: item.title!,
            value: {
              name: item.title,
              id: item.id!,
            },
          }))}
          onAfterChange={() => {
            values.conditions[idx].question = undefined
            values.conditions[idx].answers = undefined
          }}
        />
        <LapeRadioSelectInput
          label="Select question"
          name={`conditions.${idx}.question`}
          options={questions.map(item => ({
            label: item.title!,
            value: {
              name: item.title,
              id: item.id!,
            },
          }))}
          onAfterChange={() => {
            values.conditions[idx].answers = undefined
          }}
        />
        <LapeRadioSelectInput
          label="Select condition"
          name={`conditions.${idx}.condition`}
          selector={selectorKeys.automation_rule_condition}
          onAfterChange={() => {
            values.conditions[idx].answers = undefined
          }}
        />
        {isMultiInputQuestion ? (
          <LapeNewMultiSelect
            placeholder="Select answers"
            name={`conditions.${idx}.answers`}
            required
            {...commonAnswerProps}
          />
        ) : (
          <RadioSelectInput
            label="Select answer"
            value={values.conditions[idx].answers?.[0] || null}
            onChange={val => {
              values.conditions[idx].answers = val ? [val] : undefined
            }}
            inputProps={
              {
                'data-name': `conditions.${idx}.answers`,
              } as React.HTMLAttributes<HTMLInputElement>
            }
            {...commonAnswerProps}
          />
        )}
      </InputGroup>
    </Section>
  )
}

export default connect(ConditionSection)
