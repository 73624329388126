import React from 'react'
import { TalentType } from '@src/interfaces/talent/talent'
import { useGetEnableDownloadSetting } from '@src/utils/settings'
import ExportMenu, { ExportMenuProps } from './ExportMenu'

interface AllowedExportMenuProps extends ExportMenuProps {
  type: TalentType
}

export const AllowedExportMenu = ({ type, ...props }: AllowedExportMenuProps) => {
  const canDownload = useGetEnableDownloadSetting(type)
  return canDownload ? <ExportMenu {...props} /> : null
}
