export enum OnboardingSendEmailAction {
  Welcome = 'welcome',
  Day1Instructions = 'day-1-instructions',
  FollowUp = 'follow-up',
}

export const OnboardingSendEmailCategories = {
  [OnboardingSendEmailAction.Welcome]: {
    id: 'onboarding_welcome_email',
    name: 'Onboarding welcome email',
  },
  [OnboardingSendEmailAction.Day1Instructions]: {
    id: 'onboarding_day_one_instructions',
    name: 'Onboarding day 1 instructions',
  },
  [OnboardingSendEmailAction.FollowUp]: null,
}
