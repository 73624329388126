import React, { useEffect, useState } from 'react'
import { Route, Switch, useParams } from 'react-router-dom'

import { Flex, VStack, Item, Image, StatusWidget, Box } from '@revolut/ui-kit'
import { pathToUrl } from '@src/utils/router'
import TabBarNavigation from '@src/features/TabBarNavigation/TabBarNavigation'
import { EngagementResultsScope } from '@src/interfaces/engagement'
import useFetchOptions from '@components/Inputs/hooks/useFetchOptions'
import { FieldOptions, IdAndName } from '@src/interfaces'
import { selectorKeys } from '@src/constants/api'
import { PermissionTypes } from '@src/store/auth/types'
import { Feedback } from './Feedback'
import { Drivers } from './Drivers'
import { Questions } from './Questions'
import { useQueryParams } from '@src/apps/People/Engagement/Results/hooks'
import { QueryParams } from '@src/apps/People/Engagement/Results/constants'
import { useIsNewLayout } from '@src/pages/EmployeeProfile/Layout/helpers'
import { InternalRedirect } from '@src/components/InternalLink/InternalRedirect'

export const EngagementResults = <T extends { id: number; field_options: FieldOptions }>({
  data,
  scope,
  driversPath,
  questionsPath,
  feedbackPath,
}: {
  data: T
  driversPath: string
  questionsPath: string
  feedbackPath: string
  scope?: EngagementResultsScope
}) => {
  const params = useParams<{ id: string; subtab: 'drivers' | 'questions' | 'feedback' }>()

  const [survey, setSurvey] = useState<IdAndName>()
  const [round, setRound] = useState<IdAndName>()
  const [hasAnonymisedData, setHasAnonymisedData] = useState(false)
  const isNewLayout = useIsNewLayout()

  const { query, updateQueryParams } = useQueryParams({ survey, round })

  const surveysOptionsData = useFetchOptions<IdAndName>(
    selectorKeys.engagement_survey_titles_sorted,
  )
  const surveysOptions = surveysOptionsData.options.map(({ value }) => value)
  const { asyncState: surveysOptionsAsyncState } = surveysOptionsData

  useEffect(() => {
    if (surveysOptionsAsyncState !== 'ready' || !surveysOptions[0]) {
      return
    }
    const surveyIdFromQuery = query[QueryParams.survey]
    const defaultOption =
      surveysOptions.find(
        surveyOption => String(surveyOption.id) === surveyIdFromQuery,
      ) || surveysOptions[0]
    setSurvey(defaultOption)
  }, [surveysOptionsAsyncState])

  useEffect(() => {
    const surveyIdFromQuery = query[QueryParams.survey]
    const roundIdFromQuery = query[QueryParams.round]

    if (survey && !surveyIdFromQuery) {
      updateQueryParams({ surveyId: String(survey.id) })
    }

    if (round && !roundIdFromQuery) {
      updateQueryParams({ roundId: String(round.id) })
    }
  }, [params.subtab])

  if (!scope) {
    return null
  }

  const tabs = [
    {
      path: driversPath,
      to: pathToUrl(driversPath, params),
      title: 'Drivers',
      canView: true,
      component: Drivers,
    },
    {
      path: questionsPath,
      to: pathToUrl(questionsPath, params),
      title: 'Questions',
      canView: true,
      component: Questions,
    },
    {
      path: feedbackPath,
      to: pathToUrl(feedbackPath, params),
      title: 'Feedback',
      canView: data.field_options.permissions?.includes(
        PermissionTypes.ViewEngagementComments,
      ),
      component: Feedback,
    },
  ]

  const filteredTabs = tabs.filter(tab => tab.canView)

  const navigation = (
    <Flex alignItems="flex-start">
      <TabBarNavigation isSubtab tabs={filteredTabs} />
    </Flex>
  )
  const showNewLayoutEmployeeNavigation =
    (isNewLayout && scope === EngagementResultsScope.Employees) ||
    [EngagementResultsScope.Teams, EngagementResultsScope.Departments].includes(scope)

  return (
    <VStack space="s-16">
      {!showNewLayoutEmployeeNavigation && navigation}
      {hasAnonymisedData && (
        <Item>
          <Item.Avatar>
            <Image src="https://assets.revolut.com/assets/3d-images/3D173a.png" />
          </Item.Avatar>
          <Item.Content>
            <Item.Title>Some results are hidden to protect anonymity</Item.Title>
            <Item.Description>
              This is because there are not enough answers submitted.
              {/* TODO: Add link to Confluence page here when docs are ready */}
              {/* You can find more information about this <Link href="">here</Link>. */}
            </Item.Description>
          </Item.Content>
        </Item>
      )}
      {surveysOptions.length ? (
        <Switch>
          {filteredTabs.map(tab => (
            <Route exact path={tab.path} key={tab.path}>
              <tab.component<T>
                data={data}
                scope={scope}
                survey={survey}
                setSurvey={setSurvey}
                round={round}
                setRound={setRound}
                surveysOptions={surveysOptions}
                surveysOptionsAsyncState={surveysOptionsAsyncState}
                setHasAnonymisedData={setHasAnonymisedData}
                isNewLayout={isNewLayout}
                navigation={showNewLayoutEmployeeNavigation ? navigation : undefined}
              />
            </Route>
          ))}
          <InternalRedirect to={filteredTabs[0].path} />
        </Switch>
      ) : (
        <StatusWidget>
          {showNewLayoutEmployeeNavigation && <Box width="100%">{navigation}</Box>}
          <StatusWidget.Image
            src="https://assets.revolut.com/assets/3d-images/3D086.png"
            srcSet="https://assets.revolut.com/assets/3d-images/3D086@2x.png 2x, https://assets.revolut.com/assets/3d-images/3D086@3x.png 3x"
          />
          <StatusWidget.Title>No surveys found</StatusWidget.Title>
        </StatusWidget>
      )}
    </VStack>
  )
}
