import { TableFilter } from '@src/features/AudienceSelection/AudienceTable'
import { IdAndName } from '.'
import { EmployeeOptionInterface } from './employees'
import { ScheduleInterface } from '@src/interfaces/schedule'
import { Color } from '@revolut/ui-kit'
import { CommunicationGroupInterface } from '@src/interfaces/communicationGroup'

export enum NotificationType {
  email = 'email',
  slack = 'slack',
  sms = 'sms',
  system = 'system',
  announcement = 'announcement',
}

export enum TemplateType {
  text = 'text',
  html = 'html',
  json = 'json',
}

export enum DeliveryType {
  ad_hoc = 'ad_hoc',
  one_time = 'one_time',
  scheduled = 'scheduled',
  event_based = 'event_based',
}

export enum NotificationStatus {
  created = 'created',
  active = 'active',
  archived = 'archived',
}

export enum NotificationAction {
  url = 'url',
  text = 'text',
}

export interface SlackBotInterface {
  bot_description: string
  bot_id: string
  name: string
  id: number
}

export interface NotificationTemplateInterface extends ScheduleInterface {
  id: number
  type: { id: NotificationType; name: string }
  audience_selection_type: IdAndName<
    'filtered_table' | 'sql' | 'communication_groups' | 'none'
  >
  tenants_filters?: { id: string[] } | null
  is_for_all_tenants?: boolean
  communication_groups?: CommunicationGroupInterface[]
  table_filters: TableFilter | null
  name: string
  description?: string
  template_text: string
  template_type: { id: TemplateType; name: string }
  sql_query: string | null
  sql_query_db: IdAndName<'people_ops' | 'helios'> | null
  delivery_type: { id: DeliveryType; name: string }
  created_date_time: string
  updated_date_time: string
  last_sent_date_time: string
  status: { id: NotificationStatus; name: string }
  email_send_from?: string
  email_send_as?: string
  email_allow_external_emails?: boolean
  email_subject?: string
  slack_bot?: SlackBotInterface
  slack_send_as?: string
  slack_send_as_icon?: string
  sms_send_from?: string
  owner: EmployeeOptionInterface
  schedule?: string
  stats_all: number
  stats_success: number
  stats_fail: number
  use_new_schedule_method: boolean
  system_title?: string
  system_description?: string
  system_action_url?: string
  system_category?: string
  system_action?: NotificationAction
  announcement_action?: NotificationAction
  announcement_action_url?: string
  announcement_title?: string
  announcement_show_icon?: boolean
  announcement_pinned?: boolean
  announcement_pinned_to?: string
  announcement_color?: Color
}

export type NotificationHistoryStatus =
  | 'scheduled'
  | 'sending'
  | 'sent'
  | 'sent_with_errors'
  | 'failed'
  | 'canceling'
  | 'canceled'
  | 'cancellation_failed'

export interface NotificationSendingHistoryInterface {
  id: number
  audience_count: number
  fail_count: number
  success_count: number
  read_count: number
  dismissed_count: number
  request_id: string
  sent_by: EmployeeOptionInterface
  sent_date: string
  status: IdAndName<NotificationHistoryStatus>
}

export interface NotificationLogsInterface {
  id: number
  created_date: string
  read_date: string
  dismissed_date: string
  extra: any
  message_content: string
  recipient: string
  recipient_employee: EmployeeOptionInterface[]
  request: NotificationSendingHistoryInterface
  request_type: IdAndName<NotificationType>
  send_date: string
  status: IdAndName<
    'created' | 'scheduled' | 'sent' | 'failed' | 'canceled' | 'dismissed' | 'read'
  >
  updated_date: string
}
