import React from 'react'
import { InternalRedirect } from '@src/components/InternalLink/InternalRedirect'
import { ROUTES } from '@src/constants/routes'

import { OnboardingAppScreen } from '@src/pages/OnboardingChecklistV2/components/OnboardingAppScreen'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'

import { useSelector } from 'react-redux'
import { Route, Switch } from 'react-router-dom'
import { SurveysTable } from './components/SurveysTable'

const EngagementBase = () => {
  const permissions = useSelector(selectPermissions)
  const canView = permissions.includes(PermissionTypes.ViewEngagement)

  return (
    <Switch>
      {canView && (
        <Route exact path={ROUTES.PERFORMANCE.ENGAGEMENT.DASHBOARD}>
          <SurveysTable />
        </Route>
      )}
      <InternalRedirect to={ROUTES.PERFORMANCE.GOALS.GENERAL} />
    </Switch>
  )
}

export const Engagement = () => {
  return (
    <OnboardingAppScreen category="engagement">
      <EngagementBase />
    </OnboardingAppScreen>
  )
}
