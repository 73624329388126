import { Box, Flex, Group, Text, TextButton } from '@revolut/ui-kit'
import React from 'react'

interface GlobalSearchSectionProps {
  name: string
  children: React.ReactNode
  showMore?: () => void
}

const GlobalSearchSection = ({ name, children, showMore }: GlobalSearchSectionProps) => {
  return (
    <Box mb="s-32">
      <Flex justifyContent="space-between" alignItems="center">
        <Text
          data-testid="global-search-section-title"
          color="grey-tone-50"
          use="div"
          mb="s-16"
        >
          {name}
        </Text>
        {showMore && (
          <TextButton fontSize={14} onClick={showMore}>
            See more
          </TextButton>
        )}
      </Flex>
      <Group data-testid="global-search-section-container">{children}</Group>
    </Box>
  )
}

export default GlobalSearchSection
