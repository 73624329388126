import styled from 'styled-components'

import { CellTypes, ColumnInterface, FilterType } from '@src/interfaces/data'
import { selectorKeys } from '../api'
import { RoleInterface } from '@src/interfaces/roles'
import React from 'react'
import { Colored } from '@components/ColumnInserts/ColoredPercent/ColoredPercent'
import {
  performanceToColor,
  performanceToString,
} from '@src/features/Scorecard/constants'
import { Token } from '@revolut/ui-kit'
import { Statuses } from '@src/interfaces'

const TooltipContainer = styled.div`
  padding: 11px;
  width: 200px;
  color: ${Token.color.background};
`

const DraftWrap = styled.span`
  padding-right: 6px;
  color: ${Token.color.greyTone50};
`

export const roleGenericNameColumn: ColumnInterface<RoleInterface> = {
  type: CellTypes.insert,
  idPoint: 'id',
  dataPoint: 'name',
  sortKey: 'name',
  filterKey: 'id',
  selectorsKey: selectorKeys.role,
  title: 'Role',
  insert: ({ data }) =>
    data.status === Statuses.draft ? (
      <span>
        <DraftWrap>[Draft]</DraftWrap>
        {data.name}
      </span>
    ) : (
      data.name
    ),
}

export const roleNameColumn: ColumnInterface<{}> = {
  type: CellTypes.text,
  idPoint: 'specialisation.id',
  dataPoint: 'specialisation.name',
  sortKey: 'specialisation__name',
  filterKey: 'specialisation__id',
  selectorsKey: selectorKeys.specialisations,
  title: 'Role (Specialisation)',
}

export const specialisationRoleNameColumn: ColumnInterface<{}> = {
  type: CellTypes.text,
  idPoint: 'specialisation.id',
  dataPoint: 'specialisation.name',
  sortKey: 'specialisation__name',
  filterKey: 'specialisation__id',
  selectorsKey: selectorKeys.specialisations,
  title: 'Role (Specialisation)',
}

export const roleStatusColumn: ColumnInterface<RoleInterface> = {
  type: CellTypes.text,
  idPoint: 'status',
  dataPoint: 'status_display',
  colors: (data: RoleInterface) => {
    switch (data.status) {
      case Statuses.approved:
        return Token.color.green
      case Statuses.pending:
        return Token.color.warning
      case Statuses.draft:
        return Token.color.greyTone50
      default:
        return Token.color.foreground
    }
  },
  sortKey: 'status',
  filterKey: 'status',
  selectorsKey: selectorKeys.roles_status,
  title: 'Status',
}

export const roleSkillsColumn: ColumnInterface<RoleInterface> = {
  type: CellTypes.insert,
  idPoint: 'skills',
  dataPoint: 'skills',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Skills',
  insert: ({ data }) => {
    if (!data.skillsets) {
      return '-'
    }
    return data.skillsets.reduce((prev, curr) => {
      if (prev !== '') {
        return `${prev}, ${curr.name}`
      }
      return curr.name
    }, '')
  },
}

export const roleOwnerColumn: ColumnInterface<RoleInterface> = {
  type: CellTypes.text,
  idPoint: 'role_manager.id',
  dataPoint: 'role_manager.display_name',
  sortKey: 'role_manager__id',
  filterKey: 'role_manager__id',
  selectorsKey: selectorKeys.employee,
  title: 'Owner',
}

export const roleFunctionColumn: ColumnInterface<RoleInterface> = {
  type: CellTypes.text,
  idPoint: 'function.id',
  dataPoint: 'function.name',
  sortKey: 'function__name',
  filterKey: 'function__id',
  selectorsKey: selectorKeys.functions,
  title: 'Function',
}

export const roleRequisitionsColumn: ColumnInterface<RoleInterface> = {
  type: CellTypes.text,
  idPoint: 'requisition_headcount',
  dataPoint: 'requisition_headcount',
  sortKey: 'requisition_headcount',
  filterKey: 'requisition_headcount',
  filterType: FilterType.range,
  colors: (data: RoleInterface) =>
    data.requisition_headcount ? Token.color.foreground : Token.color.greyTone20,
  selectorsKey: selectorKeys.none,
  title: 'Hiring',
}

export const roleHeadcountColumn: ColumnInterface<RoleInterface> = {
  type: CellTypes.text,
  idPoint: 'headcount',
  dataPoint: 'headcount',
  sortKey: 'headcount',
  filterType: FilterType.range,
  filterKey: 'headcount',
  selectorsKey: selectorKeys.none,
  colors: (data: RoleInterface) =>
    data.headcount ? Token.color.foreground : Token.color.greyTone20,
  title: 'headcount',
}

export const rolePerfReviewColumn: ColumnInterface<RoleInterface> = {
  type: CellTypes.insert,
  idPoint: 'average_performance_review_label',
  dataPoint: 'average_performance_review_label',
  sortKey: 'average_performance_review_label',
  filterKey: 'average_performance_review_label',
  selectorsKey: selectorKeys.performance_score_labels,
  title: 'Perf review',
  masked: true,
  maskTooltip: {
    isShown: <TooltipContainer>Click to hide performance scores.</TooltipContainer>,
    isHidden: <TooltipContainer>Click to show performance scores.</TooltipContainer>,
  },
  headerTooltip: (
    <TooltipContainer>
      Individual score from the most recent performance review.
    </TooltipContainer>
  ),
  insert: ({ data }) => (
    <Colored color={performanceToColor(data.average_performance_review_label)}>
      {performanceToString(data.average_performance_review_label)}
    </Colored>
  ),
}
