import { Badge, Token, HStack, Text } from '@revolut/ui-kit'
import React, { ReactNode } from 'react'

export const RecommendedLabel = ({ label }: { label: ReactNode }) => (
  <HStack>
    <Text>{label}</Text>
    <Recommended />
  </HStack>
)

export const Recommended = () => {
  return (
    <Badge bg={Token.color.blue_10} color={Token.color.blue} p="s-8" ml="s-8">
      Recommended
    </Badge>
  )
}
