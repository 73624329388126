import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import { selectorKeys } from '@src/constants/api'
import {
  InterviewFeedbackStatus,
  InterviewFeedbackInterface,
} from '@src/interfaces/interviewTool'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { ColoredText } from '@src/components/CommonSC/General'
import capitalize from 'lodash/capitalize'
import { Statuses } from '@src/interfaces'
import { Token } from '@revolut/ui-kit'
import { InterviewResultIcon } from '@components/ColumnInserts/InterviewResult/InterviewResultIcon'
import Tooltip from '@components/Tooltip/Tooltip'
import { getLocationDescriptor } from '@src/actions/RouterActions'

const ViewLink = styled(Link)`
  color: ${Token.color.blue};
  text-decoration: none;

  &:hover {
    color: ${Token.color.blue_60};
  }
`

const StyledTooltip = styled(Tooltip)`
  display: inline-flex;
  justify-content: flex-start;
`

const DisabledLink = styled.span`
  color: ${Token.color.blue};
  cursor: not-allowed;
  opacity: 0.5;
`

export const interviewStatus: ColumnInterface<InterviewFeedbackInterface> = {
  type: CellTypes.insert,
  idPoint: 'status',
  dataPoint: 'status',
  sortKey: 'status_order',
  filterKey: null,
  selectorsKey: selectorKeys.none,
  insert: ({ data }) => {
    let color = Token.color.foreground
    if (
      data.status === InterviewFeedbackStatus.pending ||
      data.status === InterviewFeedbackStatus.draft
    ) {
      color = Token.color.orange
    }
    if (data.status === InterviewFeedbackStatus.completed) {
      color = Token.color.green
    }
    if (data.status === InterviewFeedbackStatus.rejected) {
      color = Token.color.greyTone50
    }
    return <ColoredText color={color}>{capitalize(data.status)}</ColoredText>
  },
  title: 'Status',
}

export const interviewCandidate: ColumnInterface<InterviewFeedbackInterface> = {
  type: CellTypes.text,
  idPoint: 'interview_stage.interview_round.candidate.full_name',
  dataPoint: 'interview_stage.interview_round.candidate.full_name',
  sortKey: 'interview_stage.interview_round.candidate.full_name',
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Candidate',
}

export const interviewRoleSeniority: ColumnInterface<InterviewFeedbackInterface> = {
  type: CellTypes.insert,
  idPoint: 'interview_stage.interview_round.role.id',
  dataPoint: 'interview_stage.interview_round.role.name',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  insert: ({ data }) => {
    if (data.interview_stage.interview_round.seniority) {
      return `${data.interview_stage.interview_round.role.name} - ${data.interview_stage.interview_round.seniority.name}`
    }

    return data.interview_stage.interview_round.role.name
  },
  title: 'Role - Seniority',
}

export const interviewNameColumn: ColumnInterface<InterviewFeedbackInterface> = {
  type: CellTypes.text,
  idPoint: 'interview_stage.title',
  dataPoint: 'interview_stage.title',
  sortKey: 'interview_stage.title',
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Stage',
}

export const interviewDateColumn: ColumnInterface<InterviewFeedbackInterface> = {
  type: CellTypes.dateTime,
  idPoint: 'scheduled_date_time',
  dataPoint: 'scheduled_date_time',
  sortKey: 'scheduled_date_time',
  filterKey: 'scheduled_date_time',
  selectorsKey: selectorKeys.none,
  title: 'Date',
}

export const interviewRatingColumn: ColumnInterface<InterviewFeedbackInterface> = {
  type: CellTypes.insert,
  idPoint: 'result',
  dataPoint: 'result',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  insert: ({ data }) => (
    <InterviewResultIcon result={data.result} status={data.status} showTooltip />
  ),
  title: 'Rating',
}

export const interviewScorecardColumn: ColumnInterface<InterviewFeedbackInterface> = {
  type: CellTypes.insert,
  idPoint: 'id',
  dataPoint: 'id',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Scorecard',
  insert: ({ data }) => {
    const buttonNames = {
      [Statuses.pending]: 'Rate',
      [Statuses.scheduled]: 'Preview',
      [Statuses.completed]: 'Edit',
    }

    if (!data.is_feedback_editable) {
      return (
        <StyledTooltip placement="top" text="You are not allowed to edit previous stage">
          <DisabledLink onClick={e => e.stopPropagation()}>
            {/* @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */}
            {buttonNames[data.status] || 'View'}
          </DisabledLink>
        </StyledTooltip>
      )
    }

    return (
      <ViewLink
        to={getLocationDescriptor(
          pathToUrl(ROUTES.FORMS.INTERVIEW_FEEDBACK.GENERAL, { id: data.id }),
        )}
        onClick={e => e.stopPropagation()}
      >
        {/* @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */}
        {buttonNames[data.status] || 'View'}
      </ViewLink>
    )
  },
}
