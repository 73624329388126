import React, { useEffect, useState } from 'react'
import { ActionButton, Button, HStack, Header, Token } from '@revolut/ui-kit'
import { matchPath, useLocation } from 'react-router-dom'
import useResizeObserver from 'use-resize-observer'
import { useIntl } from 'react-intl'

import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { ROUTES } from '@src/constants/routes'
import { setupGuideConfig } from './SetupGuide/config'
import { SetupGuideSidebar } from './SetupGuide/SetupGuideSidebar'
import mainHeaderState from '@src/features/MainHeader/MainHeaderState'
import { useCurrentTenantInfo } from '@src/api/tenants'

interface OnboardingChecklistHeaderProps {
  title: React.ReactNode
  description?: React.ReactNode
  backUrl?: string
}

export const OnboardingChecklistHeader = ({
  title,
  description,
  backUrl,
}: OnboardingChecklistHeaderProps) => {
  const { formatMessage } = useIntl()
  const { isDemoMode } = useCurrentTenantInfo()

  const [sidebarOpen, setSidebarOpen] = useState(false)

  const { pathname } = useLocation()

  const { ref, height: headerHeight } = useResizeObserver()

  useEffect(() => {
    if (headerHeight) {
      mainHeaderState.height = headerHeight
    }
  }, [headerHeight])

  const matchedSidebarConfig = setupGuideConfig.find(config =>
    config.paths.some(path => matchPath(pathname, path)),
  )

  return (
    <>
      <Header
        variant="item"
        ref={ref}
        top={isDemoMode ? 44 : 0}
        mb={isDemoMode ? 's-40' : 0}
      >
        <Header.BackButton use={InternalLink} to={backUrl} aria-label="Back" />
        <Header.Title use="h1">{title}</Header.Title>
        <Header.Description>{description}</Header.Description>
        <Header.Actions>
          <HStack space="s-16" align="center">
            {matchedSidebarConfig ? (
              <ActionButton
                onClick={() => setSidebarOpen(state => !state)}
                useIcon="Cleaning"
              >
                {formatMessage({
                  id: 'onboarding.general.setupGuideButton',
                  defaultMessage: 'Setup Guide',
                })}
              </ActionButton>
            ) : null}
            <Button
              variant="text"
              useIcon="Cross"
              color={Token.color.foreground}
              use={InternalLink}
              to={ROUTES.MAIN}
            >
              {formatMessage({
                id: 'onboarding.general.closeButton',
                defaultMessage: 'Close',
              })}
            </Button>
          </HStack>
        </Header.Actions>
      </Header>

      <SetupGuideSidebar
        open={!!(sidebarOpen && matchedSidebarConfig)}
        onClose={() => setSidebarOpen(false)}
        title={matchedSidebarConfig?.title}
        description={matchedSidebarConfig?.description}
        customContent={matchedSidebarConfig?.customContent}
        questionsAnswers={matchedSidebarConfig?.questionsAnswers}
      />
    </>
  )
}
