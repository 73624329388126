import React from 'react'

import { CellTypes, ColumnInterface, FilterType } from '@src/interfaces/data'
import { selectorKeys } from '@src/constants/api'
import UserWithAvatar from '@src/components/UserWithAvatar/UserWithAvatar'
import { PayCycleInterface } from '@src/interfaces/payroll'
import { formatMoneyWithCode } from '@src/utils/format'
import {
  getIssuesInfoString,
  mapCycleStatusToColor,
} from '@src/apps/People/Payroll/helpers'

export const payCycleEntityColumn: ColumnInterface<PayCycleInterface> = {
  type: CellTypes.text,
  idPoint: 'pay_group.name',
  dataPoint: 'pay_group.name',
  sortKey: 'pay_group__name',
  filterKey: 'pay_group__id',
  selectorsKey: selectorKeys.active_pay_groups,
  title: 'Entity',
}

export const payCycleCountryColumn: ColumnInterface<PayCycleInterface> = {
  type: CellTypes.text,
  idPoint: 'pay_group.country.id',
  dataPoint: 'pay_group.country.name',
  sortKey: 'pay_group__country_id',
  filterKey: 'pay_group__country_id',
  selectorsKey: selectorKeys.countries,
  title: 'Country',
}

export const payCycleStartDateColumn: ColumnInterface<PayCycleInterface> = {
  type: CellTypes.date,
  idPoint: 'start_date',
  dataPoint: 'start_date',
  sortKey: 'start_date',
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Start date',
}

export const payCycleEndDateColumn: ColumnInterface<PayCycleInterface> = {
  type: CellTypes.date,
  idPoint: 'end_date',
  dataPoint: 'end_date',
  sortKey: 'end_date',
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'End date',
}

export const payCycleSubmissionDateColumn: ColumnInterface<PayCycleInterface> = {
  type: CellTypes.date,
  idPoint: 'submission_date',
  dataPoint: 'submission_date',
  sortKey: 'submission_date',
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Submission date',
}

export const payCyclePayDayColumn: ColumnInterface<PayCycleInterface> = {
  type: CellTypes.date,
  idPoint: 'pay_date',
  dataPoint: 'pay_date',
  sortKey: 'pay_date',
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Pay day',
}

export const payCycleHeadcountColumn: ColumnInterface<PayCycleInterface> = {
  type: CellTypes.text,
  idPoint: 'headcount',
  dataPoint: 'headcount',
  sortKey: 'headcount',
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Headcount',
}

export const payCycleIssuesColumn: ColumnInterface<PayCycleInterface> = {
  type: CellTypes.insert,
  idPoint: 'issue_count',
  dataPoint: 'issue_count',
  sortKey: 'issue_count',
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Issues',
  insert: ({ data }) => getIssuesInfoString<PayCycleInterface>(data),
}

export const payCycleTotalGrossColumn: ColumnInterface<PayCycleInterface> = {
  type: CellTypes.insert,
  idPoint: 'total_gross_pay',
  dataPoint: 'total_gross_pay',
  sortKey: 'total_gross_pay',
  filterKey: 'total_gross_pay',
  filterType: FilterType.range,
  selectorsKey: selectorKeys.none,
  title: 'Total gross pay',
  insert: ({ data }) =>
    formatMoneyWithCode(
      Number(data.total_gross_pay),
      data.pay_group?.country?.national_currency?.iso_code || '-',
    ),
}

export const payCycleTotalNetColumn: ColumnInterface<PayCycleInterface> = {
  type: CellTypes.insert,
  idPoint: 'total_net_pay',
  dataPoint: 'total_net_pay',
  sortKey: 'total_net_pay',
  filterKey: 'total_net_pay',
  filterType: FilterType.range,
  selectorsKey: selectorKeys.none,
  title: 'Total net pay',
  insert: ({ data }) =>
    formatMoneyWithCode(
      Number(data.total_net_pay),
      data.pay_group?.country?.national_currency?.iso_code || '-',
    ),
}

export const payCycleTotalDeductionsColumn: ColumnInterface<PayCycleInterface> = {
  type: CellTypes.insert,
  idPoint: 'total_deductions',
  dataPoint: 'total_deductions',
  sortKey: 'total_deductions',
  filterKey: 'total_deductions',
  filterType: FilterType.range,
  selectorsKey: selectorKeys.none,
  title: 'Total deductions',
  insert: ({ data }) =>
    formatMoneyWithCode(
      Number(data.total_deductions),
      data.pay_group?.country?.national_currency?.iso_code || '-',
    ),
}

export const payCycleTotalContributionColumn: ColumnInterface<PayCycleInterface> = {
  type: CellTypes.insert,
  idPoint: 'total_employee_contributions',
  dataPoint: 'total_employee_contributions',
  sortKey: 'total_employee_contributions',
  filterKey: 'total_employee_contributions',
  filterType: FilterType.range,
  selectorsKey: selectorKeys.none,
  title: 'Total employer contribution',
  insert: ({ data }) =>
    formatMoneyWithCode(
      Number(data.total_employee_contributions),
      data.pay_group?.country?.national_currency?.iso_code || '-',
    ),
}

export const payCycleOwnerColumn: ColumnInterface<PayCycleInterface> = {
  type: CellTypes.insert,
  idPoint: 'owner.id',
  dataPoint: 'owner.name',
  sortKey: 'owner__name',
  filterKey: 'owner__id',
  selectorsKey: selectorKeys.employee,
  title: 'Owner',
  insert: ({ data }) => <UserWithAvatar {...data.owner} />,
}

export const payCycleStatusColumn: ColumnInterface<PayCycleInterface> = {
  type: CellTypes.text,
  idPoint: 'status.id',
  dataPoint: 'status.name',
  sortKey: 'status',
  filterKey: null,
  selectorsKey: selectorKeys.none,
  colors: mapCycleStatusToColor,
  title: 'Status',
}
