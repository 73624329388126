import React, { useEffect } from 'react'
import { EntitySelector } from '@src/apps/People/Engagement/Results/components/EntitySelector'
import { IdAndName } from '@src/interfaces'
import { PageStateQueryInterface } from '@src/apps/People/Engagement/Results/hooks'
import { QueryParams } from '@src/apps/People/Engagement/Results/constants'

type Props = {
  value?: IdAndName
  setValue: (driver: IdAndName | undefined) => void
  options: IdAndName[]
  isFetching: boolean
  query: PageStateQueryInterface
}
export const DriverSelector = ({
  value,
  setValue,
  options,
  isFetching,
  query,
}: Props) => {
  useEffect(() => {
    if (isFetching) {
      return
    }
    const driverIdFromQuery = query[QueryParams.driver]
    const defaultOption =
      options.find(option => String(option.id) === driverIdFromQuery) || options[0]

    if (!value && defaultOption) {
      setValue(defaultOption)
    }
  }, [isFetching, options])

  return (
    <EntitySelector
      label="Driver"
      value={value}
      setValue={setValue}
      options={options}
      isFetching={isFetching}
    />
  )
}
