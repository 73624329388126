import { useGetLifecycleSettings } from '@src/api/settings'

export const useSenderOptions = (user?: string) => {
  const { data: settings } = useGetLifecycleSettings()

  return [
    {
      id: 'user' as const,
      name: user || 'Sender’s email',
      subtitle: user ? undefined : 'Sender’s email address',
    },
    settings?.contact_hr_email && settings?.contact_hr_name
      ? {
          id: 'generic' as const,
          name: settings.contact_hr_name,
          subtitle: settings.contact_hr_email,
        }
      : null,
  ]
    .filter(Boolean)
    .map(value => ({
      label: value.name,
      value,
    }))
}
