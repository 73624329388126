import React, { useState } from 'react'
import { RowInterface } from '@src/interfaces/data'
import AdjustableTable from '@components/Table/AdjustableTable'
import { useTable } from '@components/Table/hooks'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { Flex, MoreBar, Widget } from '@revolut/ui-kit'
import { usersRequests } from '@src/api/accessManagement'
import { UserAccessManagementInterface } from '@src/interfaces/accessManagement'
import {
  actionColumn,
  emailColumn,
  lastLoginColumn,
  statusColumn,
  typeColumn,
  userNameColumn,
} from '@src/constants/columns/accessManagement/accessManagementUsers'
import SettingsButtons from '@src/features/SettingsButtons'
import { LockOpened, Plus, SendMessage, AddContact } from '@revolut/icons'
import { useSelector } from 'react-redux'
import { selectPermissions, selectFeatureFlags } from '@src/store/auth/selectors'
import { PermissionTypes, FeatureFlags } from '@src/store/auth/types'
import { Statuses } from '@src/interfaces'
import SearchTable from '@components/Table/SearchTable/SearchTable'
import { AssignGroupsSidebar } from './AssignGroupsSidebar'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { TableNames } from '@src/constants/table'
import { useGetAccessManagementSettings } from '@src/api/settings'
import Stat from '@src/components/Stat/Stat'
import { useLoginSettings } from '@src/api/login'

interface Props {
  groupId?: number | string
}

const UsersTable = ({ groupId }: Props) => {
  const [selectedUser, setSelectedUser] = useState<UserAccessManagementInterface>()
  const { data: settings } = useGetAccessManagementSettings()
  const { data: loginSettings } = useLoginSettings()
  const featureFlags = useSelector(selectFeatureFlags)
  const isCommercial = featureFlags?.includes(FeatureFlags.CommercialProduct)
  const isOnboardingV2 = featureFlags?.includes(FeatureFlags.OnboardingFlowV2)

  const initialFilter = [
    {
      columnName: 'status',
      filters: [
        { id: Statuses.active, name: Statuses.active },
        { id: Statuses.inactive, name: Statuses.inactive },
      ],
      nonResettable: true,
    },
    ...(groupId
      ? [
          {
            filters: [{ name: String(groupId), id: groupId }],
            columnName: 'group__id',
            nonResettable: true,
          },
        ]
      : []),
  ]
  const table = useTable<UserAccessManagementInterface>(usersRequests, initialFilter)
  const permissions = useSelector(selectPermissions)

  const canAddServiceAccount =
    permissions.includes(PermissionTypes.AddServiceAccount) &&
    settings?.enable_service_accounts
  const canAssignUserGroups = permissions?.includes(PermissionTypes.AddUserGroups)
  const canSendInvitations =
    permissions.includes(PermissionTypes.HRManagerPermissions) &&
    !!loginSettings?.credentials_authentication_enabled
  const canInviteEmployees =
    isCommercial && permissions?.includes(PermissionTypes.CanInviteEmployees)

  const Row: RowInterface<UserAccessManagementInterface> = {
    linkToForm: ({ id }) =>
      navigateTo(pathToUrl(ROUTES.FORMS.ACCESS_MANAGEMENT_USER.GENERAL, { id })),
    cells: [
      {
        ...userNameColumn,
        width: 300,
      },
      {
        ...typeColumn,
        width: 300,
      },
      {
        ...emailColumn,
        width: 300,
      },
      {
        ...lastLoginColumn,
        width: 300,
      },
      {
        ...statusColumn,
        width: 300,
      },
      canAssignUserGroups
        ? {
            ...actionColumn(setSelectedUser),
            width: 200,
          }
        : null,
    ].filter(Boolean),
  } as RowInterface<UserAccessManagementInterface>

  return (
    <>
      <Widget p="s-16">
        <Flex flexWrap="wrap" justifyContent="space-between" mb="s-16">
          <Stat label="Users" val={table?.loading ? undefined : table?.count} />
          <SearchTable
            placeholder="Search by name or email"
            onFilter={table.onFilterChange}
          />
        </Flex>
        <SettingsButtons mb="s-16">
          {settings?.enable_group_access_request_approvals ? (
            <MoreBar.Action
              useIcon={LockOpened}
              use={InternalLink}
              to={pathToUrl(ROUTES.FORMS.GROUP_ACCESS_REQUEST.GENERAL)}
            >
              Request access
            </MoreBar.Action>
          ) : null}
          {canAssignUserGroups && (
            <MoreBar.Action
              useIcon={LockOpened}
              use={InternalLink}
              to={pathToUrl(ROUTES.FORMS.ASSIGN_USER_TO_PERMISSION_GROUPS.GENERAL)}
            >
              Assign groups
            </MoreBar.Action>
          )}
          {canAddServiceAccount && (
            <MoreBar.Action
              useIcon={Plus}
              use={InternalLink}
              to={pathToUrl(ROUTES.FORMS.ADD_SERVICE_ACCOUNT.GENERAL)}
            >
              Add service account
            </MoreBar.Action>
          )}
          {canSendInvitations && !isOnboardingV2 && (
            <MoreBar.Action
              use={InternalLink}
              to={pathToUrl(ROUTES.FORMS.SEND_ACTIVATION_EMAILS)}
              useIcon={SendMessage}
            >
              Send activation emails
            </MoreBar.Action>
          )}
          {canInviteEmployees && (
            <MoreBar.Action
              use={InternalLink}
              to={pathToUrl(ROUTES.PLATFORM_ONBOARDING.INVITE_TEAM)}
              useIcon={AddContact}
            >
              Invite users
            </MoreBar.Action>
          )}
        </SettingsButtons>
        <Flex flexDirection="column" width="100%">
          <Flex style={{ position: 'relative' }} flex="1 0">
            <AdjustableTable<UserAccessManagementInterface>
              name={TableNames.UsersAccess}
              useWindowScroll
              dataType="Users"
              row={Row}
              {...table}
              noDataMessage="Users will appear here."
            />
          </Flex>
        </Flex>
      </Widget>

      <AssignGroupsSidebar
        selectedUser={selectedUser}
        onClose={() => setSelectedUser(undefined)}
      />
    </>
  )
}

export default UsersTable
