import { EmployeeInterface } from '@src/interfaces/employees'
import {
  PerformanceSelector,
  PerformanceTimelineInterface,
} from '@src/interfaces/performance'
import {
  ToggleSecondaryTabs,
  usePerformanceChartData,
  usePerformanceTimelineTable,
} from '@src/pages/EmployeeProfile/Preview/Performance/PerformanceHistory/hooks'
import { useGetPerformanceSettings } from '@src/api/performanceSettings'
import React, { useMemo, useState } from 'react'
import { Box, Cell, Flex, Popup, Token, VStack } from '@revolut/ui-kit'
import { SummaryTableContainer, summaryTableProps } from '@src/features/Summary/common'
import AdjustableTable from '@components/Table/AdjustableTable'
import { TableNames } from '@src/constants/table'
import { Chart } from '@components/Charts/EmployeePerformanceChart/EmployeePerformanceChart'
import OverallFeedback from '@src/pages/Forms/EmployeePerformance/OverallFeedback'
import { AccessError } from '@src/pages/EmployeeProfile/Preview/PerformanceSummary'
import { RowInterface } from '@src/interfaces/data'
import {
  performanceFeedbackColumn,
  performanceGradeColumn,
  performanceTimelineNameColumn,
  performanceTimelineSeniorityColumn,
  performanceTimelineSpecialisationColumn,
} from '@src/constants/columns/performance'
import useTabBarSwitcher from '@src/features/TabBarSwitcher/useTabBarSwitcher'

const performanceTimelineROW = (
  onMessageClick: (data: PerformanceTimelineInterface) => void,
  cycleId?: number | string,
): RowInterface<PerformanceTimelineInterface> => ({
  noChildrenRequest: true,
  highlight: data => {
    if (data.cycle.id === cycleId) {
      return Token.color.actionBackground
    }
    return ''
  },
  cells: [
    {
      ...performanceTimelineNameColumn,
      width: 100,
    },
    {
      ...performanceGradeColumn,
      width: 100,
    },
    {
      ...performanceTimelineSeniorityColumn,
      width: 140,
    },
    {
      ...performanceTimelineSpecialisationColumn,
      width: 100,
    },
    {
      ...performanceFeedbackColumn(onMessageClick),
      width: 100,
    },
  ],
})

export const PerformanceSection = ({
  data,
  selectedCycle,
  onRowClick,
}: {
  data: EmployeeInterface
  selectedCycle?: PerformanceSelector
  onRowClick: (rowData: PerformanceTimelineInterface) => void
}) => {
  const performanceTimelineTable = usePerformanceTimelineTable(data)
  const { mappedChartData } = usePerformanceChartData(data)
  const { data: performanceSettings } = useGetPerformanceSettings()

  const subtabs = useMemo<ToggleSecondaryTabs[]>(() => {
    if (performanceSettings?.enable_skill_assessment) {
      return [
        ToggleSecondaryTabs.Grades,
        ToggleSecondaryTabs.Deliverables,
        ToggleSecondaryTabs.Skills,
      ]
    }
    return [ToggleSecondaryTabs.Grades, ToggleSecondaryTabs.Deliverables]
  }, [performanceSettings?.enable_skill_assessment])

  const { tabBar: secondaryTabBar, currentTab: currentSecondaryTab } = useTabBarSwitcher({
    tabs: subtabs,
    defaultTab: ToggleSecondaryTabs.Grades,
    highlightSelected: false,
  })
  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const [popupDetails, setPopupDetails] = useState<PerformanceTimelineInterface>()

  const getChartData = (tab?: ToggleSecondaryTabs) => {
    switch (tab) {
      case ToggleSecondaryTabs.Deliverables:
        return mappedChartData.deliverables
      case ToggleSecondaryTabs.Skills:
        return mappedChartData.skills
      default:
        return mappedChartData.grades
    }
  }

  const onMessageClick = (notesData: PerformanceTimelineInterface) => {
    setPopupDetails(notesData)
    setIsPopupOpen(true)
  }

  const tableData = performanceTimelineTable.table?.data || []

  return (
    <Cell p={0}>
      {performanceTimelineTable.error ? (
        <Flex justifyContent="center" width="100%">
          <AccessError />
        </Flex>
      ) : (
        <Flex flexDirection="column" width="100%">
          <SummaryTableContainer>
            <AdjustableTable
              hideCountAndButtonSection
              expandableType="chevron"
              name={TableNames.PerformanceTimeline}
              noDataMessage="Performance timeline will appear here"
              row={performanceTimelineROW(onMessageClick, selectedCycle?.id)}
              onRowClick={onRowClick}
              {...summaryTableProps}
              {...performanceTimelineTable.table}
              data={tableData}
            />
            {isPopupOpen && (
              <Popup
                onClose={() => setIsPopupOpen(false)}
                open={Boolean(isPopupOpen && popupDetails)}
                variant="bottom-sheet"
              >
                <OverallFeedback
                  avatar={popupDetails?.reviewers?.[0].avatar}
                  title={popupDetails?.reviewers?.[0].full_name}
                  subtitle={`Relation: ${popupDetails?.stage}`}
                  name={popupDetails?.reviewers?.[0].display_name}
                  reviewerId={popupDetails?.reviewers?.[0].id}
                  pros={popupDetails?.overall_feedback?.pros}
                  cons={popupDetails?.overall_feedback?.cons}
                  isSidebar
                  disabled
                />
              </Popup>
            )}
          </SummaryTableContainer>
          <VStack space="s-8" mt="s-16">
            {secondaryTabBar}
            <SummaryTableContainer>
              <Flex flex="1 0" flexDirection="column">
                <Box height={400}>
                  <Chart
                    data={getChartData(currentSecondaryTab)}
                    id={data.id}
                    isRating={currentSecondaryTab !== ToggleSecondaryTabs.Grades}
                  />
                </Box>
              </Flex>
            </SummaryTableContainer>
          </VStack>
        </Flex>
      )}
    </Cell>
  )
}
