import React from 'react'
import { ROUTES } from '@src/constants/routes'
import { EngagementResults } from '@src/apps/People/Engagement/Results'
import { EngagementResultsScope } from '@src/interfaces/engagement'
import { FunctionInterface } from '@src/interfaces/functions'

export const Engagement = ({ data }: { data: FunctionInterface }) => {
  return (
    <EngagementResults
      scope={EngagementResultsScope.Functions}
      driversPath={ROUTES.FORMS.FUNCTION.ENGAGEMENT.DRIVERS}
      questionsPath={ROUTES.FORMS.FUNCTION.ENGAGEMENT.QUESTIONS}
      feedbackPath={ROUTES.FORMS.FUNCTION.ENGAGEMENT.FEEDBACK}
      data={data}
    />
  )
}
