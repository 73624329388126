import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { connect } from 'lape'
import cloneDeep from 'lodash/cloneDeep'
import { Avatar, Bar, Token, Flex, InputGroup, Item } from '@revolut/ui-kit'
import { navigateReplace } from '@src/actions/RouterActions'
import { selectorKeys } from '@src/constants/api'
import { ROUTES } from '@src/constants/routes'
import { QueryInterface } from '@src/interfaces/dataAnalytics'
import { useLapeContext } from '@src/features/Form/LapeForm'
import LapeNewInput from '@components/Inputs/LapeFields/LapeNewInput'
import LapeNewTextArea from '@components/Inputs/LapeFields/LapeNewTextArea'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import { PageBody } from '@src/components/Page/PageBody'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { selectFeatureFlags } from '@src/store/auth/selectors'
import { FeatureFlags, PermissionTypes } from '@src/store/auth/types'
import { pathToUrl } from '@src/utils/router'

export const MetaData = connect(() => {
  const { initialValues, values } = useLapeContext<QueryInterface>()

  const permissions = values.field_options?.permissions || []
  const featureFlags = useSelector(selectFeatureFlags)
  const canChangeSavedQuery =
    permissions.includes(PermissionTypes.CanChangeSavedQuery) &&
    featureFlags?.includes(FeatureFlags.ReportingQueriesManagement)

  useEffect(() => {
    if (initialValues.sql) {
      values.sql = initialValues.sql
    }

    if (initialValues.connection) {
      values.connection = cloneDeep(initialValues.connection)
    }

    if (initialValues.parameters) {
      values.parameters = cloneDeep(initialValues.parameters)
    }
  }, [])

  return (
    <PageBody margin={0}>
      {canChangeSavedQuery && (
        <Bar mb="s-16">
          <NewSaveButtonWithPopup
            hideWhenNoChanges={false}
            onAfterSubmit={res => {
              navigateReplace(pathToUrl(ROUTES.FORMS.QUERY.META_DATA, { id: res.id }))
            }}
            successText={`Query ${values.name} successfully saved`}
            variant="secondary"
            useIcon="Plus"
            useValidator
            small
          >
            Save
          </NewSaveButtonWithPopup>
        </Bar>
      )}
      <Flex
        backgroundColor={Token.color.background}
        borderRadius={12}
        flexDirection="column"
        gap="s-16"
        p="s-16"
      >
        <Item p={0}>
          <Item.Avatar>
            <Avatar useIcon="Document" />
          </Item.Avatar>
          <Item.Content>
            <Item.Title>General information</Item.Title>
            <Item.Description>Basic query characteristics</Item.Description>
          </Item.Content>
        </Item>
        <InputGroup>
          <LapeNewInput
            disabled={!canChangeSavedQuery}
            label="Query name"
            name="name"
            required
          />
          <LapeNewTextArea
            disabled={!canChangeSavedQuery}
            label="Description"
            name="description"
            rows={3}
          />
          <LapeRadioSelectInput
            disabled={!canChangeSavedQuery}
            label="Owner"
            name="owner"
            required
            selector={selectorKeys.employee}
          />
        </InputGroup>
      </Flex>
    </PageBody>
  )
})
