import { GlobalSearch } from '@src/interfaces/globalSearch'
import React from 'react'
import { StarEmpty, StarFilled } from '@revolut/icons'
import {
  useAddFavourite,
  useDeleteFavourite,
  useGetFavourites,
} from '@src/api/favourites'
import { Spinner } from '@revolut/ui-kit'
import globalSearchState from '@components/GlobalSearchSidebar/GlobalSearchSidebarState'

interface StarItemProps {
  data: GlobalSearch
}

const StarItem = ({ data }: StarItemProps) => {
  const { data: favourites, isLoading } = useGetFavourites()
  const addFavourite = useAddFavourite()
  const deleteFavourite = useDeleteFavourite()

  const favourite = favourites?.results.find(f => f.favourite_object?.id === data.id)

  const handleClick = () => {
    globalSearchState.showAction = true

    if (favourite) {
      deleteFavourite.mutateAsync(favourite.id)
    } else {
      addFavourite.mutateAsync({ favourite_object: { id: data.id } })
    }
  }

  if (isLoading || addFavourite.isLoading || deleteFavourite.isLoading) {
    return <Spinner size={20} mx="s-2" />
  }

  return (
    <button
      type="button"
      data-testid={`star-toggle-${data.id}`}
      key={data.id}
      onClick={handleClick}
    >
      {favourite ? (
        <StarFilled color="grey-tone-50" />
      ) : (
        <StarEmpty color="grey-tone-50" />
      )}
    </button>
  )
}

export default StarItem
