import { PageWrapper } from '@src/components/Page/Page'
import React from 'react'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { ROUTES } from '@src/constants/routes'
import Loader from '@components/CommonSC/Loader'
import { useGetInternalDashboard } from '@src/api/analyticsDashboards'
import Page404 from '@src/pages/Page404/Page404'
import { useParams } from 'react-router-dom'
import { Subtitle } from '@components/Page/Header/PageHeaderTitle'
import { formatDateTime } from '@src/utils/format'
import { EntityPermissions } from '@src/store/auth/types'
import { MoreBar } from '@revolut/ui-kit'
import { pathToUrl } from '@src/utils/router'
import { InternalLink } from '@components/InternalLink/InternalLink'
import { PageBody } from '@components/Page/PageBody'
import { MetricsGrid } from '@src/pages/Forms/DataAnalyticsInternalDashboardView/components/MetricsGrid'

export const DataAnalyticsInternalDashboardView = () => {
  const { id } = useParams<{ id: string }>()
  const { data, isLoading } = useGetInternalDashboard(id)

  if (isLoading) {
    return (
      <PageWrapper>
        <Loader />
      </PageWrapper>
    )
  }

  if (!data || !id) {
    return <Page404 />
  }

  const canChange = data.field_options?.actions?.includes(EntityPermissions.Change)

  return (
    <>
      <PageWrapper>
        <PageHeader
          title={
            <PageHeader.Title
              title={data?.name}
              labels={
                data.update_date_time ? (
                  <Subtitle iconName="History">
                    Edited {formatDateTime(data.update_date_time)}
                  </Subtitle>
                ) : null
              }
              actions={
                canChange ? (
                  <MoreBar>
                    <MoreBar.Action
                      to={pathToUrl(
                        ROUTES.FORMS.DATA_ANALYTICS_INTERNAL_DASHBOARD.METRICS,
                        {
                          id: data.id,
                        },
                      )}
                      use={InternalLink}
                      useIcon="Pencil"
                    >
                      Edit
                    </MoreBar.Action>
                  </MoreBar>
                ) : null
              }
            />
          }
          backUrl={ROUTES.APPS.DATA_ANALYTICS.DASHBOARD}
        />
        <PageBody maxWidth="100%">
          <MetricsGrid layout={data.layout} />
        </PageBody>
      </PageWrapper>
    </>
  )
}
