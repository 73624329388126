import React, { useEffect, useMemo, useRef, useState } from 'react'
import MatrixTable, { MatrixCellInsertParams } from '@components/Table/MatrixTable'
import {
  performanceCriteriaColumn,
  performanceNotesColumn,
  performanceRatingColumn,
} from '@src/constants/columns/performance'
import {
  PerformanceKPISection,
  PerformanceRating,
  PerformanceReviewTypes,
  Ratings,
  ReviewCategory,
  ReviewSummaryDataInterface,
  SkillSummary,
  SummarySkillCardInterface,
  SummarySkillCardJustificationsInterface,
} from '@src/interfaces/performance'
import styled from 'styled-components'
import SummaryCardRating from '@components/ColumnInserts/CardRating/SummaryCardRating'
import { CellTypes } from '@src/interfaces/data'
import { getNormalizedSeniorityName } from '@src/utils/competency'
import { getSelectors } from '@src/api/selectors'
import { selectorKeys } from '@src/constants/api'
import { SeniorityInterface } from '@src/interfaces/seniority'
import { getExpectedRatings } from '@src/api/probationReview'
import { ExpectedSkillRatingInterface } from '@src/interfaces/probationReview'
import isEmpty from 'lodash/isEmpty'
import {
  Box,
  Color,
  Dropdown,
  Flex,
  Icon,
  Text,
  TextButton,
  Token,
} from '@revolut/ui-kit'
import { SummaryRating } from '@src/pages/Forms/EmployeePerformance/components/SummaryCard'
import { CompetencyLevels } from '@src/features/CompetencyMatrixTable/utils'
import SummaryJustification from '@components/ColumnInserts/Justification/SummaryJustification'
import KPIPreviewSide from '@src/features/KPIPreviewSide/KPIPreviewSide'
import { SkillLevels } from '@src/interfaces/roles'
import { useHasNewScorecards } from '@src/utils/performance'

interface PerformanceTableProps {
  data: ReviewSummaryDataInterface | null
  cycleId?: string
  isSidebar?: boolean
  employeeId: number
  employeeSeniorityId?: number
  category: ReviewCategory
}

const Competency = styled(Text)`
  font-style: italic;
`

interface ExpectedRatingsHeaderProps {
  seniorities: SeniorityInterface[]
  seniorityId: number
  disabled?: boolean
  changeSeniority: (seniority: SeniorityInterface) => void
}

const ExpectedRatingsHeader = ({
  seniorities,
  seniorityId,
  changeSeniority,
  disabled,
}: ExpectedRatingsHeaderProps) => {
  const ref = useRef(null)
  const [isOpen, setOpen] = useState(false)

  if (disabled) {
    return (
      <Box color="inherit" style={{ textTransform: 'inherit' }}>
        Expected (
        {getNormalizedSeniorityName(seniorities.find(s => s.id === seniorityId)!.name)})
      </Box>
    )
  }

  return (
    <>
      <Flex
        ref={ref}
        onClick={() => setOpen(true)}
        use="button"
        alignItems="flex-start"
        color="inherit"
        style={{ textTransform: 'inherit' }}
      >
        Expected (
        {getNormalizedSeniorityName(seniorities.find(s => s.id === seniorityId)!.name)})
        <Box ml="s-4">
          <Icon name="ChevronDown" size={17} />
        </Box>
      </Flex>
      <Dropdown open={isOpen} anchorRef={ref} onClose={() => setOpen(false)}>
        {seniorities.map(seniority => (
          <Dropdown.Item
            use="button"
            onClick={() => {
              changeSeniority(seniority)
              setOpen(false)
            }}
            key={seniority.id}
          >
            {seniority.name}
          </Dropdown.Item>
        ))}
      </Dropdown>
    </>
  )
}

interface PerformanceSectionHeader {
  isSectionHeader: true
  name: string
  rating?: PerformanceRating
  ratings?: Ratings[]
}

type SkillCardWithTypeData = SummarySkillCardInterface & {
  id?: number
  type: PerformanceReviewTypes
}

type PerformanceTableRow = SkillCardWithTypeData | PerformanceSectionHeader

type PartialSkillSummary = Partial<SkillSummary> & Pick<SkillSummary, 'cards'>

type SummarySection =
  | {
      name: string
      data?: PartialSkillSummary
      type: Exclude<PerformanceReviewTypes, PerformanceReviewTypes.kpi>
    }
  | {
      name: string
      data?: PerformanceKPISection
      type: PerformanceReviewTypes.kpi
    }

const generateSummaryData = (sections: SummarySection[]): PerformanceTableRow[] => {
  const result: PerformanceTableRow[] = []

  sections.forEach(section => {
    if (section.data) {
      result.push({
        isSectionHeader: true,
        name: section.name,
        rating: section.data.rating,
        ratings: section.data.ratings,
      })

      if (section.type === PerformanceReviewTypes.kpi) {
        result.push(
          ...section.data.kpi_items.map(row => ({
            id: row.id!,
            name: row.name!,
            rating: row.rating!,
            ratings: [],
            sections: [],
            rating_expectation: null,
            type: section.type,
          })),
        )
      } else if (section.data.cards) {
        result.push(...section.data.cards.map(row => ({ ...row, type: section.type })))
      }
    }
  })

  return result
}

const isSectionHeader = (data: PerformanceTableRow): data is PerformanceSectionHeader =>
  'isSectionHeader' in data

const getCellBackground = (
  data: PerformanceTableRow,
  rating?: PerformanceRating | SkillLevels | null,
) => {
  if (isSectionHeader(data)) {
    return Token.color.background
  }

  switch (rating) {
    case PerformanceRating.basic:
    case PerformanceRating.basic_plus:
    case PerformanceRating.performing:
      return Token.color.teal_5
    case PerformanceRating.poor:
    case PerformanceRating.poor_plus:
      return Token.color.red_10
    case PerformanceRating.intermediate_minus:
    case PerformanceRating.intermediate:
    case PerformanceRating.intermediate_plus:
    case PerformanceRating.superpower:
    case SkillLevels.Intermediate:
      return Token.color.teal_30
    case PerformanceRating.advanced_minus:
    case PerformanceRating.advanced:
    case PerformanceRating.advanced_plus:
    case SkillLevels.Advanced:
      return Token.color.teal_70
    case PerformanceRating.expert_minus:
    case PerformanceRating.expert:
    case SkillLevels.Expert:
      return Token.color.teal
    default:
      return Token.color.background
  }
}

const rows = (
  cycleId: string,
  employeeId: number,
  category: ReviewCategory,
  expectedRatings: ExpectedSkillRatingInterface[],
  seniorities: SeniorityInterface[],
  changeSeniority: (seniority: SeniorityInterface) => void,
  setKpiPreviewId: (id: number) => void,
  seniorityId?: number,
  isSidebar?: boolean,
) => {
  return {
    cells: [
      {
        ...performanceCriteriaColumn,
        type: CellTypes.insert,
        insert: ({ data }: MatrixCellInsertParams<PerformanceTableRow>) => {
          if (!isSectionHeader(data) && data.type === PerformanceReviewTypes.kpi) {
            return (
              <TextButton
                onClick={() => setKpiPreviewId(data.id!)}
                justifyContent="start"
                pl={0}
              >
                {data.name}
              </TextButton>
            )
          }
          return (
            <Text color={isSectionHeader(data) ? Color.GREY_TONE_50 : Color.FOREGROUND}>
              {data.name}
            </Text>
          )
        },
        background: getCellBackground,
        width: isSidebar ? 200 : 300,
      },
      {
        ...performanceRatingColumn,
        insert: ({ data }: MatrixCellInsertParams<PerformanceTableRow>) => {
          if (isSectionHeader(data)) {
            return (
              <Flex>
                <SummaryRating
                  rating={data.rating}
                  ratings={data.ratings}
                  tooltipPlacement="top"
                />
              </Flex>
            )
          }
          return (
            <SummaryCardRating
              data={data}
              cycleId={cycleId}
              employeeId={employeeId}
              type={data.type}
              category={category}
              hideJustification
            />
          )
        },
        background: (data: PerformanceTableRow) => {
          return getCellBackground(data, data.rating)
        },
        width: isSidebar ? 120 : 150,
      },
      {
        type: CellTypes.insert,
        idPoint: '',
        dataPoint: '',
        title:
          seniorityId !== undefined ? (
            <ExpectedRatingsHeader
              disabled={isSidebar}
              seniorities={seniorities}
              seniorityId={seniorityId}
              changeSeniority={changeSeniority}
            />
          ) : (
            '-'
          ),
        background: (data: PerformanceTableRow) => {
          if (isSectionHeader(data) || seniorityId === undefined) {
            return Token.color.background
          }
          const rating = getCompetencyLevel(
            expectedRatings,
            data.type,
            seniorityId,
            data.skill_id,
          )
          const level = CompetencyLevels.find(lev => lev.id === rating)
          if (level) {
            return getCellBackground(data, level.id)
          }
          return null
        },
        insert: ({ data }: MatrixCellInsertParams<PerformanceTableRow>) => {
          if (isSectionHeader(data) || seniorityId === undefined) {
            return <></>
          }
          const rating = getCompetencyLevel(
            expectedRatings,
            data.type,
            seniorityId,
            data.skill_id,
          )
          if (!rating) {
            return <Competency>N/A</Competency>
          }
          const level = CompetencyLevels.find(lev => lev.id === rating)
          return <Competency>{level?.name}</Competency>
        },
        width: isSidebar ? 120 : 150,
      },
      {
        ...performanceNotesColumn,
        insert: ({ data }: MatrixCellInsertParams<PerformanceTableRow>) => {
          if (isSectionHeader(data)) {
            return <></>
          }
          return <SummaryJustification data={data} />
        },
        background: getCellBackground,
        width: isSidebar ? 64 : 80,
      },
    ],
  }
}

const getCompetencyLevel = (
  expectedRatings: ExpectedSkillRatingInterface[],
  type: PerformanceReviewTypes,
  seniorityId: number,
  skillId?: number,
) => {
  const expectedRating = expectedRatings.find(r => r.seniority.id === seniorityId)

  if (!expectedRating) {
    return ''
  }

  switch (type) {
    case PerformanceReviewTypes.cultureFit:
      if (!skillId) {
        return ''
      }
      return expectedRating.expected_ratings_by_culture_skill[skillId]
    case PerformanceReviewTypes.deliverables:
    case PerformanceReviewTypes.kpi:
      return expectedRating.expected_deliverables_rating
    case PerformanceReviewTypes.skills:
      if (!skillId) {
        return ''
      }
      return expectedRating.expected_ratings_by_functional_skill[skillId]
  }

  return ''
}

const PerformanceTable = ({
  data,
  cycleId,
  isSidebar,
  employeeId,
  employeeSeniorityId,
  category,
}: PerformanceTableProps) => {
  const [expectedRatings, setExpectedRatings] = useState<ExpectedSkillRatingInterface[]>()
  const [seniorityId, setSeniorityId] = useState<number | undefined>(employeeSeniorityId)
  const [seniorities, setSeniorities] = useState<SeniorityInterface[]>()
  const [kpiPreviewId, setKpiPreviewId] = useState<number | null>(null)
  const showNewScorecards = useHasNewScorecards()

  useEffect(() => {
    setSeniorityId(employeeSeniorityId)
  }, [employeeSeniorityId])

  useEffect(() => {
    if (!employeeId || !cycleId) {
      return
    }
    const fetchData = async () => {
      const response = await getExpectedRatings(employeeId, cycleId)
      setExpectedRatings(response?.data)
    }
    const fetchSeniorities = async () => {
      const response = await getSelectors(selectorKeys.seniority)
      const sortedSeniorities = (response.data.options as SeniorityInterface[]).sort(
        (a, b) => a.level - b.level,
      )
      setSeniorities(sortedSeniorities)
    }

    fetchSeniorities()
    fetchData()
  }, [employeeId, cycleId])

  const changeSeniority = (seniority: SeniorityInterface) => {
    setSeniorityId(seniority.id)
  }

  const calculatedDeliverables = useMemo(() => {
    const list: SummarySkillCardInterface[] = []

    if (!data?.calculated_deliverables_ratings) {
      return list
    }

    const getJustifications = (key: string) => {
      const justifications: SummarySkillCardJustificationsInterface[] = []
      return (
        data?.extra_deliverables_overall_feedback?.reduce((acc, feedback) => {
          /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
          if (feedback.value?.[key]) {
            acc.push({
              /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
              value: feedback.value?.[key].map((val: string) => ({ comment: val })),
              review: feedback.review,
            })
          }
          return acc
        }, justifications) || []
      )
    }

    const deliverables = data.calculated_deliverables_ratings

    return [
      {
        name: 'Complexity',
        rating: deliverables.complexity?.rating,
        ratings: deliverables.complexity?.ratings,
        sections: [],
        rating_expectation: null,
        justifications: getJustifications('complexity'),
      },
      {
        name: 'Speed',
        rating: deliverables.speed?.rating,
        ratings: deliverables.speed?.ratings,
        sections: [],
        rating_expectation: null,
        justifications: getJustifications('speed'),
      },
      {
        name: 'Quality',
        rating: deliverables.quality?.rating,
        ratings: deliverables.quality?.ratings,
        sections: [],
        rating_expectation: null,
        justifications: getJustifications('quality'),
      },
    ] as SummarySkillCardInterface[]
  }, [data?.calculated_deliverables_ratings])

  if (cycleId == null || !data || !seniorities || !expectedRatings) {
    return null
  }

  const sectionData = [
    {
      name: 'KPIs',
      data:
        !!data.calculated_deliverables_ratings &&
        !isEmpty(data.calculated_deliverables_ratings)
          ? {
              cards: calculatedDeliverables,
              rating: data.calculated_deliverables_ratings.rating?.rating,
              ratings: data.calculated_deliverables_ratings.rating?.ratings,
            }
          : undefined,
      type: PerformanceReviewTypes.deliverables as const,
    },
    {
      name: 'Deliverables',
      data: data.deliverables
        ? {
            ...data.deliverables,
            cards: showNewScorecards ? [] : data.deliverables.cards,
          }
        : undefined,
      type: PerformanceReviewTypes.deliverables as const,
    },
    {
      name: 'KPIs',
      data: data.kpis_section,
      type: PerformanceReviewTypes.kpi as const,
    },
    {
      name: 'Skills',
      data: isEmpty(data.manager_values) ? data.functional_skills : data.manager_values,
      type: PerformanceReviewTypes.skills as const,
    },
    {
      name: 'Culture Fit',
      data: isEmpty(data.culture_values) ? data.culture_skills : data.culture_values,
      type: PerformanceReviewTypes.cultureFit as const,
    },
  ]

  const generatedData = generateSummaryData(sectionData)

  return (
    <Box mb="s-8">
      <Box style={{ overflowX: 'auto' }}>
        <MatrixTable
          rows={rows(
            cycleId,
            employeeId,
            category,
            expectedRatings,
            seniorities,
            changeSeniority,
            setKpiPreviewId,
            seniorityId,
            isSidebar,
          )}
          data={generatedData}
        />
      </Box>
      <KPIPreviewSide
        onClose={() => setKpiPreviewId(null)}
        isOpen={!!kpiPreviewId}
        kpiId={kpiPreviewId}
      />
    </Box>
  )
}

export default PerformanceTable
